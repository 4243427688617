import React, { FunctionComponent, useState, useEffect } from 'react';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import InformationField from '../../shared/components/InformationField';
import ReviewField from '../../shared/components/ReviewField';
import { Appointment } from '../../appointments/model';
import { _translateEach } from '../../shared/utils/translation.utils';
import { sessionNoteStore } from '../../shared/singletons';
import { useStoreObservable } from '../../shared/state/useStoreObservable.hook';
import { SessionNote } from '../model';

interface SessionNoteHistoryProps {
  appointment: Appointment;
  appointmentLoading: boolean;
}

const SessionNoteHistory: FunctionComponent<SessionNoteHistoryProps> = (props) => {
  const { appointment, appointmentLoading } = props;
  const [pastAppointmentId, setPastAppointmentId] = useState('0');
  const { sessionNotes, isLoading, appointmentDate } = useStoreObservable(sessionNoteStore);

  useEffect(() => {
    if (appointment?.past_appointment_ids?.length > 0) {
      // set to first set of sesssion notes by default
      setPastAppointmentId(appointment.past_appointment_ids[0]);
      sessionNoteStore.fetchSessionNotesByAppointment(appointment.past_appointment_ids[0]);
    }
  }, [appointment]);

  const handlePrevious = () => {
    // use indexOf to get index of session notes on appointment
    let nextIndex = appointment.past_appointment_ids.indexOf(pastAppointmentId) - 1;

    if (nextIndex < 0) {
      nextIndex = appointment.past_appointment_ids.length - 1;
    }

    setPastAppointmentId(appointment.past_appointment_ids[nextIndex]);
    sessionNoteStore.fetchSessionNotesByAppointment(appointment.past_appointment_ids[nextIndex]);
  };

  const handleNext = () => {
    // use indexOf to get index of session notes on appointment
    let nextIndex = appointment.past_appointment_ids.indexOf(pastAppointmentId) + 1;

    if (nextIndex === appointment.past_appointment_ids.length) {
      nextIndex = 0;
    }

    setPastAppointmentId(appointment.past_appointment_ids[nextIndex]);
    sessionNoteStore.fetchSessionNotesByAppointment(appointment.past_appointment_ids[nextIndex]);
  };

  const t = _translateEach({
    noRecords: 'sessions.noteHistory.noRecords',
    next: 'sessions.noteHistory.next',
    previous: 'sessions.noteHistory.previous',
  });

  return appointmentLoading || isLoading ? (
    <Grid container justify="center" alignItems="center">
      <Typography paragraph>Loading...</Typography>
    </Grid>
  ) : (
    <Grid container>
      {appointment?.past_appointment_ids?.length > 0 ? (
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Grid container justify="space-between">
                    <Grid item>
                      <Grid container spacing={2}>
                        {appointmentDate && (
                          <Grid item>
                            <ReviewField label="Appointment Date" value={appointmentDate} />
                          </Grid>
                        )}
                        {sessionNotes?.[0] && (
                          <Grid item>
                            <ReviewField label="Date Created" value={sessionNotes[0]?.appointment_date} />
                          </Grid>
                        )}
                        {sessionNotes?.[0] && (
                          <Grid item>
                            <ReviewField label="Created By" value={sessionNotes[0]?.created_by} />
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Grid container spacing={2}>
                        <Grid item>
                          <Button variant="contained" color="primary" onClick={handlePrevious}>
                            {t.previous}
                          </Button>
                        </Grid>
                        <Grid item>
                          <Button variant="contained" color="primary" onClick={handleNext}>
                            {t.next}
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  {sessionNotes?.[0] ? (
                    <Box>
                      {sessionNotes?.map((session_note: SessionNote, index: number) => (
                        <Grid container spacing={2} key={session_note.id}>
                          <Grid item xs={12}>
                            {session_note.goal_id ? (
                              <Typography variant="h3">{`Service Goal ${index + 1}`}</Typography>
                            ) : (
                              <Typography variant="h3">Subjective Notes</Typography>
                            )}
                          </Grid>
                          {session_note.percent_string && (
                            <Grid item xs={12}>
                              <InformationField label="Percent" value={session_note.percent_string} />
                            </Grid>
                          )}
                          {session_note.assistance && (
                            <Grid item xs={12}>
                              <InformationField label="Level of Assistance" value={session_note.assistance} />
                            </Grid>
                          )}
                          {session_note.content && (
                            <Grid item xs={12}>
                              <InformationField label="Comment" value={session_note.content} />
                            </Grid>
                          )}
                          {session_note.prompts && (
                            <Grid item xs={12}>
                              <InformationField label="Prompts" value={session_note.prompts} />
                            </Grid>
                          )}
                          {session_note.participation && (
                            <Grid item xs={12}>
                              <InformationField label="Participation" value={session_note.participation} />
                            </Grid>
                          )}
                          {session_note.transitions && (
                            <Grid item xs={12}>
                              <InformationField label="Transitions" value={session_note.transitions} />
                            </Grid>
                          )}
                          {session_note.subjective_comment && (
                            <Grid item xs={12}>
                              <InformationField label="Subjective Comment" value={session_note.subjective_comment} />
                            </Grid>
                          )}
                        </Grid>
                      ))}
                    </Box>
                  ) : (
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography>No notes available for this appointment</Typography>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Grid container justify="center" alignItems="center">
          <Typography paragraph>{t.noRecords}</Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default SessionNoteHistory;
