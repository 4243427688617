import React, { FunctionComponent, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import AppointmentDetail from '../components/AppointmentDetail';
import { useStoreObservable } from '../../shared/state/useStoreObservable.hook';
import { AppointmentRecordState } from '../appointmentRecord.store';
import { appointmentRecordStore } from '../../shared/singletons';
import { AppointmentRouteParams } from '../model';
import { ServiceFormOptionsContext } from '../../services/contexts/ServiceFormOptionsContext';

export type AppointmentDetailPageProps = Record<string, unknown>;

const AppointmentDetailPage: FunctionComponent<AppointmentDetailPageProps> = () => {
  const { appointment, appointmentLoading } = useStoreObservable<AppointmentRecordState>(appointmentRecordStore);
  const { studentId, appointmentId, tab } = useParams<AppointmentRouteParams>();
  const { fetchChoices } = React.useContext(ServiceFormOptionsContext);

  /* eslint-disable */
  useEffect(() => {
    fetchChoices();
    appointmentRecordStore.fetchRecord(studentId, appointmentId);
  }, [studentId, appointmentId]);
  /* eslint-enable */

  return <AppointmentDetail appointment={appointment} tab={tab} appointmentLoading={appointmentLoading} />;
};

export default AppointmentDetailPage;
