import React, { FunctionComponent, useContext } from 'react';
import Card from '@material-ui/core/Card';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CardContent from '@material-ui/core/CardContent';
import LoadingSpinner from '../../../shared/components/LoadingSpinner';
import EditListingServiceModal from './EditListingServiceModal';
import NewListingServiceModal from './NewListingServiceModal';
import ListingServiceRowMenu from './ListingServiceRowMenu';
import { IDataTableColumn } from '../../../shared/components/DataTable/DataTable/model';
import DataTable from '../../../shared/components/DataTable';
import { ListingService } from '../model';
import { ListingServicesContext } from '../contexts/ListingServicesContext';
import InformationField from '../../../shared/components/InformationField';

const columns: IDataTableColumn<Record<string, unknown>>[] = [
  {
    name: 'NAME',
    selector: 'name',
    sortable: true,
  },
  {
    name: 'STUDENT SERVICE COUNT',
    selector: 'active_service_count',
    sortable: true,
  },
  {
    name: 'REFERRAL SERVICE COUNT',
    selector: 'active_referral_count',
    sortable: true,
  },
  {
    name: 'STATUS',
    selector: 'status',
    sortable: true,
    cell: (row: Record<string, string>) => (
      <div
        style={{
          fontWeight: 700,
          textTransform: 'uppercase',
          color: row.status === 'active' ? 'green' : 'red',
        }}
      >
        {row.status}
      </div>
    ),
  },
  {
    cell: (listingService) => <ListingServiceRowMenu listingService={listingService} />,
    name: 'ACTIONS',
    allowOverflow: true,
    button: true,
    selector: 'actions',
  },
];

const ExpandableComponent = ({ data }) => (
  <Grid container spacing={3} style={{ padding: '10px' }}>
    <Grid item xs={3}>
      <InformationField
        label="Maximum Units"
        value={parseInt(data.maximum_units) > 64 ? 'No Maximum' : data.maximum_units}
      />
    </Grid>
    <Grid item xs={3}>
      <InformationField label="Editable Units" value={data.editable ? 'Yes' : 'No'} />
    </Grid>
    <Grid item xs={3}>
      <InformationField label="Category" value={data.category} />
    </Grid>
    <Grid item xs={3}>
      <InformationField label="Notes Template" value={data.note_template_category} />
    </Grid>
    <Grid item xs={3}>
      <InformationField label="Goal Required" value={data.goal_required ? 'Yes' : 'No'} />
    </Grid>
    <Grid item xs={3}>
      <InformationField label="Renewal Required" value={data.renewal_required ? 'Yes' : 'No'} />
    </Grid>
  </Grid>
);

type ListingServicesListProps = { records: ListingService[]; isLoading: boolean };

const ListingServicesList: FunctionComponent<ListingServicesListProps> = (props: ListingServicesListProps) => {
  const { records = [], isLoading = false } = props;

  const { setCreateModalOpen } = useContext(ListingServicesContext);

  return isLoading ? (
    <LoadingSpinner />
  ) : (
    <Box>
      <EditListingServiceModal />
      <NewListingServiceModal />
      <Grid justify="space-between" container spacing={3}>
        <Grid item>
          <Typography variant="h1" component="h1">
            Listing Services
          </Typography>
        </Grid>
        <Grid item>
          <Button variant="contained" color="primary" onClick={() => setCreateModalOpen(true)}>
            Create New Listing Service +
          </Button>
        </Grid>
      </Grid>
      <br />
      <Card>
        <CardContent>
          <DataTable
            noHeader
            columns={columns}
            data={records}
            striped
            highlightOnHover
            expandableRows
            expandableRowsComponent={<ExpandableComponent data />}
          />
        </CardContent>
      </Card>
    </Box>
  );
};

export default ListingServicesList;
