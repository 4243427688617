import { axios, alertStore, renewalRecordStore } from '../shared/singletons';
import { BaseFormStore } from '../shared/state/baseForm.store';
import { Renewal, RenewalFormValues, RenewalFormErrors, RenewalResponse } from './model';

export class RenewalFormState {
  formValues: RenewalFormValues;
  formErrors: RenewalFormErrors;
  formErrorAlertVisible: boolean;
  formStepperIndex: number;
  formModalOpen: boolean;
  formModalRenewal: Renewal;
  editStageModalOpen: boolean;
  editStageModalRenewal: Renewal;

  static create(props: Partial<RenewalFormState>): RenewalFormState {
    const defaults: RenewalFormState = {
      formValues: {},
      formErrors: null,
      formErrorAlertVisible: true,
      formStepperIndex: 0,
      formModalOpen: false,
      formModalRenewal: {},
      editStageModalOpen: false,
      editStageModalRenewal: {},
    };
    return Object.assign(new RenewalFormState(), defaults, props || {});
  }
}

export class RenewalFormStore extends BaseFormStore<RenewalFormState> {
  constructor() {
    super(RenewalFormState.create({}));
  }

  public async createOrUpdateRenewal(
    formValues: RenewalFormValues,
    studentId: string,
    renewalId: string,
    onSuccess?: () => void,
  ): Promise<void> {
    try {
      const response = await axios[renewalId ? 'put' : 'post']<string, RenewalResponse>(
        `patients/${studentId}/renewals${renewalId ? `/${renewalId}` : ''}.json`,
        {
          renewal: formValues,
          headers: { 'Content-Type': 'application/json' },
        },
      );

      if (onSuccess) {
        onSuccess();
        this.setState({
          formModalOpen: false,
          editStageModalOpen: false,
        });
      } else {
        renewalRecordStore.setState({ renewal: response?.data?.result });
        this.setState({
          formModalOpen: false,
          editStageModalOpen: false,
          formStepperIndex: 2,
          // renewal: response?.data?.result,
        });
      }
    } catch (error) {
      this.setState({
        formStepperIndex: 1, // Don't progress stepper if submit errored
        formErrors: error.response?.data,
        formErrorAlertVisible: !!error.response?.data?.base, // The base errors are not tied to a specific field and displayed at the top of the form
      });
    }
  }

  public deleteRenewal(renewalId: string, afterDelete: () => void): void {
    axios
      .delete<string, RenewalResponse>(`renewals/${renewalId}.json`, {
        headers: { 'Content-Type': 'application/json' },
      })
      .then(() => {
        afterDelete();
      })
      .catch(() => {
        alertStore.alertError('renewals.alert.delete.error');
      });
  }

  public setEditModalOpen(isOpen: boolean, renewal: Renewal): void {
    this.clearFormValues();

    if (renewal) {
      const formValues = {
        student_id: renewal.student_id,
        patient_id: renewal.student_id,
        renewal_type_id: renewal.renewal_type_id,
        student_rr_date: renewal.student_rr_date,
        renewal_stage_id: renewal.renewal_stage_id,
        referral_service_id: renewal.referral_service_id,
        active: renewal.active,
        user_id: renewal.user_id,
        user_ids: renewal.user_ids || [],
        service_end_date: renewal.service_end_date,
      };

      this.setState({ formModalOpen: isOpen, formStepperIndex: 1, formModalRenewal: renewal, formValues });
    }
  }

  public setEditStageModalOpen(isOpen: boolean, renewal: Renewal): void {
    this.clearFormValues();

    if (renewal) {
      const formValues = {
        patient_id: renewal.student_id,
        renewal_type_id: renewal.renewal_type_id,
        student_rr_date: renewal.student_rr_date,
        renewal_stage_id: renewal.renewal_stage_id,
        user_id: renewal.user_id,
        student_id: renewal.student_id,
      };

      this.setState({
        editStageModalOpen: isOpen,
        formValues,
        editStageModalRenewal: renewal,
      });
    }
  }

  public setStepperIndex(index: number): void {
    this.setState({ formStepperIndex: index });
  }

  public setCreateModalOpen(isOpen: boolean, studentId?: string): void {
    this.setState({
      formModalRenewal: {},
      formModalOpen: isOpen,
      formValues: { student_id: studentId, user_ids: [], active: 'true' },
      formStepperIndex: studentId ? 1 : 0,
    });
  }

  protected clearFormValues(): void {
    this.setState({ formValues: {} });
  }
}
