import React from 'react';
import { useParams } from 'react-router-dom';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { useHistory } from 'react-router-dom';

const DischargeCompletePage: React.FC = () => {
  const { studentId } = useParams<Record<string, string>>();
  const history = useHistory();

  return (
    <Dialog
      open={true}
      onClose={() => history.push(`/dashboard`)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Success!</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">Student has been fully discharged!</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => history.push(`/students/${studentId}/discharge`)}>Back to Discharge</Button>
        <Button onClick={() => history.push(`/students/${studentId}`)}>Return to Student Page</Button>
      </DialogActions>
    </Dialog>
  );
};

export default DischargeCompletePage;
