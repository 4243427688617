import React, { useContext } from 'react';
import { Box, Typography, FormControl, FormLabel, FormGroup, FormControlLabel, Radio, Grid } from '@material-ui/core';
import ErrorAlert from '../../shared/components/ErrorAlert';
import { userFormStore, userSelectOptionStore } from '../../shared/singletons';
import { useStoreObservable } from '../../shared/state/useStoreObservable.hook';
import { UserContext } from '../../auth/contexts/userContext';
import { InternalUsersContext } from '../../internalUsers/contexts/InternalUsersContext';
import { useStyles } from '../../shared/style/siteWideStyles';

const UserRolesForm = () => {
  const classes = useStyles();
  const { currentUserHasRole } = useContext(UserContext);
  const { selectedRole, setSelectedRole } = useContext(InternalUsersContext);

  const { formErrorAlertVisible, formErrors } = useStoreObservable(userFormStore);
  const { roleChoices } = useStoreObservable(userSelectOptionStore);

  return (
    <Grid>
      {formErrors?.base && (
        <ErrorAlert
          display={formErrorAlertVisible}
          onClose={() => userFormStore.setState({ formErrorAlertVisible: false })}
        >
          {formErrors?.base}
        </ErrorAlert>
      )}
      <FormControl component="fieldset">
        <FormLabel component="legend">Select one role for this user</FormLabel>
        <br />
        {/* TODO: Replace these hard-coded id values with values pulled from the database */}
        {/* Ideally, put these permissions in a separate table and join them to the Role table */}
        {/* Then, we can use the permissions in the backend, and these descriptions will update when we
        change the permissions that are associated with a role */}
        <FormGroup>
          <Box>
            <FormControlLabel
              classes={{ label: classes.checkboxLabel }}
              key="system_owner"
              checked={selectedRole === '53'}
              control={
                <Radio
                  name="role"
                  onChange={() => (currentUserHasRole('system_owner') ? setSelectedRole('53') : null)}
                  value="53"
                />
              }
              label={
                currentUserHasRole('system_owner') ? 'System Owner' : 'System Owner (can only be set by system owners)'
              }
            />
            <div style={{ marginLeft: '30px' }}>
              <Typography className={classes.subtitle} color="primary">
                CAN READ / CREATE / DELETE
              </Typography>
              <Typography className={classes.description}>
                All Users, All Students, All Appointments, All Services, All Renewals, All Schools, Fees, Rollovers
              </Typography>
            </div>
            <hr />
          </Box>
          <Box>
            <FormControlLabel
              classes={{ label: classes.checkboxLabel }}
              checked={selectedRole === '57'}
              control={
                <Radio
                  name="role"
                  onChange={() => (currentUserHasRole('system_owner') ? setSelectedRole('57') : null)}
                  value="57"
                />
              }
              label={
                currentUserHasRole('system_owner') ? 'Accountant' : 'Accountant (can only be set by system owners)'
              }
            />
            <div style={{ marginLeft: '30px' }}>
              <Typography className={classes.subtitle} color="primary">
                CAN READ
              </Typography>
              <Typography className={classes.description}>All students and services</Typography>
              <Typography className={classes.subtitle} color="primary">
                CAN CREATE
              </Typography>
              <Typography className={classes.description}>Invoices, School Activity Report</Typography>
            </div>
            <hr />
          </Box>
          <Box>
            <FormControlLabel
              classes={{ label: classes.checkboxLabel }}
              checked={selectedRole === '58'}
              control={<Radio name="role" onChange={() => setSelectedRole('58')} value="58" />}
              label="Services Coordinator"
            />
            <div style={{ marginLeft: '30px' }}>
              <Typography className={classes.subtitle} color="primary">
                CAN READ
              </Typography>
              <Typography className={classes.description}>All students and services</Typography>
            </div>
            <hr />
          </Box>
          <Box>
            <FormControlLabel
              classes={{ label: classes.checkboxLabel }}
              key={'admin'}
              checked={selectedRole === '4'}
              control={<Radio name="role" onChange={() => setSelectedRole('4')} value="4" />}
              label="System Administrator"
            />
            <div style={{ marginLeft: '30px' }}>
              <Typography className={classes.subtitle} color="primary">
                CAN READ / CREATE / DELETE
              </Typography>
              <Typography className={classes.description}>
                All Users, All Students, All Appointments, All Services, All Renewals
              </Typography>
            </div>
            <hr />
          </Box>
          <Box>
            <FormControlLabel
              classes={{ label: classes.checkboxLabel }}
              key={roleChoices?.service_provider}
              checked={selectedRole === '56'}
              control={<Radio name="role" onChange={() => setSelectedRole('56')} value="56" />}
              label="Supervisor"
            />
            <div style={{ marginLeft: '30px' }}>
              <Typography className={classes.subtitle} color="primary">
                CAN READ / CREATE / DELETE
              </Typography>
              <Typography className={classes.description}>Their own Communications, Notes, and Attachments</Typography>
              <Typography className={classes.subtitle} color="primary">
                CAN READ ONLY
              </Typography>
              <Typography className={classes.description}>
                Communications, Notes, Attachments, Students, Appointments, Services, and Renewals of those they
                supervise
              </Typography>
            </div>
            <hr />
          </Box>
          <Box>
            <FormControlLabel
              classes={{ label: classes.checkboxLabel }}
              key={roleChoices?.service_provider}
              checked={selectedRole === '17'}
              control={<Radio name="role" onChange={() => setSelectedRole('17')} value="17" />}
              label="Service Provider"
            />
            <div style={{ marginLeft: '30px' }}>
              <Typography className={classes.subtitle} color="primary">
                CAN READ / CREATE / DELETE
              </Typography>
              <Typography className={classes.description}>
                Their Own Communications, Their Own Notes, Their Own Attachments
              </Typography>
              <Typography className={classes.subtitle} color="primary">
                CAN READ ONLY
              </Typography>
              <Typography className={classes.description}>
                Their Own Students, Their Own Appointments, Their Own Services, Their Own Renewals
              </Typography>
            </div>
            <hr />
          </Box>
          <Box>
            <FormControlLabel
              classes={{ label: classes.checkboxLabel }}
              key={roleChoices?.school_admin}
              checked={selectedRole === '13'}
              control={<Radio name="role" onChange={() => setSelectedRole('13')} value="13" />}
              label="School District"
            />
            <div style={{ marginLeft: '30px' }}>
              <Typography className={classes.subtitle} color="primary">
                CAN READ / CREATE
              </Typography>
              <Typography className={classes.description}>Their Own Students, Their Own Services</Typography>
              <Typography className={classes.subtitle} color="primary">
                CAN READ / CREATE / DESTROY
              </Typography>
              <Typography className={classes.description}>Their Own Attachments</Typography>
            </div>
            <hr />
          </Box>
        </FormGroup>
      </FormControl>
    </Grid>
  );
};

export default UserRolesForm;
