import React, { FunctionComponent, useEffect } from 'react';
import StudentsList from '../components/StudentsList';
import { useStoreObservable } from '../../shared/state/useStoreObservable.hook';
import { studentRecordStore } from '../../shared/singletons';
import { UserContext } from '../../auth/contexts/userContext';

export type StudentsListPageProps = Record<string, unknown>;

const StudentsListPage: FunctionComponent<StudentsListPageProps> = () => {
  const { filteredRecords, records, recordsLoading, totalRecords } = useStoreObservable(studentRecordStore);
  const { currentUserHasRole } = React.useContext(UserContext);

  useEffect(() => {
    studentRecordStore.fetchRecords();
  }, [currentUserHasRole]);

  return (
    <StudentsList
      records={filteredRecords || records}
      recordsLoading={recordsLoading}
      totalRecords={totalRecords}
      showFilterBar
    />
  );
};

export default StudentsListPage;
