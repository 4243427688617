import { BaseStore } from '../shared/state/base.store';
import { SelectOption } from '../shared/common.model';
import { Colors } from '../shared/style/colors';

export class ServiceState {
  schoolChoices: SelectOption[];
  studentChoices: SelectOption[];
  phaseChoices: SelectOption[];
  serviceTypeChoices: SelectOption[];
  stageChoices: SelectOption[];
  ownerChoices: SelectOption[];
  frequencyChoices: SelectOption[];
  statusChoices: SelectOption[];

  statusColors: Record<string, string>;
  phaseColors: Record<string, string>;

  static create(props: Partial<ServiceState>): ServiceState {
    const defaults: ServiceState = {
      schoolChoices: [],
      studentChoices: [],
      phaseChoices: [],
      serviceTypeChoices: [],
      stageChoices: [],
      ownerChoices: [],
      frequencyChoices: [],
      statusChoices: [
        { label: 'Active', value: 'active' },
        { label: 'Complete', value: 'complete' },
      ],

      statusColors: {
        'On Target': Colors.Success,
      },
      phaseColors: {
        referral: Colors.PurpleThree,
        student: Colors.Success,
        patient: Colors.Success,
        cancelled: Colors.Red,
        on_hold: Colors.Orange,
      },
    };
    return Object.assign(new ServiceState(), defaults, props || {});
  }
}

export class ServiceSelectOptionStore extends BaseStore<ServiceState> {
  constructor() {
    super(ServiceState.create(JSON.parse(sessionStorage.getItem('serviceState')) || {}));
  }
}
