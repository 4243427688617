import React, { FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import ConfirmCancelModal from '../../shared/components/modal/ConfirmCancelModal';
import TextInput from '../../shared/components/form/TextInput';
import { useStoreObservable } from '../../shared/state/useStoreObservable.hook';
import { GoalState } from '../goal.store';
import { axios, goalStore } from '../../shared/singletons';
import { API_ENDPOINT } from '../../../config/env';

const useStyles = makeStyles({
  textInput: {
    height: '60px',
    width: '98%',
    margin: '10px 0',
  },
  baseError: {
    marginBottom: '10px',
  },
});

interface NewGoalModalProps {
  serviceId?: string;
  studentId?: string;
  afterAction: () => void;
}

const NewGoalModal: FunctionComponent<NewGoalModalProps> = (props) => {
  const { serviceId, studentId } = props;
  const state = useStoreObservable<GoalState>(goalStore);
  const { createModalOpen } = state;
  const history = useHistory();

  const classes = useStyles();
  const [formValues, setFormValues] = React.useState({ start_date: '', end_date: '', description: '' });

  const submitGoal = () => {
    axios({
      method: 'post',
      url: `${API_ENDPOINT}/referral_services/${serviceId}/goals`,
      data: { goal: formValues },
      headers: { 'Content-Type': 'application/json' },
    }).then(() => {
      history.push(`/students/${studentId}/services/${serviceId}/goals`);
    });
  };

  return (
    <ConfirmCancelModal
      isOpen={createModalOpen}
      title="New Goal"
      confirmLabel="Create Goal"
      openStatusChanged={(isOpen) => goalStore.setModalOpen(isOpen)}
      onConfirm={submitGoal}
    >
      <form noValidate autoComplete="off">
        <br />
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <TextInput
              styleOverrides={classes.textInput}
              value={formValues?.start_date}
              label="Start Date"
              type="date"
              shrinkLabel
              valueChanged={(value: string) => setFormValues({ ...formValues, start_date: value })}
            />
          </Grid>
          <Grid item xs={6} style={{ marginLeft: -15 }}>
            <TextInput
              styleOverrides={classes.textInput}
              value={formValues?.end_date}
              label="End Date"
              type="date"
              shrinkLabel
              valueChanged={(value: string) => setFormValues({ ...formValues, end_date: value })}
            />
          </Grid>
        </Grid>
        <div style={{ height: 200 }}>
          <Grid item xs={12}>
            <TextInput
              styleOverrides={classes.textInput}
              value={formValues?.description}
              label="Goal Objectives"
              multiline
              rows={8}
              valueChanged={(value: string) => setFormValues({ ...formValues, description: value })}
            />
          </Grid>
        </div>
      </form>
    </ConfirmCancelModal>
  );
};

export default NewGoalModal;
