import { axios, alertStore } from '../shared/singletons';
import { BaseStore } from '../shared/state/base.store';
import { API_ENDPOINT } from '../../config/env';
import {
  SessionNote,
  SessionNoteFormValue,
  SessionNoteErrorValue,
  SessionNoteResponse,
  SessionNoteChoicesResponse,
} from './model';
import { Appointment } from '../appointments/model';
import { SelectOption } from '../shared/common.model';
import { AppointmentFormValues } from '../appointments/model';

export class SessionNoteState {
  isCreating: boolean;
  isLoading: boolean;
  sessionNotes: SessionNote[];
  appointment: Appointment;
  appointmentDate: string;
  appointmentLoading: boolean;
  formValue: SessionNoteFormValue;
  formValues: SessionNoteFormValue[];
  errorValues: SessionNoteErrorValue[];

  assistanceChoices: SelectOption[];
  participationAttentionChoices: SelectOption[];
  transitionChoices: SelectOption[];
  prompts: SelectOption[];
  locations: SelectOption[];
  methodChoices: SelectOption[];
  sdiChoices: SelectOption[];

  static create(props: Partial<SessionNoteState>): SessionNoteState {
    const defaults: SessionNoteState = {
      isCreating: false,
      isLoading: false,
      sessionNotes: [],
      appointment: {},
      appointmentDate: '',
      appointmentLoading: false,
      formValue: null,
      formValues: [],
      errorValues: [],
      locations: [],

      assistanceChoices: [
        { label: 'Complete Independence (0%)', value: 'Complete Independence (0%)' },
        { label: 'Modified Independence (0%)', value: 'Modified Independence (0%)' },
        { label: 'Minimal Assistance (25%)', value: 'Minimal Assistance (25%)' },
        { label: 'Moderate Assistance (50%)', value: 'Moderate Assistance (50%)' },
        { label: 'Maximum Assistance (75%)', value: 'Maximum Assistance (75%)' },
        { label: 'Total Assistance (100%)', value: 'Total Assistance (100%)' },
      ],

      participationAttentionChoices: [
        { label: 'Complete Independence (0%)', value: 'Complete Independence (0%)' },
        { label: 'Modified Independence (0%)', value: 'Modified Independence (0%)' },
        { label: 'Minimal Assistance (25%)', value: 'Minimal Assistance (25%)' },
        { label: 'Moderate Assistance (50%)', value: 'Moderate Assistance (50%)' },
        { label: 'Maximum Assistance (75%)', value: 'Maximum Assistance (75%)' },
        { label: 'Not Addressed', value: 'Not Addressed' },
      ],

      transitionChoices: [
        { label: 'Complete Independence (0%)', value: 'Complete Independence (0%)' },
        { label: 'Modified Independence (0%)', value: 'Modified Independence (0%)' },
        { label: 'Minimal Assistance (25%)', value: 'Minimal Assistance (25%)' },
        { label: 'Moderate Assistance (50%)', value: 'Moderate Assistance (50%)' },
        { label: 'Maximum Assistance (75%)', value: 'Maximum Assistance (75%)' },
        { label: 'Not Addressed', value: 'Not Addressed' },
      ],

      prompts: [
        { label: 'No Prompts Required', value: 'No Prompts Required' },
        { label: 'Proximity Prompt', value: 'Proximity Prompt' },
        { label: 'Visual Prompt', value: 'Visual Prompt' },
        { label: 'Gestural Prompt', value: 'Gestural Prompt' },
        { label: 'Model Prompt', value: 'Model Prompt' },
        { label: 'Verbal (Partial) Prompt', value: 'Verbal (Partial) Prompt' },
        { label: 'Verbal (Full) Prompt', value: 'Verbal (Full) Prompt' },
        { label: 'Partial Physical Prompt', value: 'Partial Physical Prompt' },
        { label: 'Full Physical Prompt', value: 'Full Physical Prompt' },
      ],

      methodChoices: [
        { label: 'Phone', value: 'Phone' },
        { label: 'Email', value: 'Email' },
        { label: 'In Person', value: 'In Person' },
        { label: 'Virtual Meeting', value: 'Virtual Meeting' },
        { label: 'Compliance Activity', value: 'Compliance Activity' },
      ],

      sdiChoices: [
        { label: 'Improved independence', value: 'Improved independence' },
        { label: 'Utilized given assistance', value: 'Utilized given assistance' },
        { label: 'Not targeted this session', value: 'Not targeted this session' },
      ],
    };
    return Object.assign(new SessionNoteState(), defaults, props || {});
  }
}

export class SessionNoteStore extends BaseStore<SessionNoteState> {
  constructor() {
    super(SessionNoteState.create({}));
  }

  public fetchAppointment(studentId: string, appointmentId: string): void {
    this.setState({ appointmentLoading: true });

    axios(`${API_ENDPOINT}/patients/${studentId}/appointments/${appointmentId}.json`)
      .then((result) => result?.data?.result ?? [])
      .then((appointment) => {
        this.extractFormValues(appointment);
        this.setState({ appointment, appointmentLoading: false });
      })
      .catch(() => {
        this.setState({ appointmentLoading: false });
      });
  }

  public fetchChoices(studentId: string): void {
    axios
      .get<string, SessionNoteChoicesResponse>(`${API_ENDPOINT}/patients/${studentId}/appointments/new.json`)
      .then((r: SessionNoteChoicesResponse) => {
        const { prompts, locations } = r.data?.result;

        this.setState({
          prompts,
          locations,
        });
      });
  }

  public fetchSessionNotes(goalId: string): void {
    this.setState({ isLoading: true });

    axios(`goals/${goalId}/goal_notes.json`)
      .then((result) => result?.data?.result ?? [])
      .then((sessionNotes) => {
        this.setState({ sessionNotes, isLoading: false });
      })
      .catch(() => {
        this.setState({ isLoading: false });
      });
  }

  public fetchSessionNotesByAppointment(appointmentId: string): void {
    this.setState({ isLoading: true });

    axios(`appointments/${appointmentId}/goal_notes.json`)
      .then((result) => {
        this.setState({
          appointmentDate: result?.data?.appointment_date,
          sessionNotes: result?.data?.result,
          isLoading: false,
        });
      })
      .catch(() => {
        this.setState({ isLoading: false });
      });
  }

  public clearSessionNotes(): void {
    this.setState({ sessionNotes: [] });
  }

  public createSessionNotes(
    formValues: SessionNoteFormValue[],
    appointmentFormValues: AppointmentFormValues,
    appointmentId: string,
    studentId: string,
    published: boolean,
  ): void {
    this.setState({ isCreating: true });
    alertStore.alertSuccess('sessionNotes.alert.create.success');

    axios
      .post<string, SessionNoteResponse>(`${API_ENDPOINT}/appointments/${appointmentId}/goal_notes.json`, {
        data: { session_notes: formValues, appointment: appointmentFormValues, published },
        headers: { 'Content-Type': 'application/json' },
      })
      .then(() => {
        this.setState({ isCreating: false });

        setTimeout(() => {
          window.location.href = `/appointments`;
        }, 1000);
      })
      .catch(() => {
        alertStore.alertSuccess('comments.alert.create.error');
        this.setState({ isCreating: false });
      });
  }

  public setFormValue(formValue: SessionNoteFormValue): void {
    this.setState({ formValue });
  }

  public setFormValues(formValues: SessionNoteFormValue[]): void {
    this.setState({ formValues });
  }

  public extractFormValues(appointment: Appointment): void {
    if (appointment && appointment?.session_notes?.length > 0) {
      const formValues = [];

      appointment.session_notes.forEach((session_note) => {
        formValues.push({
          percent_string: session_note?.percent_string,
          assistance: session_note?.assistance,
          prompts: session_note?.prompts,
          content: session_note?.content,
          participation: session_note?.participation,
          transitions: session_note?.transitions,
          subjective_comment: session_note?.subjective_comment,
          goal_id: session_note?.goal_id ? session_note?.goal_id : 'subjective',
        });
      });

      this.setState({ formValues });
    }
  }
}
