import React, { FunctionComponent, useState } from 'react';
import { CSVLink } from 'react-csv';
import { Box, Button, Typography, Grid } from '@material-ui/core';
import TextInput from '../../shared/components/form/TextInput';
import DataTable from '../../shared/components/DataTable';
import { IDataTableColumn } from '../../shared/components/DataTable/DataTable/model';
import { axios } from '../../shared/singletons';
import { useStyles } from '../../shared/style/siteWideStyles';
import { ReportFormValues } from '../model';

const appointmentColumns: IDataTableColumn[] = [
  {
    name: 'TIMELINE ID',
    selector: 'student_slug',
    sortable: true,
  },
  {
    name: 'SCHOOL',
    selector: 'school_abbreviation',
    sortable: true,
  },
  {
    name: 'SERVICE',
    selector: 'service_name',
    sortable: true,
  },
  {
    name: 'BILLING DATE',
    selector: 'billing_date',
    sortable: true,
  },
  {
    name: 'APPOINTMENT START TIME',
    selector: 'appointment_start_time',
    sortable: true,
    grow: 1.2,
  },
];

const BillingDiscrepancyReportPage: FunctionComponent = () => {
  const classes = useStyles();

  const [downloadDisabled, setDownloadDisabled] = React.useState(true);
  const [billingDiscrepancyReport, setBillingDiscrepancyReport] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [formValues, setFormValues] = useState<ReportFormValues>({});

  const generateBillingDiscrepancyReport = async () => {
    setIsLoading(true);
    const response = await axios(`reports/billing_discrepancies`, { params: formValues });
    setBillingDiscrepancyReport(response?.data?.result);
    setDownloadDisabled(false);
    setIsLoading(false);
  };

  return (
    <Box>
      <Typography variant="h2" component="h2">
        Select Billing Date Range
      </Typography>
      <Typography variant="h4">
        This report lists appointments whose billing date falls before the appointment's start date.
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={3}>
          <TextInput
            styleOverrides={classes.textInput}
            value={formValues?.start_date}
            label="Start Date"
            type="date"
            shrinkLabel
            valueChanged={(value: string) => setFormValues({ ...formValues, start_date: value })}
          />
        </Grid>
        <Grid item xs={3}>
          <TextInput
            styleOverrides={classes.textInput}
            value={formValues?.end_date}
            label="End Date"
            type="date"
            shrinkLabel
            valueChanged={(value: string) => setFormValues({ ...formValues, end_date: value })}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Button variant="contained" color="primary" onClick={generateBillingDiscrepancyReport}>
          {isLoading ? 'Generating...' : 'Generate Billing Discrepancy Report'}
        </Button>
        <CSVLink
          data={billingDiscrepancyReport}
          filename={`billing_discrepancy_report.csv`}
          className={`MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary ${
            downloadDisabled ? 'Mui-disabled' : ''
          }`}
          style={{ marginLeft: '8px' }}
        >
          Download Report
        </CSVLink>
      </Grid>
      <Box my={4}>
        <DataTable title="" columns={appointmentColumns} noHeader data={billingDiscrepancyReport} />
      </Box>
    </Box>
  );
};

export default BillingDiscrepancyReportPage;
