enum Urls {
  AdminAccessPanelRoute = '/admin_access_panel',
  AccountRoute = '/account',
  GradeLevelsRoute = '/grade_levels',
  ListingServicesRoute = '/listing_services',
  LocationsRoute = '/locations',
  ReferralStagesRoute = '/referral_stages',
  RenewalStagesRoute = '/renewal_stages',
  RenewalTypesRoute = '/renewal_types',
  RolesRoute = '/roles',

  DashboardRoute = '/',
  StudentsRoute = '/students',
  AppointmentsRoute = '/appointments',
  ServicesRoute = '/services',
  RenewalsRoute = '/renewals',
  UsersRoute = '/users',
  SchoolsRoute = '/schools',
  LoginRoute = '/login',
}

export { Urls };
