import React, { FunctionComponent } from 'react';
import { CSVLink } from 'react-csv';
import { Box, Button, Grid, Typography, makeStyles } from '@material-ui/core';
import DataTable from '../../shared/components/DataTable';
import { IDataTableColumn } from '../../shared/components/DataTable/DataTable/model';
import TextInput from '../../shared/components/form/TextInput';
import { reportStore } from '../../shared/singletons';
import { useStoreObservable } from '../../shared/state/useStoreObservable.hook';

// TODO: Temp styles before we line things up with the mockup
const useStyles = makeStyles({
  textInput: {
    height: '60px',
    width: '98%',
    margin: '10px 0',
  },
  baseError: {
    marginBottom: '10px',
  },
});

const columns: IDataTableColumn<unknown>[] = [
  {
    name: 'SCHOOL NAME',
    selector: 'school_name',
    sortable: true,
  },
  {
    name: 'COMPLETED APPOINTMENTS',
    selector: 'completed_appointments',
    sortable: true,
  },
];

const SchoolActivityReport: FunctionComponent = () => {
  const classes = useStyles();

  const { formValues, schoolActivityRecords, isLoading } = useStoreObservable(reportStore);

  const [downloadDisabled, setDownloadDisabled] = React.useState(true);

  const handleFormValueChange = (key: string, value: string) => {
    setDownloadDisabled(true);
    reportStore.setState({ formValues: { ...formValues, [key]: value } });
  };

  const handleReportGeneration = async () => {
    setDownloadDisabled(true);
    await reportStore.generateSchoolActivityReport();
    setDownloadDisabled(false);
  };

  return (
    <Box>
      <Typography variant="h2" component="h2">
        Select Date Range
      </Typography>
      <Grid container spacing={3}>
        <Grid item>
          <TextInput
            styleOverrides={classes.textInput}
            value={formValues?.start_date}
            label="Start Date"
            type="date"
            shrinkLabel
            valueChanged={(value: string) => handleFormValueChange('start_date', value)}
          />
        </Grid>
        <Grid item>
          <TextInput
            styleOverrides={classes.textInput}
            value={formValues?.end_date}
            label="End Date"
            type="date"
            shrinkLabel
            valueChanged={(value: string) => handleFormValueChange('end_date', value)}
          />
        </Grid>
      </Grid>
      <br />
      <Button variant="contained" color="primary" onClick={handleReportGeneration}>
        {isLoading ? 'Generating...' : 'Generate School Activity Report'}
      </Button>
      <CSVLink
        data={schoolActivityRecords}
        filename={`SchoolActivity_${formValues.start_date}_to_${formValues.end_date}.csv`}
        className={`MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary ${
          downloadDisabled ? 'Mui-disabled' : ''
        }`}
        style={{ marginLeft: '8px' }}
      >
        Download Report
      </CSVLink>
      <Box my={4}>
        <DataTable title="" columns={columns} noHeader data={schoolActivityRecords} />
      </Box>
    </Box>
  );
};

export default SchoolActivityReport;
