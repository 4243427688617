import React, { FunctionComponent, useState } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Card, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DataTable from '../../shared/components/DataTable';
import LoadingSpinner from '../../shared/components/LoadingSpinner';
import { IDataTableColumn } from '../../shared/components/DataTable/DataTable/model';
import { HistoryItem } from '../../shared/common.model';
import DetailEditModal from './DetailEditModal';

interface AppointmentHistoryProps {
  historyItems: HistoryItem[];
  appointmentLoading: boolean;
}

const AppointmentHistory: FunctionComponent<AppointmentHistoryProps> = (props: AppointmentHistoryProps) => {
  const { historyItems, appointmentLoading } = props;
  const [itemToEdit] = useState({});
  const [detailEditModalOpen, setDetailEditModalOpen] = useState(false);

  const columns: IDataTableColumn<Record<string, unknown>>[] = [
    {
      name: 'DATE MODIFIED',
      selector: 'date_modified',
      sortable: true,
    },
    {
      name: 'FROM',
      selector: 'formatted_before_name',
      sortable: true,
      cell: (history: HistoryItem) => <div style={{ whiteSpace: 'break-spaces' }}>{history.formatted_before_name}</div>,
    },
    {
      name: 'TO',
      selector: 'formatted_after_name',
      sortable: true,
      cell: (history: HistoryItem) => <div style={{ whiteSpace: 'break-spaces' }}>{history.formatted_after_name}</div>,
    },
    {
      name: 'USER',
      selector: 'user',
      sortable: true,
    },
    {
      name: 'COMMENT',
      selector: 'details',
      sortable: true,
      cell: (row) => (
        <>
          <p>{row.details}</p>
        </>
      ),
    },
  ];

  return appointmentLoading ? (
    <LoadingSpinner />
  ) : (
    <Card>
      <DetailEditModal
        isOpen={detailEditModalOpen}
        setDetailEditModalOpen={setDetailEditModalOpen}
        itemToEdit={itemToEdit}
      />
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
          <Typography variant="h2" component="h2">
            Appointment History
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <DataTable columns={columns} noHeader data={historyItems} striped highlightOnHover pointerOnHover />
        </AccordionDetails>
      </Accordion>
    </Card>
  );
};

export default AppointmentHistory;
