import React, { FunctionComponent } from 'react';
import { Box, Card, CardContent, Grid, Typography } from '@material-ui/core';
import LoginForm from '../components/LoginForm';

const LoginPage: FunctionComponent = () => {
  return (
    <Box>
      <Grid justify="space-between" container spacing={3}>
        <Grid item>
          <Typography variant="h1" component="h1">
            Login
          </Typography>
        </Grid>
      </Grid>
      <br />
      <Card>
        <CardContent>
          <LoginForm />
        </CardContent>
      </Card>
    </Box>
  );
};

export default LoginPage;
