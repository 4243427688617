import React, { FunctionComponent } from 'react';
import { Grid, Typography } from '@material-ui/core';
import DataTable from '../../shared/components/DataTable';
import LoadingSpinner from '../../shared/components/LoadingSpinner';
import { IDataTableColumn } from '../../shared/components/DataTable/DataTable/model';
import { Quarter } from '../../appointments/model';

const columns: IDataTableColumn<Record<string, unknown>>[] = [
  {
    name: 'Name',
    selector: 'name',
    sortable: true,
  },
  {
    name: 'START DATE',
    selector: 'start_date',
    sortable: true,
  },
  {
    name: 'END DATE',
    selector: 'end_date',
    sortable: true,
  },
  {
    name: 'APPOINTMENTS COUNT',
    selector: 'appointments_count',
    sortable: true,
    cell: (quarter: Quarter) => <div style={{ whiteSpace: 'break-spaces' }}>{quarter.appointments_count}</div>,
  },
  {
    name: 'SCHEDULED',
    selector: 'scheduled',
    sortable: true,
    cell: (quarter: Quarter) => <div style={{ whiteSpace: 'break-spaces' }}>{quarter.scheduled}</div>,
  },
  {
    name: 'INCOMPLETE',
    selector: 'incomplete',
    sortable: true,
    cell: (quarter: Quarter) => <div style={{ whiteSpace: 'break-spaces' }}>{quarter.incomplete}</div>,
  },
  {
    name: 'COMPLETED',
    selector: 'completed',
    sortable: true,
    cell: (quarter: Quarter) => <div style={{ whiteSpace: 'break-spaces' }}>{quarter.completed}</div>,
  },
];

interface DuplicateAppointmentQuartersProps {
  quarters: Quarter[];
  appointmentLoading: boolean;
  title: string;
}

const DuplicateAppointmentQuarters: FunctionComponent<DuplicateAppointmentQuartersProps> = (props) => {
  const { quarters, appointmentLoading, title } = props;

  return appointmentLoading ? (
    <LoadingSpinner />
  ) : (
    <Grid item xs={12}>
      <Typography variant="h2" component="h2">
        {title}
      </Typography>
      <DataTable columns={columns} noHeader data={quarters} striped highlightOnHover pointerOnHover />
      <br />
    </Grid>
  );
};

export default DuplicateAppointmentQuarters;
