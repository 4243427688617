import { axios } from '../shared/singletons';
import { SelectOption } from '../shared/common.model';
import { BaseStore } from '../shared/state/base.store';
import { AppointmentChoicesResponse } from './model';
import { Service } from '../services/model';

export class AppointmentBaseState {
  isLoadingChoices: boolean;

  serviceTypeChoices: SelectOption[];
  schoolChoices: SelectOption[];
  allOwnerChoices: SelectOption[];
  supervisorChoices: SelectOption[];
  appointmentOwnerChoices: SelectOption[];
  serviceOwnerChoices: Record<string, SelectOption[]>[];
  locationChoices: SelectOption[];
  recurrenceChoices: SelectOption[];
  studentChoices: SelectOption[];

  static create(props: Partial<AppointmentBaseState>): AppointmentBaseState {
    const defaults: AppointmentBaseState = {
      isLoadingChoices: false,
      serviceTypeChoices: [],
      schoolChoices: [],
      locationChoices: [],
      allOwnerChoices: [],
      supervisorChoices: [],
      appointmentOwnerChoices: [],
      serviceOwnerChoices: [],
      recurrenceChoices: [],
      studentChoices: [],
    };
    return Object.assign(new AppointmentBaseState(), defaults, props || {});
  }
}

export class AppointmentBaseStore extends BaseStore<AppointmentBaseState> {
  constructor() {
    super(AppointmentBaseState.create({}));
  }

  public async fetchChoices(service?: Service): Promise<void> {
    this.setState({ isLoadingChoices: true });

    const studentId = service?.student?.id || '0';
    const serviceId = service?.id || '0';

    const response = await axios.get<string, AppointmentChoicesResponse>(
      `patients/${studentId}/appointments/new/?referral_service_id=${serviceId}`,
    );

    const {
      service_types,
      recurrence_options,
      locations,
      owners,
      all_owners,
      supervisors,
      all_schools,
    } = response.data?.result;

    this.setState({
      schoolChoices: all_schools,
      recurrenceChoices: recurrence_options,
      serviceTypeChoices: service_types,
      locationChoices: locations,
      appointmentOwnerChoices: owners,
      allOwnerChoices: all_owners,
      supervisorChoices: supervisors,
      isLoadingChoices: false,
    });
  }

  // TODO: fetchRescheduleChoices and fetchChoices are redundant.  We only need one of them.
  // Additionally, serviceOwnerChoices is redundant with appointmentOwnerChoices

  public async fetchRescheduleChoices(studentId = '0'): Promise<void> {
    this.setState({ isLoadingChoices: true });

    const response = await axios.get<string, AppointmentChoicesResponse>(`patients/${studentId}/appointments/new.json`);

    const {
      recurrence_options,
      service_types,
      locations,
      owners,
      all_owners,
      supervisors,
      service_owners,
      all_schools,
    } = response.data?.result;

    // For now, do not set serviceType choices because it will conflict with the service type filter
    if (studentId === '0') {
      this.setState({
        schoolChoices: all_schools,
        recurrenceChoices: recurrence_options,
        serviceTypeChoices: service_types,
        locationChoices: locations,
        appointmentOwnerChoices: owners,
        allOwnerChoices: all_owners,
        supervisorChoices: supervisors,
        isLoadingChoices: false,
      });
    } else {
      this.setState({
        schoolChoices: all_schools,
        recurrenceChoices: recurrence_options,
        locationChoices: locations,
        appointmentOwnerChoices: owners,
        serviceOwnerChoices: service_owners,
        allOwnerChoices: all_owners,
        supervisorChoices: supervisors,
        isLoadingChoices: false,
      });
    }
  }

  public async resetChoices(): Promise<void> {
    this.setState({
      serviceTypeChoices: [],
      locationChoices: [],
      appointmentOwnerChoices: [],
      allOwnerChoices: [],
      supervisorChoices: [],
      isLoadingChoices: false,
    });
  }
}
