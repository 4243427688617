import { Primitive } from './generic.model';
import { _isNil } from './validation.utils';
import { _defaultToValueIfUndefined } from './generic.utils';

// ###########################
// COLLECTION
// ###########################

export const _distinct = (items: string[]) =>
  (items || []).filter((value, index, self) => self.indexOf(value) === index);
export const _compact = <T>(items: T[]): T[] => (items || []).filter((item) => !_isNil(item));
export const _excludeIfNil = <T>(items: T[]): T[] => _compact(items);
export const _excludeIfFalsey = <T>(items: T[]): T[] => (items || []).filter((item) => !!item);
export const _size = (items: any[] | string): number => (items || []).length;
export const _compactJoin = (items: Primitive[], separator: string) => _compact(items).join(separator);
export const _hasItems = (items: any[]): boolean => _size(_compact(items)) > 0;
export const _keys = (obj: Object): string[] => (obj ? Object.keys(obj) : []);
export const _values = <T>(obj: Object): T[] => _keys(obj).map((key) => obj[key]); // IE doesn't support Object.values... so use this instead
export const _first = <T>(items: T[]): T => (items ? items[0] : null);
export const _last = <T>(items: T[]): T => (items ? items[_size(items) - 1] : null);
export const _itemAtIndex = <T>(index: number, items: T[], defaultVal: T = null): T =>
  _defaultToValueIfUndefined((items || [])[index], defaultVal);

export const _haveIdenticalValues = (oneValues: any[], twoValues: any[]): boolean => {
  if (_size(oneValues) !== _size(twoValues)) {
    return false;
  }
  let result: boolean = true;
  (oneValues || []).forEach((oneValue, index) => {
    if (twoValues[index] !== oneValue) {
      result = false;
    }
  });
  return result;
};

export const _populatedListOrDefault = <T>(items: T[], defaultItems: T[]): T[] =>
  _hasItems(items) ? items : defaultItems;

export const _chunk = <T>(items: T[], chunkSize: number): T[][] => {
  const chunks: T[][] = [];
  let copiedItems: T[] = [...(items || [])];
  const numOfChild = Math.ceil(_size(copiedItems) / chunkSize); // Round up to the nearest integer
  for (let i = 0; i < numOfChild; i++) {
    chunks.push(copiedItems.splice(0, chunkSize));
  }
  return chunks;
};

export const _removeAtIndex = <T>(index: number, items: T[]): T[] => {
  let results = [...items];
  results.splice(index, 1);
  return results;
};

export const _replaceAtIndex = <T>(replacementItem: T, changeIndex: number, items: T[]): T[] => {
  return [...items.slice(0, changeIndex), replacementItem, ...items.slice(changeIndex + 1)];
};

export const _moveListItem = <T>(fromIndex: number, toIndex: number, items: T[]): T[] => {
  let modifiedItems = [...items];
  // account for negative indices
  while (fromIndex < 0) {
    fromIndex += modifiedItems.length;
  }
  while (toIndex < 0) {
    toIndex += modifiedItems.length;
  }
  // if toIndex >= list length, push undefined for each index between
  if (toIndex >= modifiedItems.length) {
    let k = toIndex - modifiedItems.length;
    while (k-- + 1) {
      modifiedItems.push(undefined);
    }
  }
  modifiedItems.splice(toIndex, 0, modifiedItems.splice(fromIndex, 1)[0]);
  return modifiedItems;
};

export const _moveFirstToEnd = <T>(items: T[]): T[] => {
  const first = items.shift();
  return [...items, first];
};

export const _arrayValuesMatch = (one: Primitive[], two: Primitive[]): boolean => {
  if (one && !two) return false;
  if (two && !one) return false;
  if (!one && !two) return true;
  if (_size(one) !== _size(two)) return false;
  let result: boolean = true;
  one.forEach((oneValue, index) => {
    if (two[index] !== oneValue) result = false;
  });
  return result;
};
export const _arrayContainsAll = (array: any[], values: any[]) => {
  for (let i = 0, length = values.length; i < length; i++) {
    if (array.indexOf(values[i]) < 0) {
      return false;
    }
  }

  return true;
};
