import { axios, alertStore, schoolRecordStore } from '../shared/singletons';
import { BaseStore } from '../shared/state/base.store';
import { ListingFee, ListingFeeFormValues, ListingFeeFormErrors, ListingFeeResponse } from './model';
import { API_ENDPOINT } from '../../config/env';
import { SelectOption } from '../shared/common.model';

export class ListingFeeState {
  records: ListingFee[];
  listingFee: ListingFee;
  recordsLoading: boolean;
  listingFeeLoading: boolean;
  isDeleting: boolean;

  formValues: ListingFeeFormValues;
  initialServiceFeeAttributes: any;
  initialPrepAttributes: any;
  initialFlatRateAttributes: any;
  formErrors: ListingFeeFormErrors;
  formErrorAlertVisible: boolean;
  createModalOpen: boolean;
  editModalOpen: boolean;
  formSchoolId: string;

  serviceFees: SelectOption[];

  static create(props: Partial<ListingFeeState>): ListingFeeState {
    const defaults: ListingFeeState = {
      records: [],
      listingFee: {},
      recordsLoading: false,
      listingFeeLoading: false,
      isDeleting: false,

      formValues: null,
      initialServiceFeeAttributes: null,
      initialPrepAttributes: null,
      initialFlatRateAttributes: null,
      formErrors: null,
      formErrorAlertVisible: true,
      createModalOpen: false,
      editModalOpen: false,
      formSchoolId: '0',

      serviceFees: [],
    };
    return Object.assign(new ListingFeeState(), defaults, props || {});
  }
}

export class ListingFeeStore extends BaseStore<ListingFeeState> {
  constructor() {
    super(ListingFeeState.create({}));
  }

  public createListingFee(formValues: ListingFeeFormValues, schoolId: string): void {
    axios({
      method: 'post',
      url: `${API_ENDPOINT}/payers/${schoolId}/fee_schedules.json`,
      data: { fee_schedule: formValues },
      headers: { 'Content-Type': 'application/json' },
    })
      .then(() => {
        schoolRecordStore.fetchRecord(schoolId);
        this.setState({ createModalOpen: false });
      })
      .catch((error) => {
        this.setState({
          formErrors: error.response.data,
          formErrorAlertVisible: !!error.response?.data?.base, // The base errors are not tied to a specific field and displayed at the top of the form
        });
      });
  }

  public updateListingFee(formValues: ListingFeeFormValues, schoolId: string, listingFeeId: string): void {
    axios({
      method: 'put',
      url: `${API_ENDPOINT}/payers/${schoolId}/fee_schedules/${listingFeeId}.json`,
      data: { fee_schedule: formValues },
      headers: { 'Content-Type': 'application/json' },
    })
      .then(() => {
        schoolRecordStore.fetchRecord(schoolId);
        this.setState({ editModalOpen: false });
      })
      .catch(() => {
        this.setState({ editModalOpen: false });
      });
  }

  public deleteListingFee(listingFee: ListingFee): void {
    this.setState({ isDeleting: true });

    axios
      .delete<string, ListingFeeResponse>(`fee_schedules/${listingFee.id}.json`, {
        headers: { 'Content-Type': 'application/json' },
      })
      .then(() => {
        schoolRecordStore.fetchRecord(listingFee.school_id);
        this.setState({ isDeleting: false });
        alertStore.alertSuccess('Fee schedule was successfully deleted.');
      })
      .catch(() => {
        this.setState({ isDeleting: false });
        alertStore.alertError('Something went wrong deleting your fee schedule.');
      });
  }

  public fetchServiceFees(schoolId: string): void {
    axios.get<string, any>(`${API_ENDPOINT}/payers/${schoolId}/fee_schedules/new.json`).then((r) => {
      this.setState({ serviceFees: r?.data?.result?.service_fees });
    });
  }

  public async setFormValues(formValues: any): Promise<void> {
    this.setState({ formValues });
  }

  public setEditModalOpen(isOpen: boolean, listingFeeId: string): void {
    axios({
      method: 'get',
      url: `/fee_schedules/${listingFeeId}/edit.json`,
      headers: { 'Content-Type': 'application/json' },
    }).then((response) => {
      const listingFee = response?.data?.result;

      const formValues = {
        start_date: listingFee.start_time,
        end_date: listingFee.end_time,
      };

      let initialServiceFeeAttributes = {};
      let initialPrepAttributes = {};
      let initialFlatRateAttributes = {};

      listingFee.service_fees.forEach((service_fee: any, index: number) => {
        initialServiceFeeAttributes = {
          ...initialServiceFeeAttributes,
          [index]: {
            amount: service_fee.amount || 0.0,
            prep: service_fee.prep,
            flat_rate: service_fee.flat_rate,
            service_id: service_fee.service_id,
            service_fee_type: service_fee.service_fee_type,
            id: service_fee.id,
          },
        };

        initialPrepAttributes = {
          ...initialPrepAttributes,
          [index]: {
            value: service_fee.prep,
          },
        };

        initialFlatRateAttributes = {
          ...initialFlatRateAttributes,
          [index]: {
            value: service_fee.flat_rate,
          },
        };
      });

      this.setState({
        editModalOpen: isOpen,
        formValues,
        initialServiceFeeAttributes,
        initialPrepAttributes,
        initialFlatRateAttributes,
        listingFee,
      });
    });
  }

  public setCreateModalOpen(isOpen: boolean, school: ListingFee): void {
    const formValues = {
      start_date: '',
      end_date: '',
    };

    this.setState({ createModalOpen: isOpen, formSchoolId: school.id, formValues });
  }
}
