import React, { FunctionComponent, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import SchoolDetail from '../components/SchoolDetail';
import { useStoreObservable } from '../../shared/state/useStoreObservable.hook';
import { schoolRecordStore } from '../../shared/singletons';
import { SchoolRouteParams } from '../model';

export type SchoolDetailPageProps = Record<string, unknown>;

const SchoolDetailPage: FunctionComponent<SchoolDetailPageProps> = () => {
  const schoolState = useStoreObservable(schoolRecordStore);
  const { school, schoolLoading } = schoolState;
  const { schoolId, tab } = useParams<SchoolRouteParams>();

  useEffect(() => {
    schoolRecordStore.fetchRecord(schoolId);
  }, [schoolId]);

  return <SchoolDetail school={school} tab={tab} schoolLoading={schoolLoading} />;
};

export default SchoolDetailPage;
