import React, { memo } from 'react';
import styled from 'styled-components';
import { Cell } from './Cell';
import { useTableContext } from './DataTableContext';
import NativeSortIcon from '../icons/NativeSortIcon';
import { IDataTableColumn } from 'react-data-table-component';

export interface TableColStyleProps {
  column: any;
  className: any;
  head: any;
}

const TableColStyle = styled(Cell)<any>`
  ${(props) => props.column.button && 'text-align: center'};
`;

export interface ColumnSortableProps {
  sortActive: any;
  column: IDataTableColumn<any>;
}

const ColumnSortable = styled.div<ColumnSortableProps>`
  display: inline-flex;
  align-items: center;
  height: 100%;
  line-height: 1;
  user-select: none;
  ${(props) => (props.sortActive ? props.theme.headCells.activeSortStyle : props.theme.headCells.inactiveSortStyle)};

  span.__rdt_custom_sort_icon__ {
    i,
    svg {
      ${(props) => (props.sortActive ? 'opacity: 1' : 'opacity: 1')};
      color: inherit;
      font-size: 18px !important;
      height: 18px !important;
      width: 18px !important;
      backface-visibility: hidden;
      transform-style: preserve-3d;
      transition-duration: 125ms;
      transition-property: transform;
    }

    &.asc i,
    &.asc svg {
      transform: rotate(180deg);
    }
  }

  &:hover {
    ${({ column }) => column.sortable && 'cursor: pointer'};
    ${({ column, theme }) => column.sortable && theme.headCells.activeStyle};

    span,
    span.__rdt_custom_sort_icon__ * {
      ${({ sortActive, column }) => !sortActive && column.sortable && 'opacity: 1'};
    }
  }
`;

export interface TableColProps {
  column: IDataTableColumn<any>;
  sortIcon: string;
}

const TableCol: React.FunctionComponent<any> = memo<TableColProps>((props) => {
  const { column, sortIcon } = props;
  const {
    dispatch,
    pagination,
    paginationServer,
    sortColumn,
    sortDirection,
    sortServer,
    selectableRowsVisibleOnly,
    persistSelectedOnSort,
  } = useTableContext();

  if (column.omit) {
    return null;
  }

  const handleSortChange = () => {
    if (column.sortable) {
      let direction = sortDirection;
      // change sort direction only if sortColumn (currently selected column) is === the newly clicked column
      // otherwise, retain sort direction if the column is switched
      if (sortColumn === column.selector) {
        direction = sortDirection === 'asc' ? 'desc' : 'asc';
      }

      dispatch({
        type: 'SORT_CHANGE',
        sortDirection: direction,
        sortColumn: column.selector,
        sortServer,
        selectedColumn: column,
        pagination,
        paginationServer,
        visibleOnly: selectableRowsVisibleOnly,
        persistSelectedOnSort,
      });
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSortChange();
    }
  };

  const renderNativeSortIcon = (sortActive) => (
    <NativeSortIcon column={column} sortActive={sortActive} sortDirection={sortDirection} />
  );

  const renderCustomSortIcon = () => (
    <span className={[sortDirection, '__rdt_custom_sort_icon__'].join(' ')}>{sortIcon}</span>
  );

  const sortActive = column.sortable && sortColumn === column.selector;
  const nativeSortIconLeft = column.sortable && !sortIcon && !column.right;
  const nativeSortIconRight = column.sortable && !sortIcon && column.right;
  const customSortIconLeft = column.sortable && sortIcon && !column.right;
  const customSortIconRight = column.sortable && sortIcon && column.right;

  return (
    <TableColStyle
      className="rdt_TableCol"
      column={column} // required by Cell.js
      head
    >
      {column.name && (
        <ColumnSortable
          id={`column-${column.selector}`}
          role="button"
          aria-pressed={sortActive}
          tabIndex={0}
          className="rdt_TableCol_Sortable"
          onClick={handleSortChange}
          onKeyPress={handleKeyPress}
          sortActive={sortActive}
          column={column}
        >
          {customSortIconRight && renderCustomSortIcon()}
          {nativeSortIconRight && renderNativeSortIcon(sortActive)}
          <div>{column.name}</div>
          {customSortIconLeft && renderCustomSortIcon()}
          {nativeSortIconLeft && renderNativeSortIcon(sortActive)}
        </ColumnSortable>
      )}
    </TableColStyle>
  );
});

/*TableCol.propTypes = {
  column: PropTypes.object.isRequired,
  sortIcon: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.object,
  ]).isRequired,
};*/

export default TableCol;
