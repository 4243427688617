import React, { useState, Dispatch, SetStateAction } from 'react';
import { axios } from '../../shared/singletons';
import { Renewal, RenewalFormValues, RenewalResponse } from '../model';
import { today } from '../../shared/utils/date.utils';

interface RenewalsContextInterface {
  fetchIepRenewals?: (params?: string, page?: number, per?: number) => Promise<void>;
  iepRenewals?: Renewal[];
  totalIepRenewals?: number;
  iepRenewalsLoading?: boolean;

  fetchRevaluationRenewals?: (params?: string, page?: number, per?: number) => Promise<void>;
  revaluationRenewals?: Renewal[];
  totalRevaluationRenewals?: number;
  revaluationRenewalsLoading?: boolean;

  filterValues?: Record<string, string>;
  setFilterValues?: Dispatch<SetStateAction<Record<string, string>>>;

  createRenewal?: (studentId?: string, formValues?: RenewalFormValues) => Promise<void>;
  updateRenewal?: (studentId?: string, renewalId?: string, formValues?: RenewalFormValues) => Promise<void>;
  editRenewalModalOpen?: boolean;
  setEditRenewalModalOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  renewalToEdit?: Renewal;
  setRenewalToEdit?: React.Dispatch<React.SetStateAction<Renewal>>;
  formattedFilterParams?: any;

  handlePrepareIEPDownload?: () => Promise<void>;
  iepRenewalsCSV?: Renewal[];
  submittingDownload?: boolean;

  iepDownloadError?: string;
  rrDownloadError?: string;

  handlePrepareRRDownload?: () => Promise<void>;
  rrRenewalsCSV?: Renewal[];
}

const RenewalsContext = React.createContext<RenewalsContextInterface>({});

const RenewalsContextConsumer = RenewalsContext.Consumer;
const RenewalsContextProvider = ({ children }) => {
  const [iepRenewals, setIepRenewals] = useState<Renewal[]>([]);
  const [totalIepRenewals, setTotalIepRenewals] = useState(0);
  const [iepRenewalsLoading, setIepRenewalsLoading] = useState(false);
  const [submittingDownload, setSubmittingDownload] = useState(false);
  const [iepDownloadError, setIEPDownloadError] = useState('');
  const [rrDownloadError, setRRDownloadError] = useState('');

  const [revaluationRenewals, setRevaluationRenewals] = useState<Renewal[]>([]);
  const [totalRevaluationRenewals, setTotalRevaluationRenewals] = useState(0);
  const [revaluationRenewalsLoading, setRevaluationRenewalsLoading] = useState(false);
  const [editRenewalModalOpen, setEditRenewalModalOpen] = useState(false);
  const [renewalToEdit, setRenewalToEdit] = useState<Renewal>();
  const [iepRenewalsCSV, setIepRenewalsCSV] = useState<Renewal[]>();
  const [rrRenewalsCSV, setRrRenewalsCSV] = useState<Renewal[]>();

  const [filterValues, setFilterValues] = useState<Record<string, string>>({ 'ransack[active_eq]': 'true' });

  const fetchIepRenewals = async (params = formattedFilterParams(), page = 1, per = 10) => {
    try {
      saveAppointmentFilters();
      setIepRenewalsLoading(true);

      const result = await axios.get(`renewals/ieps?${params || '&'}page=${page}&per=${per}`);
      const records = result?.data?.result ?? [];
      setTotalIepRenewals(result?.data?.total_records);
      setIepRenewals(records);
    } catch (error) {
      console.error(error);
    } finally {
      setIepRenewalsLoading(false);
    }
  };

  const fetchRevaluationRenewals = async (params = formattedFilterParams(), page = 1, per = 10) => {
    try {
      saveAppointmentFilters();
      setRevaluationRenewalsLoading(true);

      const result = await axios.get(`renewals/revaluations?${params || '&'}page=${page}&per=${per}`);
      const records = result?.data?.result ?? [];
      setTotalRevaluationRenewals(result?.data?.total_records);
      setRevaluationRenewals(records);
    } catch (error) {
      console.error(error);
    } finally {
      setRevaluationRenewalsLoading(false);
    }
  };

  const handlePrepareIEPDownload = async () => {
    try {
      setSubmittingDownload(true);
      const result = await axios.get(`renewals/ieps_download/?${formattedFilterParams()}`);
      setIepRenewalsCSV(result?.data?.renewals_csv);
      setIEPDownloadError('');
    } catch (error) {
      setIEPDownloadError('An error occurred while preparing the download.');
    } finally {
      setSubmittingDownload(false);
    }
  };

  const handlePrepareRRDownload = async () => {
    try {
      setSubmittingDownload(true);
      const result = await axios.get(`renewals/revaluations_download/?${formattedFilterParams()}`);
      setRrRenewalsCSV(result?.data?.renewals_csv);
      setRRDownloadError('');
    } catch (error) {
      setRRDownloadError('An error occurred while preparing the download.');
    } finally {
      setSubmittingDownload(false);
    }
  };

  const createRenewal = async (studentId?: string, formValues?: RenewalFormValues) => {
    axios.post<string, RenewalResponse>(`patients/${studentId}/renewals`, {
      renewal: formValues,
      headers: { 'Content-Type': 'application/json' },
    });
  };

  const updateRenewal = async (studentId?: string, renewalId?: string, formValues?: RenewalFormValues) => {
    axios.put<string, RenewalResponse>(`patients/${studentId}/renewals/${renewalId}`, {
      renewal: formValues,
      headers: { 'Content-Type': 'application/json' },
    });
  };

  const formattedFilterParams = (values = filterValues) => {
    return Object?.keys(values)?.reduce((params, key, _index: number) => `${params}${key}=${values[key]}&` || '', '');
  };

  const saveAppointmentFilters = () => {
    const savedRenewalstate = {
      filterValues: {
        'ransack[patient_last_name_cont]': filterValues['ransack[patient_last_name_cont]'],
        'ransack[patient_slug_cont]': filterValues['ransack[patient_slug_cont]'],
        'ransack[payer_id_eq]': filterValues['ransack[payer_id_eq]'],
        'ransack[status_eq]': filterValues['ransack[status_eq]'],
        'ransack[start_time_gteq]': filterValues['ransack[start_time_gteq]'] || today(),
        'ransack[start_time_lteq]': filterValues['ransack[start_time_lteq]'] || today(),
        'ransack[service_id_eq]': filterValues['ransack[service_id_eq]'],
        'ransack[user_id_eq]': filterValues['ransack[user_id_eq]'],
        'ransack[location_id_eq]': filterValues['ransack[location_id_eq]'],
        'ransack[blackout_eq]': filterValues['ransack[blackout_eq]'],
      },
      loadListPageRecords: true,
    };

    sessionStorage.setItem('renewalstate', JSON.stringify(savedRenewalstate));
  };

  return (
    <RenewalsContext.Provider
      value={{
        fetchIepRenewals,
        iepRenewals,
        totalIepRenewals,
        iepRenewalsLoading,

        fetchRevaluationRenewals,
        revaluationRenewals,
        totalRevaluationRenewals,
        revaluationRenewalsLoading,

        setFilterValues,
        filterValues,

        createRenewal,
        updateRenewal,

        editRenewalModalOpen,
        setEditRenewalModalOpen,

        renewalToEdit,
        setRenewalToEdit,
        formattedFilterParams,

        handlePrepareIEPDownload,
        iepRenewalsCSV,
        handlePrepareRRDownload,
        rrRenewalsCSV,

        submittingDownload,
        iepDownloadError,
        rrDownloadError,
      }}
    >
      {children}
    </RenewalsContext.Provider>
  );
};

export { RenewalsContextProvider, RenewalsContextConsumer, RenewalsContext };
