import React, { useContext } from 'react';
import ContextFilterBar from '../../shared/components/ContextFilterBar';
import { UserContext } from '../../auth/contexts/userContext';
import { UserTicketsContext } from '../contexts/UserTicketsContext';
import { FilterProps } from '../../shared/components/FilterBar';

type Props = {};

const statusOptions = [
  { value: 'pending', label: 'Pending' },
  { value: 'approved', label: 'Approved' },
  { value: 'rejected', label: 'Rejected' },
  { value: 'no_action', label: 'No Action' },
];

const viewedOptions = [
  { value: 'false', label: 'Not Viewed' },
  { value: 'true', label: 'Viewed' },
];

export const serviceProviderTicketFilters: FilterProps[] = [
  {
    selector: 'ransack[status_eq]',
    label: 'Status',
    type: 'select',
    options: statusOptions,
  },
  {
    selector: 'ransack[viewed_eq]',
    label: 'Viewed',
    type: 'select',
    options: viewedOptions,
  },
  {
    selector: 'ransack[created_at_gteq]',
    label: 'Created From',
    type: 'date',
  },
  {
    selector: 'ransack[created_at_lteq]',
    label: 'Created To',
    type: 'date',
  },
];

function UserTicketsFilterBar(props: Props) {
  const { currentUserHasRole } = useContext(UserContext);
  const {
    filterValues,
    setFilterValues,
    fetchUserTickets,
    fetchReferralTickets,
    schoolOptions,
    providerOptions,
  } = React.useContext(UserTicketsContext);

  const adminTicketFilters: FilterProps[] = [
    {
      selector: 'ransack[status_eq]',
      label: 'Status',
      type: 'select',
      options: statusOptions,
    },
    {
      selector: 'ransack[payer_id_eq]',
      label: 'School',
      type: 'select',
      options: schoolOptions,
    },
    {
      selector: 'ransack[user_id_eq]',
      label: 'Provider',
      type: 'select',
      options: providerOptions,
    },
    {
      selector: 'ransack[created_at_gteq]',
      label: 'Created From',
      type: 'date',
    },
    {
      selector: 'ransack[created_at_lteq]',
      label: 'Created To',
      type: 'date',
    },
  ];

  return (
    <ContextFilterBar
      filters={currentUserHasRole('admin') ? adminTicketFilters : serviceProviderTicketFilters}
      filterValues={filterValues}
      setFilterValues={setFilterValues}
      onFilter={(params) => {
        fetchUserTickets(params);
        fetchReferralTickets(params);
      }}
    />
  );
}

export default UserTicketsFilterBar;
