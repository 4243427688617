import React, { FC, useState, useEffect, useContext, ChangeEvent } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TabLabel from '../../shared/components/TabLabel';
import TabPanel from '../../shared/components/TabPanel';
import TabLabels from '../../shared/components/TabLabels';

import { StudentRouteParams } from '../model';
import { ServiceFormOptionsContext } from '../../services/contexts/ServiceFormOptionsContext';
import { UserContext } from '../../auth/contexts/userContext';

import LoadingSpinner from '../../shared/components/LoadingSpinner';
import StudentDetailsTab from '../components/StudentDetailsTab';
import StudentServicesTab from '../components/StudentServicesTab';
import StudentAppointmentsTab from '../components/StudentAppointmentsTab';
import StudentRenewalsTab from '../components/StudentRenewalsTab';
import StudentGoalsTab from '../components/StudentGoalsTab';
import StudentCustomActions from '../components/StudentCustomActions';

// TODO: We've managed to separate fetching for Appointments and Services.  Still need to do the same for Goals and Renewals.

import { Student } from '../model';

import axios from '../../shared/utils/axios.utils';

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const potentialObjects = [
  {
    value: 'profile',
    component: StudentDetailsTab,
    roles: ['admin', 'supervisor', 'service_provider', 'school_admin', 'accountant', 'services_coordinator'],
  },
  {
    value: 'services',
    component: StudentServicesTab,
    roles: ['admin', 'supervisor', 'service_provider', 'accountant', 'services_coordinator'],
    // Not: ['school_admin']
  },
  {
    value: 'appointments',
    component: StudentAppointmentsTab,
    roles: ['admin', 'supervisor', 'service_provider', 'accountant', 'services_coordinator'],
    // Not: ['school_admin']
  },
  {
    value: 'renewals',
    component: StudentRenewalsTab,
    roles: ['admin', 'supervisor', 'service_provider'],
    // Not: ['school_admin', 'accountant', 'services_coordinator']
  },
  {
    value: 'goals',
    component: StudentGoalsTab,
    roles: ['admin', 'supervisor', 'service_provider'],
    // Not: ['school_admin', 'accountant', 'services_coordinator']
  },
  {
    value: 'actions',
    component: StudentCustomActions,
    roles: ['admin'], // only
  },
];

const capitalize = (s: string) => s.charAt(0).toUpperCase() + s.slice(1);

const StudentDetailPage: FC = () => {
  let history = useHistory();

  let { studentId, tab } = useParams<StudentRouteParams>();

  let { currentUserHasRole } = useContext(UserContext);
  let { fetchChoices } = useContext(ServiceFormOptionsContext);

  let [value, setValue] = useState(0);
  let [student, setStudent] = useState<Student>(null);

  const tabIndexMap = potentialObjects.reduce((acc, obj, index) => {
    acc[obj.value] = index;
    return acc;
  }, {} as Record<string, number>);

  const handleChange = (_event: ChangeEvent, newValue: number) => {
    setValue(newValue);
    history.push(`/students/${studentId}/${potentialObjects[newValue].value}`);
  };

  const fetchStudent = async () => {
    try {
      let response = await axios.get(`/patients/${studentId}`);
      setStudent(response.data.result);
    } catch (error) {
      console.error(error);
    }
  };

  /* eslint-disable */
  useEffect(() => {
    fetchChoices();
    fetchStudent();
    if (tab) {
      setValue(tabIndexMap[tab] || 0);
    }
  }, [studentId, tab]);
  /* eslint-enable */

  if (!student?.id) {
    return <LoadingSpinner />;
  }

  return (
    <div>
      <Grid justify="space-between" container spacing={3}>
        <Grid item xs={8}>
          <Typography variant="h1" component="h1">
            {`${student?.first_name} ${student?.last_name}`}
          </Typography>
        </Grid>
        <Grid item>
          {currentUserHasRole('admin') && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => history.push(`/students/${student?.id}/add_services`)}
            >
              Create New Service +
            </Button>
          )}
        </Grid>
      </Grid>
      <div>
        <TabLabels variant="scrollable" value={value} onChange={handleChange} aria-label="student tabs">
          {potentialObjects.map((obj, index) => {
            if (obj.roles.some((role) => currentUserHasRole(role))) {
              return <TabLabel key={index} label={capitalize(obj.value)} {...a11yProps(index)} />;
            }
            return null; // Return null when condition is not met
          })}
        </TabLabels>
        {potentialObjects.map((obj, index) => {
          if (obj.roles.some((role) => currentUserHasRole(role))) {
            const Component = obj.component;
            return (
              <TabPanel key={index} value={value} index={index}>
                <Component student={student} />
              </TabPanel>
            );
          }
          return null;
        })}
      </div>
    </div>
  );
};

export default StudentDetailPage;
