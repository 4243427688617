import React, { FunctionComponent, useState } from 'react';
import { CSVLink } from 'react-csv';
import { Box, Button } from '@material-ui/core';
import DataTable from '../../shared/components/DataTable';
import { IDataTableColumn } from '../../shared/components/DataTable/DataTable/model';
import { axios } from '../../shared/singletons';

const appointmentColumns: IDataTableColumn[] = [
  {
    name: 'TIMELINE ID',
    selector: 'slug',
    sortable: true,
  },
  {
    name: 'STUDENT NAME',
    selector: 'student_name',
    sortable: true,
  },
  {
    name: 'SCHOOL',
    selector: 'school_abbreviation',
    sortable: true,
  },
  {
    name: 'LOCATION',
    selector: 'location_name',
    sortable: true,
  },
  {
    name: 'NO SHOW APPOINTMENTS',
    selector: 'no_show_appointments',
    sortable: true,
  },
  {
    name: 'LATE CANCEL APPOINTMENTS',
    selector: 'late_cancel_appointments',
    sortable: true,
    grow: 1.2,
  },
];

const AttendanceReport: FunctionComponent = () => {
  const [downloadDisabled, setDownloadDisabled] = React.useState(true);
  const [attendanceReport, setAttendanceReport] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const generateAttendanceReport = async () => {
    setIsLoading(true);
    const response = await axios(`reports/attendance.json}`);
    setAttendanceReport(response?.data?.result);
    setDownloadDisabled(false);
    setIsLoading(false);
  };

  return (
    <Box>
      <br />
      <br />
      <Button variant="contained" color="primary" onClick={generateAttendanceReport}>
        {isLoading ? 'Generating...' : 'Generate Report'}
      </Button>
      <CSVLink
        data={attendanceReport}
        filename={`attendance_report.csv`}
        className={`MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary ${
          downloadDisabled ? 'Mui-disabled' : ''
        }`}
        style={{ marginLeft: '8px' }}
      >
        Download Report
      </CSVLink>
      <Box my={4}>
        <DataTable title="" columns={appointmentColumns} noHeader data={attendanceReport} />
      </Box>
    </Box>
  );
};

export default AttendanceReport;
