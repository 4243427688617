import React, { useState, useContext, useEffect, Dispatch, SetStateAction } from 'react';

import Grid from '@material-ui/core/Grid';
import ConfirmCancelModal from '../../../shared/components/modal/ConfirmCancelModal';

import SelectInput from '../../../shared/components/form/SelectInput';

import { AppointmentsContext } from '../../contexts/AppointmentsContext';
import { Appointment } from '../../model';

import axios from '../../../shared/utils/axios.utils';
import { useStyles } from '../../../shared/style/siteWideStyles';

interface AssignAppointmentToInvoiceModalProps {
  setAppointments: Dispatch<SetStateAction<Appointment[]>>;
  fetchInvoice: () => void;
}

const AssignAppointmentToInvoiceModal: React.FC<AssignAppointmentToInvoiceModalProps> = (props) => {
  let classes = useStyles();

  let { editModalOpen, setEditModalOpen, appointmentToEdit } = useContext(AppointmentsContext);
  let { setAppointments, fetchInvoice } = props;

  let [invoiceOptions, setInvoiceOptions] = useState([]);
  let [newInvoiceId, setNewnewInvoiceId] = useState('');

  let fetchInvoiceOptions = async () => {
    let response = await axios.get(`/patients/0/appointments/${appointmentToEdit}/invoice_options`);
    setInvoiceOptions(response.data.invoice_options);
  };

  let handleConfirm = async () => {
    setEditModalOpen(false);

    let response = await axios.put(`/patients/0/appointments/${appointmentToEdit}/assign`, {
      invoice_id: newInvoiceId,
    });

    setAppointments(response.data.result);
    fetchInvoice();
  };

  useEffect(() => {
    if (appointmentToEdit) fetchInvoiceOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointmentToEdit]);

  return (
    <ConfirmCancelModal
      isOpen={editModalOpen}
      title="Assign Appointment to Invoice"
      confirmLabel="Assign"
      openStatusChanged={(isOpen) => setEditModalOpen(isOpen)}
      onConfirm={handleConfirm}
      width={'800px'}
      height={'400px'}
    >
      <Grid container>
        <Grid item xs={12}>
          <SelectInput
            label="Assign Invoice"
            choices={invoiceOptions}
            styleOverrides={classes.textInput}
            valueChanged={(value: string) => setNewnewInvoiceId(value)}
            value={newInvoiceId}
          />
        </Grid>
      </Grid>
    </ConfirmCancelModal>
  );
};

export default AssignAppointmentToInvoiceModal;
