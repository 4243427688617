import React from 'react';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import { XYPlot, Hint, VerticalGridLines, MarkSeries, makeWidthFlexible } from 'react-vis';
import TimelineServiceTooltip from './TimelineServiceTooltip';
import TimelineRenewalTooltip from './TimelineRenewalTooltip';
import TimelineVisualisationBorders from './TimelineVisualisationBorders';
import { dashboardStore } from '../../shared/singletons';
import { useStoreObservable } from '../../shared/state/useStoreObservable.hook';

const useStyles = makeStyles({
  timelineSpacer: {
    height: 22,
    marginTop: 85,
    width: 8,
    background: '#F4F4F4',
    position: 'absolute',
    left: '61.2%',
    borderLeft: '2px solid black',
    borderRight: '2px solid black',
    zIndex: 1000,
  },
});

const TimelineVisualisation: React.FC = () => {
  const { timelineData } = useStoreObservable(dashboardStore);
  const [selectedServices, setSelectedServices] = React.useState<any>({});
  const [selectedRenewals, setSelectedRenewals] = React.useState<any>({});
  const FlexibleXYPlot = makeWidthFlexible(XYPlot);
  const classes = useStyles();

  const findServicesByTimeline = (timelineValue: number | string | Date) => {
    const allServices = timelineData.redServices
      .concat(timelineData.yellowServices)
      .concat(timelineData.greenServices)
      .concat(timelineData.duplicateRedServices)
      .concat(timelineData.duplicateYellowServices)
      .concat(timelineData.duplicateGreenServices);

    return allServices.filter((service) => service.x === timelineValue);
  };

  const findRenewalsByTimeline = (timelineValue: number | string | Date) => {
    const allRenewals = timelineData.redRenewals
      .concat(timelineData.yellowRenewals)
      .concat(timelineData.greenRenewals)
      .concat(timelineData.duplicateRedRenewals)
      .concat(timelineData.duplicateYellowRenewals)
      .concat(timelineData.duplicateGreenRenewals);

    return allRenewals.filter((renewal) => renewal.x === timelineValue);
  };

  return (
    <>
      <Grid container>
        <Grid item xs={7} style={{ zIndex: 999 }}>
          <Typography variant="h1" component="h1" align="center">
            Referrals
          </Typography>
          <br />
          <div style={{ position: 'relative', top: 15 }}>
            <FlexibleXYPlot height={50} xDomain={[2, 60]}>
              <VerticalGridLines />
              <MarkSeries
                color="green"
                data={timelineData.greenServices}
                onValueClick={(val) => {
                  setSelectedServices(findServicesByTimeline(val.x));
                }}
              />
              <MarkSeries
                color="yellow"
                data={timelineData.yellowServices}
                onValueClick={(val) => {
                  setSelectedServices(findServicesByTimeline(val.x));
                }}
              />
              <MarkSeries
                color="red"
                data={timelineData.redServices}
                onValueClick={(val) => {
                  setSelectedServices(findServicesByTimeline(val.x));
                }}
              />
              <MarkSeries
                color="green"
                stroke="#424E7A"
                strokeWidth={2}
                data={timelineData.duplicateGreenServices}
                onValueClick={(val) => {
                  setSelectedServices(findServicesByTimeline(val.x));
                }}
              />
              <MarkSeries
                color="yellow"
                stroke="#424E7A"
                strokeWidth={2}
                data={timelineData.duplicateYellowServices}
                onValueClick={(val) => {
                  setSelectedServices(findServicesByTimeline(val.x));
                }}
              />
              <MarkSeries
                color="red"
                stroke="#424E7A"
                strokeWidth={2}
                data={timelineData.duplicateRedServices}
                onValueClick={(val) => {
                  setSelectedServices(findServicesByTimeline(val.x));
                }}
              />
              {(selectedServices?.[0]?.x || selectedServices?.[0]?.x === 0) && (
                <Hint value={selectedServices}>
                  <TimelineServiceTooltip allServices={selectedServices} setAllServices={setSelectedServices} />
                </Hint>
              )}
            </FlexibleXYPlot>
          </div>
        </Grid>
        <div className={classes.timelineSpacer} />
        <Grid item xs={5} style={{ zIndex: 999 }}>
          <Typography variant="h1" component="h1" align="center">
            Renewals
          </Typography>
          <br />
          <div style={{ position: 'relative', top: 15 }}>
            <FlexibleXYPlot height={50} xDomain={[58, 0]}>
              <MarkSeries
                color="green"
                data={timelineData.greenRenewals}
                onValueClick={(val) => {
                  setSelectedRenewals(findRenewalsByTimeline(val.x));
                }}
              />
              <MarkSeries
                color="yellow"
                data={timelineData.yellowRenewals}
                onValueClick={(val) => {
                  setSelectedRenewals(findRenewalsByTimeline(val.x));
                }}
              />
              <MarkSeries
                color="red"
                data={timelineData.redRenewals}
                onValueClick={(val) => {
                  setSelectedRenewals(findRenewalsByTimeline(val.x));
                }}
              />
              <MarkSeries
                color="green"
                stroke="#424E7A"
                strokeWidth={2}
                data={timelineData.duplicateGreenRenewals}
                onValueClick={(val) => {
                  setSelectedRenewals(findRenewalsByTimeline(val.x));
                }}
              />
              <MarkSeries
                color="yellow"
                stroke="#424E7A"
                strokeWidth={2}
                data={timelineData.duplicateYellowRenewals}
                onValueClick={(val) => {
                  setSelectedRenewals(findRenewalsByTimeline(val.x));
                }}
              />
              <MarkSeries
                color="red"
                stroke="#424E7A"
                strokeWidth={2}
                data={timelineData.duplicateRedRenewals}
                onValueClick={(val) => {
                  setSelectedRenewals(findRenewalsByTimeline(val.x));
                }}
              />
              {(selectedRenewals?.[0]?.x || selectedRenewals?.[0]?.x === 0) && (
                <Hint value={selectedRenewals}>
                  <TimelineRenewalTooltip allRenewals={selectedRenewals} setAllRenewals={setSelectedRenewals} />
                </Hint>
              )}
            </FlexibleXYPlot>
          </div>
        </Grid>
      </Grid>
      <TimelineVisualisationBorders />
    </>
  );
};

export default TimelineVisualisation;
