import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import Breadcrumbs, { BreadcrumbLink } from '../../shared/components/Breadcrumbs';
import AttachmentsList from '../../attachments/components/AttachmentsList';
import { schoolRecordStore } from '../../shared/singletons';
import { useStoreObservable } from '../../shared/state/useStoreObservable.hook';
import { UserContext } from '../../auth/contexts/userContext';

const links: BreadcrumbLink[] = [
  {
    href: '/',
    text: 'HOME',
  },
  {
    href: '/help',
    text: 'HELP',
  },
  {
    href: '/documents',
    text: 'MY DOCUMENTS',
  },
];

const MyDocuments: React.FC = () => {
  const schoolState = useStoreObservable(schoolRecordStore);
  const { user } = React.useContext(UserContext);
  const { school } = schoolState;

  React.useEffect(() => {
    schoolRecordStore.fetchRecord(user?.school_id);
  }, [user]);

  return (
    <Box>
      <Grid justify="space-between" container spacing={3}>
        <Grid item>
          <Typography variant="h1" component="h1">
            My Documents
          </Typography>
          <Breadcrumbs links={links} />
          <br />
        </Grid>
      </Grid>
      <br />
      <Grid justify="space-between" container spacing={1}>
        <Grid item xs={12}>
          <AttachmentsList
            title="My Documents"
            schoolUser
            attachments={school.school_documents}
            showAddAttachment={false}
            isLoading={false}
            attachmentableType="payers"
            attachmentableId="0"
            afterAction={() => null}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default MyDocuments;
