import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import ContextsProvider from './contexts';
import App from './App';

import theme from './theme';
import './index.css';

const ThemeLoader: React.FC = () => {
  return (
    theme && (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <BrowserRouter forceRefresh>
          <ContextsProvider>
            <App />
          </ContextsProvider>
        </BrowserRouter>
      </ThemeProvider>
    )
  );
};

export default ThemeLoader;
