import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FilledInput, FormControl, FormHelperText, InputLabel, Select } from '@material-ui/core';
import { InputElementEvent, SelectOption } from '../../common.model';
import MenuItem from '@material-ui/core/MenuItem';
import TranslatedText from '../TranslatedText';

interface SelectInputProps {
  value?: string;
  label: string;
  onChange?: (element: React.ChangeEvent) => void;
  valueChanged?: (value: string) => void;
  styleOverrides?: string;
  errorMessage?: string;
  isFilled?: boolean;
  type?: string;
  choices: SelectOption[];
  defaultChoice?: SelectOption;
  textColor?: string;
  disabled?: boolean;
  testId?: string;
}

const useStyles = makeStyles((_theme) => ({
  textField: {
    height: '30px',
  },
  helperText: {
    fontSize: '12px',
  },
}));

const SelectInput: React.FC<SelectInputProps> = (props) => {
  const {
    label,
    onChange,
    valueChanged,
    styleOverrides,
    errorMessage,
    value = '',
    choices,
    defaultChoice,
    textColor,
    disabled = false,
    testId,
  } = props;

  const classes = useStyles();

  const handleChange = (event: InputElementEvent): void => {
    if (onChange) {
      onChange(event);
    }
    if (valueChanged) {
      valueChanged(event.target.value);
    }
  };

  const createMenuItem = (choice: SelectOption) =>
    choice ? (
      <MenuItem value={choice.value} key={choice.value}>
        <TranslatedText textColor={choice.value === value ? textColor : 'inherit'} tKey={choice.label} />
      </MenuItem>
    ) : null;

  const defaultMenuItem = createMenuItem(defaultChoice);
  const menuItemChoices = (choices || []).map((choice) => createMenuItem(choice));

  return (
    <FormControl
      variant="filled"
      error={errorMessage?.length > 0}
      className={`${styleOverrides} ${classes.textField}`}
      data-cy={testId}
    >
      <InputLabel htmlFor="{label}">{label}</InputLabel>
      <Select value={value} input={<FilledInput onChange={handleChange} disabled={disabled} />} data-cy={testId}>
        {defaultMenuItem}
        {menuItemChoices}
      </Select>
      {errorMessage && <FormHelperText className={classes.helperText}>{`${label} ${errorMessage}`}</FormHelperText>}
    </FormControl>
  );
};

export default SelectInput;
