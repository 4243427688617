import React, { useState, useEffect, FunctionComponent } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {
  Box,
  Card,
  CardContent,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@material-ui/core';
import TextInput from '../../shared/components/form/TextInput';
import { axios } from '../../shared/singletons';
import { useStyles } from '../../shared/style/siteWideStyles';

const PasswordUpdateForm: FunctionComponent = () => {
  const classes = useStyles();
  const history = useHistory();

  const { token } = useParams<Record<string, string>>();

  const [newPassword, setNewPassword] = useState('');
  const [successfulUpdate, setSuccessfulUpdate] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [disableUpdateButton, setDisableUpdateButton] = useState(true);

  useEffect(() => {
    if (newPassword.length >= 8) {
      setDisableUpdateButton(false);
      setErrorMessage('');
    } else {
      setDisableUpdateButton(true);
      setErrorMessage('Password must be at least 8 characters long');
    }
  }, [newPassword]);

  const handlePasswordUpdate = () => {
    axios
      .put(`/passwords/0`, { reset_password_token: token, new_password: newPassword })
      .then(() => {
        setSuccessfulUpdate(true);
      })
      .catch((error) => {
        setSuccessfulUpdate(false);
        if (error.response && error.response.data && error.response.data.error) {
          setErrorMessage(error.response.data.error);
        }
      });
  };

  return (
    <Box>
      <Dialog
        open={successfulUpdate === true}
        onClose={() => history.push(`/dashboard`)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Success!</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">Password updated</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => history.push(`/login`)}>Login Page</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={successfulUpdate === false}
        onClose={() => history.push(`/login`)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Something went wrong</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{errorMessage || 'Please try again'}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => history.push(`/login`)}>Reset Password Page</Button>
        </DialogActions>
      </Dialog>
      <Card>
        <CardContent>
          <>
            <p>Please enter your new password</p>
            <TextInput
              styleOverrides={classes.textInput}
              label="New Password"
              value={newPassword}
              valueChanged={(value: string) => setNewPassword(value)}
            />
            <Grid container spacing={3}>
              <Grid item>
                <p style={{ color: 'red' }}>{errorMessage}</p>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handlePasswordUpdate()}
                  disabled={disableUpdateButton}
                >
                  Change Password
                </Button>
              </Grid>
            </Grid>
          </>
        </CardContent>
      </Card>
    </Box>
  );
};

export default PasswordUpdateForm;
