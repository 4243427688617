import React, { FunctionComponent, useEffect } from 'react';
import UserRolesList from '../components/UserRolesList';
import { useStoreObservable } from '../../../shared/state/useStoreObservable.hook';
import { UserRoleState } from '../userRole.store';
import { userRoleStore } from '../../../shared/singletons';

export type UserRolesPageProps = Record<string, unknown>;

const UserRolesPage: FunctionComponent<UserRolesPageProps> = () => {
  const userRoleState = useStoreObservable<UserRoleState>(userRoleStore);
  const { records, isLoading } = userRoleState;

  useEffect(() => {
    userRoleStore.fetchRecords();
  }, []);

  return <UserRolesList records={records} isLoading={isLoading} />;
};

export default UserRolesPage;
