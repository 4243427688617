import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { CircularProgress, Box } from '@mui/material';
import { useHistory, useParams } from 'react-router-dom';
import { StudentsContext } from '../../students/contexts/StudentsContext';
import StudentForm from '../components/StudentForm';

const EditStudentInfoPage: React.FunctionComponent = () => {
  const { studentId } = useParams<Record<string, string>>();
  const history = useHistory();
  const [studentFetched, setStudentFetched] = React.useState(false);
  const { fetchStudent, student, studentsLoading } = React.useContext(StudentsContext);

  React.useEffect(() => {
    if (!studentFetched) {
      fetchStudent(studentId);
      setStudentFetched(true);
    }
  }, [studentFetched, setStudentFetched, fetchStudent, studentId]);

  return studentsLoading ? (
    <Box sx={{ display: 'inline-block', width: '100%', textAlign: 'center', marginTop: 25 }}>
      <p style={{ fontSize: '18px' }}>Loading student...</p>
      <br />
      <CircularProgress />
    </Box>
  ) : (
    <>
      <Typography variant="h1" component="h1">
        Update Student Details
      </Typography>
      <Grid container flex-direction="column">
        <Grid item xs={10}>
          <StudentForm student={student} onSuccess={(id: string) => history.push(`/students/${id}`)} />
        </Grid>
      </Grid>
    </>
  );
};

export default EditStudentInfoPage;
