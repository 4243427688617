import { axios, alertStore, serviceRecordStore } from '../shared/singletons';
import { BaseFormStore } from '../shared/state/baseForm.store';
import { Service, ServiceFormValues, ServiceFormErrors, ServiceResponse } from './model';
import { today } from '../shared/utils/date.utils';
import { API_ENDPOINT } from '../../config/env';

export class ServiceFormState {
  isLoading: boolean;
  appointmentsLoading: boolean;

  formValues: ServiceFormValues;
  formErrors: ServiceFormErrors;
  formErrorAlertVisible: boolean;
  formStepperIndex: number;

  formModalOpen: boolean;
  formModalService: Service;
  editStageModalOpen: boolean;
  editStageModalService: Service;
  detailsModalOpen: boolean;
  detailsModalService: Service;

  defaultFilters: Record<string, string>;

  static create(props: Partial<ServiceFormState>): ServiceFormState {
    const defaults: ServiceFormState = {
      isLoading: false,
      appointmentsLoading: false,

      formValues: {},
      formErrors: {},
      formErrorAlertVisible: true,
      formStepperIndex: 0,
      formModalOpen: false,
      formModalService: {},
      editStageModalOpen: false,
      editStageModalService: {},
      detailsModalOpen: false,
      detailsModalService: {},

      defaultFilters: {
        'ransack[status_eq]': 'active',
        'appointment_ransack[start_time_gteq]': today(),
        'appointment_ransack[start_time_lteq]': today(),
      },
    };
    return Object.assign(new ServiceFormState(), defaults, props || {});
  }
}

export class ServiceFormStore extends BaseFormStore<ServiceFormState> {
  constructor() {
    super(ServiceFormState.create({}));
  }

  public async createOrUpdateService(
    formValue: ServiceFormValues,
    studentId: string,
    serviceId: string,
    onSuccess?: () => void,
    params = this.formattedFilterParams(),
  ): Promise<void> {
    try {
      const response = await axios[serviceId ? 'put' : 'post']<string, ServiceResponse>(
        `${API_ENDPOINT}/patients/${studentId}/referral_services${serviceId ? `/${serviceId}` : ''}.json?${params}`,
        {
          referral_service: formValue,
          headers: { 'Content-Type': 'application/json' },
        },
      );

      if (onSuccess) {
        onSuccess();
        this.setState({ editStageModalOpen: false });
      } else {
        serviceRecordStore.fetchRecords(`ransack[patient_id_eq]=${studentId}&sort=sort_order&`);
        serviceRecordStore.setState({ service: response.data.result });
        this.setState({ formStepperIndex: 2, editStageModalOpen: false, formModalService: response.data.result });
      }
    } catch (error) {
      this.setState({
        formStepperIndex: 1, // Don't progress stepper if submit errored
        formErrors: error.response?.data,
        formErrorAlertVisible: !!error.response?.data?.base, // The base errors are not tied to a specific field and displayed at the top of the form
      });
    }
  }

  public updateServiceDetails(
    formValue: ServiceFormValues,
    studentId: string,
    serviceId: string,
    onSuccess?: () => void,
  ): void {
    axios
      .put<string, ServiceResponse>(`${API_ENDPOINT}/patients/${studentId}/referral_services/${serviceId}.json`, {
        referral_service: formValue,
        headers: { 'Content-Type': 'application/json' },
      })
      .then((response) => {
        if (onSuccess) {
          onSuccess();
          serviceRecordStore.fetchRecords(`ransack[patient_id_eq]=${studentId}&sort=sort_order&`);
          this.setCreateModalOpen(false);
        } else {
          serviceRecordStore.fetchRecords(); // TODO optimize?
          serviceRecordStore.setState({ service: response.data.result });
          this.setCreateModalOpen(false);
        }
      })
      .catch((error) => {
        this.setState({
          formStepperIndex: 2, // Don't progress stepper if submit errored
          formErrors: error.response?.data,
          formErrorAlertVisible: !!error.response?.data?.base, // The base errors are not tied to a specific field and displayed at the top of the form
        });
      });
  }

  public deleteService(serviceId: string, afterAction?: () => void): void {
    axios
      .delete<string, ServiceResponse>(`${API_ENDPOINT}/referral_services/${serviceId}.json`, {
        headers: { 'Content-Type': 'application/json' },
      })
      .then(() => {
        afterAction();
        // serviceRecordStore.fetchRecords(); // TODO optimize?
      })
      .catch(() => {
        alertStore.alertError('services.alert.delete.error');
      });
  }

  public sortServices(sortValues: any, afterAction: () => void): void {
    axios
      .post<string, ServiceResponse>('/referral_services/sort.json', {
        sorting: sortValues,
        headers: { 'Content-Type': 'application/json' },
      })
      .then(() => {
        afterAction();
      });
  }

  public fetchServiceAppointments(
    studentId: string,
    serviceId: string,
    params = serviceRecordStore.formattedFilterParams(),
    page = 1,
  ): void {
    this.setState({ appointmentsLoading: true });

    axios(
      `${API_ENDPOINT}/patients/${studentId}/referral_services/${serviceId}.json?${params}&appointment_page=${page}&appointment_per=25`,
    )
      .then((result) => result?.data?.result ?? [])
      .then((service) => {
        serviceRecordStore.setState({ service });
        this.setState({ appointmentsLoading: false });
      })
      .catch(() => {
        this.setState({ appointmentsLoading: false });
        alertStore.alertError('students.alert.unknown.error');
      });
  }

  public setCreateModalOpen(isOpen: boolean, studentId?: string): void {
    this.setState({
      formModalOpen: isOpen,
      formModalService: {},
      formValues: { student_id: studentId, status: 'active' },
      formStepperIndex: studentId ? 1 : 0,
    });
  }

  public setEditModalOpen(isOpen: boolean, service: Service): void {
    this.clearFormValues();

    if (service) {
      const formValues = {
        frequency: service.frequency,
        phase: service.phase,
        service_id: service.service_id,
        status: service.status,
        referral_stage_id: service.referral_stage_id,
        owner_id: service.owner_id,
        user_id: service.user_id,
        initial_referral_date: service.initial_referral_date,
        start_date: service.start_date,
        end_date: service.end_date,
        esy_start_date: service.esy_start_date,
        esy_end_date: service.esy_end_date,
        esy_frequency: service.esy_frequency,
        student_id: service?.student_id,
        patient_id: service?.student_id,
        referral_service_users_attributes: service?.referral_service_user_attributes,
        initial_owners: service?.referral_service_user_attributes,
        esy_scheduling_active: service?.esy_scheduling_active,
      };

      this.setState({
        formStepperIndex: 1,
        formModalOpen: isOpen,
        formModalService: service,
        formValues,
      });
    }
  }

  public setEditStageModalOpen(isOpen: boolean, service: Service): void {
    this.clearFormValues();

    if (service) {
      const formValues = {
        frequency: service.frequency,
        phase: service.phase,
        service_id: service.service_id,
        status: service.status,
        referral_stage_id: service.referral_stage_id,
        owner_id: service.owner_id,
        user_id: service.user_id,
        initial_referral_date: service.initial_referral_date,
        start_date: service.start_date,
        end_date: service.end_date,
        student_id: service?.student_id,
        patient_id: service?.student_id,
        esy_scheduling_active: service?.esy_scheduling_active,
      };

      this.setState({
        editStageModalOpen: isOpen,
        editStageModalService: service,
        formValues,
      });
    }
  }

  public setDetailsModalOpen(isOpen: boolean, service: Service): void {
    this.clearFormValues();

    if (service) {
      const formValues = {
        frequency: service.frequency,
        phase: service.phase,
        service_id: service.service_id,
        referral_stage_id: service.referral_stage_id,
        status: service.status,
        owner_id: service.owner_id,
        user_id: service.user_id,
        initial_referral_date: service.initial_referral_date,
        start_date: service.start_date,
        end_date: service.end_date,
        esy_start_date: service.esy_start_date,
        esy_end_date: service.esy_end_date,
        esy_frequency: service.esy_frequency,
        student_id: service?.student_id,
        patient_id: service?.student_id,
        referral_service_users_attributes: service?.referral_service_user_attributes,
        initial_owners: service?.referral_service_user_attributes,
        esy_scheduling_active: service?.esy_scheduling_active,
      };

      this.setState({
        detailsModalOpen: isOpen,
        formStepperIndex: 0,
        detailsModalService: service,
        formValues,
      });
    }
  }

  protected clearFormValues(): void {
    this.setState({ formValues: {} });
  }

  public setFormValue(formValues: ServiceFormValues): void {
    this.setState({ formValues });
  }

  public formattedFilterParams(): string {
    const { defaultFilters } = this.getState();

    return Object?.keys(defaultFilters)?.reduce(
      (params, key, _index: number) => `${params}${key}=${defaultFilters[key]}&` || '',
      '',
    );
  }
}
