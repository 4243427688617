import React, { FunctionComponent, SetStateAction, Dispatch, useEffect } from 'react';
import { alertStore } from '../../shared/singletons';
import { Box, Grid, makeStyles } from '@material-ui/core';
import { AppointmentsContext } from '../../appointments/contexts/AppointmentsContext';
import ConfirmCancelModal from '../../shared/components/modal/ConfirmCancelModal';
import SelectInput from '../../shared/components/form/SelectInput';
import TextInput from '../../shared/components/form/TextInput';
import { Appointment } from '../../appointments/model';
import { axios } from '../../shared/singletons';
import AppointmentErrorsList from '../../appointments/components/AppointmentErrorsList';

const useStyles = makeStyles({
  textInput: {
    height: 'auto',
    width: '98%',
    margin: '14px 0',
  },
  baseError: {
    marginBottom: '10px',
  },
});

interface IncompleteAppointmentTicketModalProps {
  onSuccess?: () => void;
  appointment?: Appointment;
  modalOpen: boolean;
  setModalOpen: Dispatch<SetStateAction<boolean>>;
  appointmentFormValues: any;
}

const IncompleteAppointmentTicketModal: FunctionComponent<IncompleteAppointmentTicketModalProps> = (props) => {
  const { appointment, modalOpen, setModalOpen, appointmentFormValues, onSuccess } = props;
  const { frontendDateErrors } = React.useContext(AppointmentsContext);

  const classes = useStyles();

  const [formValues, setFormValues] = React.useState<Record<string, string>>({ category: 'default' });
  const [comment, setComment] = React.useState('');
  const [errorMessage, setErrorMessage] = React.useState('');

  useEffect(() => {
    setComment('');
  }, []);

  const handleFormSubmit = () => {
    if (comment.trim() === '' || formValues?.category === 'default') {
      setErrorMessage('Please submit a category and comment explaining your request.');
    } else {
      setModalOpen(false);

      // We don't care about which student the appointments belong to, so we use 0 as a placeholder to
      // avoid complicating the appointment routes with some nested and some not
      axios
        .post(`change_requests`, {
          change_request: {
            appointment_id: appointment.id,
            comment: comment,
            category: formValues?.category,
            original_error_message: frontendDateErrors.join(', '),
            new_data_values: {
              status: formValues?.new_status,
              start_time: appointmentFormValues?.start_time,
              end_time: appointmentFormValues?.end_time,
              schedule_date: appointmentFormValues?.schedule_date,
            },
          },
        })
        .then(() => {
          if (onSuccess) {
            onSuccess();
          }
          alertStore.alertSuccess('Successfully submitted timeline support request.');
        });
    }
  };

  return (
    <ConfirmCancelModal
      isOpen={modalOpen}
      title={'Timeline Support Request'}
      onConfirm={handleFormSubmit}
      confirmLabel={'Submit Ticket'}
      openStatusChanged={(isOpen) => setModalOpen(isOpen)}
    >
      <Box p={2}>
        <Grid container>
          {frontendDateErrors && (
            <Grid item xs={10}>
              <p>Error you're seeing:</p>
              <AppointmentErrorsList />
            </Grid>
          )}
          <Grid item xs={10}>
            <SelectInput
              value={formValues?.category}
              styleOverrides={classes.textInput}
              type="text"
              label="Request Category"
              valueChanged={(value: string) => setFormValues({ ...formValues, category: value })}
              choices={[
                { label: 'Select an Option', value: 'default' },
                { label: 'Request Change to Appointment Date and/or Time', value: 'provider_time_change' },
                { label: 'Request Change to Appointment Status', value: 'provider_status_change' },
              ]}
            />
          </Grid>
          {formValues?.category === 'provider_time_change' && (
            <>
              <Grid item xs={10}>
                <TextInput
                  value={appointmentFormValues?.schedule_date}
                  styleOverrides={classes.textInput}
                  type="date"
                  label="New Appointment Date"
                  shrinkLabel
                  disabled
                  valueChanged={(value: string) => setFormValues({ ...formValues, schedule_date: value })}
                />
              </Grid>
              <Grid item xs={10}>
                <TextInput
                  value={appointmentFormValues?.start_time || appointment?.start_time}
                  styleOverrides={classes.textInput}
                  type="time"
                  label="New Start Time"
                  shrinkLabel
                  disabled
                  valueChanged={(value: string) => setFormValues({ ...formValues, start_time: value })}
                />
              </Grid>
              <Grid item xs={10}>
                <TextInput
                  value={appointmentFormValues?.end_time || appointment?.end_time}
                  styleOverrides={classes.textInput}
                  type="time"
                  label="New End Time"
                  shrinkLabel
                  disabled
                  valueChanged={(value: string) => setFormValues({ ...formValues, end_time: value })}
                />
              </Grid>
            </>
          )}
          <Grid item xs={10}>
            <TextInput
              value={comment}
              styleOverrides={classes.textInput}
              label={
                formValues?.category === 'provider_time_change'
                  ? 'Time Change Explanation'
                  : 'Status Change Explanation'
              }
              multiline
              rows={8}
              valueChanged={(value: string) => setComment(value)}
            />
          </Grid>
          {errorMessage !== '' && <p style={{ color: 'red' }}>{errorMessage}</p>}
          <br />
        </Grid>
        <br />
      </Box>
    </ConfirmCancelModal>
  );
};

export default IncompleteAppointmentTicketModal;
