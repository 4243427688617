import React from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Card, CardContent, Grid, Typography } from '@material-ui/core';
import DataTable from '../../shared/components/DataTable';
import { DashboardContext } from '../contexts/DashboardContext';

const columns: unknown[] = [
  {
    name: 'SERVICE PROVIDER',
    selector: 'owner_name',
    sortable: true,
  },
  {
    name: 'TIMELINE ID',
    selector: 'slug',
    sortable: true,
  },
  {
    name: 'SCHOOL NAME',
    selector: 'school',
    sortable: true,
  },
  {
    name: 'INCOMPLETE APPOINTMENTS',
    selector: 'incomplete_appointments',
    sortable: true,
  },
  {
    name: 'NEEDS RESCHEDULED APPOINTMENTS',
    selector: 'needs_rescheduled_appointments',
    sortable: true,
  },
];

const IncompleteNeedsRescheduledAppointmentsTable: React.FunctionComponent = () => {
  const history = useHistory();
  const [incompleteAppointmentsFetched, setIncompleteAppointmentsFetched] = React.useState(false);
  const {
    incompleteAppointmentsLoading,
    fetchIncompleteNeedsRescheduledAppointments,
    incompleteNeedsRescheduledAppointments,
  } = React.useContext(DashboardContext);

  React.useEffect(() => {
    if (!incompleteAppointmentsFetched) {
      fetchIncompleteNeedsRescheduledAppointments();
      setIncompleteAppointmentsFetched(true);
    }
  }, [incompleteAppointmentsFetched, setIncompleteAppointmentsFetched, fetchIncompleteNeedsRescheduledAppointments]);

  return (
    <Box>
      <br />
      <Grid justify="space-between" container spacing={3}>
        <Grid item>
          <Typography variant="h1" component="h1">
            Incomplete and Needs Rescheduled Appointments With Inactive Owners
          </Typography>
        </Grid>
      </Grid>
      <Card>
        <CardContent>
          <DataTable
            title=""
            columns={columns}
            noHeader
            data={incompleteNeedsRescheduledAppointments}
            progressPending={incompleteAppointmentsLoading}
            striped
            onRowClicked={(student) => history.push(`students/${student.id}`)}
            highlightOnHover
            pointerOnHover
            pagination
          />
        </CardContent>
      </Card>
    </Box>
  );
};

export default IncompleteNeedsRescheduledAppointmentsTable;
