import React from 'react';
import { IDataTableColumn } from '../../shared/components/DataTable/DataTable/model';
import ProviderTicketActions from '../components/ProviderTicketActions';
import AdminTicketActions from '../components/AdminTicketActions';
import AdminReferralActions from '../components/AdminReferralActions';
import { UserTicket } from '../model';

export const appointmentChangeColumns = (isAdmin): IDataTableColumn<UserTicket>[] => [
  {
    name: '#',
    selector: 'id',
    sortable: true,
    grow: 0.2,
  },
  {
    name: 'REQUESTED BY',
    selector: 'requested_by',
    sortable: true,
    grow: 0.7,
  },
  {
    name: 'SCHOOL',
    selector: 'payer_name',
    sortable: true,
    grow: 0.7,
  },
  {
    name: 'REQUESTED AT',
    selector: 'created_at',
    sortable: false,
    grow: 0.8,
  },
  {
    name: 'CATEGORY',
    selector: 'formatted_category',
    sortable: false,
    grow: 1.1,
  },
  {
    name: 'COMMENT',
    selector: 'description',
    sortable: false,
    grow: 1.5,
  },
  {
    name: 'Link',
    selector: 'appointment_link',
    sortable: false,
    grow: 0.7,
    cell: (row: any) => (
      <a href={row.appointment_link} target="_blank" rel="noopener noreferrer">
        View Appointment
      </a>
    ),
  },
  {
    name: 'Status',
    selector: 'status',
    sortable: true,
    grow: 0.5,
  },
  {
    name: 'ACTIONS',
    selector: 'logs',
    sortable: false,
    grow: 0.7,
    cell: (row: any) => {
      return isAdmin ? <AdminTicketActions changeRequest={row} /> : <ProviderTicketActions changeRequest={row} />;
    },
  },
];

export const meetingCompleteColumns = (isAdmin): IDataTableColumn<UserTicket>[] => [
  {
    name: '#',
    selector: 'id',
    sortable: true,
    grow: 0.2,
  },
  {
    name: 'REQUESTED BY',
    selector: 'requested_by',
    sortable: true,
    grow: 0.7,
  },
  {
    name: 'SCHOOL',
    selector: 'payer_name',
    sortable: true,
    grow: 0.7,
  },
  {
    name: 'REQUESTED AT',
    selector: 'created_at',
    sortable: false,
    grow: 0.7,
  },
  {
    name: 'Timeline ID',
    selector: 'student_slug',
    cell: (row) => <p>{row.student_slug} Annual Update</p>,
    sortable: false,
    grow: 1,
  },
  {
    name: 'COMMENT',
    selector: 'description',
    sortable: false,
    grow: 1.5,
  },
  {
    name: 'Link',
    selector: 'student_link',
    sortable: false,
    grow: 0.7,
    cell: (row: any) => (
      <>
        <a href={row.student_link} target="_blank" rel="noopener noreferrer">
          {isAdmin ? 'Go to Annual Updater' : 'Review Student Services'}
        </a>
      </>
    ),
  },
  {
    name: 'Status',
    selector: 'status',
    sortable: true,
    grow: 0.5,
  },
  {
    name: 'ACTIONS',
    selector: 'logs',
    sortable: false,
    grow: 1.2,
    cell: (row: any) => {
      return isAdmin ? <AdminTicketActions changeRequest={row} /> : <ProviderTicketActions changeRequest={row} />;
    },
  },
];

export const referralTicketColumns = (isAdmin): IDataTableColumn<UserTicket>[] => [
  {
    name: '#',
    selector: 'id',
    sortable: true,
    grow: 0.2,
  },
  {
    name: 'REQUESTED BY',
    selector: 'requested_by',
    sortable: true,
    grow: 0.7,
  },
  {
    name: 'SCHOOL',
    selector: 'payer_name',
    sortable: true,
    grow: 0.7,
  },
  {
    name: 'REQUESTED AT',
    selector: 'created_at',
    sortable: false,
    grow: 0.7,
  },
  {
    name: 'Timeline ID',
    selector: 'student_slug',
    cell: (row) => <p>{row.student_slug}</p>,
    sortable: false,
    grow: 1,
  },
  {
    name: 'COMMENT',
    selector: 'description',
    sortable: false,
    grow: 1.5,
  },
  {
    name: 'LOCATION',
    selector: 'location',
    sortable: false,
    grow: 1,
  },
  {
    name: 'LINK',
    selector: 'student_link',
    sortable: true,
    grow: 0.7,
    cell: (row) => (
      <a href={row.student_link} target="_blank" rel="noopener noreferrer">
        View Attachments
      </a>
    ),
  },
  {
    name: 'Status',
    selector: 'status',
    sortable: true,
    grow: 0.5,
  },
  {
    name: 'ACTIONS',
    selector: 'logs',
    sortable: false,
    grow: 1.2,
    cell: (row: any) => {
      return isAdmin ? <AdminReferralActions referralTicket={row} /> : <ProviderTicketActions changeRequest={row} />;
    },
  },
];
