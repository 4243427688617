import React, { Dispatch, SetStateAction } from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import ConfirmCancelModal from '../../shared/components/modal/ConfirmCancelModal';

import { InvoiceLineItemRecord } from '../model';

import InformationField from '../../shared/components/InformationField';
import axios from '../../shared/utils/axios.utils';

interface DeleteLineItemModalProps {
  lineItemToDelete: InvoiceLineItemRecord;
  deleteLineItemModalOpen: boolean;
  setDeleteLineItemModalOpen: Dispatch<SetStateAction<boolean>>;
  setLineItemRecords: Dispatch<SetStateAction<InvoiceLineItemRecord[]>>;
}

const DeleteLineItemModal: React.FC<DeleteLineItemModalProps> = (props) => {
  let { lineItemToDelete, deleteLineItemModalOpen, setDeleteLineItemModalOpen, setLineItemRecords } = props;

  const handleDelete = async () => {
    let response = await axios.delete(`/invoices/invoice_line_item_records/${lineItemToDelete.id}`);
    setLineItemRecords(response.data.result);
    setDeleteLineItemModalOpen(false);
  };

  return (
    <ConfirmCancelModal
      confirmLabel="Delete"
      onConfirm={handleDelete}
      openStatusChanged={(isOpen) => setDeleteLineItemModalOpen(isOpen)}
      title="Delete Line Item"
      isOpen={deleteLineItemModalOpen}
      height="350px"
    >
      <Grid container>
        <Grid item xs={12} style={{ marginBottom: 25 }}>
          <Grid container spacing={4}>
            <Grid item>
              <InformationField label="Description" value={lineItemToDelete?.description} />
            </Grid>
            <Grid item>
              <InformationField label="Unit Cost" value={lineItemToDelete?.unit_cost} />
            </Grid>
            <Grid item>
              <InformationField label="Quantity" value={lineItemToDelete?.quantity} />
            </Grid>
            <Grid item>
              <InformationField label="Total" value={lineItemToDelete?.total_cost} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h3">Delete this Line Item?</Typography>
        </Grid>
      </Grid>
    </ConfirmCancelModal>
  );
};

export default DeleteLineItemModal;
