import React, { FunctionComponent } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import DataTable from '../../shared/components/DataTable';
import LoadingSpinner from '../../shared/components/LoadingSpinner';
import { IDataTableColumn } from '../../shared/components/DataTable/DataTable/model';
import { ListingFee } from '../model';
import ListingFeeRowMenu from './ListingFeeRowMenu';
import EditListingFeeModal from './EditListingFeeModal';
import { School } from '../../schools/model';

const columns: IDataTableColumn<ListingFee>[] = [
  {
    name: 'START TIME',
    selector: 'start_time',
    sortable: true,
  },
  {
    name: 'END TIME',
    selector: 'end_time',
    sortable: true,
  },
  {
    name: 'FEES',
    selector: 'fee_type',
    sortable: true,
    cell: (fee: any) => <div style={{ whiteSpace: 'break-spaces' }}>{fee.fee_type}</div>,
  },
  {
    cell: (listingFee) => <ListingFeeRowMenu listingFee={listingFee} />,
    name: 'ACTIONS',
    allowOverflow: true,
    button: true,
    selector: 'actions',
  },
];

interface ListingFeesListProps {
  school: School;
  isLoading: boolean;
}

const ListingFeesList: FunctionComponent<ListingFeesListProps> = (props) => {
  const { school, isLoading } = props;

  return isLoading ? (
    <LoadingSpinner />
  ) : (
    <Card>
      <EditListingFeeModal />
      <CardContent>
        <Typography variant="h2" component="h2">
          Listing Fees
        </Typography>
        <DataTable columns={columns} noHeader data={school?.listing_fees} striped highlightOnHover pointerOnHover />
      </CardContent>
    </Card>
  );
};

export default ListingFeesList;
