import React from 'react';
import styled from 'styled-components';
import { IDataTableColumn } from 'react-data-table-component';

export interface IconProps {
  sortActive: boolean;
  sortDirection: 'asc' | 'desc';
}

const Icon = styled.span<IconProps>`
  padding: 2px 6px;
  color: inherit;
  flex-grow: 0;
  flex-shrink: 0;
  ${(props) => (props.sortActive ? 'opacity: 1' : 'opacity: 0.4')};
  ${(props) => props.sortDirection === 'desc' && 'transform: rotate(180deg)'};
`;

export interface NativeSortIconProps {
  sortActive: boolean;
  sortDirection: 'asc' | 'desc';
  sortIcon?: React.ReactNode;
  column: IDataTableColumn<any>;
}

const NativeSortIcon: React.FunctionComponent<NativeSortIconProps> = (props) => {
  const { sortActive = false, sortDirection, sortIcon = <>&#9650;</> } = props;
  return (
    <Icon sortActive={sortActive} sortDirection={sortDirection}>
      {sortIcon}
    </Icon>
  );
};

/*NativeSortIcon.propTypes = {
  sortDirection: PropTypes.string.isRequired,
  sortActive: PropTypes.bool,
  sortIcon: PropTypes.node,
};*/

NativeSortIcon.defaultProps = {
  sortActive: false,
  sortIcon: <>&#9650;</>,
};

export default NativeSortIcon;
