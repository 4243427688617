import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { axios } from '../../shared/singletons';
import { InternalUsersContext } from '../../internalUsers/contexts/InternalUsersContext';

function UserActivateButtons() {
  const history = useHistory();

  const { user } = useContext(InternalUsersContext);

  const [deactivateConfirmOpen, setDeactivateConfirmOpen] = useState(false);

  const handleDeactivate = () => {
    const nowTime = new Date();
    axios.put(`/users/${user?.id}`, { user: { inactivated_at: nowTime } }).then(() => {
      history.push(`/users/${user?.id}`);
    });
  };

  const handleActivate = () => {
    axios.put(`/users/${user?.id}`, { user: { inactivated_at: null } }).then(() => {
      history.push(`/users/${user?.id}`);
    });
  };

  return (
    <>
      <br />
      {user?.inactivated_at ? (
        <Button variant="contained" color="primary" onClick={handleActivate}>
          Activate User
        </Button>
      ) : (
        <Button
          variant="contained"
          color="primary"
          onClick={() => setDeactivateConfirmOpen(true)}
          disabled={deactivateConfirmOpen}
        >
          Deactivate User
        </Button>
      )}
      {deactivateConfirmOpen && (
        <Button
          variant="contained"
          style={{ backgroundColor: '#F44F64', marginLeft: 7, color: 'white' }}
          onClick={handleDeactivate}
        >
          Confirm De-Activate
        </Button>
      )}
    </>
  );
}

export default UserActivateButtons;
