import { axios, alertStore } from '../shared/singletons';
import { BaseStore } from '../shared/state/base.store';
import { Attachment } from './model';
import { API_ENDPOINT } from '../../config/env';

export class AttachmentState {
  records: Attachment[];
  recordsLoading: boolean;
  attachment: Attachment;
  isDeleting: boolean;

  formModalOpen: boolean;
  editModalOpen: boolean;

  static create(props: Partial<AttachmentState>): AttachmentState {
    const defaults: AttachmentState = {
      records: [],
      recordsLoading: false,
      attachment: {},
      isDeleting: false,

      formModalOpen: false,
      editModalOpen: false,
    };
    return Object.assign(new AttachmentState(), defaults, props || {});
  }
}

export class AttachmentStore extends BaseStore<AttachmentState> {
  constructor() {
    super(AttachmentState.create({}));
  }

  public fetchRecords(service_id: string): void {
    this.setState({ recordsLoading: true });

    axios(`${API_ENDPOINT}/referral_services/${service_id}/attachments.json`)
      .then((result) => result?.data?.result ?? [])
      .then((records) => {
        this.setState({ records, recordsLoading: false });
      })
      .catch(() => {
        this.setState({ recordsLoading: false });
      });
  }

  public deleteAttachment(attachmentId: string, onSuccess?: () => void): void {
    this.setState({ isDeleting: true });

    axios
      .delete<string>(`attachments/${attachmentId}.json`, {
        headers: { 'Content-Type': 'application/json' },
      })
      .then(() => {
        if (onSuccess) {
          onSuccess();
        }

        this.setState({ isDeleting: false });
        alertStore.alertSuccess('Attachment was successfully deleted.');
      })
      .catch(() => {
        this.setState({ isDeleting: false });
        alertStore.alertError('Something went wrong with attachment.');
      });
  }

  public setEditModalOpen(isOpen: boolean, attachment: Attachment): void {
    this.setState({ editModalOpen: isOpen, attachment });
  }

  public setModalOpen = (isOpen: boolean): void => this.setState({ formModalOpen: isOpen });
}
