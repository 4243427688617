import React from 'react';
import GoalsList from '../../goals/components/GoalsList';
import { Service } from '../model';
import { serviceRecordStore } from '../../shared/singletons';
import { Grid } from '@material-ui/core';
import SubjectiveSessionNotes from '../../sessionNotes/components/SubjectiveSessionNotes';

type ServiceGoalsTabProps = {
  service: Service;
  serviceLoading: boolean;
};

function ServiceGoalsTab(props: ServiceGoalsTabProps) {
  const { service, serviceLoading } = props;

  return (
    <>
      <GoalsList
        goals={service?.goals}
        serviceId={service.id}
        studentId={service.student_id}
        isLoading={serviceLoading}
        afterAction={() => serviceRecordStore.fetchRecord(service.student_id, service.id)}
      />
      <br />
      <Grid item xs={12}>
        {service?.subjective_notes?.length > 0 && (
          <SubjectiveSessionNotes title="Subjective Notes" subjectiveNotes={service?.subjective_notes} />
        )}
      </Grid>
    </>
  );
}

export default ServiceGoalsTab;
