import React from 'react';
import StudentRenewalsList from '../../renewals/components/StudentRenewalsList';
import { Student } from '../model';
import { studentRecordStore } from '../../shared/singletons';

type StudentRenewalsTabProps = {
  student: Student;
  studentLoading: boolean;
};

// TODO: Update the RenewalsCaseloadList to begin with a student filter
// Then we can replace the StudentRenewalsList with the RenewalsCaseload list

function StudentRenewalsTab(props: StudentRenewalsTabProps) {
  const { student, studentLoading } = props;

  return (
    <StudentRenewalsList
      iepRenewals={student?.renewals}
      revaluationRenewals={student?.renewals}
      renewalsLoading={studentLoading}
      studentId={student?.id}
      afterAction={() => studentRecordStore.fetchRecord(student.id)}
    />
  );
}

export default StudentRenewalsTab;
