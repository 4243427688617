import React, { FunctionComponent } from 'react';
import { Box, Grid, Typography, makeStyles } from '@material-ui/core';
import ConfirmCancelModal from '../../shared/components/modal/ConfirmCancelModal';
import TextInput from '../../shared/components/form/TextInput';
import SelectInput from '../../shared/components/form/SelectInput';
import { goalStore } from '../../shared/singletons';
import { useStoreObservable } from '../../shared/state/useStoreObservable.hook';

const statusChoices = [
  { label: 'Active', value: 'active' },
  { label: 'Inactive', value: 'inactive' },
];

const useStyles = makeStyles({
  textInput: {
    height: 'auto',
    width: '98%',
    margin: '14px 0',
  },
  baseError: {
    marginBottom: '10px',
  },
});

interface EditGoalModalProps {
  onUpdate?: () => void;
}

const EditGoalModal: FunctionComponent<EditGoalModalProps> = (props) => {
  const { onUpdate } = props;
  const { editModalOpen, formValues, formErrors } = useStoreObservable(goalStore);
  const { goal } = useStoreObservable(goalStore);
  const classes = useStyles();

  const handleFormValueChange = (key: string, value: string) => {
    goalStore.setState({ formValues: { ...formValues, [key]: value } });
  };

  const handleFormSubmit = () => {
    goalStore.updateGoal(formValues, goal.id, onUpdate);
  };

  return (
    <ConfirmCancelModal
      isOpen={editModalOpen}
      title={goal.id ? 'Edit Goal Form' : 'New Goal Form'}
      onConfirm={handleFormSubmit}
      confirmLabel={goal.id ? 'Update' : 'Create'}
      openStatusChanged={(isOpen) => goalStore.setState({ editModalOpen: isOpen })}
    >
      <Box p={2}>
        <Typography variant="h2">Goal Details</Typography>
        <Grid container spacing={3}>
          <Grid item xs={5}>
            <TextInput
              styleOverrides={classes.textInput}
              value={formValues?.start_date}
              label="Start Date"
              type="date"
              errorMessage={formErrors?.start_date}
              shrinkLabel
              valueChanged={(value: string) => handleFormValueChange('start_date', value)}
            />
          </Grid>
          <Grid item xs={5}>
            <TextInput
              styleOverrides={classes.textInput}
              value={formValues?.end_date}
              label="End Date"
              type="date"
              errorMessage={formErrors?.end_date}
              shrinkLabel
              valueChanged={(value: string) => handleFormValueChange('end_date', value)}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={10}>
            <SelectInput
              styleOverrides={classes.textInput}
              value={formValues?.status}
              label="Status"
              choices={statusChoices}
              valueChanged={(value: string) => handleFormValueChange('status', value)}
              type="text"
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={10}>
            <TextInput
              styleOverrides={classes.textInput}
              value={formValues?.description}
              label="Goal Objectives"
              multiline
              rows={16}
              valueChanged={(value: string) => handleFormValueChange('description', value)}
            />
          </Grid>
        </Grid>
        <br />
      </Box>
    </ConfirmCancelModal>
  );
};

export default EditGoalModal;
