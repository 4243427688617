import React, { useEffect, useContext } from 'react';
import AppointmentsListContainer from '../../appointments/components/AppointmentsListContainer';
import { Service } from '../model';
import { useStoreObservable } from '../../shared/state/useStoreObservable.hook';
import { appointmentBaseStore } from '../../shared/singletons';
import { FilterProps } from '../../shared/components/FilterBar';
import { allAppointmentStatusChoices } from '../../appointments/constants';
import { today, oneYearFromNow } from '../../shared/utils/date.utils';
import { AppointmentsContext } from '../../appointments/contexts/AppointmentsContext';

type ServiceAppointmentsTabProps = {
  service: Service;
};

function ServiceAppointmentsTab(props: ServiceAppointmentsTabProps) {
  let { service } = props;

  let { allOwnerChoices } = useStoreObservable(appointmentBaseStore);

  let { fetchAppointments, setFilterValues, setDefaultFilterValues } = useContext(AppointmentsContext);

  let appointmentFilters: FilterProps[] = [
    {
      selector: 'ransack[referral_service_id_eq]',
      label: 'Service ID',
      type: 'text',
      locked: true,
    },
    {
      selector: 'ransack[original_date_gteq]',
      label: 'Original Date From',
      type: 'date',
      advanced: true,
    },
    {
      selector: 'ransack[original_date_lteq]',
      label: 'Original Date To',
      type: 'date',
      advanced: true,
    },
    {
      selector: 'ransack[status_eq]',
      label: 'Status',
      type: 'text',
      options: allAppointmentStatusChoices,
      multiple: false,
    },
    {
      selector: 'ransack[start_time_gteq]',
      label: 'Date From',
      type: 'date',
    },
    {
      selector: 'ransack[start_time_lteq]',
      label: 'Date To',
      type: 'date',
    },
    {
      selector: 'ransack[user_id_eq]',
      label: 'Owner',
      type: 'text',
      options: allOwnerChoices,
      advanced: true,
    },
    {
      selector: 'ransack[blackout_eq]',
      label: 'School Closing',
      type: 'text',
      advanced: true,
      options: [
        { label: 'Open', value: 'open' },
        { label: 'Conflict', value: 'black' },
      ],
    },
  ];

  let serviceDefaultFilterValues = {
    'ransack[referral_service_id_eq]': service.id,
    'ransack[start_time_gteq]': today(),
    'ransack[start_time_lteq]': oneYearFromNow(),
  };

  useEffect(() => {
    setDefaultFilterValues(serviceDefaultFilterValues);
    setFilterValues(serviceDefaultFilterValues);
    fetchAppointments(serviceDefaultFilterValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [service.id]);

  return <AppointmentsListContainer filters={appointmentFilters} />;
}

export default ServiceAppointmentsTab;
