import React, { useState } from 'react';
import { Goal } from '../model';

interface GoalsContextInterface {
  activeGoals?: Goal[];
  setActiveGoals?: React.Dispatch<React.SetStateAction<Goal[]>>;
  inactiveGoals?: Goal[];
  setInactiveGoals?: React.Dispatch<React.SetStateAction<Goal[]>>;
  addGoalShowing?: boolean;
  setAddGoalShowing?: React.Dispatch<React.SetStateAction<boolean>>;
}

const GoalsContext = React.createContext<GoalsContextInterface>({});
const GoalsContextConsumer = GoalsContext.Consumer;

const GoalsContextProvider: React.FC = ({ children }) => {
  const [activeGoals, setActiveGoals] = useState<Goal[]>([]);
  const [inactiveGoals, setInactiveGoals] = useState<Goal[]>([]);
  const [addGoalShowing, setAddGoalShowing] = useState(false);

  return (
    <GoalsContext.Provider
      value={{
        activeGoals,
        setActiveGoals,
        inactiveGoals,
        setInactiveGoals,
        addGoalShowing,
        setAddGoalShowing,
      }}
    >
      {children}
    </GoalsContext.Provider>
  );
};

export { GoalsContextProvider, GoalsContextConsumer, GoalsContext };
