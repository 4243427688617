/* eslint-disable react-hooks/exhaustive-deps */
import React, { FunctionComponent, useEffect } from 'react';
import RenewalStagesList from '../components/RenewalStagesList';
import { RenewalStagesContext } from '../contexts/RenewalStagesContext';

export type RenewalStagesPageProps = Record<string, unknown>;

const RenewalStagesPage: FunctionComponent<RenewalStagesPageProps> = () => {
  const { fetchRecords } = React.useContext(RenewalStagesContext);

  useEffect(() => {
    fetchRecords();
  }, []);

  return <RenewalStagesList />;
};

export default RenewalStagesPage;
