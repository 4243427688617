import React, { FunctionComponent } from 'react';
import { Card, CardContent, Typography, Grid } from '@material-ui/core';
import DataTable from '../../shared/components/DataTable';
import { Quarter } from '../model';
import { AcademicYear } from '../../services/model';
import appointmentQuarterColumns from '../constants/appointmentQuarterColumns';
import SelectInput from '../../shared/components/form/SelectInput';
import { makeStyles } from '@material-ui/core/styles';
import { axios } from '../../shared/singletons';
import { Service } from '../../services/model';
import { API_ENDPOINT } from '../../../config/env';

const useStyles = makeStyles({
  textInput: {
    height: '60px',
    width: '100%',
    margin: '10px 0',
  },
  baseError: {
    marginBottom: '10px',
  },
});

interface AppointmentQuartersProps {
  service: Service;
  year_choice: string;
  academic_years: AcademicYear[];
  initialQuarters: Quarter[];
}

const AppointmentQuarters: FunctionComponent<AppointmentQuartersProps> = (props: AppointmentQuartersProps) => {
  const { service, year_choice, academic_years, initialQuarters } = props;
  const classes = useStyles();

  const [yearChoice, setYearChoice] = React.useState(year_choice);
  const [quarters, setQuarters] = React.useState<Quarter[]>(initialQuarters);
  const [appointmentLoading, setAppointmentLoading] = React.useState<Boolean>(false);

  const fetchQuarters = async (yearChoice) => {
    setAppointmentLoading(true);
    axios
      .get<any>(`${API_ENDPOINT}/referral_services/${service?.id}?year_choice=${yearChoice}`)
      .then((response) => {
        setQuarters(response?.data?.result?.quarters);
      })
      .then((response) => {
        setAppointmentLoading(false);
      })
      .catch(() => {
        setAppointmentLoading(false);
      });
  };

  return (
    <Card>
      <CardContent>
        <Grid container justify="space-between" alignItems="center">
          <Grid item xs={6}>
            <Typography variant="h2" component="h2">
              Appointment Quarters
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Grid container spacing={3} style={{ justifyContent: 'right' }} alignItems="center">
              <Grid item xs={8}>
                <SelectInput
                  styleOverrides={classes.textInput}
                  type="text"
                  label="Academic Year"
                  choices={academic_years?.map((year) => ({ label: year.title, value: year.id }))}
                  value={yearChoice}
                  valueChanged={(value: string) => {
                    setYearChoice(value);
                    fetchQuarters(value);
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid>
          <DataTable
            columns={appointmentQuarterColumns}
            noHeader
            data={quarters}
            progressPending={appointmentLoading}
            striped
            highlightOnHover
            pointerOnHover
          />
        </Grid>
      </CardContent>
    </Card>
  );
};

export default AppointmentQuarters;
