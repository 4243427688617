/* eslint-disable react-hooks/exhaustive-deps */
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
  Grid,
  makeStyles,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Button,
} from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import TextInput from '../../shared/components/form/TextInput';
import { Appointment } from '../../appointments/model';
import { questionsByCategory } from '../../sessionNotes/constants/questionsByCategory';
import SelectInput from '../../shared/components/form/SelectInput';
import AppointmentNotesList from '../../appointmentNotes/components/AppointmentNotesList';

interface GenericFormProps {
  appointment?: Appointment;
  errorValues?: Record<string, string>;
  contentFields?: Record<string, string>;
  category: string;
  setContentFields?: Dispatch<SetStateAction<Record<string, string>>>;
}

const useStyles = makeStyles({
  textInput: {
    height: 'auto',
    width: '80%',
    margin: '10px 0',
  },
  baseError: {
    marginBottom: '10px',
  },
  link: {
    textDecoration: 'none',
  },
});

const GenericForm: React.FC<GenericFormProps> = (props) => {
  const { appointment, errorValues, contentFields, setContentFields, category = 'meetings' } = props;
  const [questions] = useState(questionsByCategory[category]?.questions || []);
  const [showNoteHistory, setShowNoteHistory] = React.useState(false);

  const classes = useStyles();

  useEffect(() => {
    const blankContentFields = {};
    for (var key in questions) {
      blankContentFields[questions[key].label.toLowerCase()] = contentFields[questions[key].label.toLowerCase()];
    }
    setContentFields(blankContentFields);
  }, [category]);

  const handleContentFieldsChange = (key, value) => {
    const updatedField = { ...contentFields };
    updatedField[`${key}`] = value;
    setContentFields(updatedField);
  };

  return (
    <Accordion expanded>
      <AccordionSummary>
        <Typography variant="h2">Appointment Notes</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container>
          {Object.keys(questions)?.map((key) => {
            const displayLabel = questions[key].label;
            const keyLabel = displayLabel.toLowerCase();

            return (
              <>
                <Grid item xs={12}>
                  {questions[key].type === 'drop_down' && (
                    <SelectInput
                      styleOverrides={classes.textInput}
                      type="text"
                      label={displayLabel}
                      choices={questions[key].options}
                      value={contentFields[keyLabel]}
                      valueChanged={(value: string) => handleContentFieldsChange(keyLabel, value)}
                      defaultChoice={questions[key].options[0].value}
                      key={`${displayLabel}_${key}`}
                    />
                  )}
                  {questions[key].type === 'short_answer' && (
                    <TextInput
                      value={contentFields[keyLabel]}
                      styleOverrides={classes.textInput}
                      shrinkLabel
                      label={displayLabel}
                      multiline
                      rows={4}
                      errorMessage={errorValues?.content}
                      valueChanged={(value: string) => handleContentFieldsChange(keyLabel, value)}
                      key={`${displayLabel}_${key}`}
                    />
                  )}
                  {questions[key].type === 'checkbox' && (
                    <FormControlLabel
                      control={
                        <Checkbox
                          name={displayLabel}
                          value={contentFields[keyLabel]}
                          color="primary"
                          onChange={(event) => handleContentFieldsChange(keyLabel, event.target.checked)}
                        />
                      }
                      label={displayLabel}
                    />
                  )}
                </Grid>
              </>
            );
          })}
          <Grid item xs={12}>
            <Button variant="contained" color="primary" onClick={() => setShowNoteHistory(!showNoteHistory)}>
              Toggle Note History
            </Button>
            <Box display={showNoteHistory ? 'block' : 'none'}>
              <AppointmentNotesList appointment={appointment} appointmentNotes={appointment?.all_appointment_notes} />
            </Box>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default GenericForm;
