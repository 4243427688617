import React from 'react';
import { useParams } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import ReferralForm from '../components/ReferralForm';
import { ReferralsContext } from '../contexts/ReferralsContext';
import { ReferralRouteParams } from '../model';

const NewReferral: React.FC = () => {
  const { studentId } = useParams<ReferralRouteParams>();
  const { fetchReferralStudent, fetchChoices } = React.useContext(ReferralsContext);
  const [studentFetched, setStudentFetched] = React.useState(false);

  React.useEffect(() => {
    if (!studentFetched) {
      setStudentFetched(true);
      fetchReferralStudent(studentId);
      fetchChoices();
    }
  }, [fetchReferralStudent, studentFetched, studentId, fetchChoices]);

  return false ? (
    <p>Loading...</p>
  ) : (
    <>
      <Typography variant="h1" component="h1">
        New Referral Details
      </Typography>
      <ReferralForm />
    </>
  );
};

export default NewReferral;
