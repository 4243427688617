/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react';
import { Typography, Box, Grid, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import { CSVLink } from 'react-csv';
import format from 'date-fns/format';
import DataTable from '../../shared/components/DataTable';
import { UserContext } from '../../auth/contexts/userContext';
import { UserTicketsContext } from '../contexts/UserTicketsContext';
import { meetingCompleteColumns, appointmentChangeColumns, referralTicketColumns } from '../constants/columns';
import UserTicketsFilterBar from '../components/UserTicketsFilterBar';
import ApproveTicketModal from '../components/ApproveTicketModal';
import RejectTicketModal from '../components/RejectTicketModal';
import CompleteReferralModal from '../components/CompleteReferralModal';
import TicketApprovalDialog from '../components/TicketApprovalDialog';
import ReferralTicketCompletedDialog from '../components/ReferralTicketCompletedDialog';
import LoadingSpinner from '../../shared/components/LoadingSpinner';

const toQueryString = (params: Record<string, string>) => {
  return Object.entries(params)
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
    .join('&');
};

const UserTicketsList: React.FC = () => {
  const {
    filterValues,
    setFilterValues,
    fetchUserTickets,
    fetchReferralTickets,
    userTickets,
    meetingCompleteTickets,
    referralTickets,
    ticketsDownload,
    userTicketsLoading,
    referralTicketsLoading,
  } = useContext(UserTicketsContext);

  const { user, currentUserHasRole } = useContext(UserContext);

  useEffect(() => {
    let queryString = toQueryString(filterValues);

    if (currentUserHasRole('admin')) {
      queryString += `&ransack[status_eq]=pending`;
      setFilterValues({ ...filterValues, 'ransack[status_eq]': 'pending' });
    } else {
      setFilterValues({ ...filterValues, 'ransack[viewed_eq]': 'false' });
      queryString += `&ransack[viewed_eq]=false`;
    }

    fetchUserTickets(queryString);
    fetchReferralTickets(queryString);
  }, [user]);

  const ExpandedMessage = ({ data }) => <p>{data?.rejection_message}</p>;

  return (
    <Box>
      <ApproveTicketModal />
      <RejectTicketModal />
      <CompleteReferralModal />
      <TicketApprovalDialog />
      <ReferralTicketCompletedDialog />
      <Grid justify="space-between" container spacing={3}>
        <Grid item>
          <Typography variant="h1" component="h1">
            User Tickets
          </Typography>
        </Grid>
        <Grid item>
          <CSVLink
            data={ticketsDownload}
            filename={`user-tickets-${format(new Date(), 'MM-dd-yyyy')}.csv`}
            className={`MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary`}
            style={{ marginLeft: '8px', backgroundColor: '#424E7A', color: '#fff' }}
          >
            Download Tickets
          </CSVLink>
        </Grid>
      </Grid>
      <br />
      {userTicketsLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <UserTicketsFilterBar />
          <br />
          <Accordion>
            <AccordionSummary>
              <Grid container>
                <Grid item xs={3}>
                  <Typography variant="h2" component="h2">
                    Appointment Tickets
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="h3" component="h2">
                    {userTickets.length} {userTickets.length === 1 ? 'Ticket' : 'Tickets'}
                  </Typography>
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <DataTable
                title=""
                noHeader
                columns={appointmentChangeColumns(currentUserHasRole('admin'))}
                data={userTickets}
                striped
                highlightOnHover
                pointerOnHover
                pagination
                expandableRows
                expandableRowExpanded={(row) => row.rejection_message !== null}
                expandableRowsComponent={<ExpandedMessage data />}
              />
            </AccordionDetails>
          </Accordion>
          <br />
          <Accordion>
            <AccordionSummary>
              <Grid container>
                <Grid item xs={3}>
                  <Typography variant="h2" component="h2">
                    Meeting Tickets
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="h3" component="h2">
                    {meetingCompleteTickets.length} {meetingCompleteTickets.length === 1 ? 'Ticket' : 'Tickets'}
                  </Typography>
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <DataTable
                title=""
                noHeader
                columns={meetingCompleteColumns(currentUserHasRole('admin'))}
                data={meetingCompleteTickets}
                striped
                highlightOnHover
                pointerOnHover
                pagination
              />
            </AccordionDetails>
          </Accordion>
          <br />
        </>
      )}
      {referralTicketsLoading ? (
        <LoadingSpinner />
      ) : (
        <Accordion>
          <AccordionSummary>
            <Grid container>
              <Grid item xs={3}>
                <Typography variant="h2" component="h2">
                  Referral Tickets
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="h3" component="h2">
                  {referralTickets.length} {referralTickets.length === 1 ? 'Ticket' : 'Tickets'}
                </Typography>
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <DataTable
              title=""
              noHeader
              columns={referralTicketColumns(currentUserHasRole('admin'))}
              data={referralTickets}
              striped
              highlightOnHover
              pointerOnHover
              pagination
            />
          </AccordionDetails>
        </Accordion>
      )}
    </Box>
  );
};

export default UserTicketsList;
