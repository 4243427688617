export const intervalChoices = [
  { label: 'Day', value: 'day' },
  { label: 'Week', value: 'week' },
  { label: 'Two Weeks', value: 'twoweeks' },
  { label: 'Month', value: 'month' },
  { label: 'Quarter', value: 'quarter' },
  { label: 'Academic Year', value: 'academic_year' },
  { label: 'Custom', value: 'custom' },
];

export const frequencyCountUnitChoices = [
  { label: 'Minutes', value: 'minutes' },
  { label: 'Hours', value: 'hours' },
  { label: 'Appointments', value: 'appointments' },
];
