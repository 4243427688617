import React, { FunctionComponent, SetStateAction, Dispatch } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Grid, Typography, makeStyles, Card } from '@material-ui/core';
import ConfirmCancelModal from '../../shared/components/modal/ConfirmCancelModal';
import SelectInput from '../../shared/components/form/SelectInput';
import TextInput from '../../shared/components/form/TextInput';
import { appointmentFormStore } from '../../shared/singletons';
import { useStoreObservable } from '../../shared/state/useStoreObservable.hook';
import { UserContext } from '../../auth/contexts/userContext';
import { _translateEach } from '../../shared/utils/translation.utils';
import { allAppointmentStatusChoices } from '../constants';
import { Appointment } from '../model';
import DataTable from '../../shared/components/DataTable';
import { axios } from '../../shared/singletons';

const useStyles = makeStyles({
  textInput: {
    height: 'auto',
    width: '98%',
    margin: '14px 0',
  },
  baseError: {
    marginBottom: '10px',
  },
});

const briefAppointmentColumns = [
  {
    name: 'STUDENT NAME',
    selector: 'student_name',
  },
  {
    name: 'SERVICE TYPE',
    selector: 'service_type',
  },
  {
    name: 'SCHEDULED DATE',
    selector: 'formatted_schedule_date',
  },
];

interface AppointmentBulkStatusChangeModalProps {
  onSuccess?: () => void;
  bulkUpdateModalOpen: boolean;
  setBulkUpdateModalOpen: Dispatch<SetStateAction<boolean>>;
  selectedRows: Appointment[];
}

const AppointmentBulkStatusChangeModal: FunctionComponent<AppointmentBulkStatusChangeModalProps> = (props) => {
  const { bulkUpdateModalOpen, setBulkUpdateModalOpen, selectedRows, onSuccess } = props;

  const history = useHistory();
  const classes = useStyles();

  const { user } = React.useContext(UserContext);
  const { editStatusModalOpen, formValues, formErrors } = useStoreObservable(appointmentFormStore);
  const [comment, setComment] = React.useState('');
  const [errorMessage, setErrorMessage] = React.useState('');

  const t = _translateEach({
    title: 'appointments.stageChangeModal.title',
    update: 'appointments.stageChangeModal.update',
  });

  React.useEffect(() => {
    setComment('');
  }, [editStatusModalOpen]);

  const handleFormValueChange = (key: string, value: string) => {
    setErrorMessage('');
    appointmentFormStore.setState({ formValues: { ...formValues, [key]: value } });
  };

  const handleFormSubmit = () => {
    if (comment.trim() === '') {
      setErrorMessage('Please submit a comment explaining the status change.');
    } else {
      setBulkUpdateModalOpen(false);

      // We don't care about which student the appointments belong to, so we use 0 as a placeholder to
      // avoid complicating the appointment routes with some nested and some not
      axios.put(`patients/0/appointments/bulk_status_update`, { selectedRows, appointment: formValues }).then(() => {
        if (onSuccess) {
          onSuccess();
        } else {
          history.push(`appointments`);
        }
      });
    }
  };

  const handleCommentChange = (value: string) => {
    setComment(value);

    // Using the strange format below to automatically work with Rails accepts_nested_attributes_for
    appointmentFormStore.setState({
      formValues: {
        ...formValues,
        comments_attributes: {
          '0': {
            content: value,
            user_id: user.id,
            category: ['incomplete', 'needs_rescheduled'].includes(value) ? 'status' : 'unknown',
          },
        },
      },
    });
  };

  return (
    <ConfirmCancelModal
      isOpen={bulkUpdateModalOpen}
      title={t.title}
      onConfirm={handleFormSubmit}
      confirmLabel={t.update}
      openStatusChanged={(isOpen) => setBulkUpdateModalOpen(isOpen)}
    >
      <Box p={2}>
        <Typography variant="h2">Updating Status for these Appointments:</Typography>

        <Card>
          <DataTable
            columns={briefAppointmentColumns}
            noHeader
            data={selectedRows}
            striped
            highlightOnHover
            pointerOnHover
          />
        </Card>
        <br />

        <Grid container>
          <Grid item xs={10}>
            <SelectInput
              value={formValues?.status}
              styleOverrides={classes.textInput}
              type="text"
              label="Appointment Status"
              errorMessage={formErrors?.status}
              valueChanged={(value: string) => handleFormValueChange('status', value)}
              choices={allAppointmentStatusChoices}
            />
          </Grid>
          <Grid item xs={10}>
            <TextInput
              value={comment}
              styleOverrides={classes.textInput}
              label="Comment"
              multiline
              rows={8}
              valueChanged={(value: string) => handleCommentChange(value)}
            />
          </Grid>
          {errorMessage !== '' && <p style={{ color: 'red' }}>{errorMessage}</p>}
          <br />
        </Grid>
        <br />
      </Box>
    </ConfirmCancelModal>
  );
};

export default AppointmentBulkStatusChangeModal;
