import React, { FunctionComponent } from 'react';
import { Button, Card, CardContent, Typography, Grid, makeStyles } from '@material-ui/core';
import InformationField from '../../shared/components/InformationField';
import LoadingSpinner from '../../shared/components/LoadingSpinner';
import { Service } from '../../services/model';
import DeleteModal from '../../shared/components/modal/DeleteModal';
import { DischargeStudentContext } from '../contexts/DischargeStudentContext';

interface DischargeServiceCardProps {
  service: Service;
  serviceLoading: boolean;
}

const useStyles = makeStyles((theme) => ({
  listItemIcon: {
    minWidth: '24px',
  },
  modalText: {
    fontSize: '20px',
    marginLeft: '20%',
    marginBottom: '20px',
    width: '60%',

    [theme.breakpoints.down('sm')]: {
      marginLeft: '2%',
      width: '96%',
    },
  },
}));

const DischargeServiceCard: FunctionComponent<DischargeServiceCardProps> = (props) => {
  const { service, serviceLoading } = props;
  const classes = useStyles();
  const { dischargeService } = React.useContext(DischargeStudentContext);

  const [destroyModalOpen, setDestroyModalOpen] = React.useState(false);

  const handleDischargeService = () => {
    dischargeService(service?.id);
    setDestroyModalOpen(false);
  };

  const openAppointmentsTab = () => {
    const win = window.open(`/students/${service?.student_id}/services/${service?.id}/appointments`, '_blank');
    win.focus();
  };

  return serviceLoading ? (
    <LoadingSpinner />
  ) : (
    <Card>
      <DeleteModal
        isOpen={destroyModalOpen}
        openStatusChanged={setDestroyModalOpen}
        onDelete={() => handleDischargeService()}
        confirmLabel="Confirm"
      >
        <Typography align="center" className={classes.modalText} variant="h5">
          Are you sure you want to discharge this service? This will delete all future appointments.
        </Typography>
      </DeleteModal>
      <CardContent>
        <Grid container justify="space-between">
          <Grid item>
            <Typography variant="h2" component="h2">
              {`${service?.service_type} Information`}
            </Typography>
          </Grid>
        </Grid>
        <br />
        <Grid container spacing={3}>
          <Grid item xs={6} md={3}>
            <InformationField
              label="Phase"
              value={service?.phase === 'patient' ? 'STUDENT' : service?.phase?.toUpperCase()}
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <InformationField label="Status" value={service?.status?.toUpperCase()} />
          </Grid>
          <Grid item xs={6} md={3}>
            <InformationField label="Stage" value={service?.stage} />
          </Grid>
          <Grid item md={3} />
          <Grid item xs={6} md={3}>
            <InformationField label="Last Updated" value={service?.updated_at} />
          </Grid>
          <Grid item xs={6} md={3}>
            <InformationField label="Owner" value={service?.owner} />
          </Grid>
          <Grid item xs={6} md={3}>
            <InformationField label="Start Date" value={service?.start_date} />
          </Grid>
          <Grid item xs={6} md={3}>
            <InformationField label="End Date" value={service?.end_date} />
          </Grid>
          <Grid item xs={6} md={12}>
            <InformationField label="Frequency" value={service?.frequency} />
          </Grid>
          <Grid item xs={6} md={6}>
            <div>
              <Typography variant="subtitle1" component="h6">
                Past Incomplete & Needs Rescheduled Appointments
              </Typography>
              <Typography variant="body1" component="p" style={{ whiteSpace: 'break-spaces', fontWeight: 600 }}>
                {service.incomplete_appointments_count} Appointments
              </Typography>
              <Typography
                variant="body1"
                component="p"
                style={{ whiteSpace: 'break-spaces', marginTop: 5, width: '80%' }}
              >
                Note: An email will be sent to timelinesupport@thehopelearningcenter.org with a list of these
                appointments when you discharge the service.
              </Typography>
            </div>
          </Grid>
          <Grid item xs={6} md={6}>
            <div>
              <Typography variant="subtitle1" component="h6">
                Remaining Future Appointments
              </Typography>
              <Typography variant="body1" component="p" style={{ whiteSpace: 'break-spaces', fontWeight: 600 }}>
                {`${service.future_appointments_count} Appointments`}
              </Typography>
              <Typography variant="body1" component="p" style={{ whiteSpace: 'break-spaces', marginTop: 5 }}>
                Note: These will be deleted when you discharge the service.
              </Typography>
            </div>
          </Grid>
        </Grid>
        <br />
        <Button variant="contained" color="primary" onClick={openAppointmentsTab}>
          View Appointments
        </Button>
        <Button
          variant="contained"
          style={{ backgroundColor: '#F44F64', marginLeft: 7 }}
          color="primary"
          onClick={() => setDestroyModalOpen(true)}
        >
          Discharge Service
        </Button>
      </CardContent>
    </Card>
  );
};

export default DischargeServiceCard;
