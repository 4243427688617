import React, { FunctionComponent } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import SchoolSummerScheduling from '../components/SchoolSummerScheduling';
import { SchoolsContext } from '../../schools/contexts/SchoolsContext';

export type SchoolEsySchedulingProps = Record<string, unknown>;

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const SchoolEsyScheduling: FunctionComponent<SchoolEsySchedulingProps> = () => {
  const { fetchSchool, fetchEsySchedulingDetails, esyDetailsLoading } = React.useContext(SchoolsContext);
  const { schoolId } = useParams<Record<string, string>>();
  const params = useQuery();

  const school_id = params.get('school_id');

  /* eslint-disable */
  React.useEffect(() => {
    fetchSchool(schoolId);
    fetchEsySchedulingDetails(schoolId);
  }, []);
  /* eslint-enable */

  return esyDetailsLoading ? <></> : <SchoolSummerScheduling initialSchoolId={school_id} />;
};

export default SchoolEsyScheduling;
