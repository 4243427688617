import React, { useContext } from 'react';
import { AppointmentsContext } from '../contexts/AppointmentsContext';
import { Typography } from '@material-ui/core';

const AppointmentErrorsList: React.FC = () => {
  const { frontendDateErrors } = useContext(AppointmentsContext);

  return (
    <>
      {frontendDateErrors?.map((error) => (
        <Typography variant="h3" style={{ color: 'red', marginBottom: '4px' }}>
          - {error}
        </Typography>
      ))}
    </>
  );
};

export default AppointmentErrorsList;
