import React, { FunctionComponent } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ConfirmCancelModal from '../../shared/components/modal/ConfirmCancelModal';
import TextInput from '../../shared/components/form/TextInput';
import SelectInput from '../../shared/components/form/SelectInput';
import { communicationStore } from '../../shared/singletons';
import { useStoreObservable } from '../../shared/state/useStoreObservable.hook';
import { CommunicationState } from '../communication.store';

const useStyles = makeStyles({
  textInput: {
    height: '60px',
    width: '80%',
    margin: '10px 0',
  },
  baseError: {
    marginBottom: '10px',
  },
});

interface CommunicationFormModal {
  parentType: string;
  parentId: string;
  onSuccess?: () => void;
}

const CommunicationFormModal: FunctionComponent<CommunicationFormModal> = (props) => {
  const classes = useStyles();
  const { parentType, parentId, onSuccess } = props;

  const state = useStoreObservable<CommunicationState>(communicationStore);
  const { formModalOpen, formValues } = state;
  const [errorMessage, setErrorMessage] = React.useState('');

  const handleSubmit = (): void => {
    if (
      formValues.contact_name &&
      formValues.contact_method &&
      formValues.contact_on &&
      formValues.contact_reason &&
      formValues.contact_result
    ) {
      setErrorMessage('');
      communicationStore.createCommunication(formValues, parentType, parentId, onSuccess);
    } else {
      setErrorMessage('Please fill out all fields in the form above.');
    }
  };

  const handleFormValueChange = (key: string, value: string) => {
    communicationStore.setFormValue({ ...formValues, [key]: value });
  };

  return (
    <ConfirmCancelModal
      isOpen={formModalOpen}
      title="Create Comment"
      confirmLabel="Submit"
      openStatusChanged={(isOpen) => communicationStore.setState({ formModalOpen: isOpen })}
      onConfirm={handleSubmit}
    >
      <form noValidate autoComplete="off">
        <p style={{ color: 'red' }}>{errorMessage}</p>
        <TextInput
          styleOverrides={classes.textInput}
          value={formValues?.contact_name}
          label="Who was contacted (required)"
          type="text"
          valueChanged={(value: string) => handleFormValueChange('contact_name', value)}
        />
        <SelectInput
          styleOverrides={classes.textInput}
          choices={[
            { label: 'Phone', value: 'Phone' },
            { label: 'Email', value: 'Email' },
            { label: 'Other', value: 'Other' },
          ]}
          value={formValues?.contact_method}
          label="Contact Method (required)"
          type="text"
          valueChanged={(value: string) => handleFormValueChange('contact_method', value)}
        />
        <TextInput
          styleOverrides={classes.textInput}
          value={formValues?.contact_on}
          label="Date of Contact (required)"
          type="date"
          shrinkLabel
          valueChanged={(value: string) => handleFormValueChange('contact_on', value)}
        />
        <TextInput
          styleOverrides={classes.textInput}
          value={formValues?.contact_reason}
          label="Contact Reason (required)"
          type="text"
          valueChanged={(value: string) => handleFormValueChange('contact_reason', value)}
        />
        <TextInput
          styleOverrides={classes.textInput}
          value={formValues?.contact_result}
          label="Communication (required)"
          multiline
          rows={8}
          valueChanged={(value: string) => handleFormValueChange('contact_result', value)}
        />
      </form>
    </ConfirmCancelModal>
  );
};

export default CommunicationFormModal;
