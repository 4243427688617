import React, { FunctionComponent } from 'react';
import { default as MaterialIcon } from '@material-ui/core/Icon';
import { IconClassKey } from '@material-ui/core/Icon/Icon';
import { PropTypes } from '@material-ui/core';
import styled from 'styled-components';
import { _compactJoin } from '../../utils/collection.utils';

export type IconFontSize = 'inherit' | 'default' | 'small' | 'large';
export type IconColor = PropTypes.Color | 'action' | 'disabled' | 'error';

interface IconProps {
  name: string;
  fontSize?: IconFontSize;
  colorKey?: IconColor;
  classKey?: IconClassKey;
  disabled?: boolean;
  isClickable?: boolean;
  onClick?: () => void;
}

const IconWrapper = styled.div`
  &.clickable {
    cursor: pointer;

    &.disabled {
      cursor: not-allowed;
    }
  }
`;

export const HopeIcon: FunctionComponent<IconProps> = (props: IconProps) => {
  const {
    name,
    fontSize = 'inherit',
    colorKey = 'action',
    disabled = false,
    isClickable = false,
    onClick = () => null,
  } = props;

  const classes = _compactJoin(
    [
      isClickable ? 'clickable' : null, //
      disabled ? 'disabled' : null,
    ],
    ' ',
  );

  const handleClick = () => {
    if (!disabled && isClickable && onClick) {
      onClick();
    }
  };

  return (
    <IconWrapper className={classes}>
      <MaterialIcon fontSize={fontSize} onClick={handleClick} color={disabled ? 'disabled' : colorKey}>
        {name}
      </MaterialIcon>
    </IconWrapper>
  );
};
