import React from 'react';
import Typography from '@material-ui/core/Typography';

interface ReviewFieldProps {
  label: string;
  value?: string | number | null;
}

const ReviewField: React.FC<ReviewFieldProps> = (props) => {
  const { label, value } = props;
  return (
    <Typography variant="body1">
      <b>{label}:</b> {value}
    </Typography>
  );
};

export default ReviewField;
