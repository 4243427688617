import React, { useContext } from 'react';
import { fade, makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { AppBar, Box } from '@material-ui/core';
import Toolbar from '@material-ui/core/Toolbar';
import Link from '@material-ui/core/Link';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MoreIcon from '@material-ui/icons/MoreVert';
import { Urls } from '../constants/urls';
import { UserContext } from '../../auth/contexts/userContext';
import Logo from '../../../assets/logo.png';
import { ENVIRONMENT } from '../../../config/env';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      boxShadow: 'none',
    },
    grow: {
      flexGrow: 1,
    },
    toolbar: {
      backgroundColor: ENVIRONMENT === 'staging' ? '#F44F64' : '#fff',
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      display: 'none',
      color: '#000',
      [theme.breakpoints.up('sm')]: {
        display: 'block',
      },
    },
    search: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: fade(theme.palette.common.white, 0.15),
      '&:hover': {
        backgroundColor: fade(theme.palette.common.white, 0.25),
      },
      marginRight: theme.spacing(2),
      marginLeft: 0,
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto',
      },
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: '#ccc',
    },
    inputRoot: {
      color: 'inherit',
      border: '1px solid #ccc',
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create('width'),
      color: '#000',
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '25ch',
      },
    },
    sectionDesktop: {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'flex',
      },
    },
    sectionMobile: {
      display: 'flex',
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
  }),
);

const PrimarySearchAppBar: React.FC<any> = (props) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [navigationElement, setNavigationElement] = React.useState<null | HTMLElement>(null);
  const { currentUserHasRole, currentUserHasAnyRole, user, logout } = useContext(UserContext);

  const isMenuOpen = Boolean(anchorEl);
  const isNavigationOpen = Boolean(navigationElement);

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleNavigationOpen = (event: React.MouseEvent<HTMLElement>) => {
    setNavigationElement(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setNavigationElement(null);
  };

  const menuOptionsByRole = [
    {
      label: 'My Account',
      url: Urls.AccountRoute,
      roles: ['system_owner', 'admin', 'service_provider', 'school_admin', 'accountant', 'services_coordinator'],
    },
    {
      label: 'My Trainings',
      url: '/my_trainings',
      roles: ['system_owner', 'service_provider', 'accountant', 'services_coordinator', 'supervisor'],
    },
    { label: 'Admin Access Panel', url: Urls.AdminAccessPanelRoute, roles: ['system_owner', 'admin'] },
    {
      label: 'Reports',
      url: '/reports',
      roles: ['system_owner', 'admin', 'accountant', 'supervisor', 'services_coordinator'],
    },
    { label: 'School Actions', url: '/school_actions', roles: ['system_owner', 'admin'] },
    { label: 'Compliance Tracking', url: '/compliance_tracking', roles: ['system_owner', 'admin'] },
    { label: 'Manage Trainings', url: '/manage_trainings', roles: ['system_owner', 'admin'] },
    { label: 'Help', url: '/help', roles: ['system_owner', 'school_admin'] },
    { label: 'My Documents', url: '/documents', roles: ['system_owner', 'school_admin'] },
  ];

  const menuId = 'primary-search-account-menu';
  const navigationId = 'mobile-navigation-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      {menuOptionsByRole.map((option) => {
        return (
          <Box key={option.label}>
            {currentUserHasAnyRole(option.roles) && (
              <MenuItem onClick={handleMenuClose}>
                <Link variant="body2" href={option.url}>
                  {option.label}
                </Link>
              </MenuItem>
            )}
          </Box>
        );
      })}
      {user && (
        <MenuItem onClick={handleMenuClose}>
          <Link variant="body2" onClick={logout}>
            Logout
          </Link>
        </MenuItem>
      )}
      {!user && (
        <MenuItem onClick={handleMenuClose}>
          <Link variant="body2" href={Urls.LoginRoute}>
            Login
          </Link>
        </MenuItem>
      )}
    </Menu>
  );

  const renderNavigation = (
    <Menu
      anchorEl={navigationElement}
      anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
      id={navigationId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      open={isNavigationOpen}
      onClose={handleMenuClose}
    >
      <Box>
        {currentUserHasRole('school_admin') && (
          <MenuItem onClick={handleMenuClose}>
            <Link href="/referral_students/new">New Referral</Link>
          </MenuItem>
        )}
        {(currentUserHasRole('admin') || currentUserHasRole('service_provider')) && (
          <MenuItem onClick={handleMenuClose}>
            <Link href={Urls.DashboardRoute}>Dashboard</Link>
          </MenuItem>
        )}
        {(currentUserHasRole('admin') ||
          currentUserHasRole('service_provider') ||
          currentUserHasRole('school_admin')) && (
          <MenuItem onClick={handleMenuClose}>
            <Link href={Urls.StudentsRoute}>Students</Link>
          </MenuItem>
        )}
        {(currentUserHasRole('admin') || currentUserHasRole('service_provider')) && (
          <MenuItem onClick={handleMenuClose}>
            <Link href={Urls.AppointmentsRoute}>Appointments</Link>
          </MenuItem>
        )}
        {(currentUserHasRole('admin') ||
          currentUserHasRole('service_provider') ||
          currentUserHasRole('school_admin')) && (
          <MenuItem onClick={handleMenuClose}>
            <Link href="/referrals">Referrals</Link>
          </MenuItem>
        )}
        {(currentUserHasRole('admin') || currentUserHasRole('service_provider')) && (
          <MenuItem onClick={handleMenuClose}>
            <Link href={Urls.RenewalsRoute}>Renewals</Link>
          </MenuItem>
        )}
        {currentUserHasRole('admin') && (
          <MenuItem onClick={handleMenuClose}>
            <Link href={Urls.UsersRoute}>Users</Link>
          </MenuItem>
        )}
        {currentUserHasRole('admin') && (
          <MenuItem onClick={handleMenuClose}>
            <Link href={Urls.SchoolsRoute}>Schools</Link>
          </MenuItem>
        )}
      </Box>
    </Menu>
  );

  return (
    <div className={classes.grow}>
      <AppBar position="fixed" className={classes.root}>
        <Toolbar className={classes.toolbar}>
          <div className={classes.sectionDesktop}>
            <img src={Logo} style={{ height: 40 }} alt="Timeline" />
            {ENVIRONMENT === 'staging' && (
              <Typography style={{ marginTop: 5, fontSize: 18, marginLeft: 30 }}>
                You are using the Timeline test site.
              </Typography>
            )}
          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls="mobile-navigation-menu-mobile"
              aria-haspopup="true"
              onClick={handleNavigationOpen}
              color="primary"
            >
              <MenuIcon />
            </IconButton>
          </div>
          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            {user && user?.first_name && (
              <div>
                <Typography style={{ lineHeight: '65px' }}>{`${user?.first_name} ${user?.last_name}`}</Typography>
              </div>
            )}
            <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="primary"
            >
              <AccountCircle />
            </IconButton>
          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls="primary-search-account-menu-mobile"
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="primary"
            >
              <MoreIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {renderNavigation}
      {renderMenu}
    </div>
  );
};

export default PrimarySearchAppBar;
