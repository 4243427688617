import React, { FunctionComponent } from 'react';
import Card from '@material-ui/core/Card';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CardContent from '@material-ui/core/CardContent';
import LoadingSpinner from '../../../shared/components/LoadingSpinner';
import EditUserRoleModal from './EditUserRoleModal';
import NewUserRoleModal from './NewUserRoleModal';
import UserRoleRowMenu from './UserRoleRowMenu';
import { IDataTableColumn } from '../../../shared/components/DataTable/DataTable/model';
import DataTable from '../../../shared/components/DataTable';
import { userRoleStore } from '../../../shared/singletons';
import { UserRole } from '../model';

const columns: IDataTableColumn<Record<string, unknown>>[] = [
  {
    name: 'NAME',
    selector: 'name',
    sortable: true,
  },
  {
    cell: (userRole) => <UserRoleRowMenu userRole={userRole} />,
    name: 'ACTIONS',
    allowOverflow: true,
    button: true,
    selector: 'actions',
  },
];

type UserRolesListProps = { records: UserRole[]; isLoading: boolean };

const UserRolesList: FunctionComponent<UserRolesListProps> = (props: UserRolesListProps) => {
  const { records = [], isLoading = false } = props;

  return isLoading ? (
    <LoadingSpinner />
  ) : (
    <Box>
      <EditUserRoleModal />
      <NewUserRoleModal />
      <Grid justify="space-between" container spacing={3}>
        <Grid item>
          <Typography variant="h1" component="h1">
            User Roles
          </Typography>
        </Grid>
        <Grid item>
          <Button variant="contained" color="primary" onClick={() => userRoleStore.setState({ createModalOpen: true })}>
            Create New User Role +
          </Button>
        </Grid>
      </Grid>
      <br />
      <Card>
        <CardContent>
          <DataTable noHeader columns={columns} data={records} striped highlightOnHover pointerOnHover />
        </CardContent>
      </Card>
    </Box>
  );
};

export default UserRolesList;
