import React, { FunctionComponent } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((_theme) => ({
  card: {
    flexShrink: 0,
    textAlign: 'center',
    minHeight: '100px',
  },
  loadingIcon: {
    flexShrink: 0,
    marginTop: '30px',
  },
}));

const LoadingSpinner: FunctionComponent = () => {
  const classes = useStyles();
  return (
    <Card className={classes.card}>
      <CircularProgress className={classes.loadingIcon} />
    </Card>
  );
};

export default LoadingSpinner;
