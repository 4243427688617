/* eslint-disable react-hooks/exhaustive-deps */
import React, { FunctionComponent, Dispatch, SetStateAction, useState, useEffect } from 'react';
import { Box, Grid, Typography, makeStyles } from '@material-ui/core';
import ConfirmCancelModal from '../../shared/components/modal/ConfirmCancelModal';
import TextInput from '../../shared/components/form/TextInput';
import { ProductivityDetail, blankProductivityDetail } from '../model';
import { axios } from '../../shared/singletons';

const useStyles = makeStyles({
  textInput: {
    height: 'auto',
    width: '98%',
    margin: '14px 0',
  },
  baseError: {
    marginBottom: '10px',
  },
});

type ProductivityDetailFormModalProps = {
  isOpen: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  productivityDetail: ProductivityDetail;
  setProductivityDetail: Dispatch<SetStateAction<ProductivityDetail>>;
};

const ProductivityDetailFormModal: FunctionComponent<ProductivityDetailFormModalProps> = (
  props: ProductivityDetailFormModalProps,
) => {
  const { isOpen, setOpen, productivityDetail, setProductivityDetail } = props;
  const classes = useStyles();
  const [formValues, setFormValues] = useState(blankProductivityDetail);

  useEffect(() => {
    setFormValues(productivityDetail);
  }, [productivityDetail]);

  const handleFormSubmit = async () => {
    const response = await axios.put(`productivity_details/${productivityDetail?.id}`, {
      productivity_detail: formValues,
    });
    setProductivityDetail(response?.data);
    setOpen(false);
  };

  return (
    <ConfirmCancelModal
      title="Edit Productivity Details"
      cancelLabel="Cancel"
      confirmLabel="Update Details"
      width="768px"
      hasCancelButton
      hasConfirmButton
      isOpen={isOpen}
      onConfirm={handleFormSubmit}
      openStatusChanged={(isOpen) => setOpen(isOpen)}
    >
      <Box p={2}>
        <Typography variant="h2">Productivity Details</Typography>
        <br />
        <Grid item xs={10}>
          <Typography variant="h3">Expected Units</Typography>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <TextInput
              styleOverrides={classes.textInput}
              label="Monday"
              type="units"
              value={formValues?.expected_units_monday}
              valueChanged={(value: string) => setFormValues({ ...formValues, expected_units_monday: value })}
            />
          </Grid>
          <Grid item xs={4}>
            <TextInput
              styleOverrides={classes.textInput}
              label="Tuesday"
              type="units"
              value={formValues?.expected_units_tuesday}
              valueChanged={(value: string) => setFormValues({ ...formValues, expected_units_tuesday: value })}
            />
          </Grid>
          <Grid item xs={4}>
            <TextInput
              styleOverrides={classes.textInput}
              label="Wednesday"
              type="units"
              value={formValues?.expected_units_wednesday}
              valueChanged={(value: string) => setFormValues({ ...formValues, expected_units_wednesday: value })}
            />
          </Grid>
          <Grid item xs={4}>
            <TextInput
              styleOverrides={classes.textInput}
              type="units"
              label="Thursday"
              value={formValues?.expected_units_thursday}
              valueChanged={(value: string) => setFormValues({ ...formValues, expected_units_thursday: value })}
            />
          </Grid>
          <Grid item xs={4}>
            <TextInput
              styleOverrides={classes.textInput}
              type="units"
              label="Friday"
              value={formValues?.expected_units_friday}
              valueChanged={(value: string) => setFormValues({ ...formValues, expected_units_friday: value })}
            />
          </Grid>
        </Grid>
        <br />
        <Grid item xs={10}>
          <Typography variant="h3">Alert Threshold</Typography>
        </Grid>
        <Grid item xs={4}>
          <TextInput
            styleOverrides={classes.textInput}
            type="percent"
            label="Alert Threshold"
            value={formValues?.alert_threshold}
            valueChanged={(value: string) => setFormValues({ ...formValues, alert_threshold: value })}
          />{' '}
        </Grid>
      </Box>
    </ConfirmCancelModal>
  );
};

export default ProductivityDetailFormModal;
