/* eslint-disable react-hooks/exhaustive-deps */
import React, { FunctionComponent, ChangeEvent } from 'react';
import { Button, Typography, makeStyles } from '@material-ui/core';
import TextInput from '../../shared/components/form/TextInput';
import SelectInput from '../../shared/components/form/SelectInput';
import { _translateEach } from '../../shared/utils/translation.utils';
import { studentBaseStore } from '../../shared/singletons';
import { useStoreObservable } from '../../shared/state/useStoreObservable.hook';
import { ReferralsContext } from '../contexts/ReferralsContext';
import { StudentsContext } from '../../students/contexts/StudentsContext';

const useStyles = makeStyles({
  textInput: {
    height: '60px',
    width: '80%',
    margin: '10px',
  },
  formTitle: {
    marginLeft: '12px',
  },
});

const ReferralStudentReviewForm: FunctionComponent = () => {
  const classes = useStyles();

  const [formValues, setFormValues] = React.useState<Record<string, string>>({});
  const { gradeLevelChoices } = useStoreObservable(studentBaseStore);

  const { locationChoices, fetchLocations } = React.useContext(StudentsContext);

  const { updateReferralStudent, referralStudent, formErrors } = React.useContext(ReferralsContext);

  const t = _translateEach({
    firstName: 'students.form.firstName',
    lastName: 'students.form.lastName',
    school: 'students.form.school',
    teacherName: 'students.form.teacherName',
    roomNumber: 'students.form.roomNumber',
    dateOfBirth: 'students.form.dateOfBirth',
    preferredLocation: 'students.form.preferredLocation',
    gradeLevel: 'students.form.gradeLevel',
    defaultSelectOption: 'selectOption.defaultSelectOption',
  });

  // const handleFormValueChange = (key: string, value: string) => {
  //   studentFormStore.setState({ formValues: { ...formValues, [key]: value } });
  // };

  React.useEffect(() => {
    if (referralStudent?.payer_id) {
      fetchLocations(referralStudent?.payer_id);
    }
  }, [referralStudent]);

  React.useEffect(() => {
    studentBaseStore.fetchChoices();
  }, []);

  React.useEffect(() => {
    setFormValues({
      first_name: referralStudent?.first_name,
      last_name: referralStudent?.last_name,
      birthdate: referralStudent?.birthdate,
      payer_id: referralStudent?.payer_id,
      teacher: referralStudent?.teacher,
      room_number: referralStudent?.room_number,
      location_id: referralStudent?.location_id,
      grade_level_id: referralStudent?.grade_level_id,
      email_student: referralStudent?.email_student,
    });
  }, [referralStudent]);

  // Required Fields are all the form fields except for email
  const requiredFields = [
    'first_name',
    'last_name',
    'birthdate',
    'teacher',
    'room_number',
    'location_id',
    'grade_level_id',
  ];

  const shouldDisableSubmit = requiredFields.some((field) => !formValues[field]);

  return (
    <form noValidate autoComplete="off">
      <Typography className={classes.formTitle} variant="h2" component="h2">
        Student Details
      </Typography>
      <TextInput
        styleOverrides={classes.textInput}
        type="text"
        shrinkLabel
        label={t.firstName}
        errorMessage={formErrors?.first_name}
        value={formValues?.first_name}
        valueChanged={(value: string) => setFormValues({ ...formValues, first_name: value })}
      />
      <TextInput
        styleOverrides={classes.textInput}
        type="text"
        shrinkLabel
        label={t.lastName}
        errorMessage={formErrors?.last_name}
        value={formValues?.last_name}
        valueChanged={(value: string) => setFormValues({ ...formValues, last_name: value })}
      />
      <TextInput
        styleOverrides={classes.textInput}
        type="date"
        shrinkLabel
        label={t.dateOfBirth}
        errorMessage={formErrors?.birthdate}
        value={formValues?.birthdate}
        valueChanged={(value: string) => setFormValues({ ...formValues, birthdate: value })}
      />
      <TextInput
        styleOverrides={classes.textInput}
        type="text"
        shrinkLabel
        label={t.teacherName}
        errorMessage={formErrors?.teacher}
        value={formValues?.teacher}
        valueChanged={(value: string) => setFormValues({ ...formValues, teacher: value })}
      />
      <TextInput
        styleOverrides={classes.textInput}
        type="text"
        shrinkLabel
        label={t.roomNumber}
        errorMessage={formErrors?.room_number}
        value={formValues?.room_number}
        valueChanged={(value: string) => setFormValues({ ...formValues, room_number: value })}
      />
      <SelectInput
        styleOverrides={classes.textInput}
        type="text"
        label={t.preferredLocation}
        errorMessage={formErrors?.location_id}
        value={formValues?.location_id}
        choices={locationChoices}
        onChange={(e: ChangeEvent<HTMLInputElement>) => setFormValues({ ...formValues, location_id: e.target.value })}
      />
      <SelectInput
        styleOverrides={classes.textInput}
        type="text"
        label={t.gradeLevel}
        errorMessage={formErrors?.grade_level_id}
        value={formValues?.grade_level_id}
        choices={gradeLevelChoices}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          setFormValues({ ...formValues, grade_level_id: e.target.value })
        }
      />
      <TextInput
        styleOverrides={classes.textInput}
        type="text"
        shrinkLabel
        label="Email (Optional)"
        errorMessage={formErrors?.email_student}
        value={formValues?.email_student}
        valueChanged={(value: string) => setFormValues({ ...formValues, email_student: value })}
      />
      <Button
        variant="contained"
        color="primary"
        onClick={() => updateReferralStudent(referralStudent.id, formValues)}
        disabled={shouldDisableSubmit}
      >
        Confirm Student Information
      </Button>
    </form>
  );
};

export default ReferralStudentReviewForm;
