import React, { FunctionComponent, ChangeEvent, useEffect } from 'react';
import { Button, Grid, Typography } from '@material-ui/core';
import TabLabels from '../../shared/components/TabLabels';
import TabLabel from '../../shared/components/TabLabel';
import TabPanel from '../../shared/components/TabPanel';
import LoadingSpinner from '../../shared/components/LoadingSpinner';
import { listingFeeStore } from '../../shared/singletons';
import SchoolDetailsTab from './SchoolDetailsTab';
import AcademicYearsTab from './AcademicYearsTab';
import SchoolLocationsTab from './SchoolLocationsTab';
import NewListingFeeModal from '../../listingFees/components/NewListingFeeModal';
import SchoolCustomActions from './SchoolCustomActions';
import InvoiceReport from '../../billings/components/InvoiceReport';
import ListingFeesList from '../../listingFees/components/ListingFeesList';
import { School } from '../model';
import Breadcrumbs, { BreadcrumbLink } from '../../shared/components/Breadcrumbs';
import { UserContext } from '../../auth/contexts/userContext';

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

interface SchoolDetailProps {
  school: School;
  schoolLoading: boolean;
  tab?: string;
}

const SchoolDetail: FunctionComponent<SchoolDetailProps> = (props) => {
  const { school, schoolLoading, tab } = props;
  const [value, setValue] = React.useState(0);
  const { currentUserHasRole } = React.useContext(UserContext);

  const links: BreadcrumbLink[] = [
    {
      href: '/schools',
      text: 'SCHOOLS',
    },
    {
      href: `/schools/${school.id}`,
      text: school.name,
    },
  ];

  const detailsObject = { value: 'details', label: 'Details', component: SchoolDetailsTab };
  const academicYearsObject = { value: 'academic_years', label: 'Academic Years', component: AcademicYearsTab };
  const locationsObject = { value: 'locations', label: 'Locations', component: SchoolLocationsTab };
  const customActionsObject = { value: 'custom_actions', label: 'Actions', component: SchoolCustomActions };
  const listingFeesObject = { value: 'listing_fees', label: 'Listing Fees', component: ListingFeesList };
  const billingObject = { value: 'billings', label: 'Billings', component: InvoiceReport };

  let objectsByRole = [];

  // TODO: Add roles to a base array to better account for users who have multiple roles
  if (currentUserHasRole('system_owner')) {
    objectsByRole = [detailsObject, academicYearsObject, listingFeesObject, billingObject];
  } else if (currentUserHasRole('accountant')) {
    objectsByRole = [detailsObject, listingFeesObject, billingObject];
  } else if (currentUserHasRole('supervisor')) {
    objectsByRole = [detailsObject];
  } else if (currentUserHasRole('admin')) {
    objectsByRole = [detailsObject, locationsObject, academicYearsObject, customActionsObject];
  } else if (currentUserHasRole('services_coordinator')) {
    objectsByRole = [detailsObject];
  }

  const handleChange = (_event: ChangeEvent, newValue: number) => {
    setValue(newValue);
    window.history.pushState('', '', `/schools/${school.id}/${objectsByRole[newValue]?.value}`);
  };

  useEffect(() => {
    if (tab) {
      setValue(objectsByRole.findIndex((obj) => obj.value === tab));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab]);

  return schoolLoading ? (
    <LoadingSpinner />
  ) : (
    <div>
      <NewListingFeeModal />
      <Grid justify="space-between" container spacing={3}>
        <Grid item>
          <Typography variant="h1" component="h1">
            {school.name}
          </Typography>
          <Breadcrumbs links={links} />
          <br />
        </Grid>
        <Grid item>
          {currentUserHasRole('system_owner') && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => listingFeeStore.setCreateModalOpen(true, school)}
            >
              Add New Listing Fee +
            </Button>
          )}
        </Grid>
      </Grid>
      <div>
        <TabLabels variant="scrollable" value={value} onChange={handleChange} aria-label="student tabs">
          {objectsByRole.map((obj, index) => (
            <TabLabel key={index} label={obj.label} other={a11yProps(index)} />
          ))}
        </TabLabels>
        {objectsByRole.map((obj, index) => {
          const Component = obj.component;
          return (
            <TabPanel key={index} value={value} index={index}>
              <Component school={school} schoolLoading={schoolLoading} />
            </TabPanel>
          );
        })}
      </div>
    </div>
  );
};

export default SchoolDetail;
