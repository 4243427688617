import React from 'react';
import { ErrorOutline } from '@material-ui/icons';

type AlertIconLinkCellProps = {
  row: any;
  itemCount: number;
  linkEnd: string;
};

const AlertIconLinkCell: React.FC<AlertIconLinkCellProps> = (props: AlertIconLinkCellProps) => {
  const { row, itemCount, linkEnd } = props;

  return (
    <div>
      {itemCount > 0 ? (
        <a href={`/students/${row.id}/${linkEnd}`} target="_blank" rel="noopener noreferrer">
          <ErrorOutline
            style={{ marginRight: '4px', top: '4px', position: 'relative', fontSize: '1.4em' }}
            fontSize="small"
          />
          {itemCount}
        </a>
      ) : (
        0
      )}
    </div>
  );
};

export default AlertIconLinkCell;
