import React, { FunctionComponent, useEffect } from 'react';
import SchoolsList from '../components/SchoolsList';
import { useStoreObservable } from '../../shared/state/useStoreObservable.hook';
import { schoolRecordStore } from '../../shared/singletons';
import { UserContext } from '../../auth/contexts/userContext';

export type SchoolsListPageProps = Record<string, unknown>;

const SchoolsListPage: FunctionComponent<SchoolsListPageProps> = () => {
  const schoolState = useStoreObservable(schoolRecordStore);
  const { filteredRecords, records, recordsLoading, totalRecords } = schoolState;
  const { currentUserHasRole } = React.useContext(UserContext);

  useEffect(() => {
    schoolRecordStore.fetchRecords('ransack[discharged_eq]=false&');
  }, [currentUserHasRole]);

  return (
    <SchoolsList
      records={filteredRecords || records}
      recordsLoading={recordsLoading}
      showFilterBar
      totalRecords={totalRecords}
    />
  );
};

export default SchoolsListPage;
