import React from 'react';
import { Student, StudentResponse, StudentFormValues, StudentChoicesResponse, StudentFormErrors } from '../model';
import { axios } from '../../shared/singletons';
import { API_ENDPOINT } from '../../../config/env';
import { SelectOption } from '../../shared/common.model';
import { today } from '../../shared/utils/date.utils';
import { useHistory } from 'react-router-dom';

interface StudentsContextInterface {
  fetchStudents?: (params: string, page: number, per: number) => Promise<void>;
  students?: Student[];

  updateStudent?: (
    studentId: string,
    formValues: StudentFormValues,
    onSuccess?: (studentId: string) => void,
  ) => Promise<void>;

  deleteStudent?: (studentId: string) => void;

  createStudent?: (formValues: StudentFormValues, onSuccess?: (studentId: string) => void) => Promise<void>;

  fetchStudent?: (studentId: string) => Promise<void>;
  student?: Student;
  fetchChoices?: (payerId: string) => Promise<void>;
  fetchLocations?: (payerId: string) => Promise<void>;
  newStudentFormChoices?: Record<string, string>;
  formErrors?: StudentFormErrors;
  schoolChoices?: SelectOption[];
  locationChoices?: SelectOption[];
  gradeLevelChoices?: SelectOption[];
  statusChoices?: SelectOption[];
  userChoices?: SelectOption[];
  studentsLoading?: boolean;
}

const StudentsContext = React.createContext<StudentsContextInterface>({});
const StudentsContextConsumer = StudentsContext.Consumer;

const StudentsContextProvider: React.FC = ({ children }) => {
  const [students, setStudents] = React.useState<Student[]>([]);
  const [student, setStudent] = React.useState<Student>({});
  const [studentsLoading, setStudentsLoading] = React.useState(false);
  const [formErrors, setFormErrors] = React.useState<StudentFormErrors>({});
  const [schoolChoices, setSchoolChoices] = React.useState<SelectOption[]>([]);
  const [locationChoices, setLocationChoices] = React.useState<SelectOption[]>([]);
  const [gradeLevelChoices, setGradeLevelChoices] = React.useState<SelectOption[]>([]);
  const [statusChoices, setStatusChoices] = React.useState<SelectOption[]>([]);
  const [userChoices, setUserChoices] = React.useState<SelectOption[]>([]);
  const history = useHistory();

  const [filterValues] = React.useState({
    'ransack[discharged_eq]': 'false',
    'appointment_ransack[start_time_gteq]': today(),
    'appointment_ransack[start_time_lteq]': today(),
  });

  const fetchStudents = async (params = formattedFilterParams(), page = 1, per = 10) => {
    setStudentsLoading(true);
    saveStudentFilters();

    axios(`${API_ENDPOINT}/patients.json?${params}page=${page}&per=${per}`)
      .then((result) => {
        // this.setState({ totalRecords: result?.data?.total_records });
        return result?.data?.result ?? [];
      })
      .then((records) => {
        setStudentsLoading(false);
        setStudents(records);
      })
      .catch(() => {
        setStudentsLoading(false);
      });
  };

  const fetchStudent = async (studentId: string) => {
    setStudentsLoading(true);

    // const formattedStudentId = studentId || this?.getState()?.student?.id;

    await axios(`${API_ENDPOINT}/patients/${studentId}.json?`)
      .then((result) => result?.data?.result ?? [])
      .then((student) => {
        setStudentsLoading(false);
        setStudent(student);
      })
      .catch(() => {
        setStudentsLoading(false);
      });
  };

  const updateStudent = async (
    studentId: string,
    formValues: StudentFormValues,
    onSuccess?: (studentId: string) => void,
  ) => {
    try {
      await axios
        .put<string, StudentResponse>(`${API_ENDPOINT}/patients/${studentId}.json`, {
          patient: formValues,
          headers: { 'Content-Type': 'application/json' },
        })
        .then((response) => {
          if (onSuccess) {
            onSuccess(response.data.result.id);
          }
        });
    } catch (error) {
      setFormErrors(error.response?.data);
    }
  };

  const createStudent = async (formValues: StudentFormValues, onSuccess?: (studentId: string) => void) => {
    try {
      await axios
        .post<string, StudentResponse>(`${API_ENDPOINT}/patients.json`, {
          patient: formValues,
          headers: { 'Content-Type': 'application/json' },
        })
        .then((response) => {
          if (onSuccess) {
            onSuccess(response.data.result.id);
          }
        });
    } catch (error) {
      setFormErrors(error.response?.data);
    }
  };

  const deleteStudent = (studentId: string) => {
    axios
      .delete<string, StudentResponse>(`${API_ENDPOINT}/patients/${studentId}.json`, {
        headers: { 'Content-Type': 'application/json' },
      })
      .then(() => {
        history.push(`/students`);
      });
  };

  const fetchChoices = async (payerId) => {
    axios.get<string, StudentChoicesResponse>(`${API_ENDPOINT}/patients/new.json`).then((r: StudentChoicesResponse) => {
      const { schools, grade_levels, locations, users } = r.data?.result;

      setSchoolChoices(schools);
      setGradeLevelChoices(grade_levels);

      if (payerId) {
        axios.get(`${API_ENDPOINT}/locations/${payerId}/location_choices_by_school.json`).then((response) => {
          setLocationChoices(response?.data?.location_choices_by_school);
        });
      } else {
        setLocationChoices(locations);
      }

      setUserChoices(users);
      setStatusChoices([
        { label: 'Active', value: 'false' },
        { label: 'Inactive', value: 'true' },
      ]);
    });
  };

  const fetchLocations = async (payerId) => {
    axios.get(`${API_ENDPOINT}/locations/${payerId}/location_choices_by_school.json`).then((response) => {
      setLocationChoices(response?.data?.location_choices_by_school);
    });
  };

  const formattedFilterParams = () => {
    return Object?.keys(filterValues)?.reduce(
      (params, key, _index: number) => `${params}${key}=${filterValues[key]}&` || '',
      '',
    );
  };

  const saveStudentFilters = () => {
    const savedStudentState = {
      filterValues: {
        'ransack[last_name_cont]': filterValues['ransack[last_name_cont]'],
        'ransack[slug_cont]': filterValues['ransack[slug_cont]'],
        'ransack[payer_id_eq]': filterValues['ransack[payer_id_eq]'],
        'ransack[created_by_eq]': filterValues['ransack[created_by_eq]'],
        'ransack[discharged_eq]': filterValues['ransack[discharged_eq]'],

        'appointment_ransack[start_time_gteq]': today(),
        'appointment_ransack[start_time_lteq]': today(),
      },
      loadListPageRecords: true,
    };

    sessionStorage.setItem('studentState', JSON.stringify(savedStudentState));
  };

  return (
    <StudentsContext.Provider
      value={{
        students,
        fetchStudents,

        fetchStudent,
        student,

        updateStudent,
        createStudent,
        deleteStudent,

        studentsLoading,
        fetchChoices,
        fetchLocations,

        formErrors,
        schoolChoices,
        gradeLevelChoices,
        locationChoices,
        statusChoices,
        userChoices,
      }}
    >
      {children}
    </StudentsContext.Provider>
  );
};

export { StudentsContextProvider, StudentsContextConsumer, StudentsContext };
