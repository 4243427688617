import React, { FunctionComponent, useEffect, useContext, useState } from 'react';
import { CircularProgress, Button, Grid } from '@mui/material';
import { useParams } from 'react-router-dom';
import DataTable from '../../shared/components/DataTable';
import { IDataTableColumn } from '../../shared/components/DataTable/DataTable/model';
import InformationField from '../../shared/components/InformationField';
import { SchoolRouteParams } from '../model';
import { Student } from '../../students/model';
import { DischargeStudentContext } from '../../students/contexts/DischargeStudentContext';
import DischargeStudentModal from '../components/DischargeStudentModal';
import DischargeSchoolModal from '../components/DischargeSchoolModal';
import AlertIconLinkCell from '../components/AlertIconLinkCell';

export type DischargeSchoolPageProps = Record<string, unknown>;

const DischargeSchoolPage: FunctionComponent<DischargeSchoolPageProps> = () => {
  const { schoolId } = useParams<SchoolRouteParams>();

  const {
    fetchPreDischargeData,
    schoolToDischarge,
    relevantStudents,
    totals,
    studentsToDischarge,
    setStudentsToDischarge,
    dischargeStudentsModalOpen,
    setDischargeStudentsModalOpen,
  } = useContext(DischargeStudentContext);

  const [dischargeSchoolModalOpen, setDischargeSchoolModalOpen] = useState(false);

  useEffect(() => {
    fetchPreDischargeData(schoolId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schoolId]);

  const handleDischargeStudentsButtonClicked = () => {
    setDischargeStudentsModalOpen(true);
  };

  const columns: IDataTableColumn<Student>[] = [
    {
      name: 'Timeline ID',
      selector: 'slug',
      sortable: true,
      cell: (row) => (
        <a target="_blank" rel="noopener noreferrer" href={`/students/${row.id}/`}>
          {row.slug}
        </a>
      ),
    },
    {
      name: 'Active Services',
      selector: 'active_service_count',
      sortable: true,
      cell: (row: any) => <AlertIconLinkCell row={row} itemCount={row.active_service_count} linkEnd="services" />,
    },
    {
      name: 'Active Appointments',
      selector: 'active_appointment_count',
      sortable: true,
      cell: (row: any) => (
        <AlertIconLinkCell row={row} itemCount={row.active_appointment_count} linkEnd="appointments" />
      ),
    },
    {
      name: 'Active Renewals',
      selector: 'active_renewal_count',
      sortable: true,
      cell: (row: any) => <AlertIconLinkCell row={row} itemCount={row.active_renewal_count} linkEnd="renewals" />,
    },
    {
      name: 'Status',
      selector: 'status',
      sortable: true,
    },
  ];

  return schoolToDischarge ? (
    <>
      <DischargeStudentModal />
      <DischargeSchoolModal
        dischargeSchoolModalOpen={dischargeSchoolModalOpen}
        setDischargeSchoolModalOpen={setDischargeSchoolModalOpen}
        schoolToDischarge={schoolToDischarge}
      />
      {schoolToDischarge.discharged ? (
        <h1 className="MuiTypography-root MuiTypography-h1">
          {schoolToDischarge.name} ({schoolToDischarge.abbreviation}) &mdash; DISCHARGED
        </h1>
      ) : (
        <h1 className="MuiTypography-root MuiTypography-h1">{`Discharging ${schoolToDischarge.name} (${schoolToDischarge.abbreviation})`}</h1>
      )}

      <Grid container flex-direction="column">
        <Grid item xs={3}>
          <InformationField label="Total Active Students" value={totals?.total_active_students} />
        </Grid>
        <Grid item xs={3}>
          <InformationField label="Total Active Services" value={totals?.total_active_services} />
        </Grid>
        <Grid item xs={3}>
          <InformationField label="Total Active Appointments" value={totals?.total_active_appointments} />
        </Grid>
        <Grid item xs={3}>
          <InformationField label="Total Active Renewals" value={totals?.total_active_renewals} />
        </Grid>
      </Grid>
      <br />
      <>
        <h1 className="MuiTypography-root MuiTypography-h1">Active Students</h1>
        <DataTable
          columns={columns}
          noHeader
          data={relevantStudents}
          pagination
          paginationPerPage={50}
          striped
          highlightOnHover
          selectableRows
          selectedRows={studentsToDischarge}
          onSelectedRowsChange={({ selectedRows }) => setStudentsToDischarge(selectedRows)}
        />
      </>
      <br />
      <div>
        <Button
          variant="contained"
          style={{ backgroundColor: '#F44F64' }}
          color="primary"
          onClick={handleDischargeStudentsButtonClicked}
          disabled={studentsToDischarge.length === 0 || dischargeStudentsModalOpen}
        >
          Discharge Selected Students
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={schoolToDischarge.discharged || totals?.total_active_students > 0}
          style={{ marginLeft: 7 }}
          onClick={() => setDischargeSchoolModalOpen(true)}
        >
          Discharge School
        </Button>
      </div>
    </>
  ) : (
    <CircularProgress />
  );
};

export default DischargeSchoolPage;
