import React, { FunctionComponent } from 'react';
import ConfirmCancelModal from '../../shared/components/modal/ConfirmCancelModal';
import SchoolForm from './SchoolForm';
import { useStoreObservable } from '../../shared/state/useStoreObservable.hook';
import { schoolFormStore } from '../../shared/singletons';

const SchoolModal: FunctionComponent = () => {
  const { formModalOpen, formValues, formModalSchool: school } = useStoreObservable(schoolFormStore);

  return (
    <ConfirmCancelModal
      isOpen={formModalOpen}
      title="Create New School"
      onConfirm={() => schoolFormStore.createOrUpdateSchool(formValues, school.id)}
      confirmLabel={school?.id ? 'Update School' : 'Create School'}
      openStatusChanged={(isOpen) => schoolFormStore.setCreateModalOpen(isOpen)}
    >
      <SchoolForm />
    </ConfirmCancelModal>
  );
};

export default SchoolModal;
