import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import StudentForm from '../components/StudentForm';
import StudentBuilderProgressStepper from '../components/StudentBuilderProgressStepper';

const NewStudentPage: React.FunctionComponent = () => {
  const [student, setStudent] = React.useState({});
  const history = useHistory();

  React.useEffect(() => {
    setStudent({
      first_name: '',
      last_name: '',
      payer_id: '',
      teacher: '',
      room_number: '',
      birthdate: '',
      location_id: '',
      grade_level_id: '',
      email_student: '',
      address_line1: '',
      address_line2: '',
      address_city: '',
      address_state: '',
      address_zip: '',
    });
  }, [setStudent]);

  return (
    <>
      <div style={{ margin: 10 }}>
        <Grid container spacing={1}>
          <Typography variant="h1" component="h1">
            Create New Student
          </Typography>
        </Grid>
        <StudentBuilderProgressStepper activeStep={0} />
        <br />
      </div>
      <Grid container flex-direction="column">
        <Grid item xs={10}>
          <StudentForm
            student={student}
            onSuccess={(studentId: string) => history.push(`/students/${studentId}/edit_contact_info`)}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default NewStudentPage;
