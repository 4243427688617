import React, { FunctionComponent, useEffect, useState, useContext } from 'react';
import ReferralsList from '../components/ReferralsList';
import { ServiceFormOptionsContext } from '../../services/contexts/ServiceFormOptionsContext';

export type ReferralsListPageProps = Record<string, unknown>;

const ReferralsListPage: FunctionComponent<ReferralsListPageProps> = () => {
  const { fetchChoices } = useContext(ServiceFormOptionsContext);

  const [choicesFetched, setChoicesFetched] = useState(false);

  useEffect(() => {
    if (!choicesFetched) {
      fetchChoices();
      setChoicesFetched(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [choicesFetched]);

  return (
    <ReferralsList
      showCreateButton={false}
      showFilterBar
      initialFilter={{
        'ransack[phase_eq]': 'referral',
        'ransack[status_eq]': 'active',
      }}
    />
  );
};

export default ReferralsListPage;
