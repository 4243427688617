import { axios, alertStore, appointmentRecordStore } from '../shared/singletons';
import { BaseFormStore } from '../shared/state/baseForm.store';
import { Appointment, AppointmentFormValues, AppointmentResponse, AppointmentFormErrors } from './model';

export class AppointmentFormState {
  isLoading: boolean;
  isSubmitting: boolean;

  formValues: AppointmentFormValues;
  formErrors: AppointmentFormErrors;
  baseErrorAlertVisible: boolean;
  formStepperIndex: number;

  formModalOpen: boolean;
  formModalAppointment: Appointment;
  detailsModalOpen: boolean;
  detailsModalAppointment: Appointment;
  rescheduleModalOpen: boolean;
  rescheduleModalAppointment: Appointment;
  editStatusModalOpen: boolean;
  editStatusModalAppointment: Appointment;
  deleteModalOpen: boolean;

  static create(props: Partial<AppointmentFormState>): AppointmentFormState {
    const defaults: AppointmentFormState = {
      isLoading: false,
      isSubmitting: false,

      formValues: {},
      formErrors: {},
      baseErrorAlertVisible: true,
      formStepperIndex: 0,

      formModalOpen: false,
      detailsModalOpen: false,
      rescheduleModalOpen: false,
      editStatusModalOpen: false,
      deleteModalOpen: false,

      formModalAppointment: {},
      detailsModalAppointment: {},
      rescheduleModalAppointment: {},
      editStatusModalAppointment: {},
    };
    return Object.assign(new AppointmentFormState(), defaults, props || {});
  }
}

export class AppointmentFormStore extends BaseFormStore<AppointmentFormState> {
  constructor() {
    super(AppointmentFormState.create({}));
  }

  public async createOrUpdateAppointment(
    formValues: AppointmentFormValues,
    studentId: string,
    appointmentId: string,
    onSuccess?: () => void,
  ): Promise<void> {
    try {
      this.setState({ isSubmitting: true });
      const response = await axios[appointmentId ? 'put' : 'post']<string, AppointmentResponse>(
        `patients/${studentId}/appointments${appointmentId ? `/${appointmentId}` : ''}.json`,
        {
          appointment: formValues,
          headers: { 'Content-Type': 'application/json' },
        },
      );

      if (onSuccess) {
        onSuccess();
        this.setState({
          editStatusModalOpen: false,
          rescheduleModalOpen: false,
          formModalOpen: false,
          isSubmitting: false,
        });
      } else {
        appointmentRecordStore.setState({ appointment: response?.data?.result });

        this.setState({
          formStepperIndex: 2,
          editStatusModalOpen: false,
          rescheduleModalOpen: false,
          isSubmitting: false,
        });
      }
    } catch (error) {
      this.setState({
        formErrors: error.response?.data,
        isSubmitting: false,
        baseErrorAlertVisible: !!error.response?.data?.base, // The base errors are not tied to a specific field and displayed at the top of the form
      });
    }
  }

  public deleteAppointment(appointmentId: string, onDelete: () => void): void {
    axios
      .delete<string, AppointmentResponse>(`appointments/${appointmentId}.json`, {
        headers: { 'Content-Type': 'application/json' },
      })
      .then(() => {
        if (onDelete) {
          onDelete();
        } else {
          appointmentRecordStore.fetchRecords();
        }

        this.setState({ deleteModalOpen: false });
      })
      .catch(() => {
        alertStore.alertError('appointments.alert.delete.error');
      });
  }

  public deleteFutureAppointments(appointmentId: string, onDelete: () => void): void {
    axios
      .delete<string, AppointmentResponse>(`appointments/${appointmentId}.json?destype=fwd`, {
        headers: { 'Content-Type': 'application/json' },
      })
      .then(() => {
        if (onDelete) {
          onDelete();
        } else {
          appointmentRecordStore.fetchRecords();
        }

        this.setState({ deleteModalOpen: false });
      })
      .catch(() => {
        alertStore.alertError('appointments.alert.delete.error');
      });
  }

  public setEditModalOpen(isOpen: boolean, appointment: Appointment): void {
    this.clearFormValues();

    if (appointment) {
      this.spreadAppointmentFormValues(appointment, {});

      this.setState({
        formModalOpen: isOpen,
        formModalAppointment: appointment,
        formStepperIndex: appointment.id ? 1 : 0,
      });
    }
  }

  public setDetailsModalOpen(isOpen: boolean, appointment: Appointment): void {
    this.clearFormValues();

    if (appointment) {
      this.spreadAppointmentFormValues(appointment, {});
      this.setState({ detailsModalOpen: isOpen, detailsModalAppointment: appointment });
    }
  }

  public setRescheduleModalOpen(isOpen: boolean, appointment: Appointment): void {
    this.clearFormValues();

    if (appointment) {
      this.spreadAppointmentFormValues(appointment, {});
      this.setState({ rescheduleModalOpen: isOpen, rescheduleModalAppointment: appointment });
    }
  }

  public setEditStatusModalOpen(isOpen: boolean, appointment: Appointment): void {
    this.clearFormValues();

    if (appointment) {
      this.spreadAppointmentFormValues(appointment, {});
      this.setState({ editStatusModalOpen: isOpen, editStatusModalAppointment: appointment });
    }
  }

  protected clearFormValues(): void {
    this.setState({ formValues: {} });
  }

  protected spreadAppointmentFormValues(appointment: Appointment, customValues: Record<string, string>): void {
    this.setState({
      formValues: {
        referral_service_id: appointment.referral_service_id,
        schedule_date: appointment.schedule_date,
        start_time: appointment.start_time,
        end_time: appointment.end_time,
        admin_units_override: appointment.admin_units_override,
        location_id: appointment.location_id,
        student_id: appointment.student_id,
        recurrence: appointment.recurrence,
        end_recurrence: appointment.end_recurrence,
        user_id: appointment.user_id,
        original_date: appointment.original_date,
        status: appointment.status,
        comment: '',
        ...customValues,
      },
    });
  }
}
