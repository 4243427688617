import React, { useState, useContext, useEffect, FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import ConfirmCancelModal from '../../shared/components/modal/ConfirmCancelModal';
import TextInput from '../../shared/components/form/TextInput';
import SelectInput from '../../shared/components/form/SelectInput';
import axios from '../../shared/utils/axios.utils';
import { TrainingsContext } from '../contexts/TrainingsContext';
import { Typography, FormControlLabel, Checkbox } from '@material-ui/core';
import { nameConversions } from '../constants/constants';
import { TrainingFormValues } from '../model';

const useStyles = makeStyles({
  textInput: {
    height: '60px',
    width: '80%',
    margin: '10px 0',
  },
  baseError: {
    marginBottom: '10px',
  },
});

const joyrideTrackingNameChoices = [{ label: 'Referral Service Stage Change', value: 'referral_service_stage_change' }];

interface NewTrainingModalProps {}

const NewTrainingModal: FunctionComponent<NewTrainingModalProps> = (props: NewTrainingModalProps) => {
  const { trainingFormModalOpen, setTrainingFormModalOpen, trainingToEdit, setTrainingToEdit } = useContext(
    TrainingsContext,
  );
  const history = useHistory();
  const classes = useStyles();
  const [formValues, setFormValues] = useState<TrainingFormValues>({});
  const [roleChoices, setRoleChoices] = useState([]);
  const [rolesChosen, setRolesChosen] = useState([]);

  const handleSubmit = async (): Promise<void> => {
    setTrainingFormModalOpen(false);

    formValues['role_ids'] = rolesChosen;

    if (formValues.id) {
      await axios.put(`manage_trainings/${formValues.id}`, { training: formValues });
    } else {
      await axios.post(`manage_trainings`, { training: formValues });
    }
    history.push(`/manage_trainings`);
  };

  const closeModal = () => {
    setTrainingToEdit({});
    setTrainingFormModalOpen(false);
  };

  useEffect(() => {
    if (!trainingToEdit.id) return;

    setFormValues(trainingToEdit);
    setRolesChosen(roleChoices.filter(({ name }) => trainingToEdit.role_names?.includes(name)).map(({ id }) => id));
  }, [trainingToEdit, roleChoices]);

  const fetchRoles = async () => {
    const response = await axios.get(`roles`);
    const roles = response?.data?.result;
    const filteredRoles = roles.filter((element) =>
      ['admin', 'payer_admin', 'therapist', 'supervisor'].includes(element.name),
    );
    setRoleChoices(filteredRoles);
  };

  useEffect(() => {
    fetchRoles();
  }, []);

  const handleSelectRole = (id) => {
    if (rolesChosen.includes(id)) {
      const newArray = rolesChosen.filter((elem) => {
        return elem !== id;
      });
      setRolesChosen(newArray);
    } else {
      setRolesChosen([...rolesChosen, id]);
    }
  };

  return (
    <ConfirmCancelModal
      isOpen={trainingFormModalOpen}
      title="Create Training"
      confirmLabel="Submit"
      openStatusChanged={closeModal}
      onConfirm={handleSubmit}
    >
      <form noValidate autoComplete="off">
        <TextInput
          styleOverrides={classes.textInput}
          value={formValues.name}
          label="Training Name"
          valueChanged={(value: string) => setFormValues({ ...formValues, name: value })}
        />
        <br />
        <TextInput
          styleOverrides={classes.textInput}
          value={formValues.description}
          label="Training Description (1-2 sentences)"
          valueChanged={(value: string) => setFormValues({ ...formValues, description: value })}
        />
        <br />
        <TextInput
          styleOverrides={classes.textInput}
          value={formValues.content_link}
          label="Content Link"
          valueChanged={(value: string) => setFormValues({ ...formValues, content_link: value })}
        />
        <br />
        <SelectInput
          label="Joyride Tracking Name"
          styleOverrides={classes.textInput}
          choices={joyrideTrackingNameChoices}
          type="text"
          value={formValues?.joyride_tracking_name}
          valueChanged={(value: string) => setFormValues({ ...formValues, joyride_tracking_name: value })}
        />
        <br />
        <>
          <Typography variant="subtitle1" component="h5">
            Roles
          </Typography>
          {roleChoices.map((role) => (
            <FormControlLabel
              control={
                <Checkbox name={role.name} value={role.id} color="primary" onChange={() => handleSelectRole(role.id)} />
              }
              label={nameConversions[role.name]}
              checked={rolesChosen.includes(role.id)}
            />
          ))}
        </>
      </form>
    </ConfirmCancelModal>
  );
};

export default NewTrainingModal;
