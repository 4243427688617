import React, { FunctionComponent } from 'react';
import { Link } from '@material-ui/core';

const NoUserMessage: FunctionComponent = () => {
  return (
    <>
      <Link style={{ lineHeight: '42px' }} href="/login">
        &#8249; Back to Login
      </Link>
      <p>The link you followed is invalid or expired</p>
    </>
  );
};

export default NoUserMessage;
