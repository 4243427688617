import React, { useState, useEffect, FunctionComponent, Dispatch, SetStateAction } from 'react';
import SelectInput from '../../shared/components/form/SelectInput';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';

import axios from '../../shared/utils/axios.utils';

import { Invoice } from '../model';
import { Colors } from '../../shared/style/colors';

let statusColors = {
  'Review Needed': Colors.Red,
  'Under Review': Colors.BabyBlue,
  Finalized: Colors.PurpleThree,
  Hold: Colors.Orange,
  Sent: Colors.Success,
};

let statusOptions = [
  { value: 'Finalized', label: 'Finalized' },
  { value: 'Hold', label: 'Hold' },
  { value: 'Sent', label: 'Sent' },
];

let useStyles = makeStyles({
  selectInput: {
    width: '200px',
    height: '100%',
    marginBottom: '10px',
    textTransform: 'uppercase',
  },
});

interface InvoiceQuickStatusChangeProps {
  invoiceToEdit: Invoice;
  setFinalizeModalOpen: Dispatch<SetStateAction<boolean>>;
  setSentModalOpen: Dispatch<SetStateAction<boolean>>;
  setInvoiceForModal: Dispatch<SetStateAction<Invoice>>;
}

const InvoiceQuickStatusChange: FunctionComponent<InvoiceQuickStatusChangeProps> = (props) => {
  let classes = useStyles();
  let { invoiceToEdit, setInvoiceForModal, setFinalizeModalOpen, setSentModalOpen } = props;

  let [newStatus, setNewStatus] = useState<string>('');

  useEffect(() => {
    setNewStatus(invoiceToEdit.status);
  }, [invoiceToEdit]);

  if (invoiceToEdit?.status === 'Under Review' && !statusOptions.find((option) => option.value === 'Under Review')) {
    statusOptions.unshift({ value: 'Under Review', label: 'Under Review' });
  }

  let finalizing = (newStatus) => {
    if (invoiceToEdit?.status === 'Under Review' && ['Finalized', 'Hold'].includes(newStatus)) {
      return true;
    }

    return false;
  };

  let handleValueChanged = async (value) => {
    setNewStatus(value);
    if (finalizing(value)) {
      setInvoiceForModal(invoiceToEdit);
      setFinalizeModalOpen(true);
    } else if (value === 'Sent') {
      setInvoiceForModal(invoiceToEdit);
      setSentModalOpen(true);
    } else {
      // This branch is only for switching between 'Finalized' and 'Hold'
      await axios.put(`/invoices/invoices/${invoiceToEdit.id}`, { invoice: { status: value } });
    }
  };

  return (
    <Grid container>
      {['Sent', 'Review Needed'].includes(invoiceToEdit?.status) ? (
        <Typography
          style={{ textTransform: 'uppercase', color: statusColors?.[invoiceToEdit?.status], fontWeight: 700 }}
        >
          {invoiceToEdit?.status}
        </Typography>
      ) : (
        <Grid item xs={12}>
          <SelectInput
            value={newStatus}
            type="text"
            label=""
            disabled={newStatus === 'ticket'}
            styleOverrides={classes.selectInput}
            valueChanged={(value) => {
              handleValueChanged(value);
            }}
            choices={statusOptions}
            textColor={statusColors?.[newStatus]}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default InvoiceQuickStatusChange;
