/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Typography, Grid, Card, Button } from '@material-ui/core';
import { useParams, useHistory } from 'react-router-dom';
import { axios } from '../../shared/singletons';
import { Training } from '../model';
import InformationField from '../../shared/components/InformationField';
import DataTable from '../../shared/components/DataTable';
import { IDataTableColumn } from '../../shared/components/DataTable/DataTable/model';

type TrainingDetailParams = {
  trainingId: string;
};

const columns: IDataTableColumn<any>[] = [
  {
    name: 'Name',
    selector: 'name',
    sortable: true,
  },
  {
    name: 'Date Completed',
    selector: 'date',
    sortable: true,
  },
];

function TrainingDetailPage() {
  const { trainingId } = useParams<TrainingDetailParams>();
  const history = useHistory();
  const [training, setTraining] = useState<Training>({});
  const [providersWhoCompleted, setProvidersWhoCompleted] = useState([]);

  const fetchTraining = async () => {
    axios.get(`manage_trainings/${trainingId}`).then((response) => {
      setTraining(response?.data?.result);
      setProvidersWhoCompleted(response?.data?.result?.providers_who_completed);
    });
  };

  useEffect(() => {
    fetchTraining();
  }, [trainingId]);

  return (
    <>
      <Typography variant="h1">{training?.name}</Typography>
      <Typography variant="h2">Training Details</Typography>
      <br />
      <Grid container style={{ display: 'flex', justifyContent: 'flex-start' }}>
        <Grid item xs={2}>
          <InformationField value={training?.archived?.toString()} label="Archived" />
        </Grid>
        <Grid item xs={9}>
          <InformationField value={training?.description} label="Description" />
        </Grid>
      </Grid>
      <br />
      <Grid container>
        <Card style={{ padding: 15 }}>
          <Typography variant="h2">Providers Who Have Completed This Training</Typography>
          <DataTable columns={columns} data={providersWhoCompleted} pagination paginationPerPage={10} />
        </Card>
      </Grid>
      <br />
      <Button color="primary" variant="contained" onClick={() => history.push(`/manage_trainings`)}>
        All Trainings
      </Button>
    </>
  );
}

export default TrainingDetailPage;
