import React, { useContext } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepButton from '@mui/material/StepButton';
import { useHistory, useLocation } from 'react-router-dom';
import { updaterFlowLabels, updaterFlowLinks } from '../constants/constants';
import { AnnualUpdatesContext } from '../contexts/AnnualUpdatesContext';

interface ProgressStepperProps {
  activeStep: number;
  studentId?: string;
}

const ProgressStepper: React.FC<ProgressStepperProps> = (props) => {
  const { activeStep, studentId } = props;
  const { continueDisabled } = useContext(AnnualUpdatesContext);
  const history = useHistory();
  const location = useLocation();
  let stepsCompleted = [];
  if (location?.state) {
    stepsCompleted = location?.state['comp'];
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Stepper nonLinear activeStep={activeStep}>
        {updaterFlowLabels.map((label, index) => {
          const completed = stepsCompleted.includes(index);
          return (
            <Step key={label} completed={completed}>
              <StepButton
                color="inherit"
                disabled={continueDisabled}
                onClick={() =>
                  history.push({
                    pathname: `/students/${studentId}/annual_updater_${updaterFlowLinks[index]}`,
                    state: { comp: stepsCompleted },
                  })
                }
              >
                <StepLabel>{label}</StepLabel>
              </StepButton>
            </Step>
          );
        })}
      </Stepper>
      <>
        {continueDisabled ? (
          <p style={{ color: 'red' }}>You have unsaved changes</p>
        ) : (
          <>
            <br style={{ lineHeight: '1em' }} />
            <br style={{ lineHeight: '1em' }} />
          </>
        )}
      </>

      <br />
    </Box>
  );
};

export default ProgressStepper;
