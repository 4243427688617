import styled, { css } from 'styled-components';
import { media } from './media';
import { IDataTableColumn } from './model';

interface CellBaseProps {
  theme: any;
  head: any;
  noPadding: any;
  style: any;
  role: any;
}

export const CellBase = styled.div<any>`
  position: relative;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  line-height: normal;
  ${({ theme, head }) => theme[head ? 'headCells' : 'cells'].style};
  ${(props) => props.noPadding && 'padding: 0'};
`;

export interface StyledCellProps {
  column: IDataTableColumn;
}

const _isInteger = (item: any) => Number.isInteger(item);

// Flex calculations
export const Cell = styled(CellBase)<StyledCellProps>`
  flex-grow: ${(props) => (props.column.grow === 0 || props.column.button ? 0 : props.column.grow || 1)};
  flex-shrink: 0;
  flex-basis: 0;
  max-width: ${(props) => props.column.maxWidth || '100%'};
  min-width: ${(props) => props.column.minWidth || '55px'};
  ${(props) =>
    props.column.width &&
    css`
      min-width: ${props.column.width};
      max-width: ${props.column.width};
    `};
  ${(props) => props.column.right && 'justify-content: flex-end'};
  ${(props) => (props.column.center || props.column.button) && 'justify-content: center'};
  ${(props) => (props.column.compact || props.column.button) && 'padding: 0'};

  /* handle hiding cells */
  ${(props) =>
    props.column.hide &&
    props.column.hide === 'sm' &&
    media.sm`
    display: none;
  `};
  ${(props) =>
    props.column.hide &&
    props.column.hide === 'md' &&
    media.md`
    display: none;
  `};
  ${(props) =>
    props.column.hide &&
    props.column.hide === 'lg' &&
    media.lg`
    display: none;
  `};
  ${(props) =>
    props.column.hide &&
    _isInteger(props.column.hide) &&
    media.custom(props.column.hide)`
    display: none;
  `};
`;
