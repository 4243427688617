import { MarkSeriesPoint } from 'react-vis';
import { axios } from '../shared/singletons';
import { SelectOption } from '../shared/common.model';
import { Renewal } from '../renewals/model';
import { Service } from '../services/model';
import { BaseStore } from '../shared/state/base.store';

export class DashboardState {
  appointments: Record<string, string>;
  radialValues: Record<string, number[]>;
  radialLabels: Record<string, string>;
  schoolClosings: unknown[];
  students: unknown[];
  schools: SelectOption[];
  supervisors: SelectOption[];
  modalRecords: Service[] | Renewal[];
  timeline: Record<string, Service[] | Renewal[]>;
  timelineData: Record<string, MarkSeriesPoint[]>;
  timelineRenewalsModalOpen: boolean;
  timelineServicesModalOpen: boolean;
  timelineModalTitle: string;
  criticalPoint: number;
  isLoading: boolean;

  static create(props: Partial<DashboardState>): DashboardState {
    const defaults: DashboardState = {
      appointments: {
        today: '0',
        needs_rescheduled: '0',
        incomplete: '0',
      },
      radialValues: {
        iep: [0, 10],
        referrals: [0, 10],
        rrs: [0, 10],
      },
      radialLabels: {
        iep_label: '0',
        referral_label: '0',
        rr_label: '0',
      },
      schools: [],
      supervisors: [],
      schoolClosings: [],
      students: [],
      modalRecords: [],
      criticalPoint: 0,
      isLoading: false,
      timelineRenewalsModalOpen: false,
      timelineServicesModalOpen: false,
      timelineModalTitle: '',
      timelineData: {
        greenServices: [],
        yellowServices: [],
        redServices: [],
        duplicateGreenServices: [],
        duplicateYellowServices: [],
        duplicateRedServices: [],
        greenRenewals: [],
        yellowRenewals: [],
        redRenewals: [],
      },
      timeline: {
        // Referral arrays
        documentation_missing: [],
        unscheduled_evaluations: [],
        overdue_evaluations: [],
        unsubmitted_drafts: [],
        unreviewed_drafts: [],
        unsubmitted_referrals: [],

        // Renewal arrays
        unstarted_renewals: [],
        drafts_needed: [],
        unfinalized_renewals: [],
        unsubmitted_renewals: [],
      },
    };
    return Object.assign(new DashboardState(), defaults, props || {});
  }
}

export class DashboardStore extends BaseStore<DashboardState> {
  constructor() {
    super(DashboardState.create({}));
  }

  public fetchRecords(): void {
    this.setState({ isLoading: true });

    axios(`timelines.json`)
      .then((result) => {
        this.setState({
          appointments: result?.data?.appointments,
          schools: result?.data?.schools,
          supervisors: result?.data?.supervisors,
          timeline: {
            documentation_missing: result?.data?.documentation_missing,
            unscheduled_evaluations: result?.data?.unscheduled_evaluations,
            overdue_evaluations: result?.data?.overdue_evaluations,
            unsubmitted_drafts: result?.data?.unsubmitted_drafts,
            unreviewed_drafts: result?.data?.unreviewed_drafts,
            unsubmitted_referrals: result?.data?.unsubmitted_referrals,
            unstarted_renewals: result?.data?.unstarted_renewals,
            drafts_needed: result?.data?.drafts_needed,
            unfinalized_renewals: result?.data?.unfinalized_renewals,
            unsubmitted_renewals: result?.data?.unsubmitted_renewals,
          },
          isLoading: false,
        });
      })
      .catch(() => {
        this.setState({ isLoading: false });
      });
  }

  public fetchRecord(id = '0'): void {
    this.setState({ isLoading: true });

    axios(`timelines/${id}.json`)
      .then((result) => {
        this.setState({
          appointments: {
            today: result?.data?.today_appointments,
            needs_rescheduled: result?.data?.needs_rescheduled_appointments,
            incomplete: result?.data?.incomplete_appointments,
          },
          radialValues: {
            ieps: result?.data?.iep_count,
            referrals: result?.data?.referral_count,
            rrs: result?.data?.rr_count,
          },
          radialLabels: {
            iep_label: result?.data?.iep_full_count,
            referral_label: result?.data?.referral_full_count,
            rr_label: result?.data?.rr_full_count,
          },
          schools: result?.data?.schools,
          schoolClosings: result?.data?.blackout_dates,
          students: result?.data?.students,
          timelineData: {
            greenServices: result?.data?.green_services,
            yellowServices: result?.data?.yellow_services,
            redServices: result?.data?.red_services,
            duplicateGreenServices: result?.data?.duplicate_green_services,
            duplicateYellowServices: result?.data?.duplicate_yellow_services,
            duplicateRedServices: result?.data.duplicate_red_services,
            greenRenewals: result?.data.green_renewals,
            yellowRenewals: result?.data?.yellow_renewals,
            redRenewals: result?.data?.red_renewals,
            duplicateGreenRenewals: result?.data?.duplicate_green_renewals,
            duplicateYellowRenewals: result?.data?.duplicate_yellow_renewals,
            duplicateRedRenewals: result?.data?.duplicate_red_renewals,
          },

          isLoading: false,
        });
      })
      .catch(() => {
        this.setState({ isLoading: false });
      });
  }
}
