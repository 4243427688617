import React, { FunctionComponent, useState } from 'react';
import { Grid, Link, Typography } from '@material-ui/core';
import Button, { ButtonFlavor } from '../../shared/components/Button';
import { axios } from '../../shared/singletons';
import TextInput from '../../shared/components/form/TextInput';
import { useStyles } from '../../shared/style/siteWideStyles';

const PasswordResetForm: FunctionComponent = () => {
  const classes = useStyles();
  const [email, setEmail] = useState('');
  const [submitted, setSubmitted] = useState(false);

  const handlePasswordReset = (e: React.FormEvent) => {
    e.preventDefault();
    axios.post('/passwords', { email }).then(() => {
      setSubmitted(true);
    });
  };

  return (
    <>
      {submitted ? (
        <>
          <Typography variant="h3">
            If the address you submitted is in our system, you will receive an email with a password reset link.{' '}
          </Typography>
          <br />
          <Typography variant="h4">The link will expire in six hours.</Typography>
        </>
      ) : (
        <>
          <Link style={{ lineHeight: '42px' }} href="/login">
            &#8249; Back to Login
          </Link>
          <form noValidate autoComplete="off" onSubmit={handlePasswordReset}>
            <p>Enter your email address and we will send you a password reset link.</p>
            <TextInput
              styleOverrides={classes.textInput}
              label="Email"
              value={email}
              valueChanged={(value: string) => setEmail(value)}
            />
            <Grid container spacing={3}>
              <Grid item>
                <Button
                  text="Reset Password"
                  disabled={false}
                  flavor={ButtonFlavor.Primary}
                  handleClick={(e) => handlePasswordReset(e)}
                />
              </Grid>
            </Grid>
          </form>
        </>
      )}
    </>
  );
};

export default PasswordResetForm;
