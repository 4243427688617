import React, { Dispatch, SetStateAction, useState, useEffect } from 'react';
import ConfirmCancelModal from '../../shared/components/modal/ConfirmCancelModal';
import { HistoryItem } from '../../shared/common.model';
import TextInput from '../../shared/components/form/TextInput';
import { axios } from '../../shared/singletons';
import { useHistory, useParams } from 'react-router-dom';

interface DetailEditModalProps {
  isOpen?: boolean;
  setDetailEditModalOpen?: Dispatch<SetStateAction<boolean>>;
  itemToEdit?: HistoryItem;
}

interface RouteParams {
  studentId: string;
  appointmentId: string;
}

const DetailEditModal: React.FC<DetailEditModalProps> = (props) => {
  const history = useHistory();
  const { studentId, appointmentId } = useParams<RouteParams>();
  const { isOpen, setDetailEditModalOpen, itemToEdit } = props;
  const [editedDetails, setEditedDetails] = useState(itemToEdit.details);

  useEffect(() => {
    setEditedDetails(itemToEdit?.details);
  }, [itemToEdit]);

  const handleConfirm = () => {
    axios.put(`history_items/${itemToEdit.id}`, { history_item: { details: editedDetails } });
    history.push(`/students/${studentId}/appointments/${appointmentId}`);
  };

  return (
    <ConfirmCancelModal
      title="Edit History Item Comment"
      isOpen={isOpen}
      openStatusChanged={(isOpen) => setDetailEditModalOpen(isOpen)}
      height={'300px'}
      onConfirm={handleConfirm}
    >
      <>
        {/* Show more info about the HistoryItem so the user can confirm they're editing the right one */}
        <TextInput value={editedDetails} label="Comment" valueChanged={(value: string) => setEditedDetails(value)} />
      </>
    </ConfirmCancelModal>
  );
};

export default DetailEditModal;
