import React, { FunctionComponent } from 'react';
import { Box, MenuItem, Typography, makeStyles } from '@material-ui/core';
import CustomMaterialMenu from '../../shared/components/CustomMaterialMenu';
import DeleteModal from '../../shared/components/modal/DeleteModal';
import EditFileNameModal from './EditFileNameModal';
import { UserContext } from '../../auth/contexts/userContext';
import { attachmentStore, axios } from '../../shared/singletons';
import { Attachment } from '../model';
interface AttachmentRowMenuProps {
  attachment: Attachment;
  afterAction: () => void;
}

const useStyles = makeStyles((theme) => ({
  modalText: {
    fontSize: '20px',
    marginLeft: '20%',
    marginBottom: '20px',
    width: '60%',

    [theme.breakpoints.down('sm')]: {
      marginLeft: '2%',
      width: '96%',
    },
  },
}));

const AttachmentRowMenu: FunctionComponent<AttachmentRowMenuProps> = (props: AttachmentRowMenuProps) => {
  const { attachment, afterAction } = props;
  const classes = useStyles();
  const { currentUserHasRole } = React.useContext(UserContext);
  const [isDestroyModalOpen, setDestroyModalOpen] = React.useState<boolean>(false);
  const [isEditModalOpen, setEditModalOpen] = React.useState<boolean>(false);

  const handleDestroyFormSubmit = (): void => {
    attachmentStore.deleteAttachment(attachment.id, afterAction);
  };

  const setArchive = (value: string, attachment: Attachment) => {
    axios
      .put(`/attachments/${attachment.id}.json`, {
        attachment: { archived: value },
      })
      .then(() => {
        afterAction();
      })
      .catch(() => {
        afterAction();
      });
  };

  return (
    <>
      <DeleteModal
        isOpen={isDestroyModalOpen}
        openStatusChanged={setDestroyModalOpen}
        onDelete={handleDestroyFormSubmit}
      >
        <Typography align="center" className={classes.modalText} variant="h5">
          Are you sure you want to delete this attachment? This action cannot be undone.
        </Typography>
      </DeleteModal>
      <EditFileNameModal
        isOpen={isEditModalOpen}
        openStatusChanged={setEditModalOpen}
        attachment={attachment}
        afterAction={afterAction}
      >
        <Typography align="center" className={classes.modalText} variant="h5">
          Update File Name
        </Typography>
      </EditFileNameModal>

      <CustomMaterialMenu size="small">
        <Box>{!currentUserHasRole('admin') && <MenuItem>No Actions Available</MenuItem>}</Box>
        <Box>
          {currentUserHasRole('admin') && (
            <Box>
              <MenuItem onClick={() => setEditModalOpen(true)}>Edit File Name</MenuItem>

              {attachment.archived ? (
                <MenuItem onClick={() => setArchive('false', attachment)}>Unarchive Attachment</MenuItem>
              ) : (
                <MenuItem onClick={() => setArchive('true', attachment)}>Archive Attachment</MenuItem>
              )}
              <MenuItem onClick={() => setDestroyModalOpen(true)}>Destroy Attachment</MenuItem>
            </Box>
          )}
        </Box>
      </CustomMaterialMenu>
    </>
  );
};

export default AttachmentRowMenu;
