import React, { FunctionComponent } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import ServiceInformation from './ServiceInformation';
import ServiceHistory from './ServiceHistory';
import AppointmentQuarters from '../../appointments/components/AppointmentQuarters';
import AttachmentsList from '../../attachments/components/AttachmentsList';
import { Service } from '../model';
import { serviceRecordStore } from '../../shared/singletons';
import { UserContext } from '../../auth/contexts/userContext';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
});

interface ServiceDetailsProps {
  service: Service;
  serviceLoading: boolean;
}

const ServiceDetailsTab: FunctionComponent<ServiceDetailsProps> = (props) => {
  const { service, serviceLoading } = props;
  const { currentUserHasAnyRole } = React.useContext(UserContext);
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <ServiceInformation service={service} serviceLoading={serviceLoading} />
        </Grid>
        <Grid item xs={12} md={6}>
          <AttachmentsList
            attachments={service.attachments}
            showAddAttachment={service.status === 'active'}
            isLoading={serviceLoading}
            attachmentableType="patients"
            attachmentableId={service.student_id}
            afterAction={() => serviceRecordStore.fetchRecord(service.student_id, service.id)}
          />
        </Grid>
        <Grid item xs={12}>
          <AppointmentQuarters
            initialQuarters={service?.quarters}
            academic_years={service?.academic_years}
            year_choice={service?.year_choice}
            service={service}
          />
        </Grid>
        {currentUserHasAnyRole(['admin', 'service_provider', 'accountant', 'services_coordinator', 'system_owner']) && (
          <Grid item xs={12}>
            <ServiceHistory historyItems={service.history} isLoading={serviceLoading} />
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default ServiceDetailsTab;
