import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Typography, Button, Grid } from '@material-ui/core';
import { ServicesContext } from '../../services/contexts/ServicesContexts';
import InformationField from '../../shared/components/InformationField';
import GoalAccordion from '../components/GoalAccordion';
import RenewalAccordion from '../components/RenewalAccordion';
import StudentBuilderProgressStepper from '../components/StudentBuilderProgressStepper';

const NewStudentGoalsRenewalsPage: React.FunctionComponent = () => {
  const { studentId } = useParams<Record<string, string>>();
  const { services, fetchServices } = React.useContext(ServicesContext);
  const history = useHistory();

  React.useEffect(() => {
    if (studentId) {
      let initialFilter = { 'ransack[patient_id_eq]': studentId, 'ransack[phase_not_eq]': 'discharged' };
      fetchServices(initialFilter);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studentId]);

  const handleContinue = () => {
    history.push(`/students/${studentId}`);
  };

  return (
    <>
      <Typography variant="h1" component="h1">
        Goals and Renewals for New Student
      </Typography>
      <StudentBuilderProgressStepper activeStep={3} />
      <Grid container spacing={6}>
        <Grid item>
          <InformationField label="Student Name" value={services[0]?.student_name} />
        </Grid>
        <Grid item>
          <InformationField label="Timeline ID" value={services[0]?.student_slug} />
        </Grid>
        <Grid item>
          <InformationField label="School" value={services[0]?.school_abbreviation} />
        </Grid>
      </Grid>
      <br />
      <Grid spacing={3} container>
        {services?.map((service, idx) => (
          <>
            {service.goal_required ? (
              <>
                <Grid item xs={12} md={10}>
                  <GoalAccordion key={`goals_${service.id}`} serviceId={service?.id} idx={idx} />
                </Grid>
                <br />
              </>
            ) : (
              <></>
            )}
          </>
        ))}
      </Grid>
      <br />
      <hr />
      <br />
      <Typography variant="h1" component="h1">
        Renewals
      </Typography>
      <Grid item xs={12} md={10}>
        {services?.map((service, idx) => (
          <div>
            {!service.connected_service &&
              ['IEP Service', '504 Service', 'IEP and 504 Service'].includes(service.category) && (
                <>
                  <RenewalAccordion key={`renewal_${service.id}`} service={service} idx={null} defaultRenewalType={2} />
                  <br />
                </>
              )}
          </div>
        ))}
      </Grid>

      <br />
      <Button variant="contained" color="primary" onClick={handleContinue} style={{ marginTop: '10px' }}>
        Finish
      </Button>
    </>
  );
};

export default NewStudentGoalsRenewalsPage;
