import React, { FunctionComponent, Dispatch, SetStateAction } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ConfirmCancelModal from '../../../shared/components/modal/ConfirmCancelModal';
import TextInput from '../../../shared/components/form/TextInput';
import { RenewalStagesContext } from '../contexts/RenewalStagesContext';

const useStyles = makeStyles({
  textInput: {
    height: '60px',
    width: '80%',
    margin: '10px 0',
  },
  baseError: {
    marginBottom: '10px',
  },
});

interface NewRenewalStageModalProps {
  isOpen: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

const NewRenewalStageModal: FunctionComponent<NewRenewalStageModalProps> = (props) => {
  const { isOpen, setOpen } = props;
  const classes = useStyles();

  const { createRenewalStage } = React.useContext(RenewalStagesContext);

  const [newName, setNewName] = React.useState<string>(null);

  const handleSubmit = (): void => {
    if (!newName) return;
    createRenewalStage({ name: newName });
  };

  return (
    <ConfirmCancelModal
      isOpen={isOpen}
      title="Create Renewal Stage"
      confirmLabel="Submit"
      openStatusChanged={(open) => setOpen(open)}
      onConfirm={handleSubmit}
    >
      <form noValidate autoComplete="off">
        <TextInput
          styleOverrides={classes.textInput}
          value={newName}
          label="Renewal Stage Name"
          valueChanged={(value: string) => setNewName(value)}
        />
      </form>
    </ConfirmCancelModal>
  );
};

export default NewRenewalStageModal;
