import React, { useContext } from 'react';
import { Typography } from '@material-ui/core';
import DeleteModal from '../../shared/components/modal/DeleteModal';
import { AppointmentsContext } from '../contexts/AppointmentsContext';
import { appointmentFormStore } from '../../shared/singletons';
import { useStyles } from '../../shared/style/siteWideStyles';

interface DeleteAppointmentConfirmModalProps {
  onDelete?: () => void;
}

const DeleteAppointmentConfirmModal: React.FC<DeleteAppointmentConfirmModalProps> = (props) => {
  const { onDelete = () => window.location.reload() } = props;
  const classes = useStyles();

  const { destroyModalOpen, setDestroyModalOpen, appointmentToDelete } = useContext(AppointmentsContext);

  const handleDestroySingleAppointmentSubmit = () => {
    appointmentFormStore.deleteAppointment(appointmentToDelete.id, onDelete);
  };

  return (
    <DeleteModal
      isOpen={destroyModalOpen}
      openStatusChanged={setDestroyModalOpen}
      onDelete={handleDestroySingleAppointmentSubmit}
    >
      <Typography align="center" className={classes.modalText} variant="h5">
        Are you sure you want to delete this appointment? This action cannot be undone.
      </Typography>
    </DeleteModal>
  );
};

export default DeleteAppointmentConfirmModal;
