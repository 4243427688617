import React, { FunctionComponent } from 'react';
import { Box, Grid, Typography, makeStyles } from '@material-ui/core';
import ConfirmCancelModal from '../../../shared/components/modal/ConfirmCancelModal';
import TextInput from '../../../shared/components/form/TextInput';
import { userRoleStore } from '../../../shared/singletons';
import { useStoreObservable } from '../../../shared/state/useStoreObservable.hook';

const useStyles = makeStyles({
  textInput: {
    height: 'auto',
    width: '98%',
    margin: '14px 0',
  },
  baseError: {
    marginBottom: '10px',
  },
});

const EditUserRoleModal: FunctionComponent = () => {
  const { editModalOpen, formValues, userRole } = useStoreObservable(userRoleStore);
  const classes = useStyles();

  const handleFormValueChange = (key: string, value: string) => {
    userRoleStore.setState({ formValues: { ...formValues, [key]: value } });
  };

  const handleFormSubmit = () => {
    userRoleStore.updateUserRole(formValues, userRole);
  };

  return (
    <ConfirmCancelModal
      title="Edit User Role"
      cancelLabel="Cancel"
      confirmLabel="Update User Role"
      width="768px"
      hasCancelButton
      hasConfirmButton
      isOpen={editModalOpen}
      onConfirm={handleFormSubmit}
      openStatusChanged={(isOpen) => userRoleStore.setState({ editModalOpen: isOpen })}
    >
      <Box p={2}>
        <Typography variant="h2">User Role Details</Typography>
        <Grid container spacing={3}>
          <Grid item xs={10}>
            <TextInput
              styleOverrides={classes.textInput}
              label="User Role Name"
              value={formValues?.name}
              valueChanged={(value: string) => handleFormValueChange('name', value)}
            />
          </Grid>
        </Grid>
        <br />
      </Box>
    </ConfirmCancelModal>
  );
};

export default EditUserRoleModal;
