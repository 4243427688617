/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from 'react';
import { Card, CardContent, Grid, Typography, Button } from '@material-ui/core';
import { User, ProductivityDetail, blankProductivityDetail } from '../model';
import LoadingSpinner from '../../shared/components/LoadingSpinner';
import { UserContext } from '../../auth/contexts/userContext';
import InformationField from '../../shared/components/InformationField';
import ProductivityDetailFormModal from './ProductivityDetailFormModal';
import { axios } from '../../shared/singletons';
import ProductivityDetailHistory from './ProductivityDetailHistory';

interface UserProductivityDetailTabProps {
  user: User;
  userLoading: boolean;
}

const UserProductivityDetailTab: React.FC<UserProductivityDetailTabProps> = (props) => {
  const { user, userLoading } = props;
  const { currentUserHasRole } = useContext(UserContext);
  const [productivityDetail, setProductivityDetail] = useState<ProductivityDetail>(blankProductivityDetail);
  const [productivityDetailFetched, setProductivityDetailFetched] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const fetchProductivityDetail = async () => {
    const response = await axios.get(`productivity_details/${user.id}`);
    setProductivityDetail(response?.data);
    setProductivityDetailFetched(true);
  };

  useEffect(() => {
    if (!productivityDetailFetched && user?.id) {
      fetchProductivityDetail();
    }
  }, [user]);

  const handleEditClick = () => {
    setModalOpen(true);
  };

  return userLoading ? (
    <LoadingSpinner />
  ) : (
    <>
      <ProductivityDetailFormModal
        isOpen={modalOpen}
        setOpen={setModalOpen}
        productivityDetail={productivityDetail}
        setProductivityDetail={setProductivityDetail}
      />
      <Card>
        <CardContent>
          <Grid container justify="space-between">
            <Grid item>
              <Typography variant="h2" component="h2">
                Productivity Detail
              </Typography>
            </Grid>
            <Grid item>
              {currentUserHasRole('admin') && (
                <>
                  <Button variant="contained" color="primary" onClick={handleEditClick} style={{ marginLeft: 7 }}>
                    Edit Details
                  </Button>
                </>
              )}
            </Grid>
          </Grid>
          <br />
          <Grid item>
            <Typography variant="h3" component="h2">
              Expected Units
            </Typography>
          </Grid>
          <br />
          <Grid container spacing={3}>
            <Grid item xs={2} md={2}>
              <InformationField label="Monday" value={productivityDetail?.expected_units_monday} />
            </Grid>
            <Grid item xs={2} md={2}>
              <InformationField label="Tuesday" value={productivityDetail?.expected_units_tuesday} />
            </Grid>
            <Grid item xs={2} md={2}>
              <InformationField label="Wednesday" value={productivityDetail?.expected_units_wednesday} />
            </Grid>
            <Grid item xs={2} md={2}>
              <InformationField label="Thursday" value={productivityDetail?.expected_units_thursday} />
            </Grid>
            <Grid item xs={2} md={2}>
              <InformationField label="Friday" value={productivityDetail?.expected_units_friday} />
            </Grid>
          </Grid>
          <br />
          <Grid item>
            <Typography variant="h3" component="h2">
              Alert Threshold
            </Typography>
          </Grid>
          <Typography variant="body1" component="p" style={{ whiteSpace: 'break-spaces' }}>
            {productivityDetail?.alert_threshold + ' %'}
          </Typography>
        </CardContent>
      </Card>
      <br />
      <Grid item xs={12}>
        <ProductivityDetailHistory historyItems={productivityDetail.history} productivityDetailLoading={userLoading} />
      </Grid>
    </>
  );
};

export default UserProductivityDetailTab;
