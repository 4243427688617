import React, { FunctionComponent, useContext, useEffect } from 'react';
import { FormControl, FormGroup, FormControlLabel, Checkbox } from '@material-ui/core';
import { userSelectOptionStore } from '../../shared/singletons';
import { useStoreObservable } from '../../shared/state/useStoreObservable.hook';
import { SelectOption } from '../../shared/common.model';
import { InternalUsersContext } from '../../internalUsers/contexts/InternalUsersContext';

const UserSchoolsForm: FunctionComponent = () => {
  const { schoolChoices } = useStoreObservable(userSelectOptionStore);
  const { selectedSchools, setSelectedSchools } = useContext(InternalUsersContext);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let selections = selectedSchools;

    if (event.target.checked) {
      selections = [...selections, event.target.value];
    } else {
      selections = selections.filter((role) => role !== event.target.value);
    }
    setSelectedSchools(selections);
  };

  useEffect(() => {
    userSelectOptionStore.fetchChoices();
  }, []);

  return (
    <FormControl component="fieldset">
      <FormGroup>
        {schoolChoices.map((school: SelectOption, _index: number) => (
          <FormControlLabel
            key={school.value}
            checked={selectedSchools?.includes(school.value.toString())}
            control={<Checkbox onChange={handleChange} value={school.value} />}
            label={school.label}
          />
        ))}
      </FormGroup>
    </FormControl>
  );
};

export default UserSchoolsForm;
