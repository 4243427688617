import React from 'react';
import { Box, Card, CardContent, Grid, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import DataTable from '../../shared/components/DataTable';
import { DashboardContext } from '../contexts/DashboardContext';
import { User } from '../../users/model';

const columns: unknown[] = [
  {
    name: 'NAME',
    selector: 'name',
    sortable: true,
  },
  {
    name: 'SERVICES',
    selector: 'referral_services_count',
    sortable: true,
  },
  {
    name: 'RENEWALS',
    selector: 'renewals_count',
    sortable: true,
  },
  {
    name: 'REFERRALS',
    selector: 'referrals_count',
    sortable: true,
  },
];

const SupervisorTeamMembersTable: React.FunctionComponent = () => {
  const history = useHistory();
  const { supervisorTeamMembersLoading, supervisorTeamMembers, fetchSupervisorTeamMembers } = React.useContext(
    DashboardContext,
  );
  const [supervisorTeamMembersFetched, setSupervisorTeamMembersFetched] = React.useState(false);

  React.useEffect(() => {
    if (!supervisorTeamMembersFetched) {
      fetchSupervisorTeamMembers();
      setSupervisorTeamMembersFetched(true);
    }
  }, [supervisorTeamMembersFetched, setSupervisorTeamMembersFetched, fetchSupervisorTeamMembers]);

  return (
    <Box>
      <br />
      <Grid justify="space-between" container spacing={3}>
        <Grid item>
          <Typography variant="h1" component="h1">
            Team Members
          </Typography>
        </Grid>
      </Grid>
      <Card>
        <CardContent>
          <DataTable
            title=""
            columns={columns}
            noHeader
            data={supervisorTeamMembers}
            progressPending={supervisorTeamMembersLoading}
            striped
            onRowClicked={(teamMember: User) => history.push(`users/${teamMember.id}`)}
            highlightOnHover
            pointerOnHover
            pagination
          />
        </CardContent>
      </Card>
    </Box>
  );
};

export default SupervisorTeamMembersTable;
