import React, { FunctionComponent, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import TextInput from '../../shared/components/form/TextInput';
import ConfirmCancelModal from '../../shared/components/modal/ConfirmCancelModal';
import { InternalUsersContext } from '../../internalUsers/contexts/InternalUsersContext';
import { axios } from '../../shared/singletons';
import { useStyles } from '../../shared/style/siteWideStyles';

const UserPasswordModal: FunctionComponent = () => {
  const history = useHistory();
  const classes = useStyles();

  const { user, passwordModalOpen, setPasswordModalOpen } = useContext(InternalUsersContext);

  const [newPassword, setNewPassword] = useState('');
  const [formError, setFormError] = useState('');

  const handleUpdate = () => {
    axios
      .put(`/passwords/${user?.id}/reset`, { password: newPassword })
      .then(() => {
        history.push(`/users/${user?.id}`);
      })
      .catch((error) => {
        setFormError(error?.response?.data?.error.join(', '));
      });
  };

  return (
    <ConfirmCancelModal
      isOpen={passwordModalOpen}
      onCancel={() => setPasswordModalOpen(false)}
      onConfirm={handleUpdate}
      openStatusChanged={(open) => setPasswordModalOpen(open)}
      title="Reset Password"
      height={'300'}
    >
      <TextInput
        styleOverrides={classes.textInput}
        label="New Password"
        errorMessage={formError}
        value={newPassword}
        valueChanged={(value: string) => setNewPassword(value)}
      />
    </ConfirmCancelModal>
  );
};

export default UserPasswordModal;
