import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  timelineGridLine: {
    width: '97%',
    borderTop: '1px solid black',
    top: -25,
    position: 'relative',
  },
  timelineLabelContainer: {
    position: 'relative',
    top: -15,
  },
  timelineLabel: {
    display: 'inline-block',
    textAlign: 'center',
    verticalAlign: 'top',
  },
  timelineBorder: {
    height: 5,
    width: '100%',
    margin: 0,
    border: '1px solid black',
    borderTop: 'none',
  },
  timelineNumbersContainer: {
    position: 'relative',
    top: -95,
    left: 3,
  },
  timelineNumber: {
    textAlign: 'right',
    fontWeight: 700,
  },
  timelineText: {
    textAlign: 'center',
    margin: 0,
  },
});

const SchoolUserTimelineLabels: React.FC = () => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.timelineGridLine} />
      <div className={classes.timelineLabelContainer}>
        <div className={classes.timelineLabel} style={{ width: '7.5%', marginLeft: 10 }}>
          <p className={classes.timelineBorder} />
          <p className={classes.timelineText}>Provide Docs.</p>
        </div>
        <div className={classes.timelineLabel} style={{ width: '22%' }}>
          <p className={classes.timelineBorder} />
          <p className={classes.timelineText}>Schedule Eval.</p>
        </div>
        <div className={classes.timelineLabel} style={{ width: '15%' }}>
          <p className={classes.timelineBorder} />
          <p className={classes.timelineText}>Complete Eval.</p>
        </div>
        <div className={classes.timelineLabel} style={{ width: '10.5%' }}>
          <p className={classes.timelineBorder} />
          <p className={classes.timelineText}>Submit Draft</p>
        </div>
        <div className={classes.timelineLabel} style={{ width: '11.5%' }}>
          <p className={classes.timelineBorder} />
          <p className={classes.timelineText}>Finalized</p>
        </div>
        <div className={classes.timelineLabel} style={{ width: '25%' }}>
          <p className={classes.timelineBorder} />
          <p className={classes.timelineText}>Submit to School</p>
        </div>
      </div>
      <div className={classes.timelineNumbersContainer}>
        <div className={classes.timelineLabel} style={{ width: '7.5%', marginLeft: 10 }}>
          <p className={classes.timelineNumber}>5</p>
        </div>
        <div className={classes.timelineLabel} style={{ width: '22%' }}>
          <p className={classes.timelineNumber}>20</p>
        </div>
        <div className={classes.timelineLabel} style={{ width: '15%' }}>
          <p className={classes.timelineNumber}>30</p>
        </div>
        <div className={classes.timelineLabel} style={{ width: '10.5%' }}>
          <p className={classes.timelineNumber}>37</p>
        </div>
        <div className={classes.timelineLabel} style={{ width: '11.5%' }}>
          <p className={classes.timelineNumber}>44</p>
        </div>
        <div className={classes.timelineLabel} style={{ width: '25%' }}>
          <p className={classes.timelineNumber}>60</p>
        </div>
      </div>
    </>
  );
};

export default SchoolUserTimelineLabels;
