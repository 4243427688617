import React, { Dispatch, SetStateAction, useState, useEffect, useContext } from 'react';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import Receipt from '@material-ui/icons/Receipt';

import LoadingSpinner from '../../shared/components/LoadingSpinner';
import DataTable from '../../shared/components/DataTable';
import AssignAppointmentToInvoiceModal from '../../appointments/components/AppointmentListsByRole/AssignAppointmentToInvoiceModal';
import ResetCheckmarkButton from './ResetCheckmarkButton';

import { AppointmentsContext } from '../../appointments/contexts/AppointmentsContext';
import { accountantAppointmentColumns } from '../../appointments/constants';

import { Invoice } from '../model';
import { Appointment } from '../../appointments/model';

import axios from '../../shared/utils/axios.utils';

interface AppointmentsToReviewProps {
  invoice: Invoice;
  setInvoice: Dispatch<SetStateAction<Invoice>>;
  setActiveStep: Dispatch<SetStateAction<number>>;
  stepIndex: number;
  fetchInvoice: () => void;
}

const AppointmentsToReview: React.FC<AppointmentsToReviewProps> = (props) => {
  let { invoice, setInvoice, setActiveStep, stepIndex, fetchInvoice } = props;

  let { setEditModalOpen, setAppointmentToEdit } = useContext(AppointmentsContext);

  let [appointments, setAppointments] = useState<Appointment[]>([]);
  let [appointmentsLoaded, setAppointmentsLoaded] = useState(false);

  const fetchAppointments = async (page: number) => {
    let response = await axios.get(`/invoices/invoices/${invoice?.id}/appointments_to_review`, { params: { page } });
    setAppointments(response.data.result);
    setAppointmentsLoaded(true);
  };

  useEffect(() => {
    fetchAppointments(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePageChange = (page: number) => {
    fetchAppointments(page);
  };

  let handleContinue = async () => {
    let response = await axios.put(`/invoices/invoices/${invoice?.id}`, {
      step_index: stepIndex,
    });
    setInvoice(response.data.result);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  let invoiceDropdownColumn = {
    name: 'Assign Invoice:',
    selector: 'school_abbreviation',
    cell: (row) => (
      <Receipt
        onClick={() => {
          setEditModalOpen(true);
          setAppointmentToEdit(row.id);
        }}
      />
    ),
  };

  let columnsToUse = [...accountantAppointmentColumns, invoiceDropdownColumn];

  return appointmentsLoaded ? (
    <Grid container>
      <AssignAppointmentToInvoiceModal setAppointments={setAppointments} fetchInvoice={fetchInvoice} />
      <Grid item xs={12}>
        {appointments?.length > 0 && (
          <DataTable
            columns={columnsToUse}
            noHeader
            data={appointments}
            striped
            highlightOnHover
            pointerOnHover
            onChangePage={handlePageChange}
            pagination
            paginationServer
            paginationPerPage={25}
            paginationRowsPerPageOptions={[25]}
            paginationTotalRows={invoice.appointments_to_review_count}
          />
        )}
      </Grid>
      <Grid item xs={12} style={{ marginTop: 15 }}>
        <Button variant="contained" color="primary" onClick={handleContinue} style={{ marginRight: 10 }}>
          Continue
        </Button>
        <ResetCheckmarkButton invoice={invoice} setInvoice={setInvoice} stepIndex={stepIndex} />
      </Grid>
    </Grid>
  ) : (
    <LoadingSpinner />
  );
};

export default AppointmentsToReview;
