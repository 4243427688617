import React from 'react';
import { Link } from '@material-ui/core';
import { Doughnut } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
// import SmallRadialChartLabels from './SmallRadialChartLabels';
// import LargeRadialChartLabels from './LargeRadialChartLabels';

interface RadialChartProps {
  data: number[];
  label: string;
  labelNumber: string;
  link: string;
  chartSize: string;
}

const RadialChart: React.FC<RadialChartProps> = (props) => {
  const { data, label, labelNumber, link, chartSize } = props;

  const referralDonutData = {
    datasets: [
      {
        backgroundColor: ['#424E7A', '#fff'],
        data,
      },
    ],
  };

  const largeDonutLabels = {
    datasets: [
      {
        backgroundColor: ['transparent', 'transparent', 'transparent'],
        data: [1, 1, 1],
        datalabels: {
          anchor: 'end',
          align: 'end',
          offset: 2,
          clip: false,
          display: true,
          formatter(_value: any, context: any) {
            if (context.dataIndex === 1) {
              return '5';
            }
            return '';
          },
        },
      },
    ],
  };

  return (
    <>
      {chartSize === 'large' ? (
        <Doughnut
          data={referralDonutData}
          width={600}
          height={200}
          options={{
            aspectRatio: 2,
            width: 400,
            maintainAspectRatio: false,
            responsive: false,
            cutoutPercentage: 90,
            circumference: Math.PI,
            rotation: Math.PI,
            tooltips: { enabled: false },
          }}
        />
      ) : (
        <Doughnut
          data={referralDonutData}
          width={270}
          height={135}
          options={{
            aspectRatio: 2,
            maintainAspectRatio: false,
            responsive: false,
            cutoutPercentage: 90,
            circumference: Math.PI,
            rotation: Math.PI,
            tooltips: { enabled: false },
          }}
        />
      )}
      {chartSize === 'large2' && (
        <div style={{ position: 'absolute', bottom: 13 }}>
          <Doughnut
            data={largeDonutLabels}
            plugins={[ChartDataLabels]}
            options={{ cutoutPercentage: 90, circumference: Math.PI, rotation: Math.PI, tooltips: { enabled: false } }}
          />
        </div>
      )}
      {/* chartSize === 'small' ? <SmallRadialChartLabels /> : <LargeRadialChartLabels /> */}

      {chartSize === 'large' ? (
        <h4 style={{ textAlign: 'center', position: 'relative', top: -105, fontSize: 36, lineHeight: '40px' }}>
          <Link href={link}>
            {labelNumber}
            <br />
            {label}
          </Link>
        </h4>
      ) : (
        <h4 style={{ textAlign: 'center', position: 'relative', top: -80, fontSize: 28, lineHeight: '36px' }}>
          <Link href={link}>
            {labelNumber}
            <br />
            {label}
          </Link>
        </h4>
      )}
    </>
  );
};

export default RadialChart;
