import React, { useState } from 'react';
import axios from '../../shared/utils/axios.utils';
import { RenewalChoicesResponse } from '../model';
import { SelectOption } from '../../shared/common.model';
import { Colors } from '../../shared/style/colors';
import { ServiceOption } from '../../students/model';

interface RenewalSelectOptionStateInterface {
  renewalTypeChoices: SelectOption[];
  renewalStageChoices: SelectOption[];
  allOwnerChoices: SelectOption[];
  ownerChoices: SelectOption[];
  studentChoices: SelectOption[];
  schoolChoices: SelectOption[];
  attachedServiceChoices: ServiceOption[];
  statusColors: Record<string, string>;
  fetchChoices: (studentId: string) => void;
}

const RenewalOptionsContext = React.createContext<RenewalSelectOptionStateInterface>(
  {} as RenewalSelectOptionStateInterface,
);

const RenewalOptionsContextProvider: React.FC = ({ children }) => {
  const [renewalTypeChoices, setRenewalTypeChoices] = useState<SelectOption[]>([]);
  const [renewalStageChoices, setRenewalStageChoices] = useState<SelectOption[]>([]);
  const [allOwnerChoices, setAllOwnerChoices] = useState<SelectOption[]>([]);
  const [ownerChoices, setOwnerChoices] = useState<SelectOption[]>([]);
  const [studentChoices, setStudentChoices] = useState<SelectOption[]>([]);
  const [schoolChoices, setSchoolChoices] = useState<SelectOption[]>([]);
  const [attachedServiceChoices, setAttachedServiceChoices] = useState<ServiceOption[]>([]);

  const statusColors = {
    'On Target': Colors.Success,
    Renewed: Colors.BabyBlue,
    'Needs Assignment': Colors.Red,
  };

  const fetchChoices = (studentId: string): void => {
    axios
      .get<string, RenewalChoicesResponse>(`patients/${studentId}/renewals/new.json`)
      .then((r: RenewalChoicesResponse) => {
        const {
          renewal_types,
          renewal_stages,
          all_owners,
          service_owners,
          students,
          all_schools,
          attached_services,
        } = r.data?.result;

        setRenewalTypeChoices(renewal_types || []);
        setRenewalStageChoices(renewal_stages || []);
        setAllOwnerChoices(all_owners || []);
        setOwnerChoices(service_owners || []);
        setStudentChoices(students || []);
        setSchoolChoices(all_schools || []);
        setAttachedServiceChoices(attached_services || []);
      });
  };

  return (
    <RenewalOptionsContext.Provider
      value={{
        renewalTypeChoices,
        renewalStageChoices,
        allOwnerChoices,
        ownerChoices,
        studentChoices,
        schoolChoices,
        attachedServiceChoices,
        statusColors,
        fetchChoices,
      }}
    >
      {children}
    </RenewalOptionsContext.Provider>
  );
};

export { RenewalOptionsContext, RenewalOptionsContextProvider };
