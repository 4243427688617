import React, { useContext } from 'react';
import { Button } from '@material-ui/core';
import { RenewalsContext } from '../contexts/RenewalsContext';
import { CSVLink } from 'react-csv';

const DownloadIEPRenewalsButton: React.FC = () => {
  const { handlePrepareIEPDownload, iepRenewalsCSV, submittingDownload } = useContext(RenewalsContext);

  return iepRenewalsCSV?.length > 0 ? (
    <CSVLink
      data={iepRenewalsCSV}
      filename={'iep_504_renewals.csv'}
      className="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary"
      style={{ marginLeft: '8px', backgroundColor: 'lightgray', color: '#000000' }}
    >
      Download IEP & 504 Renewals
    </CSVLink>
  ) : (
    <Button onClick={handlePrepareIEPDownload} variant="contained" color="primary" disabled={submittingDownload}>
      {submittingDownload ? 'Preparing Download' : 'Prepare Download'}
    </Button>
  );
};

export default DownloadIEPRenewalsButton;
