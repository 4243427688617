import React, { FunctionComponent } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Card,
  Grid,
  Typography,
  makeStyles,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CommunicationFormModal from './CommunicationFormModal';
import EditCommunicationFormModal from './EditCommunicationFormModal';
import LoadingSpinner from '../../shared/components/LoadingSpinner';
import DeleteModal from '../../shared/components/modal/DeleteModal';
import { Communication } from '../model';
import { UserContext } from '../../auth/contexts/userContext';
import { communicationStore } from '../../shared/singletons';
import { _translateEach } from '../../shared/utils/translation.utils';

interface CommunicationsListProps {
  communications: Communication[];
  isLoading: boolean;
  parentType: string;
  parentId: string;
  onSuccess: () => void;
}

const useStyles = makeStyles((theme) => ({
  content: {
    lineHeight: '1.4em',
  },
  commentSpacer: {
    margin: '15px 0',
  },
  modalText: {
    fontSize: '20px',
    marginLeft: '20%',
    marginBottom: '20px',
    width: '60%',

    [theme.breakpoints.down('sm')]: {
      marginLeft: '2%',
      width: '96%',
    },
  },
}));

const CommunicationsList: FunctionComponent<CommunicationsListProps> = (props) => {
  const { communications, isLoading, parentType, parentId, onSuccess } = props;
  const classes = useStyles();

  const { currentUserHasRole } = React.useContext(UserContext);
  const [isDestroyModalOpen, setDestroyModalOpen] = React.useState<boolean>(false);
  const [currentCommunication, setCurrentCommunication] = React.useState<Communication>();

  const t = _translateEach({
    createButton: 'communicationsList.createButton',
    title: 'communicationsList.title',
    deleteConfirm: 'communicationsList.deleteConfirm',
  });

  const onClickDelete = (communication: Communication) => {
    setDestroyModalOpen(true);
    setCurrentCommunication(communication);
  };

  const onClickEdit = (communication: Communication) => {
    communicationStore.setEditModalOpen(true, communication);
  };

  const handleDestroyFormSubmit = (): void => {
    communicationStore.deleteCommunication(currentCommunication.id, onSuccess);
    setDestroyModalOpen(false);
  };

  return isLoading ? (
    <LoadingSpinner />
  ) : (
    <Card>
      <DeleteModal
        isOpen={isDestroyModalOpen}
        openStatusChanged={setDestroyModalOpen}
        onDelete={handleDestroyFormSubmit}
      >
        <Typography align="center" className={classes.modalText} variant="h5">
          {t.deleteConfirm}
        </Typography>
      </DeleteModal>
      <EditCommunicationFormModal onSuccess={onSuccess} />
      <CommunicationFormModal parentType={parentType} parentId={parentId} onSuccess={onSuccess} />
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
          <Grid container justify="space-between" spacing={3}>
            <Grid item>
              <Typography variant="h2" component="h2">
                {t.title}
              </Typography>
            </Grid>
            <Grid item>
              <Button variant="contained" color="primary" onClick={() => communicationStore.setCreateModalOpen(true)}>
                {t.createButton}
              </Button>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              {communications?.map((communication: Communication, _index: number) => (
                <div key={communication.id}>
                  <Grid container spacing={1} justify="space-between">
                    <Grid item>
                      {communication.contact_method && communication.contact_name && (
                        <Typography variant="subtitle1">
                          <strong>{`${communication.contact_method} - ${communication.contact_name}`}</strong>
                        </Typography>
                      )}
                    </Grid>
                    <Grid item>
                      {currentUserHasRole('admin') && (
                        <Button
                          style={{ marginRight: '8px' }}
                          variant="contained"
                          color="primary"
                          onClick={() => onClickEdit(communication)}
                        >
                          Edit
                        </Button>
                      )}
                      {currentUserHasRole('admin') && (
                        <Button variant="contained" color="primary" onClick={() => onClickDelete(communication)}>
                          Delete
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                  <Grid container spacing={1}>
                    {communication.date_created && (
                      <Grid item>
                        <Typography variant="subtitle1">
                          <strong>Date Created: </strong>
                          {communication.date_created}
                        </Typography>
                      </Grid>
                    )}
                    {communication.created_by && (
                      <Grid item>
                        <Typography variant="subtitle1">
                          <strong>Created By: </strong>
                          {communication.created_by}
                        </Typography>
                      </Grid>
                    )}
                    {communication.contact_reason && (
                      <Grid item>
                        <Typography variant="subtitle1">
                          <strong>Reason: </strong>
                          {communication.contact_reason}
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item>
                      <Typography variant="subtitle1" className={classes.content}>
                        {communication.content}
                      </Typography>
                    </Grid>
                  </Grid>
                  <hr className={classes.commentSpacer} />
                </div>
              ))}
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Card>
  );
};

export default CommunicationsList;
