import { AlertStore } from './alert.store';
import { ApplicationSettingsStore } from '../admin/applicationSettings/applicationSettings.store';
import { AppointmentBaseStore } from '../appointments/appointmentBase.store';
import { AppointmentRecordStore } from '../appointments/appointmentRecord.store';
import { AppointmentFormStore } from '../appointments/appointmentForm.store';
import { AttachmentStore } from '../attachments/attachment.store';
import { CommunicationStore } from '../communications/communication.store';
import { DashboardStore } from '../dashboard/dashboard.store';
import { GoalStore } from '../goals/goal.store';
import { GradeLevelStore } from '../admin/gradeLevels/gradeLevel.store';
import { ListingFeeStore } from '../listingFees/listingFee.store';
import { ReferralStageStore } from '../admin/referralStages/referralStage.store';
import { RenewalRecordStore } from '../renewals/renewalRecord.store';
import { RenewalFormStore } from '../renewals/renewalForm.store';
import { RenewalTypeStore } from '../admin/renewalTypes/renewalType.store';
import { ReportStore } from '../reports/report.store';
import { SchoolFormStore } from '../schools/schoolForm.store';
import { SchoolRecordStore } from '../schools/schoolRecord.store';
import { ServiceRecordStore } from '../services/serviceRecord.store';
import { ServiceFormStore } from '../services/serviceForm.store';
import { ServiceSelectOptionStore } from '../services/serviceSelectOption.store';
import { SessionNoteStore } from '../sessionNotes/sessionNote.store';
import { StudentBaseStore } from '../students/studentBase.store';
import { StudentRecordStore } from '../students/studentRecord.store';
import { StudentFormStore } from '../students/studentForm.store';
import { UserRecordStore } from '../users/userRecord.store';
import { UserFormStore } from '../users/userForm.store';
import { UserSelectOptionStore } from '../users/userSelectOption.store';
import { UserRoleStore } from '../admin/userRoles/userRole.store';
import Axios from './utils/axios.utils';

// instantiate store classes here (and only here)
export const alertStore = new AlertStore();
export const applicationSettingsStore = new ApplicationSettingsStore();
export const appointmentBaseStore = new AppointmentBaseStore();
export const appointmentRecordStore = new AppointmentRecordStore();
export const appointmentFormStore = new AppointmentFormStore();
export const attachmentStore = new AttachmentStore();
export const communicationStore = new CommunicationStore();
export const dashboardStore = new DashboardStore();
export const goalStore = new GoalStore();
export const gradeLevelStore = new GradeLevelStore();
export const listingFeeStore = new ListingFeeStore();
export const referralStageStore = new ReferralStageStore();
export const renewalRecordStore = new RenewalRecordStore();
export const renewalFormStore = new RenewalFormStore();
export const renewalTypeStore = new RenewalTypeStore();
export const reportStore = new ReportStore();
export const schoolFormStore = new SchoolFormStore();
export const schoolRecordStore = new SchoolRecordStore();
export const serviceRecordStore = new ServiceRecordStore();
export const serviceSelectOptionStore = new ServiceSelectOptionStore();
export const serviceFormStore = new ServiceFormStore();
export const sessionNoteStore = new SessionNoteStore();
export const studentBaseStore = new StudentBaseStore();
export const studentRecordStore = new StudentRecordStore();
export const studentFormStore = new StudentFormStore();
export const userRecordStore = new UserRecordStore();
export const userFormStore = new UserFormStore();
export const userSelectOptionStore = new UserSelectOptionStore();
export const userRoleStore = new UserRoleStore();

// other services

export const axios = Axios;
