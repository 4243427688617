export type Dict<T> = { [index: string]: T };
export type Primitive = string | number | boolean;
export type PrimitiveDict = Dict<Primitive>;
export type StringDict = Dict<string>;
export type NumericBoolean = 0 | 1;

export type SortComparisonResult = -1 | 0 | 1;
export type SortComparisonFunc<T> = (a: T, b: T) => SortComparisonResult;

export interface DynamicRendererProps {
  componentName: string;
  componentProps: Object;
}

export interface SelectionEvent {
  id: string;
  isSelected: boolean;
}

export const DASHED_NO_VALUE: string = '-------';

export class LabelValue {
  label: string;
  value: string;
}

export class IdLabel<T extends string | number = string> {
  id: T;
  label: string;
  count?: number;

  static fromStrings = (items: string[]): IdLabel<string>[] => (items || []).map((item) => ({ id: item, label: item }));
  static includesId = (id: string, items: IdLabel<string>[]): boolean => (items || []).some((i) => i.id === id);
  static includesAnyId = (ids: string[], items: IdLabel<string>[]): boolean =>
    (ids || []).some((id) => IdLabel.includesId(id, items));
  static includesAllIds = (ids: string[], items: IdLabel<string>[]): boolean =>
    (ids || []).every((id) => IdLabel.includesId(id, items));
}

export type NumberChoice = IdLabel<number>;
export type StringChoice = IdLabel<string>;

export type StringChoiceList = StringChoice[];
export type NumberChoiceList = NumberChoice[];
export type NumberRangeChoiceLists = {
  min: NumberChoiceList;
  max: NumberChoiceList;
};

export class LabelValueDescription {
  label: string;
  value: string;
  description: string;
}

export type TranslationParams = Dict<Primitive> | Object;

export class Translation {
  key: string;
  params: TranslationParams;

  constructor(key: string, params: TranslationParams = {}) {
    this.key = key;
    this.params = params;
  }

  static readonly convertEach = (keys: string[]): Translation[] => (keys || []).map((e) => new Translation(e));
}

export type StringOrTranslationDict = Dict<string | Translation>;

export type TextDescription = { text: string; description: string };
export type TextDescriptionTranslations = {
  text: Translation;
  description: Translation;
};

export class SimpleValidation {
  // TODO move to utility.model.ts
  isValid: boolean;
  messages: Translation[];

  constructor(isValid: boolean = true, messages: Translation[] = []) {
    this.isValid = isValid;
    this.messages = messages;
  }

  fail(translationKey: string, params: TranslationParams = {}): SimpleValidation {
    this.messages = [...this.messages, new Translation(translationKey, params)];
    this.isValid = false;
    return this;
  }
}

export class ComponentFlavor {
  key: string;
  cssClass: string;
  style: Dict<string>;
}

export interface ElementState {
  visible: boolean;
  html?: string;
}

export class Coords {
  longitude: number;
  latitude: number;
}

export enum FlexJustification {
  flexEnd = 'flex-end',
  flexStart = 'flex-start',
  spaceBetween = 'space-between',
  spaceAround = 'space-around',
  spaceEvenly = 'space-evenly',
}

export class NextPreviousLinks {
  next: number;
  previous: number;
}

export enum RestMethod {
  GET = 'GET',
  POST = 'POST',
  PATCH = 'PATCH',
  PUT = 'PUT',
  DELETE = 'DELETE',
}

export enum CrudOperation {
  CREATE = 'CREATE',
  READ = 'READ',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
}
