import React, { FunctionComponent, useContext } from 'react';
import UserRolesForm from './UserRolesForm';
import ConfirmCancelModal from '../../shared/components/modal/ConfirmCancelModal';
import { userSelectOptionStore } from '../../shared/singletons';
import { InternalUsersContext } from '../../internalUsers/contexts/InternalUsersContext';
import { axios } from '../../shared/singletons';
import { useHistory } from 'react-router-dom';

interface UserRoleFormModalProps {
  onSuccess?: () => void;
}

const UserRoleFormModal: FunctionComponent<UserRoleFormModalProps> = (props) => {
  const history = useHistory();
  const { selectedRole, rolesModalOpen, setRolesModalOpen, user } = useContext(InternalUsersContext);

  React.useEffect(() => {
    userSelectOptionStore.fetchChoices();
  }, []);

  // There is a submit function in the context that could be used here
  const handleFormSubmit = () => {
    axios.put(`/users/${user?.id}`, { user: { role_ids: [selectedRole] } }).then(() => {
      history.push(`/users/${user?.id}`);
    });
  };

  return (
    <ConfirmCancelModal
      isOpen={rolesModalOpen}
      title="Edit User Roles"
      onConfirm={handleFormSubmit}
      confirmLabel="Update Role"
      openStatusChanged={(open) => setRolesModalOpen(open)}
    >
      <UserRolesForm />
    </ConfirmCancelModal>
  );
};

export default UserRoleFormModal;
