import React, { createContext, useState, Dispatch, SetStateAction } from 'react';
import { Training } from '../model';

interface TrainingsContextInterface {
  trainingToEdit?: Training;
  setTrainingToEdit?: Dispatch<SetStateAction<Training>>;

  trainingToDelete?: number;
  setTrainingToDelete?: Dispatch<SetStateAction<number>>;

  trainingLoading?: boolean;
  setTrainingLoading?: Dispatch<SetStateAction<boolean>>;

  trainingFormModalOpen?: boolean;
  setTrainingFormModalOpen?: Dispatch<SetStateAction<boolean>>;

  trainingDestroyModalOpen?: boolean;
  setTrainingDestroyModalOpen?: Dispatch<SetStateAction<boolean>>;
}

const TrainingsContext = createContext<TrainingsContextInterface>({});

const TrainingsContextConsumer = TrainingsContext.Consumer;
const TrainingsContextProvider = ({ children }) => {
  const [trainingToEdit, setTrainingToEdit] = useState<Training>({});
  const [trainingToDelete, setTrainingToDelete] = useState<number>(null);
  const [trainingLoading, setTrainingLoading] = useState(false);
  const [trainingFormModalOpen, setTrainingFormModalOpen] = useState(false);
  const [trainingDestroyModalOpen, setTrainingDestroyModalOpen] = useState(false);

  return (
    <TrainingsContext.Provider
      value={{
        trainingToEdit,
        setTrainingToEdit,
        trainingToDelete,
        setTrainingToDelete,
        trainingLoading,
        setTrainingLoading,
        trainingFormModalOpen,
        setTrainingFormModalOpen,
        trainingDestroyModalOpen,
        setTrainingDestroyModalOpen,
      }}
    >
      {children}
    </TrainingsContext.Provider>
  );
};

export { TrainingsContextProvider, TrainingsContextConsumer, TrainingsContext };
