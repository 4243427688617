import React, { FunctionComponent } from 'react';
import { Button, Card, CardContent, Typography, Grid } from '@material-ui/core';
import InformationField from '../../shared/components/InformationField';
import LoadingSpinner from '../../shared/components/LoadingSpinner';
import { Renewal } from '../model';
import RenewalFormModal from './RenewalFormModal';
import RenewalStageChangeModal from './RenewalStageChangeModal';
import { UserContext } from '../../auth/contexts/userContext';
import { renewalFormStore } from '../../shared/singletons';

interface RenewalInformationProps {
  renewal: Renewal;
  renewalLoading: boolean;
}

const RenewalInformation: FunctionComponent<RenewalInformationProps> = (props) => {
  const { renewal, renewalLoading } = props;
  const { currentUserHasRole } = React.useContext(UserContext);

  const handleEditClick = () => {
    renewalFormStore.setEditModalOpen(true, renewal);
  };

  const handleChangeStageClick = () => {
    renewalFormStore.setEditStageModalOpen(true, renewal);
  };

  const renewalDateLabel = () => {
    if (renewal.type === 'RR') {
      return 'Renewal Date (Student RR)';
    } else {
      return 'Renewal Date (Service End)';
    }
  };

  return renewalLoading ? (
    <LoadingSpinner />
  ) : (
    <Card>
      <RenewalFormModal />
      <RenewalStageChangeModal />
      <CardContent>
        <Grid container justify="space-between">
          <Grid item>
            <Typography variant="h2" component="h2">
              Renewal Information
            </Typography>
          </Grid>
          <Grid item>
            <Button variant="contained" color="primary" onClick={handleChangeStageClick}>
              Change Stage
            </Button>
            {currentUserHasRole('admin') && (
              <Button variant="contained" color="primary" onClick={handleEditClick} style={{ marginLeft: 7 }}>
                Edit Renewal
              </Button>
            )}
          </Grid>
        </Grid>
        <br />
        <Grid container spacing={3}>
          <Grid item xs={6} md={4}>
            <InformationField label="Status" value={renewal?.status} />
          </Grid>
          <Grid item xs={6} md={4}>
            <InformationField label="Type" value={renewal?.type} />
          </Grid>
          <Grid item xs={6} md={4}>
            <InformationField label="Stage" value={renewal?.stage} />
          </Grid>
          <Grid item xs={6} md={4}>
            <InformationField label={renewalDateLabel()} value={renewal?.renewal_date} />
          </Grid>
          <Grid item xs={6} md={4}>
            <InformationField label="Days Until Due" value={renewal?.timeline} />
          </Grid>
          <Grid item xs={6} md={4}>
            <InformationField label="School" value={renewal?.school_abbreviation} />
          </Grid>
          {renewal.referral_service_id && (
            <>
              <Grid item xs={6} md={4}>
                <InformationField label="Service" value={renewal?.service_name} />
              </Grid>
              <Grid item xs={6} md={4}>
                <InformationField label="Service Start Date" value={renewal?.service_start_date} />
              </Grid>
              <Grid item xs={6} md={4}>
                <InformationField label="Service End Date" value={renewal?.service_end_date} />
              </Grid>
            </>
          )}

          <Grid item xs={6} md={4}>
            <InformationField label="Owner" value={renewal?.owner} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default RenewalInformation;
