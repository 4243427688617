import React, { FunctionComponent } from 'react';
import RenewalForm from './RenewalForm';
import { renewalFormStore } from '../../shared/singletons';
import { useStoreObservable } from '../../shared/state/useStoreObservable.hook';
import ConfirmCancelModal from '../../shared/components/modal/ConfirmCancelModal';

interface RenewalFormModalProps {
  onSuccess?: () => void;
}

const RenewalFormModal: FunctionComponent<RenewalFormModalProps> = (props) => {
  const { formModalOpen, formValues, formModalRenewal: renewal } = useStoreObservable(renewalFormStore);
  const { onSuccess } = props;

  const handleConfirm = (): void => {
    renewalFormStore.createOrUpdateRenewal(formValues, formValues.student_id, renewal.id, onSuccess);
  };

  return (
    <ConfirmCancelModal
      isOpen={formModalOpen}
      title="Update Renewal"
      confirmLabel="Update"
      openStatusChanged={(isOpen) => renewalFormStore.setCreateModalOpen(isOpen)}
      onConfirm={() => handleConfirm()}
    >
      <RenewalForm />
    </ConfirmCancelModal>
  );
};

export default RenewalFormModal;
