import React, { FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Card, CardContent, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Service } from '../model';
import { serviceFormStore, serviceRecordStore } from '../../shared/singletons';
import DeleteModal from '../../shared/components/modal/DeleteModal';

const useStyles = makeStyles((theme) => ({
  modalText: {
    fontSize: '20px',
    marginLeft: '20%',
    marginBottom: '20px',
    width: '60%',

    [theme.breakpoints.down('sm')]: {
      marginLeft: '2%',
      width: '96%',
    },
  },
  listItemIcon: {
    minWidth: '24px',
  },
}));

interface ServiceCustomActionsProps {
  service: Service;
}

const ServiceCustomActions: FunctionComponent<ServiceCustomActionsProps> = (props) => {
  const { service } = props;

  const classes = useStyles();
  const history = useHistory();

  const [isDestroyModalOpen, setDestroyModalOpen] = React.useState<boolean>(false);

  const handleDestroyFormSubmit = () => {
    serviceFormStore.deleteService(service.id, () => serviceRecordStore.fetchRecord(service.student_id, service.id));
    setDestroyModalOpen(false);
  };

  return (
    <>
      <Card>
        <CardContent>
          <Grid container justify="space-between">
            <Grid item>
              <Typography variant="h2" component="h2">
                Discharge Service
              </Typography>
              <Typography>Services must be discharged from the student's discharge page.</Typography>
              <br />
              <Button
                variant="contained"
                color="primary"
                onClick={() => history.push(`/students/${service.student_id}/discharge`)}
              >
                Go to Discharge Page
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <DeleteModal
        isOpen={isDestroyModalOpen}
        openStatusChanged={setDestroyModalOpen}
        onDelete={handleDestroyFormSubmit}
      >
        <Typography align="center" className={classes.modalText} variant="h5">
          Are you sure you want to delete this service? This action cannot be undone.
        </Typography>
      </DeleteModal>
      <Card style={{ marginTop: 30 }}>
        <CardContent>
          <Grid container justify="space-between">
            <Grid item>
              <Typography variant="h2" component="h2">
                Delete Service
              </Typography>
              <Typography>
                Permanently delete service from the timeline site. This cannot be undone, and in most cases services
                should be discharged instead of deleted.
              </Typography>
              <br />
              <Button
                variant="contained"
                color="primary"
                style={{ backgroundColor: '#F44F64' }}
                onClick={() => setDestroyModalOpen(true)}
              >
                Delete Service
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

export default ServiceCustomActions;
