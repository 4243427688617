import React, { useState, createContext } from 'react';
import { axios } from '../../../shared/singletons';
import { ServiceCategoriesResponse } from '../model';
import { SelectOption } from '../../../shared/common.model';
import { ListingService } from '../model';

interface ListingServicesContextInterface {
  fetchChoices?: () => void;
  serviceCategoryChoices?: SelectOption[];
  serviceSubcategoryChoices?: SelectOption[];

  createModalOpen?: boolean;
  setCreateModalOpen?: React.Dispatch<React.SetStateAction<boolean>>;

  editModalOpen?: boolean;
  setEditModalOpen?: React.Dispatch<React.SetStateAction<boolean>>;

  listingService?: ListingService;
  setListingService?: React.Dispatch<React.SetStateAction<ListingService>>;
}

const ListingServicesContext = createContext<ListingServicesContextInterface>({});
const ListingServicesContextConsumer = ListingServicesContext.Consumer;
const ListingServicesContextProvider = ({ children }) => {
  const [serviceCategoryChoices, setServiceCategoryChoices] = useState<SelectOption[]>([]);
  const [serviceSubcategoryChoices, setServiceSubcategoryChoices] = useState<SelectOption[]>([]);

  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [listingService, setListingService] = useState<ListingService>();

  const fetchChoices = async () => {
    axios.get<string, ServiceCategoriesResponse>(`listing_services/new.json`).then((r: ServiceCategoriesResponse) => {
      const { service_categories, service_subcategories } = r.data?.result;

      setServiceCategoryChoices(service_categories);
      setServiceSubcategoryChoices(service_subcategories);
    });
  };

  return (
    <ListingServicesContext.Provider
      value={{
        fetchChoices,
        serviceCategoryChoices,
        serviceSubcategoryChoices,
        createModalOpen,
        setCreateModalOpen,
        editModalOpen,
        setEditModalOpen,
        listingService,
        setListingService,
      }}
    >
      {children}
    </ListingServicesContext.Provider>
  );
};

export { ListingServicesContextProvider, ListingServicesContextConsumer, ListingServicesContext };
