import React, { useState } from 'react';
import { Grid, Typography, Button } from '@material-ui/core';
import EmailAlertForm from '../components/EmailAlertForm';
import { EmailAlert } from '../model';
import useEmailAlertRequestFunctions from '../constants/emailAlertRequestFunctions';

const NewEmailAlert: React.FunctionComponent = () => {
  const { handleCreateNewEmailAlert } = useEmailAlertRequestFunctions();

  const [formValues, setFormValues] = useState<EmailAlert>({
    role: '',
    message: '',
    subject: '',
  });

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h1" component="h1">
          Create New Email Alert
        </Typography>
      </Grid>
      <Grid item xs={12} style={{ marginBottom: 12 }}>
        <EmailAlertForm formValues={formValues} setFormValues={setFormValues} />
      </Grid>
      <Grid item xs={2}>
        <Button variant="contained" color="primary" onClick={() => handleCreateNewEmailAlert(formValues)}>
          Save Draft
        </Button>
      </Grid>
    </Grid>
  );
};

export default NewEmailAlert;
