import React, { FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';
import { Autocomplete } from '@material-ui/lab';
import { Box, Button, TextField, Typography } from '@material-ui/core';
import ReferralNewStudentForm from './ReferralNewStudentForm';
import { ReferralsContext } from '../contexts/ReferralsContext';
import { SelectOption } from '../../shared/common.model';

interface ReferralStudentFormProps {
  creatingStudent: boolean;
  setCreatingStudent: any;
}

const ReferralStudentForm: FunctionComponent<ReferralStudentFormProps> = (props) => {
  const { creatingStudent, setCreatingStudent } = props;
  const history = useHistory();
  const { studentChoices } = React.useContext(ReferralsContext);

  const [selectExistingStudent, setSelectExistingStudent] = React.useState(false);

  const handleSelectStudent = (student: SelectOption | string) => {
    if (typeof student === 'string') {
      history.push(`/referrals/${student}/review_student`);
    } else if (student?.value !== undefined) {
      history.push(`/referrals/${student.value}/review_student`);
    }
  };

  return (
    <form noValidate autoComplete="off">
      <Typography variant="h2">
        {creatingStudent ? 'Create New Student' : 'Step 1: Please Select One of the Following Options'}
      </Typography>
      <Box my={2}>
        {creatingStudent ? (
          <Button variant="contained" color="primary" onClick={() => setCreatingStudent(false)}>
            Hide New Student Form
          </Button>
        ) : (
          <Button
            variant="contained"
            color="primary"
            onClick={() => setCreatingStudent(true)}
            style={{ marginTop: 20 }}
          >
            Create New Student +
          </Button>
        )}
      </Box>
      {creatingStudent ? (
        <Box p={2} style={{ background: '#F1F2FF', border: '1px solid #725CDC', borderRadius: '4px' }}>
          <ReferralNewStudentForm />
        </Box>
      ) : (
        <>
          <Typography>OR</Typography>
          <br />
          <Button
            variant="contained"
            style={{ marginBottom: 20 }}
            color="primary"
            onClick={() => {
              setSelectExistingStudent(true);
            }}
          >
            Select Current Student
          </Button>
          {selectExistingStudent && (
            <Autocomplete
              options={studentChoices}
              getOptionLabel={(option) => option.label}
              style={{ width: 500 }}
              loading
              loadingText="Loading Students..."
              onChange={(_event, newValue) => handleSelectStudent(newValue)}
              renderInput={(params) => <TextField {...params} label="Select Student" variant="outlined" />}
            />
          )}
          <br />
        </>
      )}
    </form>
  );
};

export default ReferralStudentForm;
