import React, { FunctionComponent } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ConfirmCancelModal from '../../../shared/components/modal/ConfirmCancelModal';
import TextInput from '../../../shared/components/form/TextInput';
import { referralStageStore } from '../../../shared/singletons';
import { useStoreObservable } from '../../../shared/state/useStoreObservable.hook';

const useStyles = makeStyles({
  textInput: {
    height: '60px',
    width: '80%',
    margin: '10px 0',
  },
  baseError: {
    marginBottom: '10px',
  },
});

const NewReferralStageModal: FunctionComponent = () => {
  const classes = useStyles();
  const { createModalOpen } = useStoreObservable(referralStageStore);
  const [newName, setNewName] = React.useState<string>(null);

  const handleSubmit = (): void => {
    if (!newName) return;
    referralStageStore.createReferralStage({ name: newName });
  };

  return (
    <ConfirmCancelModal
      isOpen={createModalOpen}
      title="Create Referral Stage"
      confirmLabel="Submit"
      openStatusChanged={(isOpen) => referralStageStore.setState({ createModalOpen: isOpen })}
      onConfirm={handleSubmit}
    >
      <form noValidate autoComplete="off">
        <TextInput
          styleOverrides={classes.textInput}
          value={newName}
          label="Referral Stage Name"
          valueChanged={(value: string) => setNewName(value)}
        />
      </form>
    </ConfirmCancelModal>
  );
};

export default NewReferralStageModal;
