import React from 'react';

import Typography from '@material-ui/core/Typography';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';

const InvoiceActivity = () => {
  return (
    <Accordion>
      <AccordionSummary>
        <Typography variant="h2">Invoice Activity</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {/* <DataTable
          data={invoiceActivity}
          columns={activityColumns}
          noHeader
          striped
          highlightOnHover
          pointerOnHover
          pagination
          paginationPerPage={25}
          paginationRowsPerPageOptions={[25]}
        /> */}
      </AccordionDetails>
    </Accordion>
  );
};

export default InvoiceActivity;
