import React, { useState, useContext, useEffect, FunctionComponent } from 'react';
import SelectInput from '../../shared/components/form/SelectInput';
import { UserContext } from '../../auth/contexts/userContext';
import { AppointmentsContext } from '../contexts/AppointmentsContext';
import { Appointment } from '../model';
import { statusColors, getAppointmentStatusOptions } from '../constants';

interface AppointmentQuickStatusChangeProps {
  appointment: Appointment;
}

const AppointmentQuickStatusChange: FunctionComponent<AppointmentQuickStatusChangeProps> = (props) => {
  const { appointment } = props;

  const { currentUserHasRole } = useContext(UserContext);
  const { setAppointmentToEdit, setQuickStatusModalOpen, setStatusForQuickModal } = useContext(AppointmentsContext);

  const [newStatus, setNewStatus] = useState<string>('');

  useEffect(() => {
    setNewStatus(appointment.formatted_status);
  }, [appointment]);

  const handleValueChanged = (value) => {
    setNewStatus(value);
    setAppointmentToEdit(appointment);
    setStatusForQuickModal(value);
    setQuickStatusModalOpen(true);
  };

  return (
    <SelectInput
      value={newStatus}
      type="text"
      label=""
      disabled={newStatus === 'ticket'}
      valueChanged={(value) => {
        handleValueChanged(value);
      }}
      choices={getAppointmentStatusOptions(appointment, currentUserHasRole('admin'))}
      textColor={statusColors?.[newStatus]}
    />
  );
};

export default AppointmentQuickStatusChange;
