/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { axios } from '../../shared/singletons';
import { API_ENDPOINT } from '../../../config/env';
import { Typography, Grid, Card, CardContent, Button, Checkbox, makeStyles } from '@material-ui/core';
import { StudentsContext } from '../../students/contexts/StudentsContext';
import LoadingSpinner from '../../shared/components/LoadingSpinner';
import PageHeader from '../components/PageHeader';
import DataTable from '../../shared/components/DataTable';
import { IDataTableColumn } from '../../shared/components/DataTable/DataTable/model';
import { Attachment } from '../../attachments/model';
import { ReferralsContext } from '../../referrals/contexts/ReferralsContext';
import ConfirmCancelModal from '../../shared/components/modal/ConfirmCancelModal';
import NavButtons from '../components/NavButtons';
import { AnnualUpdatesContext } from '../contexts/AnnualUpdatesContext';

const useStyles = makeStyles((theme) => ({
  listItemIcon: {
    minWidth: '24px',
  },
  modalText: {
    fontSize: '20px',
    marginLeft: '20%',
    marginBottom: '20px',
    width: '60%',

    [theme.breakpoints.down('sm')]: {
      marginLeft: '2%',
      width: '96%',
    },
  },
}));

const AnnualUpdaterAttachments: React.FC = () => {
  const { studentId } = useParams<Record<string, string>>();
  const { fetchStudent, student } = useContext(StudentsContext);
  const { continueDisabled, setContinueDisabled } = useContext(AnnualUpdatesContext);
  const [idsToArchive, setIdsToArchive] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const [bulkArchiveSubmitting, setBulkArchiveSubmitting] = useState(false);
  const [archiveModalOpen, setArchiveModalOpen] = useState(false);
  const [filesToLoad, setFilesToLoad] = useState(0);
  const [filesLoaded, setFilesLoaded] = useState(0);
  const [attachmentsSubmitting, setAttachmentsSubmitting] = useState(false);

  const fileReferences = {
    'Student Documentation': React.useRef(null),
    'Other Documentation': React.useRef(null),
  };

  const { handleSelectedFile, optionalAttachedFiles } = React.useContext(ReferralsContext);

  const classes = useStyles();

  const submitAttachments = () => {
    setAttachmentsSubmitting(true);
    optionalAttachedFiles.map((attachmentList) => {
      return Array.from(attachmentList).forEach((attachment) => {
        const data = new FormData();

        data.append(`file`, attachment, attachment.name);
        data.append('category', 'Student Documentation');

        setFilesToLoad((prevFilesToLoad) => prevFilesToLoad + 1);

        axios.post(`/patients/${studentId}/attachments.json`, data).then(() => {
          setFilesLoaded((prevFilesLoaded) => prevFilesLoaded + 1);
        });
      });
    });
  };

  const uploadLabels = ['Student Documentation', 'Other Documentation'];

  const handleSelectAttachment = (id) => {
    if (idsToArchive.includes(id)) {
      const newArray = idsToArchive.filter((elem) => {
        return elem !== id;
      });
      setIdsToArchive(newArray);
    } else {
      setIdsToArchive([...idsToArchive, id]);
    }
  };

  const handleBulkArchive = () => {
    setBulkArchiveSubmitting(true);
    const archiveSet = idsToArchive.map((id) => {
      return axios.put(`${API_ENDPOINT}/attachments/${id}.json`, {
        attachment: { archived: 'true' },
      });
    });

    Promise.all(archiveSet).then(() => {
      setArchiveModalOpen(false);
      refreshAttachments();
      setIdsToArchive([]);
      setBulkArchiveSubmitting(false);
    });
  };

  const columns: IDataTableColumn<Attachment>[] = [
    {
      name: 'Archive',
      selector: '',
      cell: (row) => (
        <Checkbox
          name={row.id}
          value={row.id}
          color="primary"
          checked={idsToArchive.includes(row.id)}
          onChange={(e) => handleSelectAttachment(row.id)}
        />
      ),
      grow: 0.1,
    },
    {
      name: 'FILE NAME',
      selector: 'file_name',
      sortable: true,
      cell: (row) => (
        <a target="_blank" rel="noopener noreferrer" href={row.file_url}>
          {row.file_name}
        </a>
      ),
    },
    {
      name: 'FILE TYPE',
      selector: 'file_name',
      sortable: true,
      cell: (row) => <p>Student Documentation</p>,
    },
    {
      name: 'DATE CREATED',
      selector: 'date_created',
      sortable: true,
    },
  ];

  useEffect(() => {
    if (filesLoaded === filesToLoad && filesToLoad > 0) {
      setFilesLoaded(0);
      setFilesToLoad(0);
      refreshAttachments();
      setContinueDisabled(false);
      Object.keys(fileReferences).forEach((reference) => {
        fileReferences[reference].current.value = null;
      });
    }
  }, [filesLoaded]);

  useEffect(() => {
    if (studentId) {
      fetchStudent(studentId);
      refreshAttachments();
    }
  }, [studentId]);

  const refreshAttachments = () => {
    axios.get('/attachments', { params: { id: studentId } }).then((response) => {
      setAttachments(response?.data?.result);
      setAttachmentsSubmitting(false);
    });
  };

  useEffect(() => {
    if (optionalAttachedFiles?.length > 0) {
      setContinueDisabled(true);
    }
  }, [optionalAttachedFiles]);

  return !student.first_name ? (
    <LoadingSpinner />
  ) : (
    <>
      <ConfirmCancelModal
        isOpen={archiveModalOpen}
        title={'Archive All Selected Attachments'}
        onConfirm={handleBulkArchive}
        confirmLabel={'Archive All'}
        openStatusChanged={(isOpen) => setArchiveModalOpen(isOpen)}
        height={'200'}
      >
        <Typography align="center" className={classes.modalText} variant="h5">
          Are you sure you want to archive these attachments?
        </Typography>
      </ConfirmCancelModal>

      <br />

      <PageHeader
        title="Attachments"
        message="Archive any documents that are being replaced. Attach the updated document"
        student={student}
        step={0}
      />
      <br />

      <Card style={{ padding: 10 }}>
        <CardContent>
          <Typography variant="h3">Current Attachments</Typography>
          {attachmentsSubmitting ? (
            <LoadingSpinner />
          ) : (
            <DataTable
              columns={columns}
              noHeader
              data={attachments}
              loading={true}
              striped
              highlightOnHover
              pointerOnHover
            />
          )}
          <br />
          <Grid>
            <Button
              variant="contained"
              color="primary"
              disabled={bulkArchiveSubmitting}
              onClick={() => setArchiveModalOpen(true)}
            >
              Archive All Selected
            </Button>
          </Grid>
        </CardContent>
      </Card>

      <br />

      <Card style={{ padding: 10 }}>
        <Grid container spacing={1} style={{ marginLeft: '10px', marginTop: '20px' }}>
          <Grid item xs={12}>
            <Typography variant="h3" component="h3">
              New Attachments
            </Typography>
          </Grid>
          <br />
          <br />

          {uploadLabels.map((uploadLabel, index) => (
            <Grid item xs={12}>
              <Grid container>
                <Grid item>
                  <Typography>{uploadLabel}</Typography>
                </Grid>
                <Grid item style={{ marginLeft: 7 }}>
                  <input
                    type="file"
                    multiple
                    ref={fileReferences[uploadLabel]}
                    onChange={(value) => handleSelectedFile(value)}
                  />
                </Grid>
              </Grid>
              <br />
            </Grid>
          ))}
        </Grid>
        <Button variant="contained" color="primary" disabled={!continueDisabled} onClick={() => submitAttachments()}>
          Save Attachments
        </Button>
      </Card>
      <br />
      <NavButtons activePage="attachments" studentId={studentId} />
    </>
  );
};

export default AnnualUpdaterAttachments;
