import React, { FunctionComponent } from 'react';
import { Box } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import NewSchoolClosing from '../components/NewSchoolClosing';
import { useStoreObservable } from '../../shared/state/useStoreObservable.hook';
import { schoolRecordStore } from '../../shared/singletons';
import { SchoolRouteParams } from '../../schools/model';

const NewSchoolClosingPage: FunctionComponent = () => {
  const { school } = useStoreObservable(schoolRecordStore);
  const { schoolId } = useParams<SchoolRouteParams>();

  React.useEffect(() => {
    schoolRecordStore.fetchRecord(schoolId);
  }, [schoolId]);

  return (
    <Box>
      <NewSchoolClosing school={school} />
    </Box>
  );
};

export default NewSchoolClosingPage;
