import React, { FunctionComponent } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Grid, Button } from '@material-ui/core';
import TextInput from '../../shared/components/form/TextInput';
import { axios } from '../../shared/singletons';
import { API_ENDPOINT } from '../../../config/env';
import { Student, StudentFormValues } from '../../students/model';

const useStyles = makeStyles({
  textInput: {
    height: '60px',
    width: '98%',
    margin: '10px 0',
  },
  baseError: {
    marginBottom: '10px',
  },
});

interface StudentAddressAccordionProps {
  student?: Student;
  formValues: StudentFormValues;
  setFormValues: any;
}

const StudentAddressAccordion: FunctionComponent<StudentAddressAccordionProps> = (props) => {
  const { student, formValues, setFormValues } = props;
  const classes = useStyles();
  const [formErrors, setFormErrors] = React.useState<any>({});
  const [expanded, setExpanded] = React.useState<boolean>(false);

  const handleSubmit = () => {
    axios({
      method: 'put',
      url: `${API_ENDPOINT}/patients/${student.id}.json`,
      data: { patient: formValues },
      headers: { 'Content-Type': 'application/json' },
    })
      .then(() => {
        setExpanded(false);
      })
      .catch((error) => {
        setFormErrors(error.response?.data);
      });
  };

  return (
    <Accordion expanded={expanded}>
      <AccordionSummary onClick={() => setExpanded(!expanded)} expandIcon="▼">
        <Typography variant="h2" component="h2">
          Student Address (Optional)
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container>
          <Grid item xs={12}>
            <form noValidate autoComplete="off">
              <Grid item xs={12}>
                <TextInput
                  styleOverrides={classes.textInput}
                  type="text"
                  label="Address Line 1"
                  errorMessage={formErrors?.address_line1}
                  value={formValues.address_line1}
                  valueChanged={(value) => setFormValues({ ...formValues, address_line1: value })}
                  shrinkLabel={formValues?.address_line1 !== null}
                />
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  styleOverrides={classes.textInput}
                  type="text"
                  label="Address Line 2"
                  errorMessage={formErrors?.address_line2}
                  value={formValues.address_line2}
                  valueChanged={(value) => setFormValues({ ...formValues, address_line2: value })}
                  shrinkLabel={formValues?.address_line2 !== null}
                />
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={4}>
                  <TextInput
                    styleOverrides={classes.textInput}
                    type="text"
                    label="City"
                    errorMessage={formErrors?.address_city}
                    value={formValues.address_city}
                    valueChanged={(value) => setFormValues({ ...formValues, address_city: value })}
                    shrinkLabel={formValues?.address_city !== null}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextInput
                    styleOverrides={classes.textInput}
                    type="text"
                    label="State"
                    errorMessage={formErrors?.address_state}
                    value={formValues.address_state}
                    valueChanged={(value) => setFormValues({ ...formValues, address_state: value })}
                    shrinkLabel={formValues?.address_state !== null}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextInput
                    styleOverrides={classes.textInput}
                    type="text"
                    label="Zip"
                    errorMessage={formErrors?.address_zip}
                    value={formValues.address_zip}
                    valueChanged={(value) => setFormValues({ ...formValues, address_zip: value })}
                    shrinkLabel={formValues?.address_zip !== null}
                  />
                </Grid>
              </Grid>
              <br />
            </form>
          </Grid>
          <Grid>
            <Button variant="contained" color="primary" onClick={() => handleSubmit()}>
              Save Student Address
            </Button>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default StudentAddressAccordion;
