import { axios, alertStore } from '../../shared/singletons';
import { BaseStore } from '../../shared/state/base.store';
import { RenewalType, RenewalTypeFormValues } from './model';
import { API_ENDPOINT } from '../../../config/env';

export class RenewalTypeState {
  records: RenewalType[];
  renewalType: RenewalType;
  isLoading: boolean;

  formValues: RenewalTypeFormValues;
  editModalOpen: boolean;
  createModalOpen: boolean;

  static create(props: Partial<RenewalTypeState>): RenewalTypeState {
    const defaults: RenewalTypeState = {
      records: [],
      renewalType: {},
      isLoading: false,

      formValues: null,
      editModalOpen: false,
      createModalOpen: false,
    };
    return Object.assign(new RenewalTypeState(), defaults, props || {});
  }
}

export class RenewalTypeStore extends BaseStore<RenewalTypeState> {
  constructor() {
    super(RenewalTypeState.create({}));
  }

  public fetchRecords(): void {
    this.setState({ isLoading: true });

    axios(`${API_ENDPOINT}/renewal_types.json`)
      .then((result) => result?.data?.result ?? [])
      .then((renewalTypes) => {
        this.setState({ records: renewalTypes, isLoading: false });
      })
      .catch(() => {
        this.setState({ isLoading: false });
      });
  }

  public createRenewalType(formValues: RenewalTypeFormValues): void {
    axios({
      method: 'post',
      url: `${API_ENDPOINT}/renewal_types.json`,
      data: { renewal_type: formValues },
      headers: { 'Content-Type': 'application/json' },
    })
      .then(() => {
        alertStore.alertSuccess('Successfully created renewal type.');
        this.fetchRecords();
        this.setState({ createModalOpen: false });
      })
      .catch(() => {
        this.setState({ createModalOpen: false });
      });
  }

  public updateRenewalType(editFormValues: RenewalTypeFormValues, renewalType: RenewalType): void {
    axios({
      method: 'put',
      url: `${API_ENDPOINT}/renewal_types/${renewalType.id}.json`,
      data: { renewal_type: editFormValues },
      headers: { 'Content-Type': 'application/json' },
    })
      .then(() => {
        alertStore.alertSuccess('Successfully updated renewal type.');
        this.fetchRecords();
        this.setState({ editModalOpen: false });
      })
      .catch(() => {
        this.setState({ editModalOpen: false });
      });
  }

  public deleteRenewalType(renewalType: RenewalType, onDelete: () => void): void {
    axios({
      method: 'delete',
      url: `${API_ENDPOINT}/renewal_types/${renewalType.id}.json`,
      headers: { 'Content-Type': 'application/json' },
    })
      .then(() => {
        alertStore.alertSuccess('Successfully deleted renewal type.');
        this.fetchRecords();
        onDelete();
      })
      .catch(() => {
        onDelete();
      });
  }

  public setEditModalOpen(isOpen: boolean, renewalType: RenewalType): void {
    const formValues = {
      name: renewalType.name,
    };

    this.setState({ editModalOpen: isOpen, formValues, renewalType });
  }

  public setFormValue(formValues: RenewalTypeFormValues): void {
    this.setState({ formValues });
  }
}
