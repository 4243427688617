import React, { FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Card } from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import InformationField from '../../shared/components/InformationField';
import LoadingSpinner from '../../shared/components/LoadingSpinner';
import { Appointment } from '../model';
import { UserContext } from '../../auth/contexts/userContext';
import { Colors } from '../../shared/style/colors';

interface AppointmentInformationProps {
  appointment: Appointment;
  appointmentLoading: boolean;
}

// Even though 'scheduled' doesn't exist in the database, a front-end appointment can have that status from the rabl node
export const statusColors = {
  complete: Colors.Success,
  incomplete: Colors.Red,
  scheduled: Colors.PurplePrimary,
  needs_rescheduled: Colors.PurpleThree,
  late_cancel: Colors.Orange,
  early_cancel: Colors.BabyBlue,
  no_show: Colors.GreyText,
};

const AppointmentInformation: FunctionComponent<AppointmentInformationProps> = (props: AppointmentInformationProps) => {
  const { appointment, appointmentLoading } = props;
  const history = useHistory();
  const { currentUserHasRole } = React.useContext(UserContext);

  return appointmentLoading ? (
    <LoadingSpinner />
  ) : (
    <Card>
      <CardContent>
        <Grid container justify="space-between">
          <Grid item>
            <Typography variant="h2" component="h2">
              Appointment Information
            </Typography>
          </Grid>
          {(currentUserHasRole('admin') || currentUserHasRole('system_owner')) && (
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={() =>
                  history.push(`/students/${appointment?.student_id}/appointments/${appointment?.id}/edit`)
                }
              >
                Edit Appointment
              </Button>
            </Grid>
          )}
        </Grid>
        <br />
        <Grid container spacing={3}>
          <Grid item xs={6} md={4}>
            <Typography variant="subtitle1" component="h6">
              Status
            </Typography>
            <Typography
              variant="body1"
              component="p"
              style={{
                fontWeight: 700,
                textTransform: 'uppercase',
                color: statusColors?.[appointment.formatted_status],
              }}
            >
              {appointment?.formatted_status?.replace('_', ' ')}
            </Typography>
          </Grid>
          <Grid item xs={6} md={4}>
            <InformationField label="Owner" value={appointment?.owner_last_name_first_name} />
          </Grid>
          <Grid item xs={6} md={4}>
            <InformationField label="Timeline ID" value={appointment?.student_slug} />
          </Grid>
          <Grid item xs={6} md={4}>
            <InformationField label="Scheduled for" value={appointment?.formatted_schedule_date} />
          </Grid>
          <Grid item xs={6} md={4}>
            <InformationField label="Start Time" value={appointment?.formatted_start_time} />
          </Grid>
          <Grid item xs={6} md={4}>
            <InformationField label="End Time" value={appointment?.formatted_end_time} />
          </Grid>
          <Grid item xs={6} md={4}>
            <InformationField label="Location" value={appointment?.location} />
          </Grid>
          <Grid item xs={6} md={4}>
            <InformationField label="School" value={appointment?.school} />
          </Grid>
          <Grid item xs={6} md={4}>
            <InformationField label="Mileage" value={appointment?.mileage} />
          </Grid>
          <Grid item xs={6} md={4}>
            <InformationField label="Initial Schedule Date" value={appointment?.formatted_original_date} />
          </Grid>
          {!currentUserHasRole('service_provider') && (
            <Grid item xs={6} md={4}>
              <InformationField label="Billing Date" value={appointment?.billing_date} />
            </Grid>
          )}
          {(currentUserHasRole('accountant') || currentUserHasRole('system_owner')) && (
            <Grid item xs={6} md={4}>
              {appointment?.invoice_details ? (
                <InformationField
                  label="Invoice Name"
                  value={appointment?.invoice_details?.title}
                  link={`https://timelinecds.com/invoices/${appointment?.invoice_details?.id}`}
                />
              ) : (
                <InformationField label="Invoice Name" value={'None'} />
              )}
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default AppointmentInformation;
