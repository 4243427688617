/* eslint-disable react-hooks/exhaustive-deps */
import React, { FunctionComponent } from 'react';
import { Box, Button, Card, CardContent, Grid, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import DataTable from '../../shared/components/DataTable';
import { Renewal } from '../model';
import RenewalFormModal from './RenewalFormModal';
import { renewalFormStore } from '../../shared/singletons';
import { RenewalOptionsContext } from '../contexts/RenewalOptionsContext';
import RenewalStageChangeModal from './RenewalStageChangeModal';
import { UserContext } from '../../auth/contexts/userContext';
import { rrColumns, iepColumns } from '../constants/renewalColumns';

interface RenewalsCaseloadListProps {
  revaluationRenewals?: Renewal[];
  iepRenewals?: Renewal[];
  renewalsLoading?: boolean;
  studentId?: string;
  afterAction?: () => void;
}

const RenewalsCaseloadList: FunctionComponent<RenewalsCaseloadListProps> = (props) => {
  const { revaluationRenewals = [], iepRenewals = [], renewalsLoading = false, studentId = null, afterAction } = props;

  const history = useHistory();

  const { currentUserHasRole } = React.useContext(UserContext);

  const { fetchChoices } = React.useContext(RenewalOptionsContext);

  React.useEffect(() => {
    fetchChoices(studentId);
  }, [studentId]);

  return (
    <Box>
      {currentUserHasRole('admin') && <RenewalFormModal onSuccess={afterAction} />}
      <RenewalStageChangeModal onSuccess={afterAction} />
      <Grid justify="space-between" container spacing={3}>
        <Grid item>
          <Typography variant="h1" component="h1">
            Renewals
          </Typography>
        </Grid>
        <Grid item>
          {currentUserHasRole('admin') && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => renewalFormStore.setCreateModalOpen(true, studentId)}
            >
              Create New Renewal +
            </Button>
          )}
        </Grid>
      </Grid>
      <br />
      <Typography variant="h2" component="h2">
        IEP & 504 Renewals
      </Typography>
      <Card>
        <CardContent>
          <DataTable
            title=""
            columns={iepColumns(currentUserHasRole('admin'))}
            noHeader
            data={iepRenewals.filter((record) => record.type !== 'RR')}
            striped
            onRowClicked={(renewal: Renewal) => history.push(`/students/${renewal.student_id}/renewals/${renewal.id}`)}
            highlightOnHover
            pointerOnHover
            pagination
          />
        </CardContent>
      </Card>
      <br />
      <hr />
      <br />
      <Typography variant="h2" component="h2">
        RR Renewals
      </Typography>
      <Card>
        <CardContent>
          <DataTable
            title=""
            columns={rrColumns(currentUserHasRole('admin'))}
            noHeader
            data={revaluationRenewals.filter((record) => record.type === 'RR')}
            progressPending={renewalsLoading}
            striped
            onRowClicked={(renewal: Renewal) => history.push(`/students/${renewal.student_id}/renewals/${renewal.id}`)}
            highlightOnHover
            pointerOnHover
            pagination
          />
        </CardContent>
      </Card>
    </Box>
  );
};

export default RenewalsCaseloadList;
