import React from 'react';
import { Grid, Box, Typography } from '@mui/material';
import AppointmentNotesList from '../../appointmentNotes/components/AppointmentNotesList';
import { Appointment } from '../../appointments/model';
import Breadcrumbs from '../../shared/components/Breadcrumbs';
import InformationField from '../../shared/components/InformationField';
import { BreadcrumbLink } from '../../shared/components/Breadcrumbs';

type SupervisorSessionNotePageProps = {
  appointment?: Appointment;
};

function SupervisorSessionNotePage(props: SupervisorSessionNotePageProps) {
  const { appointment } = props;

  const links: BreadcrumbLink[] = [
    {
      href: `/students/${appointment?.student_id}`,
      text: `${appointment?.student?.first_name} ${appointment?.student?.last_name}`,
    },
    {
      href: `/students/${appointment?.student_id}/services/${appointment?.service_id}`,
      text: `${appointment?.service?.service_type}`,
    },
    {
      href: null,
      text: `${appointment?.service_type} ${appointment?.formatted_schedule_date} ${appointment?.start_time}`,
    },
  ];

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h4" component="h1">
          Appointment Details
        </Typography>
        <div style={{ marginLeft: -5 }}>
          <Breadcrumbs links={links} />
        </div>
      </Grid>
      <Grid item xs={12} style={{ marginTop: 10 }}>
        <Box display={{ xs: 'none', sm: 'none', md: 'block' }}>
          <Grid container spacing={5}>
            <Grid item>
              <InformationField label="Appointment" value={appointment?.schedule_date} />
            </Grid>
            <Grid item>
              <InformationField label="Service Type" value={appointment?.service_type} />
            </Grid>
            <Grid item>
              <InformationField label="Student" value={appointment?.student_name} />
            </Grid>
            <Grid item>
              <InformationField label="Timeline ID" value={appointment?.student?.slug} />
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={12} style={{ marginTop: 10 }}>
        <AppointmentNotesList appointment={appointment} appointmentNotes={appointment?.all_appointment_notes} />
      </Grid>
    </Grid>
  );
}

export default SupervisorSessionNotePage;
