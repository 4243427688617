import React from 'react';
import ServicesList from '../../services/components/ServicesList';
import { Student } from '../model';

type StudentServicesTabProps = {
  student: Student;
};

function StudentServicesTab(props: StudentServicesTabProps) {
  const { student } = props;

  return <ServicesList initialFilter={{ 'ransack[patient_slug_i_cont]': student?.slug }} />;
}

export default StudentServicesTab;
