import { axios } from '../shared/singletons';
import { BaseRecordStore } from '../shared/state/baseRecord.store';
import { User } from './model';
import { SelectOption } from '../shared/common.model';

export class UserState {
  records: User[];
  user: User;
  recordsLoading: boolean;
  userLoading: boolean;
  isLoading: boolean;
  filteredRecords: User[];
  totalRecords: number;
  filterValues: Record<string, string>;
  supervisors: SelectOption[];

  static create(props: Partial<UserState>): UserState {
    const defaults: UserState = {
      records: [],
      user: {},
      recordsLoading: false,
      userLoading: false,
      isLoading: false,
      filteredRecords: null,
      totalRecords: 0,
      filterValues: { 'ransack[inactivated_at_null]': '1' },
      supervisors: [],
    };
    return Object.assign(new UserState(), defaults, props || {});
  }
}

export class UserRecordStore extends BaseRecordStore<UserState> {
  constructor() {
    super(UserState.create(JSON.parse(sessionStorage.getItem('userState')) || {}));
  }

  public fetchRecords(params = this.formattedFilterParams(), page = 1, per = 10): void {
    this.setState({ recordsLoading: true });
    this.saveUserFilters();

    axios(`users.json?${params}page=${page}&per=${per}`)
      .then((result) => {
        this.setState({ totalRecords: result?.data?.total_records, supervisors: result?.data?.supervisors });
        return result?.data?.result ?? [];
      })
      .then((records) => {
        this.setState({ records, recordsLoading: false });
      })
      .catch(() => {
        this.setState({ recordsLoading: false });
      });
  }

  public fetchRecord(userId: string): void {
    this.setState({ userLoading: true });

    axios(`users/${userId}.json`)
      .then((result) => result?.data?.result ?? [])
      .then((user) => {
        this.setState({ user, userLoading: false });
      })
      .catch(() => {
        this.setState({ userLoading: false });
      });
  }

  public setFilterValues(filterValues: Record<string, string>): void {
    this.setState({ filterValues });
  }

  public resetFilterValues(): void {
    this.setState({ filterValues: {} });
  }

  public formattedFilterParams(): string {
    const { filterValues } = this.getState();

    return Object?.keys(filterValues)?.reduce(
      (params, key, _index: number) => `${params}${key}=${filterValues[key]}&` || '',
      '',
    );
  }

  public saveUserFilters(): void {
    const { filterValues } = this.getState();
    const savedUserState = {
      filterValues: {
        'ransack[last_name_i_cont]': filterValues['ransack[last_name_i_cont]'],
        'ransack[supervisor_last_name_i_cont]': filterValues['ransack[supervisor_last_name_i_cont]'],
        'ransack[inactivated_at_null]': filterValues['ransack[inactivated_at_null]'],
      },
      loadListPageRecords: true,
    };

    sessionStorage.setItem('userState', JSON.stringify(savedUserState));
  }
}
