import React from 'react';
import InformationField from '../../shared/components/InformationField';
import { Grid } from '@material-ui/core';
import { Student } from '../../students/model';

type StudentInfoBarProps = {
  student: Student;
};

const StudentInfoBar: React.FC<StudentInfoBarProps> = (props) => {
  const { student } = props;
  return (
    <Grid container spacing={6}>
      <Grid item>
        <InformationField label="Student Name" value={`${student.first_name} ${student.last_name}`} />
      </Grid>
      <Grid item>
        <InformationField label="Timeline ID" value={student.slug} />
      </Grid>
      <Grid item>
        <InformationField label="School" value={student.school} />
      </Grid>
    </Grid>
  );
};

export default StudentInfoBar;
