import { css } from 'styled-components';

export const SMALL = 599;
export const MEDIUM = 959;
export const LARGE = 1280;

// css.call syntax from this post: https://stackoverflow.com/questions/49397538/typing-the-css-function-in-styled-components

export const media = {
  sm: (...args: any[]) => css`
    @media screen and (max-width: ${SMALL}px) {
      ${css.call(undefined, ...args)}
    }
  `,
  md: (...args) => css`
    @media screen and (max-width: ${MEDIUM}px) {
      ${css.call(undefined, ...args)}
    }
  `,
  lg: (...args) => css`
    @media screen and (max-width: ${LARGE}px) {
      ${css.call(undefined, ...args)}
    }
  `,
  custom: (value) => (...args) => css`
    @media screen and (max-width: ${value}px) {
      ${css.call(undefined, ...args)}
    }
  `,
};
