import React, { FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';
import { Typography, Box, Grid, Card, CardContent, Button } from '@material-ui/core';
import DataTable from '../../shared/components/DataTable';
import { IDataTableColumn } from '../../shared/components/DataTable/DataTable/model';
import { Student } from '../model';
import { studentBaseStore, studentRecordStore } from '../../shared/singletons';
import { useStoreObservable } from '../../shared/state/useStoreObservable.hook';
import { _translateEach } from '../../shared/utils/translation.utils';
import FilterBar, { FilterProps } from '../../shared/components/FilterBar';
import { UserContext } from '../../auth/contexts/userContext';

const t = _translateEach({
  createButton: 'studentList.createButton',
  title: 'studentList.title',
  studentName: 'students.columnLabel.studentName',
  id: 'students.columnLabel.id',
  dateCreated: 'students.columnLabel.dateCreated',
  serviceType: 'students.columnLabel.serviceType',
  school: 'students.columnLabel.school',
  owner: 'students.columnLabel.owner',
  createdBy: 'students.columnLabel.createdBy',
  status: 'students.columnLabel.status',
  actions: 'students.columnLabel.actions',
});

interface StudentsListProps {
  records: Student[];
  recordsLoading: boolean;
  showFilterBar?: boolean;
  totalRecords?: number;
}

const StudentsList: FunctionComponent<StudentsListProps> = (props) => {
  const { records, recordsLoading, showFilterBar = false, totalRecords = records?.length || 0 } = props;
  const [recordsPerPage, setRecordsPerPage] = React.useState(10);
  const history = useHistory();
  const { currentUserHasRole } = React.useContext(UserContext);
  const { statusColors, schoolChoices, statusChoices } = useStoreObservable(studentBaseStore);

  React.useEffect(() => {
    studentBaseStore.fetchChoices();
  }, []);

  const filters: FilterProps[] = [
    {
      selector: 'ransack[slug_cont]',
      label: 'Timeline ID',
      type: 'text',
    },
    {
      selector: 'ransack[last_name_cont]',
      label: 'Student Last Name',
      type: 'text',
    },
    {
      selector: 'ransack[payer_id_eq]',
      label: 'School',
      type: 'select',
      options: schoolChoices,
    },
    {
      selector: 'ransack[discharged_eq]',
      label: 'Status',
      type: 'select',
      options: statusChoices,
    },
  ];

  const schoolAdminFilters: FilterProps[] = [
    {
      selector: 'ransack[last_name_cont]',
      label: 'Last Name',
      type: 'text',
    },
    {
      selector: 'ransack[discharged_eq]',
      label: 'Status',
      type: 'select',
      options: statusChoices,
    },
  ];

  const columns: IDataTableColumn<any>[] = [
    {
      name: t.studentName,
      selector: 'student_name',
      sortable: true,
    },
    {
      name: t.id,
      selector: 'slug',
      sortable: true,
      omit: currentUserHasRole('school_admin'),
    },
    {
      name: t.dateCreated,
      selector: 'created_at',
      sortable: true,
    },
    {
      name: t.school,
      selector: 'school',
      sortable: true,
      omit: currentUserHasRole('school_admin'),
    },
    {
      name: t.status,
      selector: 'status',
      grow: 0.5,
      cell: (row: Student) => (
        <div
          style={{
            fontWeight: 700,
            textTransform: 'uppercase',
            color: statusColors[row.status],
          }}
        >
          {row.status === 'true' ? 'Inactive' : 'Active'}
        </div>
      ),
      sortable: true,
    },
  ];

  const handlePageChange = (page: number) => {
    const filterParams = studentRecordStore.formattedFilterParams();
    studentRecordStore.fetchRecords(filterParams, page, recordsPerPage);
  };

  const handlePerRowsChange = async (newPerPage: number, page: number) => {
    setRecordsPerPage(newPerPage);
    const filterParams = studentRecordStore.formattedFilterParams();
    studentRecordStore.fetchRecords(filterParams, page, newPerPage);
  };

  const handleColumnSort = async (column: Record<string, string>, sortDirection: string) => {
    const columnNamesToRansack = {
      student_name: 'last_name',
      slug: 'slug',
      created_at: 'created_at',
      school: 'payer_name',
      created_by: 'created_by',
      status: 'status',
    };

    studentRecordStore.setState({
      filterValues: {
        ...studentRecordStore.getState().filterValues,
        sort: `${columnNamesToRansack[column.selector]} ${sortDirection}`,
      },
    });

    const filterParams = studentRecordStore.formattedFilterParams();
    studentRecordStore.fetchRecords(filterParams, 1, recordsPerPage);
  };

  return (
    <Box>
      <Grid justify="space-between" container spacing={3}>
        <Grid item xs={9}>
          <Typography variant="h1" component="h1">
            {t.title}
          </Typography>
        </Grid>
        <Grid item>
          {(currentUserHasRole('admin') || currentUserHasRole('service_provider')) && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => history.push(`/students/new`)}
              data-cy="add-new-student"
            >
              Create New Student +
            </Button>
          )}
        </Grid>
        {showFilterBar && (
          <Grid item xs={12}>
            <FilterBar
              filters={currentUserHasRole('school_admin') ? schoolAdminFilters : filters}
              baseStore={studentRecordStore}
              onFilter={() => studentRecordStore.fetchRecords()}
            />
          </Grid>
        )}
      </Grid>
      <br />
      <Card>
        <CardContent>
          <DataTable
            title=""
            columns={columns}
            noHeader
            data={records}
            progressPending={recordsLoading}
            striped
            onRowClicked={(student: Student) => history.push(`/students/${student.id}`)}
            highlightOnHover
            pointerOnHover
            pagination
            paginationServer
            paginationTotalRows={totalRecords}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            sortServer
            onSort={handleColumnSort}
          />
        </CardContent>
      </Card>
    </Box>
  );
};

export default StudentsList;
