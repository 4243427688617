import React, { FunctionComponent } from 'react';
import { Box, Typography } from '@material-ui/core';
import DataTable from '../../shared/components/DataTable';
import { IDataTableColumn } from '../../shared/components/DataTable/DataTable/model';
import { sessionNoteStore } from '../../shared/singletons';
import { SessionNote } from '../model';
import { useStoreObservable } from '../../shared/state/useStoreObservable.hook';

const columns: IDataTableColumn<SessionNote>[] = [
  {
    name: 'APPOINTMENT DATE',
    selector: 'appointment_date',
    sortable: true,
    sortFunction: (rowA: SessionNote, rowB: SessionNote) =>
      new Date(rowA.appointment_date).getTime() - new Date(rowB.appointment_date).getTime(),
  },
  {
    name: 'CREATED BY',
    selector: 'created_by',
    sortable: true,
  },
  {
    name: 'CONTENT',
    selector: 'all_fields',
    sortable: true,
    grow: 8,
    cell: (row: SessionNote) => (
      <div>
        <p>
          <strong>Content: </strong>
          {row.content || 'N/A'}
        </p>
        {row.assistance && (
          <p>
            <strong>Assistance: </strong>
            {row.assistance}
          </p>
        )}
        {row.percent_string && (
          <p>
            <strong>Percent: </strong>
            {row.percent_string}
          </p>
        )}
      </div>
    ),
  },
];

interface ExpandedSessionNoteListProps {
  goalId?: string;
  title?: string;
}

const ExpandedSessionNoteList: FunctionComponent<ExpandedSessionNoteListProps> = (props) => {
  const { goalId, title = 'Goal History' } = props;
  const { sessionNotes, isLoading } = useStoreObservable(sessionNoteStore);

  React.useEffect(() => {
    sessionNoteStore.fetchSessionNotes(goalId);
  }, [goalId]);

  return isLoading ? (
    <Box my={4}>
      <Typography align="center">Loading...</Typography>
    </Box>
  ) : (
    <Box my={4}>
      <Typography variant="h2" component="h2">
        {title}
      </Typography>
      <DataTable
        columns={columns}
        noHeader
        data={window.location?.href?.includes('session_notes') ? sessionNotes?.slice(0, 8) : sessionNotes}
        striped
        highlightOnHover
        pointerOnHover
        pagination
      />
    </Box>
  );
};

export default ExpandedSessionNoteList;
