import React from 'react';
import Typography from '@material-ui/core/Typography';
import LinkIcon from '@material-ui/icons/OpenInNew';
import { IconButton } from '@material-ui/core';

interface InformationFieldProps {
  label: string;
  capitalize?: boolean;
  value?: string | number | null;
  link?: string;
}

const InformationField: React.FC<InformationFieldProps> = (props) => {
  const { label, value, capitalize = false, link } = props;

  return (
    <div>
      <Typography variant="subtitle1" component="h6" style={{ textTransform: capitalize ? 'capitalize' : 'inherit' }}>
        {label}
      </Typography>
      <Typography variant="body1" component="p" style={{ whiteSpace: 'break-spaces', wordBreak: 'break-word' }}>
        {value}
        {link && (
          <IconButton href={link} target="_blank" rel="noopener noreferrer" style={{ marginLeft: 8 }} size="small">
            <LinkIcon />
          </IconButton>
        )}
      </Typography>
    </div>
  );
};

export default InformationField;
