import React, { FunctionComponent } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import DuplicateAppointments from '../components/DuplicateAppointments';
import { DuplicateAppointmentsContext } from '../contexts/DuplicateAppointmentsContext';

export type DuplicateAppointmentsPageProps = Record<string, unknown>;

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const DuplicateAppointmentsPage: FunctionComponent<DuplicateAppointmentsPageProps> = () => {
  const { fetchRecord } = React.useContext(DuplicateAppointmentsContext);
  const { studentId, userId } = useParams<Record<string, string>>();
  const params = useQuery();

  /* eslint-disable */
  React.useEffect(() => {
    fetchRecord(userId, studentId, params);
  }, []);
  /* eslint-enable */

  return <DuplicateAppointments initialSchoolId={useQuery().get('school_id')} />;
};

export default DuplicateAppointmentsPage;
