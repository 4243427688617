import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { BaseModalProps, ConfirmCancelLabels, ConfirmCancelLabelsByIndex } from './modal.model';
import ConfirmCancelModal from './ConfirmCancelModal';
import NumberedStepper from '../NumberedStepper';

interface StepperModalProps extends BaseModalProps {
  currentStepIndex: number;
  totalSteps: number;
  buttonLabelsByIndex?: ConfirmCancelLabelsByIndex;
  previousDisabled?: boolean;
  nextDisabled?: boolean;
  previousClicked?: () => void;
  nextClicked?: () => void;
}

const StepperModalWrapper = styled.div``;

const ModalContent = styled.div``;
const ChildrenContainer = styled.div``;

const StepperModal: FunctionComponent<StepperModalProps> = (props: StepperModalProps) => {
  const {
    //
    currentStepIndex,
    isOpen,
    title,
    totalSteps,
    buttonLabelsByIndex = {},
    children,

    previousDisabled = false,
    nextDisabled = false,

    previousClicked = () => null,
    nextClicked = () => null,
    openStatusChanged = (isOpen: boolean) => null,
  } = props;

  const getButtonLabels = (index: number) => buttonLabelsByIndex[index] || ConfirmCancelLabels.Defaults;

  const buttonLabels = getButtonLabels(currentStepIndex);

  return (
    <StepperModalWrapper>
      <ConfirmCancelModal
        isOpen={isOpen}
        title={title}
        onConfirm={() => nextClicked()}
        onCancel={() => previousClicked()}
        confirmDisabled={nextDisabled}
        cancelDisabled={previousDisabled || currentStepIndex <= 0}
        confirmLabel={buttonLabels.confirm}
        cancelLabel={buttonLabels.cancel}
        openStatusChanged={openStatusChanged}
      >
        <ModalContent>
          <NumberedStepper totalSteps={totalSteps} currentStepIndex={currentStepIndex} />
          <ChildrenContainer>{children}</ChildrenContainer>
        </ModalContent>
      </ConfirmCancelModal>
    </StepperModalWrapper>
  );
};

export default StepperModal;
