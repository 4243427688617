import { makeStyles } from '@material-ui/core';
import { Colors } from './colors';

export const useStyles = makeStyles({
  textInput: {
    height: '60px',
    width: '80%',
    margin: '10px 0',
  },
  selectInput: {
    height: 'auto',
    width: '98%',
    margin: '14px 0',
    textAlign: 'left',
  },
  baseError: {
    marginBottom: '10px',
  },
  description: {
    marginBottom: '12px',
  },
  errorIcon: {
    color: Colors.Red,
    fontSize: '66px',
    marginBottom: '20px',
  },
  formTitle: {
    marginLeft: '12px',
    marginTop: '20px',
  },
  modalText: {
    fontSize: '20px',
    marginLeft: '20%',
    marginBottom: '20px',
    width: '60%',
  },
  checkboxLabel: {
    textTransform: 'capitalize',
    fontWeight: 700,
    color: '#424E7A',
    fontSize: '18px',
  },
  subtitle: {
    marginBottom: '6px',
  },
});
