import React, { FunctionComponent, ChangeEvent } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Grid, Button, Typography, makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { alertStore } from '../../shared/singletons';
import TabLabels from '../../shared/components/TabLabels';
import TabLabel from '../../shared/components/TabLabel';
import TabPanel from '../../shared/components/TabPanel';
import LoadingSpinner from '../../shared/components/LoadingSpinner';
import AppointmentDetailsTab from './AppointmentDetailsTab';
import AppointmentCustomActions from './AppointmentCustomActions';
import AppointmentServiceDetails from './AppointmentServiceDetails';
import GoalsList from '../../goals/components/GoalsList';
import SessionNotesList from '../../sessionNotes/components/SessionNotesList';
import SessionNoteHistory from '../../sessionNotes/components/SessionNoteHistory';
import { Appointment } from '../model';
import Breadcrumbs, { BreadcrumbLink } from '../../shared/components/Breadcrumbs';
import { appointmentRecordStore } from '../../shared/singletons';
import { UserContext } from '../../auth/contexts/userContext';
import AppointmentNotesList from '../../appointmentNotes/components/AppointmentNotesList';
import AppointmentNoteDetails from '../../appointmentNotes/components/AppointmentNoteDetails';
import CompletedAppointmentTicketModal from '../../userTickets/components/CompletedAppointmentTicketModal';

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

interface AppointmentDetailProps {
  appointment: Appointment;
  appointmentLoading: boolean;
  tab: string;
}

const useStyles = makeStyles({
  root: {
    padding: 0,
  },
});

const tabLabels = ['details', 'service', 'session_notes', 'actions'];

const AppointmentDetail: FunctionComponent<AppointmentDetailProps> = (props: AppointmentDetailProps) => {
  const classes = useStyles();
  const history = useHistory();
  const { appointment, appointmentLoading, tab } = props;

  const { currentUserHasAnyRole } = React.useContext(UserContext);

  const [ticketModalOpen, setTicketModalOpen] = React.useState(false);
  const [value, setValue] = React.useState(
    currentUserHasAnyRole(['system_owner', 'admin', 'accountant', 'services_coordinator']) ? 0 : 2,
  );

  const links: BreadcrumbLink[] = [
    {
      href: `/students/${appointment?.student_id}`,
      text: `${appointment?.student?.first_name} ${appointment?.student?.last_name}`,
    },
    {
      href: `/students/${appointment?.student_id}/appointments`,
      text: 'APPOINTMENTS',
    },
    {
      href: `/students/${appointment?.student_id}/services/${appointment?.service_id}`,
      text: `${appointment?.service?.service_type}`,
    },
    {
      href: null,
      text: `${appointment?.service_type} ${appointment?.formatted_schedule_date} ${appointment?.start_time}`,
    },
  ];

  const handleChange = (_event: ChangeEvent, newValue: number) => {
    setValue(newValue);
    window.history.pushState(
      '',
      '',
      `/students/${appointment.student_id}/appointments/${appointment.id}/${tabLabels[newValue]}`,
    );
  };

  const appointmentIsUnlocked = ['incomplete', 'needs_rescheduled', 'scheduled'].includes(appointment?.status);

  const AddSessionNotesButton = () => (
    <Button
      variant="contained"
      color="primary"
      onClick={() =>
        history.push(`/students/${appointment.student_id}/appointments/${appointment.id}/session_notes/new`)
      }
    >
      Add Session Notes
    </Button>
  );

  const TimelineSupportRequestButton = () => {
    return (
      <Button
        variant="contained"
        color="default"
        onClick={() => setTicketModalOpen(true)}
        disabled={appointment?.pending_ticket_already_exists}
      >
        Timeline Support Request
      </Button>
    );
  };

  React.useEffect(() => {
    if (tab) {
      setValue(['details', 'service', 'session_notes', 'actions'].indexOf(tab));
    }
  }, [tab]);

  return appointmentLoading ? (
    <LoadingSpinner />
  ) : (
    <div>
      <CompletedAppointmentTicketModal
        appointment={appointment}
        onSuccess={() => alertStore.alertSuccess('Successfully submitted timeline support request.')}
        modalOpen={ticketModalOpen}
        setModalOpen={setTicketModalOpen}
      />
      <Grid justify="space-between" container spacing={3}>
        <Grid item>
          <Typography variant="h1" component="h1">
            {`${appointment.service_type} ${appointment.formatted_schedule_date} ${appointment.start_time}`}
          </Typography>
          <Breadcrumbs links={links} />
          <br />
        </Grid>
        {currentUserHasAnyRole(['system_owner', 'admin', 'service_provider', 'supervisor']) && (
          <Grid item>{appointmentIsUnlocked ? <AddSessionNotesButton /> : <TimelineSupportRequestButton />}</Grid>
        )}
      </Grid>

      <div>
        {currentUserHasAnyRole(['system_owner', 'admin', 'accountant', 'services_coordinator']) && (
          <TabLabels variant="scrollable" value={value} onChange={handleChange} aria-label="student tabs">
            <TabLabel label="Details" other={a11yProps(0)} />
            <TabLabel label="Service" other={a11yProps(1)} />
            {currentUserHasAnyRole(['system_owner', 'admin']) && <TabLabel label="Session Note" other={a11yProps(2)} />}
            {currentUserHasAnyRole(['system_owner', 'admin']) && <TabLabel label="Actions" other={a11yProps(3)} />}
          </TabLabels>
        )}
        <TabPanel value={value} index={0} className={classes.root}>
          <AppointmentDetailsTab appointment={appointment} appointmentLoading={appointmentLoading} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <AppointmentServiceDetails appointment={appointment} appointmentLoading={appointmentLoading} />
        </TabPanel>
        <TabPanel value={value} index={2}>
          {appointment?.goals?.length > 0 ? (
            <>
              <SessionNotesList sessionNotes={appointment.session_notes} isLoading={appointmentLoading} />
              <br />
              <GoalsList
                afterAction={() => appointmentRecordStore.fetchRecord(appointment.student_id, appointment.id)}
                goals={appointment.goals}
                serviceId={appointment.service?.id}
                isLoading={appointmentLoading}
              />
            </>
          ) : (
            <>
              <AppointmentNoteDetails appointmentNote={appointment?.appointment_note} isLoading={appointmentLoading} />
              <br />
              <GoalsList
                afterAction={() => appointmentRecordStore.fetchRecord(appointment.student_id, appointment.id)}
                goals={appointment.goals}
                serviceId={appointment.service?.id}
                isLoading={appointmentLoading}
              />
              <br />
            </>
          )}
          <br />
          <AppointmentNotesList appointment={appointment} appointmentNotes={appointment?.all_appointment_notes} />
          <br />
          {appointment?.goals?.length > 0 && (
            <Grid item xs={12}>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                  <Typography variant="h2" component="h2">
                    Past Session Notes
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <SessionNoteHistory appointment={appointment} appointmentLoading={appointmentLoading} />
                </AccordionDetails>
              </Accordion>
            </Grid>
          )}
        </TabPanel>
        {currentUserHasAnyRole(['system_owner', 'admin']) && (
          <TabPanel value={value} index={3}>
            <AppointmentCustomActions appointment={appointment} />
          </TabPanel>
        )}
      </div>
    </div>
  );
};

export default AppointmentDetail;
