/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, FunctionComponent, useContext } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Grid, Typography } from '@material-ui/core';
import InformationField from '../../shared/components/InformationField';
import { Service, AcademicYear } from '../../services/model';
import { axios } from '../../shared/singletons';
import YAGForm from '../components/YAGForm';
import YearlongAppointmentPreviewCalendar from '../components/YearlongAppointmentPreviewCalendar';
import LoadingSpinner from '../../shared/components/LoadingSpinner';
import { AppointmentsContext } from '../contexts/AppointmentsContext';
import { SchoolsContext } from '../../schools/contexts/SchoolsContext';

export type YearlongAppointmentGeneratorPageProps = Record<string, unknown>;

type AddAppointmentParams = {
  serviceId: string;
};

const YearlongAppointmentGeneratorPage: FunctionComponent<YearlongAppointmentGeneratorPageProps> = () => {
  let history = useHistory();

  let { serviceId } = useParams<AddAppointmentParams>();

  let [service, setService] = useState<Service>({});
  let [currentAcademicYear, setCurrentAcademicYear] = useState<AcademicYear>({});

  const { appointmentsLoading, setAppointmentsLoading, fetchAppointmentChoices, formShowing } = useContext(
    AppointmentsContext,
  );

  const { school, fetchSchool } = useContext(SchoolsContext);

  const fetchService = async () => {
    setAppointmentsLoading(true);
    const response = await axios.get(`referral_services/${serviceId}`);
    setService(response?.data?.result);
    setCurrentAcademicYear(response?.data?.result?.current_academic_year);

    const payer_id = response?.data?.result?.student?.payer_id;
    const student_id = response?.data?.result?.student_id;

    if (payer_id) {
      await fetchSchool(payer_id);
    }

    if (student_id) {
      await fetchAppointmentChoices(student_id, serviceId);
    }

    setAppointmentsLoading(false);
  };

  useEffect(() => {
    fetchService();
  }, [serviceId]);

  return (
    <>
      <Typography variant="h1">Yearlong Appointment Generator</Typography>
      {appointmentsLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <Grid container spacing={6}>
            <Grid item>
              <InformationField label="Student" value={service?.student_name} />
            </Grid>
            <Grid item>
              <InformationField label="Timeline ID" value={service?.student_slug} />
            </Grid>
            <Grid item>
              <InformationField label="Service Type" value={service?.service_type} />
            </Grid>
            <Grid item>
              <InformationField label="Academic Year" value={currentAcademicYear?.title} />
            </Grid>
          </Grid>
          <br />
          {formShowing ? (
            <YAGForm service={service} school={school} academicYear={currentAcademicYear} />
          ) : (
            <YearlongAppointmentPreviewCalendar
              afterCreate={() => history.push(`/students/${service?.student_id}/services/${service?.id}/appointments`)}
              includeProrateButton={true}
            />
          )}
        </>
      )}
    </>
  );
};

export default YearlongAppointmentGeneratorPage;
