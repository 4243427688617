import React, { useContext } from 'react';
import { Typography } from '@mui/material';
import DeleteModal from '../../shared/components/modal/DeleteModal';
import { DischargeStudentContext } from '../../students/contexts/DischargeStudentContext';
import { axios, alertStore } from '../../shared/singletons';
import { School } from '../model';

type DischargeSchoolModalProps = {
  dischargeSchoolModalOpen: boolean;
  setDischargeSchoolModalOpen: (value: boolean) => void;
  schoolToDischarge: School;
};

function DischargeSchoolModal(props: DischargeSchoolModalProps) {
  const { dischargeSchoolModalOpen, setDischargeSchoolModalOpen } = props;

  const { schoolToDischarge, setSchoolToDischarge } = useContext(DischargeStudentContext);

  const handleDischargeSchool = () => {
    setDischargeSchoolModalOpen(false);
    axios
      .put(`payers/${schoolToDischarge.id}`, { discharged: true })
      .then(() => {
        alertStore.alertSuccess('Successfully discharged school.');
        setSchoolToDischarge({ ...schoolToDischarge, discharged: true });
      })
      .catch((error) => {
        alertStore.alertError(error);
      });
  };

  return (
    <DeleteModal
      isOpen={dischargeSchoolModalOpen}
      openStatusChanged={setDischargeSchoolModalOpen}
      onDelete={handleDischargeSchool}
      confirmLabel="Discharge"
    >
      <Typography align="center" variant="h5">
        Are you sure you want to discharge this school? This action cannot be undone.
      </Typography>
    </DeleteModal>
  );
}

export default DischargeSchoolModal;
