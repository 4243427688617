import { axios, studentRecordStore } from '../shared/singletons';
import { BaseStore } from '../shared/state/base.store';
import { Student, StudentFormValues, StudentFormErrors, StudentResponse } from './model';

import { API_ENDPOINT } from '../../config/env';

export class StudentState {
  formValues: StudentFormValues;
  formErrors: StudentFormErrors;
  newStudent: boolean;

  formErrorAlertVisible: boolean;
  formStepperIndex: number;

  allStudentInfoModalOpen: boolean;
  detailsModalOpen: boolean;
  destroyModalOpen: boolean;

  static create(props: Partial<StudentState>): StudentState {
    const defaults: StudentState = {
      formValues: {},
      formErrors: {},
      newStudent: false,

      formErrorAlertVisible: true,
      formStepperIndex: 0,

      allStudentInfoModalOpen: false,
      detailsModalOpen: false,
      destroyModalOpen: false,
    };
    return Object.assign(new StudentState(), defaults, props || {});
  }
}

export class StudentFormStore extends BaseStore<StudentState> {
  constructor() {
    super(StudentState.create({}));
  }

  public async createOrUpdateStudent(
    formValues: StudentFormValues,
    studentId: string,
    afterAction?: () => void,
  ): Promise<void> {
    try {
      const response = await axios[studentId ? 'put' : 'post']<string, StudentResponse>(
        `${API_ENDPOINT}/patients${studentId ? `/${studentId}` : ''}.json`,
        {
          patient: formValues,
          headers: { 'Content-Type': 'application/json' },
        },
      );

      if (afterAction) {
        afterAction();
      }

      studentRecordStore.setState({ student: response?.data?.result });
      this.setState({ formStepperIndex: 1 });
    } catch (error) {
      this.setState({
        formStepperIndex: 0, // Don't progress stepper if submit errored
        formErrors: error.response?.data,
        formErrorAlertVisible: !!error.response?.data?.base, // The base errors are not tied to a specific field and displayed at the top of the form
      });
    }
  }

  public updateEmergencyContacts(formValues: StudentFormValues, studentId: string): void {
    axios
      .put<string, StudentResponse>(`${API_ENDPOINT}/patients/${studentId}.json`, {
        patient: formValues,
        headers: { 'Content-Type': 'application/json' },
      })
      .then((_response) => {
        // this.setState({ formStepperIndex: 2, formStudentId: response.data.result.id });
        this.setState({ formStepperIndex: 2 });
      })
      .catch((error) => {
        this.setState({
          formStepperIndex: 1, // Don't progress stepper if submit errored
          formErrors: error.response?.data,
          formErrorAlertVisible: !!error.response?.data?.base, // The base errors are not tied to a specific field and displayed at the top of the form
        });
      });
  }

  public updateAddress(formValues: StudentFormValues, studentId: string, onSuccess: () => void): void {
    axios
      .put<string, StudentResponse>(`${API_ENDPOINT}/patients/${studentId}.json`, {
        patient: formValues,
        headers: { 'Content-Type': 'application/json' },
      })
      .then(() => {
        if (onSuccess) {
          onSuccess();
        } else {
          this.setState({ formStepperIndex: 3 });
        }
      })
      .catch((error) => {
        this.setState({
          formStepperIndex: 2, // Don't progress stepper if submit errored
          formErrors: error.response.data,
          formErrorAlertVisible: !!error.response?.data?.base, // The base errors are not tied to a specific field and displayed at the top of the form
        });
      });
  }

  public setStepperIndex(index: number): void {
    this.setState({ formStepperIndex: index });
  }

  public setCreateModalOpen(isOpen: boolean): void {
    if (isOpen) {
      studentRecordStore.setState({ student: {} });
    }

    this.setState({
      allStudentInfoModalOpen: isOpen,
      formValues: { discharged: 'false' },
      formStepperIndex: 0,
      newStudent: true,
    });
  }

  public setDetailsModalOpen(isOpen: boolean, student: Student): void {
    this.clearFormValues();

    if (student) {
      // Set record store student value in case we're inside service or renewal detail page
      studentRecordStore.setState({ student });
      this.spreadStudentFormValues(student, {});
      this.setState({ detailsModalOpen: isOpen, formStepperIndex: 0, newStudent: false });
    }
  }

  public setEditModalOpen(isOpen: boolean, student: Student): void {
    this.clearFormValues();

    if (student) {
      studentRecordStore.setState({ student });
      this.spreadStudentFormValues(student, {});
      this.setState({ allStudentInfoModalOpen: isOpen, formStepperIndex: 0, newStudent: false });
    }
  }

  public setFormErrorAlertVisibility(isVisible: boolean): void {
    this.setState({ formErrorAlertVisible: isVisible });
  }

  protected clearFormValues(): void {
    this.setState({ formValues: {} });
  }

  protected spreadStudentFormValues(student: Student, customValues: Record<string, string>): void {
    this.setState({
      formValues: {
        ...customValues,
        id: student.id,
        first_name: student.first_name,
        last_name: student.last_name,
        payer_id: student.payer_id,
        discharged: student.status,
        teacher: student.teacher,
        room_number: student.room_number,
        birthdate: student.birthdate,
        location_id: student.location_id,
        grade_level_id: student.grade_level_id,
        email_student: student.email,
        address_line1: student.address_line1,
        address_line2: student.address_line2,
        address_city: student.address_city,
        address_state: student.address_state,
        address_zip: student.address_zip,
      },
    });
  }
}
