import React, { useState } from 'react';
import { axios, alertStore } from '../../../shared/singletons';
import { RenewalStage, RenewalStageFormValues } from '../model';

interface RenewalStageStateInterface {
  renewalStages?: RenewalStage[];
  renewalStage?: RenewalStage | null;
  isLoading?: boolean;
  formValues?: RenewalStageFormValues | null;
  editModalOpen?: boolean;
  createModalOpen?: boolean;
  fetchRecords?: () => void;
  createRenewalStage?: (formValues: RenewalStageFormValues) => void;
  updateRenewalStage?: (editFormValues: RenewalStageFormValues, renewalStage: RenewalStage) => void;
  deleteRenewalStage?: (renewalStage: RenewalStage, onDelete: () => void) => void;
  setEditModalOpen?: (isOpen: boolean, renewalStage: RenewalStage) => void;
  setFormValue?: (formValues: RenewalStageFormValues) => void;
}

const RenewalStagesContext = React.createContext<RenewalStageStateInterface>({} as RenewalStageStateInterface);

const RenewalStagesContextProvider: React.FC = ({ children }) => {
  const [renewalStages, setRecords] = useState<RenewalStage[]>([]);
  const [renewalStage, setRenewalStage] = useState<RenewalStage | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [formValues, setFormValues] = useState<RenewalStageFormValues | null>(null);
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);
  const [createModalOpen, setCreateModalOpen] = useState<boolean>(false);

  const fetchRecords = (): void => {
    setIsLoading(true);

    axios(`renewal_stages.json`)
      .then((result) => setRecords(result?.data?.result ?? []))
      .catch(() => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const createRenewalStage = (formValues: RenewalStageFormValues): void => {
    axios({
      method: 'post',
      url: `renewal_stages.json`,
      data: { renewal_stage: formValues },
      headers: { 'Content-Type': 'application/json' },
    })
      .then(() => {
        alertStore.alertSuccess('Successfully created renewal stage.');
        fetchRecords();
        setCreateModalOpen(false);
      })
      .catch(() => {
        setCreateModalOpen(false);
      });
  };

  const updateRenewalStage = (editFormValues: RenewalStageFormValues, renewalStage: RenewalStage): void => {
    axios({
      method: 'put',
      url: `renewal_stages/${renewalStage.id}.json`,
      data: { renewal_stage: editFormValues },
      headers: { 'Content-Type': 'application/json' },
    })
      .then(() => {
        alertStore.alertSuccess('Successfully updated renewal stage.');
        fetchRecords();
        setEditModalOpen(false);
      })
      .catch(() => {
        setEditModalOpen(false);
      });
  };

  const deleteRenewalStage = (renewalStage: RenewalStage, onDelete: () => void): void => {
    axios({
      method: 'delete',
      url: `renewal_stages/${renewalStage.id}.json`,
      headers: { 'Content-Type': 'application/json' },
    })
      .then(() => {
        alertStore.alertSuccess('Successfully deleted renewal stage.');
        fetchRecords();
        onDelete();
      })
      .catch(() => {
        onDelete();
      });
  };

  const setEditModalOpenFunc = (isOpen: boolean, renewalStage: RenewalStage): void => {
    setFormValues({ name: renewalStage.name });
    setEditModalOpen(isOpen);
    setRenewalStage(renewalStage);
  };

  return (
    <RenewalStagesContext.Provider
      value={{
        renewalStages,
        renewalStage,
        isLoading,
        formValues,
        editModalOpen,
        createModalOpen,
        fetchRecords,
        createRenewalStage,
        updateRenewalStage,
        deleteRenewalStage,
        setEditModalOpen: setEditModalOpenFunc,
      }}
    >
      {children}
    </RenewalStagesContext.Provider>
  );
};

export { RenewalStagesContext, RenewalStagesContextProvider };
