import React, { FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';
import { Accordion, Grid } from '@material-ui/core';
import DashboardPanel from './DashboardPanel';
import { Colors } from '../../shared/style/colors';
import { dashboardStore } from '../../shared/singletons';
import { useStoreObservable } from '../../shared/state/useStoreObservable.hook';
import { today, yesterday, lastSchoolYear } from '../../shared/utils/date.utils';

interface TimelineAppointmentCounterProps {
  ownerParam: string;
  supervisor_id?: string;
}

const TimelineAppointmentCounter: FunctionComponent<TimelineAppointmentCounterProps> = (props) => {
  const history = useHistory();
  const { ownerParam, supervisor_id } = props;
  const { appointments } = useStoreObservable(dashboardStore);

  const todayScheduleLink = () => {
    if (supervisor_id) {
      return `/appointments?filters=ransack[start_time_gteq]=${today()}__ransack[start_time_lteq]=${today()}__${ownerParam}__supervisor_id=${supervisor_id}`;
    } else {
      return `/appointments?filters=ransack[start_time_gteq]=${today()}__ransack[start_time_lteq]=${today()}__${ownerParam}`;
    }
  };

  const needsRescheduledLink = () => {
    if (supervisor_id) {
      return `/appointments?filters=ransack[start_time_gteq]=${lastSchoolYear()}__ransack[start_time_lteq]=${yesterday()}__ransack[status_eq]=needs_rescheduled__${ownerParam}__supervisor_id=${supervisor_id}`;
    } else {
      return `/appointments?filters=ransack[start_time_gteq]=${lastSchoolYear()}__ransack[start_time_lteq]=${yesterday()}__ransack[status_eq]=needs_rescheduled__${ownerParam}`;
    }
  };

  const incompleteLink = () => {
    if (supervisor_id) {
      return `/appointments?filters=ransack[start_time_gteq]=${lastSchoolYear()}__ransack[start_time_lteq]=${yesterday()}__ransack[status_eq]=incomplete__${ownerParam}__supervisor_id=${supervisor_id}`;
    } else {
      return `/appointments?filters=ransack[start_time_gteq]=${lastSchoolYear()}__ransack[start_time_lteq]=${yesterday()}__ransack[status_eq]=incomplete__${ownerParam}`;
    }
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={4}>
        <Accordion square expanded onClick={() => history.push(todayScheduleLink())}>
          <DashboardPanel title="Today's Schedule" panelCount={appointments.today} panelColor={Colors.Success} />
        </Accordion>
      </Grid>
      <Grid item xs={4}>
        <Accordion square expanded onClick={() => history.push(needsRescheduledLink())}>
          <DashboardPanel
            title="Needs Rescheduled"
            panelCount={appointments.needs_rescheduled}
            panelColor={Colors.Orange}
          />
        </Accordion>
      </Grid>
      <Grid item xs={4}>
        <Accordion square expanded onClick={() => history.push(incompleteLink())}>
          <DashboardPanel title="Incomplete" panelCount={appointments.incomplete} panelColor={Colors.Red} />
        </Accordion>
      </Grid>
    </Grid>
  );
};

export default TimelineAppointmentCounter;
