import React, { useContext } from 'react';
import { Button } from '@material-ui/core';
import { RenewalsContext } from '../contexts/RenewalsContext';
import { CSVLink } from 'react-csv';

const DownloadRRRenewalsButton: React.FC = () => {
  const { rrRenewalsCSV, handlePrepareRRDownload, submittingDownload } = useContext(RenewalsContext);

  return rrRenewalsCSV?.length > 0 ? (
    <CSVLink
      data={rrRenewalsCSV}
      filename="rr_renewals.csv"
      className="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary"
      style={{ marginLeft: '8px', backgroundColor: 'lightgray', color: '#000000' }}
    >
      Download RR Renewals
    </CSVLink>
  ) : (
    <Button onClick={handlePrepareRRDownload} variant="contained" color="primary" disabled={submittingDownload}>
      {submittingDownload ? 'Preparing Download' : 'Prepare Download'}
    </Button>
  );
};

export default DownloadRRRenewalsButton;
