import { axios } from '../shared/singletons';
import { BaseStore } from '../shared/state/base.store';
import { ReportFormValues } from './model';
import { SelectOption } from '../shared/common.model';
import { beginningOfLastMonth, endOfLastMonth } from '../shared/utils/date.utils';

export class ReportState {
  productivityRecords: Record<string, string>[];
  schoolActivityRecords: Record<string, string>[];
  invoiceRecords: string[][];
  invoiceAppointmentRecords: Record<string, string>[];
  studentBySchoolRecords: Record<string, string>[];
  invalidAppointmentRecords: Record<string, unknown>[];
  isLoading: boolean;
  formValues: ReportFormValues;
  invoiceColumns: (string | number)[];
  statusChoices: SelectOption[];

  static create(props: Partial<ReportState>): ReportState {
    const defaults: ReportState = {
      productivityRecords: [],
      schoolActivityRecords: [],
      invoiceRecords: [],
      invoiceAppointmentRecords: [],
      studentBySchoolRecords: [],
      invalidAppointmentRecords: [],
      isLoading: false,
      formValues: {
        start_date: beginningOfLastMonth(),
        end_date: endOfLastMonth(),
      },
      invoiceColumns: [
        'original_date',
        'date_of_service',
        'status',
        'student_name',
        'related_service',
        'therapist_name',
        'related_service_units',
        'related_service_unit_price',
        'related_service_unit_total',
      ],
      statusChoices: [
        { label: 'Active', value: 'active' },
        { label: 'Complete', value: 'complete' },
      ],
    };
    return Object.assign(new ReportState(), defaults, props || {});
  }
}

export class ReportStore extends BaseStore<ReportState> {
  constructor() {
    super(ReportState.create({}));
  }

  public async generateProductivityReport(): Promise<void> {
    this.setState({ isLoading: true, productivityRecords: [] });

    try {
      const { week_of = '', supervisor_ids = '' } = this.getState().formValues;
      const response = await axios(`reports/productivity.json?week_of=${week_of}&supervisor_ids=${supervisor_ids}`);
      this.setState({ productivityRecords: response?.data?.result, isLoading: false });
    } catch {
      this.setState({ isLoading: false });
    }
  }

  public async generateSchoolActivityReport(): Promise<void> {
    this.setState({ isLoading: true, schoolActivityRecords: [] });

    try {
      const { start_date = '', end_date = '' } = this.getState().formValues;
      const response = await axios(`reports/school_activity.json?start_date=${start_date}&end_date=${end_date}`);
      this.setState({ schoolActivityRecords: response?.data?.result, isLoading: false });
    } catch {
      this.setState({ isLoading: false });
    }
  }

  public async generateStudentBySchoolReport(): Promise<void> {
    this.setState({ isLoading: true, studentBySchoolRecords: [] });

    const { status = '', school_ids = [] } = this.getState().formValues;
    const school_ids_param = school_ids.join('&payer_ids[]=');
    const response = await axios(`reports/students_by_school.json?status=${status}&payer_ids[]=${school_ids_param}`);

    this.setState({ studentBySchoolRecords: response?.data?.result, isLoading: false });
  }

  public async generateInvalidAppointmentsReport(): Promise<void> {
    this.setState({ isLoading: true, invalidAppointmentRecords: [] });

    const { status = '' } = this.getState().formValues;
    const response = await axios(`reports/invalid_appointments.json?status=${status}`);

    const formattedInvalidAppointmentRecords = [];

    response?.data?.result?.forEach((appointment: Record<string, unknown>) => {
      formattedInvalidAppointmentRecords.push({
        'Student Name': appointment?.student_name,
        School: appointment?.school_abbreviation,
        Location: appointment?.location_name,
        'Service Type': appointment?.service_type,
        'Scheduled Date': appointment?.formatted_schedule_date,
        'Start Time': appointment?.formatted_start_time,
        'End Time': appointment?.formatted_end_time,
        Owner: appointment?.owner_last_name_first_name,
        'Original Date': appointment?.formatted_original_date,
        Units: appointment?.units,
        Status: appointment?.status,
      });
    });

    this.setState({ isLoading: false, invalidAppointmentRecords: formattedInvalidAppointmentRecords });
  }

  public async generateInvoiceReport(schoolId: string, invoiceColumns): Promise<void> {
    this.setState({ isLoading: true, invoiceRecords: [] });

    const { start_date = '', end_date = '' } = this.getState().formValues;
    await axios(
      `reports/invoice_report.json?payer_id=${schoolId}&start_date=${start_date}&end_date=${end_date}&columns=${invoiceColumns}`,
    );

    this.setState({
      isLoading: false,
    });
  }
}
