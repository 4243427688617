import { _size } from './collection.utils';
import { _allToLower, _toLower } from './string.utils';

export const _anyAreDefined = (items: any[]): boolean => (items || []).some((item) => !_isUndefined(item));
export const _isEmptyStringOrNil = (item: string): boolean => item === '' || item === null || item === undefined;
export const _isString = (item: any): boolean => typeof item === 'string';
export const _isArray = (item: any): boolean => item && Array.isArray(item);
export const _isObject = (obj) => Object.prototype.toString.call(obj) === '[object Object]';
export const _isFunction = (item: any): boolean => typeof item === 'function';
export const _isNumber = (item: any): boolean => typeof item === 'number' && !isNaN(item);
export const _isBoolean = (item: boolean): boolean => typeof item === 'boolean';
export const _isUndefined = (item: any): boolean => typeof item === 'undefined';
export const _isNil = (item): boolean => _isUndefined(item) || item === null;
export const _isEven = (value: number): boolean => value % 2 === 0;

export const _allPass = (items: any[], predicate: (item) => boolean): boolean =>
  !items.find((item) => !predicate(item));
export const _allAreBoolean = (items: any[]): boolean => _allPass(items, _isBoolean);
export const _allAreString = (items: any[]): boolean => _allPass(items, _isString);
export const _allAreNumber = (items: any[]): boolean => _allPass(items, _isNumber);
export const _anyAreNil = (items: any[]): boolean => !!(items || []).find(_isNil);
export const _anyAreTrue = (items: any[]): boolean => !!(items || []).find((item) => item === true);
export const _allAreTruthy = (items: any[]): boolean => (items || []).every((item) => !!item);
export const _allAreEqualTo = (value: any, items: any[]): boolean => _allPass(items, (item) => item === value);

export const _throwIfTruthy = (item: any, message: string): void => {
  if (!!item) {
    throw new Error(message);
  }
};
export const _throwIfNil = (item, message: string): void => _throwIfTruthy(_isNil(item), message);
export const _throwIfFalsey = (item, message: string): void => _throwIfTruthy(!item, message);
export const _throwIfSizeExceeds = (max: number, items: any[], message: string): void =>
  _throwIfTruthy(_size(items) > max, message);

export const _isFalseCaseInsensitive = (val: string): boolean => _isString(val) && _toLower(val) === 'false';

export const _isCaseInsensitiveMatch = (one: string, two: string): boolean => _toLower(one) === _toLower(two);
export const _includesCaseInsensitive = (itemToFind: string, items: string[]) =>
  _allToLower(items).includes(_toLower(itemToFind));
