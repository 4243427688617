import React, { FunctionComponent } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import DataTable from '../../shared/components/DataTable';
import LoadingSpinner from '../../shared/components/LoadingSpinner';
import { IDataTableColumn } from '../../shared/components/DataTable/DataTable/model';
import { HistoryItem } from '../../shared/common.model';

const columns: IDataTableColumn<Record<string, unknown>>[] = [
  {
    name: 'DATE MODIFIED',
    selector: 'date_modified',
    sortable: true,
  },
  {
    name: 'STAGE FROM',
    selector: 'stage_from',
    sortable: true,
  },
  {
    name: 'STAGE TO',
    selector: 'stage_to',
    sortable: true,
  },
  {
    name: 'USER',
    selector: 'user',
    sortable: true,
  },
];

interface RenewalHistoryProps {
  historyItems: HistoryItem[];
  isLoading: boolean;
}

const RenewalHistory: FunctionComponent<RenewalHistoryProps> = (props) => {
  const { historyItems, isLoading } = props;

  return isLoading ? (
    <LoadingSpinner />
  ) : (
    <Card>
      <CardContent>
        <Typography variant="h2" component="h2">
          Renewal History
        </Typography>
        <DataTable columns={columns} noHeader data={historyItems} striped highlightOnHover pointerOnHover />
      </CardContent>
    </Card>
  );
};

export default RenewalHistory;
