import { serviceFormStore } from '../../shared/singletons';
import axios from '../../shared/utils/axios.utils';

const demoService = {
  student_slug: 'stu-d10',
  student_name: 'Demo Student',
  service_type: 'Demo Service',
};

export const joyrideSteps = [
  {
    target: 'body',
    content: 'This training will guide you through the steps for changing the status of a service.',
    placement: 'center' as const,
    title: 'Welcome to the Training',
    disableScrolling: true,
  },
  {
    target: '.joyride-serviceStage-step1',
    content: "Click here to open the 'Change Stage' modal",
    hideCloseButton: true,
    disableScrolling: true,
    routeAfter: '/referrals',
    afterAction: () => serviceFormStore.setEditStageModalOpen(true, demoService),
  },
  {
    target: '.joyride-serviceStage-step2',
    content: 'Now the Service Stage Modal is open.  Use this field to select the new stage for this referral.',
  },
  {
    target: '.joyride-serviceStage-step2',
    content:
      "Note: You may change it here for practice, but you aren't required to.  Any changes you make to this demo student will be reset after the training completes. ",
    disableBeacon: true,
  },
  {
    target: '.buttons',
    content: 'Make sure to click "Update Service" when you finish.',
  },
  {
    target: 'body',
    content:
      'This concludes the tutorial for changing a referrals stage.  If you would like to see it again, please select it from your "My Trainings" page.',
    placement: 'center' as const,
    title: 'Conclusion',
    disableScrolling: true,
    afterAction: () => axios.get('/my_trainings/reset_demo_student'),
  },
];
