import React, { useState, useContext } from 'react';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import TextInput from '../../shared/components/form/TextInput';

import { AppointmentsContext } from '../contexts/AppointmentsContext';

import { axios } from '../../shared/singletons';

const ProrateSection = () => {
  let { appointmentYear, setAppointmentYear } = useContext(AppointmentsContext);

  let [boundaryDate, setBoundaryDate] = useState('');

  const handleProrate = () => {
    axios
      .post(`yearlong_appointment_generators/prorate`, { appointmentYear, boundary_date: boundaryDate })
      .then((result) => {
        setAppointmentYear(result?.data);
      });
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <hr />
      </Grid>
      <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
        <Typography variant="h4" style={{ fontSize: 18, marginRight: 10 }}>
          Delete Appointments Before:
        </Typography>
        <TextInput
          label="Date"
          type="date"
          value={boundaryDate}
          valueChanged={(value) => setBoundaryDate(value)}
          shrinkLabel
        />
        <Button variant="contained" onClick={handleProrate} style={{ backgroundColor: '#F44F64', marginLeft: 10 }}>
          Delete
        </Button>
      </Grid>
    </Grid>
  );
};

export default ProrateSection;
