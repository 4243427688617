import React from 'react';
import { Typography } from '@material-ui/core';
import ReferralStudentForm from '../components/ReferralStudentForm';
import { ReferralsContext } from '../contexts/ReferralsContext';

const NewReferralStudent: React.FC = () => {
  const [choicesFetched, setChoicesFetched] = React.useState(false);
  const [creatingStudent, setCreatingStudent] = React.useState(false);

  const { fetchChoices } = React.useContext(ReferralsContext);

  React.useEffect(() => {
    if (!choicesFetched) {
      setChoicesFetched(true);
      fetchChoices();
    }
  }, [choicesFetched, fetchChoices]);

  return false ? (
    <p>Loading...</p>
  ) : (
    <>
      <Typography variant="h1" component="h1">
        New Referral
      </Typography>
      <ReferralStudentForm creatingStudent={creatingStudent} setCreatingStudent={setCreatingStudent} />
    </>
  );
};

export default NewReferralStudent;
