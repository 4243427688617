// ###########################
// NUMBER
// ###########################

import { Dict, Primitive } from './generic.model';
import { _compactJoin, _values } from './collection.utils';
import { _isNil, _isNumber, _throwIfTruthy } from './validation.utils';

export const _round = (item: number, multiple: number = 1): number => Math.round(item / multiple) * multiple;
export const _floor = (item: number): number => Math.floor(item);
export const _max = (...values: number[]): number => Math.max(...values);
export const _toNumbers = (items: string[]): number[] => (items || []).map((item) => Number(item));
export const _randomNumber = (): number => Math.random();
export const _randomInteger = (multiplier: number = 1000000000): number => _floor(_randomNumber() * multiplier);
export const _absoluteValue = (item: number): number => Math.abs(item);

export const _toString = (val: Primitive): string => {
  if (typeof val == 'string') return val;
  if (typeof val == 'number') return _stringifiedNumber(val);
  if (typeof val == 'boolean') return val.toString();
  return null;
};
export const _toStrings = (vals: Primitive[]): string[] => (vals || []).map((val) => _toString(val));
export const _stringifiedNumber = (val: number | string): string =>
  typeof val == 'number' ? (_isNumber(val) ? val.toString() : null) : val;
export const _isValueMatch = (one: number | string, two: number | string): boolean =>
  _stringifiedNumber(one) === _stringifiedNumber(two);

export const _commaSeparatedUnitsDisplay = (value: number, units: string = null) => {
  const _value: string = _commaSeparated(value);
  return _value && units ? `${_value}${units}` : _value;
};

export const _numberRange = (min: number, max: number, delta: number = 1): number[] => {
  if (min > max) return [];
  _throwIfTruthy(!(delta > 0), 'delta must be greater than 0');

  if (_isNil(min) && !_isNil(max)) return [max];
  if (_isNil(max) && !_isNil(min)) return [min];

  let results = [];
  while (max >= min) {
    results.push(min);
    min += delta;
  }
  return results;
};

export const _forceTwoDigits = (value: number): string => (value < 10 ? `0${value}` : `${value}`);

export const _sum = (items: number[]): number => {
  let sum = 0;
  (items || []).forEach((item) => (sum += item || 0));
  return sum;
};

export const _sumOfValues = (dict: Dict<number>): number => _sum(_values<number>(dict));

export const _justDigits = (input: string): string => (typeof input == 'string' ? input.replace(/\D/g, '') : null);
export const _commaSeparated = (input: number): string => (!_isNil(input) ? input.toLocaleString('en') : null);

export const _dollarDisplay = (amount: number, numDecimals: number = 0): string => {
  if (_isNil(amount)) {
    return null;
  }
  const _justDecimals = (val: number): string => (_isNumber(val) ? val.toFixed(numDecimals).split('.')[1] : null);

  const beforeDecimals = _absoluteValue(amount).toString().split('.')[0];
  const decimals = _justDecimals(amount);

  const sign = amount < 0 ? '-' : '';

  return _compactJoin([`${sign}$${_commaSeparated(parseFloat(beforeDecimals))}`, decimals], '.');
  // return _isNil(amount) ? null : `$${parseFloat(amount.toFixed(numDecimals))}`;
};
export const _signedDollarDisplay = (val: number, numDecimals: number = 0): string => {
  const operator = !val ? '' : val > 0 ? '+' : '-';
  const dollarDisplay = _dollarDisplay(val, numDecimals);
  return dollarDisplay ? `${operator}${dollarDisplay}` : null;
};
