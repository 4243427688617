import React, { FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Card, CardContent, Grid, Typography } from '@material-ui/core';
import DataTable from '../../shared/components/DataTable';
import { Appointment } from '../../appointments/model';
import { dashboardStore } from '../../shared/singletons';
import { useStoreObservable } from '../../shared/state/useStoreObservable.hook';

const columns: unknown[] = [
  {
    name: 'TIMELINE ID',
    selector: 'slug',
    sortable: true,
  },
  {
    name: 'UPDATED AT',
    selector: 'updated_at',
    sortable: true,
  },
  {
    name: 'SCHOOL',
    selector: 'school',
    sortable: true,
  },
];

const RecentlyUpdatedStudents: FunctionComponent = () => {
  const { isLoading, students } = useStoreObservable(dashboardStore);
  const history = useHistory();

  return (
    <Box>
      <Grid justify="space-between" container spacing={3}>
        <Grid item>
          <Typography variant="h1" component="h1">
            Recently Updated Students
          </Typography>
        </Grid>
      </Grid>
      <Card>
        <CardContent>
          <DataTable
            title=""
            columns={columns}
            noHeader
            data={students}
            progressPending={isLoading}
            striped
            onRowClicked={(appointment: Appointment) => history.push(`/students/${appointment.id}`)}
            highlightOnHover
            pointerOnHover
          />
        </CardContent>
      </Card>
    </Box>
  );
};

export default RecentlyUpdatedStudents;
