/* eslint-disable react-hooks/exhaustive-deps */
import React, { FunctionComponent, useState } from 'react';
import { Grid, FormGroup, FormControlLabel, Checkbox, Typography, makeStyles } from '@material-ui/core';
import TextInput from '../../shared/components/form/TextInput';
import SelectInput from '../../shared/components/form/SelectInput';
import ErrorAlert from '../../shared/components/ErrorAlert';
import { renewalFormStore } from '../../shared/singletons';
import { RenewalOptionsContext } from '../contexts/RenewalOptionsContext';
import { useStoreObservable } from '../../shared/state/useStoreObservable.hook';
import { SelectOption } from '../../shared/common.model';
import InformationField from '../../shared/components/InformationField';

const useStyles = makeStyles({
  textInput: {
    height: '60px',
    width: '80%',
    margin: '10px 0',
  },
  baseError: {
    marginBottom: '10px',
  },
});

const RenewalForm: FunctionComponent = () => {
  const classes = useStyles();

  const { formErrorAlertVisible, formErrors, formValues } = useStoreObservable(renewalFormStore);
  const [serviceEndDate, setServiceEndDate] = useState<string>('');

  const {
    fetchChoices,
    renewalTypeChoices,
    renewalStageChoices,
    attachedServiceChoices,
    allOwnerChoices,
  } = React.useContext(RenewalOptionsContext);

  const handleFormValueChange = (key: string, value: string) => {
    if (key === 'user_ids') {
      if (formValues[key] === undefined) {
        formValues[key] = [];
      }

      if (formValues[key]?.find((x) => x === value)) {
        formValues[key] = formValues[key].filter((p) => p !== value);
      } else {
        formValues[key] = [...formValues[key], value];
      }

      renewalFormStore.setState({ formValues: { ...formValues, [key]: formValues[key] } });
    } else {
      renewalFormStore.setState({ formValues: { ...formValues, [key]: value } });
    }
  };

  React.useEffect(() => {
    fetchChoices(formValues.student_id);
  }, [formValues.student_id]);

  const renewalTypeName = renewalTypeChoices.find((x) => x.value === formValues?.renewal_type_id)?.label;

  const handleServiceChange = (value) => {
    const chosenService = attachedServiceChoices.find((service) => service.value === value);

    renewalFormStore.setState({ formValues: { ...formValues, referral_service_id: value } });
    setServiceEndDate(chosenService?.end_date);
  };

  return (
    <form noValidate autoComplete="off">
      {formErrors?.base && (
        <ErrorAlert
          display={formErrorAlertVisible}
          onClose={() => renewalFormStore.setState({ formErrorAlertVisible: false })}
        >
          {formErrors?.base}
        </ErrorAlert>
      )}
      <SelectInput
        styleOverrides={classes.textInput}
        type="text"
        label="Renewal Type"
        errorMessage={formErrors?.renewal_type}
        value={formValues?.renewal_type_id}
        valueChanged={(value: string) => handleFormValueChange('renewal_type_id', value)}
        choices={renewalTypeChoices}
        defaultChoice={{ value: '', label: 'None' }}
      />
      {renewalTypeName === 'RR' ? (
        <TextInput
          styleOverrides={classes.textInput}
          type="date"
          shrinkLabel
          label="Student RR Date"
          errorMessage={formErrors?.student_rr_date}
          value={formValues?.student_rr_date}
          valueChanged={(value: string) => handleFormValueChange('student_rr_date', value)}
        />
      ) : (
        <InformationField label="Service End Date" value={serviceEndDate || formValues?.service_end_date} />
      )}
      <SelectInput
        styleOverrides={classes.textInput}
        type="text"
        label="Renewal Stage"
        errorMessage={formErrors?.renewal_stage}
        value={formValues?.renewal_stage_id}
        valueChanged={(value: string) => handleFormValueChange('renewal_stage_id', value)}
        choices={renewalStageChoices}
        defaultChoice={{ value: '', label: 'None' }}
      />
      <SelectInput
        styleOverrides={classes.textInput}
        type="text"
        label="Service Type"
        value={formValues?.referral_service_id}
        valueChanged={(value: string) => handleServiceChange(value)}
        choices={attachedServiceChoices}
        defaultChoice={{ value: '', label: 'None' }}
      />
      <SelectInput
        styleOverrides={classes.textInput}
        type="text"
        label="Status"
        errorMessage={formErrors?.active}
        value={formValues?.active}
        valueChanged={(value: string) => handleFormValueChange('active', value)}
        choices={[
          { label: 'Active', value: 'true' },
          { label: 'Inactive', value: 'false' },
        ]}
      />
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="h3" component="h3">
            Owners
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <FormGroup row>
            <Grid container>
              {allOwnerChoices.map((owner: SelectOption) => (
                <Grid key={owner.label} item md={6} xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name={owner.label}
                        value={owner.value}
                        color="primary"
                        onChange={() => handleFormValueChange('user_ids', owner.value)}
                      />
                    }
                    label={owner.label}
                    checked={formValues?.user_ids?.includes(owner?.value)}
                  />
                </Grid>
              ))}
            </Grid>
          </FormGroup>
        </Grid>
      </Grid>
      <br />
    </form>
  );
};

export default RenewalForm;
