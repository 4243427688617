import React from 'react';
import { Button, Card, CardContent, Grid, Typography } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { useHistory } from 'react-router-dom';
import InformationField from '../../shared/components/InformationField';
import RenewalStageChangeModal from '../../renewals/components/RenewalStageChangeModal';
import { UserContext } from '../../auth/contexts/userContext';
import { dashboardStore, renewalFormStore } from '../../shared/singletons';

interface TimelineRenewalTooltipProps {
  allRenewals?: any;
  setAllRenewals?: any;
}

const TimelineRenewalTooltip: React.FC<TimelineRenewalTooltipProps> = (props) => {
  const { allRenewals, setAllRenewals } = props;
  const [renewal, setSelectedRenewal] = React.useState<any>();
  const [renewalIndex, setRenewalIndex] = React.useState(0);
  const { currentUserHasRole } = React.useContext(UserContext);
  const history = useHistory();

  React.useEffect(() => {
    setSelectedRenewal(allRenewals[renewalIndex]);
  }, [renewalIndex, allRenewals]);

  const incrementRenewal = () => {
    setRenewalIndex(renewalIndex + 1);
  };

  const decrementRenewal = () => {
    setRenewalIndex(renewalIndex - 1);
  };

  return renewal?.id === undefined ? (
    <Card />
  ) : (
    <Card>
      <RenewalStageChangeModal onSuccess={() => dashboardStore.fetchRecord()} />
      <CardContent style={{ minWidth: 250 }}>
        <>
          {allRenewals.length > 1 && (
            <Grid container justify="space-between" alignItems="center">
              <Grid item>
                <ArrowBackIosIcon onClick={decrementRenewal} />
              </Grid>
              <Grid item>
                <p>{`${renewalIndex + 1} of ${allRenewals.length}`}</p>
              </Grid>
              <Grid item>
                <ArrowForwardIosIcon onClick={incrementRenewal} />
              </Grid>
            </Grid>
          )}
          <Grid container justify="space-between">
            <Grid item>
              <Typography variant="h2" component="h2">
                Renewal Information
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                align="right"
                style={{ cursor: 'pointer', paddingLeft: 20 }}
                onClick={() => {
                  setSelectedRenewal({});
                  setAllRenewals({});
                }}
              >
                X
              </Typography>
            </Grid>
          </Grid>

          <InformationField label="Student Name" value={renewal?.student_name} />
          <br />
          <InformationField label="Type" value={renewal?.type} />
          <br />
          <InformationField label="Timeline" value={renewal?.days_til_due} />
          <br />
          {(currentUserHasRole('service_provider') || currentUserHasRole('admin')) && (
            <>
              <div>
                <Typography variant="subtitle1" component="h6">
                  Stage
                </Typography>
                <Typography
                  variant="body1"
                  component="p"
                  style={{
                    whiteSpace: 'break-spaces',
                    textTransform: 'uppercase',
                    fontWeight: 700,
                    color: renewal.color,
                  }}
                >
                  {renewal.stage}
                </Typography>
              </div>
              <br />

              <Button
                variant="contained"
                color="primary"
                style={{ width: '100%' }}
                onClick={() => renewalFormStore.setEditStageModalOpen(true, renewal)}
              >
                Change Stage
              </Button>
              <Button
                variant="contained"
                color="primary"
                style={{ width: '100%', marginTop: 15 }}
                onClick={() => history.push(`/students/${renewal?.student_id}/renewals/${renewal?.id}`)}
              >
                View Renewal
              </Button>
            </>
          )}
        </>
      </CardContent>
    </Card>
  );
};

export default TimelineRenewalTooltip;
