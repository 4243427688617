import React, { FunctionComponent, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutlineRounded';
import { Box, MenuItem, Typography } from '@material-ui/core';
import CustomMaterialMenu from '../../../shared/components/CustomMaterialMenu';
import ConfirmCancelModal from '../../../shared/components/modal/ConfirmCancelModal';
import { Colors } from '../../../shared/style/colors';
import { ListingService } from '../model';
import { axios } from '../../../shared/singletons';

interface ListingServiceRowMenuProps {
  listingService: ListingService;
}

const useStyles = makeStyles((theme) => ({
  errorIcon: {
    color: Colors.Red,
    fontSize: '66px',
    marginBottom: '20px',
  },
  modalText: {
    fontSize: '20px',
    marginLeft: '20%',
    marginBottom: '20px',
    width: '60%',

    [theme.breakpoints.down('sm')]: {
      marginLeft: '2%',
      width: '96%',
    },
  },
}));

const ListingServiceRowMenu: FunctionComponent<ListingServiceRowMenuProps> = (props: ListingServiceRowMenuProps) => {
  const history = useHistory();
  const { listingService } = props;
  const [isDestroyModalOpen, setDestroyModalOpen] = useState<boolean>(false);
  const classes = useStyles();

  const handleDestroyFormSubmit = () => {
    axios.delete(`/service_subcategories/${listingService.id}.json`).then(() => history.push('/listing_services'));
  };

  return (
    <CustomMaterialMenu size="small">
      <ConfirmCancelModal
        isOpen={isDestroyModalOpen}
        openStatusChanged={setDestroyModalOpen}
        cancelLabel="Cancel"
        confirmLabel="Delete"
        width="768px"
        height="350px"
        onConfirm={handleDestroyFormSubmit}
        onCancel={() => setDestroyModalOpen(false)}
        hasTitleBar={false}
        hasFooterBar={false}
        hasCancelButton
        hasConfirmButton
      >
        <Box>
          <ErrorOutlineIcon className={classes.errorIcon} />
          <Typography align="center" className={classes.modalText} variant="h5">
            Are you sure you want to delete this service subcategory? This action cannot be undone.
          </Typography>
        </Box>
      </ConfirmCancelModal>
      <MenuItem>Edit Service Subcategory</MenuItem>
      <MenuItem onClick={() => setDestroyModalOpen(true)}>Destroy Service Subcategory</MenuItem>
    </CustomMaterialMenu>
  );
};

export default ListingServiceRowMenu;
