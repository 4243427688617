/* eslint-disable react-hooks/exhaustive-deps */
import React, { FunctionComponent, useState, useContext, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Checkbox from '@material-ui/core/Checkbox';
import { format } from 'date-fns';
import InformationField from '../../shared/components/InformationField';
import TextInput from '../../shared/components/form/TextInput';
import SelectInput from '../../shared/components/form/SelectInput';
import { appointmentFormStore, appointmentBaseStore } from '../../shared/singletons';
import { useStoreObservable } from '../../shared/state/useStoreObservable.hook';
import { UserContext } from '../../auth/contexts/userContext';
import { AppointmentsContext } from '../contexts/AppointmentsContext';
import { stringToDayOfWeek } from '../../shared/utils/date.utils';
import { SelectOption } from '../../shared/common.model';
import AppointmentErrorsList from './AppointmentErrorsList';

const useStyles = makeStyles({
  textInput: {
    height: 'auto',
    width: '100%',
    margin: '14px 0',
  },
  startTimeInput: {
    height: 'auto',
    width: '98%',
    margin: '14px 0',
  },
  baseError: {
    marginBottom: '10px',
  },
});
//
interface RescheduleAllFutureAppointmentsFormProps {
  onSuccess?: () => void;
  dayOfWeekOptions?: SelectOption[];
  siblingCounter?: number;
}

const RescheduleAllFutureAppointmentsForm: FunctionComponent<RescheduleAllFutureAppointmentsFormProps> = (props) => {
  const { onSuccess, dayOfWeekOptions, siblingCounter = 1 } = props;

  const { formErrors } = useStoreObservable(appointmentFormStore);
  const { locationChoices, isLoadingChoices } = useStoreObservable(appointmentBaseStore);

  const classes = useStyles();

  let [allowUnitChange, setAllowUnitChange] = useState(false);

  const { user, currentUserHasRole } = useContext(UserContext);
  const {
    appointmentFormValues,
    setAppointmentFormValues,
    selectedUnits,
    appointmentDuration,
    setAppointmentDuration,
    appointmentErrorWarnings,
    updateButtonDisabled,
    appointment,
    setRequireComment,
    appointmentComment,
    setAppointmentComment,
  } = useContext(AppointmentsContext);

  let mismatchedUnits = appointmentDuration !== selectedUnits;

  let showAllowCheckbox = mismatchedUnits && (currentUserHasRole('admin') || appointment?.editable_units);

  let blockUnitChange = !allowUnitChange && mismatchedUnits;

  // blockUnitChange comes from this form, and uses logic to determine whether changing the units is allowed
  // updateButtonDisabled comes from the context, and considers several possible errors,
  // such as moving appointments to a weekend
  let previewChangeButtonDisabled = blockUnitChange || updateButtonDisabled;

  const [startDateLabel, setStartDateLabel] = React.useState('');

  useEffect(() => {
    setAppointmentFormValues({
      start_time: appointment?.start_time,
      end_time: appointment?.end_time,
      wday: appointment?.wday,
      schedule_date: appointment?.schedule_date,
      location_id: appointment?.location_id,
      user_id: appointment?.user_id,
    });

    // TODO: Move this calculation of appointmentDuration to the Context
    // But first we need to move the appointment record there for all components that refer to appointmentDuration
    setAppointmentDuration(
      (Number(new Date(`${appointment?.schedule_date}T${appointment?.end_time}`)) -
        Number(new Date(`${appointment?.schedule_date}T${appointment?.start_time}`))) /
        (15 * 60000),
    );

    appointmentBaseStore.fetchRescheduleChoices(appointment?.student_id);

    setAppointmentComment('');
    setRequireComment(true);
  }, [appointment]);

  React.useEffect(() => {
    appointmentErrorWarnings(appointment, currentUserHasRole('admin') ? 'admin' : 'therapist');
  }, [appointmentFormValues]);

  const handleFormValueChange = (key: string, value: string) => {
    // If any value changes besides owner, then status should be set to incomplete unless appointment is complete
    if (key === 'start_time') {
      const startTimeDifference =
        Number(new Date(`${appointment?.schedule_date} ${value}`)) -
        Number(new Date(`${appointment?.schedule_date} ${appointmentFormValues?.start_time}`));
      const newEndTime =
        Number(new Date(`${appointment?.schedule_date} ${appointmentFormValues?.end_time}`)) + startTimeDifference;

      setAppointmentFormValues({
        ...appointmentFormValues,
        start_time: value,
        end_time: format(new Date(newEndTime), 'HH:mm'),
      });
    } else {
      setAppointmentFormValues({ ...appointmentFormValues, [key]: value });
    }
  };

  const handleCommentChange = (value: string) => {
    setAppointmentComment(value);
    setAppointmentFormValues({
      ...appointmentFormValues,
      comments_attributes: { '0': { content: value, user_id: user.id, category: 'reschedule' } },
    });
  };

  return isLoadingChoices ? (
    <Typography>Loading...</Typography>
  ) : (
    <Box p={2}>
      <Typography variant="h2">{`Bulk Appointment Scheduler (Scheduling ${siblingCounter} Appointments)`}</Typography>
      <Grid container spacing={6}>
        <Grid item>
          <InformationField label="Student" value={appointment?.student_name} />
        </Grid>
        <Grid item>
          <InformationField label="Timeline ID" value={appointment?.student_slug} />
        </Grid>
        <Grid item>
          <InformationField label="Service Type" value={appointment?.service_type} />
        </Grid>
      </Grid>
      <br />
      <Grid container>
        <Grid item xs={10}>
          <TextInput
            value={appointmentFormValues?.schedule_date}
            styleOverrides={classes.textInput}
            type="date"
            shrinkLabel
            label={`Start Date ${startDateLabel}`}
            errorMessage={formErrors?.schedule_date}
            valueChanged={(value: string) => {
              handleFormValueChange('schedule_date', value);
              setStartDateLabel(`(${stringToDayOfWeek(`${value} 12:00`)})`);
            }}
          />
        </Grid>
        {dayOfWeekOptions.length > 1 && (
          <Grid item xs={10}>
            <TextInput
              value={dayOfWeekOptions.filter((option) => option.value === appointmentFormValues?.wday)[0]?.label}
              styleOverrides={classes.textInput}
              type="text"
              label="Rescheduling Appointments On..."
              valueChanged={(value: string) => null}
              disabled
            />
          </Grid>
        )}
        <Grid item xs={5}>
          <TextInput
            value={appointmentFormValues?.start_time}
            styleOverrides={classes.startTimeInput}
            type="time"
            shrinkLabel
            label="Start Time"
            errorMessage={formErrors?.start_hour}
            valueChanged={(value: string) => handleFormValueChange('start_time', value)}
          />
        </Grid>
        <Grid item xs={5}>
          <TextInput
            value={appointmentFormValues?.end_time}
            styleOverrides={classes.textInput}
            type="time"
            shrinkLabel
            label="End Time"
            errorMessage={formErrors?.end_hour}
            valueChanged={(value: string) => handleFormValueChange('end_time', value)}
          />
        </Grid>
        <Grid container>
          <Grid item xs={4}>
            <p>
              Selected units: &nbsp;
              <span style={mismatchedUnits ? { color: 'red', fontWeight: 'bold' } : {}}>{selectedUnits}</span>
            </p>
          </Grid>
          <Grid item xs={4}>
            <p>
              Required units: &nbsp;
              {appointmentDuration}
            </p>
          </Grid>
        </Grid>
        {showAllowCheckbox && (
          <>
            <Grid item xs={12}>
              <Checkbox checked={allowUnitChange} onChange={(e) => setAllowUnitChange(e.target.checked)} />
              <Typography variant="h4" style={{ display: 'inline' }}>
                Allow unit change for all future appointments
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <p style={{ color: 'red' }}>
                This would also change the last appointment, even if it's shorter than the rest of the series.
              </p>
            </Grid>
          </>
        )}
        <Grid item xs={10}>
          <SelectInput
            value={appointmentFormValues?.location_id}
            styleOverrides={classes.textInput}
            type="text"
            label="Location"
            errorMessage={formErrors?.location_id}
            valueChanged={(value: string) => handleFormValueChange('location_id', value)}
            choices={locationChoices}
            defaultChoice={{ value: '', label: 'None' }}
          />
        </Grid>
        <Grid item xs={10}>
          <TextInput
            value={appointmentComment}
            styleOverrides={classes.textInput}
            multiline
            rows={8}
            label="Comment"
            errorMessage={formErrors?.comment}
            valueChanged={(value: string) => handleCommentChange(value)}
          />
        </Grid>
        <br />
      </Grid>
      <AppointmentErrorsList />
      <br />
      <Button variant="contained" color="primary" onClick={onSuccess} disabled={previewChangeButtonDisabled}>
        Preview Changes
      </Button>
      <br />
    </Box>
  );
};

export default RescheduleAllFutureAppointmentsForm;
