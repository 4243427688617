export const questionsByCategory = {
  indirect_services: {
    label: 'Indirect Services',
    questions: {
      q1: {
        label: 'Method',
        type: 'drop_down',
        options: [
          { label: 'Phone', value: 'phone' },
          { label: 'Email', value: 'email' },
          { label: 'In-person Meeting', value: 'in-person' },
          { label: 'Virtual Meeting', value: 'virtual-meeting' },
          { label: 'Compliance Activity', value: 'compliance' },
        ],
      },
      q2: { label: 'Who', type: 'short_answer' },
      q3: { label: 'Outcome', type: 'short_answer' },
    },
  },
  meetings: {
    label: 'Meetings',
    questions: {
      q1: {
        label: 'Method',
        type: 'drop_down',
        options: [
          { label: 'Phone', value: 'phone' },
          { label: 'In-person Meeting', value: 'in-person' },
          { label: 'Virtual Meeting', value: 'virtual-meeting' },
        ],
      },
      q2: { label: 'Attendees', type: 'short_answer' },
      q3: { label: 'Outcome', type: 'short_answer' },
    },
  },
  daily_services: {
    label: 'Daily Services',
    questions: {
      q1: { label: 'Students Serviced/Supported', type: 'short_answer' },
      q2: { label: 'Staff Supported', type: 'short_answer' },
      q3: { label: 'Meetings Attended', type: 'short_answer' },
      q4: { label: 'Documentation Completed', type: 'short_answer' },
    },
  },
  paraprofessional: {
    label: 'Paraprofessional',
    questions: {
      q1: { label: 'Academic Support Provided', type: 'short_answer' },
      q2: { label: 'Behavior Support Provided', type: 'short_answer' },
      q3: {
        label: 'Participation/Attention',
        type: 'drop_down',
        options: [
          { label: 'Complete Independence (0%)', value: 'complete' },
          { label: 'Modified Independence (0%)', value: 'modified' },
          { label: 'Minimal Assistance (50%)', value: 'minimal' },
          { label: 'Moderate Assistance (75%)', value: 'moderate' },
          { label: 'Maximum Assistance (100%)', value: 'maximum' },
        ],
      },
    },
  },
  assessments: {
    label: 'Assessments',
    questions: {
      q1: { label: 'Assessments Completed', type: 'short_answer' },
      q2: { label: 'Comments', type: 'short_answer' },
    },
  },
  screenings: {
    label: 'Screenings',
    questions: {
      q1: {
        label: 'Screening Completed',
        type: 'checkbox',
        required: true,
      },
      q2: { label: 'Comments', type: 'short_answer' },
    },
  },
  documentation: {
    label: 'Documentation',
    questions: {
      q1: {
        label: 'Documentation Completed',
        type: 'checkbox',
        required: true,
      },
      q2: { label: 'Comments', type: 'short_answer' },
    },
  },
  integrated_services: {
    label: 'Integrated Services',
    questions: {
      q1: { label: 'Activities Completed', type: 'short_answer' },
      q2: { label: 'Comments', type: 'short_answer' },
    },
  },
  transportation: {
    label: 'Transportation',
    questions: {
      q1: {
        label: 'Route Completed',
        type: 'checkbox',
        required: true,
      },
    },
  },
  misc: {
    label: 'All Other Services',
    questions: {
      q1: { label: 'Describe Service Provided', type: 'short_answer' },
    },
  },
};
