/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useContext } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Accordion, AccordionSummary, AccordionDetails, Box, Button, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import InformationField from '../../shared/components/InformationField';
import TextInput from '../../shared/components/form/TextInput';
import SelectInput from '../../shared/components/form/SelectInput';
import { Appointment } from '../../appointments/model';
import { UserContext } from '../../auth/contexts/userContext';
import { AppointmentsContext } from '../../appointments/contexts/AppointmentsContext';
import Breadcrumbs, { BreadcrumbLink } from '../../shared/components/Breadcrumbs';
import AppointmentErrorsList from '../../appointments/components/AppointmentErrorsList';
import { formatDate } from '../../shared/utils/date.utils';

interface AppointmentValuesProps {
  appointment: Appointment;
}

const useStyles = makeStyles({
  textInput: {
    height: 'auto',
    width: '80%',
    margin: '10px 0',
  },
  dateTimeInput: {
    height: 'auto',
    width: '100%',
    margin: '10px 0',
  },
  link: {
    textDecoration: 'none',
  },
});

const AppointmentValues: React.FC<AppointmentValuesProps> = (props) => {
  const { appointment } = props;

  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  const currentUrl = `${location.pathname}${location.search}`;

  const { currentUserHasRole } = useContext(UserContext);
  const {
    appointmentErrorWarnings,
    appointmentChoices,
    appointmentFormValues,
    setAppointmentFormValues,
    selectedUnits,
    appointmentDuration,
    setAppointmentDuration,
    setAllowFutureRescheduling,
  } = useContext(AppointmentsContext);

  useEffect(() => {
    setAllowFutureRescheduling(false);

    setAppointmentDuration(
      (Number(new Date(`${appointment.schedule_date}T${appointment.end_time}`)) -
        Number(new Date(`${appointment.schedule_date}T${appointment.start_time}`))) /
        (15 * 60000),
    );

    setAppointmentFormValues({
      location_id: appointment.location_id,
      start_time: appointment.start_time,
      end_time: appointment.end_time,
      schedule_date: appointment?.schedule_date,
    });
  }, [appointment]);

  const links: BreadcrumbLink[] = [
    {
      href: `/students/${appointment?.student_id}`,
      text: `${appointment?.student?.first_name} ${appointment?.student?.last_name}`,
    },
    {
      href: `/students/${appointment?.student_id}/services/${appointment?.service_id}`,
      text: `${appointment?.service?.service_type}`,
    },
    {
      href: null,
      text: `${appointment?.service_type} ${appointment?.formatted_schedule_date} ${appointment?.start_time}`,
    },
  ];

  useEffect(() => {
    appointmentErrorWarnings(appointment, currentUserHasRole('admin') ? 'admin' : 'therapist');
  }, [appointmentFormValues, appointment]);

  const disableRescheduleButton = appointment?.status === 'ticket';

  return (
    <>
      <Grid container justify="space-between" spacing={3}>
        <Grid item>
          <Link to="/appointments" className={classes.link}>
            <Grid alignItems="center" container spacing={1}>
              <Grid item>
                <ArrowBackIcon color="primary" style={{ display: 'inherit' }} />
              </Grid>
              <Grid item>
                <Typography color="primary" variant="body1">
                  BACK TO APPOINTMENTS
                </Typography>
              </Grid>
            </Grid>
          </Link>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="default"
            style={{ marginLeft: 7 }}
            onClick={() =>
              history.push(`/students/${appointment.student_id}/appointments/${appointment.id}/bulk-reschedule`)
            }
            disabled={disableRescheduleButton}
          >
            Reschedule Future Appointments
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() =>
              history.push(
                `/students/${appointment.student_id}/appointments/${appointment.id}/edit?return_to=${encodeURIComponent(
                  currentUrl,
                )}`,
              )
            }
            style={{ marginLeft: 7 }}
            disabled={disableRescheduleButton}
          >
            Reschedule Appointment
          </Button>
        </Grid>
      </Grid>
      {appointment?.status === 'ticket' && (
        <Grid container justify="flex-end">
          <p style={{ color: 'red' }}>Rescheduling not allowed while there is a pending ticket.</p>
        </Grid>
      )}

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h1" component="h1">
            Add Session Note
          </Typography>
          <div style={{ marginLeft: -5 }}>
            <Breadcrumbs links={links} />
          </div>
        </Grid>
        <Grid item xs={12}>
          <Box display={{ xs: 'none', sm: 'none', md: 'block' }}>
            <Grid container spacing={5}>
              <Grid item>
                <InformationField label="Appointment" value={appointment?.schedule_date} />
              </Grid>
              <Grid item>
                <InformationField label="Service Type" value={appointment?.service_type} />
              </Grid>
              <Grid item>
                <InformationField label="Student" value={appointment?.student_name} />
              </Grid>
              <Grid item>
                <InformationField label="Timeline ID" value={appointment?.student?.slug} />
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Accordion expanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
              <Typography variant="h2" component="h2">
                Appointment Hours (Required)
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Grid container spacing={1}>
                    <Grid item sm={10} md={6}>
                      <TextInput
                        value={appointmentFormValues?.schedule_date}
                        styleOverrides={classes.dateTimeInput}
                        type="date"
                        shrinkLabel
                        label="Scheduled Date"
                        valueChanged={(value: string) => {
                          setAppointmentFormValues({ ...appointmentFormValues, schedule_date: value });
                        }}
                      />
                    </Grid>
                  </Grid>
                  {/* TODO: setAppointmentFormValues when the appointment is loaded so we don't need || branches in these components */}
                  <Grid container spacing={1}>
                    <Grid item sm={10} md={3}>
                      <TextInput
                        value={appointmentFormValues?.start_time || appointment?.start_time}
                        type="time"
                        shrinkLabel
                        styleOverrides={classes.dateTimeInput}
                        label="Start Time"
                        errorMessage=""
                        valueChanged={(value: string) =>
                          setAppointmentFormValues({ ...appointmentFormValues, start_time: value })
                        }
                      />
                    </Grid>
                    <Grid item sm={10} md={3}>
                      <TextInput
                        value={appointmentFormValues?.end_time || appointment?.end_time}
                        type="time"
                        shrinkLabel
                        styleOverrides={classes.dateTimeInput}
                        label="End Time"
                        errorMessage=""
                        valueChanged={(value: string) => {
                          setAppointmentFormValues({ ...appointmentFormValues, end_time: value });
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={4}>
                      <p>{`Selected units: ${selectedUnits}`}</p>
                    </Grid>
                    {appointment?.editable_units === false && (
                      <Grid xs={4}>
                        <p>{`Required units: ${appointmentDuration}`}</p>
                      </Grid>
                    )}
                  </Grid>
                  <AppointmentErrorsList />
                </Grid>
                <Grid item sm={10} md={6}>
                  <SelectInput
                    value={appointmentFormValues?.location_id}
                    styleOverrides={classes.dateTimeInput}
                    label="Location"
                    errorMessage=""
                    choices={appointmentChoices?.locations}
                    valueChanged={(value: string) => {
                      setAppointmentFormValues({ ...appointmentFormValues, location_id: value });
                    }}
                  />
                </Grid>
                <Grid item xs={5}>
                  <Typography variant="h4" component="h3">
                    <strong>First Day of School Year:</strong> {formatDate(appointment?.first_day_of_school_year)}
                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography variant="h4" component="h3">
                    <strong>Last Day of School Year:</strong> {formatDate(appointment?.last_day_of_school_year)}
                  </Typography>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
    </>
  );
};

export default AppointmentValues;
