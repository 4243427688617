import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import NewStudentServiceForm from '../components/NewStudentServiceForm';
import StudentBuilderProgressStepper from '../components/StudentBuilderProgressStepper';
import { StudentsContext } from '../../students/contexts/StudentsContext';

const AddStudentServicesPage: React.FunctionComponent = () => {
  const history = useHistory();
  const { studentId } = useParams<Record<string, string>>();

  const [studentFetched, setStudentFetched] = React.useState(false);
  const { fetchStudent, student } = React.useContext(StudentsContext);

  React.useEffect(() => {
    if (!studentFetched) {
      fetchStudent(studentId);
      setStudentFetched(true);
    }
  }, [studentFetched, setStudentFetched, fetchStudent, studentId]);

  return (
    <>
      <Typography variant="h1" component="h1">
        Add Student's Services
      </Typography>
      <StudentBuilderProgressStepper activeStep={2} />
      <Grid container flex-direction="column">
        <Grid item xs={10}>
          <NewStudentServiceForm
            student={student}
            onSuccess={() => history.push(`/students/${studentId}/goals_and_renewals`)}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default AddStudentServicesPage;
