import React, { FunctionComponent, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import RenewalDetail from '../components/RenewalDetail';
import { useStoreObservable } from '../../shared/state/useStoreObservable.hook';
import { renewalRecordStore } from '../../shared/singletons';
import { RenewalRouteParams } from '../model';

export type RenewalDetailPageProps = Record<string, unknown>;

const RenewalDetailPage: FunctionComponent<RenewalDetailPageProps> = () => {
  const { renewal, renewalLoading } = useStoreObservable(renewalRecordStore);
  const params = useParams<RenewalRouteParams>();

  useEffect(() => {
    renewalRecordStore.fetchRecord(params.studentId, params.renewalId);
  }, [params]);

  return <RenewalDetail renewal={renewal} renewalLoading={renewalLoading} />;
};

export default RenewalDetailPage;
