import { axios, alertStore } from '../../shared/singletons';
import { BaseStore } from '../../shared/state/base.store';
import { UserRole, UserRoleFormValues } from './model';
import { API_ENDPOINT } from '../../../config/env';

export class UserRoleState {
  records: UserRole[];
  userRole: UserRole;
  isLoading: boolean;

  formValues: UserRoleFormValues;
  editModalOpen: boolean;
  createModalOpen: boolean;

  static create(props: Partial<UserRoleState>): UserRoleState {
    const defaults: UserRoleState = {
      records: [],
      userRole: {},
      isLoading: false,

      formValues: null,
      editModalOpen: false,
      createModalOpen: false,
    };
    return Object.assign(new UserRoleState(), defaults, props || {});
  }
}

export class UserRoleStore extends BaseStore<UserRoleState> {
  constructor() {
    super(UserRoleState.create({}));
  }

  public fetchRecords(): void {
    this.setState({ isLoading: true });

    axios(`${API_ENDPOINT}/roles.json`)
      .then((result) => result?.data?.result ?? [])
      .then((userRoles) => {
        this.setState({ records: userRoles, isLoading: false });
      })
      .catch(() => {
        this.setState({ isLoading: false });
      });
  }

  public createUserRole(formValues: UserRoleFormValues): void {
    axios({
      method: 'post',
      url: `${API_ENDPOINT}/roles.json`,
      data: { role: formValues },
      headers: { 'Content-Type': 'application/json' },
    })
      .then(() => {
        alertStore.alertSuccess('Successfully created user role.');
        this.fetchRecords();
        this.setState({ createModalOpen: false });
      })
      .catch(() => {
        this.setState({ createModalOpen: false });
      });
  }

  public updateUserRole(editFormValues: UserRoleFormValues, userRole: UserRole): void {
    axios({
      method: 'put',
      url: `${API_ENDPOINT}/roles/${userRole.id}.json`,
      data: { role: editFormValues },
      headers: { 'Content-Type': 'application/json' },
    })
      .then(() => {
        alertStore.alertSuccess('Successfully updated user role.');
        this.fetchRecords();
        this.setState({ editModalOpen: false });
      })
      .catch(() => {
        this.setState({ editModalOpen: false });
      });
  }

  public deleteUserRole(userRole: UserRole, onDelete: () => void): void {
    axios({
      method: 'delete',
      url: `${API_ENDPOINT}/roles/${userRole.id}.json`,
      headers: { 'Content-Type': 'application/json' },
    })
      .then(() => {
        alertStore.alertSuccess('Successfully deleted user role.');
        this.fetchRecords();
        onDelete();
      })
      .catch(() => {
        onDelete();
      });
  }

  public setEditModalOpen(isOpen: boolean, userRole: UserRole): void {
    const formValues = {
      name: userRole.name,
    };

    this.setState({ editModalOpen: isOpen, formValues, userRole });
  }

  public setFormValue(formValues: UserRoleFormValues): void {
    this.setState({ formValues });
  }
}
