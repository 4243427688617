import { _compactJoin, _size } from './collection.utils';
import { _isString } from './validation.utils';

export const _trim = (value: string): string => (_isString(value) ? value.trim() : value);
export const _trimEach = (values: string[]): string[] => (values || []).map((str) => _trim(str));
export const _words = (str: string): string[] => (!str ? [] : _trimEach(_trim(str).split(' ')));
export const _isMultiWord = (str: string): boolean => _size(_words(str)) > 1;

export const _titleCase = (str: string): string => {
  if (!str) {
    return str;
  }
  let split = str.toLowerCase().split(' ');
  split.forEach((item, i) => (split[i] = split[i].charAt(0).toUpperCase() + split[i].slice(1)));
  return split.join(' ');
};

export const _wrapInParenthesis = (value: number): string => (value || value === 0 ? `(${value})` : null);

export const _extractChars = (indexes: number[], item: string): string => {
  return item && indexes
    ? _compactJoin(
        indexes.map((index) => item[index]),
        '',
      )
    : null;
};

export const _removeAllWhiteSpace = (str: string): string => str.replace(/\s/g, '');

export const _toUpper = (text: string): string => (text || text === '' ? text.toUpperCase() : null);
export const _toLower = (text: string): string => (text || text === '' ? text.toLowerCase() : null);
export const _allToLower = (items: string[]): string[] => (items || []).map((item) => _toLower(item));
export const _contains = (textToFind: string, item: string): boolean =>
  _isString(textToFind) && _isString(item) && item.indexOf(textToFind) > -1;

export const _cloneString = (item: string): string => (' ' + item).slice(1);

export const _lastChar = (str: string): string => (_isString(str) ? str.slice(-1) : null);
export const _lastCharIs = (char: string, str: string): boolean => _lastChar(str) === char;
export const _hasTrailingSlash = (str: string): boolean => _lastCharIs('/', str);
export const _addTrailingSlashIfMissing = (str: string): string =>
  _hasTrailingSlash(str) || _contains('?', str) ? str : `${str}/`;

export const _replaceAll = (original: string, textToReplace: string, replaceWith: string): string => {
  return (original || '').split(textToReplace).join(replaceWith);
};
