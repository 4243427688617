import React, { FunctionComponent } from 'react';
import { Typography, Box, Grid, Button, Card, CardContent } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import DataTable from '../../shared/components/DataTable';
import { IDataTableColumn } from '../../shared/components/DataTable/DataTable/model';
import { School } from '../model';
import { schoolRecordStore } from '../../shared/singletons';
import FilterBar, { FilterProps } from '../../shared/components/FilterBar';
import { UserContext } from '../../auth/contexts/userContext';
import { Colors } from '../../shared/style/colors';

interface SchoolsList {
  records: School[];
  recordsLoading: boolean;
  showFilterBar: boolean;
  totalRecords?: number;
}

const SchoolsList: FunctionComponent<SchoolsList> = (props) => {
  const { records, recordsLoading, showFilterBar = false, totalRecords = records?.length || 0 } = props;
  const history = useHistory();
  const { currentUserHasRole } = React.useContext(UserContext);
  const [recordsPerPage, setRecordsPerPage] = React.useState(10);

  const statusColors = {
    false: Colors.Success,
    true: Colors.Red,
  };

  const columns: IDataTableColumn<School>[] = [
    {
      name: 'SCHOOL NAME',
      selector: 'name',
      sortable: true,
    },
    {
      name: 'ABBREVIATION',
      selector: 'abbreviation',
      sortable: true,
    },
    {
      name: 'TOTAL STUDENTS',
      selector: 'total_students',
      sortable: false,
    },
    {
      name: 'TOTAL SERVICES',
      selector: 'total_services',
      sortable: false,
    },
    {
      name: 'STATUS',
      selector: 'status',
      sortable: false,
      cell: (row: School) => (
        <div
          style={{
            fontWeight: 700,
            textTransform: 'uppercase',
            color: statusColors[row.status],
          }}
        >
          {row.status ? 'Discharged' : 'Active'}
        </div>
      ),
    },
  ];

  const filters: FilterProps[] = [
    {
      selector: 'ransack[name_i_cont]',
      label: 'School Name',
      type: 'text',
    },
    {
      selector: 'ransack[abbr_i_cont]',
      label: 'School Abbreviation',
      type: 'text',
    },
    {
      selector: 'ransack[discharged_eq]',
      label: 'STATUS',
      type: 'select',
      options: [
        { label: 'Active', value: 'false' },
        { label: 'Discharged', value: 'true' },
      ],
    },
  ];

  const handlePageChange = (page: number) => {
    const filterParams = schoolRecordStore.formattedFilterParams();
    schoolRecordStore.fetchRecords(filterParams, page, recordsPerPage);
  };

  const handlePerRowsChange = async (newPerPage: number, page: number) => {
    setRecordsPerPage(newPerPage);
    const filterParams = schoolRecordStore.formattedFilterParams();
    schoolRecordStore.fetchRecords(filterParams, page, newPerPage);
  };

  const handleColumnSort = async (column: Record<string, string>, sortDirection: string) => {
    const columnNamesToRansack = {
      name: 'name',
      abbreviation: 'abbr',
      locations: 'locations',
      total_students: 'patients',
      total_services: 'referral_services',
    };

    schoolRecordStore.setFilterValues({
      ...schoolRecordStore.getState().filterValues,
      sort: `${columnNamesToRansack[column.selector]} ${sortDirection}`,
    });

    const filterParams = schoolRecordStore.formattedFilterParams();
    schoolRecordStore.fetchRecords(filterParams, 1, recordsPerPage);
  };

  return (
    <Box>
      <Grid justify="space-between" container spacing={3}>
        <Grid item>
          <Typography variant="h1" component="h1">
            Schools
          </Typography>
        </Grid>
        <Grid item>
          {currentUserHasRole('admin') && (
            <Button variant="contained" color="primary" onClick={() => history.push(`/schools/new`)}>
              Create New School +
            </Button>
          )}
        </Grid>
        {showFilterBar && (
          <Grid item xs={12}>
            <FilterBar
              filters={filters}
              baseStore={schoolRecordStore}
              onFilter={() => schoolRecordStore.fetchRecords()}
            />
          </Grid>
        )}
      </Grid>
      <br />
      <Card>
        <CardContent>
          <DataTable
            title=""
            columns={columns}
            noHeader
            data={records}
            progressPending={recordsLoading}
            striped
            onRowClicked={(school: School) => history.push(`/schools/${school.id}`)}
            highlightOnHover
            pointerOnHover
            pagination
            paginationServer
            paginationTotalRows={totalRecords}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            sortServer
            onSort={handleColumnSort}
          />
        </CardContent>
      </Card>
    </Box>
  );
};

export default SchoolsList;
