import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { School, Location } from '../model';
import { Grid, Typography, Box, Button, Card, IconButton } from '@material-ui/core';
import DataTable from '../../shared/components/DataTable';
import { IDataTableColumn } from '../../shared/components/DataTable/DataTable/model';
import { Edit, Delete } from '@material-ui/icons';
import NewLocationModal from './NewLocationModal';
import EditLocationModal from './EditLocationModal';
import DeleteLocationModal from './DeleteLocationModal';

type SchoolLocationsTabProps = {
  school: School;
};

const SchoolLocationsTab: React.FC<SchoolLocationsTabProps> = (props) => {
  const history = useHistory();
  const { school } = props;

  const [locationToEdit, setLocationToEdit] = useState<School | null>(null);
  const [editLocationModalOpen, setEditLocationModalOpen] = useState(false);
  const [newLocationModalOpen, setNewLocationModalOpen] = useState(false);
  const [deleteLocationModalOpen, setDeleteLocationModalOpen] = useState(false);

  const handleLocationEditClick = (location: Location) => {
    setLocationToEdit(location);
    setEditLocationModalOpen(true);
  };

  const handleLocationDeleteClick = (location: Location) => {
    setLocationToEdit(location);
    setDeleteLocationModalOpen(true);
  };

  const columns: IDataTableColumn<School>[] = [
    {
      name: 'LOCATION NAME',
      selector: 'name',
      sortable: true,
    },
    {
      name: 'ADDRESS',
      selector: 'address',
      sortable: true,
      grow: 2,
    },
    {
      name: 'STUDENT COUNT',
      selector: 'student_count',
      sortable: true,
    },
    {
      name: 'CREATED AT',
      selector: 'created_at',
      sortable: true,
    },
    {
      name: 'ACTIONS',
      selector: 'edit',
      grow: 0.6,
      cell: (row) => (
        <IconButton
          onClick={() => {
            handleLocationEditClick(row);
          }}
        >
          <Edit />
        </IconButton>
      ),
    },
    {
      name: 'ACTIONS',
      selector: 'delete',
      grow: 0.6,
      cell: (row) => (
        <IconButton
          onClick={() => {
            handleLocationDeleteClick(row);
          }}
        >
          <Delete />
        </IconButton>
      ),
    },
  ];

  return (
    <Grid container>
      <EditLocationModal
        school={school}
        locationToEdit={locationToEdit}
        editLocationModalOpen={editLocationModalOpen}
        setEditLocationModalOpen={setEditLocationModalOpen}
      />
      <NewLocationModal
        school={school}
        newLocationModalOpen={newLocationModalOpen}
        setNewLocationModalOpen={setNewLocationModalOpen}
      />
      <DeleteLocationModal
        deleteLocationModalOpen={deleteLocationModalOpen}
        setDeleteLocationModalOpen={setDeleteLocationModalOpen}
        locationToEdit={locationToEdit}
      />
      <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="h2">Locations</Typography>
        <Box my={2}>
          <Button variant="contained" color="primary" onClick={() => setNewLocationModalOpen(true)}>
            Add Location +
          </Button>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Card style={{ padding: 15 }}>
          <DataTable
            title=""
            columns={columns}
            noHeader
            data={school.locations}
            striped
            highlightOnHover
            pointerOnHover
            pagination
            onRowClicked={(row) => history.push(`/locations/${row.id}`)}
          />
        </Card>
      </Grid>
    </Grid>
  );
};

export default SchoolLocationsTab;
