import React, { FunctionComponent } from 'react';
import StepperModal from '../../shared/components/modal/StepperModal';
import ServiceForm from './ServiceForm';
import ServiceCategoryForm from './ServiceCategoryForm';
import { serviceFormStore, serviceRecordStore } from '../../shared/singletons';
import { useStoreObservable } from '../../shared/state/useStoreObservable.hook';
import { _translateEach } from '../../shared/utils/translation.utils';

const ServiceDetailsFormModal: FunctionComponent = () => {
  const { formStepperIndex, detailsModalOpen, formValues, detailsModalService: service } = useStoreObservable(
    serviceFormStore,
  );

  const t = _translateEach({
    createTitle: 'services.formModal.createTitle',
    updateTitle: 'services.formModal.updateTitle',
    cancel: 'services.formModal.cancel',
    confirmLabelStep1: 'services.formModal.confirmLabelStep1',
    confirmLabelStep2: 'services.formModal.confirmLabelStep2',
    confirmLabelStep3: 'services.formModal.confirmLabelStep3',
    confirmLabelStep4: 'services.formModal.confirmLabelStep4',
    updateLabelStep1: 'services.formModal.updateLabelStep1',
    updateLabelStep2: 'services.formModal.updateLabelStep2',
    updateLabelStep3: 'services.formModal.updateLabelStep3',
  });

  const handleNextClick = (currentIndex: number): void => {
    if (currentIndex === 0) {
      if (service.student_id) {
        serviceFormStore.setState({ formErrorAlertVisible: false, formStepperIndex: 1 });
        // serviceStore.setState({f});
      } else {
        serviceFormStore.setState({ formErrors: { base: 'Please select a student before continuing.' } });
        serviceFormStore.setState({ formErrorAlertVisible: true, formStepperIndex: 0 });
        // serviceStore.setState({});
      }
    } else if (currentIndex === 1) {
      serviceFormStore.createOrUpdateService(formValues, service.student_id, service.id, () => {
        serviceRecordStore.fetchRecord(service.student_id, service.id);
        serviceFormStore.setState({ detailsModalOpen: false });
      });
    }
  };

  const stepperModalContent = () => {
    switch (formStepperIndex) {
      case 0:
        return <ServiceCategoryForm service={service} />;
      case 1:
        return <ServiceForm />;
      default:
        return <></>;
    }
  };

  return (
    <StepperModal
      currentStepIndex={formStepperIndex}
      totalSteps={2}
      isOpen={detailsModalOpen}
      title={service.id ? t.updateTitle : t.createTitle}
      openStatusChanged={(isOpen) => serviceFormStore.setState({ detailsModalOpen: isOpen })}
      buttonLabelsByIndex={[
        { cancel: 'Cancel', confirm: service.id ? t.updateLabelStep1 : t.confirmLabelStep1 },
        { cancel: 'Cancel', confirm: service.id ? t.updateLabelStep2 : t.confirmLabelStep2 },
        { cancel: 'Cancel', confirm: t.confirmLabelStep4 }, // TODO translation
      ]}
      previousClicked={() => serviceFormStore.setState({ formStepperIndex: formStepperIndex - 1 })}
      nextClicked={() => handleNextClick(formStepperIndex)}
    >
      {stepperModalContent()}
    </StepperModal>
  );
};

export default ServiceDetailsFormModal;
