import React from 'react';
import { Box, Checkbox, FormControlLabel, Grid, Typography, makeStyles } from '@material-ui/core';
import ConfirmCancelModal from '../../shared/components/modal/ConfirmCancelModal';
import TextInput from '../../shared/components/form/TextInput';
import { listingFeeStore } from '../../shared/singletons';
import { useStoreObservable } from '../../shared/state/useStoreObservable.hook';

const useStyles = makeStyles({
  textInput: {
    height: 'auto',
    width: '98%',
    margin: '14px 0',
  },
  baseError: {
    marginBottom: '10px',
  },
  formTitle: {
    marginLeft: '12px',
  },
});

const EditListingFeeModal: React.FC = () => {
  const { editModalOpen, formValues, formErrors } = useStoreObservable(listingFeeStore);
  const {
    listingFee,
    initialServiceFeeAttributes,
    initialPrepAttributes,
    initialFlatRateAttributes,
  } = useStoreObservable(listingFeeStore);
  const classes = useStyles();

  const [serviceFeeAttributes, setServiceFeeAttributes] = React.useState({});
  const [prepAttributes, setPrepAttributes] = React.useState({});
  const [flatRateAttributes, setFlatRateAttributes] = React.useState({});

  const handleFormValueChange = (key: string, value: string) => {
    listingFeeStore.setState({ formValues: { ...formValues, [key]: value } });
  };

  const handleServiceFeeChange = (
    fee_type: string,
    amount: string,
    service_id: string,
    fee_index: string,
    fee_id: string,
  ) => {
    setServiceFeeAttributes({
      ...serviceFeeAttributes,
      [fee_index]: {
        id: fee_id,
        amount,
        prep: prepAttributes[fee_index]?.value || '0',
        flat_rate: flatRateAttributes[fee_index]?.value || '0',
        service_id,
        service_fee_type: fee_type,
      },
    });
  };

  // const handleServiceFeeCancelChange = (
  //   fee_type: string,
  //   amount: string,
  //   service_id: string,
  //   fee_index: string,
  //   fee_id: string,
  // ) => {
  //   setServiceFeeAttributes({
  //     ...serviceFeeAttributes,
  //     [fee_index]: {
  //       id: fee_id,
  //       prep: prepAttributes[fee_index]?.value || '0',
  //       flat_rate: flatRateAttributes[fee_index]?.value || '0',
  //       service_id: service_id,
  //       service_fee_type: fee_type,
  //     },
  //   });
  // };

  const handlePrepChange = async (checked: boolean, fee_index: string) => {
    setPrepAttributes({
      ...prepAttributes,
      [fee_index]: {
        value: checked ? '1' : '0',
      },
    });

    setServiceFeeAttributes({
      ...serviceFeeAttributes,
      [fee_index]: {
        id: serviceFeeAttributes[fee_index]?.id,
        amount: serviceFeeAttributes[fee_index]?.amount,
        prep: checked ? '1' : '0',
        flat_rate: flatRateAttributes[fee_index]?.value || '0',
        service_id: serviceFeeAttributes[fee_index]?.service_id,
        service_fee_type: serviceFeeAttributes[fee_index]?.service_fee_type,
      },
    });
  };

  const handleFlatRateChange = async (checked: boolean, fee_index: string) => {
    setFlatRateAttributes({
      ...flatRateAttributes,
      [fee_index]: {
        value: checked ? '1' : '0',
      },
    });

    setServiceFeeAttributes({
      ...serviceFeeAttributes,
      [fee_index]: {
        id: serviceFeeAttributes[fee_index]?.id,
        amount: serviceFeeAttributes[fee_index]?.amount,
        prep: prepAttributes[fee_index]?.value || '0',
        flat_rate: checked ? '1' : '0',
        service_id: serviceFeeAttributes[fee_index]?.service_id,
        service_fee_type: serviceFeeAttributes[fee_index]?.service_fee_type,
      },
    });
  };

  const handleFormSubmit = () => {
    listingFeeStore
      .setFormValues({
        ...formValues,
        service_fees_attributes: serviceFeeAttributes,
      })
      .then(() => {
        listingFeeStore.updateListingFee(listingFeeStore.getState().formValues, listingFee.school_id, listingFee.id);
      });
  };

  React.useEffect(() => {
    setServiceFeeAttributes(initialServiceFeeAttributes);
    setFlatRateAttributes(initialFlatRateAttributes);
    setPrepAttributes(initialPrepAttributes);
  }, [initialServiceFeeAttributes, initialPrepAttributes, initialFlatRateAttributes]);

  return (
    <ConfirmCancelModal
      isOpen={editModalOpen}
      title="Edit Listing Fee"
      onConfirm={() => {
        handleFormSubmit();
      }}
      confirmLabel="Update Listing Fee"
      openStatusChanged={(isOpen) => listingFeeStore.setState({ editModalOpen: isOpen })}
    >
      <Box>
        <Typography variant="h2" component="h2" className={classes.formTitle}>
          Listing Fee Details
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={5}>
            <TextInput
              styleOverrides={classes.textInput}
              value={formValues?.start_date}
              label="Start Date"
              type="date"
              errorMessage={formErrors?.start_date}
              shrinkLabel
              valueChanged={(value: string) => handleFormValueChange('start_date', value)}
            />
          </Grid>
          <Grid item xs={5}>
            <TextInput
              styleOverrides={classes.textInput}
              value={formValues?.end_date}
              label="End Date"
              type="date"
              errorMessage={formErrors?.end_date}
              shrinkLabel
              valueChanged={(value: string) => handleFormValueChange('end_date', value)}
            />
          </Grid>
        </Grid>
        {listingFee.service_fees && serviceFeeAttributes && (
          <Grid container className={classes.formTitle}>
            <Grid component={Box} item xs={12}>
              <Typography variant="h2" component="h2">
                Service Fees
              </Typography>
              <br />
              {listingFee.service_fees.map((fee: any, index: number) => (
                <>
                  <Typography variant="h3" component="h3">
                    {fee.service_fee_type}
                  </Typography>
                  <br />
                  <Grid container spacing={3}>
                    <Grid item md={6} xs={12}>
                      <TextInput
                        styleOverrides={classes.textInput}
                        type="text"
                        label="Standard Amount"
                        value={serviceFeeAttributes[index]?.amount}
                        valueChanged={(value) =>
                          handleServiceFeeChange(fee.service_fee_type, value, fee.service_id, index.toString(), fee.id)
                        }
                      />
                      {/* <TextInput
                        styleOverrides={classes.textInput}
                        type="text"
                        label={`Late Cancel & No Show Amount`}
                        value={serviceFeeAttributes[index]?.cancel_amount}
                        valueChanged={(value) => null}
                      /> */}
                    </Grid>
                    <Grid item md={3} xs={12} style={{ marginTop: 20 }}>
                      <FormControlLabel
                        checked={prepAttributes[index.toString()]?.value === '1'}
                        control={
                          <Checkbox
                            onChange={(e) => handlePrepChange(e.target.checked, index.toString())}
                            name="prep"
                            value="1"
                          />
                        }
                        label="PREP"
                      />
                    </Grid>
                    <Grid item md={3} xs={12} style={{ marginTop: 20 }}>
                      <FormControlLabel
                        checked={flatRateAttributes[index.toString()]?.value === '1'}
                        control={
                          <Checkbox
                            onChange={(e) => handleFlatRateChange(e.target.checked, index.toString())}
                            name="prep"
                            value="1"
                          />
                        }
                        label="Flat Rate"
                      />
                    </Grid>
                  </Grid>
                  <br />
                </>
              ))}
            </Grid>
          </Grid>
        )}
      </Box>
    </ConfirmCancelModal>
  );
};

export default EditListingFeeModal;
