import React, { Dispatch, useState, SetStateAction } from 'react';
import { Renewal } from '../../renewals/model';

interface AnnualUpdatesContextInterface {
  continueDisabled?: boolean;
  setContinueDisabled?: Dispatch<SetStateAction<boolean>>;

  newRenewalButtonShowing?: boolean;
  setNewRenewalButtonShowing?: Dispatch<SetStateAction<boolean>>;

  newRenewalCardShowing?: boolean;
  setNewRenewalCardShowing?: Dispatch<SetStateAction<boolean>>;

  newestRenewal?: Renewal;
  setNewestRenewal?: Dispatch<SetStateAction<Renewal>>;

  ownersExpanded?: boolean;
  setOwnersExpanded?: Dispatch<SetStateAction<boolean>>;
}

const AnnualUpdatesContext = React.createContext<AnnualUpdatesContextInterface>({});

const AnnualUpdatesContextConsumer = AnnualUpdatesContext.Consumer;
const AnnualUpdatesContextProvider = ({ children }) => {
  const [continueDisabled, setContinueDisabled] = useState(false);
  const [newRenewalButtonShowing, setNewRenewalButtonShowing] = useState(true);
  const [newRenewalCardShowing, setNewRenewalCardShowing] = useState(false);
  const [ownersExpanded, setOwnersExpanded] = useState(false);
  const [newestRenewal, setNewestRenewal] = useState({});

  return (
    <AnnualUpdatesContext.Provider
      value={{
        continueDisabled,
        setContinueDisabled,

        newRenewalButtonShowing,
        setNewRenewalButtonShowing,

        newRenewalCardShowing,
        setNewRenewalCardShowing,

        newestRenewal,
        setNewestRenewal,

        ownersExpanded,
        setOwnersExpanded,
      }}
    >
      {children}
    </AnnualUpdatesContext.Provider>
  );
};

export { AnnualUpdatesContextProvider, AnnualUpdatesContextConsumer, AnnualUpdatesContext };
