import React from 'react';
import { Typography, Grid } from '@material-ui/core';
import StudentInfoBar from './StudentInfoBar';
import { Student } from '../../students/model';
import ProgressStepper from '../components/ProgressStepper';

type PageHeaderProps = {
  title: string;
  message?: string;
  student: Student;
  step: number;
};

const PageHeader: React.FC<PageHeaderProps> = (props) => {
  const { student, title, message, step } = props;

  return (
    <>
      <Grid style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Grid item xs={3}>
          <Typography variant="h1">Annual Updater</Typography>
        </Grid>
        <Grid item xs={8}>
          <ProgressStepper activeStep={step} studentId={student?.id} />
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12} style={{ marginTop: 15 }}>
          <StudentInfoBar student={student} />
        </Grid>
        <br />
        <Grid item xs={12} style={{ marginTop: 15 }}>
          <Typography variant="h2">{title}</Typography>
          <Typography>{message}</Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default PageHeader;
