import React, { createContext } from 'react';
import { axios, alertStore } from '../../shared/singletons';
import { SchoolFormValues, SchoolResponse } from '../model';
import { schoolFormStore, schoolRecordStore } from '../../shared/singletons';

const AcademicYearsContext = createContext({
  createAcademicYear: undefined,
  updateAcademicYear: undefined,
  deleteAcademicYear: undefined,
});

const AcademicYearsContextConsumer = AcademicYearsContext.Consumer;
const AcademicYearsContextProvider = ({ children }) => {
  const createAcademicYear = async (formValues: SchoolFormValues, schoolId: string) => {
    await axios.post<string, SchoolResponse>(`payers/${schoolId}/academic_years.json`, {
      academic_year: formValues,
      headers: { 'Content-Type': 'application/json' },
    });

    schoolRecordStore.fetchRecord(schoolId);
  };

  const updateAcademicYear = async (formValues: SchoolFormValues, schoolId: string, academicYearId: string) => {
    await axios.put<string, SchoolResponse>(`payers/${schoolId}/academic_years/${academicYearId}.json`, {
      academic_year: formValues,
      headers: { 'Content-Type': 'application/json' },
    });

    schoolRecordStore.fetchRecord(schoolId);
  };

  const deleteAcademicYear = async (schoolId: string, academicYearId: string) => {
    if (academicYearId) {
      const response = await axios.delete<string, SchoolResponse>(
        `payers/${schoolId}/academic_years/${academicYearId}.json`,
        {
          headers: { 'Content-Type': 'application/json' },
        },
      );

      if (response?.data?.success) {
        schoolRecordStore.fetchRecord(schoolId);
        schoolFormStore.setState({ formModalOpen: false });
        alertStore.alertSuccess('Successfully deleted academic year.');
      } else {
        alertStore.alertError('Could not delete academic year. Please create a second academic year first.');
      }
    } else {
      alertStore.alertError('Please select an academic year to delete.');
    }
  };

  return (
    <AcademicYearsContext.Provider
      value={{
        createAcademicYear,
        updateAcademicYear,
        deleteAcademicYear,
      }}
    >
      {children}
    </AcademicYearsContext.Provider>
  );
};

export { AcademicYearsContextProvider, AcademicYearsContextConsumer, AcademicYearsContext };
