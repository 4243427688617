import React, { FunctionComponent, useEffect } from 'react';
import ReferralStagesList from '../components/ReferralStagesList';
import { useStoreObservable } from '../../../shared/state/useStoreObservable.hook';
import { ReferralStageState } from '../referralStage.store';
import { referralStageStore } from '../../../shared/singletons';

export type ReferralStagesPageProps = Record<string, unknown>;

const ReferralStagesPage: FunctionComponent<ReferralStagesPageProps> = () => {
  const referralStageState = useStoreObservable<ReferralStageState>(referralStageStore);
  const { records, isLoading } = referralStageState;

  useEffect(() => {
    referralStageStore.fetchRecords();
  }, []);

  return <ReferralStagesList records={records} isLoading={isLoading} />;
};

export default ReferralStagesPage;
