import { axios } from '../shared/singletons';
import { BaseRecordStore } from '../shared/state/baseRecord.store';
import { Service } from './model';
import { today } from '../shared/utils/date.utils';
import { oneYearFromNow } from '../shared/utils/date.utils';

export class ServiceState {
  records: Service[];
  referralsDownload: Service[];
  service: Service;
  recordsLoading: boolean;
  serviceLoading: boolean;
  appointmentsLoading: boolean;
  filteredRecords: Service[];
  totalRecords: number;
  filterValues: Record<string, string>;

  static create(props: Partial<ServiceState>): ServiceState {
    const defaults: ServiceState = {
      records: [],
      referralsDownload: [],
      service: {},
      recordsLoading: false,
      serviceLoading: false,
      appointmentsLoading: false,
      filteredRecords: null,
      totalRecords: 0,
      filterValues: {
        'ransack[status_eq]': 'active',
        'appointment_ransack[start_time_gteq]': today(),
        'appointment_ransack[start_time_lteq]': oneYearFromNow(),
        // 'appointment_ransack[start_time_lteq]': ENVIRONMENT !== 'production' ? oneYearFromNow() : today(),
      },
    };
    return Object.assign(new ServiceState(), defaults, props || {});
  }
}

export class ServiceRecordStore extends BaseRecordStore<ServiceState> {
  constructor() {
    super(ServiceState.create(JSON.parse(sessionStorage.getItem('serviceState')) || {}));
  }

  public fetchRecords(params = this.formattedFilterParams(), page = 1, per = 10): void {
    this.setState({ recordsLoading: true });
    this.saveServiceFilters();

    axios(`referral_services.json?${params}page=${page}&per=${per}`)
      .then((result) => {
        this.setState({ totalRecords: result?.data?.total_records });
        return result?.data?.result ?? [];
      })
      .then((records) => {
        this.setState({ records, recordsLoading: false });
      })
      .catch(() => {
        this.setState({ recordsLoading: false });
      });
  }

  public fetchRecord(studentId: string, serviceId: string, params = this.formattedFilterParams()): void {
    this.setState({ serviceLoading: true });

    axios(`patients/${studentId}/referral_services/${serviceId}.json?${params}`)
      .then((result) => result?.data?.result ?? [])
      .then((service) => {
        this.setState({ service, serviceLoading: false });
      })
      .catch(() => {
        this.setState({ serviceLoading: false });
      });
  }

  public setFilterValues(filterValues: Record<string, string>): void {
    this.setState({ filterValues });
  }

  public resetFilterValues(): void {
    this.setState({ filterValues: {} });
  }

  public formattedFilterParams(): string {
    const { filterValues } = this.getState();

    return Object?.keys(filterValues)?.reduce(
      (params, key, _index: number) => `${params}${key}=${filterValues[key]}&` || '',
      '',
    );
  }

  public saveServiceFilters(): void {
    const { filterValues } = this.getState();
    const savedServiceState = {
      filterValues: {
        'ransack[phase_eq]': filterValues['ransack[phase_eq]'],
        'ransack[patient_slug_i_cont]': filterValues['ransack[patient_slug_i_cont]'],
        'ransack[patient_last_name_i_cont]': filterValues['ransack[patient_last_name_i_cont]'],
        'ransack[payer_id_eq]': filterValues['ransack[payer_id_eq]'],
        'ransack[service_id_eq]': filterValues['ransack[service_id_eq]'],
        'ransack[users_id_eq]': filterValues['ransack[users_id_eq]'],
        'ransack[referral_stage_id_eq]': filterValues['ransack[referral_stage_id_eq]'],
        'ransack[status_eq]': filterValues['ransack[status_eq]'],

        'appointment_ransack[start_time_gteq]': today(),
        'appointment_ransack[start_time_lteq]': today(),
      },
      loadListPageRecords: true,
    };

    sessionStorage.setItem('serviceState', JSON.stringify(savedServiceState));
  }
}
