export const assistanceChoices = [
  { label: 'Complete Independence (0%)', value: 'Complete Independence (0%)' },
  { label: 'Modified Independence (0%)', value: 'Modified Independence (0%)' },
  { label: 'Minimal Assistance (25%)', value: 'Minimal Assistance (25%)' },
  { label: 'Moderate Assistance (50%)', value: 'Moderate Assistance (50%)' },
  { label: 'Maximum Assistance (75%)', value: 'Maximum Assistance (75%)' },
  { label: 'Total Assistance (100%)', value: 'Total Assistance (100%)' },
  { label: 'Not Addressed', value: 'Not Addressed' },
];

export const participationAttentionChoices = [
  { label: 'Complete Independence (0%)', value: 'Complete Independence (0%)' },
  { label: 'Modified Independence (0%)', value: 'Modified Independence (0%)' },
  { label: 'Minimal Assistance (25%)', value: 'Minimal Assistance (25%)' },
  { label: 'Moderate Assistance (50%)', value: 'Moderate Assistance (50%)' },
  { label: 'Maximum Assistance (75%)', value: 'Maximum Assistance (75%)' },
  { label: 'Not Addressed', value: 'Not Addressed' },
];

export const transitionChoices = [
  { label: 'Complete Independence (0%)', value: 'Complete Independence (0%)' },
  { label: 'Modified Independence (0%)', value: 'Modified Independence (0%)' },
  { label: 'Minimal Assistance (25%)', value: 'Minimal Assistance (25%)' },
  { label: 'Moderate Assistance (50%)', value: 'Moderate Assistance (50%)' },
  { label: 'Maximum Assistance (75%)', value: 'Maximum Assistance (75%)' },
  { label: 'Not Addressed', value: 'Not Addressed' },
];

export const prompts = [
  { label: 'No Prompts Required', value: 'No Prompts Required' },
  { label: 'Proximity Prompt', value: 'Proximity Prompt' },
  { label: 'Visual Prompt', value: 'Visual Prompt' },
  { label: 'Gestural Prompt', value: 'Gestural Prompt' },
  { label: 'Model Prompt', value: 'Model Prompt' },
  { label: 'Verbal (Partial) Prompt', value: 'Verbal (Partial) Prompt' },
  { label: 'Verbal (Full) Prompt', value: 'Verbal (Full) Prompt' },
  { label: 'Partial Physical Prompt', value: 'Partial Physical Prompt' },
  { label: 'Full Physical Prompt', value: 'Full Physical Prompt' },
  { label: 'Not Addressed', value: 'Not Addressed' },
];

export const methodChoices = [
  { label: 'Phone', value: 'Phone' },
  { label: 'Email', value: 'Email' },
  { label: 'In Person', value: 'In Person' },
  { label: 'Virtual Meeting', value: 'Virtual Meeting' },
  { label: 'Compliance Activity', value: 'Compliance Activity' },
];

export const sdiChoices = [
  { label: 'Improved independence', value: 'Improved independence' },
  { label: 'Utilized given assistance', value: 'Utilized given assistance' },
  { label: 'Not targeted this session', value: 'Not targeted this session' },
];
