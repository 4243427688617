import React, { useState, useContext } from 'react';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import FilterListIcon from '@material-ui/icons/FilterList';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

import FilterRow from './FilterRow';

import { appointmentBaseStore } from '../../shared/singletons';
import { useStoreObservable } from '../../shared/state/useStoreObservable.hook';
import { FilterProps } from '../../shared/components/FilterBar';
import { defaultFilters } from '../constants';
import { AppointmentsContext } from '../contexts/AppointmentsContext';
import { allAppointmentStatusChoices } from '../constants';

import { UserContext } from '../../auth/contexts/userContext';

interface AppointmentFilterBarProps {
  filters?: FilterProps[];
}

const AppointmentFilterBar: React.FunctionComponent<AppointmentFilterBarProps> = (props) => {
  let [advancedFiltersOpen, setAdvancedFiltersOpen] = useState(false);

  let { schoolChoices, allOwnerChoices, supervisorChoices, serviceTypeChoices } = useStoreObservable(
    appointmentBaseStore,
  );
  let { fetchAppointments, filterValues, setFilterValues, defaultFilterValues, setAppointmentsDownload } = useContext(
    AppointmentsContext,
  );
  let { currentUserHasAnyRole } = useContext(UserContext);

  let {
    filters = defaultFilters(
      schoolChoices,
      allAppointmentStatusChoices,
      allOwnerChoices,
      supervisorChoices,
      serviceTypeChoices,
    ),
  } = props;

  let regularFilters = filters.filter((filter) => !filter.advanced);
  let advancedFilters = filters.filter((filter) => filter.advanced);

  // Can remove fetchAppointments from props

  const clearFilters = () => {
    setAppointmentsDownload(null);
    setFilterValues(defaultFilterValues);
    fetchAppointments(defaultFilterValues);
  };

  const handleApplyClicked = () => {
    setAppointmentsDownload(null);
    fetchAppointments();
  };

  return (
    <Grid container spacing={1} alignItems="center">
      <Grid item xs={12}>
        <FilterRow
          filters={regularFilters}
          filterValues={filterValues}
          setFilterValues={setFilterValues}
          fetchAppointments={fetchAppointments}
        />
      </Grid>
      <Grid item>
        <Button variant="contained" color="primary" disableElevation onClick={handleApplyClicked}>
          Apply
        </Button>
      </Grid>
      <Grid item>
        <Button variant="contained" color="default" disableElevation onClick={() => clearFilters()}>
          Clear All
        </Button>
      </Grid>
      <Grid item xs={12}>
        {currentUserHasAnyRole(['admin', 'supervisor', 'system_owner']) && (
          <Grid item>
            <Button
              onClick={() => setAdvancedFiltersOpen(!advancedFiltersOpen)}
              color={advancedFiltersOpen ? 'secondary' : 'primary'}
              startIcon={advancedFiltersOpen ? <VisibilityOffIcon /> : <FilterListIcon />}
            >
              {advancedFiltersOpen ? 'Hide Advanced Filters' : 'Show Advanced Filters'}
            </Button>
          </Grid>
        )}
      </Grid>
      {advancedFiltersOpen && (
        <Grid item xs={12}>
          <FilterRow
            filters={advancedFilters}
            filterValues={filterValues}
            setFilterValues={setFilterValues}
            fetchAppointments={fetchAppointments}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default AppointmentFilterBar;
