import React, { FunctionComponent } from 'react';
import { useStoreObservable } from '../state/useStoreObservable.hook';
import { alertStore } from '../singletons';
import { AlertState } from '../alert.store';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import TranslatedText from './TranslatedText';

type AppAlertProps = {};

const AppAlert: FunctionComponent<AppAlertProps> = () => {
  const state = useStoreObservable<AlertState>(alertStore);
  const { isOpen, severity, translation, autoHideDurationInMs } = state;

  const handleClose = (event) => alertStore.handleClose(event);

  return (
    <Snackbar open={isOpen} autoHideDuration={autoHideDurationInMs} onClose={handleClose}>
      <Alert onClose={handleClose} severity={severity}>
        <TranslatedText t={translation} />
      </Alert>
    </Snackbar>
  );
};

export default AppAlert;
