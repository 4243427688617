import React, { useEffect, useContext, useState } from 'react';
import { Box, Grid, Typography, FormGroup, FormControlLabel, Checkbox, makeStyles } from '@material-ui/core';
import LoadingSpinner from '../../shared/components/LoadingSpinner';
import TextInput from '../../shared/components/form/TextInput';
import SelectInput from '../../shared/components/form/SelectInput';
import ErrorAlert from '../../shared/components/ErrorAlert';
import { SelectOption } from '../../shared/common.model';
import { AppointmentFormValues } from '../model';
import { appointmentFormStore, appointmentBaseStore, appointmentRecordStore } from '../../shared/singletons';
import { useStoreObservable } from '../../shared/state/useStoreObservable.hook';
import { stringToDayOfWeek } from '../../shared/utils/date.utils';
import { UserContext } from '../../auth/contexts/userContext';
import { AppointmentsContext } from '../contexts/AppointmentsContext';
import { Service } from '../../services/model';
import { formatDate } from '../../shared/utils/date.utils';

const useStyles = makeStyles({
  textInput: {
    height: '60px',
    width: '80%',
    margin: '10px 0',
  },
  baseError: {
    marginBottom: '10px',
  },
});

interface NewAppointmentFormProps {
  service?: Service;
  isLoading: boolean;
}

const NewAppointmentForm: React.FC<NewAppointmentFormProps> = (props) => {
  const { isLoading, service } = props;

  const classes = useStyles();
  const { appointment } = useStoreObservable(appointmentRecordStore);
  const { formErrors } = useStoreObservable(appointmentFormStore);
  const { appointmentOwnerChoices, locationChoices, recurrenceChoices } = useStoreObservable(appointmentBaseStore);
  const { currentUserHasRole } = useContext(UserContext);
  const [startDateLabel, setStartDateLabel] = useState('');
  const [endDateLabel, setEndDateLabel] = useState('');
  const [ownerArray, setOwnerArray] = useState<string[]>([]);
  const [yearWord, setYearWord] = useState('Year');

  const { appointmentFormValues, setAppointmentFormValues, appointmentErrorWarnings, selectedUnits } = useContext(
    AppointmentsContext,
  );

  useEffect(() => {
    appointmentBaseStore.fetchChoices(service);
    if (service?.quarter_name === 'ESY') {
      setYearWord('ESY');
    } else {
      setYearWord('Year');
    }
  }, [service]);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    appointmentErrorWarnings(appointment, currentUserHasRole('admin') ? 'admin' : 'therapist', service);
  }, [appointmentFormValues]);
  /* eslint-enable react-hooks/exhaustive-deps */

  const [appointmentCount, setAppointmentCount] = useState(1);

  const handleOwnerCheckboxChange = (value: string) => {
    let newOwnerArray = [...ownerArray];
    if (ownerArray?.includes(value)) {
      newOwnerArray = ownerArray?.filter((p) => p !== value);
    } else {
      newOwnerArray.push(value);
    }

    setOwnerArray(newOwnerArray);
    setAppointmentFormValues({ ...appointmentFormValues, owner_array: newOwnerArray });
  };

  const handleFormValueChange = async (key: string, value: string) => {
    setAppointmentFormValues({ ...appointmentFormValues, [key]: value });
    calculateAppointmentCount({ ...appointmentFormValues, [key]: value });
  };

  const calculateAppointmentCount = (appointmentFormValues: AppointmentFormValues) => {
    // Copied code from CRM site to calculate number of appointments between two dates
    const recur_lengths = { daily: 1, weekly: 7, biweekly: 14, monthly: 28, quarterly: 63, yearly: 364 };
    const startDate = new Date(appointmentFormValues.schedule_date);
    const endDate = new Date(appointmentFormValues.end_recurrence);
    const recur_days = recur_lengths[appointmentFormValues.recurrence];

    const next_date = startDate;
    let count = 0;
    while (next_date <= endDate) {
      count++;
      next_date.setDate(next_date.getDate() + recur_days);
      if (appointmentFormValues.recurrence === 'daily') {
        if (next_date.getDay() === 0) {
          next_date.setDate(next_date.getDate() + 1);
        }
        if (next_date.getDay() === 6) {
          next_date.setDate(next_date.getDate() + 2);
        }
      }
    }
    setAppointmentCount(count);
  };

  return isLoading ? (
    <LoadingSpinner />
  ) : (
    <Grid container>
      {formErrors?.base && (
        <ErrorAlert
          display={formErrors?.base?.length > 0}
          onClose={() => appointmentFormStore.setState({ baseErrorAlertVisible: false })}
        >
          {formErrors?.base}
        </ErrorAlert>
      )}
      <Grid container>
        <Grid item xs={3}>
          <Typography>{`Current Quarter: ${service?.quarter_name}`}</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography>{`${yearWord} Start: ${formatDate(service?.first_day_of_school_year)}`}</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography>{`${yearWord} End: ${formatDate(service?.last_day_of_school_year)}`}</Typography>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <SelectInput
          value={appointmentFormValues?.recurrence}
          styleOverrides={classes.textInput}
          type="text"
          label="Appointment Recurrence"
          errorMessage={formErrors?.recurrence}
          valueChanged={(value: string) => handleFormValueChange('recurrence', value)}
          choices={recurrenceChoices}
          defaultChoice={{ value: '', label: 'None' }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextInput
          value={appointmentFormValues?.schedule_date}
          styleOverrides={classes.textInput}
          type="date"
          shrinkLabel
          label={`Start Date ${startDateLabel}`}
          errorMessage={formErrors?.schedule_date}
          valueChanged={(value: string) => {
            handleFormValueChange('schedule_date', value);
            setStartDateLabel(`(${stringToDayOfWeek(`${value} 12:00`)})`);
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextInput
          value={appointmentFormValues?.end_recurrence}
          styleOverrides={classes.textInput}
          type="date"
          shrinkLabel
          label={`End Date ${endDateLabel}`}
          display={
            appointmentFormValues?.recurrence === 'one_time' || appointmentFormValues?.recurrence === undefined
              ? 'none'
              : 'block'
          }
          errorMessage={formErrors?.end_recurrence}
          valueChanged={(value: string) => {
            handleFormValueChange('end_recurrence', value);
            setEndDateLabel(`(${stringToDayOfWeek(`${value} 12:00`)})`);
          }}
        />
      </Grid>
      <Grid container>
        {appointmentFormValues?.recurrence !== 'one_time' && (
          <Grid item xs={5}>
            <Typography>{`Number of Appointments: ${appointmentCount}`}</Typography>
          </Grid>
        )}
        <Grid item xs={5}>
          <Typography>{`Selected Units: ${selectedUnits || ''}`}</Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={5}>
          <TextInput
            value={appointmentFormValues?.start_time}
            styleOverrides={classes.textInput}
            type="time"
            shrinkLabel
            label="Start Time"
            errorMessage={formErrors?.start_hour}
            valueChanged={(value: string) => handleFormValueChange('start_time', value)}
            inputProps={{ step: 300 }}
          />
        </Grid>
        <Grid item xs={5}>
          <TextInput
            value={appointmentFormValues?.end_time}
            styleOverrides={classes.textInput}
            type="time"
            shrinkLabel
            label="End Time"
            errorMessage={formErrors?.end_hour}
            valueChanged={(value: string) => handleFormValueChange('end_time', value)}
            inputProps={{ step: 300 }}
          />
        </Grid>
      </Grid>
      <SelectInput
        value={appointmentFormValues?.admin_units_override}
        styleOverrides={classes.textInput}
        type="text"
        label="Allow Service Provider to Exceed Maximum Units"
        valueChanged={(value: string) => handleFormValueChange('admin_units_override', value)}
        choices={[
          { value: 'true', label: 'Yes' },
          { value: 'false', label: 'No' },
        ]}
      />
      <SelectInput
        value={appointmentFormValues?.location_id}
        styleOverrides={classes.textInput}
        type="text"
        label="Location"
        errorMessage={formErrors?.location_id}
        valueChanged={(value: string) => handleFormValueChange('location_id', value)}
        choices={locationChoices}
        defaultChoice={{ value: '', label: 'None' }}
      />
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="h3" component="h3">
            Owners
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <FormGroup row>
            <Grid container>
              {appointmentOwnerChoices?.map((owner: SelectOption) => (
                <Grid component={Box} key={owner.label} item xs={12} md={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name={owner.label}
                        value={owner.value.toString()}
                        color="primary"
                        onChange={() => handleOwnerCheckboxChange(owner.value.toString())}
                      />
                    }
                    label={owner.label}
                    checked={ownerArray?.includes(owner.value.toString())}
                  />
                </Grid>
              ))}
            </Grid>
          </FormGroup>
        </Grid>
      </Grid>
      <br />
    </Grid>
  );
};

export default NewAppointmentForm;
