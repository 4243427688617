import React, { FunctionComponent, useContext } from 'react';
import { Typography, Grid, Accordion, AccordionSummary, AccordionDetails, Button } from '@material-ui/core';
import UserBasicInfoForm from '../components/UserEditDetailsForm';
import UserRolesForm from '../components/UserRolesForm';
import UserSchoolsForm from '../components/UserSchoolsForm';
import { InternalUsersContext } from '../../internalUsers/contexts/InternalUsersContext';
import { ExpandMore } from '@material-ui/icons';

const NewUserPage: FunctionComponent = () => {
  const { selectedRole, createInternalUser } = useContext(InternalUsersContext);

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h1">New User</Typography>
      </Grid>

      <Grid item xs={12}>
        <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography variant="h2">Basic Info</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <UserBasicInfoForm />
          </AccordionDetails>
        </Accordion>
      </Grid>

      <br />
      <Grid item xs={12}>
        <Accordion style={{ marginTop: 30 }} defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography variant="h2">Roles</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <UserRolesForm />
          </AccordionDetails>
        </Accordion>
      </Grid>
      <br />
      <Grid item xs={12}>
        <Accordion style={{ marginTop: 30 }} disabled={selectedRole !== '13'}>
          <AccordionSummary>
            <Typography variant="h2">Schools</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <UserSchoolsForm />
          </AccordionDetails>
        </Accordion>
      </Grid>
      <Grid style={{ marginTop: 30 }}>
        <Button variant="contained" color="primary" onClick={createInternalUser}>
          Create User
        </Button>
      </Grid>
    </Grid>
  );
};

export default NewUserPage;
