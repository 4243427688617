import React, { FunctionComponent } from 'react';
import { Button, Grid, Typography, makeStyles } from '@material-ui/core';
import SelectInput from '../../shared/components/form/SelectInput';
import { dashboardStore } from '../../shared/singletons';
import { ServiceFormOptionsContext } from '../../services/contexts/ServiceFormOptionsContext';
import { UserContext } from '../../auth/contexts/userContext';

const useStyles = makeStyles({
  textInput: {
    height: '60px',
    width: '80%',
    minWidth: 400,
    margin: '10px 0',
  },
});

interface ServiceProviderPicker {
  serviceProvider: string;
  setServiceProvider: any;
}

const ServiceProviderPicker: FunctionComponent<ServiceProviderPicker> = (props) => {
  const { serviceProvider, setServiceProvider } = props;
  const classes = useStyles();
  const { ownerChoices, fetchChoices } = React.useContext(ServiceFormOptionsContext);
  const [choicesFetched, setChoicesFetched] = React.useState(false);
  const { user } = React.useContext(UserContext);

  React.useEffect(() => {
    if (!choicesFetched) {
      fetchChoices();
      setChoicesFetched(true);
    }
  }, [choicesFetched, setChoicesFetched, fetchChoices]);

  const serviceProviderChoices = ownerChoices.filter((owner) => owner.value !== user.id);

  return (
    <Grid container spacing={3} justify="center" style={{ display: 'flex', alignItems: 'center' }}>
      <Grid item>
        <Typography variant="h2" component="h2">
          View Dashboard As Service Provider:
        </Typography>
      </Grid>
      <Grid item>
        <SelectInput
          value={serviceProvider}
          styleOverrides={classes.textInput}
          type="text"
          label="Service Provider"
          valueChanged={(value: string) => setServiceProvider(value)}
          choices={serviceProviderChoices}
          defaultChoice={{ value: '', label: 'None' }}
        />
      </Grid>
      <Grid item>
        <Button variant="contained" color="primary" onClick={() => dashboardStore.fetchRecord(serviceProvider || '0')}>
          View Dashboard
        </Button>
      </Grid>
    </Grid>
  );
};

export default ServiceProviderPicker;
