import { axios } from '../shared/singletons';
import { BaseRecordStore } from '../shared/state/baseRecord.store';
import { Appointment } from './model';
import { today } from '../shared/utils/date.utils';
import { oneYearFromNow } from '../shared/utils/date.utils';
import { ENVIRONMENT } from '../../config/env';

export class AppointmentRecordState {
  records: Appointment[];
  appointment: Appointment;
  recordsLoading: boolean;
  appointmentLoading: boolean;
  filteredRecords: Appointment[];
  totalRecords: number;
  filterValues: Record<string, string>;

  static create(props: Partial<AppointmentRecordState>): AppointmentRecordState {
    const defaults: AppointmentRecordState = {
      records: [],
      appointment: {},
      recordsLoading: false,
      appointmentLoading: false,
      filteredRecords: null,
      totalRecords: 0,
      filterValues: {
        'ransack[start_time_gteq]': today(),
        'ransack[start_time_lteq]': ENVIRONMENT !== 'production' ? oneYearFromNow() : today(),
      },
    };
    return Object.assign(new AppointmentRecordState(), defaults, props || {});
  }
}

export class AppointmentRecordStore extends BaseRecordStore<AppointmentRecordState> {
  constructor() {
    super(AppointmentRecordState.create(JSON.parse(sessionStorage.getItem('appointmentState')) || {}));
  }

  public fetchRecords(params = this.formattedFilterParams(), page = 1, per = 25): void {
    this.saveAppointmentFilters(params);
    this.setState({ recordsLoading: true });

    axios(`appointments.json?${params}&page=${page}&per=${per}`)
      .then((result) => {
        this.setState({ totalRecords: result?.data?.total_records });
        return result?.data?.result ?? [];
      })
      .then((records) => {
        this.setState({ records, recordsLoading: false });
      })
      .catch(() => {
        this.setState({ recordsLoading: false });
      });
  }

  public fetchRecord(studentId: string, appointmentId: string, params = this.formattedFilterParams()): void {
    this.setState({ appointmentLoading: true });

    axios(`patients/${studentId}/appointments/${appointmentId}.json?${params}`)
      .then((result) => result?.data?.result ?? [])
      .then((appointment) => {
        this.setState({ appointment, appointmentLoading: false });
      })
      .catch(() => {
        this.setState({ appointmentLoading: false });
      });
  }

  public formattedFilterParams(): string {
    const { filterValues } = this.getState();

    return Object?.keys(filterValues)?.reduce(
      (params, key, _index: number) => `${params}${key}=${filterValues[key]}&` || '',
      '',
    );
  }

  public convertParamsToFilterValues(params: string): Record<string, string> {
    const filterValues = {};

    params.split('&').forEach((param) => {
      const [key, value] = param.split('=');
      filterValues[key] = value;
    });

    return filterValues;
  }

  public saveAppointmentFilters(params): void {
    const { filterValues } = this.getState();
    const convertedParams = this.convertParamsToFilterValues(params);
    const filterValuesToSave = convertedParams || filterValues;

    const savedAppointmentState = {
      filterValues: {
        'ransack[patient_last_name_cont]': filterValuesToSave['ransack[patient_last_name_cont]'],
        'ransack[patient_slug_cont]': filterValuesToSave['ransack[patient_slug_cont]'],
        'ransack[payer_id_eq]': filterValuesToSave['ransack[payer_id_eq]'],
        'ransack[status_eq]': filterValuesToSave['ransack[status_eq]'],
        'ransack[start_time_gteq]': filterValuesToSave['ransack[start_time_gteq]'] || today(),
        'ransack[start_time_lteq]': filterValuesToSave['ransack[start_time_lteq]'] || today(),
        'ransack[service_id_eq]': filterValuesToSave['ransack[service_id_eq]'],
        'ransack[user_id_eq]': filterValuesToSave['ransack[user_id_eq]'],
        'ransack[location_id_eq]': filterValuesToSave['ransack[location_id_eq]'],
        'ransack[blackout_eq]': filterValuesToSave['ransack[blackout_eq]'],
      },
      loadListPageRecords: true,
    };

    this.setState({ filterValues: filterValuesToSave });
    sessionStorage.setItem('appointmentState', JSON.stringify(savedAppointmentState));
  }
}
