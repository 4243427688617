import React, { createContext } from 'react';
import { axios, schoolRecordStore } from '../../shared/singletons';
import { SchoolClosingFormValues } from '../model';

interface SchoolClosingContextProps {
  createSchoolClosing?: (formValues: SchoolClosingFormValues, schoolId: string) => void;
  deleteSchoolClosing?: (deletedDate: string, schoolId: string, onSuccess: () => void) => Promise<void>;
}

const SchoolClosingContext = createContext<SchoolClosingContextProps>({});
const SchoolClosingContextConsumer = SchoolClosingContext.Consumer;

const SchoolClosingContextProvider: React.FC = ({ children }) => {
  const createSchoolClosing = (formValues: SchoolClosingFormValues, schoolId: string) => {
    axios({
      method: 'post',
      url: `/payers/${schoolId}/blackout_dates.json`,
      data: { blackout_date: formValues },
      headers: { 'Content-Type': 'application/json' },
    })
      .then(() => {
        schoolRecordStore.fetchRecord(schoolId);
      })
      .catch((error) => {});
  };

  const deleteSchoolClosing = async (deletedDate: string, schoolId: string, onSuccess) => {
    axios({
      method: 'delete',
      url: `/payers/${schoolId}/blackout_dates/0.json`,
      data: { start_date: deletedDate },
      headers: { 'Content-Type': 'application/json' },
    })
      .then(() => {
        if (onSuccess) {
          onSuccess();
        }

        schoolRecordStore.fetchRecord(schoolId);
      })
      .catch(() => {
        schoolRecordStore.fetchRecord(schoolId);
      });
  };

  return (
    <SchoolClosingContext.Provider
      value={{
        createSchoolClosing,
        deleteSchoolClosing,
      }}
    >
      {children}
    </SchoolClosingContext.Provider>
  );
};

export { SchoolClosingContextProvider, SchoolClosingContextConsumer, SchoolClosingContext };
