import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, FilledInput, FormControl, FormHelperText, Input, InputLabel, InputAdornment } from '@material-ui/core';
import { InputElementEvent } from '../../common.model';

interface TextInputProps {
  value: string;
  label: string;
  disabled?: boolean;
  onChange?: (element: React.ChangeEvent) => void;
  valueChanged?: (value: string) => void;
  display?: string;
  styleOverrides?: string;
  error?: boolean;
  errorMessage?: string;
  isFilled?: boolean;
  shrinkLabel?: boolean;
  multiline?: boolean;
  type?: string;
  rows?: number;
  inputProps?: Record<string, string | number>;
}

const useStyles = makeStyles((_theme) => ({
  textField: {
    minHeight: '30px',
    width: '100%',
  },
  helperText: {
    fontSize: '12px',
  },
  endPercentAdornment: {
    position: 'relative',
    top: '7px',
  },
  endUnitAdornment: {
    position: 'relative',
    top: '7px',
  },
  startUnitAdornment: {
    position: 'relative',
  },
}));

const TextInput: React.FC<TextInputProps> = (props) => {
  const {
    value,
    label,
    onChange,
    valueChanged,
    styleOverrides,
    errorMessage,
    type,
    display,
    shrinkLabel,
    isFilled = true,
    disabled = false,
    multiline,
    rows,
    inputProps = {},
  } = props;

  const classes = useStyles();

  const handleChange = (event: InputElementEvent): void => {
    if (onChange) {
      onChange(event);
    }
    if (valueChanged) {
      valueChanged(event.target.value);
    }
  };

  const getEndAdornment = (type) => {
    if (type === 'percent') {
      return (
        <InputAdornment position="end">
          <p className={classes.endPercentAdornment}>%</p>
        </InputAdornment>
      );
    } else if (type === 'units') {
      return (
        <InputAdornment position="end">
          <p className={classes.endPercentAdornment}>units</p>
        </InputAdornment>
      );
    }
    return null;
  };

  const getStartAdornment = (type) => {
    if (type === 'dollar') {
      return (
        <InputAdornment position="start">
          <p className={classes.startUnitAdornment}>$</p>
        </InputAdornment>
      );
    }
    return null;
  };

  const formattedInput = isFilled ? (
    <FilledInput
      multiline={multiline}
      rows={rows}
      onChange={handleChange}
      type={type}
      value={value}
      disabled={disabled}
      startAdornment={getStartAdornment(type)}
      endAdornment={getEndAdornment(type)}
      inputProps={inputProps}
    />
  ) : (
    <Input onChange={handleChange} type={type} value={value} inputProps={inputProps} disabled={disabled} />
  );

  return (
    <Box display={display}>
      <FormControl
        variant="filled"
        error={errorMessage?.length > 0}
        className={`${styleOverrides} ${classes.textField}`}
      >
        <InputLabel shrink={shrinkLabel} htmlFor="{label}">
          {label}
        </InputLabel>
        {formattedInput}
        {errorMessage && <FormHelperText className={classes.helperText}>{`${label} ${errorMessage}`}</FormHelperText>}
      </FormControl>
    </Box>
  );
};

export default TextInput;
