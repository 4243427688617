import React, { useState, Dispatch, SetStateAction, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { User } from '../../users/model';
import { axios } from '../../shared/singletons';

// TODO: Rename UserContext to SessionsContext
// Then rename this to something that better fits with updating existing users

interface InternalUsersContextInterface {
  formValues?: Record<string, string>;
  setFormValues?: Dispatch<SetStateAction<Record<string, string>>>;

  formErrors?: Record<string, string>;
  setFormErrors?: Dispatch<SetStateAction<Record<string, string>>>;

  selectedRole?: String;
  setSelectedRole?: Dispatch<SetStateAction<String>>;

  selectedSchools?: String[];
  setSelectedSchools?: Dispatch<SetStateAction<String[]>>;

  user?: User;
  setUser?: Dispatch<SetStateAction<User>>;

  fetchUser?: (userId: string) => void;

  userLoading?: boolean;
  setUserLoading?: Dispatch<SetStateAction<boolean>>;

  basicInfoModalOpen?: boolean;
  setBasicInfoModalOpen?: Dispatch<SetStateAction<boolean>>;

  rolesModalOpen?: boolean;
  setRolesModalOpen?: Dispatch<SetStateAction<boolean>>;

  passwordModalOpen?: boolean;
  setPasswordModalOpen?: Dispatch<SetStateAction<boolean>>;

  activateModalOpen?: boolean;
  setActivateModalOpen?: Dispatch<SetStateAction<boolean>>;

  createInternalUser?: () => void;
  updateInternalUser?: () => void;
}

const InternalUsersContext = React.createContext<InternalUsersContextInterface>({});

const InternalUsersContextConsumer = InternalUsersContext.Consumer;
const InternalUsersContextProvider = ({ children }) => {
  const history = useHistory();

  const [formValues, setFormValues] = useState<Record<string, string>>({});
  const [selectedRole, setSelectedRole] = useState<String>();
  const [selectedSchools, setSelectedSchools] = useState<String[]>([]);
  const [userLoading, setUserLoading] = useState<boolean>(false);
  const [user, setUser] = useState<User>();
  const [basicInfoModalOpen, setBasicInfoModalOpen] = useState<boolean>(false);
  const [rolesModalOpen, setRolesModalOpen] = useState<boolean>(false);
  const [passwordModalOpen, setPasswordModalOpen] = useState<boolean>(false);
  const [activateModalOpen, setActivateModalOpen] = useState<boolean>(false);
  const [formErrors, setFormErrors] = useState<Record<string, string>>({});

  useEffect(() => {
    setSelectedRole(user?.role_ids[0]);
    setSelectedSchools(user?.payer_ids?.map((id) => id.toString()) || []);
  }, [user]);

  const fetchUser = (userId: string) => {
    setUserLoading(true);
    axios.get(`/users/${userId}`).then((response) => {
      setUser(response?.data?.result);
      setUserLoading(false);
    });
  };

  const createInternalUser = () => {
    const combinedFormValues = { ...formValues, role_ids: [selectedRole], payer_ids: selectedSchools };

    axios
      .post('/users', { user: combinedFormValues })
      .then((response) => {
        const id = response?.data?.result?.id;
        if (id) {
          history.push(`/users/${id}`);
        }
      })
      .catch((error) => {
        setFormErrors(error?.response?.data);
      });
  };

  const updateInternalUser = () => {
    const combinedFormValues = { ...formValues, role_ids: [selectedRole], payer_ids: selectedSchools };

    axios
      .put(`/users/${user?.id}`, { user: combinedFormValues })
      .then(() => {
        history.push(`/users/${user?.id}`);
      })
      .catch((error) => {
        setFormErrors(error?.response?.data);
      });
  };

  return (
    <InternalUsersContext.Provider
      value={{
        formValues,
        setFormValues,

        selectedRole,
        setSelectedRole,

        selectedSchools,
        setSelectedSchools,

        user,
        fetchUser,

        userLoading,
        setUserLoading,

        basicInfoModalOpen,
        setBasicInfoModalOpen,
        rolesModalOpen,
        setRolesModalOpen,
        passwordModalOpen,
        setPasswordModalOpen,
        activateModalOpen,
        setActivateModalOpen,

        formErrors,
        setFormErrors,

        createInternalUser,
        updateInternalUser,
      }}
    >
      {children}
    </InternalUsersContext.Provider>
  );
};

export { InternalUsersContext, InternalUsersContextConsumer, InternalUsersContextProvider };
