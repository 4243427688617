import { axios, alertStore, userRecordStore } from '../shared/singletons';
import { BaseFormStore } from '../shared/state/baseForm.store';
import { User, UserFormValues, UserFormErrors, UserResponse } from './model';
import { API_ENDPOINT } from '../../config/env';

export class UserState {
  formValues: UserFormValues;
  formErrors: UserFormErrors;
  formErrorAlertVisible: boolean;
  formStepperIndex: number;
  formModalOpen: boolean;
  formModalUser: User;
  rolesFormModalOpen: boolean;
  rolesFormModalUser: User;
  accountEditModal: boolean;
  accountEditModalUser: User;

  static create(props: Partial<UserState>): UserState {
    const defaults: UserState = {
      formValues: {},
      formErrors: {},
      formErrorAlertVisible: true,
      formStepperIndex: 0,
      formModalOpen: false,
      formModalUser: {},
      rolesFormModalOpen: false,
      rolesFormModalUser: {},
      accountEditModal: false,
      accountEditModalUser: {},
    };
    return Object.assign(new UserState(), defaults, props || {});
  }
}

export class UserFormStore extends BaseFormStore<UserState> {
  constructor() {
    super(UserState.create({}));
  }

  public async createOrUpdateUser(formValues: UserFormValues, userId: string, onSuccess?: () => void): Promise<void> {
    try {
      const response = await axios[userId ? 'put' : 'post']<string, UserResponse>(
        `${API_ENDPOINT}/users${userId ? `/${userId}` : ''}.json`,
        {
          user: formValues,
          headers: { 'Content-Type': 'application/json' },
        },
      );

      if (onSuccess) {
        onSuccess();
      } else {
        userRecordStore.fetchRecords();
      }

      userRecordStore.setState({ user: response.data.result });
      this.setState({ formStepperIndex: 1, rolesFormModalOpen: false, formModalUser: response.data.result });
    } catch (error) {
      this.setState({
        formStepperIndex: 0, // Don't progress stepper if submit errored
        formErrors: error.response?.data,
        formErrorAlertVisible: !!error.response?.data?.base, // The base errors are not tied to a specific field and displayed at the top of the form
      });
    }
  }

  public updateLocations(formValues: UserFormValues, userId: string): void {
    axios
      .put<string, UserResponse>(`${API_ENDPOINT}/users/${userId}.json`, {
        user: formValues,
        headers: { 'Content-Type': 'application/json' },
      })
      .then(() => {
        userRecordStore.fetchRecords(); // TODO optimize?
        this.setState({ formStepperIndex: 2 });
      })
      .catch((error) => {
        this.setState({
          formStepperIndex: 1, // Don't progress stepper if submit errored
          formErrors: error.response.data,
          formErrorAlertVisible: !!error.response?.data?.base, // The base errors are not tied to a specific field and displayed at the top of the form
        });
      });
  }

  public updateRoles(formValues: UserFormValues, userId: string, onSuccess: () => void): void {
    axios
      .put<string, UserResponse>(`${API_ENDPOINT}/users/${userId}.json`, {
        user: formValues,
        headers: { 'Content-Type': 'application/json' },
      })
      .then(() => {
        userRecordStore.fetchRecord(userId);
        this.setState({ formModalOpen: false, rolesFormModalOpen: false, formValues: {}, formStepperIndex: 0 });
      })
      .catch((error) => {
        this.setState({
          formErrors: error.response.data,
          formErrorAlertVisible: !!error.response?.data?.base, // The base errors are not tied to a specific field and displayed at the top of the form
        });
      });
  }

  public deleteUser(userId: string, afterAction: () => void): void {
    axios
      .delete<string, UserResponse>(`${API_ENDPOINT}/users/${userId}.json`, {
        headers: { 'Content-Type': 'application/json' },
      })
      .then(() => {
        if (afterAction) {
          afterAction();
        } else {
          userRecordStore.fetchRecords(); // TODO optimize?
        }
      })
      .catch(() => {
        alertStore.alertError('users.alert.delete.error');
      });
  }

  public setEditRolesModalOpen(isOpen: boolean, user: User): void {
    this.clearFormValues();

    if (user) {
      const formValues = {
        role_ids: user.role_ids,
        inactivated_at: user.status === 'active' ? '' : 'inactive',
      };

      this.setState({ rolesFormModalOpen: isOpen, rolesFormModalUser: user, formValues });
    }
  }

  public setEditModalOpen(isOpen: boolean, user: User): void {
    this.clearFormValues();

    if (user) {
      const formValues = {
        email: user.email,
        username: user.username,
        first_name: user.first_name,
        last_name: user.last_name,
        supervisor_id: user.supervisor_id,
        payer_ids: user.payer_ids,
        role_ids: user.role_ids,
        password: '',
        password_confirmation: '',
      };

      this.setState({ formModalOpen: isOpen, formModalUser: user, formValues });
    }
  }

  public setAccountEditModalOpen(isOpen: boolean, user: User): void {
    this.clearFormValues();

    if (user) {
      const formValues = {
        email: user.email,
        username: user.username,
        first_name: user.first_name,
        last_name: user.last_name,
        supervisor_id: user.supervisor_id,
        password: user.password,
        password_confirmation: user.password_confirmation,
        payer_ids: user.payer_ids,
        role_ids: user.role_ids,
      };

      this.setState({
        accountEditModal: isOpen,
        formValues,
        accountEditModalUser: user,
      });
    }
  }

  protected clearFormValues(): void {
    this.setState({ formValues: {}, formErrors: {} });
  }
}
