import React, { useState, useEffect, FunctionComponent } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Grid, Typography } from '@material-ui/core';
import { axios } from '../../shared/singletons';
import PasswordUpdateForm from '../components/PasswordUpdateForm';
import NoUserMessage from '../components/NoUserMessage';

const PasswordResetUpdatePage: FunctionComponent = () => {
  const { token } = useParams<Record<string, string>>();

  const [user, setUser] = useState({ id: null });

  useEffect(() => {
    axios
      .get(`/passwords/0/edit?reset_password_token=${token}`)
      .then((response) => {
        setUser(response?.data);
      })
      .catch((error) => {
        console.error('Error fetching user:', error);
      });
  }, [token]);

  return (
    <Box>
      <Grid justify="space-between" container spacing={3}>
        <Grid item>
          <Typography variant="h1" component="h1">
            Reset Password
          </Typography>
        </Grid>
      </Grid>
      <br />
      {user?.id ? <PasswordUpdateForm /> : <NoUserMessage />}
    </Box>
  );
};

export default PasswordResetUpdatePage;
