/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext, FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';
import axios from '../../shared/utils/axios.utils';
import DataTable from '../../shared/components/DataTable';
import { IDataTableColumn } from '../../shared/components/DataTable/DataTable/model';
import LoadingSpinner from '../../shared/components/LoadingSpinner';
import { Box, Grid, Card, CardContent, Typography, Button } from '@material-ui/core';
import TrainingFormModal from '../components/TrainingFormModal';
import { TrainingsContext } from '../contexts/TrainingsContext';

export type MyTrainingsPageProps = Record<string, unknown>;

const statusColors = {
  Started: 'red',
  'Not Started': 'black',
  Complete: 'green',
};

const MyTrainingsPage: FunctionComponent<MyTrainingsPageProps> = () => {
  const history = useHistory();
  const { trainingLoading, setTrainingLoading } = useContext(TrainingsContext);
  const [demoStudentId, setDemoStudentId] = useState(null);
  const [demoReferralServiceId, setDemoReferralServiceId] = useState(null);
  const [trainings, setTrainings] = useState([]);
  const [blockNewTraining, setBlockNewTraining] = useState(false);

  const handleViewTraining = async (row) => {
    localStorage.setItem('joyrideStepIndex', '0');
    await axios.post('/training_logs/', { training_id: row.id, status: 'Started' });
    const replacedLink = row.content_link
      .replace('demoStudentId', demoStudentId)
      .replace('demoReferralServiceId', demoReferralServiceId);
    window.open(replacedLink, '_blank');
    history.push(`/my_trainings`);
  };

  const handleMarkComplete = async (row) => {
    await axios.post(`/training_logs/`, { training_id: row.id, status: 'Complete' });
    history.push(`/my_trainings`);
  };

  const columns: IDataTableColumn<any>[] = [
    {
      name: 'Name',
      selector: 'name',
      sortable: true,
      grow: 1,
    },
    {
      name: 'Description',
      selector: 'description',
      sortable: true,
      grow: 1,
    },
    {
      name: 'Status',
      selector: 'overall_status',
      sortable: true,
      grow: 0.8,
      cell: (row: Record<string, string>) => (
        <div
          style={{
            fontWeight: 700,
            textTransform: 'uppercase',
            color: statusColors[row.overall_status],
          }}
        >
          {row.overall_status}
        </div>
      ),
    },
    {
      name: '',
      selector: 'content_link',
      style: {
        textAlign: 'right',
        display: 'inline-block',
      },
      allowOverflow: true,
      cell: (row) => (
        <div
          style={{
            width: '100%',
            paddingRight: 30,
          }}
        >
          <Button
            variant="contained"
            color="secondary"
            style={{ float: 'right', marginLeft: 15 }}
            onClick={() => handleMarkComplete(row)}
            disabled={row.overall_status !== 'Started'}
          >
            Mark Complete
          </Button>
          <Button
            variant="contained"
            color="primary"
            style={{ float: 'right' }}
            onClick={() => handleViewTraining(row)}
            disabled={blockNewTraining}
          >
            {row?.overall_status === 'Complete' ? 'Restart Training' : 'View Training'}
          </Button>
        </div>
      ),
    },
  ];

  const fetchTrainings = async () => {
    setTrainingLoading(true);
    const response = await axios.get<string, any>(`/my_trainings`);
    setTrainings(response.data?.result);
    setDemoStudentId(response?.data?.demo_student?.id);
    setDemoReferralServiceId(response?.data?.demo_referral_service?.id);
    if (response?.data?.training_engaged === null) {
      setBlockNewTraining(false);
    } else {
      setBlockNewTraining(true);
    }
    setTrainingLoading(false);
  };

  useEffect(() => {
    fetchTrainings();
  }, []);

  return trainingLoading ? (
    <LoadingSpinner />
  ) : (
    <Box>
      <TrainingFormModal />
      <Grid justify="space-between" container spacing={3}>
        <Grid item>
          <Typography variant="h1" component="h1">
            My Trainings
          </Typography>
        </Grid>
      </Grid>
      <br />
      <Card>
        <CardContent>
          <DataTable noHeader columns={columns} data={trainings} striped highlightOnHover pointerOnHover />
        </CardContent>
      </Card>
    </Box>
  );
};

export default MyTrainingsPage;
