import React, { FunctionComponent } from 'react';
import { Box, Grid, Typography, makeStyles } from '@material-ui/core';
import ConfirmCancelModal from '../../../shared/components/modal/ConfirmCancelModal';
import TextInput from '../../../shared/components/form/TextInput';
import { gradeLevelStore } from '../../../shared/singletons';
import { useStoreObservable } from '../../../shared/state/useStoreObservable.hook';

const useStyles = makeStyles({
  textInput: {
    height: 'auto',
    width: '98%',
    margin: '14px 0',
  },
  baseError: {
    marginBottom: '10px',
  },
});

const EditGradeLevelModal: FunctionComponent = () => {
  const { editModalOpen, formValues, gradeLevel } = useStoreObservable(gradeLevelStore);
  const classes = useStyles();

  const handleFormValueChange = (key: string, value: string) => {
    gradeLevelStore.setState({ formValues: { ...formValues, [key]: value } });
  };

  const handleFormSubmit = () => {
    gradeLevelStore.updateGradeLevel(formValues, gradeLevel);
  };

  return (
    <ConfirmCancelModal
      title="Edit Grade Level"
      cancelLabel="Cancel"
      confirmLabel="Update Grade Level"
      width="768px"
      hasCancelButton
      hasConfirmButton
      isOpen={editModalOpen}
      onConfirm={handleFormSubmit}
      openStatusChanged={(isOpen) => gradeLevelStore.setState({ editModalOpen: isOpen })}
    >
      <Box p={2}>
        <Typography variant="h2">Grade Level Details</Typography>
        <Grid container spacing={3}>
          <Grid item xs={10}>
            <TextInput
              styleOverrides={classes.textInput}
              label="Grade Level Name"
              value={formValues?.name}
              valueChanged={(value: string) => handleFormValueChange('name', value)}
            />
          </Grid>
        </Grid>
        <br />
      </Box>
    </ConfirmCancelModal>
  );
};

export default EditGradeLevelModal;
