export const allPossibleFields = [
  { label: 'Date of Service', value: 'date_of_service' },
  { label: 'Status', value: 'status' },
  { label: 'Related Service', value: 'related_service' },
  { label: 'Therapist Name', value: 'therapist_name' },
  { label: 'Student Name', value: 'student_name' },
  { label: 'Related Service Units', value: 'related_service_units' },
  { label: 'Related Service Unit Price', value: 'related_service_unit_price' },
  { label: 'Related Service Unit Total', value: 'related_service_unit_total' },
  { label: 'Billing Date', value: 'billing_date' },
  { label: 'Original Date', value: 'original_date' },
  { label: 'Start Time', value: 'start_time' },
  { label: 'End Time', value: 'end_time' },
  { label: 'Timeline ID', value: 'student_id' },
  { label: 'School-Issued ID', value: 'school_issued_id' },
  { label: 'Grade Level', value: 'grade_level' },
  { label: 'Building', value: 'building' },
  { label: 'Mileage', value: 'mileage' },
  { label: 'Mileage Cost', value: 'mileage_cost' },
  { label: 'Indirect Units', value: 'indirect_units' },
  { label: 'Indirect Unit Total', value: 'indirect_unit_total' },
  { label: 'Indirect and Related Service Total', value: 'indirect_and_related_service_total' },
];
