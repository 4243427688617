import React, { FunctionComponent } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Grid, Button } from '@material-ui/core';
import TextInput from '../../shared/components/form/TextInput';
import { axios } from '../../shared/singletons';
import { API_ENDPOINT } from '../../../config/env';
import { Student, StudentFormValues } from '../../students/model';

const useStyles = makeStyles({
  textInput: {
    height: '60px',
    width: '98%',
    margin: '10px 0',
  },
  baseError: {
    marginBottom: '10px',
  },
});

interface PrimaryGuardianAccordionProps {
  student: Student;
  formValues: StudentFormValues;
  setFormValues: any;
}

const PrimaryGuardianAccordion: FunctionComponent<PrimaryGuardianAccordionProps> = (props) => {
  const { student, formValues, setFormValues } = props;
  const classes = useStyles();
  const [formErrors, setFormErrors] = React.useState<any>({});
  const [expanded, setExpanded] = React.useState<boolean>(true);

  const handleSubmit = () => {
    axios({
      method: 'put',
      url: `${API_ENDPOINT}/patients/${student.id}.json`,
      data: { patient: formValues },
      headers: { 'Content-Type': 'application/json' },
    })
      .then(() => {
        setExpanded(false);
      })
      .catch((error) => {
        setFormErrors(error.response?.data);
      });
  };

  return (
    <Accordion expanded={expanded}>
      <AccordionSummary onClick={() => setExpanded(!expanded)} expandIcon="▼">
        <Typography variant="h2" component="h2">
          Primary Guardian (Optional)
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container>
          <Grid item xs={12}>
            <form noValidate autoComplete="off">
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <TextInput
                    styleOverrides={classes.textInput}
                    type="text"
                    label="Full Name"
                    errorMessage={formErrors?.gaurdian_name}
                    value={formValues?.gaurdian_name}
                    valueChanged={(value) => setFormValues({ ...formValues, gaurdian_name: value })}
                    shrinkLabel={formValues?.gaurdian_name !== null}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    styleOverrides={classes.textInput}
                    type="text"
                    label="Cell Phone"
                    errorMessage={formErrors?.gaurdian_contact_primary}
                    value={formValues?.gaurdian_contact_primary}
                    valueChanged={(value) => setFormValues({ ...formValues, gaurdian_contact_primary: value })}
                    shrinkLabel={formValues?.gaurdian_contact_primary !== null}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    styleOverrides={classes.textInput}
                    type="text"
                    label="Home Phone"
                    errorMessage={formErrors?.gaurdian_contact_secondary}
                    value={formValues?.gaurdian_contact_secondary}
                    valueChanged={(value) => setFormValues({ ...formValues, gaurdian_contact_secondary: value })}
                    shrinkLabel={formValues?.gaurdian_contact_secondary !== null}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    styleOverrides={classes.textInput}
                    type="text"
                    label="Alternate Phone"
                    errorMessage={formErrors?.gaurdian_contact_alternate}
                    value={formValues?.gaurdian_contact_alternate}
                    valueChanged={(value) => setFormValues({ ...formValues, gaurdian_contact_alternate: value })}
                    shrinkLabel={formValues?.gaurdian_contact_alternate !== null}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    styleOverrides={classes.textInput}
                    type="text"
                    label="Email"
                    errorMessage={formErrors?.email_gaurdian}
                    value={formValues?.email_gaurdian}
                    valueChanged={(value) => setFormValues({ ...formValues, email_gaurdian: value })}
                    shrinkLabel={formValues?.email_gaurdian !== null}
                  />
                </Grid>
              </Grid>
              <br />
            </form>
          </Grid>
          <Grid>
            <Button variant="contained" color="primary" onClick={() => handleSubmit()}>
              Save Primary Guardian Info
            </Button>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default PrimaryGuardianAccordion;
