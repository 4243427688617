import React, { useState, useEffect, useContext } from 'react';
import { Grid, Typography, Button } from '@material-ui/core';
import ConfirmCancelModal from '../../shared/components/modal/ConfirmCancelModal';
import { AppointmentsContext } from '../contexts/AppointmentsContext';
import TextInput from '../../shared/components/form/TextInput';

type YearlongAppointmentCreateModalProps = {};

function YearlongAppointmentCreateModal(props: YearlongAppointmentCreateModalProps) {
  const {
    appointmentYear,
    setAppointmentYear,
    createModalDate,
    createModalOpen,
    setCreateModalOpen,
    appointmentFormValues,
  } = useContext(AppointmentsContext);

  const [dateForDisplay, setDateForDisplay] = useState('');
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [showConfirmButton, setShowConfirmButton] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');

  const quarter = appointmentYear?.findIndex((quarter) => {
    const lastAppointment = quarter[quarter.length - 1];

    const lastAppointmentDate = new Date(lastAppointment.start);
    const createModalDateDate = new Date(createModalDate);

    return createModalDateDate.getTime() <= lastAppointmentDate.getTime();
  });

  useEffect(() => {
    const startTimeString = appointmentFormValues?.start_time;
    setStartTime(startTimeString);

    const [startHours, startMinutes] = startTimeString.split(':').map(Number);
    const endTimeDate = new Date();
    endTimeDate.setHours(startHours, startMinutes);
    endTimeDate.setMinutes(endTimeDate.getMinutes() + 30);

    const endTimeString = endTimeDate.toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    });

    setEndTime(endTimeString);
  }, [appointmentFormValues]);

  useEffect(() => {
    if (createModalDate) {
      const date = new Date(createModalDate);

      // Format for display
      const formattedDateForDisplay = date.toLocaleDateString('en-US', {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      });

      setDateForDisplay(formattedDateForDisplay);
    }
  }, [createModalDate, appointmentYear]);

  useEffect(() => {
    if (endTime <= startTime) {
      setShowConfirmButton(false);
      setErrorMessage('End time must be after start time');
    } else {
      setShowConfirmButton(true);
      setErrorMessage('');
    }
  }, [startTime, endTime]);

  const format12HourTime = (time24) => {
    const [hour, minute] = time24.split(':');
    const hourNum = parseInt(hour, 10);
    const amPm = hourNum >= 12 ? 'PM' : 'AM';
    const formattedHour = hourNum % 12 || 12; // Convert 00 to 12 for 12 AM
    return `${formattedHour.toString().padStart(2, '0')}:${minute} ${amPm}`;
  };

  const formatDateForSaving = (time) => {
    const inputDate = new Date(dateForDisplay);
    const formattedTimeForSaving = format12HourTime(time);

    const timeZoneOffset = inputDate.getTimezoneOffset() * 60000; // Convert offset to milliseconds
    const adjustedDate = new Date(inputDate.getTime() + timeZoneOffset);
    const weekday = adjustedDate.toLocaleDateString('en-US', { weekday: 'long' });

    return `${weekday}, ${
      adjustedDate.getMonth() + 1
    }-${adjustedDate.getDate()}-${adjustedDate.getFullYear()} ${formattedTimeForSaving}`;
  };

  const formatListFriendlyDate = () => {
    const inputDate = new Date(dateForDisplay);
    const timeZoneOffset = inputDate.getTimezoneOffset() * 60000; // Convert offset to milliseconds
    const adjustedDate = new Date(inputDate.getTime() + timeZoneOffset);

    const shortWeekday = adjustedDate.toLocaleDateString('en-US', { weekday: 'short' });
    const year = inputDate.getUTCFullYear();
    const month = inputDate.getUTCMonth() + 1;
    const day = inputDate.getUTCDate();

    return `${shortWeekday}, ${month.toString().padStart(2, '0')}-${day}-${year}`;
  };

  const createNewAppointment = () => {
    const formattedStartDate = formatDateForSaving(startTime);
    const formattedEndDate = formatDateForSaving(endTime);

    const startMillis = new Date(formattedStartDate).getTime();
    const endMillis = new Date(formattedEndDate).getTime();
    const newDuration = (endMillis - startMillis) / 60000;
    const newTitle = `${newDuration} min`;

    return {
      title: newTitle,
      duration: newDuration,
      start: formattedStartDate,
      end: formattedEndDate,
      list_friendly_date: formatListFriendlyDate(),
      color: 'blue',
      extendedProps: {},
    };
  };

  const handleAddAppointment = () => {
    const updatedAppointments = [...appointmentYear];
    const newAppointment = createNewAppointment();

    updatedAppointments[quarter].push(newAppointment);
    // Sort the quarter so that the new appointment is in the correct place
    updatedAppointments[quarter].sort((a, b) => {
      const aDate = new Date(a.start);
      const bDate = new Date(b.start);

      return aDate.getTime() - bDate.getTime();
    });

    setAppointmentYear(updatedAppointments);
    setCreateModalOpen(false);
  };

  const buttonEndTime = (minutes) => {
    // To convert to a format where we can add minutes, we need to convert to milliseconds
    // Use a fixed date (e.g., today's date) to combine it with the start time
    const today = new Date();
    const dateString = today.toISOString().split('T')[0];
    const dateTimeString = `${dateString} ${startTime}`;
    const startTimeDate = new Date(dateTimeString);
    const endTimeMillis = startTimeDate.getTime() + minutes * 60 * 1000;

    // Convert back for timepicker
    const newEndTime = new Date(endTimeMillis);
    const formattedEndTime = newEndTime.toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    });

    setEndTime(formattedEndTime);
  };

  return (
    <Grid style={{ zIndex: 1000, position: 'fixed' }}>
      <ConfirmCancelModal
        title="Add Appointment"
        cancelLabel="Cancel"
        confirmLabel="Add"
        width="768px"
        hasCancelButton
        hasConfirmButton={showConfirmButton}
        isOpen={createModalOpen}
        height="500px"
        onConfirm={handleAddAppointment}
        openStatusChanged={(isOpen) => setCreateModalOpen(isOpen)}
      >
        <Grid container spacing={6}>
          <Grid item xs={12} style={{ marginTop: '30px', display: 'flex', justifyContent: 'center' }}>
            <Typography variant="h2">{dateForDisplay}</Typography>
          </Grid>
          <Grid item xs={6}>
            <TextInput
              value={startTime}
              type="time"
              shrinkLabel
              label="Start Time"
              valueChanged={(value: string) => {
                setStartTime(value);
              }}
              inputProps={{ step: 300 }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextInput
              value={endTime}
              type="time"
              shrinkLabel
              label="End Time"
              valueChanged={(value: string) => {
                setEndTime(value);
              }}
              inputProps={{ step: 300 }}
            />
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" color="primary" onClick={() => buttonEndTime(15)}>
              15 min
            </Button>
            &emsp;
            <Button variant="contained" color="primary" onClick={() => buttonEndTime(30)}>
              30 min
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" style={{ color: 'red' }}>
              {errorMessage}
            </Typography>
          </Grid>
        </Grid>
      </ConfirmCancelModal>
    </Grid>
  );
}

export default YearlongAppointmentCreateModal;
