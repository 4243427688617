/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext, FunctionComponent } from 'react';
import axios from '../../shared/utils/axios.utils';
import $ from 'jquery';
import 'jquery-ui-bundle';
import DataTable from '../../shared/components/DataTable';
import { IDataTableColumn } from '../../shared/components/DataTable/DataTable/model';
import LoadingSpinner from '../../shared/components/LoadingSpinner';
import {
  Box,
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core';
import TrainingFormModal from '../components/TrainingFormModal';
import TrainingRowMenu from '../components/TrainingRowMenu';
import { TrainingsContext } from '../contexts/TrainingsContext';
import { nameConversions } from '../constants/constants';
import DeleteTrainingModal from '../components/DeleteTrainingModal';

export type TrainingsListPageProps = Record<string, unknown>;

const TrainingsListPage: FunctionComponent<TrainingsListPageProps> = () => {
  const [trainings, setTrainings] = useState([]);
  const [archivedTrainings, setArchivedTrainings] = useState([]);
  const { trainingLoading, setTrainingLoading, setTrainingFormModalOpen } = useContext(TrainingsContext);
  const [isSorting, setIsSorting] = useState(false);

  const columns: IDataTableColumn<any>[] = [
    {
      name: 'Name',
      selector: 'name',
      sortable: true,
      cell: (row) => (
        <a href={`manage_trainings/${row.id}`} rel="noopener noreferrer">
          {row.name}
        </a>
      ),
    },
    {
      name: 'Description',
      selector: 'description',
      sortable: true,
    },
    {
      name: 'Roles',
      selector: 'role_names',
      cell: (row) => <div>{row.role_names.map((role) => nameConversions[role]).join(', ')}</div>,
    },
    {
      name: 'Content Link',
      selector: 'content_link',
      cell: (row) => (
        <>
          {row.content_link && (
            <a href={row.content_link} target="_blank" rel="noopener noreferrer">
              View Training
            </a>
          )}
        </>
      ),
    },
    {
      cell: (training) => <TrainingRowMenu training={training} />,
      name: 'ACTIONS',
      allowOverflow: true,
      button: true,
      selector: 'actions',
    },
  ];

  const fetchTrainings = async () => {
    setTrainingLoading(true);
    const response = await axios.get<string, any>(`/manage_trainings`);
    const trainings = response.data?.result;
    setTrainings(trainings.filter((training) => !training.archived));
    setArchivedTrainings(trainings.filter((training) => training.archived));
    setTrainingLoading(false);
  };

  const toggleSorting = (): void => {
    if (isSorting) {
      axios.post('manage_trainings/sort.json', {
        sorting: $('.rdt_TableBody:first').sortable('serialize'),
        headers: { 'Content-Type': 'application/json' },
      });
      $('.rdt_TableBody:first').sortable('destroy');
    } else {
      $('.rdt_TableBody:first').sortable();
    }

    setIsSorting(!isSorting);
  };

  useEffect(() => {
    fetchTrainings();
  }, []);

  return trainingLoading ? (
    <LoadingSpinner />
  ) : (
    <Box>
      <TrainingFormModal />
      <DeleteTrainingModal />
      <Grid justify="space-between" container spacing={3}>
        <Grid item>
          <Typography variant="h1" component="h1">
            Trainings
          </Typography>
        </Grid>
        <Grid item>
          <Button variant="contained" color="primary" onClick={() => setTrainingFormModalOpen(true)}>
            Create New Training +
          </Button>
          &emsp;
          <Button variant="contained" color="primary" onClick={toggleSorting}>
            {isSorting ? 'Save Order' : 'Sort Items'}
          </Button>
        </Grid>
      </Grid>
      <br />
      <Card>
        <CardContent>
          <DataTable noHeader columns={columns} data={trainings} striped highlightOnHover pointerOnHover />
        </CardContent>
      </Card>
      <br />
      <Accordion>
        <AccordionSummary>
          <Typography variant="h3" component="h3">
            Archived Trainings
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <DataTable noHeader columns={columns} data={archivedTrainings} striped highlightOnHover pointerOnHover />
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default TrainingsListPage;
