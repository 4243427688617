import { SelectOption } from '../shared/common.model';
import { School } from '../schools/model';
import { Service } from '../services/model';
import { HistoryItem } from '../shared/common.model';

export interface UserRouteParams {
  userId: string;
  tab: string;
}

export interface AllUsersData {
  result?: User[] | null;
}

export interface UserData {
  result: User;
}

export interface User {
  id?: string;
  email?: string;
  roles?: string;
  name?: string;
  supervisor?: string;
  supervisor_names?: string;
  supervisor_emails?: string;
  school?: string;
  status?: string;
  username?: string;
  first_name?: string;
  last_name?: string;
  supervisor_id?: string;
  password?: string;
  password_confirmation?: string;
  formatted_permission_role?: string;
  payer_ids?: string[];
  role_ids?: string[];
  services?: Service[];
  schools?: School[];
  school_choices?: SelectOption[];
  training_logs?: Record<string, string>[];
  inactivated_at?: string;
}

export interface UserResponse {
  data: {
    result: User;
  };
}

export interface UserFormValues {
  email?: string;
  username?: string;
  first_name?: string;
  last_name?: string;
  supervisor_id?: string;
  password?: string;
  password_confirmation?: string;
  payer_ids?: string[];
  role_ids?: string[];
  inactivated_at?: string;
}

export interface UserFormErrors extends UserFormValues {
  base?: string;
  supervisor?: string;
}

export type UserChoicesResponse = {
  data: {
    result: {
      supervisors: SelectOption[];
      schools: SelectOption[];
      roles: Record<string, string>;
    };
  };
};

export interface TransferServicesFormValues {
  school?: '';
  owner?: string;
}

export interface ProductivityDetail {
  id: string;
  expected_units_monday: string;
  expected_units_tuesday: string;
  expected_units_wednesday: string;
  expected_units_thursday: string;
  expected_units_friday: string;
  expected_units_saturday: string;
  expected_units_sunday: string;
  alert_threshold: string;
  history: HistoryItem[];
}

export const blankProductivityDetail = {
  id: '',
  expected_units_monday: '',
  expected_units_tuesday: '',
  expected_units_wednesday: '',
  expected_units_thursday: '',
  expected_units_friday: '',
  expected_units_saturday: '',
  expected_units_sunday: '',
  alert_threshold: '',
  history: [],
};

export interface TrainingLog {
  id: string;
  user_name: string;
  status: string;
  training_name: string;
  created_at: string;
  completed_at: string;
}
