import React, { FunctionComponent } from 'react';
import ConfirmCancelModal from '../../shared/components/modal/ConfirmCancelModal';
import DuplicateAppointmentsForm from './DuplicateAppointmentsForm';
import { axios } from '../../shared/singletons';
import { DuplicateAppointmentsContext } from '../contexts/DuplicateAppointmentsContext';

interface DuplicateAppointmentsModalProps {
  afterAction: () => void;
}

const DuplicateAppointmentsModal: FunctionComponent<DuplicateAppointmentsModalProps> = () => {
  const [isCreating, setCreating] = React.useState<boolean>(false);

  const {
    duplicateAppointmentsModalOpen,
    setDuplicateAppointmentsModalOpen,
    formValues,
    fetchRecord,
    appointmentScheduler,
  } = React.useContext(DuplicateAppointmentsContext);

  const handleSubmit = async () => {
    setCreating(true);

    await axios.post<string, any>(
      `users/${formValues.owner_id}/appointment_scheduler.json?user_id=${appointmentScheduler.owner_id}`,
      {
        appointment: { ...formValues, owner_array: ['', formValues.owner_id.toString()] },
        headers: { 'Content-Type': 'application/json' },
      },
    );

    await fetchRecord(appointmentScheduler.services[0].initial_owner_id, appointmentScheduler.id);
    setCreating(false);
    setDuplicateAppointmentsModalOpen(false);
  };

  return (
    <ConfirmCancelModal
      isOpen={duplicateAppointmentsModalOpen}
      title="Duplicate Appointments"
      confirmLabel="Submit"
      openStatusChanged={(isOpen) => setDuplicateAppointmentsModalOpen(isOpen)}
      confirmDisabled={isCreating}
      onConfirm={handleSubmit}
    >
      <DuplicateAppointmentsForm />
    </ConfirmCancelModal>
  );
};

export default DuplicateAppointmentsModal;
