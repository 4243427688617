import React, { FunctionComponent, useContext } from 'react';
// import { makeStyles } from '@material-ui/core/styles';
import { Card, CardContent, Grid, Typography, IconButton } from '@material-ui/core';
import { Comment } from '../model';
import { Edit } from '@material-ui/icons';
import { AppointmentsContext } from '../contexts/AppointmentsContext';
import { UserContext } from '../../auth/contexts/userContext';

interface CommentsListProps {
  mostRecentComment: Comment;
}

const AppointmentCommentsList: FunctionComponent<CommentsListProps> = (props) => {
  const { mostRecentComment } = props;
  const { setCommentToEdit, setEditModalOpen } = useContext(AppointmentsContext);
  const { currentUserHasAnyRole } = useContext(UserContext);

  return (
    <Card>
      <CardContent>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            {mostRecentComment ? (
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography align="left">
                  {mostRecentComment.created_by && (
                    <strong>{`${mostRecentComment.created_by} at ${mostRecentComment.created_at}: `}</strong>
                  )}
                  {mostRecentComment.content}
                </Typography>
                {currentUserHasAnyRole(['admin', 'system_owner']) && (
                  <IconButton
                    onClick={() => {
                      setCommentToEdit(mostRecentComment);
                      setEditModalOpen(true);
                    }}
                  >
                    <Edit />
                  </IconButton>
                )}
              </div>
            ) : (
              <Typography align="left">No comments on this appointment.</Typography>
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default AppointmentCommentsList;
