import React from 'react';
import { Button, Grid, Typography } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import InformationField from '../../shared/components/InformationField';
import { DischargeStudentContext } from '../contexts/DischargeStudentContext';
import DischargeServiceCard from '../components/DischargeServiceCard';
import DischargeRenewalCard from '../components/DischargeRenewalCard';

const StudentDischargePage: React.FunctionComponent = () => {
  const { studentId } = useParams<Record<string, string>>();
  const [studentFetched, setStudentFetched] = React.useState(false);
  const { fetchStudentActiveServices, student, studentLoading, dischargeStudent } = React.useContext(
    DischargeStudentContext,
  );

  React.useEffect(() => {
    if (!studentFetched) {
      fetchStudentActiveServices(studentId);
      setStudentFetched(true);
    }
  }, [studentFetched, setStudentFetched, fetchStudentActiveServices, studentId]);

  const handleDischargeStudent = () => {
    dischargeStudent(student.id);
  };

  return studentLoading ? (
    <p>Loading...</p>
  ) : (
    <>
      <h1 className="MuiTypography-root MuiTypography-h1">{`Discharge ${student.first_name} ${student.last_name}`}</h1>
      <Grid container flex-direction="column">
        <Grid item xs={3}>
          <InformationField label="Timeline ID" value={student.slug} />
        </Grid>
        <Grid item xs={3}>
          <InformationField label="Student Name" value={`${student.first_name} ${student.last_name}`} />
        </Grid>
        <Grid item xs={3}>
          <InformationField label="School Name" value={student.school} />
        </Grid>
      </Grid>
      <br />
      {student?.active_services?.length > 0 && (
        <>
          <h1 className="MuiTypography-root MuiTypography-h1">Active Students</h1>
          <div style={{ marginTop: '20px' }}>
            {student?.active_services?.map((service) => (
              <>
                <DischargeServiceCard service={service} serviceLoading={false} />
                <br />
              </>
            ))}
          </div>
        </>
      )}
      {student?.active_renewals?.length > 0 && (
        <>
          <Typography variant="h1" component="h1">
            Active Renewals
          </Typography>
          <div style={{ marginTop: '20px' }}>
            {student?.active_renewals?.map((renewal) => (
              <>
                <DischargeRenewalCard renewal={renewal} renewalLoading={false} />
                <br />
              </>
            ))}
          </div>
        </>
      )}
      <Button
        variant="contained"
        style={{ backgroundColor: '#F44F64' }}
        color="primary"
        disabled={!(student?.active_renewals?.length === 0 && student?.active_services?.length === 0)}
        onClick={() => handleDischargeStudent()}
      >
        Discharge Student
      </Button>
    </>
  );
};

export default StudentDischargePage;
