import React, { FunctionComponent } from 'react';
import { Box, Button, Card, CardContent, Grid, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import UserFormModal from './UserFormModal';
import DataTable from '../../shared/components/DataTable';
import { IDataTableColumn } from '../../shared/components/DataTable/DataTable/model';
import { User } from '../model';
import { userRecordStore } from '../../shared/singletons';
import FilterBar, { FilterProps } from '../../shared/components/FilterBar';
import { Colors } from '../../shared/style/colors';
import { UserContext } from '../../auth/contexts/userContext';
import { SelectOption } from '../../shared/common.model';

const statusChoices = [
  { label: 'Active', value: '1' },
  { label: 'Inactive', value: '0' },
];

// TODO: These role choices should be fetched from the backend

const roleChoices = [
  { label: 'Accountant', value: 'accountant' },
  { label: 'School District', value: 'payer_admin' },
  { label: 'Service Provider', value: 'therapist' },
  { label: 'Services Coordinator', value: 'services_coordinator' },
  { label: 'Supervisor', value: 'supervisor' },
  { label: 'System Administrator', value: 'admin' },
  { label: 'System Owner', value: 'system_owner' },
];

const columns: IDataTableColumn<User>[] = [
  {
    name: 'USER NAME',
    selector: 'last_name_first_name',
    grow: 0.8,
    sortable: true,
  },
  {
    name: 'ROLE',
    selector: 'roles',
    sortable: true,
    cell: (row) => <div>{row.formatted_permission_role}</div>,
  },
  {
    name: 'SUPERVISOR',
    selector: 'supervisor',
    sortable: true,
  },
  {
    name: 'STATUS',
    selector: 'status',
    sortable: true,
    grow: 0.5,
    cell: (row) => (
      <div
        style={{
          fontWeight: 700,
          textTransform: 'uppercase',
          color: row.status === 'active' ? Colors.Success : Colors.Red,
        }}
      >
        {row.status}
      </div>
    ),
  },
];

type UsersListProps = {
  records: User[];
  recordsLoading: boolean;
  showFilterBar: boolean;
  totalRecords?: number;
  supervisors: SelectOption[];
};

const UsersList: FunctionComponent<UsersListProps> = (props) => {
  const {
    records = [],
    recordsLoading = false,
    showFilterBar = false,
    totalRecords = records?.length || 0,
    supervisors = [],
  } = props;
  const history = useHistory();
  const [recordsPerPage, setRecordsPerPage] = React.useState(10);
  const { currentUserHasRole } = React.useContext(UserContext);

  const handlePageChange = (page: number) => {
    const filterParams = userRecordStore.formattedFilterParams();
    userRecordStore.fetchRecords(filterParams, page, recordsPerPage);
  };

  const handlePerRowsChange = async (newPerPage: number, page: number) => {
    setRecordsPerPage(newPerPage);
    const filterParams = userRecordStore.formattedFilterParams();
    userRecordStore.fetchRecords(filterParams, page, newPerPage);
  };

  const filters: FilterProps[] = [
    {
      selector: 'ransack[first_name_i_cont]',
      label: 'User First Name',
      type: 'text',
    },
    {
      selector: 'ransack[last_name_i_cont]',
      label: 'User Last Name',
      type: 'text',
    },
    {
      selector: 'ransack[supervisor_id_eq]',
      label: 'Supervisor',
      type: 'text',
      options: supervisors,
    },
    {
      selector: 'ransack[roles_name_eq]',
      label: 'Role',
      type: 'text',
      options: roleChoices,
    },
    {
      selector: 'ransack[inactivated_at_null]',
      label: 'Status',
      type: 'text',
      options: statusChoices,
    },
  ];

  const handleColumnSort = async (column: Record<string, string>, sortDirection: string) => {
    const columnNamesToRansack = {
      name: 'name',
      roles: 'roles',
      supervisor: 'supervisor_last_name',
      school: 'payer_name',
      email: 'email',
      status: 'status',
      actions: 'actions',
    };

    userRecordStore.setFilterValues({
      ...userRecordStore.getState().filterValues,
      sort: `${columnNamesToRansack[column.selector]} ${sortDirection}`,
    });

    const filterParams = userRecordStore.formattedFilterParams();
    userRecordStore.fetchRecords(filterParams, 1, recordsPerPage);
  };

  return (
    <Box>
      <UserFormModal />
      <Grid justify="space-between" container spacing={3}>
        <Grid item>
          <Typography variant="h1" component="h1">
            Users
          </Typography>
        </Grid>
        <Grid item>
          {currentUserHasRole('admin') && (
            <Button variant="contained" color="primary" onClick={() => history.push(`/users/new`)}>
              Create New User +
            </Button>
          )}
        </Grid>
        {showFilterBar && (
          <Grid item xs={12}>
            <FilterBar filters={filters} baseStore={userRecordStore} onFilter={() => userRecordStore.fetchRecords()} />
          </Grid>
        )}
      </Grid>
      <br />

      <Card>
        <CardContent>
          <DataTable
            title=""
            columns={columns}
            noHeader
            data={records}
            progressPending={recordsLoading}
            striped
            onRowClicked={(user: User) => history.push(`/users/${user.id}`)}
            highlightOnHover
            pointerOnHover
            pagination
            paginationServer
            paginationTotalRows={totalRecords}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            sortServer
            onSort={handleColumnSort}
          />
        </CardContent>
      </Card>
    </Box>
  );
};

export default UsersList;
