import { format, sub, startOfMonth, endOfMonth } from 'date-fns';

export const oneWeekAgo = () => {
  const today = new Date();
  const beginningOfLastWeek = new Date(today);

  beginningOfLastWeek.setDate(today.getDate() - 7);

  return format(beginningOfLastWeek, 'yyyy-MM-dd');
};

export const oneMonthAgo = () => {
  const today = new Date();
  const lastMonth = new Date(today);

  lastMonth.setDate(today.getDate() - 30);

  return format(lastMonth, 'yyyy-MM-dd');
};

export const today = () => {
  return format(new Date(), 'yyyy-MM-dd');
};

export const oneYearFromNow = () => {
  const date = new Date();
  date.setFullYear(date.getFullYear() + 1);
  return format(date, 'yyyy-MM-dd');
};

export const yesterday = () => {
  const yesterdayDate = sub(new Date(), { days: 1 });
  return format(yesterdayDate, 'yyyy-MM-dd');
};

export const lastSchoolYear = () => {
  // 2023-08-14
  const lastYearDate = sub(new Date(), { days: 226 });
  return format(lastYearDate, 'yyyy') + '-08-14';
};

export const beginningOfLastMonth = () => {
  const lastMonthDate = startOfMonth(sub(new Date(), { months: 1 }));
  return format(lastMonthDate, 'yyyy-MM-dd');
};

export const endOfLastMonth = () => {
  const lastMonthDate = endOfMonth(sub(new Date(), { months: 1 }));
  return format(lastMonthDate, 'yyyy-MM-dd');
};

export const stringToDayOfWeek = (dateString: string) => {
  const formattedDate = new Date(dateString);
  const weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const dayOfWeek = weekdays[formattedDate.getDay()];
  const dd = String(formattedDate.getDate()).padStart(2, '0');
  const mmmm = monthNames[formattedDate.getMonth()]; //January is 0!
  const yyyy = formattedDate.getFullYear();

  return `${dayOfWeek} ${mmmm} ${dd}, ${yyyy}`;
};

export const formatDate = (dateString) => {
  if (!dateString) return '';

  const [year, month, day] = dateString.split('-').map(Number);

  const date = new Date(Date.UTC(year, month - 1, day));

  const formattedMonth = String(date.getUTCMonth() + 1).padStart(2, '0');
  const formattedDay = String(date.getUTCDate()).padStart(2, '0');
  const formattedYear = date.getUTCFullYear();

  return `${formattedMonth}/${formattedDay}/${formattedYear}`;
};
