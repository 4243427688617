import React, { FunctionComponent } from 'react';
import { makeStyles } from '@material-ui/core';
// import TextInput from '../../shared/components/form/TextInput';
import SelectInput from '../../shared/components/form/SelectInput';
import { DuplicateAppointmentsContext } from '../contexts/DuplicateAppointmentsContext';
import { ServiceFormOptionsContext } from '../../services/contexts/ServiceFormOptionsContext';
import InformationField from '../../shared/components/InformationField';

const useStyles = makeStyles({
  textInput: {
    height: '60px',
    width: '98%',
    margin: '10px',
  },
  formTitle: {
    marginLeft: '12px',
  },
});

const DuplicateAppointmentsByRenewalDateForm: FunctionComponent = () => {
  const classes = useStyles();
  const { formValues, formErrors, setFormValues, appointmentScheduler } = React.useContext(
    DuplicateAppointmentsContext,
  );
  const { fetchChoices } = React.useContext(ServiceFormOptionsContext);

  const handleFormValueChange = (key: string, value: string) => {
    setFormValues({ ...formValues, [key]: value });
  };

  /* eslint-disable */
  React.useEffect(() => {
    fetchChoices();
  }, []);
  /* eslint-enable */

  return (
    <form noValidate autoComplete="off">
      <SelectInput
        value={formValues?.owner_id}
        styleOverrides={classes.textInput}
        type="text"
        label="New Owner"
        errorMessage={formErrors?.owner_id}
        valueChanged={(value: string) => handleFormValueChange('owner_id', value)}
        choices={appointmentScheduler.owner_choices}
        defaultChoice={{ value: '', label: 'None' }}
      />
      <SelectInput
        value={formValues?.location_id}
        styleOverrides={classes.textInput}
        type="text"
        label="New Location"
        errorMessage={formErrors?.location_id}
        valueChanged={(value: string) => handleFormValueChange('location_id', value)}
        choices={appointmentScheduler.location_choices}
        defaultChoice={{ value: '', label: 'None' }}
      />
      <SelectInput
        value={formValues?.primary_service_id}
        styleOverrides={classes.textInput}
        type="text"
        label="Primary Service"
        errorMessage={formErrors?.primary_service_id}
        valueChanged={(value: string) => handleFormValueChange('primary_service_id', value)}
        choices={appointmentScheduler.service_choices}
        defaultChoice={{ value: '', label: 'None' }}
      />
      {/* <TextInput
        value={formValues?.primary_service_renewal_date}
        styleOverrides={classes.textInput}
        type="date"
        label="Primary Service Renewal Date"
        errorMessage={formErrors?.primary_service_id}
        valueChanged={(value: string) => null}
      /> */}
      <InformationField label="Primary Service Renewal Date" value={formValues?.primary_service_renewal_date} />
      <br />
    </form>
  );
};

export default DuplicateAppointmentsByRenewalDateForm;
