import { axios } from '../shared/singletons';
import { BaseRecordStore } from '../shared/state/baseRecord.store';
import { School } from './model';

export class SchoolState {
  records: School[];
  school: School;
  recordsLoading: boolean;
  schoolLoading: boolean;
  filteredRecords: School[];
  totalRecords: number;

  filterValues: Record<string, string>;

  static create(props: Partial<SchoolState>): SchoolState {
    const defaults: SchoolState = {
      records: [],
      school: {},
      recordsLoading: false,
      schoolLoading: false,
      filteredRecords: null,
      totalRecords: 0,
      filterValues: {
        'ransack[discharged_eq]': 'false',
      },
    };
    return Object.assign(new SchoolState(), defaults, props || {});
  }
}

export class SchoolRecordStore extends BaseRecordStore<SchoolState> {
  constructor() {
    super(SchoolState.create(JSON.parse(sessionStorage.getItem('schoolState')) || {}));
  }

  public fetchRecords(params = this.formattedFilterParams(), page = 1, per = 10): void {
    this.setState({ recordsLoading: true });
    this.saveSchoolFilters();

    axios(`payers.json?${params}page=${page}&per=${per}`)
      .then((result) => {
        this.setState({ totalRecords: result?.data?.total_records });
        return result?.data?.result ?? [];
      })
      .then((records) => {
        this.setState({ records, recordsLoading: false });
      })
      .catch(() => {
        this.setState({ recordsLoading: false });
      });
  }

  public fetchRecord(schoolId: string, params = this.formattedFilterParams()): void {
    this.setState({ schoolLoading: true });

    axios(`payers/${schoolId}.json?${params}`)
      .then((result) => result?.data?.result ?? [])
      .then((school) => {
        this.setState({ school, schoolLoading: false });
      })
      .catch(() => {
        this.setState({ schoolLoading: false });
      });
  }

  public setFilterValues(filterValues: Record<string, string>): void {
    this.setState({ filterValues });
  }

  public resetFilterValues(): void {
    this.setState({ filterValues: {} });
  }

  public saveSchoolFilters(): void {
    const { filterValues } = this.getState();
    const savedSchoolState = {
      filterValues: {
        'ransack[name_i_cont]': filterValues['ransack[name_i_cont]'],
        'ransack[abbr_i_cont]': filterValues['ransack[abbr_i_cont]'],
      },
      loadListPageRecords: true,
    };

    sessionStorage.setItem('schoolState', JSON.stringify(savedSchoolState));
  }

  public formattedFilterParams(): string {
    const { filterValues } = this.getState();

    return Object?.keys(filterValues)?.reduce(
      (params, key, _index: number) => `${params}${key}=${filterValues[key]}&` || '',
      '',
    );
  }
}
