import React, { Dispatch, SetStateAction, useState } from 'react';
import { format, sub } from 'date-fns';
import { UserTicket, ReferralTicket } from '../model';
import { axios } from '../../shared/singletons';
import { today } from '../../shared/utils/date.utils';
import { SelectOption } from '../../shared/common.model';
import { AppointmentFormValues } from '../../appointments/model';

interface UserTicketsContextInterface {
  fetchUserTickets?: (params?: string) => Promise<void>;
  fetchReferralTickets?: (params?: string) => Promise<void>;

  updateUserTicket?: (userTicket: UserTicket, formValues: AppointmentFormValues) => Promise<void>;
  updateReferralTicket?: (referralTicket: ReferralTicket, formValues: Record<string, string>) => Promise<void>;

  userTickets?: UserTicket[];
  meetingCompleteTickets?: UserTicket[];
  referralTickets?: UserTicket[];
  userTicketsLoading?: boolean;
  referralTicketsLoading?: boolean;

  filterValues?: Record<string, string>;
  setFilterValues?: Dispatch<SetStateAction<Record<string, string>>>;

  ticketsDownload?: UserTicket[];

  schoolOptions?: SelectOption[];
  providerOptions?: SelectOption[];

  referralCompleteDialogOpen?: boolean;
  setReferralCompleteDialogOpen?: Dispatch<SetStateAction<boolean>>;

  approveTicketModalOpen?: boolean;
  setApproveTicketModalOpen?: Dispatch<SetStateAction<boolean>>;

  rejectTicketModalOpen?: boolean;
  setRejectTicketModalOpen?: Dispatch<SetStateAction<boolean>>;

  referralTicketModalOpen?: boolean;
  setReferralTicketModalOpen?: Dispatch<SetStateAction<boolean>>;

  ticketToEdit?: UserTicket;
  setTicketToEdit?: Dispatch<SetStateAction<UserTicket>>;

  referralToEdit?: ReferralTicket;
  setReferralToEdit?: Dispatch<SetStateAction<ReferralTicket>>;

  successDialogOpen?: boolean;
  setSuccessDialogOpen?: Dispatch<SetStateAction<boolean>>;
}

const UserTicketsContext = React.createContext<UserTicketsContextInterface>({});
const UserTicketsContextConsumer = UserTicketsContext.Consumer;

const UserTicketsContextProvider = ({ children }) => {
  const [userTickets, setUserTickets] = useState<UserTicket[]>([]);
  const [schoolOptions, setSchoolOptions] = useState<SelectOption[]>([]);
  const [providerOptions, setProviderOptions] = useState<SelectOption[]>([]);
  const [meetingCompleteTickets, setMeetingCompleteTickets] = useState<UserTicket[]>([]);
  const [referralTickets, setReferralTickets] = useState<UserTicket[]>([]);
  const [userTicketsLoading, setUserTicketsLoading] = useState<boolean>(false);
  const [referralTicketsLoading, setReferralTicketsLoading] = useState<boolean>(false);
  const [ticketsDownload, setTicketsDownload] = useState([]);
  const [referralCompleteDialogOpen, setReferralCompleteDialogOpen] = useState<boolean>(false);
  const [approveTicketModalOpen, setApproveTicketModalOpen] = useState<boolean>(false);
  const [rejectTicketModalOpen, setRejectTicketModalOpen] = useState<boolean>(false);
  const [referralTicketModalOpen, setReferralTicketModalOpen] = useState<boolean>(false);
  const [successDialogOpen, setSuccessDialogOpen] = useState<boolean>(false);
  const [ticketToEdit, setTicketToEdit] = useState<UserTicket>();
  const [referralToEdit, setReferralToEdit] = useState<ReferralTicket>();

  const lastYearDate = sub(new Date(), { days: 226 });
  const defaultTicketStartDate = format(lastYearDate, 'yyyy') + '-05-14';

  const initialFilterValues: Record<string, string> = {
    'ransack[created_at_gteq]': defaultTicketStartDate,
    'ransack[created_at_lteq]': today(),
  };

  const [filterValues, setFilterValues] = useState<Record<string, string>>(initialFilterValues);

  const fetchUserTickets = async (params: string) => {
    setUserTicketsLoading(true);

    const response = await axios.get(`change_requests?${params}`);
    setUserTickets(response.data.result.filter((ticket) => ticket.category !== 'meeting_completed'));
    setMeetingCompleteTickets(response.data.result.filter((ticket) => ticket.category === 'meeting_completed'));
    setTicketsDownload(response?.data?.tickets_download);
    setSchoolOptions(response.data.school_options);
    setProviderOptions(response.data.provider_options);
    setUserTicketsLoading(false);
  };

  const fetchReferralTickets = async (params: string) => {
    setReferralTicketsLoading(true);

    const response = await axios.get(`referral_tickets?${params}`);
    setReferralTickets(response.data.result);
    setReferralTicketsLoading(false);
  };

  const updateUserTicket = async (userTicket: UserTicket, formValues: Record<string, string>) => {
    axios
      .put(`change_requests/${userTicket.id}`, formValues)
      .then(() => {
        setSuccessDialogOpen(true);
      })
      .catch((error) => {
        console.error('Error rejecting change request:', error);
      });
  };

  const updateReferralTicket = async (referralTicket: ReferralTicket, formValues: Record<string, string>) => {
    axios
      .put(`referral_tickets/${referralTicket.id}`, { referral_ticket: formValues })
      .then(() => {
        setReferralCompleteDialogOpen(true);
      })
      .catch((error) => {
        console.error('Error rejecting change request:', error);
      });
  };

  return (
    <UserTicketsContext.Provider
      value={{
        fetchUserTickets,
        fetchReferralTickets,

        updateUserTicket,
        updateReferralTicket,

        userTickets,
        meetingCompleteTickets,
        referralTickets,
        userTicketsLoading,
        referralTicketsLoading,

        setFilterValues,
        filterValues,
        ticketsDownload,
        schoolOptions,
        providerOptions,

        referralCompleteDialogOpen,
        setReferralCompleteDialogOpen,

        approveTicketModalOpen,
        setApproveTicketModalOpen,

        rejectTicketModalOpen,
        setRejectTicketModalOpen,

        referralTicketModalOpen,
        setReferralTicketModalOpen,

        ticketToEdit,
        setTicketToEdit,

        referralToEdit,
        setReferralToEdit,

        successDialogOpen,
        setSuccessDialogOpen,
      }}
    >
      {children}
    </UserTicketsContext.Provider>
  );
};

export { UserTicketsContextProvider, UserTicketsContextConsumer, UserTicketsContext };
