import React, { FunctionComponent, useEffect, useState } from 'react';
import ListingServicesList from '../components/ListingServicesList';
import { ListingService } from '../model';
import { axios } from '../../../shared/singletons';

export type ListingServicesPageProps = Record<string, unknown>;

const ListingServicesPage: FunctionComponent<ListingServicesPageProps> = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [records, setRecords] = useState<ListingService[]>([]);

  useEffect(() => {
    axios.get('/listing_services.json').then((response) => {
      setRecords(response?.data?.result);
      setIsLoading(false);
    });
  }, []);

  return <ListingServicesList records={records} isLoading={isLoading} />;
};

export default ListingServicesPage;
