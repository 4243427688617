import { axios, alertStore } from '../../shared/singletons';
import { BaseStore } from '../../shared/state/base.store';
import { GradeLevel, GradeLevelFormValues } from './model';
import { API_ENDPOINT } from '../../../config/env';

export class GradeLevelState {
  records: GradeLevel[];
  gradeLevel: GradeLevel;
  isLoading: boolean;

  formValues: GradeLevelFormValues;
  editModalOpen: boolean;
  createModalOpen: boolean;

  static create(props: Partial<GradeLevelState>): GradeLevelState {
    const defaults: GradeLevelState = {
      records: [],
      gradeLevel: {},
      isLoading: false,

      formValues: null,
      editModalOpen: false,
      createModalOpen: false,
    };
    return Object.assign(new GradeLevelState(), defaults, props || {});
  }
}

export class GradeLevelStore extends BaseStore<GradeLevelState> {
  constructor() {
    super(GradeLevelState.create({}));
  }

  public fetchRecords(): void {
    this.setState({ isLoading: true });

    axios(`${API_ENDPOINT}/grade_levels.json`)
      .then((result) => result?.data?.result ?? [])
      .then((gradeLevels) => {
        this.setState({ records: gradeLevels, isLoading: false });
      })
      .catch(() => {
        this.setState({ isLoading: false });
      });
  }

  public createGradeLevel(formValues: GradeLevelFormValues): void {
    axios({
      method: 'post',
      url: `${API_ENDPOINT}/grade_levels.json`,
      data: { grade_level: formValues },
      headers: { 'Content-Type': 'application/json' },
    })
      .then(() => {
        alertStore.alertSuccess('Successfully created grade level.');
        this.fetchRecords();
        this.setState({ createModalOpen: false });
      })
      .catch(() => {
        this.setState({ createModalOpen: false });
      });
  }

  public updateGradeLevel(editFormValues: GradeLevelFormValues, gradeLevel: GradeLevel): void {
    axios({
      method: 'put',
      url: `${API_ENDPOINT}/grade_levels/${gradeLevel.id}.json`,
      data: { grade_level: editFormValues },
      headers: { 'Content-Type': 'application/json' },
    })
      .then(() => {
        alertStore.alertSuccess('Successfully updated grade level.');
        this.fetchRecords();
        this.setState({ editModalOpen: false });
      })
      .catch(() => {
        this.setState({ editModalOpen: false });
      });
  }

  public deleteGradeLevel(gradeLevel: GradeLevel, onDelete: () => void): void {
    axios({
      method: 'delete',
      url: `${API_ENDPOINT}/grade_levels/${gradeLevel.id}.json`,
      headers: { 'Content-Type': 'application/json' },
    })
      .then(() => {
        alertStore.alertSuccess('Successfully deleted grade level.');
        this.fetchRecords();
        onDelete();
      })
      .catch(() => {
        onDelete();
      });
  }

  public setEditModalOpen(isOpen: boolean, gradeLevel: GradeLevel): void {
    const formValues = {
      name: gradeLevel.name,
    };

    this.setState({ editModalOpen: isOpen, formValues, gradeLevel });
  }

  public setFormValue(formValues: GradeLevelFormValues): void {
    this.setState({ formValues });
  }
}
