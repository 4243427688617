import React, { FunctionComponent } from 'react';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import ProductivityReport from './ProductivityReport';
import StudentBySchoolReport from './StudentBySchoolReport';
import SchoolActivityReport from './SchoolActivityReport';
import InvalidAppointmentsReport from './InvalidAppointmentsReport';
import AttendanceReport from './AttendanceReport';
import BillingDiscrepancyReport from './BillingDiscrepancyReportPage';
import { UserContext } from '../../auth/contexts/userContext';

const DashboardReports: FunctionComponent = () => {
  const { currentUserHasAnyRole } = React.useContext(UserContext);
  const [activeReport, setActiveReport] = React.useState(0);

  const handleChangeReport = (reportIndex: number) => {
    setActiveReport(reportIndex);
  };

  return (
    <Box>
      <Grid justify="space-between" container spacing={3}>
        <Grid item>
          <Typography variant="h1" component="h1">
            Reports
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item>
          <Button
            variant="contained"
            color={activeReport === 0 ? 'secondary' : 'primary'}
            onClick={() => handleChangeReport(0)}
          >
            Productivity
          </Button>
        </Grid>
        {currentUserHasAnyRole(['admin', 'supervisor', 'accountant', 'system_owner']) && (
          <>
            <Grid item>
              <Button
                variant="contained"
                color={activeReport === 1 ? 'secondary' : 'primary'}
                onClick={() => handleChangeReport(1)}
              >
                Student by School
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color={activeReport === 2 ? 'secondary' : 'primary'}
                onClick={() => handleChangeReport(2)}
              >
                School Activity
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color={activeReport === 4 ? 'secondary' : 'primary'}
                onClick={() => handleChangeReport(4)}
              >
                Invalid Appointments
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color={activeReport === 5 ? 'secondary' : 'primary'}
                onClick={() => handleChangeReport(5)}
              >
                Attendance
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color={activeReport === 6 ? 'secondary' : 'primary'}
                onClick={() => handleChangeReport(6)}
              >
                Billing Discrepancies
              </Button>
            </Grid>
          </>
        )}
      </Grid>
      <br />
      <Box display={activeReport === 0 ? 'block' : 'none'}>
        <ProductivityReport />
      </Box>
      <Box display={activeReport === 1 ? 'block' : 'none'}>
        <StudentBySchoolReport />
      </Box>
      <Box display={activeReport === 2 ? 'block' : 'none'}>
        <SchoolActivityReport />
      </Box>
      <Box display={activeReport === 4 ? 'block' : 'none'}>
        <InvalidAppointmentsReport />
      </Box>
      <Box display={activeReport === 5 ? 'block' : 'none'}>
        <AttendanceReport />
      </Box>
      <Box display={activeReport === 6 ? 'block' : 'none'}>
        <BillingDiscrepancyReport />
      </Box>
    </Box>
  );
};

export default DashboardReports;
