import React from 'react';
import { IDataTableColumn } from '../../shared/components/DataTable/DataTable/model';
import { Renewal } from '../model';
import { Colors } from '../../shared/style/colors';
import HourglassDisabledIcon from '@mui/icons-material/HourglassDisabled';

const statusColors = {
  'On Target': Colors.Success,
  Renewed: Colors.BabyBlue,
  'Needs Assignment': Colors.Red,
  'Off Target': Colors.Red,
};

export const iepColumns = (isAdmin: boolean): IDataTableColumn<Renewal>[] => [
  {
    name: 'STUDENT NAME',
    selector: 'student_name',
    sortable: true,
    cell: (row) => (
      <div>
        <a href={`/students/${row.student_id}/profile`}>{row.student_name}</a>
      </div>
    ),
  },
  {
    name: 'TIMELINE ID',
    selector: 'student_slug',
    sortable: true,
  },
  {
    name: 'SCHOOL',
    selector: 'school_abbreviation',
    sortable: true,
    grow: 0.75,
  },
  {
    name: 'TYPE',
    selector: 'type',
    sortable: true,
  },
  {
    name: 'Service',
    selector: 'referral_service_id',
    cell: (row) => (
      <div>
        {row.referral_service_id ? (
          <a href={`/students/${row.student_id}/services/${row.referral_service_id}`}>{row.service_name}</a>
        ) : (
          <p>N/A</p>
        )}
      </div>
    ),
  },
  {
    name: 'OWNER',
    selector: 'owner',
    sortable: true,
    omit: !isAdmin,
  },
  {
    name: 'Renewal (Service End)',
    selector: 'formatted_renewal_date',
    sortable: true,
    grow: 1.5,
  },
  {
    name: 'THERAPIST INPUT',
    selector: 'therapist_input_date',
    sortable: true,
  },
  {
    name: 'IEP DUE TO HOPE',
    selector: 'iep_due_to_hope_date',
    sortable: true,
  },
  {
    name: 'INPUT APPROVAL',
    selector: 'input_approval_date',
    sortable: true,
  },
  {
    name: 'STAGE',
    selector: 'stage',
    sortable: true,
    cell: (row) => (
      <div
        style={{
          fontWeight: 700,
          textTransform: 'uppercase',
          color: statusColors[row.status],
        }}
      >
        {row.stage}
      </div>
    ),
  },
];

export const rrColumns = (isAdmin: boolean): IDataTableColumn<Renewal>[] => [
  {
    name: 'STUDENT NAME',
    selector: 'student_name',
    sortable: true,
    cell: (row) => (
      <div>
        <a href={`/students/${row.student_id}/profile`}>{row.student_name}</a>
      </div>
    ),
  },
  {
    name: 'TIMELINE ID',
    selector: 'student_slug',
    sortable: true,
  },
  {
    name: 'SCHOOL',
    selector: 'school_abbreviation',
    sortable: true,
    grow: 0.75,
  },
  {
    name: 'TYPE',
    selector: 'type',
    sortable: true,
  },
  {
    name: 'Service',
    selector: 'referral_service_id',
    cell: (row) => (
      <div>
        {row.referral_service_id ? (
          <a href={`/students/${row.student_id}/services/${row.referral_service_id}`}>{row.service_name}</a>
        ) : (
          <p>N/A</p>
        )}
      </div>
    ),
  },
  {
    name: 'OWNER',
    selector: 'owner',
    sortable: true,
    omit: !isAdmin,
  },
  {
    name: 'Student RR Date',
    selector: 'formatted_rr_date',
    sortable: true,
  },
  {
    name: 'THERAPIST RR INPUT',
    selector: 'therapist_rr_input_date',
    sortable: true,
  },
  {
    name: 'RR TO HOPE',
    selector: 'rr_to_hope_date',
    sortable: true,
  },
  {
    name: 'RR Approval',
    selector: 'rr_approval_date',
    sortable: true,
  },
  {
    name: 'STAGE',
    selector: 'stage',
    sortable: true,
    grow: 2,
    cell: (row) => (
      <div
        style={{
          fontWeight: 700,
          textTransform: 'uppercase',
          color: statusColors[row.status],
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {row.status === 'Off Target' && <HourglassDisabledIcon style={{ maxHeight: '80%', maxWidth: '60%' }} />}
        {row.stage || 'Missing Stage'}
      </div>
    ),
  },
];
