import React, { FunctionComponent } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Button, Grid, Typography, FormControlLabel, makeStyles, Radio, RadioGroup, Checkbox } from '@material-ui/core';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import TextInput from '../../shared/components/form/TextInput';
import InformationField from '../../shared/components/InformationField';
import SelectInput from '../../shared/components/form/SelectInput';
import { ReferralsContext } from '../contexts/ReferralsContext';
import { buildServiceTypeChoices } from '../constants/BuildServiceTypeChoices';
import DocumentationUpload from './DocumentationUpload';

const useStyles = makeStyles({
  textInput: {
    height: '60px',
    width: '80%',
    margin: '10px 0',
  },
  searchInput: {
    height: '40px',
    width: '80%',
    margin: '10px 0',
  },
  baseError: {
    marginBottom: '10px',
  },
  checkboxLabel: {
    textTransform: 'capitalize',
    fontSize: '14px',
  },
});

const ReferralForm: FunctionComponent = () => {
  const classes = useStyles();
  const { studentId } = useParams<Record<string, string>>();
  const history = useHistory();
  const {
    serviceTypeChoices,
    referralStudent,
    schoolUserCreateReferral,
    referralSubmitting,
    optionalAttachedFiles,
    requiredAttachment,
  } = React.useContext(ReferralsContext);

  const [dialogOpen, setDialogOpen] = React.useState(false);
  // const [formErrors, setFormErrors] = React.useState<Record<string, string>>({});
  const [serviceTypeFilter, setServiceTypeFilter] = React.useState('');
  const [buttonDisabled, setButtonDisabled] = React.useState<boolean>(true);
  const [selectedServiceTypesBySubgroup, setSelectedServiceTypesBySubgroup] = React.useState({});
  const [formValues, setFormValues] = React.useState<Record<string, any>>({
    status: 'active',
    initial_referral_date: '',
    referral_stage_id: '1',
  });

  React.useEffect(() => {
    requiredFormsCompleted();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues, requiredAttachment]);

  const handleCreate = () => {
    const attachments = [requiredAttachment, ...optionalAttachedFiles];
    schoolUserCreateReferral(studentId, formValues, attachments, () => setDialogOpen(true));
  };

  const requiredFormsCompleted = () => {
    if (formValues?.category === undefined) {
      setButtonDisabled(true);
    } else if (formValues?.category === 'evaluation') {
      setButtonDisabled(
        formValues?.service_types === undefined ||
          formValues?.service_types?.length === 0 ||
          formValues?.initial_referral_date === undefined ||
          requiredAttachment === undefined,
      );
    } else if (formValues?.category === 'screening') {
      setButtonDisabled(
        formValues?.service_types === undefined ||
          formValues?.service_types?.length === 0 ||
          formValues?.initial_referral_date === undefined ||
          requiredAttachment === undefined,
      );
    } else if (formValues?.category === 'iep') {
      setButtonDisabled(
        formValues?.start_date === undefined ||
          formValues?.end_date === undefined ||
          formValues?.frequency === undefined ||
          formValues?.service_types === undefined ||
          formValues?.service_types?.length === 0 ||
          requiredAttachment === undefined,
      );
    } else if (formValues?.category === '504') {
      setButtonDisabled(
        formValues?.start_date === undefined ||
          formValues?.end_date === undefined ||
          formValues?.frequency === undefined ||
          formValues?.service_types === undefined ||
          formValues?.service_types?.length === 0 ||
          requiredAttachment === undefined,
      );
    }
  };

  const handleServiceTypeChange = (event, subgroupId) => {
    const value = event.target.value;
    setSelectedServiceTypesBySubgroup((prevState) => {
      const newState = {
        ...prevState,
        [subgroupId]: value,
      };
      // Update formValues.service_types with the new selection
      setFormValues({
        ...formValues,
        service_types: Object.values(newState),
      });
      return newState;
    });
  };

  const handleServiceTypeCheck = (event) => {
    let options = [];

    if (formValues?.service_types) {
      options = formValues?.service_types;
    }

    if (event.target.checked) {
      options = [...options, event.target.value.toString()];
    } else {
      const index = options.indexOf(event.target.value);
      options.splice(index, 1);
    }

    setFormValues({ ...formValues, service_types: options });
  };

  // Assuming buildServiceTypeChoices now includes subgroupId in each service type
  const serviceTypes = buildServiceTypeChoices(formValues?.category, serviceTypeChoices);

  // Group the service types by subgroupId
  const groupedServiceTypes = serviceTypes?.reduce((groups, serviceType) => {
    const subgroupId = serviceType.service_subcategory_id || 'default';
    if (!groups[subgroupId]) {
      groups[subgroupId] = [];
    }
    groups[subgroupId].push(serviceType);
    return groups;
  }, {});

  return (
    <>
      <Dialog
        open={dialogOpen}
        onClose={() => history.push(`/dashboard`)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Success!</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Your referral has been received &mdash; thank you!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => history.push(`/dashboard`)}>Return to Dashboard</Button>
          <Button onClick={() => history.push(`/referrals`)}>Return to Referrals</Button>
        </DialogActions>
      </Dialog>
      <Grid container spacing={6}>
        <Grid item>
          <InformationField label="Student Name" value={referralStudent?.student_name} />
        </Grid>
        <Grid item>
          <InformationField label="Birthdate" value={referralStudent?.birthdate} />
        </Grid>
      </Grid>
      <br />
      <form noValidate autoComplete="off">
        <SelectInput
          styleOverrides={classes.textInput}
          type="text"
          label="Phase"
          value="referral"
          valueChanged={(value: string) => setFormValues({ ...formValues, phase: value })}
          choices={[{ label: 'Referral', value: 'referral' }]}
        />
        <SelectInput
          styleOverrides={classes.textInput}
          type="text"
          label="Status"
          value={formValues?.status}
          valueChanged={(value: string) => setFormValues({ ...formValues, status: value })}
          choices={[{ value: 'active', label: 'Active' }]}
        />
        <SelectInput
          styleOverrides={classes.textInput}
          type="text"
          label="Referral Type"
          value={formValues?.category}
          valueChanged={(value: string) => {
            setFormValues({ ...formValues, category: value });
          }}
          choices={[
            { label: 'Evaluation', value: 'evaluation' },
            { label: 'Screening', value: 'screening' },
            { label: 'IEP Service', value: 'iep' },
            { label: '504 Service', value: '504' },
          ]}
          defaultChoice={{ value: '', label: 'None' }}
        />
        <>
          {formValues?.category?.length > 0 && (
            <>
              <Typography variant="h2" component="h2" style={{ marginLeft: 0 }}>
                Service Types (Select All That Apply)
              </Typography>
              <TextInput
                styleOverrides={classes.textInput}
                type="text"
                label="Filter services by name..."
                value={serviceTypeFilter}
                valueChanged={(value: string) => setServiceTypeFilter(value)}
              />
              <Grid container>
                <Grid item md={9}>
                  <Grid container>
                    {Object.keys(groupedServiceTypes)
                      .filter((subgroup) => subgroup !== 'default')
                      .map((subgroupId, index) => (
                        <Grid item xs={12} key={1}>
                          <Typography variant="h3" gutterBottom style={{ textTransform: 'capitalize' }}>
                            {groupedServiceTypes[subgroupId][0].subcategory_name}
                          </Typography>
                          {groupedServiceTypes[subgroupId].length === 1 ? (
                            // Render a Checkbox if there's only one service
                            <FormControlLabel
                              control={
                                <Checkbox
                                  color="primary"
                                  onChange={handleServiceTypeCheck}
                                  value={groupedServiceTypes[subgroupId][0]?.value}
                                />
                              }
                              label={groupedServiceTypes[subgroupId][0].label}
                            />
                          ) : (
                            // Render the RadioGroup if there are multiple services
                            <RadioGroup
                              name={`serviceTypeGroup-${subgroupId}`}
                              row
                              value={selectedServiceTypesBySubgroup[subgroupId]?.toString() || ''}
                              onChange={(event) => handleServiceTypeChange(event, subgroupId)}
                            >
                              {groupedServiceTypes[subgroupId]
                                .filter((serviceType) => serviceType.label.includes(serviceTypeFilter))
                                .map((serviceType) => (
                                  <FormControlLabel
                                    classes={{ label: classes.checkboxLabel }}
                                    key={serviceType.value}
                                    value={serviceType.value.toString()}
                                    control={<Radio color="primary" />}
                                    label={serviceType.label}
                                  />
                                ))}
                            </RadioGroup>
                          )}
                        </Grid>
                      ))}
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
        </>
        {(formValues?.category === 'evaluation' || formValues?.category === 'screening') && (
          <>
            <TextInput
              styleOverrides={classes.textInput}
              label="Date Received by School"
              type="date"
              shrinkLabel
              // errorMessage={formErrors?.initial_referral_date}
              value={formValues?.initial_referral_date}
              valueChanged={(value: string) => setFormValues({ ...formValues, initial_referral_date: value })}
            />
            <br />
          </>
        )}
        {(formValues?.category === 'iep' || formValues?.category === '504') && (
          <>
            <br />
            <Typography variant="h2" component="h2" style={{ marginLeft: 0 }}>
              Service Details
            </Typography>
            <TextInput
              styleOverrides={classes.textInput}
              label="Date Services Begin"
              type="date"
              shrinkLabel
              // errorMessage={formErrors?.start_date}
              value={formValues?.start_date}
              valueChanged={(value: string) => setFormValues({ ...formValues, start_date: value })}
            />
            <TextInput
              styleOverrides={classes.textInput}
              label="Date Services End"
              type="date"
              shrinkLabel
              // errorMessage={formErrors?.end_date}
              value={formValues?.end_date}
              valueChanged={(value: string) => setFormValues({ ...formValues, end_date: value })}
            />
            <TextInput
              styleOverrides={classes.textInput}
              type="text"
              label="Service Frequency"
              // errorMessage={formErrors?.frequency}
              value={formValues?.frequency}
              valueChanged={(value: string) => setFormValues({ ...formValues, frequency: value })}
            />
          </>
        )}
        {formValues?.category && <DocumentationUpload category={formValues?.category} />}
        <br />
        <Grid container>
          <Button
            disabled={buttonDisabled || referralSubmitting}
            variant="contained"
            color="primary"
            onClick={handleCreate}
          >
            {referralSubmitting ? 'Submitting...' : 'Submit Referral'}
          </Button>
          {buttonDisabled && <p style={{ color: 'red', marginLeft: 7 }}>Please fill out all the fields above.</p>}
        </Grid>
      </form>
    </>
  );
};

export default ReferralForm;
