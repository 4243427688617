/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import { Typography, Grid, Card, CardContent, Button, makeStyles } from '@material-ui/core';
import { Goal } from '../../goals/model';
import TextInput from '../../shared/components/form/TextInput';
import SelectInput from '../../shared/components/form/SelectInput';
import { axios } from '../../shared/singletons';
import { GoalsContext } from '../../goals/contexts/GoalsContext';
import { AnnualUpdatesContext } from '../contexts/AnnualUpdatesContext';

const statusChoices = [
  { label: 'Active', value: 'active' },
  { label: 'Inactive', value: 'inactive' },
];

type GoalEditCardProps = {
  idx: number;
  goalId: string;
};

const useStyles = makeStyles((theme) => ({
  textInput: {
    height: '60px',
    width: '80%',
    margin: '10px 0',
  },
}));

const GoalEditCard: React.FC<GoalEditCardProps> = (props) => {
  const { idx, goalId } = props;
  const [goal, setGoal] = useState<Goal>();
  const { activeGoals, setActiveGoals, inactiveGoals, setInactiveGoals } = useContext(GoalsContext);
  const { setContinueDisabled } = useContext(AnnualUpdatesContext);
  const [changesMade, setChangesMade] = useState(false);

  const [formValues, setFormValues] = useState({
    start_date: '',
    end_date: '',
    status: '',
    description: '',
  });

  const classes = useStyles();

  useEffect(() => {
    const updatedGoal = activeGoals.find((elem) => elem?.id === goalId);
    setGoal(updatedGoal);

    setFormValues({
      start_date: updatedGoal?.form_start_date,
      end_date: updatedGoal?.form_end_date,
      status: updatedGoal?.status,
      description: updatedGoal?.objectives,
    });
  }, [activeGoals]);

  useEffect(() => {
    if (
      formValues?.start_date === goal?.form_start_date &&
      formValues?.end_date === goal?.form_end_date &&
      formValues?.status === goal?.status &&
      formValues?.description === goal?.objectives
    ) {
      setChangesMade(false);
      setContinueDisabled(false);
    } else {
      setChangesMade(true);
      setContinueDisabled(true);
    }
  }, [formValues]);

  const updateGoal = () => {
    axios.put(`goals/${goal?.id}.json`, { goal: formValues }).then((response) => {
      const updatedGoal = response?.data;

      if (updatedGoal?.status === 'inactive') {
        const newInactiveGoals = [...inactiveGoals];
        newInactiveGoals.push(updatedGoal);
        setInactiveGoals(newInactiveGoals);

        const newActiveGoals = [...activeGoals];
        setActiveGoals(newActiveGoals.filter((goal) => goal?.id !== updatedGoal?.id));
      }
      setGoal(updatedGoal);
      setChangesMade(false);
      setContinueDisabled(false);
    });
  };

  return (
    <Card>
      <CardContent>
        <Grid item>
          <Typography variant="h2" component="h2">
            {`Goal ${idx + 1} – ${goal?.service_type}`}
          </Typography>
        </Grid>
        <br />
        <Grid container spacing={3}>
          <Grid item xs={3} md={3}>
            <TextInput
              styleOverrides={classes.textInput}
              type="date"
              shrinkLabel
              label="Start Date"
              value={formValues?.start_date}
              valueChanged={(value) => setFormValues({ ...formValues, start_date: value })}
            />
          </Grid>
          <Grid item xs={3} md={3}>
            <TextInput
              styleOverrides={classes.textInput}
              type="date"
              shrinkLabel
              label="End Date"
              value={formValues?.end_date}
              valueChanged={(value) => setFormValues({ ...formValues, end_date: value })}
            />
          </Grid>

          <Grid item xs={3} md={3}>
            <SelectInput
              styleOverrides={classes.textInput}
              value={formValues?.status}
              label="Status"
              choices={statusChoices}
              valueChanged={(value: string) => setFormValues({ ...formValues, status: value })}
              type="text"
            />
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={8} style={{ paddingLeft: 25 }}>
              <TextInput
                styleOverrides={classes.textInput}
                value={formValues?.description}
                label="Goal Objectives"
                multiline
                rows={6}
                valueChanged={(value: string) => setFormValues({ ...formValues, description: value })}
              />
            </Grid>
          </Grid>
        </Grid>
        <br />

        <Grid item xs={12} spacing={1}>
          <Grid style={{ marginTop: 80, display: 'flex', justifyContent: 'flex-start' }}>
            <Button variant="contained" color="primary" disabled={!changesMade} onClick={updateGoal}>
              Update
            </Button>
            {changesMade && (
              <>
                &emsp;
                <p style={{ color: 'red' }}>Update to save your changes</p>
              </>
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default GoalEditCard;
