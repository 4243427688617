import React from 'react';

const SessionTimeout = () => {
  const startTimerInterval = React.useRef<number>();

  React.useEffect(() => {
    // Redirect to home screen after 1 hour of inactivity
    startTimerInterval.current = setInterval(() => {
      window.location.href = '/';
    }, 14400000);
  }, []);

  return <></>;
};

export default SessionTimeout;
