import React, { FunctionComponent, useEffect, useState } from 'react';
import ListingServiceCategoryList from '../components/ListingServiceCategoryList';
import ListingServiceSubcategoryList from '../components/ListingServiceSubcategoryList';
import { ListingService } from '../model';
import { axios } from '../../../shared/singletons';

export type ListingServicesPageProps = Record<string, unknown>;

const ListingServicesPage: FunctionComponent<ListingServicesPageProps> = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [records, setRecords] = useState<ListingService[]>([]);
  const [subcategories, setSubcategories] = useState<ListingService[]>([]);

  useEffect(() => {
    axios.get('/service_categories.json').then((response) => {
      setRecords(response?.data?.result);
      setIsLoading(false);
    });

    axios.get('/service_subcategories.json').then((response) => {
      setSubcategories(response?.data?.result);
      setIsLoading(false);
    });
  }, []);

  return (
    <>
      <ListingServiceCategoryList records={records} isLoading={isLoading} />
      <br />
      <ListingServiceSubcategoryList records={subcategories} isLoading={isLoading} />
    </>
  );
};

export default ListingServicesPage;
