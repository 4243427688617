import React, { FunctionComponent, Dispatch, SetStateAction } from 'react';
import { Box, Grid, Typography, makeStyles } from '@material-ui/core';
import SelectInput from '../../shared/components/form/SelectInput';
import { axios } from '../../shared/singletons';
import ConfirmCancelModal from '../../shared/components/modal/ConfirmCancelModal';
import TextInput from '../../shared/components/form/TextInput';
interface AttachmentFormModalProps {
  isOpen: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  attachmentableType: string;
  attachmentableId: string;
  afterAction: () => void;
  schoolDocuments?: boolean;
}

const useStyles = makeStyles({
  textInput: {
    height: '60px',
    width: '80%',
    margin: '10px 0',
  },
  baseError: {
    marginBottom: '10px',
  },
});

const AttachmentFormModal: FunctionComponent<AttachmentFormModalProps> = (props) => {
  const { isOpen, setOpen, attachmentableType, attachmentableId, afterAction, schoolDocuments } = props;
  const classes = useStyles();
  const [attachedFiles, setAttachedFiles] = React.useState<FileList>();
  const [isLoading, setLoading] = React.useState<boolean>(false);
  const [documentCategory, setDocumentCategory] = React.useState('');
  const [newFilename, setNewFilename] = React.useState('');

  const handleSelectedFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAttachedFiles(event.target.files);
  };

  const handleUpload = () => {
    setLoading(true);

    Array.from(attachedFiles).forEach((attachedFile) => {
      const data = new FormData();

      data.append(`file`, attachedFile, attachedFile.name);
      data.append('category', documentCategory);
      data.append('new_filename', newFilename);

      axios
        .post(`/${attachmentableType}/${attachmentableId}/attachments.json`, data)
        .then(() => {
          afterAction();
          setLoading(false);
          setOpen(false);
        })
        .catch(() => {
          afterAction();
          setLoading(false);
          setOpen(false);
        });
    });
  };

  return (
    <ConfirmCancelModal
      isOpen={isOpen}
      title={schoolDocuments ? 'Add School Document' : 'Add Attachment'}
      openStatusChanged={(open: boolean) => setOpen(open)}
      cancelLabel="Cancel"
      confirmLabel={isLoading ? 'Uploading...' : 'Upload'}
      hasCancelButton
      hasConfirmButton
      width="768px"
      onConfirm={handleUpload}
    >
      <Box>
        <Typography variant="h1" component="h1">
          Upload {schoolDocuments ? 'School Document' : 'Attachment'}
        </Typography>

        <br />
        <Box>
          <Grid container>
            {schoolDocuments ? (
              <Grid item xs={12} md={6}>
                <SelectInput
                  styleOverrides={classes.textInput}
                  type="text"
                  label="Document Category"
                  value={documentCategory}
                  valueChanged={(value: string) => setDocumentCategory(value)}
                  choices={[
                    { label: 'Help Manual', value: 'help-manual' },
                    { label: 'Generic Document', value: 'generic-document' },
                  ]}
                />
              </Grid>
            ) : (
              <Grid item xs={12} md={6}>
                <SelectInput
                  styleOverrides={classes.textInput}
                  type="text"
                  label="Document Category"
                  value={documentCategory}
                  valueChanged={(value: string) => setDocumentCategory(value)}
                  choices={[{ label: 'Student Documentation', value: 'student-documentation' }]}
                />
              </Grid>
            )}
          </Grid>
          <br />
          <Grid>
            <TextInput
              styleOverrides={classes.textInput}
              type="text"
              label="New Filename (Optional)"
              value={newFilename}
              valueChanged={(value: string) => setNewFilename(value)}
            />
          </Grid>
          <Grid container>
            <Grid item>
              <input type="file" onChange={handleSelectedFile} />
            </Grid>
          </Grid>
        </Box>
        <br />
      </Box>
    </ConfirmCancelModal>
  );
};

export default AttachmentFormModal;
