import styled, { css } from 'styled-components';

const disabled = css`
  pointer-events: none;
`;

export interface TableHeadRowProps {
  disabled: boolean;
  dense: any;
}

const TableHeadRow = styled.div<TableHeadRowProps>`
  display: flex;
  align-items: stretch;
  width: 100%;
  ${(props) => props.theme.headRow.style};
  ${(props) => props.dense && props.theme.headRow.denseStyle};
  ${(props) => props.disabled && disabled};

  @media (max-width: 960px) {
    display: none;
  }
`;

export default TableHeadRow;
