import React, { FunctionComponent, useContext, useEffect } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Grid } from '@material-ui/core';
import UserBasicInfoForm from './UserEditDetailsForm';
import ConfirmCancelModal from '../../shared/components/modal/ConfirmCancelModal';
import { InternalUsersContext } from '../../internalUsers/contexts/InternalUsersContext';
import UserActivateButtons from './UserActivateButtons';
import UserSchoolsForm from './UserSchoolsForm';

const UserFormModal: FunctionComponent = () => {
  const { setFormValues, user, basicInfoModalOpen, setBasicInfoModalOpen, updateInternalUser } = useContext(
    InternalUsersContext,
  );

  useEffect(() => {
    setFormValues({
      email: user?.email,
      username: user?.username,
      password: user?.password,
      first_name: user?.first_name,
      last_name: user?.last_name,
      supervisor_id: user?.supervisor_id,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <ConfirmCancelModal
      isOpen={basicInfoModalOpen}
      onCancel={() => setBasicInfoModalOpen(false)}
      onConfirm={updateInternalUser}
      openStatusChanged={(open) => setBasicInfoModalOpen(open)}
      title="User Details"
    >
      <>
        <UserBasicInfoForm />
        {user?.roles?.includes('payer_admin') && (
          <Accordion>
            <AccordionSummary>
              <Typography>Schools</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container>
                <UserSchoolsForm />
              </Grid>
            </AccordionDetails>
          </Accordion>
        )}
        <UserActivateButtons />
      </>
    </ConfirmCancelModal>
  );
};

export default UserFormModal;
