import React, { FunctionComponent, useState, useEffect } from 'react';
import { Box } from '@material-ui/core';
import ConfirmCancelModal from '../../shared/components/modal/ConfirmCancelModal';
import LocationForm from './LocationForm';
import { School, Location } from '../model';
import axios from '../../shared/utils/axios.utils';

interface EditLocationModalProps {
  school: School;
  locationToEdit: Location;
  editLocationModalOpen: boolean;
  setEditLocationModalOpen: (open: boolean) => void;
}

const EditLocationModal: FunctionComponent<EditLocationModalProps> = (props) => {
  const { locationToEdit, editLocationModalOpen, setEditLocationModalOpen, school } = props;

  const [locationFormValues, setLocationFormValues] = useState(locationToEdit);
  const [formErrors, setFormErrors] = useState<any>(null);

  const handleFormSubmit = () => {
    axios({
      method: 'put',
      url: `locations/${locationFormValues?.id}.json`,
      data: { location: locationFormValues },
      headers: { 'Content-Type': 'application/json' },
    })
      .then(() => {
        window.location.reload();
      })
      .catch((e) => {
        setFormErrors(e.response.data.errors);
      });
  };

  useEffect(() => {
    if (locationToEdit) {
      setLocationFormValues(locationToEdit);
    }
  }, [locationToEdit]);

  return (
    <ConfirmCancelModal
      isOpen={editLocationModalOpen}
      title="Edit Location"
      onConfirm={handleFormSubmit}
      confirmLabel="Update"
      openStatusChanged={(isOpen) => setEditLocationModalOpen(isOpen)}
    >
      <Box p={2}>
        <LocationForm
          school={school}
          locationFormValues={locationFormValues}
          setLocationFormValues={setLocationFormValues}
          formErrors={formErrors}
        />
      </Box>
    </ConfirmCancelModal>
  );
};

export default EditLocationModal;
