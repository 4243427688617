import React, { FunctionComponent } from 'react';
import { Button, Card, CardActions, CardContent, Grid, Typography } from '@material-ui/core';
import DataTable from '../../shared/components/DataTable';
import LoadingSpinner from '../../shared/components/LoadingSpinner';
import { IDataTableColumn } from '../../shared/components/DataTable/DataTable/model';
import { UserContext } from '../../auth/contexts/userContext';
import { Attachment } from '../../attachments/model';
import AttachmentRowMenu from '../../attachments/components/AttachmentRowMenu';
import AttachmentFormModal from '../../attachments/components/AttachmentFormModal';

interface SchoolDocumentsProps {
  attachments: Attachment[];
  showAddAttachment: boolean;
  isLoading: boolean;
  attachmentableType: string;
  attachmentableId: string;
  afterAction: () => void;
}

const SchoolDocuments: FunctionComponent<SchoolDocumentsProps> = (props) => {
  const { attachments, showAddAttachment, isLoading, attachmentableType, attachmentableId, afterAction } = props;
  const [showArchived, setShowArchived] = React.useState(false);
  const [modalOpen, setModalOpen] = React.useState(false);
  const { currentUserHasRole } = React.useContext(UserContext);

  const columns: IDataTableColumn<Attachment>[] = [
    {
      name: 'FILE URL',
      selector: 'file_name',
      sortable: true,
      cell: (row) => (
        <a target="_blank" rel="noopener noreferrer" href={row.file_url}>
          Click to open file
        </a>
      ),
    },
    {
      name: 'NAME',
      selector: 'file_name',
      sortable: true,
    },
    {
      name: 'CATEGORY',
      selector: 'category',
      sortable: true,
    },
    {
      name: 'DATE CREATED',
      selector: 'date_created',
      sortable: true,
    },
    {
      cell: (attachment) => <AttachmentRowMenu attachment={attachment} afterAction={afterAction} />,
      name: 'ACTIONS',
      allowOverflow: true,
      button: true,
      selector: 'actions',
    },
  ];

  const formattedAttachments =
    (showArchived && attachments) || attachments?.filter((attachment) => !attachment.archived);

  return isLoading ? (
    <LoadingSpinner />
  ) : (
    <Card>
      <AttachmentFormModal
        isOpen={modalOpen}
        setOpen={setModalOpen}
        attachmentableType={attachmentableType}
        attachmentableId={attachmentableId}
        afterAction={afterAction}
        schoolDocuments
      />
      <CardContent>
        <Grid container justify="space-between" spacing={3}>
          <Grid item>
            <Typography variant="h2" component="h2">
              External School Documents
            </Typography>
            <Typography>These are external documents that are viewable by school users inside Timeline.</Typography>
          </Grid>
          <Grid item>
            {(currentUserHasRole('admin') || (currentUserHasRole('school_admin') && showAddAttachment)) && (
              <Button
                variant="contained"
                color="primary"
                onClick={() => setModalOpen(true) /* attachmentStore.setModalOpen(true) */}
              >
                Add School Document
              </Button>
            )}
          </Grid>
        </Grid>
        <DataTable columns={columns} noHeader data={formattedAttachments} striped highlightOnHover pointerOnHover />
      </CardContent>
      <CardActions>
        <Button size="small" onClick={() => setShowArchived(!showArchived)}>
          {showArchived ? 'Hide Archives' : 'Show Archives'}
        </Button>
      </CardActions>
    </Card>
  );
};

export default SchoolDocuments;
