import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import { Modal, Typography } from '@material-ui/core';
import Button, { ButtonFlavor } from '../Button';
import { ModalHeader } from './ModalHeader';
import { ModalFooter } from './ModalFooter';
import { ModalBody } from './ModalBody';
import { Colors } from '../../style/colors';
import { HopeIcon } from '../icon/HopeIcon';
import { BaseModalProps, DEFAULT_MODAL_HEIGHT, DEFAULT_MODAL_WIDTH } from './modal.model';
import { ComponentTestId } from '../../common.model';

export const DEFAULT_CONFIRM_LABEL = 'Confirm'; // TODO translate
export const DEFAULT_CANCEL_LABEL = 'Cancel'; // TODO translate

export interface ConfirmCancelModalProps extends BaseModalProps {
  confirmLabel?: string;
  cancelLabel?: string;
  hasConfirmButton?: boolean;
  hasCancelButton?: boolean;
  errorMessage?: string;
  confirmDisabled?: boolean;
  cancelDisabled?: boolean;
  hasTitleBar?: boolean;
  hasFooterBar?: boolean;
  closeOnConfirm?: boolean;
  closeOnCancel?: boolean;
  onConfirm?: () => void;
  onCancel?: () => void;
}

interface ModalContentProps {
  width: string;
  height: string;
  hasTitleBar: boolean;
  hasFooterBar: boolean;
}

const useStyles = makeStyles({
  modalContainer: {
    overflow: 'scroll',
  },
});

const ModalContent = styled.div<ModalContentProps>`
  position: absolute;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  border-radius: 2px;
  background-color: ${Colors.White};
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.5);
  margin-bottom: 150px;

  ${(props) => `
    left: calc( (100vw - ${props.width}) / 2 );
    top: 118px;
  `};

  @media (max-width: 960px) {
    width: 100%;
    left: 0;
  }

  display: flex;
  flex-direction: column;

  .modal-body {
    overflow: auto;
  }

  ${(props) =>
    !props.hasTitleBar &&
    `.modal-header {
      border-bottom: none;
    }`}

  ${(props) =>
    !props.hasFooterBar &&
    `.modal-body {
      text-align: center;
      padding-top: 0;
    }

    .buttons {
      text-align: center;

      button {
        width: 120px;
        margin: 7px;
      }
    }`}
`;

const ConfirmCancelModal: FunctionComponent<ConfirmCancelModalProps> = (props: ConfirmCancelModalProps) => {
  const {
    isOpen,
    title,
    errorMessage = null,
    hasTitleBar = true,
    hasFooterBar = true,
    confirmLabel = DEFAULT_CONFIRM_LABEL,
    cancelLabel = DEFAULT_CANCEL_LABEL,
    hasConfirmButton = true,
    hasCancelButton = true,
    confirmDisabled = false,
    cancelDisabled = false,
    width = DEFAULT_MODAL_WIDTH,
    height = DEFAULT_MODAL_HEIGHT,
    closeOnConfirm = false,
    closeOnCancel = true,
    openStatusChanged = () => null,
    onConfirm = () => null,
    onCancel = () => null,
    children,
  } = props;

  const classes = useStyles();

  const handleClose = () => {
    openStatusChanged(false);
  };

  const headerCloseButton = (
    <HopeIcon name="close" isClickable onClick={() => openStatusChanged(false)} fontSize="large" />
  );

  const confirmButton = hasConfirmButton ? (
    <Button
      text={confirmLabel}
      disabled={confirmDisabled}
      flavor={ButtonFlavor.Primary}
      handleClick={() => {
        onConfirm();
        if (closeOnConfirm) handleClose();
      }}
    />
  ) : null;

  const cancelButton = hasCancelButton ? (
    <Button
      text={cancelLabel}
      disabled={cancelDisabled}
      flavor={ButtonFlavor.Secondary}
      handleClick={() => {
        onCancel();
        if (closeOnCancel) handleClose();
      }}
    />
  ) : null;

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      disableBackdropClick={true}
      title={title}
      className={classes.modalContainer}
      data-testid={ComponentTestId.ConfirmCancelModal}
    >
      <ModalContent
        width={width}
        height={height}
        hasTitleBar={hasTitleBar}
        hasFooterBar={hasFooterBar}
        data-testid={ComponentTestId.ConfirmCancelModalContent}
      >
        <ModalHeader className="modal-header">
          {hasTitleBar && (
            <Typography className="title" variant="h2">
              {title}
            </Typography>
          )}
          {headerCloseButton}
        </ModalHeader>

        <ModalBody className="modal-body">
          {children}
          {!hasFooterBar && (
            <div className="buttons">
              {cancelButton}
              {confirmButton}
            </div>
          )}
        </ModalBody>

        {hasFooterBar && (
          <ModalFooter>
            <div className="buttons">
              <p style={{ color: 'red', marginRight: 10 }}>{errorMessage}</p>
              {cancelButton}
              {confirmButton}
            </div>
          </ModalFooter>
        )}
      </ModalContent>
    </Modal>
  );
};

export default ConfirmCancelModal;
