import React, { FunctionComponent } from 'react';
import { Grid } from '@material-ui/core';
import { StudentFormValues, Student } from '../../students/model';
import Button, { ButtonFlavor } from '../../shared/components/Button';
import { StudentsContext } from '../../students/contexts/StudentsContext';
import PrimaryGuardianAccordion from './PrimaryGuardianAccordion';
import SecondaryGuardianAccordion from './SecondaryGuardianAccordion';
import StudentAddressAccordion from './StudentAddressAccordion';

interface EditContactInfoFormProps {
  student?: Student;
  onSuccess?: (studentId: string) => void;
}

const EditContactInfoForm: FunctionComponent<EditContactInfoFormProps> = (props) => {
  const { student, onSuccess } = props;
  const [formValues, setFormValues] = React.useState<StudentFormValues>({});
  const { updateStudent } = React.useContext(StudentsContext);

  React.useEffect(() => {
    setFormValues({
      gaurdian_name: student?.primary_guardian || '',
      gaurdian_contact_primary: student?.primary_guardian_phone_contact1 || '',
      gaurdian_contact_secondary: student?.primary_guardian_phone_contact2 || '',
      gaurdian_contact_alternate: student?.primary_guardian_phone_contact3 || '',
      email_gaurdian: student?.primary_guardian_email || '',
      gaurdian_name2: student?.secondary_guardian || '',
      gaurdian_contact_primary2: student?.secondary_guardian_phone_contact1 || '',
      gaurdian_contact_secondary2: student?.secondary_guardian_phone_contact2 || '',
      gaurdian_contact_alternate2: student?.secondary_guardian_phone_contact3 || '',
      email_gaurdian2: student?.secondary_guardian_email || '',
      emergency_name: student?.emergency_name || '',
      emergency_relationship: student?.emergency_relationship || '',
      emergency_phone: student?.emergency_phone || '',
      address_line1: student?.address_line1,
      address_line2: student?.address_line2,
      address_city: student?.address_city,
      address_state: student?.address_state,
      address_zip: student?.address_zip,
    });
  }, [setFormValues, student]);

  const handleEditContactInfo = () => {
    updateStudent(student.id, formValues, onSuccess);
  };

  return (
    <>
      <form noValidate autoComplete="off" style={{ marginBottom: '30px' }}>
        <Grid>
          <PrimaryGuardianAccordion student={student} formValues={formValues} setFormValues={setFormValues} />
        </Grid>
        <br />
        <Grid>
          <SecondaryGuardianAccordion student={student} formValues={formValues} setFormValues={setFormValues} />
        </Grid>
        <br />
        <Grid>
          <StudentAddressAccordion student={student} formValues={formValues} setFormValues={setFormValues} />
        </Grid>
      </form>
      <Button
        text="Save & Continue"
        disabled={false}
        flavor={ButtonFlavor.Primary}
        handleClick={() => handleEditContactInfo()}
      />
    </>
  );
};

export default EditContactInfoForm;
