import React from 'react';
import DataTable from '../../shared/components/DataTable';
import { IDataTableColumn } from '../../shared/components/DataTable/DataTable/model';
import { AppointmentNote } from '../model';
import { Appointment } from '../../appointments/model';
import { Card, CardContent, Grid, Typography } from '@material-ui/core';

type AppointmentNotesListProps = { appointmentNotes?: AppointmentNote[]; appointment?: Appointment };

const noteColumns: IDataTableColumn<AppointmentNote>[] = [
  {
    name: 'Appointment Date',
    selector: 'appointment_date',
    sortable: true,
    sortFunction: (rowA: AppointmentNote, rowB: AppointmentNote) =>
      new Date(rowA.appointment_date).getTime() - new Date(rowB.appointment_date).getTime(),
    grow: 0.5,
  },
  {
    name: 'Content',
    selector: 'content',
    cell: (row) => {
      let parsedContent;
      try {
        parsedContent = JSON.parse(row?.content || '{}');
      } catch {
        parsedContent = {};
      }

      return (
        <div>
          {
            <>
              {row.subjective_comment && row.subjective_comment.length > 0 ? (
                <p>
                  <strong>Content: </strong>
                  {row.subjective_comment}
                </p>
              ) : (
                <>
                  {Object.keys(parsedContent).map((key) => (
                    <p key={key}>
                      <strong style={{ textTransform: 'capitalize' }}>{key}: </strong>
                      {parsedContent[key]}
                    </p>
                  ))}
                </>
              )}
            </>
          }
        </div>
      );
    },
    grow: 2,
  },
  {
    name: 'CREATED BY',
    selector: 'created_by',
    sortable: true,
  },
];

const AppointmentNotesList = (props: AppointmentNotesListProps) => {
  const { appointmentNotes, appointment } = props;

  return (
    <Card>
      <CardContent>
        <Grid container justify="space-between" spacing={3}>
          <Grid item>
            <Typography variant="h2" component="h2">
              {appointment?.goals?.length > 0 ? 'Subjective Notes' : 'Past Appointment Notes'}
            </Typography>
          </Grid>

          <DataTable
            columns={noteColumns}
            noHeader
            data={appointmentNotes}
            striped
            highlightOnHover
            pointerOnHover
            pagination
          />
        </Grid>
      </CardContent>
    </Card>
  );
};

export default AppointmentNotesList;
