import React, { FunctionComponent, useContext, useState, useEffect } from 'react';
import { Box, Button, Grid, Typography, makeStyles } from '@material-ui/core';
import SelectInput from '../../shared/components/form/SelectInput';
import InformationField from '../../shared/components/InformationField';
import LoadingSpinner from '../../shared/components/LoadingSpinner';
import DuplicateAppointmentsFormContainer from './DuplicateAppointmentsFormContainer';
import DuplicateAppointmentsModal from './DuplicateAppointmentsModal';
import DuplicateAppointmentsByQuarterModal from './DuplicateAppointmentsByQuarterModal';
import DuplicateAppointmentsByWeekModal from './DuplicateAppointmentsByWeekModal';
import DuplicateAppointmentsByRenewalDateModal from './DuplicateAppointmentsByRenewalDateModal';
import YAGFormModal from './YAGFormModal';
import { DuplicateAppointmentsContext } from '../contexts/DuplicateAppointmentsContext';
import RenewalFormModal from '../../renewals/components/RenewalFormModal';

interface DuplicateAppointmentsProps {
  initialSchoolId: string;
}

const useStyles = makeStyles({
  textInput: {
    height: 'auto',
    width: 300,
    margin: '0',
  },
  link: {
    textDecoration: 'none',
  },
});

const DuplicateAppointments: FunctionComponent<DuplicateAppointmentsProps> = (props) => {
  const classes = useStyles();
  const { initialSchoolId = '' } = props;
  const { appointmentScheduler, fetchRecord } = useContext(DuplicateAppointmentsContext);
  const [schoolId, setSchoolId] = useState(initialSchoolId);
  const [studentId, setStudentId] = useState(appointmentScheduler?.id);
  const [displayName, setDisplayName] = useState('');

  useEffect(() => {
    setStudentId(appointmentScheduler?.id);

    if (appointmentScheduler?.error_message) {
      setDisplayName(appointmentScheduler.error_message);
    } else {
      setDisplayName(`Schedule next year for ${appointmentScheduler.first_name} ${appointmentScheduler.last_name}`);
    }
  }, [appointmentScheduler]);

  const handleStudentChange = (value: string) => {
    if (schoolId) {
      window.location.href = `/users/${appointmentScheduler.owner_id}/duplicate_appointments/${value}?school_id=${schoolId}`;
    } else {
      window.location.href = `/users/${appointmentScheduler.owner_id}/duplicate_appointments/${value}`;
    }
  };

  const handleSchoolChange = (value: string) => {
    setSchoolId(value);
    window.location.href = `/users/${appointmentScheduler.owner_id}/duplicate_appointments?school_id=${value}`;
  };

  // TODO: Probably put this in the context
  const afterCreate = () => {
    fetchRecord(appointmentScheduler.owner_id, appointmentScheduler.id);
  };

  return !appointmentScheduler?.id ? (
    <LoadingSpinner />
  ) : (
    <Grid container spacing={3}>
      <RenewalFormModal
        onSuccess={() => {
          /* eslint-disable */
          window.location = window.location;
          /* eslint-enable */
        }}
      />
      <DuplicateAppointmentsModal afterAction={() => console.log('')} />
      <DuplicateAppointmentsByQuarterModal afterAction={() => console.log('')} />
      <DuplicateAppointmentsByWeekModal afterAction={() => console.log('')} />
      <DuplicateAppointmentsByRenewalDateModal afterAction={() => console.log('')} />
      <YAGFormModal
        locationId={appointmentScheduler?.location_id}
        ownerId={appointmentScheduler?.owner_id}
        afterCreate={afterCreate}
      />
      <Grid container>
        <Grid item xs={7}>
          <Typography variant="h1" component="h1">
            {displayName}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Button
            variant="contained"
            disableElevation
            color="default"
            onClick={() => handleStudentChange(appointmentScheduler.previous_student)}
          >
            Previous Student
          </Button>
        </Grid>
        <Grid item xs={2}>
          <Button
            variant="contained"
            disableElevation
            color="primary"
            onClick={() => handleStudentChange(appointmentScheduler.next_student)}
          >
            Next Student
          </Button>
        </Grid>

        <Grid item xs={5} style={{ margin: 10 }}>
          <SelectInput
            value={initialSchoolId || '0'}
            styleOverrides={classes.textInput}
            type="text"
            label="School"
            valueChanged={(value: string) => handleSchoolChange(value)}
            choices={appointmentScheduler.school_choices}
          />
        </Grid>
        <Grid item xs={5} style={{ margin: 10 }}>
          <SelectInput
            value={studentId || '0'}
            styleOverrides={classes.textInput}
            type="text"
            label="Student"
            valueChanged={(value: string) => handleStudentChange(value)}
            choices={appointmentScheduler.student_choices}
          />
        </Grid>
        <Grid item xs={3}>
          <InformationField
            label="Student Counter"
            value={`${appointmentScheduler.current_student_index + 1} out of ${appointmentScheduler.student_count}`}
          />
        </Grid>
        <Grid item xs={3}>
          <InformationField
            label="Student Name"
            value={`${appointmentScheduler.first_name} ${appointmentScheduler.last_name}`}
          />
        </Grid>
        <Grid item xs={3}>
          <InformationField label="Timeline ID" value={appointmentScheduler.slug} />
        </Grid>
        <Box style={{ marginTop: 30, width: '100%' }}>
          <DuplicateAppointmentsFormContainer />
        </Box>
        <Grid container spacing={2}>
          <Grid item>
            <Button
              variant="contained"
              disableElevation
              color="default"
              onClick={() => handleStudentChange(appointmentScheduler.previous_student)}
            >
              Previous Student
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              disableElevation
              color="primary"
              onClick={() => handleStudentChange(appointmentScheduler.next_student)}
            >
              Next Student
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DuplicateAppointments;
