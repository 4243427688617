import React, { FunctionComponent } from 'react';
import { MenuItem } from '@material-ui/core';
import { RenewalStagesContext } from '../contexts/RenewalStagesContext';
import { RenewalStage } from '../model';

interface RenewalStageRowMenuProps {
  renewalStage: RenewalStage;
}

const RenewalStageRowMenu: FunctionComponent<RenewalStageRowMenuProps> = (props: RenewalStageRowMenuProps) => {
  const { renewalStage } = props;

  const { setEditModalOpen } = React.useContext(RenewalStagesContext);

  return <MenuItem onClick={() => setEditModalOpen(true, renewalStage)}>Edit</MenuItem>;
};

export default RenewalStageRowMenu;
