import React, { FunctionComponent } from 'react';
import { Card, CardContent } from '@material-ui/core';
import DataTable from '../../shared/components/DataTable';
import { IDataTableColumn } from '../../shared/components/DataTable/DataTable/model';
import { User, TrainingLog } from '../model';

const statusColors = {
  Started: 'red',
  'Not Started': 'black',
  Complete: 'green',
};

const columns: IDataTableColumn<TrainingLog>[] = [
  {
    name: 'USER NAME',
    selector: (row) => row.user_name,
    grow: 0.8,
    sortable: true,
  },
  {
    name: 'TRAINING NAME',
    selector: (row) => row.training_name,
    sortable: true,
  },
  {
    name: 'SUBMITTED',
    selector: (row) => row.created_at,
    sortable: true,
  },
  {
    name: 'STATUS',
    selector: (row) => row.status,
    sortable: true,
    cell: (row: TrainingLog) => (
      <div
        style={{
          fontWeight: 700,
          textTransform: 'uppercase',
          color: statusColors[row.status],
        }}
      >
        {row.status}
      </div>
    ),
  },
];

type UserTrainingLogsProps = {
  user: User;
};

const UserTrainingLogs: FunctionComponent<UserTrainingLogsProps> = (props) => {
  const { user } = props;

  return (
    <Card>
      <CardContent>
        <DataTable
          title="Training Logs"
          columns={columns}
          noHeader
          data={user.training_logs}
          striped
          highlightOnHover
          pointerOnHover
          pagination
        />
      </CardContent>
    </Card>
  );
};

export default UserTrainingLogs;
