import React, { FunctionComponent, ChangeEvent, useEffect, useState, useContext } from 'react';
import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { User } from '../model';
import Breadcrumbs, { BreadcrumbLink } from '../../shared/components/Breadcrumbs';
import TabLabels from '../../shared/components/TabLabels';
import TabLabel from '../../shared/components/TabLabel';
import TabPanel from '../../shared/components/TabPanel';
import UserProfileTab from './UserProfileTab';
import UserActionsTab from './UserActionsTab';
import UserProductivityDetailTab from './UserProductivityDetailTab';
import UserTrainingLogs from './UserTrainingLogs';
import { UserContext } from '../../auth/contexts/userContext';

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

interface UserDetailProps {
  user: User;
  userLoading: boolean;
  tab?: string;
}

const tabLabels = ['profile', 'training_logs', 'productivity_detail', 'actions'];

const UserDetail: FunctionComponent<UserDetailProps> = (props) => {
  const { user, userLoading, tab = null } = props;
  const [value, setValue] = useState(0);

  const { currentUserHasAnyRole } = useContext(UserContext);

  const links: BreadcrumbLink[] = [
    {
      href: '/users',
      text: 'USERS',
    },
    {
      href: null,
      text: user?.name,
    },
  ];

  const handleChange = (_event: ChangeEvent, newValue: number) => {
    setValue(newValue);
    window.history.pushState('', '', `/users/${user?.id}/${tabLabels[newValue]}`);
  };

  useEffect(() => {
    if (tab) {
      setValue(tabLabels.indexOf(tab));
    }
  }, [tab]);

  return (
    <div>
      <Grid justify="space-between" container spacing={3}>
        <Grid item>
          <Typography variant="h1" component="h1">
            {user?.name}
          </Typography>
          <Breadcrumbs links={links} />
          <br />
        </Grid>
      </Grid>
      <div>
        <TabLabels variant="scrollable" value={value} onChange={handleChange} aria-label="user tabs">
          <TabLabel label="Profile" other={a11yProps(0)} />
          <TabLabel label="Training Logs" other={a11yProps(1)} />
          <TabLabel label="Productivity Detail" other={a11yProps(2)} />
          {currentUserHasAnyRole(['admin', 'system_owner']) && <TabLabel label="Actions" other={a11yProps(3)} />}
        </TabLabels>
        <TabPanel value={value} index={0}>
          <UserProfileTab user={user} userLoading={userLoading} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <UserTrainingLogs user={user} />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <UserProductivityDetailTab user={user} userLoading={userLoading} />
        </TabPanel>
        {currentUserHasAnyRole(['admin', 'system_owner']) && (
          <TabPanel value={value} index={3}>
            <UserActionsTab user={user} />
          </TabPanel>
        )}
      </div>
    </div>
  );
};

export default UserDetail;
