import React, { useState, useEffect } from 'react';
import { CallBackProps } from 'react-joyride';
import { joyrideSteps } from '../constants/joyrideSteps';
import { useHistory } from 'react-router-dom';

interface JoyrideContextInterface {
  stepIndex: number;
  setStepIndex: React.Dispatch<React.SetStateAction<number>>;
  handleJoyrideCallback: (data: CallBackProps) => void;
}

const JoyrideContext = React.createContext<JoyrideContextInterface>({
  stepIndex: 0,
  setStepIndex: () => {},
  handleJoyrideCallback: () => {},
});

const JoyrideContextConsumer = JoyrideContext.Consumer;

const JoyrideContextProvider: React.FC = ({ children }) => {
  const history = useHistory();
  const [stepIndex, setStepIndex] = useState(parseInt(localStorage.getItem('joyrideStepIndex'), 10) || 0);

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { action, index, type } = data;
    const currentStep = joyrideSteps[index];

    if (action === 'close' && type === 'step:after') {
      setStepIndex((prevStepIndex) => prevStepIndex + 1);
      if (currentStep?.afterAction) {
        currentStep.afterAction();
      }
    }

    if (type === 'tour:end') {
      localStorage.removeItem('joyrideStepIndex');
      history.push(`/my_trainings`);
    }
  };

  useEffect(() => {
    localStorage.setItem('joyrideStepIndex', stepIndex.toString());
  }, [stepIndex]);

  return (
    <JoyrideContext.Provider value={{ stepIndex, setStepIndex, handleJoyrideCallback }}>
      {children}
    </JoyrideContext.Provider>
  );
};

export { JoyrideContextProvider, JoyrideContextConsumer, JoyrideContext };
