/* eslint-disable react-hooks/exhaustive-deps */
import React, { FunctionComponent } from 'react';
import { Box, Grid, Typography, makeStyles } from '@material-ui/core';
import ConfirmCancelModal from '../../shared/components/modal/ConfirmCancelModal';
import InformationField from '../../shared/components/InformationField';
import SelectInput from '../../shared/components/form/SelectInput';
import TextInput from '../../shared/components/form/TextInput';
import { RenewalOptionsContext } from '../contexts/RenewalOptionsContext';
import { renewalFormStore } from '../../shared/singletons';
import { useStoreObservable } from '../../shared/state/useStoreObservable.hook';
import { UserContext } from '../../auth/contexts/userContext';
import { _translateEach } from '../../shared/utils/translation.utils';

const useStyles = makeStyles({
  textInput: {
    height: 'auto',
    width: '98%',
    margin: '14px 0',
  },
  baseError: {
    marginBottom: '10px',
  },
});

interface RenewalStageChangeModalProps {
  onSuccess?: () => void;
}

const RenewalStageChangeModal: FunctionComponent<RenewalStageChangeModalProps> = (props) => {
  const { onSuccess } = props;
  const classes = useStyles();

  const { user } = React.useContext(UserContext);
  const { editStageModalOpen, formValues, formErrors, editStageModalRenewal: renewal } = useStoreObservable(
    renewalFormStore,
  );
  const { fetchChoices, renewalStageChoices } = React.useContext(RenewalOptionsContext);

  const [comment, setComment] = React.useState('');

  const t = _translateEach({
    title: 'renewals.stageChangeModal.title',
    update: 'renewals.stageChangeModal.update',
  });

  const handleFormValueChange = (key: string, value: string) => {
    renewalFormStore.setState({ formValues: { ...formValues, [key]: value } });
  };

  React.useEffect(() => {
    setComment('');
    if (formValues.student_id) {
      fetchChoices(formValues.student_id);
    }
  }, [formValues.student_id]);

  const handleFormSubmit = () => {
    renewalFormStore.createOrUpdateRenewal(formValues, renewal.student_id, renewal.id, onSuccess);
  };

  const handleCommentChange = (value: string) => {
    setComment(value);

    // Using the strange format below to automatically work with Rails accepts_nested_attributes_for
    renewalFormStore.setState({
      formValues: { ...formValues, comments_attributes: { '0': { content: value, user_id: user.id } } },
    });
  };

  return (
    <ConfirmCancelModal
      isOpen={editStageModalOpen}
      title={t.title}
      onConfirm={handleFormSubmit}
      confirmLabel={t.update}
      openStatusChanged={(isOpen) => renewalFormStore.setEditStageModalOpen(isOpen, renewal)}
    >
      <Box p={2}>
        <Grid container spacing={6}>
          <Grid item>
            <InformationField label="Student" value={renewal?.student_name} />
          </Grid>
          <Grid item>
            <InformationField label="ID" value={renewal?.student_id} />
          </Grid>
          <Grid item>
            <InformationField label="Service Type" value={renewal?.type} />
          </Grid>
        </Grid>
        <br />
        <Typography variant="h2">Renewal Details</Typography>
        <Grid container>
          <Grid item xs={10}>
            <SelectInput
              value={formValues?.renewal_stage_id}
              styleOverrides={classes.textInput}
              type="text"
              label="Renewal Stage"
              errorMessage={formErrors?.renewal_stage_id}
              valueChanged={(value: string) => handleFormValueChange('renewal_stage_id', value)}
              choices={renewalStageChoices}
            />
          </Grid>
          <Grid item xs={10}>
            <TextInput
              value={comment}
              styleOverrides={classes.textInput}
              label="Comment (Optional)"
              multiline
              rows={8}
              valueChanged={(value: string) => handleCommentChange(value)}
            />
          </Grid>
          <br />
        </Grid>
        <br />
      </Box>
    </ConfirmCancelModal>
  );
};

export default RenewalStageChangeModal;
