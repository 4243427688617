import React, { FunctionComponent } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ConfirmCancelModal from '../../shared/components/modal/ConfirmCancelModal';
import TextInput from '../../shared/components/form/TextInput';
import { communicationStore } from '../../shared/singletons';
import { useStoreObservable } from '../../shared/state/useStoreObservable.hook';
import { CommunicationState } from '../communication.store';

const useStyles = makeStyles({
  textInput: {
    height: '60px',
    width: '80%',
    margin: '10px 0',
  },
  baseError: {
    marginBottom: '10px',
  },
});

interface EditCommunicationFormModal {
  onSuccess?: () => void;
}

const EditCommunicationFormModal: FunctionComponent<EditCommunicationFormModal> = (props) => {
  const classes = useStyles();
  const { onSuccess } = props;

  const state = useStoreObservable<CommunicationState>(communicationStore);
  const { editModalOpen, formValues, communication } = state;

  const handleSubmit = (): void => {
    communicationStore.updateCommunication(formValues, communication.id, onSuccess);
  };

  const handleFormValueChange = (key: string, value: string) => {
    communicationStore.setFormValue({ ...formValues, [key]: value });
  };

  return (
    <ConfirmCancelModal
      isOpen={editModalOpen}
      title="Update Comment"
      confirmLabel="Submit"
      openStatusChanged={(isOpen) => communicationStore.setState({ editModalOpen: isOpen })}
      onConfirm={handleSubmit}
    >
      <form noValidate autoComplete="off">
        {/* <TextInput
          styleOverrides={classes.textInput}
          value={formValues?.initiator}
          label="Who initiated contact"
          type="text"
          valueChanged={(value: string) => handleFormValueChange('initiator', value)}
        /> */}
        <TextInput
          styleOverrides={classes.textInput}
          value={formValues?.contact_name}
          label="Who was contacted"
          type="text"
          valueChanged={(value: string) => handleFormValueChange('contact_name', value)}
        />
        <TextInput
          styleOverrides={classes.textInput}
          value={formValues?.contact_method}
          label="Contact Method"
          type="text"
          valueChanged={(value: string) => handleFormValueChange('contact_method', value)}
        />
        <TextInput
          styleOverrides={classes.textInput}
          value={formValues?.contact_on}
          label="Date of Contact"
          type="date"
          shrinkLabel
          valueChanged={(value: string) => handleFormValueChange('contact_on', value)}
        />
        <TextInput
          styleOverrides={classes.textInput}
          value={formValues?.contact_reason}
          label="Contact Reason (optional)"
          type="text"
          valueChanged={(value: string) => handleFormValueChange('contact_reason', value)}
        />
        <TextInput
          styleOverrides={classes.textInput}
          value={formValues?.contact_result}
          label="Communication"
          multiline
          rows={8}
          valueChanged={(value: string) => handleFormValueChange('contact_result', value)}
        />
      </form>
    </ConfirmCancelModal>
  );
};

export default EditCommunicationFormModal;
