import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@material-ui/core';
import { UserTicketsContext } from '../contexts/UserTicketsContext';

function ReferralTicketCompletedDialog() {
  const history = useHistory();
  const { referralCompleteDialogOpen, setReferralCompleteDialogOpen, referralToEdit } = useContext(UserTicketsContext);

  return (
    <Dialog
      open={referralCompleteDialogOpen}
      onClose={() => setReferralCompleteDialogOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Success!</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">Referral Ticket Completed</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => history.push(`students/${referralToEdit?.student_id}/services`)}>
          View Student's Services
        </Button>
        <Button onClick={() => history.push(`/user_tickets`)}>Back to Tickets</Button>
      </DialogActions>
    </Dialog>
  );
}

export default ReferralTicketCompletedDialog;
