/* eslint-disable react-hooks/exhaustive-deps */
import React, { FunctionComponent, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Grid,
  Button,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { Service } from '../../services/model';
import { SelectOption } from '../../shared/common.model';
import TextInput from '../../shared/components/form/TextInput';
import { API_ENDPOINT } from '../../../config/env';
import SelectInput from '../../shared/components/form/SelectInput';
import { RenewalOptionsContext } from '../../renewals/contexts/RenewalOptionsContext';
import { axios } from '../../shared/singletons';
import ErrorAlert from '../../shared/components/ErrorAlert';
import InformationField from '../../shared/components/InformationField';

const useStyles = makeStyles({
  textInput: {
    height: '60px',
    width: '98%',
    margin: '10px 0',
  },
  baseError: {
    marginBottom: '10px',
  },
});

interface RenewalAccordionProps {
  service?: Service;
  idx?: number;
  defaultRenewalType?: number;
}

const RenewalAccordion: FunctionComponent<RenewalAccordionProps> = (props) => {
  const { service, idx, defaultRenewalType } = props;
  const classes = useStyles();

  const { fetchChoices, renewalTypeChoices, renewalStageChoices, ownerChoices } = React.useContext(
    RenewalOptionsContext,
  );

  const [formValues, setFormValues] = useState<any>({});
  const [formErrors, setFormErrors] = useState<any>({});
  const [formErrorAlertVisible, setFormErrorAlertVisible] = useState(false);
  const [expanded, setExpanded] = useState<boolean>(true);

  React.useEffect(() => {
    if (service?.renewal) {
      setFormValues({
        id: service?.renewal?.id,
        student_rr_date: service?.renewal?.student_rr_date,
        renewal_type_id: service?.renewal?.renewal_type_id,
        renewal_stage_id: service?.renewal?.renewal_stage_id,
        active: service?.renewal?.active,
        user_ids: service?.renewal?.user_ids,
        referral_service_id: service.id,
      });
    } else {
      setFormValues({
        referral_service_id: service?.id,
        renewal_type_id: defaultRenewalType || 1,
        active: 'true',
      });
    }
  }, [service, defaultRenewalType]);

  const handleFormValueChange = (key: string, value: string) => {
    if (key === 'user_ids') {
      if (formValues[key] === undefined) {
        formValues[key] = [];
      }

      if (formValues[key]?.find((x) => x === value)) {
        formValues[key] = formValues[key].filter((p) => p !== value);
      } else {
        formValues[key] = [...formValues[key], value];
      }

      setFormValues({ ...formValues, [key]: formValues[key] });
    } else {
      setFormValues({ ...formValues, [key]: value });
    }
  };

  const handleSubmit = () => {
    axios({
      method: 'post',
      url: `${API_ENDPOINT}/patients/${service?.student_id}/renewals.json`,
      data: { renewal: formValues },
      headers: { 'Content-Type': 'application/json' },
    })
      .then((response) => {
        setExpanded(false);
      })
      .catch((error) => {
        setFormErrors(error.response?.data);
        setFormErrorAlertVisible(true);
      });
  };

  React.useEffect(() => {
    fetchChoices(service?.student_id);
  }, [service]);

  const renewalTypeName = renewalTypeChoices?.find((x) => x.value === formValues?.renewal_type_id)?.label;

  return (
    <Accordion expanded={expanded}>
      <AccordionSummary onClick={() => setExpanded(!expanded)} expandIcon="▼">
        {idx ? (
          <Typography variant="h2" component="h2">{`${idx + 1}) ${service?.service_type} Renewal`}</Typography>
        ) : (
          <Typography variant="h2" component="h2">{`${service?.service_type} Renewal`}</Typography>
        )}
      </AccordionSummary>
      <AccordionDetails>
        <Grid container>
          <Grid item xs={12}>
            <form noValidate autoComplete="off">
              <SelectInput
                styleOverrides={classes.textInput}
                type="text"
                label="Renewal Type"
                value={formValues?.renewal_type_id}
                valueChanged={(value: string) => handleFormValueChange('renewal_type_id', value)}
                choices={renewalTypeChoices}
                defaultChoice={{ value: '', label: 'None' }}
              />
              {renewalTypeName === 'RR' ? (
                <TextInput
                  styleOverrides={classes.textInput}
                  type="date"
                  shrinkLabel
                  label="Student RR Date"
                  value={formValues?.student_rr_date}
                  valueChanged={(value: string) => handleFormValueChange('student_rr_date', value)}
                />
              ) : (
                <InformationField label="Service End Date" value={service?.end_date} />
              )}
              <SelectInput
                styleOverrides={classes.textInput}
                type="text"
                label="Renewal Stage"
                value={formValues?.renewal_stage_id}
                valueChanged={(value: string) => handleFormValueChange('renewal_stage_id', value)}
                choices={renewalStageChoices}
                defaultChoice={{ value: '', label: 'None' }}
              />
              <SelectInput
                styleOverrides={classes.textInput}
                type="text"
                label="Status"
                value={formValues?.active}
                valueChanged={(value: string) => handleFormValueChange('active', value)}
                choices={[
                  { label: 'Active', value: 'true' },
                  { label: 'Inactive', value: 'false' },
                ]}
              />
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography variant="h3" component="h3">
                    Owners
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <FormGroup row>
                    <Grid container>
                      {ownerChoices?.map((owner: SelectOption) => (
                        <Grid key={owner.label} item md={6} xs={12}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                name={owner.label}
                                value={owner.value}
                                color="primary"
                                onChange={() => {
                                  handleFormValueChange('user_ids', owner.value);
                                }}
                              />
                            }
                            label={owner.label}
                            checked={formValues?.user_ids?.includes(owner?.value)}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </FormGroup>
                </Grid>
              </Grid>
              <br />
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <ErrorAlert display={formErrorAlertVisible} onClose={() => setFormErrorAlertVisible(false)}>
                    {formErrors?.base}
                  </ErrorAlert>
                </Grid>

                <Button variant="contained" color="primary" onClick={handleSubmit}>
                  Save Renewal
                </Button>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default RenewalAccordion;
