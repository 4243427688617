import React, { FunctionComponent, useEffect } from 'react';
import RenewalTypesList from '../components/RenewalTypesList';
import { useStoreObservable } from '../../../shared/state/useStoreObservable.hook';
import { RenewalTypeState } from '../renewalType.store';
import { renewalTypeStore } from '../../../shared/singletons';

export type RenewalTypesPageProps = Record<string, unknown>;

const RenewalTypesPage: FunctionComponent<RenewalTypesPageProps> = () => {
  const renewalTypeState = useStoreObservable<RenewalTypeState>(renewalTypeStore);
  const { records, isLoading } = renewalTypeState;

  useEffect(() => {
    renewalTypeStore.fetchRecords();
  }, []);

  return <RenewalTypesList records={records} isLoading={isLoading} />;
};

export default RenewalTypesPage;
