import React, { FC } from 'react';

import styled from 'styled-components';

import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import { SelectElementEvent } from '../../shared/common.model';

import { FilterProps } from '../../shared/components/FilterBar';

const useStyles = makeStyles(() =>
  createStyles({
    inputRoot: {
      backgroundColor: 'white',
    },
    formControl: {
      width: '100%',
      background: 'white',
      height: '38px',
    },
  }),
);

const StyledSelectFormControl = styled(FormControl)`
  .MuiSelect-select {
    padding: 10px;
  }
  .MuiInputLabel-root {
    margin-top: -6px;
  }
  .MuiInputLabel-shrink {
    margin-top: 0;
  }
`;

interface FilterRowProps {
  filters: FilterProps[];
  filterValues: any;
  setFilterValues: any;
  fetchAppointments: any;
}

const FilterRow: FC<FilterRowProps> = (props) => {
  let classes = useStyles();

  let { filters, filterValues, setFilterValues, fetchAppointments } = props;

  return (
    <Grid container spacing={1} alignItems="center">
      {filters.map((filter) => (
        <Grid item xs={12} md={2} key={filter.label}>
          {filter.options ? (
            <StyledSelectFormControl variant="outlined" className={classes.formControl}>
              <InputLabel htmlFor="outlined-age-native-simple">{filter.label}</InputLabel>
              <Select
                variant="outlined"
                placeholder={filter.label}
                label={filter.label}
                type={filter.type}
                value={filterValues[filter.selector] || ''}
                onChange={(e: SelectElementEvent) =>
                  setFilterValues({ ...filterValues, [filter.selector]: e.target.value })
                }
              >
                <MenuItem value=" " key="">
                  Any
                </MenuItem>
                {filter.options.map((option) => (
                  <MenuItem value={option.value} key={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </StyledSelectFormControl>
          ) : (
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              label={filter.type === 'date' ? filter.label : null}
              type={filter.type}
              value={filterValues[filter.selector] || ''}
              disabled={filter.locked}
              InputLabelProps={{
                shrink: true,
              }}
              placeholder={filter.label}
              onChange={(e: SelectElementEvent) =>
                setFilterValues({ ...filterValues, [filter.selector]: e.target.value })
              }
              onKeyPress={(e) => {
                if (e.charCode === 13) {
                  e.preventDefault();
                  fetchAppointments();
                }
              }}
              InputProps={{
                className: classes.inputRoot,
              }}
            />
          )}
        </Grid>
      ))}
    </Grid>
  );
};

export default FilterRow;
