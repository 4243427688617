import React, { FunctionComponent } from 'react';
import { Box, Grid, makeStyles } from '@material-ui/core';
import { axios, userFormStore, userRecordStore } from '../../shared/singletons';
import ConfirmCancelModal from '../../shared/components/modal/ConfirmCancelModal';
import TextInput from '../../shared/components/form/TextInput';
import { useStoreObservable } from '../../shared/state/useStoreObservable.hook';
import { UserResponse, User } from '../../users/model';
import { _translateEach } from '../../shared/utils/translation.utils';

const useStyles = makeStyles({
  textInput: {
    height: 'auto',
    width: '98%',
    margin: '14px 0',
  },
  baseError: {
    marginBottom: '10px',
  },
});

interface AccountEditModalProps {
  user: User;
}

const AccountEditModal: FunctionComponent<AccountEditModalProps> = (props) => {
  const { accountEditModal } = useStoreObservable(userFormStore);
  const { user } = props;
  const classes = useStyles();
  const [currentPassword, setCurrentPassword] = React.useState('');
  const [newPassword, setNewPassword] = React.useState('');
  const [currentPasswordError, setCurrentPasswordError] = React.useState('');
  const [newPasswordError, setNewPasswordError] = React.useState('');

  const t = _translateEach({
    title: 'users.editAccountModal.title',
    update: 'users.editAccountModal.update',
  });

  React.useEffect(() => {
    userRecordStore.fetchRecord(user.id);
  }, [user]);

  const handleFormSubmit = () => {
    axios
      .put<string, UserResponse>(`sessions/${user.id}.json`, {
        current_password: currentPassword,
        new_password: newPassword,
        headers: { 'Content-Type': 'application/json' },
      })
      .then(() => {
        userFormStore.setAccountEditModalOpen(false, user);
      })
      .catch((error) => {
        setCurrentPasswordError(error.response?.data?.current_password);
        setNewPasswordError(error.response?.data?.new_password);
      });
  };

  return (
    <ConfirmCancelModal
      isOpen={accountEditModal}
      title={t.title}
      onConfirm={handleFormSubmit}
      confirmLabel={t.update}
      openStatusChanged={(isOpen) => userFormStore.setAccountEditModalOpen(isOpen, user)}
    >
      <Box p={2}>
        <Grid container>
          <Grid item xs={10}>
            <TextInput
              value={currentPassword}
              styleOverrides={classes.textInput}
              type="password"
              errorMessage={currentPasswordError}
              label="Current Password"
              valueChanged={(value: string) => setCurrentPassword(value)}
            />
          </Grid>
          <Grid item xs={10}>
            <TextInput
              value={newPassword}
              styleOverrides={classes.textInput}
              type="password"
              errorMessage={newPasswordError}
              label="New Password"
              valueChanged={(value: string) => setNewPassword(value)}
            />
          </Grid>
          <br />
        </Grid>
        <br />
      </Box>
    </ConfirmCancelModal>
  );
};

export default AccountEditModal;
