import React, { useContext } from 'react';
import { Grid, Card, Typography, makeStyles } from '@material-ui/core';
import { AppointmentsContext } from '../contexts/AppointmentsContext';
import { AppointmentQuarter } from '../model';

const useStyles = makeStyles((theme) => ({
  hoverHighlight: {
    transition: 'background-color 0.3s ease',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#f0f0f0',
    },
  },
}));

type YearlongAppointmentsQuarterListProps = {};

const sumOfMinutes = (quarter) => {
  let sum = 0;
  if (quarter && Array.isArray(quarter)) {
    sum = quarter.reduce((accumulator, currentObject) => {
      return accumulator + (currentObject.duration || 0);
    }, 0);
  }
  return sum;
};

function YearlongAppointmentsQuarterList(props: YearlongAppointmentsQuarterListProps) {
  const classes = useStyles();

  const { appointmentYear, setEditModalOpen, setEditModalInfo } = useContext(AppointmentsContext);

  const handleListClick = (event, indexX, indexY) => {
    const fullEvent = { ...event, extendedProps: { indexX: indexX, indexY: indexY } };
    setEditModalOpen(true);
    setEditModalInfo(fullEvent);
  };

  return (
    <Grid container>
      {appointmentYear?.map((quarter: AppointmentQuarter, indexX) => (
        <Grid item xs={3} key={`quarter${indexX}`}>
          <Card style={{ margin: 8, padding: 16 }}>
            <Grid style={{ display: 'flex', justifyContent: 'center' }}>
              <Typography variant="h3">Quarter {indexX + 1}</Typography>
            </Grid>
            {quarter?.map(
              (appointment, indexY) =>
                appointment.title.includes('min') && (
                  <p
                    key={appointment.list_friendly_date}
                    onClick={() => handleListClick(appointment, indexX, indexY)}
                    className={classes.hoverHighlight}
                  >
                    {appointment.list_friendly_date}{' '}
                    <span style={{ fontWeight: 'bold' }}>{appointment.duration} min</span>
                  </p>
                ),
            )}
            <p>
              Total: <span style={{ fontWeight: 'bold' }}>{sumOfMinutes(quarter)} </span>min
            </p>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
}

export default YearlongAppointmentsQuarterList;
