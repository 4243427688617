import React, { FunctionComponent } from 'react';
import Breadcrumb from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { makeStyles } from '@material-ui/core/styles';

import { Colors } from '../style/colors';

const useStyles = makeStyles({
  crumb: {
    color: Colors.PurpleThree,
    margin: -4,
  },
  disabled: {
    color: Colors.GreyText,
  },
});

interface BreadcrumbProps {
  links?: BreadcrumbLink[];
}
export interface BreadcrumbLink {
  href: string;
  text: string;
}

const Breadcrumbs: FunctionComponent<BreadcrumbProps> = (props: BreadcrumbProps) => {
  const { links } = props;
  const classes = useStyles();

  return (
    <Breadcrumb
      style={{ marginLeft: 7 }}
      separator={<NavigateNextIcon fontSize="small" className={classes.crumb} />}
      aria-label="breadcrumb"
    >
      {links.map((link, index) => (
        <Link href={link.href} key={link.href} underline="none">
          <Typography className={index !== links.length - 1 ? classes.crumb : classes.disabled}>{link.text}</Typography>
        </Link>
      ))}
    </Breadcrumb>
  );
};

export default Breadcrumbs;
