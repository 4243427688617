import React, { FunctionComponent } from 'react';
// import { useHistory } from 'react-router-dom';
import { Box, Divider, ListItemIcon, MenuItem, Typography, makeStyles } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import CustomMaterialMenu from '../../shared/components/CustomMaterialMenu';
import DeleteModal from '../../shared/components/modal/DeleteModal';
import { DischargeStudentContext } from '../../students/contexts/DischargeStudentContext';
import { Renewal } from '../../renewals/model';
import { renewalFormStore } from '../../shared/singletons';

interface AppointmentlRowMenuProps {
  renewal: Renewal;
  onDelete: () => void;
}

const useStyles = makeStyles((theme) => ({
  listItemIcon: {
    minWidth: '24px',
  },
  modalText: {
    fontSize: '20px',
    marginLeft: '20%',
    marginBottom: '20px',
    width: '60%',

    [theme.breakpoints.down('sm')]: {
      marginLeft: '2%',
      width: '96%',
    },
  },
}));

const AppointmentRowMenu: FunctionComponent<AppointmentlRowMenuProps> = (props: AppointmentlRowMenuProps) => {
  const { renewal, onDelete } = props;
  // const history = useHistory();
  const classes = useStyles();

  const [isDestroyModalOpen, setDestroyModalOpen] = React.useState<boolean>(false);
  // const [, setEditModalOpen] = React.useState<boolean>(false);

  const { dischargeRenewal } = React.useContext(DischargeStudentContext);

  const handleEditClick = () => {
    renewalFormStore.setEditModalOpen(true, renewal);
  };

  const handleDischargeRenewal = () => {
    dischargeRenewal(renewal?.id, onDelete);
  };

  return (
    <Box>
      <DeleteModal
        isOpen={isDestroyModalOpen}
        openStatusChanged={setDestroyModalOpen}
        onDelete={handleDischargeRenewal}
        confirmLabel="Discharge"
      >
        <Typography align="center" className={classes.modalText} variant="h5">
          Are you sure you want to discharge this renewal? This action cannot be undone.
        </Typography>
      </DeleteModal>
      <CustomMaterialMenu size="small">
        <MenuItem onClick={handleEditClick}>
          <ListItemIcon className={classes.listItemIcon}>
            <EditIcon fontSize="small" color="secondary" />
          </ListItemIcon>
          <Typography variant="inherit">Edit Renewal</Typography>
        </MenuItem>
        <Divider />
        <MenuItem onClick={() => setDestroyModalOpen(true)}>
          <ListItemIcon className={classes.listItemIcon}>
            <DeleteIcon fontSize="small" color="secondary" />
          </ListItemIcon>
          <Typography variant="inherit">Discharge Renewal</Typography>
        </MenuItem>
      </CustomMaterialMenu>
    </Box>
  );
};

export default AppointmentRowMenu;
