import React, { FunctionComponent } from 'react';
import DashboardReports from '../components/DashboardReports';
import { UserContext } from '../../auth/contexts/userContext';

export type ReportsPageProps = Record<string, unknown>;

const ReportsPage: FunctionComponent<ReportsPageProps> = () => {
  const { currentUserHasAnyRole } = React.useContext(UserContext);

  return currentUserHasAnyRole(['admin', 'supervisor', 'accountant', 'system_owner', 'services_coordinator']) ? (
    <DashboardReports />
  ) : (
    <p>You do not have valid permissions to view this page</p>
  );
};

export default ReportsPage;
