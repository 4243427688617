import React, { FunctionComponent, ChangeEvent } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Typography, makeStyles } from '@material-ui/core';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import TextInput from '../../shared/components/form/TextInput';
import SelectInput from '../../shared/components/form/SelectInput';
import { _translateEach } from '../../shared/utils/translation.utils';
import { studentBaseStore, studentFormStore } from '../../shared/singletons';
import { useStoreObservable } from '../../shared/state/useStoreObservable.hook';
import { ReferralsContext } from '../contexts/ReferralsContext';
import { Student } from '../../students/model';

const useStyles = makeStyles({
  textInput: {
    height: '60px',
    width: '80%',
    margin: '10px',
  },
  formTitle: {
    marginLeft: '12px',
  },
});

interface ReferralNewStudentFormProps {
  student?: Student;
}

const ReferralNewStudentForm: FunctionComponent<ReferralNewStudentFormProps> = (props) => {
  const classes = useStyles();
  const history = useHistory();

  const { formValues } = useStoreObservable(studentFormStore);
  const { gradeLevelChoices } = useStoreObservable(studentBaseStore);
  const { createReferralStudent, locationChoices, schoolChoices, formErrors } = React.useContext(ReferralsContext);

  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [duplicateStudentId] = React.useState<string>();

  const t = _translateEach({
    firstName: 'students.form.firstName',
    lastName: 'students.form.lastName',
    school: 'students.form.school',
    teacherName: 'students.form.teacherName',
    roomNumber: 'students.form.roomNumber',
    dateOfBirth: 'students.form.dateOfBirth',
    preferredLocation: 'students.form.preferredLocation',
    gradeLevel: 'students.form.gradeLevel',
    defaultSelectOption: 'selectOption.defaultSelectOption',
    primaryGuardian: 'students.emergencyContacts.primaryGuardian',
    guardianName: 'students.emergencyContacts.guardianName',
    guardianContactPrimary: 'students.emergencyContacts.guardianContactPrimary',
    guardianContactSecondary: 'students.emergencyContacts.guardianContactSecondary',
    guardianContactAlternate: 'students.emergencyContacts.guardianContactAlternate',
    emailGuardian: 'students.emergencyContacts.emailGuardian',
    emergencyContact: 'students.emergencyContacts.emergencyContact',
    emergencyName: 'students.emergencyContacts.emergencyName',
    emergencyRelationship: 'students.emergencyContacts.emergencyRelationship',
    emergencyPhone: 'students.emergencyContacts.emergencyPhone',
    address1: 'students.form.address1',
    address2: 'students.form.address2',
    state: 'students.form.state',
    city: 'students.form.city',
    zip: 'students.form.zip',
  });

  const handleFormValueChange = (key: string, value: string) => {
    studentFormStore.setState({ formValues: { ...formValues, [key]: value } });
  };

  React.useEffect(() => {
    studentBaseStore.fetchChoices();
  }, []);

  React.useEffect(() => {
    studentFormStore.setState({ formValues: { ...formValues, payer_id: schoolChoices?.[0]?.value } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClose = () => {
    setDialogOpen(false);
  };

  const requiredFields = [
    'first_name',
    'last_name',
    'birthdate',
    'payer_id',
    'teacher',
    'room_number',
    'grade_level_id',
    'location_id',
  ];

  const shouldDisableSubmit = requiredFields.some((field) => !formValues[field]);

  return (
    <form noValidate autoComplete="off">
      <Dialog
        open={dialogOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Duplicate Student</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {`A student already exists with the name ${formValues?.first_name} ${formValues?.last_name} and the
            birthdate 04/01/2000. Please add a referral for the existing student using the button below or reach out to
            our support team by sending an email to services@thehopelearningcenter.com.`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Go back</Button>
          <Button onClick={() => history.push(`/referrals/${duplicateStudentId}/review_student`)}>
            Add Referral for Existing Student
          </Button>
        </DialogActions>
      </Dialog>

      <Typography className={classes.formTitle} variant="h2" component="h2">
        Student Details
      </Typography>
      <TextInput
        styleOverrides={classes.textInput}
        type="text"
        label="First Name (Required)"
        errorMessage={formErrors?.first_name}
        value={formValues?.first_name}
        valueChanged={(value) => handleFormValueChange('first_name', value)}
      />
      <TextInput
        styleOverrides={classes.textInput}
        type="text"
        label="Last Name (Required)"
        errorMessage={formErrors?.last_name}
        value={formValues?.last_name}
        valueChanged={(value) => handleFormValueChange('last_name', value)}
      />
      <TextInput
        styleOverrides={classes.textInput}
        type="date"
        shrinkLabel
        label="Date of Birth (Required)"
        errorMessage={formErrors?.birthdate}
        value={formValues?.birthdate}
        valueChanged={(value) => handleFormValueChange('birthdate', value)}
      />
      <SelectInput
        styleOverrides={classes.textInput}
        type="text"
        label="School (Required)"
        errorMessage={formErrors?.payer_id}
        value={formValues?.payer_id}
        choices={schoolChoices}
        onChange={(e: ChangeEvent<HTMLInputElement>) => handleFormValueChange('payer_id', e.target.value)}
      />
      <TextInput
        styleOverrides={classes.textInput}
        type="text"
        label={'School-Issued ID (Optional)'}
        errorMessage={formErrors?.school_issued_id}
        value={formValues?.school_issued_id}
        valueChanged={(value) => handleFormValueChange('school_issued_id', value)}
      />
      <TextInput
        styleOverrides={classes.textInput}
        type="text"
        label={"Teacher's Name (Required)"}
        errorMessage={formErrors?.teacher}
        value={formValues?.teacher}
        valueChanged={(value) => handleFormValueChange('teacher', value)}
      />
      <TextInput
        styleOverrides={classes.textInput}
        type="text"
        label="Room Number (Required)"
        errorMessage={formErrors?.room_number}
        value={formValues?.room_number}
        valueChanged={(value) => handleFormValueChange('room_number', value)}
      />
      <SelectInput
        styleOverrides={classes.textInput}
        type="text"
        label="Preferred Location (Required)"
        errorMessage={formErrors?.location_id}
        value={formValues?.location_id}
        choices={locationChoices}
        onChange={(e: ChangeEvent<HTMLInputElement>) => handleFormValueChange('location_id', e.target.value)}
      />
      <SelectInput
        styleOverrides={classes.textInput}
        type="text"
        label="Grade Level (Required)"
        errorMessage={formErrors?.grade_level_id}
        value={formValues?.grade_level_id}
        choices={gradeLevelChoices}
        onChange={(e: ChangeEvent<HTMLInputElement>) => handleFormValueChange('grade_level_id', e.target.value)}
      />
      <TextInput
        styleOverrides={classes.textInput}
        type="text"
        label="Email (Optional)"
        errorMessage={formErrors?.email_student}
        value={formValues?.email_student}
        valueChanged={(value) => handleFormValueChange('email_student', value)}
      />
      <Typography className={classes.formTitle} variant="h2" component="h2">
        Primary Guardian (Optional)
      </Typography>
      <TextInput
        styleOverrides={classes.textInput}
        type="text"
        label={t.guardianName}
        errorMessage={formErrors?.gaurdian_name}
        value={formValues?.gaurdian_name}
        valueChanged={(value) => handleFormValueChange('gaurdian_name', value)}
      />
      <TextInput
        styleOverrides={classes.textInput}
        type="text"
        label={t.guardianContactPrimary}
        errorMessage={formErrors?.gaurdian_contact_primary}
        value={formValues?.gaurdian_contact_primary}
        valueChanged={(value) => handleFormValueChange('gaurdian_contact_primary', value)}
      />
      <TextInput
        styleOverrides={classes.textInput}
        type="text"
        label={t.guardianContactSecondary}
        errorMessage={formErrors?.gaurdian_contact_secondary}
        value={formValues?.gaurdian_contact_secondary}
        valueChanged={(value) => handleFormValueChange('gaurdian_contact_secondary', value)}
      />
      <TextInput
        styleOverrides={classes.textInput}
        type="text"
        label={t.guardianContactAlternate}
        errorMessage={formErrors?.gaurdian_contact_alternate}
        value={formValues?.gaurdian_contact_alternate}
        valueChanged={(value) => handleFormValueChange('gaurdian_contact_alternate', value)}
      />
      <TextInput
        styleOverrides={classes.textInput}
        type="text"
        label={t.emailGuardian}
        errorMessage={formErrors?.email_gaurdian}
        value={formValues?.email_gaurdian}
        valueChanged={(value) => handleFormValueChange('email_gaurdian', value)}
      />
      <Typography className={classes.formTitle} variant="h2" component="h2">
        Student Address (Optional)
      </Typography>
      <TextInput
        styleOverrides={classes.textInput}
        type="text"
        label={t.address1}
        errorMessage={formErrors?.address_line1}
        value={formValues.address_line1}
        valueChanged={(value) => handleFormValueChange('address_line1', value)}
      />
      <TextInput
        styleOverrides={classes.textInput}
        type="text"
        label={t.address2}
        errorMessage={formErrors?.address_line2}
        value={formValues.address_line2}
        valueChanged={(value) => handleFormValueChange('address_line2', value)}
      />
      <TextInput
        styleOverrides={classes.textInput}
        type="text"
        label={t.city}
        errorMessage={formErrors?.address_city}
        value={formValues.address_city}
        valueChanged={(value) => handleFormValueChange('address_city', value)}
      />
      <TextInput
        styleOverrides={classes.textInput}
        type="text"
        label={t.state}
        errorMessage={formErrors?.address_state}
        value={formValues.address_state}
        valueChanged={(value) => handleFormValueChange('address_state', value)}
      />
      <TextInput
        styleOverrides={classes.textInput}
        type="text"
        label={t.zip}
        errorMessage={formErrors?.address_zip}
        value={formValues.address_zip}
        valueChanged={(value) => handleFormValueChange('address_zip', value)}
      />
      <Button
        variant="contained"
        color="primary"
        onClick={() => createReferralStudent(formValues)}
        disabled={shouldDisableSubmit}
      >
        Submit New Student
      </Button>
    </form>
  );
};

export default ReferralNewStudentForm;
