/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import { Grid, Typography, Button } from '@material-ui/core';
import ConfirmCancelModal from '../../shared/components/modal/ConfirmCancelModal';
import TextInput from '../../shared/components/form/TextInput';
import { AppointmentsContext } from '../contexts/AppointmentsContext';

type YearlongAppointmentEditModalProps = {};

function YearlongAppointmentEditModal(props: YearlongAppointmentEditModalProps) {
  const { appointmentYear, setAppointmentYear, editModalOpen, setEditModalOpen, editModalInfo } = useContext(
    AppointmentsContext,
  );

  const indexX = editModalInfo?.extendedProps?.indexX;
  const indexY = editModalInfo?.extendedProps?.indexY;

  const [dateForDisplay, setDateForDisplay] = useState('');
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [dateForInput, setDateForInput] = useState('');
  const [appointmentDeleted, setAppointmentDeleted] = useState(false);
  const [showConfirmButton, setShowConfirmButton] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');

  const deleteButtonText = appointmentDeleted ? 'Appointment Deleted' : 'Delete Appointment';
  const deleteButtonColor = appointmentDeleted ? '#FFFFFF' : '#F44F64';

  const initialLoadFormattedDateForDisplay = () => {
    const date = new Date(editModalInfo.start);

    return date.toLocaleDateString('en-US', {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  };

  const initialLoadFormattedDateForInput = () => {
    const date = new Date(editModalInfo.start);

    return date.toISOString().split('T')[0];
  };

  const initialLoadFormattedTime = (time) => {
    const date = new Date(time);
    const initialLoadFormattedTime = date.toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    });
    return initialLoadFormattedTime;
  };

  useEffect(() => {
    if (editModalInfo?.start) {
      setDateForDisplay(initialLoadFormattedDateForDisplay());
      setDateForInput(initialLoadFormattedDateForInput());
      setStartTime(initialLoadFormattedTime(editModalInfo.start));
      setEndTime(initialLoadFormattedTime(editModalInfo.end));
    }
  }, [editModalInfo]);

  useEffect(() => {
    if (endTime <= startTime) {
      setShowConfirmButton(false);
      setErrorMessage('End time must be after start time');
    } else {
      setShowConfirmButton(true);
      setErrorMessage('');
    }
  }, [startTime, endTime]);

  const format12HourTime = (time24) => {
    const [hour, minute] = time24.split(':');
    const hourNum = parseInt(hour, 10);
    const amPm = hourNum >= 12 ? 'PM' : 'AM';
    const formattedHour = hourNum % 12 || 12; // Convert 00 to 12 for 12 AM
    return `${formattedHour.toString().padStart(2, '0')}:${minute} ${amPm}`;
  };

  const formatDateForSaving = (time) => {
    const inputDate = new Date(dateForInput);
    const formattedTimeForSaving = format12HourTime(time);

    const timeZoneOffset = inputDate.getTimezoneOffset() * 60000; // Convert offset to milliseconds
    const adjustedDate = new Date(inputDate.getTime() + timeZoneOffset);
    const weekday = adjustedDate.toLocaleDateString('en-US', { weekday: 'long' });

    return `${weekday}, ${
      adjustedDate.getMonth() + 1
    }-${adjustedDate.getDate()}-${adjustedDate.getFullYear()} ${formattedTimeForSaving}`;
  };

  const formatListFriendlyDate = () => {
    const inputDate = new Date(dateForInput);
    const timeZoneOffset = inputDate.getTimezoneOffset() * 60000; // Convert offset to milliseconds
    const adjustedDate = new Date(inputDate.getTime() + timeZoneOffset);

    const shortWeekday = adjustedDate.toLocaleDateString('en-US', { weekday: 'short' });
    const year = inputDate.getUTCFullYear();
    const month = inputDate.getUTCMonth() + 1;
    const day = inputDate.getUTCDate();

    return `${shortWeekday}, ${month.toString().padStart(2, '0')}-${day}-${year}`;
  };

  const updatedAppointment = () => {
    const thisAppointment = { ...appointmentYear[indexX][indexY] };

    thisAppointment.start = formatDateForSaving(startTime);
    thisAppointment.end = formatDateForSaving(endTime);

    const startMillis = new Date(thisAppointment.start).getTime();
    const endMillis = new Date(thisAppointment.end).getTime();
    const newDuration = (endMillis - startMillis) / 1000 / 60;

    thisAppointment.duration = newDuration;
    thisAppointment.title = `${newDuration} min`;
    thisAppointment.list_friendly_date = formatListFriendlyDate();

    return thisAppointment;
  };

  const handleConfirm = () => {
    const updatedAppointments = [...appointmentYear];

    if (appointmentDeleted) {
      updatedAppointments[indexX].splice(indexY, 1);
    } else {
      updatedAppointments[indexX][indexY] = updatedAppointment();
    }

    setAppointmentYear(updatedAppointments);
    setEditModalOpen(false);
  };

  const buttonEndTime = (minutes) => {
    // To convert to a format where we can add minutes, we need to convert to milliseconds
    // Use a fixed date (e.g., today's date) to combine it with the start time
    const today = new Date();
    const dateString = today.toISOString().split('T')[0];
    const dateTimeString = `${dateString} ${startTime}`;
    const startTimeDate = new Date(dateTimeString);
    const endTimeMillis = startTimeDate.getTime() + minutes * 60 * 1000;

    // Convert back for timepicker
    const newEndTime = new Date(endTimeMillis);
    const formattedEndTime = newEndTime.toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    });

    setEndTime(formattedEndTime);
  };

  return (
    <Grid style={{ zIndex: 1000, position: 'fixed' }}>
      <ConfirmCancelModal
        title="Edit Appointment"
        cancelLabel="Cancel"
        confirmLabel="Update"
        width="768px"
        hasCancelButton
        hasConfirmButton={showConfirmButton}
        isOpen={editModalOpen}
        height="600px"
        onConfirm={handleConfirm}
        openStatusChanged={(isOpen) => setEditModalOpen(isOpen)}
      >
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Typography variant="h2">{dateForDisplay}</Typography>
          </Grid>
          <Grid item xs={4}>
            <TextInput
              label="Date"
              type="date"
              shrinkLabel
              value={dateForInput}
              valueChanged={(value) => setDateForInput(value)}
              disabled={appointmentDeleted}
            />
          </Grid>
          <Grid item xs={4}>
            <TextInput
              label="Start Time"
              type="time"
              shrinkLabel
              value={startTime}
              valueChanged={(value) => setStartTime(value)}
              disabled={appointmentDeleted}
            />
          </Grid>
          <Grid item xs={4}>
            <TextInput
              label="End Time"
              type="time"
              shrinkLabel
              value={endTime}
              valueChanged={(value) => setEndTime(value)}
              disabled={appointmentDeleted}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" style={{ color: 'red' }}>
              {errorMessage}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" color="primary" onClick={() => buttonEndTime(15)}>
              15 min
            </Button>
            &emsp;
            <Button variant="contained" color="primary" onClick={() => buttonEndTime(30)}>
              30 min
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              variant="contained"
              style={{ backgroundColor: deleteButtonColor, marginLeft: 7 }}
              onClick={() => setAppointmentDeleted(true)}
            >
              {deleteButtonText}
            </Button>
            &emsp;
            {appointmentDeleted && (
              <Button variant="contained" color="primary" onClick={() => setAppointmentDeleted(false)}>
                Undo
              </Button>
            )}
          </Grid>
        </Grid>
      </ConfirmCancelModal>
    </Grid>
  );
}

export default YearlongAppointmentEditModal;
