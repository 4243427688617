import { axios, alertStore } from '../shared/singletons';
import { BaseStore } from '../shared/state/base.store';
import { API_ENDPOINT } from '../../config/env';
import { CommunicationResponse, CommunicationFormValues, Communication } from './model';

export class CommunicationState {
  isDeleting: boolean;

  communication: Communication;
  formModalOpen: boolean;
  editModalOpen: boolean;
  isCreating: boolean;
  formValues: CommunicationFormValues;

  static create(props: Partial<CommunicationState>): CommunicationState {
    const defaults: CommunicationState = {
      isDeleting: false,

      communication: {},
      formModalOpen: false,
      editModalOpen: false,
      isCreating: false,
      formValues: null,
    };
    return Object.assign(new CommunicationState(), defaults, props || {});
  }
}

export class CommunicationStore extends BaseStore<CommunicationState> {
  constructor() {
    super(CommunicationState.create({}));
  }

  public setCreateModalOpen(isOpen: boolean): void {
    this.setState({ formModalOpen: isOpen, formValues: {} });
  }

  public setFormValue(formValues: CommunicationFormValues): void {
    this.setState({ formValues });
  }

  public createCommunication(
    formValues: CommunicationFormValues,
    parentType: string,
    parentId: string,
    onSuccess?: () => void,
  ): void {
    axios
      .post<string, CommunicationResponse>(`${API_ENDPOINT}/${parentType}/${parentId}/communications.json`, {
        communication: formValues,
        headers: { 'Content-Type': 'application/json' },
      })
      .then(() => {
        if (onSuccess) {
          onSuccess();
        }
        alertStore.alertSuccess('Successfully created communication.');
        this.setState({ formModalOpen: false, isCreating: false });
      })
      .catch(() => {
        alertStore.alertSuccess('Something went wrong when creating your communication.');
        this.setState({ formModalOpen: false, isCreating: false });
      });
  }

  public updateCommunication(
    formValues: CommunicationFormValues,
    communicationId: string,
    onSuccess?: () => void,
  ): void {
    axios
      .put<string, CommunicationResponse>(`${API_ENDPOINT}/communications/${communicationId}.json`, {
        communication: formValues,
        headers: { 'Content-Type': 'application/json' },
      })
      .then(() => {
        if (onSuccess) {
          onSuccess();
        }
        alertStore.alertSuccess('Successfully updated communication.');
        this.setState({ editModalOpen: false, isCreating: false });
      })
      .catch(() => {
        alertStore.alertSuccess('Something went wrong when updating your communication.');
        this.setState({ editModalOpen: false, isCreating: false });
      });
  }

  public deleteCommunication(communicationId: string, onSuccess?: () => void): void {
    this.setState({ isDeleting: true });

    axios
      .delete<string, CommunicationResponse>(`communications/${communicationId}.json`, {
        headers: { 'Content-Type': 'application/json' },
      })
      .then(() => {
        if (onSuccess) {
          onSuccess();
        }
        this.setState({ isDeleting: false });
        alertStore.alertSuccess('Communication was successfully deleted.');
      })
      .catch(() => {
        this.setState({ isDeleting: false });
        alertStore.alertError('Something went wrong when deleting your communication.');
      });
  }

  public setEditModalOpen(isOpen: boolean, communication: Communication): void {
    const formValues = {
      initiator: communication.created_by,
      contact_name: communication.contact_name,
      contact_method: communication.contact_method,
      contact_on: communication.date_created,
      contact_reason: communication.contact_reason,
      contact_result: communication.contact_result,
    };

    this.setState({ editModalOpen: isOpen, formValues, communication });
  }
}
