import React, { FunctionComponent, useContext } from 'react';
import { Box, Grid, Typography, makeStyles } from '@material-ui/core';
import ConfirmCancelModal from '../../shared/components/modal/ConfirmCancelModal';
import InformationField from '../../shared/components/InformationField';
import SelectInput from '../../shared/components/form/SelectInput';
import TextInput from '../../shared/components/form/TextInput';
import { serviceFormStore } from '../../shared/singletons';
import { useStoreObservable } from '../../shared/state/useStoreObservable.hook';
import { UserContext } from '../../auth/contexts/userContext';
import { ServiceFormOptionsContext } from '../../services/contexts/ServiceFormOptionsContext';
import { _translateEach } from '../../shared/utils/translation.utils';
import ReactJoyride from 'react-joyride';
import { joyrideSteps } from '../../joyride/constants/joyrideSteps';
import { JoyrideContext } from '../../joyride/contexts/JoyrideContext';

const useStyles = makeStyles({
  textInput: {
    height: 'auto',
    width: '98%',
    margin: '14px 0',
  },
  baseError: {
    marginBottom: '10px',
  },
});

interface ServiceStageChangeModalProps {
  onSuccess?: () => void;
}

const ServiceStageChangeModal: FunctionComponent<ServiceStageChangeModalProps> = (props) => {
  const { onSuccess } = props;
  const classes = useStyles();
  const { stepIndex, handleJoyrideCallback } = useContext(JoyrideContext);

  const { stageChoices, statusChoices } = React.useContext(ServiceFormOptionsContext);
  const { user, currentUserHasRole } = React.useContext(UserContext);

  const { editStageModalOpen, formValues, formErrors, editStageModalService: service } = useStoreObservable(
    serviceFormStore,
  );

  const [comment, setComment] = React.useState('');

  const t = _translateEach({
    title: 'services.stageChangeModal.title',
    update: 'services.stageChangeModal.update',
  });

  const handleFormValueChange = (key: string, value: string) => {
    serviceFormStore.setFormValue({ ...formValues, [key]: value });
  };

  React.useEffect(() => {
    setComment('');
  }, [editStageModalOpen]);

  const handleFormSubmit = () => {
    serviceFormStore.createOrUpdateService(formValues, formValues.student_id, service.id, onSuccess);
  };

  const handleCommentChange = (value: string) => {
    setComment(value);

    // Using the strange format below to automatically work with Rails accepts_nested_attributes_for
    serviceFormStore.setFormValue({
      ...formValues,
      comments_attributes: { '0': { content: value, user_id: user.id } },
    });
  };

  return (
    <ConfirmCancelModal
      isOpen={editStageModalOpen}
      title={t.title}
      onConfirm={handleFormSubmit}
      confirmLabel={t.update}
      openStatusChanged={(isOpen) => serviceFormStore.setEditStageModalOpen(isOpen, service)}
    >
      <Box p={2}>
        <ReactJoyride
          stepIndex={stepIndex}
          steps={joyrideSteps}
          styles={{
            options: {
              zIndex: 10000,
            },
          }}
          run={user?.training_engaged?.joyride_tracking_name === 'referral_service_stage_change'}
          locale={{ close: 'OK' }}
          callback={handleJoyrideCallback}
        />
        <Grid container spacing={6}>
          <Grid item>
            <InformationField label="Student" value={service?.student_name} />
          </Grid>
          <Grid item>
            <InformationField label="Timeline ID" value={service?.student_slug} />
          </Grid>
          <Grid item>
            <InformationField label="Service Type" value={service?.service_type} />
          </Grid>
        </Grid>
        <br />
        <Typography variant="h2">Service Details</Typography>
        <Grid container>
          <Grid item xs={10} className="joyride-serviceStage-step2">
            <SelectInput
              value={formValues?.referral_stage_id}
              styleOverrides={classes.textInput}
              type="text"
              label="Service Stage"
              errorMessage={formErrors?.referral_stage_id}
              valueChanged={(value: string) => handleFormValueChange('referral_stage_id', value)}
              choices={stageChoices}
            />
          </Grid>
          {currentUserHasRole('admin') && (
            <Grid item xs={10} className="joyride-serviceStage-step3">
              <SelectInput
                styleOverrides={classes.textInput}
                type="text"
                label="Status"
                errorMessage={formErrors?.status}
                value={formValues?.status}
                valueChanged={(value: string) => handleFormValueChange('status', value)}
                choices={statusChoices}
                defaultChoice={{ value: '', label: 'None' }}
              />
            </Grid>
          )}
          <Grid item xs={10}>
            <TextInput
              value={comment}
              styleOverrides={classes.textInput}
              label="Comment (Optional)"
              multiline
              rows={8}
              valueChanged={(value: string) => handleCommentChange(value)}
            />
          </Grid>
          <br />
        </Grid>
        <br />
      </Box>
    </ConfirmCancelModal>
  );
};

export default ServiceStageChangeModal;
