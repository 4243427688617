import React, { FunctionComponent, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Card, CardContent, Grid, Typography } from '@material-ui/core';
import { Appointment } from '../model';
import { AppointmentsContext } from '../contexts/AppointmentsContext';
import DeleteAppointmentConfirmModal from './DeleteAppointmentConfirmModal';
import DeleteFutureAppointmentsConfirmModal from './DeleteFutureAppointmentsConfirmModal';

interface AppointmentCustomActionsProps {
  appointment: Appointment;
}

const AppointmentCustomActions: FunctionComponent<AppointmentCustomActionsProps> = (props) => {
  const { appointment } = props;

  const history = useHistory();

  const { setDestroyModalOpen, setDestroyFutureModalOpen, setAppointmentToDelete } = useContext(AppointmentsContext);

  const handleDeleteClicked = () => {
    setAppointmentToDelete(appointment);
    setDestroyModalOpen(true);
  };

  return (
    <>
      <DeleteAppointmentConfirmModal />
      <DeleteFutureAppointmentsConfirmModal />
      <Card>
        <CardContent>
          <Grid container justify="space-between">
            <Grid item>
              <Typography variant="h2" component="h2">
                Reschedule Future Appointments
              </Typography>
              <Typography>Bulk reschedule all future appointments for this service.</Typography>
              <br />
              <Button
                variant="contained"
                color="primary"
                onClick={() =>
                  history.push(`/students/${appointment.student_id}/appointments/${appointment.id}/bulk-reschedule`)
                }
              >
                Go to Scheduling Page
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Card style={{ marginTop: 30 }}>
        <CardContent>
          <Grid container justify="space-between">
            <Grid item>
              <Typography variant="h2" component="h2">
                Delete Appointment
              </Typography>
              <Typography>Permanently delete appointment from the timeline site.</Typography>
              <br />
              <Button
                variant="contained"
                color="primary"
                style={{ backgroundColor: '#F44F64' }}
                onClick={() => handleDeleteClicked()}
              >
                Delete Appointment
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Card style={{ marginTop: 30 }}>
        <CardContent>
          <Grid container justify="space-between">
            <Grid item>
              <Typography variant="h2" component="h2">
                Delete Future Appointments
              </Typography>
              <Typography>
                Permanently delete this appointment and all future appointments from the timeline site.
              </Typography>
              <br />
              <Button
                variant="contained"
                color="primary"
                style={{ backgroundColor: '#F44F64' }}
                onClick={() => setDestroyFutureModalOpen(true)}
              >
                Delete Future Appointments
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

export default AppointmentCustomActions;
