import React, { Dispatch, SetStateAction } from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import ConfirmCancelModal from '../../shared/components/modal/ConfirmCancelModal';

import { Invoice } from '../model';

import InformationField from '../../shared/components/InformationField';
import axios from '../../shared/utils/axios.utils';

interface SentInvoiceModalProps {
  invoiceForModal: Invoice;
  sentModalOpen: boolean;
  setSentModalOpen: Dispatch<SetStateAction<boolean>>;
  fetchInvoices: () => void;
}

const SentInvoiceModal: React.FC<SentInvoiceModalProps> = (props) => {
  let { invoiceForModal, sentModalOpen, setSentModalOpen, fetchInvoices } = props;

  let handleSendInvoice = async () => {
    await axios.put(`/invoices/invoices/${invoiceForModal.id}`, {
      invoice: { status: 'Sent' },
    });
    fetchInvoices();
    setSentModalOpen(false);
  };

  return (
    <ConfirmCancelModal
      confirmLabel="Mark Sent"
      onConfirm={handleSendInvoice}
      openStatusChanged={(isOpen) => setSentModalOpen(isOpen)}
      title="Mark Sent"
      isOpen={sentModalOpen}
      height="350px"
    >
      <Grid container>
        <Grid item xs={12} style={{ marginBottom: 25 }}>
          <Grid container spacing={4}>
            <Grid item>
              <InformationField label="Title" value={invoiceForModal?.title} />
            </Grid>
            <Grid item>
              <InformationField label="School" value={invoiceForModal?.school_name} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h3">Setting this Invoice to 'Sent'</Typography>
          <Typography variant="h3">This cannot be reversed</Typography>
        </Grid>
      </Grid>
    </ConfirmCancelModal>
  );
};

export default SentInvoiceModal;
