import React, { FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';
import { Typography, Box, Grid, Card, CardContent, Button, Paper } from '@material-ui/core';
import DataTable from '../../shared/components/DataTable';
import { IDataTableColumn } from '../../shared/components/DataTable/DataTable/model';
import { axios } from '../../shared/singletons';
import useEmailAlertRequestFunctions from '../constants/emailAlertRequestFunctions';
import { Colors } from '../../shared/style/colors';

const colorByStatus = (status: string) => {
  if (status === 'sent') {
    return Colors.Success;
  } else if (status === 'failed') {
    return Colors.Warning;
  } else {
    return Colors.Orange;
  }
};

const EmailAlertsList: FunctionComponent = (props) => {
  const history = useHistory();

  const [emailAlerts, setEmailAlerts] = React.useState([]);

  const { handleSendEmail } = useEmailAlertRequestFunctions();

  React.useEffect(() => {
    axios
      .get(`/email_alerts`)
      .then((response) => {
        setEmailAlerts(response.data.result);
      })
      .catch((error) => {
        alert(error?.response?.data);
      });
  }, []);

  const columns: IDataTableColumn<any>[] = [
    {
      name: 'Created At',
      selector: 'created_at',
      sortable: true,
    },
    {
      name: 'Subject',
      selector: 'subject',
      sortable: true,
    },
    {
      name: 'Role',
      selector: 'formatted_role',
      sortable: true,
    },
    {
      name: 'Sent By',
      selector: 'created_by',
      sortable: true,
    },
    {
      name: 'Sent At',
      selector: 'sent_at',
      sortable: true,
    },
    {
      name: 'Status',
      selector: 'sent_by',
      sortable: true,
      cell: (row) => (
        <div
          style={{
            fontWeight: 700,
            textTransform: 'uppercase',
            color: colorByStatus(row.status),
          }}
        >
          {row.status}
        </div>
      ),
    },
    {
      name: 'Actions',
      selector: 'actions',
      sortable: false,
      cell: (row) => (
        <Button
          variant="contained"
          color="primary"
          disabled={row.status !== 'draft'}
          onClick={() => {
            const confirmed = window.confirm('Are you sure you want to send this email?');
            if (confirmed) {
              handleSendEmail(row.id);
            }
          }}
        >
          Send Now
        </Button>
      ),
    },
  ];

  const ExpandedMessage = ({ data }) => (
    <div style={{ border: '1px solid black', padding: 10 }}>
      <h2>
        <strong>Email Preview:</strong>
      </h2>
      <Paper>
        <div style={{ backgroundColor: '#fff', padding: '0', margin: '0', fontFamily: 'Calibri, sans-serif' }}>
          {/* HEADER */}
          <div style={{ width: '100%', backgroundColor: '#fff', textAlign: 'center', padding: '30px 0' }}>
            <a href="https://www.timelinecds.com" target="_blank" rel="noopener noreferrer">
              <img src="https://www.timelinecds.com/static/media/logo.8e988dd7.png" alt="" style={{ height: '60px' }} />
            </a>
          </div>

          {/* CONTENT */}
          <div
            style={{
              padding: '30px 10px',
              borderRadius: '7px',
              backgroundColor: '#fff',
              maxWidth: '850px',
              margin: '0 auto',
            }}
            dangerouslySetInnerHTML={{ __html: data?.message }}
          />
        </div>
      </Paper>
    </div>
  );

  return (
    <Box>
      <Grid justify="space-between" container spacing={3}>
        <Grid item xs={9}>
          <Typography variant="h1" component="h1">
            Email Alerts
          </Typography>
        </Grid>
        <Grid item>
          <Button variant="contained" color="primary" onClick={() => history.push(`/email_alerts/new`)}>
            Create New Email Alert
          </Button>
        </Grid>
      </Grid>
      <br />
      <Card>
        <CardContent>
          <DataTable
            title=""
            columns={columns}
            noHeader
            data={emailAlerts}
            progressPending={false}
            expandableRows
            expandableRowsComponent={<ExpandedMessage data />}
            striped
            highlightOnHover
            pointerOnHover
            pagination
            onRowClicked={(row) => history.push(`/email_alerts/${row.id}`)}
          />
        </CardContent>
      </Card>
    </Box>
  );
};

export default EmailAlertsList;
