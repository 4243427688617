import React from 'react';
import { axios } from '../../shared/singletons';
import { SchoolAction } from '../model';

interface SchoolActionsContextInterface {
  fetchSchoolActions?: (params?: string) => Promise<void>;
  schoolActions?: SchoolAction[];
  isLoading?: boolean;
}

const SchoolActionsContext = React.createContext<SchoolActionsContextInterface>({});

const SchoolActionsContextConsumer = SchoolActionsContext.Consumer;
const SchoolActionsContextProvider = ({ children }) => {
  const [schoolActions, setSchoolActions] = React.useState<SchoolAction[]>([]);
  const [isLoading, setLoading] = React.useState<boolean>(false);

  const fetchSchoolActions = async (params: string) => {
    setLoading(true);
    const response = await axios.get<string, any>(`school_actions?${params}`);
    setSchoolActions(response.data?.result);
    setLoading(false);
  };

  return (
    <SchoolActionsContext.Provider
      value={{
        fetchSchoolActions,
        schoolActions,
        isLoading,
      }}
    >
      {children}
    </SchoolActionsContext.Provider>
  );
};

export { SchoolActionsContextProvider, SchoolActionsContextConsumer, SchoolActionsContext };
