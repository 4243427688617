import { Observable, of, Subject, Subscription, timer } from 'rxjs';
import { Primitive } from './generic.model';
import { _isNil, _isUndefined } from './validation.utils';
import { _contains, _toLower } from './string.utils';
import { _keys } from './collection.utils';

// ###########################
// DEFAULT SETTERS
// ###########################

export const _numberOrDefault = (value: number, defaultVal: number): number => (_isNil(value) ? defaultVal : value);
export const _defaultToFalse = (value: boolean): boolean => value === true;
export const _defaultToTrue = (value: boolean): boolean => value !== false;
export const _defaultToValueIfUndefined = <T>(item: T, value: T): T => (_isUndefined(item) ? value : item);

// ###########################
// MISC
// ###########################

export const _possessiveName = (name: string): string => {
  if (!name) return name;
  const lastCharacter = name.slice(-1);
  const end = _toLower(lastCharacter) === 's' ? "'" : "'s";
  return `${name}${end}`;
};

export const nSecondTimer = (numSeconds: number): Observable<number> => timer(0, numSeconds * 1000);
export const oneSecondTimer = (): Observable<number> => nSecondTimer(1);

export const _addHttpPrefixIfMissing = (site: string): string => {
  if (!site) return null;
  return _contains('http', site) ? site : `http://${site}`;
};

export const _backgroundImageUrl = (src: string): string => (src ? `url('${src}')` : null);

export const _mbToBytes = (mb: number): number => Math.ceil(mb * 1024 * 1024);
export const _getPastedText = (event: ClipboardEvent): string => (event ? event.clipboardData.getData('text') : null);
export const _toPx = (val: number) => `${val}px`;

export const _promiseOf = <T>(val: T = null): Promise<T> => of(val).toPromise();
export const _unsubscribe = (item: Subscription | Subject<any>) => (item ? item.unsubscribe() : null);

export const _resultHasItems = (obj: { items: any[] }): boolean => !!obj && !!obj.items && obj.items.length > 0;

export class KeyValue {
  key: string;
  value: Primitive;

  constructor(key: string, value: string | number | boolean) {
    this.key = key;
    this.value = value;
  }

  static readonly fromObject = (obj: Object): KeyValue[] => _keys(obj).map((key) => ({ key, value: obj[key] }));
  static readonly fromValues = (key: string, values: Primitive[]): KeyValue[] =>
    (values || []).map((val) => new KeyValue(key, val));
  static readonly toQueryString = (items: KeyValue[]): string =>
    (items || []).map(({ key, value }) => `${key}=${value}`).join('&');
}
