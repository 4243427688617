import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { UserRouteParams } from '../model';
import { CaseLoadManagementContext } from '../contexts/CaseLoadManagementContext';
import InformationField from '../../shared/components/InformationField';
import AddOwnerToServicesForm from '../components/AddOwnerToServicesForm';

const CaseLoadManagementPage: React.FunctionComponent = () => {
  const { userId } = useParams<UserRouteParams>();
  const { user, fetchUser } = React.useContext(CaseLoadManagementContext);
  const [userFetched, setUserFetched] = React.useState(false);

  React.useEffect(() => {
    if (!userFetched) {
      fetchUser(userId);
      setUserFetched(true);
    }
  }, [userId, userFetched, setUserFetched, fetchUser]);

  return (
    <>
      <Typography variant="h1" component="h1">
        Case Load Management
      </Typography>
      <Grid container flex-direction="column" spacing={6}>
        <Grid item>
          <InformationField label="User" value={user.name} />
        </Grid>
        <Grid item>
          <InformationField label="Supervisor" value={user.supervisor} />
        </Grid>
        <Grid item>
          <InformationField label="Active Services" value={0} />
        </Grid>
        <Grid item>
          <InformationField label="Active Appointments" value={0} />
        </Grid>
      </Grid>
      <br />
      <AddOwnerToServicesForm school_choices={user.school_choices} />
    </>
  );
};

export default CaseLoadManagementPage;
