// ###########################
// OBJECT
// ###########################

import { StringDict } from './generic.model';
import { _keys, _size } from './collection.utils';
import { _toString } from './number.utils';
import { _isNil, _isUndefined } from './validation.utils';

export const _hasKeys = (obj: Object): boolean => _size(_keys(obj)) > 0;
export const _cloneDeep = <T>(item: T): T => JSON.parse(JSON.stringify(item)); // TODO this will blast any functions on the object

export const _keysWithUndefinedValue = (obj: Object): string[] => _keys(obj).filter((key) => _isUndefined(obj[key]));

export const _deleteKeysWithUndefinedValue = (obj: Object): Object => {
  _keysWithUndefinedValue(obj).forEach((key) => delete obj[key]);
  return obj;
};

export const _tryToParseJson = <T>(input: string, defaultValue: T = null): T => {
  try {
    return input ? JSON.parse(input) : defaultValue;
  } catch (e) {
    return defaultValue;
  }
};

export const _sortKeysAlphabetically = <T = any>(record: T): T => {
  const objWithSortedKeys = {};
  _keys(record)
    .sort()
    .forEach((key) => (objWithSortedKeys[key] = record[key]));
  return objWithSortedKeys as T;
};

export const _deleteNilValues = <T extends Object>(obj: T, selectedKeys: string[] = null): T => {
  const keys = selectedKeys || _keys(obj);
  (keys || []).forEach((key) => {
    if (_isNil(obj[key])) delete obj[key];
  });
  return obj;
};

export const _deleteKeys = <T extends Object>(obj: T, keys: string[]): T => {
  (keys || []).forEach((key) => {
    delete obj[key];
  });
  return obj;
};

export const _haveSameKeys = <T extends Object>(one: T, two: T): boolean => {
  const keysOne = _keys(one).sort();
  const keysTwo = _keys(two).sort();
  if (_size(keysOne) !== _size(keysTwo)) return false;
  let isEqual: boolean = true;
  keysOne.forEach((keyOne, index) => {
    if (keyOne !== keysTwo[index]) isEqual = false;
  });
  return isEqual;
};

export const _haveEqualValues = <T extends Object>(one: T, two: T): boolean => {
  if (!_haveSameKeys(one, two)) return false;
  let isEqual: boolean = true;
  _keys(one).forEach((key) => {
    if (!isEqual) return;
    if (one[key] !== two[key]) isEqual = false;
  });
  return isEqual;
};

export const _deepEquals = (x: any, y: any): boolean => {
  if (x === y) return true;

  if (typeof x == 'object' && x != null && typeof y == 'object' && y != null) {
    if (_size(_keys(x)) !== _size(_keys(y))) return false;

    for (let prop in x) {
      if (!y.hasOwnProperty(prop)) return false;

      if (!_deepEquals(x[prop], y[prop])) return false;
    }

    return true;
  }
  return false;
};

export const _toStringDict = (dict: Object, defaultValueForNull: string = null): StringDict => {
  return _keys(dict || {})
    .map((key) => [key, _toString(dict[key]) ?? defaultValueForNull])
    .reduce((acc, current) => {
      const [key, value] = current;
      acc[key] = value;
      return acc;
    }, {});
};
