import { axios, alertStore, schoolRecordStore } from '../shared/singletons';
import { BaseFormStore } from '../shared/state/baseForm.store';
import { School, SchoolFormValues, SchoolFormErrors, SchoolResponse } from './model';
import { API_ENDPOINT } from '../../config/env';

export class SchoolState {
  formValues: SchoolFormValues;
  formErrors: SchoolFormErrors;
  formErrorAlertVisible: boolean;
  formStepperIndex: number;
  formModalOpen: boolean;
  formModalSchool: School;

  static create(props: Partial<SchoolState>): SchoolState {
    const defaults: SchoolState = {
      formValues: null,
      formErrors: null,
      formErrorAlertVisible: true,
      formStepperIndex: 0,
      formModalOpen: false,
      formModalSchool: {},
    };
    return Object.assign(new SchoolState(), defaults, props || {});
  }
}

export class SchoolFormStore extends BaseFormStore<SchoolState> {
  constructor() {
    super(SchoolState.create({}));
  }

  public async createOrUpdateSchool(formValues: SchoolFormValues, schoolId: string): Promise<void> {
    try {
      const response = await axios[schoolId ? 'put' : 'post']<string, SchoolResponse>(
        `payers${schoolId ? `/${schoolId}` : ''}.json`,
        {
          payer: formValues,
          headers: { 'Content-Type': 'application/json' },
        },
      );

      schoolRecordStore.fetchRecords();
      schoolRecordStore.setState({ school: response.data.result });
      this.setCreateModalOpen(false);
    } catch (error) {
      this.setState({
        formStepperIndex: 0, // Don't progress stepper if submit errored
        formErrors: error.response?.data,
        formErrorAlertVisible: !!error.response?.data?.base, // The base errors are not tied to a specific field and displayed at the top of the form
      });
    }
  }

  public updateDates(formValues: SchoolFormValues, schoolId: string): void {
    axios
      .put<string, SchoolResponse>(`${API_ENDPOINT}/payers/${schoolId}.json`, {
        payer: formValues,
        headers: { 'Content-Type': 'application/json' },
      })
      .then(() => {
        schoolRecordStore.fetchRecords(); // TODO optimize?
        this.setState({ formStepperIndex: 2 });
      })
      .catch((error) => {
        this.setState({
          formStepperIndex: 1, // Don't progress stepper if submit errored
          formErrors: error.response.data,
          formErrorAlertVisible: !!error.response?.data?.base, // The base errors are not tied to a specific field and displayed at the top of the form
        });
      });
  }

  public updateLocations(formValues: SchoolFormValues, schoolId: string): void {
    axios
      .put<string, SchoolResponse>(`${API_ENDPOINT}/payers/${schoolId}.json`, {
        payer: formValues,
        headers: { 'Content-Type': 'application/json' },
      })
      .then(() => {
        schoolRecordStore.fetchRecords(); // TODO optimize?
        this.setState({ formStepperIndex: 3 });
      })
      .catch((error) => {
        this.setState({
          formStepperIndex: 2, // Don't progress stepper if submit errored
          formErrors: error.response.data,
          formErrorAlertVisible: !!error.response?.data?.base, // The base errors are not tied to a specific field and displayed at the top of the form
        });
      });
  }

  public deleteSchool(schoolId: string): void {
    axios
      .delete<string, SchoolResponse>(`${API_ENDPOINT}/payers/${schoolId}.json`, {
        headers: { 'Content-Type': 'application/json' },
      })
      .then(() => {
        schoolRecordStore.fetchRecords(); // TODO optimize?
      })
      .catch(() => {
        alertStore.alertError('Something went wrong. Please reload and try again.');
      });
  }

  public setCreateModalOpen(isOpen: boolean): void {
    this.setState({ formModalOpen: isOpen, formModalSchool: {}, formValues: {}, formStepperIndex: 0 });
  }

  public setEditModalOpen(isOpen: boolean, school: School): void {
    this.clearFormValues();

    if (school) {
      const formValues = {
        name: school.name,
        abbr: school.abbreviation,
        contact_name: school.contact_name,
        contact_email: school.contact_email,
        start_date: school.start_date,
        end_date: school.end_date,
        q1_sd: school.q1_sd,
        q1_ed: school.q1_ed,
        q2_sd: school.q2_sd,
        q2_ed: school.q2_ed,
        q3_sd: school.q3_sd,
        q3_ed: school.q3_ed,
        q4_sd: school.q4_sd,
        q4_ed: school.q4_ed,
        esy_sd: school.esy_sd,
        esy_ed: school.esy_ed,
        address1: school.address1,
        address2: school.address2,
        city: school.city,
        state: school.state,
        zip: school.zip,
        phone: school.phone,
        fax: school.fax,
      };

      this.setState({ formModalOpen: isOpen, formModalSchool: school, formValues });
    }
  }

  protected clearFormValues(): void {
    this.setState({ formValues: {} });
  }
}
