import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { Grid, makeStyles } from '@material-ui/core';
import { BaseModalProps } from '../../shared/components/modal/modal.model';
import ConfirmCancelModal from '../../shared/components/modal/ConfirmCancelModal';
import TextInput from '../../shared/components/form/TextInput';
import { Attachment } from '../model';
import { axios } from '../../shared/singletons';

const EditModalWrapper = styled.div``;

const ModalContent = styled.div``;
const ChildrenContainer = styled.div``;

interface EditFileNameModalProps extends BaseModalProps {
  onEdit?: () => void;
  confirmLabel?: string;
  attachment?: Attachment;
  afterAction: () => void;
}

const useStyles = makeStyles({
  textInput: {
    height: '60px',
    width: '80%',
    margin: '10px 0',
  },
  baseError: {
    marginBottom: '10px',
  },
});

const EditFileNameModal: FunctionComponent<EditFileNameModalProps> = (props) => {
  const {
    isOpen,
    children,
    openStatusChanged = (isOpen: boolean) => null,
    confirmLabel = 'Update',
    height = '350px',
    attachment,
    afterAction,
  } = props;

  const classes = useStyles();
  const [filename, setFilename] = React.useState(attachment?.file_name);

  const editFilename = () => {
    axios.put(`/patients/${attachment?.id}/attachments/${attachment?.id}.json`, { attachment: { filename: filename } });
    afterAction();
  };

  return (
    <EditModalWrapper>
      <ConfirmCancelModal
        isOpen={isOpen}
        openStatusChanged={openStatusChanged}
        cancelLabel="Cancel"
        confirmLabel={confirmLabel}
        width="768px"
        height={height}
        onConfirm={editFilename}
        onCancel={() => openStatusChanged(false)}
        hasTitleBar={false}
        hasFooterBar={false}
        hasCancelButton
        hasConfirmButton
      >
        <ModalContent>
          <ChildrenContainer>{children}</ChildrenContainer>
          <Grid container>
            <Grid item xs={12}>
              <TextInput
                value={filename}
                label="File Name"
                styleOverrides={classes.textInput}
                valueChanged={(value: string) => {
                  setFilename(value);
                }}
              />
            </Grid>
          </Grid>
        </ModalContent>
      </ConfirmCancelModal>
    </EditModalWrapper>
  );
};

export default EditFileNameModal;
