import React, { FunctionComponent, useState, useContext } from 'react';
import { Grid, Link } from '@material-ui/core';
import Button, { ButtonFlavor } from '../../shared/components/Button';
import TextInput from '../../shared/components/form/TextInput';
import ErrorAlert from '../../shared/components/ErrorAlert';
import { UserContext } from '../../auth/contexts/userContext';
import { useStyles } from '../../shared/style/siteWideStyles';

const LoginForm: FunctionComponent = () => {
  const classes = useStyles();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const { login, loginFormError, setLoginError } = useContext(UserContext);

  const handleLogin = (e: React.FormEvent) => {
    e.preventDefault();
    login(username, password);
  };

  return (
    <form noValidate autoComplete="off" onSubmit={handleLogin}>
      {loginFormError && (
        <ErrorAlert display={loginFormError.length > 0} onClose={() => setLoginError(undefined)}>
          {loginFormError}
        </ErrorAlert>
      )}
      <TextInput
        styleOverrides={classes.textInput}
        label="Username"
        value={username}
        valueChanged={(value: string) => setUsername(value)}
      />
      <TextInput
        styleOverrides={classes.textInput}
        type="password"
        label="Password"
        value={password}
        valueChanged={(value: string) => setPassword(value)}
      />
      <Grid container spacing={3}>
        <Grid item>
          <Button text="Login" disabled={false} flavor={ButtonFlavor.Primary} handleClick={(e) => handleLogin(e)} />
        </Grid>
        <Grid item>
          <Link style={{ lineHeight: '42px' }} href="/password-reset">
            Forgot your password?
          </Link>
        </Grid>
      </Grid>
    </form>
  );
};

export default LoginForm;
