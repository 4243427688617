import React, { useContext } from 'react';
import { Grid, Button } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';
import { updaterFlowLinks } from '../constants/constants';
import { AnnualUpdatesContext } from '../contexts/AnnualUpdatesContext';

interface NavButtonProps {
  activePage: string;
  studentId: string;
}

const NavButtons: React.FC<NavButtonProps> = (props) => {
  const { studentId, activePage } = props;
  const { continueDisabled } = useContext(AnnualUpdatesContext);
  const history = useHistory();
  const location = useLocation();

  const activeIndex = updaterFlowLinks.indexOf(activePage);

  let backPage = null;
  let nextPage = null;

  if (activeIndex > 0) {
    backPage = updaterFlowLinks[activeIndex - 1];
  }
  if (activeIndex < updaterFlowLinks.length) {
    nextPage = updaterFlowLinks[activeIndex + 1];
  }

  let stepsCompleted = [];
  if (location?.state) {
    stepsCompleted = location?.state['comp'];
  }

  return (
    <Grid>
      {backPage && (
        <Button
          variant="contained"
          color="primary"
          disabled={continueDisabled}
          onClick={() =>
            history.push({
              pathname: `/students/${studentId}/annual_updater_${backPage}`,
              state: { comp: stepsCompleted },
            })
          }
        >
          ← Back
        </Button>
      )}

      {nextPage && (
        <Button
          variant="contained"
          color="primary"
          style={{ marginLeft: 7 }}
          disabled={continueDisabled}
          onClick={() => {
            stepsCompleted.push(activeIndex);
            history.push({
              pathname: `/students/${studentId}/annual_updater_${nextPage}`,
              state: { comp: stepsCompleted },
            });
          }}
        >
          {nextPage === 'finish' ? 'Finish' : 'Continue'} →
        </Button>
      )}

      {continueDisabled && (
        <>
          &emsp;
          <p style={{ color: 'red' }}>You have unsaved changes</p>
        </>
      )}
    </Grid>
  );
};

export default NavButtons;
