import React from 'react';
import { School } from '../model';
import { Grid } from '@material-ui/core';
import AcademicYearCard from './AcademicYearCard';

type AcademicYearsTabProps = {
  school: School;
};

const AcademicYearsTab: React.FC<AcademicYearsTabProps> = (props) => {
  const { school } = props;

  return (
    <Grid container>
      <Grid>
        {school?.academic_years?.length === 0 ? (
          <AcademicYearCard year={{ title: '', payer_id: school.id, status: 'active' }} expanded />
        ) : (
          <AcademicYearCard year={{ title: '', payer_id: school.id, status: 'active' }} />
        )}

        <br />
      </Grid>
      {school?.academic_years.map((year) => (
        <Grid item xs={12} key={year.title}>
          <AcademicYearCard year={year} />
          <br />
        </Grid>
      ))}
    </Grid>
  );
};

export default AcademicYearsTab;
