import React, { FunctionComponent } from 'react';
import { Box, Grid, Typography, makeStyles } from '@material-ui/core';
import ConfirmCancelModal from '../../../shared/components/modal/ConfirmCancelModal';
import TextInput from '../../../shared/components/form/TextInput';
import { renewalTypeStore } from '../../../shared/singletons';
import { useStoreObservable } from '../../../shared/state/useStoreObservable.hook';

const useStyles = makeStyles({
  textInput: {
    height: 'auto',
    width: '98%',
    margin: '14px 0',
  },
  baseError: {
    marginBottom: '10px',
  },
});

const EditRenewalTypeModal: FunctionComponent = () => {
  const { editModalOpen, formValues, renewalType } = useStoreObservable(renewalTypeStore);
  const classes = useStyles();

  const handleFormValueChange = (key: string, value: string) => {
    renewalTypeStore.setState({ formValues: { ...formValues, [key]: value } });
  };

  const handleFormSubmit = () => {
    renewalTypeStore.updateRenewalType(formValues, renewalType);
  };

  return (
    <ConfirmCancelModal
      title="Edit Renewal Type"
      cancelLabel="Cancel"
      confirmLabel="Update Renewal Type"
      width="768px"
      hasCancelButton
      hasConfirmButton
      isOpen={editModalOpen}
      onConfirm={handleFormSubmit}
      openStatusChanged={(isOpen) => renewalTypeStore.setState({ editModalOpen: isOpen })}
    >
      <Box p={2}>
        <Typography variant="h2">Renewal Type Details</Typography>
        <Grid container spacing={3}>
          <Grid item xs={10}>
            <TextInput
              styleOverrides={classes.textInput}
              label="Renewal Type Name"
              value={formValues?.name}
              valueChanged={(value: string) => handleFormValueChange('name', value)}
            />
          </Grid>
        </Grid>
        <br />
      </Box>
    </ConfirmCancelModal>
  );
};

export default EditRenewalTypeModal;
