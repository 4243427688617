import React, { FunctionComponent, useState, useEffect, useContext } from 'react';
import { CSVLink } from 'react-csv';
import {
  Box,
  Button,
  Grid,
  Typography,
  makeStyles,
  Select,
  FilledInput,
  MenuItem,
  FormControl,
  InputLabel,
} from '@material-ui/core';
import DataTable from '../../shared/components/DataTable';
import { IDataTableColumn } from '../../shared/components/DataTable/DataTable/model';
import TextInput from '../../shared/components/form/TextInput';
import { reportStore } from '../../shared/singletons';
import { ReportsContext } from '../contexts/ReportsContext';
import { useStoreObservable } from '../../shared/state/useStoreObservable.hook';

// TODO: Temp styles before we line things up with the mockup
const useStyles = makeStyles({
  textInput: {
    height: '60px',
    width: '98%',
    margin: '10px 0',
  },
  baseError: {
    marginBottom: '10px',
  },
});

const columns: IDataTableColumn<unknown>[] = [
  {
    name: 'USER',
    selector: 'user',
    sortable: true,
  },
  {
    name: 'MONDAY COMPLETE (hours)',
    selector: 'monday_complete_hours',
    sortable: true,
  },
  {
    name: 'TUESDAY COMPLETE (hours)',
    selector: 'tuesday_complete_hours',
    sortable: true,
  },
  {
    name: 'WEDNESDAY COMPLETE (hours)',
    selector: 'wednesday_complete_hours',
    sortable: true,
  },
  {
    name: 'THURSDAY COMPLETE (hours)',
    selector: 'thursday_complete_hours',
    sortable: true,
  },
  {
    name: 'FRIDAY COMPLETE (hours)',
    selector: 'friday_complete_hours',
    sortable: true,
  },
  {
    name: 'COMPLETE (hours)',
    selector: 'complete_hours',
    sortable: true,
  },
  {
    name: 'LATE CANCELS (hours)',
    selector: 'late_cancels_hours',
    sortable: true,
  },
  {
    name: 'NO SHOWS (hours)',
    selector: 'no_shows_hours',
    sortable: true,
  },
  {
    name: 'TOTAL (hours)',
    selector: 'total_hours',
    sortable: true,
  },
  {
    name: 'INCOMPLETE (hours)',
    selector: 'incomplete_hours',
    sortable: true,
  },
  {
    name: 'NEEDS RESCHEDULED (hours)',
    selector: 'needs_rescheduled_hours',
    sortable: true,
  },
];

const ProductivityReport: FunctionComponent = () => {
  const classes = useStyles();

  const { formValues, productivityRecords, isLoading } = useStoreObservable(reportStore);
  const { fetchChoices, supervisorChoices } = useContext(ReportsContext);

  const [downloadDisabled, setDownloadDisabled] = useState(true);
  const [generateButtonDisabled, setGenerateButtonDisabled] = useState(true);
  const [supervisorsFetched, setSupervisorsFetched] = useState(false);

  useEffect(() => {
    if (!supervisorsFetched) {
      setSupervisorsFetched(true);
      fetchChoices();
    }
  }, [supervisorsFetched, fetchChoices]);

  const handleFormValueChange = (key: string, value: string) => {
    setDownloadDisabled(true);
    reportStore.setState({ formValues: { ...formValues, [key]: value } });
  };

  const handleReportGeneration = async () => {
    setDownloadDisabled(true);
    await reportStore.generateProductivityReport();
    setDownloadDisabled(false);
  };

  useEffect(() => {
    if (formValues?.week_of && formValues?.supervisor_ids && !isLoading) {
      setGenerateButtonDisabled(false);
    } else {
      setGenerateButtonDisabled(true);
    }
  }, [formValues, isLoading]);

  return (
    <Box>
      <Typography variant="h2" component="h2">
        Select Date Range and Supervisor
      </Typography>
      <Grid container spacing={3}>
        <Grid item>
          <TextInput
            styleOverrides={classes.textInput}
            value={formValues?.week_of}
            label="Week Of"
            type="date"
            shrinkLabel
            valueChanged={(value: string) => handleFormValueChange('week_of', value)}
          />
        </Grid>
        <Grid item xs={12} md={4} xl={3}>
          <FormControl variant="filled" className={classes.textInput}>
            <InputLabel htmlFor="supervisor-multiple-select">Supervisors</InputLabel>
            <Select
              multiple
              value={formValues?.supervisor_ids || []}
              onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                let selectedValues = event.target.value as number[];
                if (selectedValues.includes(0)) {
                  selectedValues = [0];
                }
                setDownloadDisabled(true);
                reportStore.setState({ formValues: { ...formValues, supervisor_ids: selectedValues } });
              }}
              input={<FilledInput id="supervisor-multiple-select" />}
              renderValue={(selected) => {
                const selectedIds = selected as string[];
                return selectedIds
                  .map((id) => {
                    const choice = supervisorChoices.find((choice) => choice.value === id);
                    return choice ? choice.label : '';
                  })
                  .join(', ');
              }}
            >
              {supervisorChoices.map((choice) => (
                <MenuItem key={choice.value} value={choice.value}>
                  {choice.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <br />
      <Button variant="contained" color="primary" onClick={handleReportGeneration} disabled={generateButtonDisabled}>
        {isLoading ? 'Generating...' : 'Generate Productivity Report'}
      </Button>
      <CSVLink
        data={productivityRecords}
        filename={`Productivity_${formValues.week_of}.csv`}
        className={`MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary ${
          downloadDisabled ? 'Mui-disabled' : ''
        }`}
        style={{ marginLeft: '8px' }}
      >
        Download Report
      </CSVLink>
      <Box my={4}>
        <DataTable title="" columns={columns} noHeader data={productivityRecords} />
      </Box>
    </Box>
  );
};

export default ProductivityReport;
