import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { TrainingsContext } from '../contexts/TrainingsContext';
import ConfirmCancelModal from '../../shared/components/modal/ConfirmCancelModal';
import { Typography } from '@material-ui/core';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutlineRounded';
import axios from '../../shared/utils/axios.utils';
import { useStyles } from '../../shared/style/siteWideStyles';

function DeleteTrainingModal() {
  const classes = useStyles();
  const history = useHistory();
  const { trainingDestroyModalOpen, setTrainingDestroyModalOpen, trainingToDelete } = useContext(TrainingsContext);

  const handleDestroyFormSubmit = async () => {
    await axios.delete<string, any>(`/manage_trainings/${trainingToDelete}`);
    history.push(`/manage_trainings`);
  };

  return (
    <ConfirmCancelModal
      isOpen={trainingDestroyModalOpen}
      openStatusChanged={setTrainingDestroyModalOpen}
      cancelLabel="Cancel"
      confirmLabel="Delete"
      width="768px"
      height="350px"
      onConfirm={handleDestroyFormSubmit}
      onCancel={() => setTrainingDestroyModalOpen(false)}
      hasTitleBar={false}
      hasFooterBar={false}
      hasCancelButton
      hasConfirmButton
    >
      <>
        <ErrorOutlineIcon className={classes.errorIcon} />
        <Typography align="center" className={classes.modalText} variant="h5">
          Are you sure you want to delete this training? This action cannot be undone.
        </Typography>
      </>
    </ConfirmCancelModal>
  );
}

export default DeleteTrainingModal;
