import React, { FunctionComponent } from 'react';
import { Box, Card, CardContent, Grid, Typography } from '@material-ui/core';
import PasswordResetForm from '../components/PasswordResetEmailRequestForm';

const PasswordResetEmailRequestPage: FunctionComponent = () => {
  return (
    <Box>
      <Grid justify="space-between" container spacing={3}>
        <Grid item>
          <Typography variant="h1" component="h1">
            Reset Password Email Request
          </Typography>
        </Grid>
      </Grid>
      <br />
      <Card>
        <CardContent>
          <PasswordResetForm />
        </CardContent>
      </Card>
    </Box>
  );
};

export default PasswordResetEmailRequestPage;
