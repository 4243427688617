import React, { FunctionComponent, useEffect } from 'react';
import UsersList from '../components/UsersList';
import { useStoreObservable } from '../../shared/state/useStoreObservable.hook';
import { userRecordStore } from '../../shared/singletons';

export type UsersListPageProps = Record<string, unknown>;

const UsersListPage: FunctionComponent<UsersListPageProps> = () => {
  const userState = useStoreObservable(userRecordStore);
  const { filteredRecords, records, recordsLoading, totalRecords, supervisors } = userState;

  useEffect(() => {
    // if (JSON.parse(sessionStorage.getItem('userState'))?.loadListPageRecords) {
    userRecordStore.fetchRecords();
    // }
  }, []);

  return (
    <UsersList
      records={filteredRecords || records}
      recordsLoading={recordsLoading}
      totalRecords={totalRecords}
      showFilterBar
      supervisors={supervisors}
    />
  );
};

export default UsersListPage;
