import React, { FunctionComponent } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import LoadingSpinner from '../../../shared/components/LoadingSpinner';
import { applicationSettingsStore } from '../../../shared/singletons';
import { useStoreObservable } from '../../../shared/state/useStoreObservable.hook';
import ApplicationSettingsForm from '../components/ApplicationSettingsForm';

const ApplicationSettingsPage: FunctionComponent = () => {
  const { isLoading } = useStoreObservable(applicationSettingsStore);

  React.useEffect(() => {
    applicationSettingsStore.fetchRecords();
  }, []);

  return isLoading ? (
    <LoadingSpinner />
  ) : (
    <Box>
      <Grid container>
        <Grid item>
          <Typography variant="h1" component="h1">
            Application Settings
          </Typography>
        </Grid>
      </Grid>
      <ApplicationSettingsForm />
    </Box>
  );
};

export default ApplicationSettingsPage;
