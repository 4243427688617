import React, { useState, useEffect, Dispatch, SetStateAction } from 'react';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Delete from '@material-ui/icons/Delete';

import DataTable from '../../shared/components/DataTable';
import LoadingSpinner from '../../shared/components/LoadingSpinner';
import SelectInput from '../../shared/components/form/SelectInput';
import TextInput from '../../shared/components/form/TextInput';
import { SelectOption } from '../../shared/common.model';
import { useStyles } from '../../shared/style/siteWideStyles';
import axios from '../../shared/utils/axios.utils';
import InformationField from '../../shared/components/InformationField';
import DeleteLineItemModal from './DeleteLineItemModal';
import ResetCheckmarkButton from './ResetCheckmarkButton';

import { Invoice, InvoiceLineItemRecord } from '../model';

interface AdditionaLineItemsProps {
  invoice: Invoice;
  setInvoice: Dispatch<SetStateAction<Invoice>>;
  setActiveStep: Dispatch<SetStateAction<number>>;
  stepIndex: number;
}

const AdditionalLineItems: React.FC<AdditionaLineItemsProps> = (props) => {
  let classes = useStyles();

  let { invoice, setInvoice, setActiveStep } = props;

  let [invoiceLineItemOptions, setInvoiceLineItemOptions] = useState<SelectOption[]>([]);
  let [lineItemRecords, setLineItemRecords] = useState<InvoiceLineItemRecord[]>([]);
  let [loading, setLoading] = useState(true);
  let [lineItemToDelete, setLineItemToDelete] = useState<InvoiceLineItemRecord>(null);
  let [deleteLineItemModalOpen, setDeleteLineItemModalOpen] = useState(false);
  let [formValues, setFormValues] = useState<InvoiceLineItemRecord>();
  let [totalCost, setTotalCost] = useState<string>('');

  let fetchLineItemOptions = async () => {
    let response = await axios.get('/invoices/invoice_line_item_options');
    setInvoiceLineItemOptions(response.data.invoice_line_item_options);
  };

  let fetchLineItemRecords = async () => {
    let response = await axios.get(`/invoices/invoices/${invoice?.id}/line_item_records`);
    setLineItemRecords(response.data.result);
  };

  useEffect(() => {
    if (invoice?.id) {
      let updatedFormValues = { ...formValues, invoice_id: invoice?.id?.toString() };
      setFormValues(updatedFormValues);
      fetchLineItemOptions();
      fetchLineItemRecords();
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoice]);

  useEffect(() => {
    if (formValues?.unit_cost && formValues?.quantity) {
      let total = parseFloat(formValues?.unit_cost) * parseFloat(formValues?.quantity);
      setTotalCost(total.toFixed(2));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues?.unit_cost, formValues?.quantity]);

  const addLineItem = async () => {
    let response = await axios.post(`/invoices/invoice_line_item_records`, {
      invoice_line_item_record: formValues,
    });

    setLineItemRecords(response.data.result);
  };

  const handleDeleteClicked = async (lineItemRecord: InvoiceLineItemRecord) => {
    setLineItemToDelete(lineItemRecord);
    setDeleteLineItemModalOpen(true);
  };

  const lineItemColumns = [
    { name: 'Description', selector: 'description' },
    { name: 'Unit Cost', selector: 'unit_cost_currency', grow: 0.5 },
    { name: 'Quantity', selector: 'quantity', grow: 0.5 },
    { name: 'Total', selector: 'total_cost_currency', grow: 0.5 },
    {
      name: 'Delete',
      cell: (row) => (
        <Button onClick={() => handleDeleteClicked(row)}>
          <Delete style={{ color: 'red' }} />
        </Button>
      ),
      grow: 0.5,
    },
  ];

  let handleContinue = async () => {
    let response = await axios.put(`/invoices/invoices/${invoice?.id}`, {
      step_index: props.stepIndex,
    });
    setInvoice(response.data.result);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  return !loading ? (
    <Grid container spacing={1} style={{ padding: '2%' }}>
      <DeleteLineItemModal
        lineItemToDelete={lineItemToDelete}
        deleteLineItemModalOpen={deleteLineItemModalOpen}
        setDeleteLineItemModalOpen={setDeleteLineItemModalOpen}
        setLineItemRecords={setLineItemRecords}
      />
      <Grid item xs={12} style={{ padding: 7, marginTop: -20 }}>
        <DataTable columns={lineItemColumns} data={lineItemRecords} striped highlightOnHover noHeader />
      </Grid>
      <Grid item xs={formValues?.invoice_line_item_option_id === '0' ? 2 : 5}>
        <SelectInput
          styleOverrides={classes.textInput}
          type="text"
          label="Description"
          choices={invoiceLineItemOptions}
          value={formValues?.invoice_line_item_option_id}
          valueChanged={(value) => setFormValues({ ...formValues, invoice_line_item_option_id: value })}
        />
      </Grid>
      {formValues?.invoice_line_item_option_id === '0' && (
        <Grid item xs={3}>
          <TextInput
            styleOverrides={classes.textInput}
            type="text"
            label="New Description"
            value={formValues?.new_description}
            valueChanged={(value) => setFormValues({ ...formValues, new_description: value })}
          />
        </Grid>
      )}
      <Grid item xs={2}>
        <TextInput
          styleOverrides={classes.textInput}
          type="dollar"
          label="Unit Cost"
          value={formValues?.unit_cost}
          valueChanged={(value) => setFormValues({ ...formValues, unit_cost: value })}
        />
      </Grid>
      <Grid item xs={2}>
        <TextInput
          styleOverrides={classes.textInput}
          type="text"
          label="Quantity"
          value={formValues?.quantity}
          valueChanged={(value) => setFormValues({ ...formValues, quantity: value })}
        />
      </Grid>
      <Grid item xs={2}>
        <InformationField label="Total" value={`$ ${totalCost}`} />
      </Grid>
      <Grid item xs={1}>
        <Button variant="contained" color="primary" onClick={addLineItem}>
          Add
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Button variant="contained" color="primary" onClick={handleContinue} style={{ marginRight: 10 }}>
          Continue
        </Button>
        <ResetCheckmarkButton invoice={invoice} setInvoice={setInvoice} stepIndex={props.stepIndex} />
      </Grid>
    </Grid>
  ) : (
    <LoadingSpinner />
  );
};

export default AdditionalLineItems;
