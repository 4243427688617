import React, { FunctionComponent, useEffect, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Grid, Typography } from '@material-ui/core';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import LoadingSpinner from '../../shared/components/LoadingSpinner';
import { sessionNoteStore } from '../../shared/singletons';
import { Appointment } from '../../appointments/model';
import { SessionNoteFormValue, SessionNoteResponse } from '../model';
import DirectServiceForm from '../../sessionNoteForms/components/DirectServiceForm';
import GenericForm from '../../sessionNoteForms/components/GenericForm';
import AppointmentValues from './AppointmentValues';
import IncompleteAppointmentTicketModal from '../../userTickets/components/IncompleteAppointmentTicketModal';
import { AppointmentsContext } from '../../appointments/contexts/AppointmentsContext';
import { axios, alertStore } from '../../shared/singletons';
import { questionsByCategory } from '../../sessionNotes/constants/questionsByCategory';
import AppointmentErrorsList from '../../appointments/components/AppointmentErrorsList';

interface NewSessionNoteProps {
  appointment: Appointment;
  studentId: string;
  appointmentLoading: boolean;
}

const NewSessionNote: FunctionComponent<NewSessionNoteProps> = (props) => {
  const { appointment, studentId, appointmentLoading } = props;

  const history = useHistory();

  const { updateButtonDisabled, appointmentFormValues } = useContext(AppointmentsContext);

  const [goalRefs] = useState<HTMLDivElement[]>([]);
  const [expandedGoals, setExpandedGoals] = useState({});
  const [contentFields, setContentFields] = useState({});
  const [goalNoteFormValues, setGoalNoteFormValues] = useState<SessionNoteFormValue[]>(appointment.session_notes);
  const [ticketModalOpen, setTicketModalOpen] = useState(false);
  const [publishButtonMessage, setPublishButtonMessage] = useState('');

  useEffect(() => {
    if (appointment?.draft_notes?.length > 0) {
      try {
        setContentFields(JSON.parse(appointment?.draft_notes?.[0]?.content));
      } catch {}
    }
  }, [appointment]);

  const hasPendingTicket = appointment?.pending_ticket_already_exists;

  const errorWithPendingTicketMessage = `
  There are errors in the form above. Typically, you would be prompted to use the
  'Timeline Support Request' button to submit a ticket to change this appointment.
  Right now, there is already a pending ticket for this appointment.
  The Support Request button will be enabled when that ticket is resolved.`;

  const pendingTicketMessage = () => `
  The 'Timeline Support Request' button cannot be used right now because there is
  already a pending ticket for this appointment.  You may still publish your appointment notes.`;

  const errorWithoutPendingTicketMessage = `
  Please fix the appointment time errors above to publish your session notes.
  Or click the 'Timeline Support Request' button to request an admin to submit your session notes.`;

  useEffect(() => {
    if (updateButtonDisabled && hasPendingTicket) {
      setPublishButtonMessage(errorWithPendingTicketMessage);
    } else if (updateButtonDisabled) {
      setPublishButtonMessage(errorWithoutPendingTicketMessage);
    } else if (hasPendingTicket) {
      setPublishButtonMessage(pendingTicketMessage);
    } else {
      setPublishButtonMessage('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateButtonDisabled, hasPendingTicket]);

  const handleCreateGoalNotes = () => {
    const validateFormGoal = (field: string, formGoal: any, index: number) => {
      if (!formGoal?.[field] || formGoal?.[field] === '') {
        sessionNoteCompleted = false;
        errorGoals.push(index);
        return 'must be filled out.';
      }
      return '';
    };

    let sessionNoteCompleted = true;
    const errorGoals: number[] = [];
    const newErrorValues = [];

    for (const [index, goal] of Array.from(appointment?.active_goals.entries() || [])) {
      const formGoal = goalNoteFormValues.find((g) => g.goal_id === goal.id);
      const errorMessages = {
        percent_string: validateFormGoal('percent_string', formGoal, index),
        assistance: validateFormGoal('assistance', formGoal, index),
        prompts: validateFormGoal('prompts', formGoal, index),
      };

      newErrorValues.push({
        goal_id: goal.id,
        ...errorMessages,
      });
    }

    sessionNoteStore.setState({ errorValues: newErrorValues });

    if (sessionNoteCompleted) {
      sessionNoteStore.createSessionNotes(goalNoteFormValues, appointmentFormValues, appointment.id, studentId, true);
    } else {
      goalRefs[Math.min(...errorGoals)]?.scrollIntoView();
      const goalsToOpen: Record<string, boolean> = {};

      for (const [index] of Array.from(appointment.active_goals.entries())) {
        goalsToOpen[index.toString()] = errorGoals.includes(index);
      }

      setExpandedGoals(goalsToOpen);
    }
  };

  const handleCreateGoalNotesDraft = () => {
    sessionNoteStore.createSessionNotes(goalNoteFormValues, appointmentFormValues, appointment.id, studentId, false);
  };

  const handleCreateAppointmentNote = (published: boolean) => {
    axios
      .post<string, SessionNoteResponse>(`/appointments/${appointment.id}/appointment_notes.json`, {
        template_category: appointment?.note_template,
        content_fields: contentFields,
        appointment: appointmentFormValues,
        published: published,
        headers: { 'Content-Type': 'application/json' },
      })
      .then((response) => {
        history.push(`/appointments`);
        alertStore.alertSuccess('Successfully saved session note');
      })
      .catch((error) => {
        alertStore.alertError('Something went wrong saving your notes, please contact Timeline Support.');
      });
  };

  const requiredAppointmentFieldsCompleted = (): boolean => {
    const questions = questionsByCategory[appointment.note_template]?.questions;

    if (!questions) return false; // If there are no questions for the current note template

    for (let questionKey in questions) {
      const question = questions[questionKey];

      if (question.required) {
        const fieldKey = question.label.toLowerCase();
        console.log(question);

        // Check if the contentFields has the required question and it's not undefined or empty
        if (!contentFields[fieldKey] || contentFields[fieldKey] === undefined || contentFields[fieldKey] === '') {
          console.log(contentFields[fieldKey]);
          return false; // Return false if a required question isn't answered
        }
      }
    }

    return true; // If we didn't return false above, then all required questions are completed
  };

  // TODO: These variables are used for the three buttons at the bottom of the form.
  // Refactor as two separate components to simplify the logic.  One for when it's a direct service, and one for when it's not.
  const publishButtonText =
    appointment.note_template === 'direct_service' ? 'Publish Note' : 'Publish Appointment Note';
  const handlePublish =
    appointment.note_template === 'direct_service' ? handleCreateGoalNotes : () => handleCreateAppointmentNote(true);
  const handleDraft =
    appointment.note_template === 'direct_service'
      ? handleCreateGoalNotesDraft
      : () => handleCreateAppointmentNote(false);
  const isPublishDisabled =
    (appointment.note_template === 'direct_service' && updateButtonDisabled) ||
    (appointment.note_template !== 'direct_service' &&
      (hasPendingTicket || updateButtonDisabled || !requiredAppointmentFieldsCompleted()));

  useEffect(() => {
    console.log('appointment', appointment);
    console.log('apointment.note_template', appointment.note_template);
    console.log('isPublishDisabled', isPublishDisabled);
    console.log('updateButtonDisabled', updateButtonDisabled);
    console.log('hasPendingTicket', hasPendingTicket);
    console.log('requiredAppointmentFieldsCompleted', requiredAppointmentFieldsCompleted());
  });

  return appointmentLoading ? (
    <LoadingSpinner />
  ) : (
    <>
      {/* TODO: Use appointmentFormValues from the context in this component */}
      <IncompleteAppointmentTicketModal
        appointment={appointment}
        onSuccess={() => handleCreateAppointmentNote(false)}
        modalOpen={ticketModalOpen}
        setModalOpen={setTicketModalOpen}
        appointmentFormValues={appointmentFormValues}
      />
      <AppointmentValues appointment={appointment} />
      <br />

      <Grid container justify="space-between" spacing={3}>
        {appointment.note_template === 'direct_service' ? (
          <Grid item xs={12}>
            <DirectServiceForm
              appointment={appointment}
              noteFormValues={goalNoteFormValues}
              setNoteFormValues={setGoalNoteFormValues}
              goalRefs={goalRefs}
              expandedGoals={expandedGoals}
              setExpandedGoals={setExpandedGoals}
            />
          </Grid>
        ) : (
          <Grid item xs={12}>
            <GenericForm
              appointment={appointment}
              contentFields={contentFields}
              setContentFields={setContentFields}
              category={appointment.note_template}
            />
          </Grid>
        )}

        <Grid item xs={12}>
          <Grid alignItems="center" container spacing={1}>
            <Grid item>
              <ErrorOutlineIcon color="primary" style={{ display: 'inherit' }} />
            </Grid>
            <Grid item>
              <Typography color="primary" variant="body1">
                All goals need to be completed to publish your note
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item>
              <Button variant="contained" color="primary" disabled={isPublishDisabled} onClick={handlePublish}>
                {publishButtonText}
              </Button>
            </Grid>
            <Grid item>
              <Button variant="contained" color="primary" onClick={handleDraft}>
                Save as Draft
              </Button>
            </Grid>
            <Grid item>
              <Button variant="contained" color="default" href={`/appointments`}>
                Delete/Discard
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} style={{ marginTop: -10 }}>
          <AppointmentErrorsList />
          <Button
            variant="contained"
            color="primary"
            disabled={hasPendingTicket}
            onClick={() => setTicketModalOpen(true)}
          >
            Timeline Support Request
          </Button>
        </Grid>
        <Grid item xs={12} style={{ marginTop: -10 }}>
          <p style={{ color: 'red' }}>{publishButtonMessage}</p>
        </Grid>
      </Grid>
    </>
  );
};

export default NewSessionNote;
