import React, { FunctionComponent } from 'react';
import { Box, Grid, Typography, makeStyles } from '@material-ui/core';
import ConfirmCancelModal from '../../../shared/components/modal/ConfirmCancelModal';
import TextInput from '../../../shared/components/form/TextInput';
import { RenewalStagesContext } from '../contexts/RenewalStagesContext';
import { RenewalStageFormValues } from '../model';

const useStyles = makeStyles({
  textInput: {
    height: 'auto',
    width: '98%',
    margin: '14px 0',
  },
  baseError: {
    marginBottom: '10px',
  },
});

const EditRenewalStageModal: FunctionComponent = () => {
  const { editModalOpen, setEditModalOpen, renewalStage, updateRenewalStage } = React.useContext(RenewalStagesContext);
  const classes = useStyles();

  const [formValues, setFormValues] = React.useState<RenewalStageFormValues>({});

  React.useEffect(() => {
    setFormValues({
      name: renewalStage?.name,
    });
  }, [renewalStage]);

  const handleFormSubmit = () => {
    updateRenewalStage(formValues, renewalStage);
  };

  return (
    <ConfirmCancelModal
      title="Edit Renewal Stage"
      cancelLabel="Cancel"
      confirmLabel="Update Renewal Stage"
      width="768px"
      hasCancelButton
      hasConfirmButton
      isOpen={editModalOpen}
      onConfirm={handleFormSubmit}
      openStatusChanged={(open) => setEditModalOpen(open, renewalStage)}
    >
      <Box p={2}>
        <Typography variant="h2">Renewal Stage Details</Typography>
        <Grid container spacing={3}>
          <Grid item xs={10}>
            <TextInput
              styleOverrides={classes.textInput}
              label="Renewal Stage Name"
              value={formValues?.name}
              valueChanged={(value) => setFormValues({ ...formValues, name: value })}
            />
          </Grid>
        </Grid>
        <br />
      </Box>
    </ConfirmCancelModal>
  );
};

export default EditRenewalStageModal;
