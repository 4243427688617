import React, { FunctionComponent } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextInput from '../../shared/components/form/TextInput';
import ErrorAlert from '../../shared/components/ErrorAlert';
import { serviceFormStore } from '../../shared/singletons';
import { useStoreObservable } from '../../shared/state/useStoreObservable.hook';

// TODO: Temp styles before we line things up with the mockup
const useStyles = makeStyles({
  textInput: {
    height: '60px',
    width: '80%',
    margin: '10px 0',
  },
  baseError: {
    marginBottom: '10px',
  },
});

const ServiceForm: FunctionComponent = () => {
  const classes = useStyles();

  const { formErrorAlertVisible, formErrors, formValues } = useStoreObservable(serviceFormStore);

  const handleFormValueChange = (key: string, value: string) => {
    serviceFormStore.setFormValue({ ...formValues, [key]: value });
  };

  return (
    <form noValidate autoComplete="off">
      {formErrors?.base && (
        <ErrorAlert
          display={formErrorAlertVisible}
          onClose={() => serviceFormStore.setState({ formErrorAlertVisible: false })}
        >
          {formErrors?.base}
        </ErrorAlert>
      )}
      <TextInput
        styleOverrides={classes.textInput}
        label="Initial Referral Date"
        type="date"
        shrinkLabel
        errorMessage={formErrors?.initial_referral_date}
        value={
          formValues?.initial_referral_date ||
          `${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDate()}`
        }
        valueChanged={(value: string) => handleFormValueChange('initial_referral_date', value)}
      />
      <TextInput
        styleOverrides={classes.textInput}
        label="Start Date"
        type="date"
        shrinkLabel
        errorMessage={formErrors?.start_date}
        value={formValues?.start_date}
        valueChanged={(value: string) => handleFormValueChange('start_date', value)}
      />
      <TextInput
        styleOverrides={classes.textInput}
        label="End Date"
        type="date"
        shrinkLabel
        errorMessage={formErrors?.end_date}
        value={formValues?.end_date}
        valueChanged={(value: string) => handleFormValueChange('end_date', value)}
      />
      <TextInput
        styleOverrides={classes.textInput}
        type="text"
        label="Frequency"
        errorMessage={formErrors?.frequency}
        value={formValues?.frequency}
        valueChanged={(value: string) => handleFormValueChange('frequency', value)}
      />
      <TextInput
        styleOverrides={classes.textInput}
        label="ESY Start Date"
        type="date"
        shrinkLabel
        errorMessage={formErrors?.esy_start_date}
        value={formValues?.esy_start_date}
        valueChanged={(value: string) => handleFormValueChange('esy_start_date', value)}
      />
      <TextInput
        styleOverrides={classes.textInput}
        label="ESY End Date"
        type="date"
        shrinkLabel
        errorMessage={formErrors?.esy_end_date}
        value={formValues?.esy_end_date}
        valueChanged={(value: string) => handleFormValueChange('esy_end_date', value)}
      />
      <TextInput
        styleOverrides={classes.textInput}
        type="text"
        label="ESY Frequency"
        errorMessage={formErrors?.esy_frequency}
        value={formValues?.esy_frequency}
        valueChanged={(value: string) => handleFormValueChange('esy_frequency', value)}
      />
    </form>
  );
};

export default ServiceForm;
