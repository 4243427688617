import React, { useState, createContext } from 'react';
import { axios } from '../../shared/singletons';
import { Service } from '../../services/model';
import { AcademicYear } from '../../services/model';
// import { set } from 'date-fns';

const DuplicateAppointmentsContext = createContext({
  fetchRecord: undefined,
  duplicateAppointmentsModalOpen: false,
  setDuplicateAppointmentsModalOpen: null,
  duplicateAppointmentsByQuarterModalOpen: false,
  setDuplicateAppointmentsByQuarterModalOpen: null,
  duplicateAppointmentsByWeekModalOpen: false,
  setDuplicateAppointmentsByWeekModalOpen: null,
  duplicateAppointmentsByRenewalDateModalOpen: true,
  setDuplicateAppointmentsByRenewalDateModalOpen: null,
  appointmentScheduler: null,
  formValues: null,
  setFormValues: undefined,
  formErrors: null,
  setFormErrors: undefined,
  yearlongGeneratorModalOpen: false,
  setYearlongGeneratorModalOpen: undefined,
  serviceForAppointmentGenerator: null,
  setServiceForAppointmentGenerator: undefined,
  submittingDuplication: false,
  setSubmittingDuplication: undefined,
  academicYear: null,
});

const DuplicateAppointmentsContextConsumer = DuplicateAppointmentsContext.Consumer;
const DuplicateAppointmentsContextProvider = ({ children }) => {
  const [duplicateAppointmentsModalOpen, setDuplicateAppointmentsModalOpen] = useState<boolean>(false);
  const [duplicateAppointmentsByQuarterModalOpen, setDuplicateAppointmentsByQuarterModalOpen] = useState<boolean>(
    false,
  );
  const [duplicateAppointmentsByWeekModalOpen, setDuplicateAppointmentsByWeekModalOpen] = useState<boolean>(false);
  const [duplicateAppointmentsByRenewalDateModalOpen, setDuplicateAppointmentsByRenewalDateModalOpen] = useState<
    boolean
  >(false);
  const [serviceForAppointmentGenerator, setServiceForAppointmentGenerator] = useState<Service>({});
  const [yearlongGeneratorModalOpen, setYearlongGeneratorModalOpen] = useState<boolean>(false);
  const [submittingDuplication, setSubmittingDuplication] = useState<boolean>(false);
  const [academicYear, setAcademicYear] = useState<AcademicYear>(null);

  const [formErrors, _setFormErrors] = useState({
    base: '',
    schedule_date: '',
    start_hour: '',
    end_hour: '',
    location_id: '',
    owner_array: '',
  });
  const [formValues, _setFormValues] = useState({
    schedule_date: '',
    start_time: '',
    end_time: '',
    location_id: '',
    referral_service_id: '',
    owner_array: [''],
  });
  const [appointmentScheduler, setAppointmentScheduler] = useState<any>({});

  const setFormValues = async (newValues: any) => {
    _setFormValues(newValues);
  };

  const setFormErrors = async (newErrors: any) => {
    _setFormErrors(newErrors);
  };

  const fetchRecord = async (userId: string, studentId = '0', params: string) => {
    setSubmittingDuplication(true);

    await axios.get<string, any>(`users/${userId}/appointment_scheduler/${studentId}.json?${params}`).then((r: any) => {
      if (r.data?.result?.error_message) {
        setAppointmentScheduler(r.data?.result);
        setSubmittingDuplication(false);
      } else {
        r.data?.result?.school_choices.push({ value: '0', label: 'All Schools' });
        setAppointmentScheduler(r.data?.result);
        setAcademicYear(r.data?.result?.academic_year);
        setSubmittingDuplication(false);
      }
    });
  };

  return (
    <DuplicateAppointmentsContext.Provider
      value={{
        fetchRecord,
        formValues,
        formErrors,
        setFormErrors,
        setFormValues,
        duplicateAppointmentsModalOpen,
        setDuplicateAppointmentsModalOpen,
        duplicateAppointmentsByQuarterModalOpen,
        setDuplicateAppointmentsByQuarterModalOpen,
        duplicateAppointmentsByWeekModalOpen,
        setDuplicateAppointmentsByWeekModalOpen,
        duplicateAppointmentsByRenewalDateModalOpen,
        setDuplicateAppointmentsByRenewalDateModalOpen,
        appointmentScheduler,
        serviceForAppointmentGenerator,
        setServiceForAppointmentGenerator,
        yearlongGeneratorModalOpen,
        setYearlongGeneratorModalOpen,
        submittingDuplication,
        setSubmittingDuplication,
        academicYear,
      }}
    >
      {children}
    </DuplicateAppointmentsContext.Provider>
  );
};

export { DuplicateAppointmentsContextProvider, DuplicateAppointmentsContextConsumer, DuplicateAppointmentsContext };
