import styled from 'styled-components';
import { Colors } from '../../style/colors';

export const ModalHeader = styled.div`
  flex: 0 1 54px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-bottom: solid 1px ${Colors.TertiaryOne};
  box-sizing: border-box;
  padding: 15px;

  .title {
    flex: 1 1 auto;
    font-size: 26px;
    font-weight: bold;
    color: ${Colors.PurplePrimary};
  }

  .header-close-button {
    flex: 0 0 auto;
    cursor: pointer;
  }
`;
