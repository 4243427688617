import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Typography } from '@mui/material';
import DeleteModal from '../../shared/components/modal/DeleteModal';
import { DischargeStudentContext } from '../../students/contexts/DischargeStudentContext';
import { axios } from '../../shared/singletons';
import { StudentResponse } from '../../students/model';
import { alertStore } from '../../shared/singletons';

function DischargeStudentModal() {
  const history = useHistory();

  const {
    studentsToDischarge,
    dischargeStudentsModalOpen,
    setDischargeStudentsModalOpen,
    schoolToDischarge,
  } = useContext(DischargeStudentContext);

  const dischargeStudentAndAllRecords = async (studentId: string) => {
    try {
      await axios.put<string, StudentResponse>(`discharge_patient/${studentId}/discharge_all`, {
        headers: { 'Content-Type': 'application/json' },
      });
    } catch {
      alertStore.alertError('Unable to discharge student, please try again');
    }
  };

  const handleDischargeSelectedStudents = async () => {
    const dischargePromises = studentsToDischarge.map((student) => dischargeStudentAndAllRecords(student.id));
    await Promise.all(dischargePromises);

    history.push(`/schools/${schoolToDischarge.id}/discharge`);
  };

  return (
    <DeleteModal
      isOpen={dischargeStudentsModalOpen}
      openStatusChanged={setDischargeStudentsModalOpen}
      onDelete={handleDischargeSelectedStudents}
      confirmLabel="Discharge"
      height={'400px'}
    >
      <>
        <Typography align="center" variant="h5">
          Are you sure you want to discharge {studentsToDischarge?.length} students? This action cannot be undone.
        </Typography>
      </>
    </DeleteModal>
  );
}

export default DischargeStudentModal;
