import React, { Dispatch, SetStateAction } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Paper, Typography } from '@material-ui/core';
import TextInput from '../../shared/components/form/TextInput';
import SelectInput from '../../shared/components/form/SelectInput';
import { EmailAlert } from '../model';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const useStyles = makeStyles({
  formControl: {
    margin: '10px',
    minWidth: 120,
    width: '98%',
  },
  checkbox: {
    marginLeft: '10px',
  },
  button: {
    marginTop: '-5px',
  },
  textInput: {
    height: 'auto',
    width: '98%',
    margin: '14px 0',
  },
  preview: {},
});

interface EmailAlertFormProps {
  formValues: EmailAlert;
  setFormValues: Dispatch<SetStateAction<EmailAlert>>;
}

const EmailAlertForm: React.FunctionComponent<EmailAlertFormProps> = (props) => {
  const classes = useStyles();

  const { formValues, setFormValues } = props;

  return (
    <form noValidate autoComplete="off">
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <SelectInput
            styleOverrides={classes.textInput}
            type="text"
            label="Role"
            value={formValues?.role}
            choices={[
              { label: 'Accountant', value: 'accountant' },
              { label: 'Services Coordinator', value: 'services_coordinator' },
              { label: 'Service Providers', value: 'therapist' },
              { label: 'Supervisors', value: 'supervisor' },
              { label: 'System Administrators', value: 'admin' },
              { label: 'System Owner', value: 'system_owner' },
            ]}
            valueChanged={(value) => setFormValues({ ...formValues, role: value })}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextInput
            type="text"
            label="Subject"
            shrinkLabel
            value={formValues?.subject}
            valueChanged={(value) => setFormValues({ ...formValues, subject: value })}
          />
        </Grid>
        <Grid item xs={12}>
          <ReactQuill
            theme="snow"
            value={formValues?.message}
            onChange={(value: string) => setFormValues({ ...formValues, message: value })}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            Email Preview:
          </Typography>
          <Paper className={classes.preview}>
            <div style={{ backgroundColor: '#fff', padding: '0', margin: '0', fontFamily: 'Calibri, sans-serif' }}>
              {/* HEADER */}
              <div style={{ width: '100%', backgroundColor: '#fff', textAlign: 'center', padding: '30px 0' }}>
                <a href="https://www.timelinecds.com" target="_blank" rel="noopener noreferrer">
                  <img
                    src="https://www.timelinecds.com/static/media/logo.8e988dd7.png"
                    alt=""
                    style={{ height: '60px' }}
                  />
                </a>
              </div>

              {/* CONTENT */}
              <div
                style={{
                  padding: '30px 10px',
                  borderRadius: '7px',
                  backgroundColor: '#fff',
                  maxWidth: '850px',
                  margin: '0 auto',
                }}
                dangerouslySetInnerHTML={{ __html: formValues?.message }}
              />
            </div>
          </Paper>
        </Grid>
      </Grid>
    </form>
  );
};

export default EmailAlertForm;
