import React from 'react';
import { Button, Card, CardContent, Grid, Typography } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { useHistory } from 'react-router-dom';
import InformationField from '../../shared/components/InformationField';
import ServiceStageChangeModal from '../../services/components/ServiceStageChangeModal';
import { UserContext } from '../../auth/contexts/userContext';
import { dashboardStore, serviceFormStore } from '../../shared/singletons';

interface TimelineServiceTooltipProps {
  allServices?: any;
  setAllServices?: any;
}

const TimelineServiceTooltip: React.FC<TimelineServiceTooltipProps> = (props) => {
  const { allServices, setAllServices } = props;
  const [service, setSelectedService] = React.useState<any>();
  const [serviceIndex, setServiceIndex] = React.useState(0);
  const { currentUserHasRole } = React.useContext(UserContext);

  const history = useHistory();

  React.useEffect(() => {
    setSelectedService(allServices[serviceIndex]);
  }, [serviceIndex, allServices]);

  const incrementService = () => {
    setServiceIndex(serviceIndex + 1);
  };

  const decrementService = () => {
    setServiceIndex(serviceIndex - 1);
  };

  const addSessionNotes = () => {
    if (service.appointment) {
      history.push(`/students/${service.student_id}/appointments/${service.appointment.id}/session_notes/new`);
    } else {
      history.push(`/students/${service.student_id}/services/${service.id}/appointments`);
    }
  };

  return service?.id === undefined ? (
    <Card />
  ) : (
    <Card>
      <ServiceStageChangeModal onSuccess={() => dashboardStore.fetchRecord()} />
      <CardContent style={{ minWidth: 250 }}>
        <>
          {allServices.length > 1 && (
            <Grid container justify="space-between" alignItems="center">
              <Grid item>
                <ArrowBackIosIcon onClick={decrementService} />
              </Grid>
              <Grid item>
                <p>{`${serviceIndex + 1} of ${allServices.length}`}</p>
              </Grid>
              <Grid item>
                <ArrowForwardIosIcon onClick={incrementService} />
              </Grid>
            </Grid>
          )}
          <Grid container justify="space-between">
            <Grid item>
              <Typography variant="h2" component="h2">
                Service Information
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                align="right"
                style={{ cursor: 'pointer', paddingLeft: 20 }}
                onClick={() => {
                  setAllServices({});
                  setSelectedService({});
                }}
              >
                X
              </Typography>
            </Grid>
          </Grid>

          <InformationField label="Student Name" value={`${service.student_name} (${service.student_slug})`} />
          <br />
          <InformationField label="Service" value={service.service_type} />
          <br />
          <InformationField label="Timeline" value={service.timeline} />
          <br />
          {(currentUserHasRole('service_provider') || currentUserHasRole('admin')) && (
            <>
              <div>
                <Typography variant="subtitle1" component="h6">
                  Stage
                </Typography>
                <Typography
                  variant="body1"
                  component="p"
                  style={{
                    whiteSpace: 'break-spaces',
                    textTransform: 'uppercase',
                    fontWeight: 700,
                    color: service.color,
                  }}
                >
                  {service.stage}
                </Typography>
              </div>
              <br />
              {(service.stage === 'Evaluation Scheduled' || service.stage === 'Evaluation Started') && (
                <Button
                  variant="contained"
                  color="primary"
                  style={{ width: '100%', marginBottom: 15 }}
                  onClick={() => addSessionNotes()}
                >
                  Add Session Notes
                </Button>
              )}
              <Button
                variant="contained"
                color="primary"
                style={{ width: '100%' }}
                onClick={() => serviceFormStore.setEditStageModalOpen(true, service)}
              >
                Change Stage
              </Button>
              <Button
                variant="contained"
                color="primary"
                style={{ width: '100%', marginTop: 15 }}
                onClick={() => history.push(`/students/${service.student_id}/services/${service.id}`)}
              >
                View Service
              </Button>
            </>
          )}
        </>
      </CardContent>
    </Card>
  );
};

export default TimelineServiceTooltip;
