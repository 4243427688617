/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Typography, Grid, Card, CardContent, Button } from '@material-ui/core';
import TextInput from '../../shared/components/form/TextInput';
import { StudentsContext } from '../../students/contexts/StudentsContext';
import LoadingSpinner from '../../shared/components/LoadingSpinner';
import PageHeader from '../components/PageHeader';
import { ServiceFormOptionsContext } from '../../services/contexts/ServiceFormOptionsContext';
import { ServicesContext } from '../../services/contexts/ServicesContexts';
import ServiceCard from '../components/ServiceCard';
import { axios } from '../../shared/singletons';
import NavButtons from '../components/NavButtons';

const AnnualUpdaterServices: React.FC = () => {
  const { studentId } = useParams<Record<string, string>>();
  const { fetchChoices } = useContext(ServiceFormOptionsContext);
  const { fetchStudent, student } = useContext(StudentsContext);
  const { services, setServices, fetchServices, servicesChecked, setServicesChecked, servicesLoading } = useContext(
    ServicesContext,
  );
  const [bulkDates, setBulkDates] = useState({ start_date: '2023-08-28', end_date: '2024-06-08' });
  const [servicesLoaded, setServicesLoaded] = useState(false);

  useEffect(() => {
    if (studentId) {
      fetchStudent(studentId);
      fetchChoices();
    }
  }, [studentId]);

  useEffect(() => {
    if (!servicesLoaded) {
      let initialFilter = { 'ransack[patient_id_eq]': studentId, 'ransack[phase_not_eq]': 'discharged' };
      fetchServices(initialFilter);
      setServicesLoaded(true);
    }

    setBulkDates({
      start_date: student.service_implementation_start_date || '2023-08-28',
      end_date: student.service_implementation_end_date || '2024-06-08',
    });
  }, [student]);

  useEffect(() => {
    localStorage.setItem('bulkDates', JSON.stringify(bulkDates));
  }, [bulkDates]);

  const handleSetAllDates = () => {
    const updatedServices = [...services];
    const updatedServiceData = servicesChecked?.map((serviceId, idx) => {
      return axios
        .put(`/patients/${studentId}/referral_services/${serviceId}.json`, { referral_service: bulkDates })
        .then((response) => {
          updatedServices[idx] = response?.data?.result;
        });
    });

    axios.put(`/patients/${studentId}.json`, { patient: bulkDates });

    Promise.all(updatedServiceData).then(() => {
      setServices(updatedServices);
      setServicesChecked([]);
    });
  };

  return servicesLoading ? (
    <LoadingSpinner />
  ) : (
    <>
      <PageHeader
        title="Services & Appointments"
        message="Copy and paste the frequency exactly from the IEP. If a support for School Personnel service, also
        include the 'Support' in the frequency box."
        student={student}
        step={1}
      />
      <br />
      <Grid>
        <Card>
          <CardContent style={{ padding: 15 }}>
            <Typography variant="h2">Bulk Update Service Dates</Typography>

            <Grid container spacing={1}>
              <Grid item sm={3}>
                <TextInput
                  value={bulkDates?.start_date}
                  type="date"
                  shrinkLabel
                  label="Start Date"
                  errorMessage=""
                  valueChanged={(value) => setBulkDates({ ...bulkDates, start_date: value })}
                />
              </Grid>
              <Grid item sm={3}>
                <TextInput
                  value={bulkDates?.end_date}
                  type="date"
                  shrinkLabel
                  label="End Date"
                  errorMessage=""
                  valueChanged={(value) => setBulkDates({ ...bulkDates, end_date: value })}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={servicesChecked?.length === 0}
                  onClick={handleSetAllDates}
                >
                  Update Selected Services
                </Button>
              </Grid>
            </Grid>
            <br />
          </CardContent>
        </Card>
      </Grid>
      <br />
      {services?.map((service, idx) => (
        <Grid style={{ marginBottom: 20 }} key={`service_${service.id}`}>
          <ServiceCard idx={idx} serviceId={service.id} />
        </Grid>
      ))}
      <br />
      <NavButtons activePage="services" studentId={studentId} />
    </>
  );
};

export default AnnualUpdaterServices;
