import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';

function Alert(props: AlertProps) {
  return <MuiAlert elevation={0} variant="filled" {...props} />;
}

interface ErrorAlertProps {
  children?: React.ReactElement | string;
  display?: boolean;
  onClose?: () => void;
}

const useStyles = makeStyles({
  baseError: {
    marginBottom: '10px',
    display: (props) => (props === true ? 'flex' : 'none'),
  },
});

const ErrorAlert: React.FunctionComponent<ErrorAlertProps> = (props: ErrorAlertProps) => {
  const { children, onClose } = props;
  const classes = useStyles(props.display);

  return (
    <Alert className={classes.baseError} onClose={onClose} severity="error">
      {children}
    </Alert>
  );
};

export default ErrorAlert;
