import React, { useState, useContext, useEffect } from 'react';
import { Grid, Typography } from '@material-ui/core';
import DeleteModal from '../../shared/components/modal/DeleteModal';
import SelectInput from '../../shared/components/form/SelectInput';
import TextInput from '../../shared/components/form/TextInput';
import { AppointmentsContext } from '../contexts/AppointmentsContext';
import { UserContext } from '../../auth/contexts/userContext';
import { statusColors, getAppointmentStatusOptions } from '../constants';
import { axios } from '../../shared/singletons';
import { AppointmentFormValues } from '../model';
import InformationField from '../../shared/components/InformationField';
import { useStyles } from '../../shared/style/siteWideStyles';

// Comments are not saving in the extracted modal

function AppointmentQuickStatusDeleteModal() {
  const classes = useStyles();

  const { quickStatusModalOpen, setQuickStatusModalOpen, appointmentToEdit, statusForQuickModal } = useContext(
    AppointmentsContext,
  );
  const { user, currentUserHasRole } = useContext(UserContext);

  const [formValues, setFormValues] = useState<AppointmentFormValues>({});
  const [errorMessage, setErrorMessage] = useState<string>('');

  useEffect(() => {
    setFormValues({ status: statusForQuickModal, comment: '' });
  }, [statusForQuickModal]);

  const updateAppointment = async (newFormValues) => {
    axios.put(`patients/${appointmentToEdit?.student_id}/appointments/${appointmentToEdit?.id}`, {
      appointment: newFormValues,
    });
  };

  const onSubmit = async () => {
    let newFormValues = {};

    if (formValues?.comment?.length > 0) {
      newFormValues = {
        status: formValues?.status,
        status_change: true,
        comments_attributes: {
          '0': {
            content: formValues?.comment,
            user_id: user.id,
            category: ['incomplete', 'needs_rescheduled'].includes(formValues?.status) ? 'status' : 'unknown',
          },
        },
      };

      await updateAppointment(newFormValues);
      setQuickStatusModalOpen(false);
    } else {
      setErrorMessage('Please submit a comment explaining the status change.');
    }
  };

  const appointmentStatusOptions = getAppointmentStatusOptions(appointmentToEdit, currentUserHasRole('admin'));

  const statusLabel = (stat) => {
    const foundOption = appointmentStatusOptions?.find((option) => option.value === stat);
    return foundOption ? foundOption.label : 'Unknown';
  };

  return (
    <DeleteModal
      isOpen={quickStatusModalOpen}
      openStatusChanged={setQuickStatusModalOpen}
      onDelete={onSubmit}
      height="600px"
      confirmLabel="Update Status"
    >
      <>
        <Typography
          align="center"
          style={{
            fontSize: '20px',
            marginLeft: '20%',
            marginBottom: '20px',
            width: '60%',
          }}
          variant="h5"
        >
          Change this appointment's status?
        </Typography>
        <Grid style={{ display: 'flex', justifyContent: 'space-around' }}>
          <InformationField label="Timeline ID" value={appointmentToEdit?.student_slug} />
          <InformationField label="Date" value={appointmentToEdit?.formatted_schedule_date} />
          <InformationField label="Previous Status" value={statusLabel(appointmentToEdit?.status)} />
        </Grid>
        <Grid container justify="center" style={{ marginTop: '30px' }}>
          <Grid item xs={10}>
            <SelectInput
              value={formValues?.status}
              styleOverrides={classes.selectInput}
              label="New Status"
              valueChanged={(value) => setFormValues({ ...formValues, status: value })}
              choices={appointmentStatusOptions}
              textColor={statusColors?.[formValues?.status]}
            />
          </Grid>
          <Grid item xs={10} style={{ marginTop: '20px' }}>
            <TextInput
              value={formValues?.comment}
              styleOverrides={classes.textInput}
              multiline
              rows={2}
              label="Comment"
              valueChanged={(value: string) => setFormValues({ ...formValues, comment: value })}
            />
          </Grid>
          {errorMessage.length > 0 && (
            <Grid item xs={12}>
              <p style={{ color: 'red' }}>{errorMessage}</p>
            </Grid>
          )}
        </Grid>
      </>
    </DeleteModal>
  );
}

export default AppointmentQuickStatusDeleteModal;
