import React, { FunctionComponent } from 'react';
import { Grid } from '@material-ui/core';
import LoadingSpinner from '../../shared/components/LoadingSpinner';
import UpcomingSchoolClosings from './UpcomingSchoolClosings';
import RecentlyUpdatedStudents from './RecentlyUpdatedStudents';
import SchoolUserRadialChart from '../../dashboardCharts/components/SchoolUserRadialChart';
import SchoolUserTimelineVisualisation from '../../dashboardCharts/components/SchoolUserTimeline';
import { UserContext } from '../../auth/contexts/userContext';
import { dashboardStore } from '../../shared/singletons';
import { useStoreObservable } from '../../shared/state/useStoreObservable.hook';
import { ServiceFormOptionsContext } from '../../services/contexts/ServiceFormOptionsContext';

interface SchoolUserDashboardProps {
  isLoading: boolean;
}

const SchoolUserDashboard: FunctionComponent<SchoolUserDashboardProps> = (props) => {
  const { isLoading } = props;
  const { radialValues, radialLabels } = useStoreObservable(dashboardStore);
  const { currentUserHasRole } = React.useContext(UserContext);
  const { fetchChoices } = React.useContext(ServiceFormOptionsContext);

  /* eslint-disable */
  React.useEffect(() => {
    fetchChoices();
  }, []);
  /* eslint-enable */

  return isLoading ? (
    <LoadingSpinner />
  ) : (
    <div>
      <Grid justify="space-between" container spacing={1}>
        <Grid item xs={12}>
          <SchoolUserTimelineVisualisation />
        </Grid>
      </Grid>
      <br />
      <div className="radial-chart-container" style={{ margin: '-45px auto 35px', height: 310, width: 600 }}>
        <div style={{ display: 'inline-block' }}>
          <SchoolUserRadialChart
            data={radialValues.referrals}
            label="Referrals"
            labelNumber={radialLabels.referral_label}
            link={`/referrals?filters=ransack[status_eq]=active__`}
            chartSize="large"
          />
        </div>
      </div>
      <br />
      {currentUserHasRole('school_admin') ? (
        <Grid justify="space-between" container spacing={3}>
          <Grid item xs={12}>
            <RecentlyUpdatedStudents />
          </Grid>
        </Grid>
      ) : (
        <Grid justify="space-between" container spacing={3}>
          <Grid item xs={6}>
            <UpcomingSchoolClosings />
          </Grid>
          <Grid item xs={6}>
            <RecentlyUpdatedStudents />
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default SchoolUserDashboard;
