import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import { StudentsContext } from '../../students/contexts/StudentsContext';
import EditContactInfoForm from '../components/EditContactInfoForm';
import StudentBuilderProgressStepper from '../components/StudentBuilderProgressStepper';

const EditContactInfoPage: React.FunctionComponent = () => {
  const { studentId, contactOnly } = useParams<Record<string, string>>();
  const history = useHistory();
  const [studentFetched, setStudentFetched] = React.useState(false);
  const { fetchStudent, student } = React.useContext(StudentsContext);

  React.useEffect(() => {
    if (!studentFetched) {
      fetchStudent(studentId);
      setStudentFetched(true);
    }
  }, [studentFetched, setStudentFetched, fetchStudent, studentId]);

  return (
    <>
      <Typography variant="h1" component="h1">
        {contactOnly ? 'Update Student Contacts' : "Add Student's Emergency Contacts"}
      </Typography>
      {!contactOnly && <StudentBuilderProgressStepper activeStep={1} />}
      <Grid container flex-direction="column">
        <Grid item xs={10}>
          <EditContactInfoForm
            student={student}
            onSuccess={(id: string) =>
              contactOnly ? history.push(`/students/${id}`) : history.push(`/students/${id}/add_services`)
            }
          />
        </Grid>
      </Grid>
    </>
  );
};

export default EditContactInfoPage;
