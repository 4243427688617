import React, { FunctionComponent } from 'react';
import { Button, Card, CardActions, CardContent, Grid, Typography } from '@material-ui/core';
import DataTable from '../../shared/components/DataTable';
import LoadingSpinner from '../../shared/components/LoadingSpinner';
import { IDataTableColumn } from '../../shared/components/DataTable/DataTable/model';
import { UserContext } from '../../auth/contexts/userContext';
import { Attachment } from '../model';
import AttachmentRowMenu from './AttachmentRowMenu';
import AttachmentFormModal from './AttachmentFormModal';

interface AttachmentsListProps {
  title?: string;
  description?: string;
  schoolUser?: boolean;
  attachments: Attachment[];
  showAddAttachment: boolean;
  isLoading: boolean;
  attachmentableType: string;
  attachmentableId: string;
  afterAction: () => void;
  schoolDocuments?: boolean;
}

const AttachmentsList: FunctionComponent<AttachmentsListProps> = (props) => {
  const {
    title,
    description,
    schoolUser = false,
    attachments,
    showAddAttachment,
    isLoading,
    attachmentableType,
    attachmentableId,
    afterAction,
    schoolDocuments,
  } = props;
  const [showArchived, setShowArchived] = React.useState(false);
  const [modalOpen, setModalOpen] = React.useState(false);
  const { currentUserHasAnyRole } = React.useContext(UserContext);

  const columns: IDataTableColumn<Attachment>[] = [
    {
      name: 'FILE NAME',
      selector: 'file_name',
      sortable: true,
      cell: (row) => (
        <a target="_blank" rel="noopener noreferrer" href={row.file_url}>
          {row.file_name}
        </a>
      ),
    },
    {
      name: 'FILE TYPE',
      selector: 'file_name',
      sortable: true,
      cell: (row) => <p>{/* TODO: Match this category to student builder */}Student Documentation</p>,
    },
    {
      name: 'DATE CREATED',
      selector: 'date_created',
      sortable: true,
    },
    {
      name: 'ARCHIVED',
      selector: 'archived',
      sortable: true,
      grow: 0.7,
      cell: (row) => <p>{row.archived ? 'Archived' : 'Active'}</p>,
    },
    {
      cell: (attachment) => <AttachmentRowMenu attachment={attachment} afterAction={afterAction} />,
      name: 'ACTIONS',
      allowOverflow: true,
      omit: !currentUserHasAnyRole(['admin', 'system_owner']),
      button: true,
      selector: 'actions',
    },
  ];

  return isLoading ? (
    <LoadingSpinner />
  ) : (
    <Card>
      <AttachmentFormModal
        isOpen={modalOpen}
        setOpen={setModalOpen}
        attachmentableType={attachmentableType}
        attachmentableId={attachmentableId}
        afterAction={afterAction}
        schoolDocuments={schoolDocuments}
      />
      <CardContent>
        <Grid container justify="space-between" spacing={3}>
          <Grid item>
            <Typography variant="h2" component="h2">
              {title || 'Attachments'}
            </Typography>
          </Grid>
          <Grid item>
            {currentUserHasAnyRole(['admin', 'system_owner']) && showAddAttachment && (
              <Button variant="contained" color="primary" onClick={() => setModalOpen(true)}>
                Add Attachment
              </Button>
            )}
          </Grid>
        </Grid>
        {description && <Typography>{description}</Typography>}
        <DataTable
          columns={columns}
          noHeader
          data={attachments?.filter((attachment) => attachment.archived === showArchived)}
          striped
          highlightOnHover
          pointerOnHover
        />
      </CardContent>
      {!schoolUser && (
        <CardActions>
          <Button size="small" onClick={() => setShowArchived(!showArchived)}>
            {showArchived ? 'Hide Archives' : 'Show Archives'}
          </Button>
        </CardActions>
      )}
    </Card>
  );
};

export default AttachmentsList;
