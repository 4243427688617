import React from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Card, CardContent, Grid, Typography } from '@material-ui/core';
import { User } from '../model';
import { userFormStore } from '../../shared/singletons';
import DeleteModal from '../../shared/components/modal/DeleteModal';

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
  },
  modalText: {
    fontSize: '20px',
    marginLeft: '20%',
    marginBottom: '20px',
    width: '60%',

    [theme.breakpoints.down('sm')]: {
      marginLeft: '2%',
      width: '96%',
    },
  },
}));

type UserActionsTabProps = {
  user: User;
};

const UserActionsTab: React.FC<UserActionsTabProps> = (props) => {
  const { user } = props;
  const history = useHistory();
  const classes = useStyles();

  const [isDestroyModalOpen, setDestroyModalOpen] = React.useState<boolean>(false);

  const handleDestroyFormSubmit = () => {
    userFormStore.deleteUser(user.id, null);
  };

  return (
    <>
      <Card>
        <CardContent>
          <Grid container justify="space-between">
            <Grid item>
              <Typography variant="h2" component="h2">
                Case Load Management
              </Typography>
              <Typography>Transfer active case load between two service providers.</Typography>
              <br />
              <Button
                variant="contained"
                color="primary"
                onClick={() => history.push(`/users/${user.id}/transfer_case_load`)}
              >
                Go to Case Load Transer Page
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Card style={{ marginTop: 30 }}>
        <CardContent>
          <Grid container justify="space-between">
            <Grid item>
              <Typography variant="h2" component="h2">
                Next Year Scheduling
              </Typography>
              <Typography>Bulk schedule appointments for the upcoming school year.</Typography>
              <br />
              <Button
                variant="contained"
                color="primary"
                onClick={() => history.push(`/users/${user.id}/duplicate_appointments`)}
              >
                Go to Scheduling Page
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <DeleteModal
        isOpen={isDestroyModalOpen}
        openStatusChanged={setDestroyModalOpen}
        onDelete={handleDestroyFormSubmit}
      >
        <Typography align="center" className={classes.modalText} variant="h5">
          Are you sure you want to delete this service? This action cannot be undone.
        </Typography>
      </DeleteModal>
      <Card style={{ marginTop: 30 }}>
        <CardContent>
          <Grid container justify="space-between">
            <Grid item>
              <Typography variant="h2" component="h2">
                Delete User
              </Typography>
              <Typography>
                Permanently delete service from the timeline site. This cannot be undone, and in most cases services
                should be discharged instead of deleted.
              </Typography>
              <br />
              <Button
                variant="contained"
                color="primary"
                style={{ backgroundColor: '#F44F64' }}
                onClick={() => setDestroyModalOpen(true)}
              >
                Delete User
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

export default UserActionsTab;
