/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect } from 'react';
import { Typography, Grid, Card, CardContent, Button, makeStyles, Checkbox } from '@material-ui/core';
import InformationField from '../../shared/components/InformationField';
import DeleteModal from '../../shared/components/modal/DeleteModal';
import { DischargeStudentContext } from '../../students/contexts/DischargeStudentContext';
import { StudentsContext } from '../../students/contexts/StudentsContext';
import { ServicesContext } from '../../services/contexts/ServicesContexts';
import TextInput from '../../shared/components/form/TextInput';
import { axios } from '../../shared/singletons';
import { Service, ServiceFormValues } from '../../services/model';
import SelectInput from '../../shared/components/form/SelectInput';
import LoadingSpinner from '../../shared/components/LoadingSpinner';
import { intervalChoices, frequencyCountUnitChoices } from '../../services/constants';
import { AnnualUpdatesContext } from '../contexts/AnnualUpdatesContext';
import { ServiceFormOptionsContext } from '../../services/contexts/ServiceFormOptionsContext';

type ServiceCardProps = {
  idx: number;
  serviceId: string;
};

const statusChoices = [
  { label: 'Active', value: 'active' },
  { label: 'Complete', value: 'complete' },
];

const useStyles = makeStyles((theme) => ({
  modalText: {
    fontSize: '20px',
    marginLeft: '20%',
    marginBottom: '20px',
    width: '60%',

    [theme.breakpoints.down('sm')]: {
      marginLeft: '2%',
      width: '96%',
    },
  },
  textInput: {
    height: '60px',
    width: '80%',
    margin: '10px 0',
  },
}));

const ServiceCard: React.FC<ServiceCardProps> = (props) => {
  const { idx, serviceId } = props;
  const [destroyModalOpen, setDestroyModalOpen] = useState(false);
  const { dischargeService } = useContext(DischargeStudentContext);
  const { setContinueDisabled } = useContext(AnnualUpdatesContext);
  const [service, setService] = useState<Service>();
  const { services, servicesLoading, servicesChecked, setServicesChecked } = useContext(ServicesContext);
  const { student } = useContext(StudentsContext);
  const [changesMade, setChangesMade] = useState(false);
  const { fetchChoices, phaseChoices } = useContext(ServiceFormOptionsContext);

  const [formValues, setFormValues] = React.useState<ServiceFormValues>({});
  const classes = useStyles();

  useEffect(() => {
    const updatedService = services.find((elem) => elem.id === serviceId);
    setService(updatedService);
    setFormValues(updatedService);
  }, [services]);

  useEffect(() => {
    const hasChanges = JSON.stringify(formValues) !== JSON.stringify(service);

    setChangesMade(hasChanges);
    setContinueDisabled(hasChanges);
  }, [formValues, service]);

  useEffect(() => {
    fetchChoices();
  }, []);

  const handleDischargeService = () => {
    dischargeService(service?.id);
    setDestroyModalOpen(false);
  };

  const updateService = () => {
    setChangesMade(false);
    setContinueDisabled(false);

    axios
      .put(`patients/${student.id}/referral_services/${service?.id}`, { referral_service: formValues })
      .then((response) => {
        setService(response?.data?.result);
      });
  };

  const handleCheckbox = () => {
    let updatedServices = [...servicesChecked];
    if (servicesChecked.includes(service?.id)) {
      updatedServices = updatedServices.filter((id) => {
        return id !== service?.id;
      });
    } else {
      updatedServices.push(service?.id);
    }

    setServicesChecked(updatedServices);
  };

  const openAppointmentsTab = () => {
    const win = window.open(`/students/${service?.student_id}/services/${serviceId}/appointments`, '_blank');
    win.focus();
  };

  return servicesLoading ? (
    <LoadingSpinner />
  ) : (
    <Card>
      <DeleteModal
        isOpen={destroyModalOpen}
        openStatusChanged={setDestroyModalOpen}
        onDelete={() => handleDischargeService()}
        confirmLabel="Confirm"
      >
        <Typography align="center" className={classes.modalText} variant="h5">
          Are you sure you want to discharge this service?
        </Typography>
      </DeleteModal>

      <CardContent>
        <Grid container justify="space-between">
          <Grid item>
            <Typography variant="h2" component="h2">
              <Checkbox
                name="select"
                checked={servicesChecked.includes(service?.id)}
                color="primary"
                onChange={handleCheckbox}
              />
              &nbsp;
              {`Service ${idx + 1} – ${service?.service_type}`}
            </Typography>
          </Grid>

          <Button
            variant="contained"
            color="primary"
            onClick={() => setDestroyModalOpen(true)}
            style={{ marginLeft: 7, backgroundColor: '#F44F64' }}
          >
            Discharge Service
          </Button>
        </Grid>
        <br />

        <Grid container spacing={3}>
          <Grid item xs={3}>
            <TextInput
              styleOverrides={classes.textInput}
              type="date"
              shrinkLabel
              label="Start Date"
              value={formValues?.start_date}
              valueChanged={(value) => setFormValues({ ...formValues, start_date: value })}
            />
          </Grid>
          <Grid item xs={3}>
            <TextInput
              styleOverrides={classes.textInput}
              type="date"
              shrinkLabel
              label="End Date"
              value={formValues?.end_date}
              valueChanged={(value) => setFormValues({ ...formValues, end_date: value })}
            />
          </Grid>

          <Grid item xs={2}>
            <InformationField label="Owner" value={service?.owner} />
          </Grid>

          <Grid item xs={2}>
            <SelectInput
              styleOverrides={classes.textInput}
              value={formValues?.status}
              label="Status"
              choices={statusChoices}
              valueChanged={(value: string) => setFormValues({ ...formValues, status: value })}
              type="text"
            />
          </Grid>
          <Grid item xs={2}>
            <SelectInput
              styleOverrides={classes.textInput}
              value={formValues?.phase}
              label="Phase"
              choices={phaseChoices}
              valueChanged={(value: string) => setFormValues({ ...formValues, phase: value })}
              type="text"
            />
          </Grid>
          <Grid item xs={8}>
            <TextInput
              styleOverrides={classes.textInput}
              type="text"
              label="Service Frequency"
              // errorMessage={formErrors?.frequency}
              value={formValues?.frequency}
              valueChanged={(value: string) => setFormValues({ ...formValues, frequency: value })}
            />
          </Grid>

          <Grid item xs={8}>
            <Grid container spacing={3}>
              <Grid item>
                <TextInput
                  styleOverrides={classes.textInput}
                  type="text"
                  label="Frequency Amount"
                  value={formValues?.frequency_count}
                  valueChanged={(value: string) => setFormValues({ ...formValues, frequency_count: value })}
                />
              </Grid>
              <Grid item style={{ minWidth: 225 }}>
                <SelectInput
                  styleOverrides={classes.textInput}
                  type="text"
                  label="Frequency Units"
                  choices={frequencyCountUnitChoices}
                  value={formValues?.frequency_count_units}
                  valueChanged={(value: string) => setFormValues({ ...formValues, frequency_count_units: value })}
                />
              </Grid>
              <Grid item style={{ margin: '30px 20px 0', alignItems: 'center' }}>
                <Typography style={{ textAlign: 'center' }}>Per</Typography>
              </Grid>
              <Grid item style={{ minWidth: 225 }}>
                <SelectInput
                  styleOverrides={classes.textInput}
                  type="text"
                  label="Frequency Interval"
                  choices={intervalChoices}
                  value={formValues?.frequency_interval}
                  valueChanged={(value: string) => setFormValues({ ...formValues, frequency_interval: value })}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={2}>
            <Typography variant="subtitle1" component="h6">
              Incomplete & Needs Rescheduled
            </Typography>
            <Typography variant="body1" component="p" style={{ whiteSpace: 'break-spaces', fontWeight: 600 }}>
              {service?.incomplete_appointments_count || 0} Appointments
            </Typography>
          </Grid>

          <Grid item xs={2}>
            <Typography variant="subtitle1" component="h6">
              Future Remaining
            </Typography>
            <Typography variant="body1" component="p" style={{ whiteSpace: 'break-spaces', fontWeight: 600 }}>
              {`${service?.future_appointments_count || 0} Appointments`}
            </Typography>
          </Grid>
        </Grid>

        <Grid container spacing={1}>
          <Grid style={{ marginTop: 30, display: 'flex', justifyContent: 'flex-start' }}>
            <Button variant="contained" color="primary" onClick={openAppointmentsTab}>
              View Appointments
            </Button>
            &emsp;
            <Button variant="contained" color="primary" disabled={!changesMade} onClick={updateService}>
              Update
            </Button>
            {changesMade && (
              <>
                &emsp;
                <p style={{ color: 'red' }}>Update to save your changes</p>
              </>
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ServiceCard;
