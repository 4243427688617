import React, { FunctionComponent } from 'react';
import ConfirmCancelModal from '../../shared/components/modal/ConfirmCancelModal';
import DuplicateAppointmentsForm from './DuplicateAppointmentsForm';
import { axios } from '../../shared/singletons';
import { DuplicateAppointmentsContext } from '../contexts/DuplicateAppointmentsContext';

interface DuplicateAppointmentsByWeekModalProps {
  afterAction: () => void;
}

const DuplicateAppointmentsByWeekModal: FunctionComponent<DuplicateAppointmentsByWeekModalProps> = () => {
  const [isCreating, setCreating] = React.useState<boolean>(false);

  const {
    duplicateAppointmentsByWeekModalOpen,
    setDuplicateAppointmentsByWeekModalOpen,
    formValues,
    fetchRecord,
    appointmentScheduler,
  } = React.useContext(DuplicateAppointmentsContext);

  const handleSubmit = async () => {
    setCreating(true);

    await axios.post<string, any>(
      `users/${appointmentScheduler.owner_id}/create_by_week.json?user_id=${appointmentScheduler.owner_id}`,
      {
        appointment: { ...formValues, owner_array: ['', appointmentScheduler.owner_id.toString()] },
        headers: { 'Content-Type': 'application/json' },
      },
    );

    await fetchRecord(appointmentScheduler.owner_id, appointmentScheduler.id);
    setCreating(false);
    setDuplicateAppointmentsByWeekModalOpen(false);
  };

  return (
    <ConfirmCancelModal
      isOpen={duplicateAppointmentsByWeekModalOpen}
      title="Duplicate Appointments By Week"
      confirmLabel="Submit"
      openStatusChanged={(isOpen) => setDuplicateAppointmentsByWeekModalOpen(isOpen)}
      onConfirm={handleSubmit}
      confirmDisabled={isCreating}
    >
      <DuplicateAppointmentsForm />
    </ConfirmCancelModal>
  );
};

export default DuplicateAppointmentsByWeekModal;
