/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from 'react';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import FullCalendar from '@fullcalendar/react';
import multiMonthPlugin from '@fullcalendar/multimonth';
import dayGridPlugin from '@fullcalendar/daygrid';
import { parse } from 'date-fns';
import { axios } from '../../shared/singletons';
import { AppointmentsContext } from '../contexts/AppointmentsContext';
import YearlongAppointmentEditModal from './YearlongAppointmentEditModal';
import YearlongAppointmentCreateModal from './YearlongAppointmentCreateModal';
import YearlongAppointmentsQuarterList from './YearlongAppointmentsQuarterList';
import ProrateSection from './ProrateSection';
import interactionPlugin from '@fullcalendar/interaction';
import { Service } from '../../services/model';
import { DuplicateAppointmentsContext } from '../../duplicateAppointments/contexts/DuplicateAppointmentsContext';

type YearlongAppointmentPreviewCalendarProps = {
  afterCreate?: () => void;
  includeProrateButton?: boolean;
};

function NewAppointmentPreviewCalendar(props: YearlongAppointmentPreviewCalendarProps) {
  const { afterCreate, includeProrateButton } = props;

  const [appointmentsForCalendarDisplay, setAppointmentsForCalendarDisplay] = useState([]);
  const [appointmentsFetched, setAppointmentsFetched] = useState(false);
  const [creatingAppointments, setCreatingAppointments] = useState(false);
  const [referralService, setReferralService] = useState<Service | null>(null);

  const {
    appointmentFormValues,
    setEditModalOpen,
    setCreateModalOpen,
    setCreateModalDate,
    appointmentYear,
    setAppointmentYear,
    setEditModalInfo,
    setFormShowing,
  } = useContext(AppointmentsContext);

  const { setYearlongGeneratorModalOpen } = useContext(DuplicateAppointmentsContext);

  const formatAppointmentsForFullCalendar = () => {
    const formattedAppointmentsForCalendarDisplay = [];

    appointmentYear?.forEach((quarter, indexX) => {
      quarter?.forEach((appointment, indexY) => {
        let allDay = true;
        let endTime = null;

        if (appointment.duration > 0) {
          allDay = false;
          endTime = parse(appointment.end, 'EEEE, MM-dd-yyyy hh:mm aa', new Date());
        }

        formattedAppointmentsForCalendarDisplay.push({
          title: `${appointment.title}`,
          start: parse(appointment.start, 'EEEE, MM-dd-yyyy hh:mm aa', new Date()),
          end: endTime,
          allDay: allDay,
          color: appointment.color || 'blue',
          extendedProps: {
            duration: appointment.duration,
            indexX: indexX,
            indexY: indexY,
          },
        });
      });
    });

    setAppointmentsForCalendarDisplay(formattedAppointmentsForCalendarDisplay);
  };

  useEffect(() => {
    formatAppointmentsForFullCalendar();
  }, [appointmentYear]);

  useEffect(() => {
    if (!appointmentsFetched) {
      setAppointmentsFetched(true);
      axios.post(`yearlong_appointment_generators/preview`, appointmentFormValues).then((result) => {
        setReferralService(result?.data?.referral_service);
        setAppointmentYear(result?.data?.appointment_year);
      });
    }
  }, [appointmentsFetched]);

  const handleEventClick = ({ event }) => {
    if (event?._def?.title.includes('min')) {
      setEditModalOpen(true);
      setEditModalInfo(event);
    }
  };

  const handleDateClick = ({ date }) => {
    setCreateModalDate(date);
    setCreateModalOpen(true);
  };

  const handleCreate = async () => {
    setCreatingAppointments(true);

    // First, update the referral_service
    await axios.put(`referral_services/${referralService?.id}`, { referral_service: referralService });

    // Then create the appointments
    axios
      .post(`yearlong_appointment_generators`, {
        appointmentYear,
        appointmentFormValues,
      })
      .then(() => {
        setYearlongGeneratorModalOpen(false);
        afterCreate();
      });
  };

  const frequencyDescription = `${referralService?.frequency_count} ${referralService?.frequency_count_units} per ${referralService?.frequency_interval}`;

  return (
    <Grid container>
      <YearlongAppointmentEditModal />
      <YearlongAppointmentCreateModal />
      <Grid item xs={6}>
        <p>
          <strong>Frequency: </strong>
          {frequencyDescription}
        </p>
      </Grid>
      <Grid item xs={8} style={{ margin: 'auto' }}>
        <FullCalendar
          plugins={[multiMonthPlugin, dayGridPlugin, interactionPlugin]}
          initialView="dayGridMonth"
          multiMonthMaxColumns={2}
          events={appointmentsForCalendarDisplay as any}
          headerToolbar={{
            left: 'prev,next today',
            center: 'title',
            right: 'dayGridMonth,multiMonthYear',
          }}
          eventClick={handleEventClick}
          dateClick={handleDateClick}
          height="400px"
        />
      </Grid>
      <br />
      <YearlongAppointmentsQuarterList />
      <br />
      <Grid item xs={12}>
        <Grid container>
          <Button color="primary" variant="contained" onClick={handleCreate} disabled={creatingAppointments}>
            Create These Appointments
          </Button>
          &emsp;
          <Button color="secondary" variant="contained" onClick={() => setFormShowing(true)}>
            Adjust Settings
          </Button>
        </Grid>
      </Grid>
      {includeProrateButton && (
        <Grid item xs={12}>
          <ProrateSection />
        </Grid>
      )}
    </Grid>
  );
}

export default NewAppointmentPreviewCalendar;
