import React, { useContext, useEffect, useState } from 'react';
import ConfirmCancelModal from '../../shared/components/modal/ConfirmCancelModal';
import { AppointmentsContext } from '../contexts/AppointmentsContext';
import TextInput from '../../shared/components/form/TextInput';
import { axios } from '../../shared/singletons';
import { useHistory } from 'react-router-dom';

interface EditCommentModalProps {
  onSuccess: () => void;
}

const EditCommentModal: React.FC<EditCommentModalProps> = (props) => {
  const { onSuccess } = props;

  const history = useHistory();
  const { editModalOpen, setEditModalOpen, commentToEdit } = useContext(AppointmentsContext);

  const [editedContent, setEditedContent] = useState('');

  const handleConfirm = () => {
    axios
      .put(`/appointments/nil/comments/${commentToEdit.id}`, { comment: { content: editedContent } })
      .then((response) => {
        if (onSuccess) {
          setEditModalOpen(false);
          onSuccess();
        } else {
          history.push('/appointments');
        }
      });
  };

  useEffect(() => {
    setEditedContent(commentToEdit?.content);
  }, [commentToEdit]);

  return (
    <ConfirmCancelModal
      title="Edit Appointment Comment"
      isOpen={editModalOpen}
      openStatusChanged={(isOpen) => setEditModalOpen(isOpen)}
      height={'300px'}
      onConfirm={handleConfirm}
    >
      <TextInput label="Comment" value={editedContent} valueChanged={(value: string) => setEditedContent(value)} />
    </ConfirmCancelModal>
  );
};

export default EditCommentModal;
