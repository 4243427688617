import React, { FunctionComponent } from 'react';
import Helmet from 'react-helmet';
import { Box, Grid, Typography, makeStyles } from '@material-ui/core';
import 'react-day-picker/lib/style.css';
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import SchoolClosingModal from './SchoolClosingModal';
import SelectInput from '../../shared/components/form/SelectInput';
import { School } from '../../schools/model';
import DeleteModal from '../../shared/components/modal/DeleteModal';
import Breadcrumbs, { BreadcrumbLink } from '../../shared/components/Breadcrumbs';
import { SchoolClosingContext } from '../contexts/SchoolClosingsContext';
import { SchoolClosingFormValues } from '../model';

interface NewSchoolClosingProps {
  school: School;
}

const useStyles = makeStyles((theme) => ({
  listItemIcon: {
    minWidth: '24px',
  },
  textInput: {
    height: '60px',
    width: '300px',
    margin: '10px',
  },
  formTitle: {
    marginLeft: '12px',
  },
  modalText: {
    fontSize: '20px',
    marginLeft: '20%',
    marginBottom: '20px',
    width: '60%',

    [theme.breakpoints.down('sm')]: {
      marginLeft: '2%',
      width: '96%',
    },
  },
}));

const NewSchoolClosing: FunctionComponent<NewSchoolClosingProps> = (props) => {
  const { school } = props;
  const classes = useStyles();

  const { deleteSchoolClosing } = React.useContext(SchoolClosingContext);

  const [destroyModalOpen, setDestroyModalOpen] = React.useState(false);
  const [deletedDate, setDeletedDate] = React.useState('');
  const [formValues, setFormValues] = React.useState<SchoolClosingFormValues>({});
  const [formModalOpen, setFormModalOpen] = React.useState<boolean>(false);

  const links: BreadcrumbLink[] = [
    {
      href: '/schools',
      text: 'SCHOOLS',
    },
    {
      href: `/schools/${school.id}`,
      text: school.name,
    },
  ];

  const formatSchoolClosings = (category = 'all'): Date[] => {
    const formattedSchoolClosings = [];
    let schoolClosings = school?.blackout_dates;

    if (category !== 'all') {
      schoolClosings = schoolClosings?.filter((closing) => closing.category === category);
    }

    schoolClosings?.map((blackout_date) => {
      if (blackout_date.end_date === null || blackout_date?.start_date === blackout_date?.end_date) {
        formattedSchoolClosings.push(new Date(blackout_date.start_date));
      } else {
        formattedSchoolClosings.push({
          after: new Date(
            new Date(blackout_date.start_date).getFullYear(),
            new Date(blackout_date.start_date).getMonth(),
            new Date(blackout_date.start_date).getDate() - 1,
          ),
          before: new Date(
            new Date(blackout_date.end_date).getFullYear(),
            new Date(blackout_date.end_date).getMonth(),
            new Date(blackout_date.end_date).getDate() + 1,
          ),
        });
      }
    });

    return formattedSchoolClosings;
  };

  const handleDayClick = (day: Date, modifiers: any) => {
    if (modifiers.selected) {
      setDestroyModalOpen(true);
      setDeletedDate(day.toISOString().slice(0, 10));
    } else {
      setFormModalOpen(true);
      setFormValues({
        payer_id: school.id,
        start_date: day.toISOString().slice(0, 10),
        end_date: day.toISOString().slice(0, 10),
      });
    }
  };

  const handleDestroySingleAppointment = () => {
    deleteSchoolClosing(deletedDate, school.id, () => setDestroyModalOpen(false));
  };

  return (
    <Box>
      <Helmet>
        <style>
          {`
          .DayPicker-Day {
            border-radius: 0;
            padding: 40px;
          }
          .DayPicker-Day--selected {
            background-color: #333 !important;
          }
          .DayPicker-Day--school-closure {
            background-color: #333 !important;
          }
          .DayPicker-Day--inservice {
            background-color: #333 !important;
          }
          `}
        </style>
      </Helmet>
      <SchoolClosingModal
        formValues={formValues}
        setFormValues={setFormValues}
        formModalOpen={formModalOpen}
        setFormModalOpen={setFormModalOpen}
      />
      <DeleteModal
        isOpen={destroyModalOpen}
        openStatusChanged={setDestroyModalOpen}
        onDelete={handleDestroySingleAppointment}
      >
        <Typography align="center" className={classes.modalText} variant="h5">
          Are you sure you want to delete this school closing?
        </Typography>
      </DeleteModal>
      <Grid container spacing={3}>
        <Grid item>
          <Typography variant="h1" component="h1">
            {school.name}
          </Typography>
          <Breadcrumbs links={links} />
          <br />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h2" component="h2">
            School Closings and Inservices
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <SelectInput
            styleOverrides={classes.textInput}
            type="text"
            label="Category"
            choices={[
              { label: 'All', value: 'all' },
              { label: 'Inservice', value: 'inservice' },
              { label: 'School Closure', value: 'school_closure' },
            ]}
            value={formValues?.category || 'all'}
            valueChanged={(value: string) => {
              setFormValues({ ...formValues, category: value });
            }}
          />
        </Grid>
      </Grid>

      <DayPicker
        key={formValues?.category}
        showOutsideDays
        initialMonth={new Date()}
        selectedDays={formatSchoolClosings(formValues?.category)}
        onDayClick={handleDayClick}
        modifiers={{
          inservice: (day: Date) => false,
          school_closure: (day: Date) => false,
        }}
      />
    </Box>
  );
};

export default NewSchoolClosing;
