import React, { Dispatch, SetStateAction } from 'react';

import Button from '@material-ui/core/Button';

import { Invoice } from '../model';

import axios from '../../shared/utils/axios.utils';

interface ResetCheckmarkButtonProps {
  invoice: Invoice;
  setInvoice: Dispatch<SetStateAction<Invoice>>;
  stepIndex: number;
}

const ResetCheckmarkButton: React.FC<ResetCheckmarkButtonProps> = (props) => {
  let { invoice, setInvoice } = props;

  let handleResetCheckmark = async () => {
    let response = await axios.put(`/invoices/invoices/${invoice?.id}`, {
      step_index: props.stepIndex,
      reset: 'true',
    });
    setInvoice(response.data.result);
  };

  return (
    <Button
      variant="contained"
      color="secondary"
      onClick={handleResetCheckmark}
      disabled={!invoice?.completed_steps?.includes(props.stepIndex.toString())}
    >
      Reset Checkmark
    </Button>
  );
};

export default ResetCheckmarkButton;
