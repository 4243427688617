import React, { Dispatch, SetStateAction } from 'react';
import DeleteModal from '../../shared/components/modal/DeleteModal';
import { Typography, Grid } from '@material-ui/core';
import InformationField from '../../shared/components/InformationField';
import { Location } from '../model';
import axios from '../../shared/utils/axios.utils';

// Try deleting a location that has students

type DeleteLocationModalProps = {
  deleteLocationModalOpen: boolean;
  setDeleteLocationModalOpen: Dispatch<SetStateAction<boolean>>;
  locationToEdit: Location;
};

const DeleteLocationModal: React.FC<DeleteLocationModalProps> = (props) => {
  const { deleteLocationModalOpen, setDeleteLocationModalOpen, locationToEdit } = props;

  const deleteLocation = (location: Location): void => {
    axios({
      method: 'delete',
      url: `locations/${location.id}.json`,
      headers: { 'Content-Type': 'application/json' },
    })
      .then(() => {
        window.location.reload();
      })
      .catch(() => {
        // Consider enforcing that locations cannot be deleted if they have students
      });
  };

  return (
    <DeleteModal
      isOpen={deleteLocationModalOpen}
      title="Delete Location"
      onDelete={() => deleteLocation(locationToEdit)}
      confirmLabel="Delete"
      openStatusChanged={(isOpen) => setDeleteLocationModalOpen(isOpen)}
    >
      <>
        <Typography
          align="center"
          style={{
            fontSize: '20px',
            marginLeft: '20%',
            marginBottom: '20px',
            width: '60%',
          }}
          variant="h5"
        >
          Delete this Location?
        </Typography>
        <Grid style={{ display: 'flex', justifyContent: 'space-around' }}>
          <InformationField label="" value={locationToEdit?.name} />
        </Grid>
      </>
    </DeleteModal>
  );
};

export default DeleteLocationModal;
