import React, { Dispatch, SetStateAction, useContext } from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import ConfirmCancelModal from '../../shared/components/modal/ConfirmCancelModal';

import { Invoice } from '../model';

import { UserContext } from '../../auth/contexts/userContext';
import InformationField from '../../shared/components/InformationField';
import axios from '../../shared/utils/axios.utils';

interface FinalizeInvoiceModalProps {
  invoiceForModal: Invoice;
  finalizeModalOpen: boolean;
  setFinalizeModalOpen: Dispatch<SetStateAction<boolean>>;
  fetchInvoices: () => void;
}

const FinalizeInvoiceModal: React.FC<FinalizeInvoiceModalProps> = (props) => {
  let { invoiceForModal, finalizeModalOpen, setFinalizeModalOpen, fetchInvoices } = props;

  let { user } = useContext(UserContext);

  let handleFinalizeInvoice = async () => {
    await axios.put(`/invoices/invoices/${invoiceForModal.id}`, {
      invoice: { status: 'Finalized', finalized_by_id: user?.id, finalized_date: new Date() },
    });
    fetchInvoices();
    setFinalizeModalOpen(false);
  };

  return (
    <ConfirmCancelModal
      confirmLabel="Finalize"
      onConfirm={handleFinalizeInvoice}
      openStatusChanged={(isOpen) => setFinalizeModalOpen(isOpen)}
      title="Finalize Invoice"
      isOpen={finalizeModalOpen}
    >
      <Grid container>
        <Grid item xs={12} style={{ marginBottom: 25 }}>
          <Grid container spacing={4}>
            <Grid item>
              <InformationField label="Title" value={invoiceForModal?.title} />
            </Grid>
            <Grid item>
              <InformationField label="School" value={invoiceForModal?.school_name} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} style={{ marginBottom: 25 }}>
          <Typography variant="h3">Setting this Invoice to 'Finalized' or 'Hold'</Typography>
          <Typography variant="h3">This invoice cannot be reverted to 'Under Review'</Typography>
        </Grid>
        <Grid item xs={12} style={{ marginBottom: 25 }}>
          <Typography variant="h2" style={{ fontWeight: 'bold' }}>
            Finalized By:
          </Typography>
          <Typography variant="h3" style={{ fontWeight: 400 }}>
            {user?.username}
          </Typography>
        </Grid>
        <Grid item xs={12} style={{ marginBottom: 25 }}>
          <Typography variant="h2" style={{ fontWeight: 'bold' }}>
            Finalized On:
          </Typography>
          <Typography variant="h3" style={{ fontWeight: 400 }}>
            {new Date().toLocaleDateString()}
          </Typography>
        </Grid>
      </Grid>
    </ConfirmCancelModal>
  );
};

export default FinalizeInvoiceModal;
