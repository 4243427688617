import React, { FunctionComponent, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutlineRounded';
import { Box, MenuItem, Typography } from '@material-ui/core';
import CustomMaterialMenu from '../../../shared/components/CustomMaterialMenu';
import ConfirmCancelModal from '../../../shared/components/modal/ConfirmCancelModal';
import { userRoleStore } from '../../../shared/singletons';
import { Colors } from '../../../shared/style/colors';
import { UserRole } from '../model';

interface UserRoleRowMenuProps {
  userRole: UserRole;
}

const useStyles = makeStyles((theme) => ({
  errorIcon: {
    color: Colors.Red,
    fontSize: '66px',
    marginBottom: '20px',
  },
  modalText: {
    fontSize: '20px',
    marginLeft: '20%',
    marginBottom: '20px',
    width: '60%',

    [theme.breakpoints.down('sm')]: {
      marginLeft: '2%',
      width: '96%',
    },
  },
}));

const UserRoleRowMenu: FunctionComponent<UserRoleRowMenuProps> = (props: UserRoleRowMenuProps) => {
  const { userRole } = props;
  const [isDestroyModalOpen, setDestroyModalOpen] = useState<boolean>(false);
  const classes = useStyles();

  const handleDestroyFormSubmit = () => {
    userRoleStore.deleteUserRole(userRole, () => setDestroyModalOpen(false));
  };

  return (
    <CustomMaterialMenu size="small">
      <ConfirmCancelModal
        isOpen={isDestroyModalOpen}
        openStatusChanged={setDestroyModalOpen}
        cancelLabel="Cancel"
        confirmLabel="Delete"
        width="768px"
        height="350px"
        onConfirm={handleDestroyFormSubmit}
        onCancel={() => setDestroyModalOpen(false)}
        hasTitleBar={false}
        hasFooterBar={false}
        hasCancelButton
        hasConfirmButton
      >
        <Box>
          <ErrorOutlineIcon className={classes.errorIcon} />
          <Typography align="center" className={classes.modalText} variant="h5">
            Are you sure you want to delete this user role? This action cannot be undone.
          </Typography>
        </Box>
      </ConfirmCancelModal>
      <MenuItem onClick={() => userRoleStore.setEditModalOpen(true, userRole)}>Edit User Role</MenuItem>
      <MenuItem onClick={() => setDestroyModalOpen(true)}>Destroy User Role</MenuItem>
    </CustomMaterialMenu>
  );
};

export default UserRoleRowMenu;
