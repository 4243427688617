/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from 'react';
import { Box, Card, CardContent, Grid, Typography } from '@material-ui/core';
import DataTable from '../../shared/components/DataTable';
import { DashboardContext } from '../contexts/DashboardContext';
import { UserContext } from '../../auth/contexts/userContext';

const columns: unknown[] = [
  {
    name: 'Name',
    selector: 'name',
    sortable: true,
  },
  {
    name: 'Date',
    selector: 'date',
    sortable: true,
  },
  {
    name: 'Units',
    selector: 'actual_number_of_minutes',
    sortable: true,
  },
  {
    name: 'Expected Units',
    selector: 'expected_number_of_minutes',
    sortable: true,
  },
];

const ProductivityAlertsTable: React.FunctionComponent = () => {
  const { productivityAlerts, productivityAlertsLoading, fetchProductivityAlerts } = React.useContext(DashboardContext);
  const { user } = useContext(UserContext);
  const [productivityAlertsFetched, setProductivityAlertsFetched] = React.useState(false);

  React.useEffect(() => {
    if (!productivityAlertsFetched) {
      fetchProductivityAlerts(user?.id);
      setProductivityAlertsFetched(true);
    }
  }, [productivityAlertsFetched]);

  return (
    <Box>
      <br />
      <Grid justify="space-between" container spacing={3}>
        <Grid item>
          <Typography variant="h1" component="h1">
            Productivity Alerts
          </Typography>
        </Grid>
      </Grid>
      <Card>
        <CardContent>
          <DataTable
            title=""
            columns={columns}
            noHeader
            data={productivityAlerts}
            progressPending={productivityAlertsLoading}
            striped
            highlightOnHover
            pointerOnHover
            pagination
          />
        </CardContent>
      </Card>
    </Box>
  );
};

export default ProductivityAlertsTable;
