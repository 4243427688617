import React, { FunctionComponent, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ServiceDetail from '../components/ServiceDetail';
import { useStoreObservable } from '../../shared/state/useStoreObservable.hook';
import { serviceRecordStore } from '../../shared/singletons';
import { ServiceRouteParams } from '../model';
import { ServiceFormOptionsContext } from '../../services/contexts/ServiceFormOptionsContext';

export type ServiceDetailPageProps = Record<string, unknown>;

const ServiceDetailPage: FunctionComponent<ServiceDetailPageProps> = () => {
  const serviceState = useStoreObservable(serviceRecordStore);
  const { service, serviceLoading } = serviceState;
  const { studentId, serviceId, tab } = useParams<ServiceRouteParams>();
  const { fetchChoices } = React.useContext(ServiceFormOptionsContext);

  /* eslint-disable */
  useEffect(() => {
    fetchChoices();
    serviceRecordStore.fetchRecord(studentId, serviceId);
  }, [studentId, serviceId]);
  /* eslint-enable */

  return <ServiceDetail service={service} tab={tab} serviceLoading={serviceLoading} />;
};

export default ServiceDetailPage;
