import React, { useState, Dispatch, SetStateAction, useEffect } from 'react';

import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import LoadingSpinner from '../../shared/components/LoadingSpinner';
import ResetCheckmarkButton from './ResetCheckmarkButton';

import DataTable from '../../shared/components/DataTable';

import { Invoice } from '../model';
import { Service } from '../../services/model';

import { axios } from '../../shared/singletons';

interface ServicesToIncludeProps {
  invoice: Invoice;
  setInvoice: Dispatch<SetStateAction<Invoice>>;
  setActiveStep: Dispatch<SetStateAction<number>>;
  stepIndex: number;
}

const ServicesToInclude: React.FC<ServicesToIncludeProps> = (props) => {
  let { setActiveStep, invoice, setInvoice, stepIndex } = props;

  // Default this to checked
  let [selectedServices, setSelectedServices] = useState<string[]>(['1', '2', '3']);
  let [potentialServices, setPotentialServices] = useState<Service[]>([]);
  let [potentialServicesLoaded, setPotentialServicesLoaded] = useState(false);

  let fetchPotentialServices = async () => {
    let response = await axios.get(`/invoices/invoices/${invoice?.id}/potential_services`);
    setPotentialServices(response.data.potential_services);
    setSelectedServices(response.data.included_services);
    setPotentialServicesLoaded(true);
  };

  useEffect(() => {
    if (invoice?.id) fetchPotentialServices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoice]);

  const handleIncludeServiceCheckbox = (serviceId: string) => {
    if (selectedServices.includes(serviceId)) {
      setSelectedServices(selectedServices.filter((id) => id !== serviceId));
    } else {
      setSelectedServices([...selectedServices, serviceId]);
    }
  };

  const handleContinue = async () => {
    let response = await axios.put(`/invoices/invoices/${invoice?.id}/services`, {
      service_ids: selectedServices,
      step_index: stepIndex,
    });
    setInvoice(response.data.result);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const servicesToIncludeColumns = [
    {
      name: 'Include',
      selector: 'id',
      sortable: false,
      cell: (row) => (
        <Checkbox onChange={() => handleIncludeServiceCheckbox(row.id)} checked={selectedServices.includes(row?.id)} />
      ),
      grow: 0.5,
    },
    {
      name: 'Name',
      selector: 'name',
      sortable: true,
      grow: 1,
    },
    {
      name: 'Num of Appointments',
      selector: 'appointment_count',
      sortable: true,
      grow: 0.5,
    },
  ];

  return potentialServicesLoaded ? (
    <Grid container>
      <Grid item xs={12} style={{ padding: 7 }}>
        {potentialServices?.length > 0 ? (
          <DataTable
            columns={servicesToIncludeColumns}
            noHeader
            data={potentialServices}
            progressPending={false}
            striped
            highlightOnHover
            pointerOnHover
            pagination
            paginationServer
            paginationPerPage={25}
            paginationRowsPerPageOptions={[25]}
          />
        ) : (
          <Typography variant="h3">No appointments on this invoice yet</Typography>
        )}
        <Grid item xs={12} style={{ marginTop: 15 }}>
          <Button variant="contained" color="primary" onClick={handleContinue} style={{ marginRight: 10 }}>
            Continue
          </Button>
          <ResetCheckmarkButton invoice={invoice} setInvoice={setInvoice} stepIndex={stepIndex} />
        </Grid>
      </Grid>
    </Grid>
  ) : (
    <LoadingSpinner />
  );
};

export default ServicesToInclude;
