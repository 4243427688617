import React, { FunctionComponent, Dispatch, SetStateAction } from 'react';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import { Accordion, AccordionSummary, AccordionDetails, Box, Button } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandedSessionNoteList from '../../sessionNotes/components/ExpandedSessionNoteList';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import InformationField from '../../shared/components/InformationField';
import TextInput from '../../shared/components/form/TextInput';
import SelectInput from '../../shared/components/form/SelectInput';
import { useStoreObservable } from '../../shared/state/useStoreObservable.hook';
import { SessionNoteState } from '../../sessionNotes/sessionNote.store';
import { sessionNoteStore } from '../../shared/singletons';
import { Goal } from '../../goals/model';
import { Appointment } from '../../appointments/model';
import { SelectOption } from '../../shared/common.model';
import { SessionNoteFormValue } from '../../sessionNotes/model';
import SubjectiveSessionNotes from '../../sessionNotes/components/SubjectiveSessionNotes';
import { transitionChoices, participationAttentionChoices } from '../../sessionNoteForms/constants/sessionNotes';

interface DirectServiceFormProps {
  appointment: Appointment;
  noteFormValues: SessionNoteFormValue[];
  setNoteFormValues?: Dispatch<SetStateAction<SessionNoteFormValue[]>>;
  goalRefs: HTMLDivElement[];
  expandedGoals: Record<string, boolean>;
  setExpandedGoals: Dispatch<SetStateAction<Record<string, boolean>>>;
}

const useStyles = makeStyles({
  textInput: {
    height: 'auto',
    width: '80%',
    margin: '10px 0',
  },
  baseError: {
    marginBottom: '10px',
  },
  link: {
    textDecoration: 'none',
  },
});

const DirectServiceForm: FunctionComponent<DirectServiceFormProps> = (props) => {
  const { appointment, noteFormValues, setNoteFormValues, goalRefs, expandedGoals, setExpandedGoals } = props;
  const classes = useStyles();
  const [noteHistory, setNoteHistory] = React.useState('0');

  const { errorValues, prompts, assistanceChoices } = useStoreObservable<SessionNoteState>(sessionNoteStore);

  const sessionNoteFormValues = (goalId): SessionNoteFormValue => {
    return noteFormValues?.find((session_note) => session_note.goal_id === goalId);
  };

  const handleHistoryToggle = (goalId: string) => {
    if (goalId !== 'subjective') {
      sessionNoteStore.fetchSessionNotes(goalId);
    }

    if (noteHistory === goalId) {
      setNoteHistory('0');
    } else {
      setNoteHistory(goalId);
    }
  };

  const handleNotAddressed = (goalId: string, checked) => {
    const formValues = noteFormValues.find((val) => val.goal_id === goalId) || {
      percent_string: '',
      assistance: '',
      prompts: '',
      content: '',
      participation: '',
      transitions: '',
      subjective_comment: '',
      who: '',
      method: '',
      outcome: '',
      goal_id: goalId,
    };

    if (checked) {
      setNoteFormValues([
        { ...formValues, assistance: 'Not Addressed', percent_string: 'Not Addressed', prompts: 'Not Addressed' },
        ...noteFormValues.filter((x) => x.goal_id !== goalId),
      ]);
    } else {
      setNoteFormValues([
        { ...formValues, assistance: '', percent_string: '', prompts: '' },
        ...noteFormValues.filter((x) => x.goal_id !== goalId),
      ]);
    }
  };

  const handleFormValueChange = (goalId: string, key: string, value: string) => {
    const obj: SessionNoteFormValue = noteFormValues.find((val) => val.goal_id === goalId) || {
      percent_string: '',
      assistance: '',
      prompts: '',
      content: '',
      participation: '',
      transitions: '',
      subjective_comment: '',
      who: '',
      method: '',
      outcome: '',
      goal_id: goalId,
    };

    if (key === 'prompts') {
      const currentPrompts = obj.prompts.split(',');

      if (currentPrompts.find((x) => x === value)) {
        obj[key] = currentPrompts.filter((p) => p !== value).toString();
      } else {
        obj[key] = obj[key].length > 0 ? `${obj[key]},${value}` : value;
      }
    } else {
      obj[key] = value;
    }

    setNoteFormValues([obj, ...noteFormValues.filter((x) => x.goal_id !== obj.goal_id)]);
  };

  return (
    appointment?.active_goals && (
      <>
        {appointment?.active_goals?.map((goal: Goal, index: number) => (
          <Grid
            item
            xs={12}
            key={goal.id}
            ref={(ref) => {
              goalRefs[index] = ref;
            }}
          >
            <Accordion
              expanded={expandedGoals[index] || false}
              onChange={() => setExpandedGoals({ ...expandedGoals, [index]: !expandedGoals[index] })}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                <Typography variant="h2" component="h2">
                  {`Service Goal ${index + 1}`}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container>
                  <Grid item xs={12}>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <Grid container spacing={1}>
                          <Grid item xs={12}>
                            <InformationField label="Objective" value={goal.objectives} />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <hr />
                      </Grid>
                      <Grid item xs={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name={'Not Addressed'}
                              value={'Not Addressed'}
                              color="primary"
                              onChange={(event) => handleNotAddressed(goal.id, event.target.checked)}
                            />
                          }
                          label={'Not Addressed'}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <form>
                          <Grid container spacing={3}>
                            <Grid item xs={12}>
                              <TextInput
                                value={sessionNoteFormValues(goal.id)?.percent_string}
                                styleOverrides={classes.textInput}
                                label="Data Collected"
                                shrinkLabel
                                errorMessage={errorValues?.find((error) => error.goal_id === goal.id)?.percent_string}
                                valueChanged={(value: string) =>
                                  handleFormValueChange(goal.id, 'percent_string', value)
                                }
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <SelectInput
                                value={sessionNoteFormValues(goal.id)?.assistance}
                                styleOverrides={classes.textInput}
                                choices={assistanceChoices}
                                label="Assistance"
                                errorMessage={errorValues?.find((error) => error.goal_id === goal.id)?.assistance}
                                valueChanged={(value: string) => handleFormValueChange(goal.id, 'assistance', value)}
                              />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <Grid container spacing={1}>
                                <Grid item xs={12}>
                                  <Typography variant="h3" component="h3">
                                    Prompts Utilized
                                  </Typography>
                                  {errorValues?.find((error) => error.goal_id === goal.id)?.prompts?.length > 0 && (
                                    <p style={{ color: '#F44F64' }}>
                                      Prompts utilized{' '}
                                      {errorValues?.find((error) => error.goal_id === goal.id)?.prompts}
                                    </p>
                                  )}
                                </Grid>
                                <Grid item xs={12}>
                                  <FormGroup row>
                                    <Grid container>
                                      {prompts.map((prompt: SelectOption) => (
                                        <Grid key={prompt.label} item xs={12} md={6}>
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                name={prompt.label}
                                                value={prompt.value}
                                                color="primary"
                                                onChange={() => handleFormValueChange(goal.id, 'prompts', prompt.value)}
                                              />
                                            }
                                            label={prompt.value}
                                            checked={sessionNoteFormValues(goal.id)?.prompts?.includes(prompt.value)}
                                          />
                                        </Grid>
                                      ))}
                                    </Grid>
                                  </FormGroup>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12}>
                              <TextInput
                                value={sessionNoteFormValues(goal.id)?.content}
                                styleOverrides={classes.textInput}
                                shrinkLabel
                                label="Note Content (Optional)"
                                multiline
                                rows={8}
                                errorMessage={errorValues?.find((error) => error.goal_id === goal.id)?.content}
                                valueChanged={(value: string) => handleFormValueChange(goal.id, 'content', value)}
                              />
                            </Grid>
                          </Grid>
                        </form>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Button variant="contained" color="primary" onClick={() => handleHistoryToggle(goal.id)}>
                      Toggle Note History
                    </Button>
                    <Box display={noteHistory === goal.id ? 'block' : 'none'}>
                      <ExpandedSessionNoteList goalId={goal.id} />
                    </Box>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
            <br />
          </Grid>
        ))}
        <Grid item xs={12}>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
              <Typography variant="h2" component="h2">
                Subjective
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <form>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <SelectInput
                          value={sessionNoteFormValues(null)?.participation}
                          styleOverrides={classes.textInput}
                          choices={participationAttentionChoices}
                          label="Participation Attention"
                          errorMessage={errorValues?.find((error) => error.goal_id === 'subjective')?.participation}
                          valueChanged={(value: string) => handleFormValueChange(null, 'participation', value)}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <SelectInput
                          value={sessionNoteFormValues(null)?.transitions}
                          styleOverrides={classes.textInput}
                          choices={transitionChoices}
                          label="Transitions"
                          errorMessage={errorValues?.find((error) => error.goal_id === 'subjective')?.transitions}
                          valueChanged={(value: string) => handleFormValueChange(null, 'transitions', value)}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextInput
                          value={sessionNoteFormValues(null)?.subjective_comment}
                          styleOverrides={classes.textInput}
                          shrinkLabel
                          label="Other Subjective (Optional)"
                          multiline
                          rows={8}
                          errorMessage={
                            errorValues?.find((error) => error.goal_id === 'subjective')?.subjective_comment
                          }
                          valueChanged={(value: string) => handleFormValueChange(null, 'subjective_comment', value)}
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Button variant="contained" color="primary" onClick={() => handleHistoryToggle('subjective')}>
                        Toggle Note History
                      </Button>
                      <Box display={noteHistory === 'subjective' ? 'block' : 'none'}>
                        <SubjectiveSessionNotes
                          title="Subjective Note History"
                          subjectiveNotes={appointment?.service?.subjective_notes}
                        />
                      </Box>
                    </Grid>
                  </form>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </>
    )
  );
};

export default DirectServiceForm;
