import React from 'react';
import { User } from '../model';
import { axios } from '../../shared/singletons';

interface CaseLoadManagementContextInterface {
  fetchUser?: (userId: string) => Promise<void>;
  user?: User;
  userLoading?: boolean;

  fetchActiveStudents?: (userId: string, payerId: string) => Promise<void>;
  activeStudents?: Record<string, string>[];

  addOwnerToServices?: (
    userId: string,
    serviceIds: string[],
    newOwnerId: string,
    onSuccess: () => void,
  ) => Promise<void>;
  ownerSubmitting?: boolean;
}

const CaseLoadManagementContext = React.createContext<CaseLoadManagementContextInterface>({});
const CaseLoadManagementContextConsumer = CaseLoadManagementContext.Consumer;

const CaseLoadManagementContextProvider: React.FC = ({ children }) => {
  const [user, setUser] = React.useState<User>({});
  const [activeStudents, setActiveStudents] = React.useState<Record<string, string>[]>([]);
  const [userLoading, setUserLoading] = React.useState(false);
  const [ownerSubmitting, setOwnerSubmitting] = React.useState(false);

  const fetchUser = async (userId: string) => {
    setUserLoading(true);

    await axios(`case_load_management/${userId}`)
      .then((result) => result?.data?.result ?? [])
      .then((data) => {
        setUserLoading(false);
        setUser(data);
      })
      .catch(() => {
        setUserLoading(false);
      });
  };

  const fetchActiveStudents = async (userId: string, payerId: string) => {
    setUserLoading(true);

    await axios(`case_load_management/${userId}/transfer_students?payer_id=${payerId}`)
      .then((result) => result?.data?.result ?? [])
      .then((data) => {
        setUserLoading(false);
        setActiveStudents(data.active_students);
      })
      .catch(() => {
        setUserLoading(false);
      });
  };

  const addOwnerToServices = async (
    userId: string,
    serviceIds: string[],
    newOwnerId: string,
    onSuccess: () => void,
  ) => {
    setOwnerSubmitting(true);

    await axios
      .post(`/case_load_management/${userId}/transfer_students`, {
        user_id: newOwnerId,
        service_ids: serviceIds,
        headers: { 'Content-Type': 'application/json' },
      })
      .then((result) => result?.data?.result ?? [])
      .then((data) => {
        if (onSuccess) {
          onSuccess();
        } else {
          setActiveStudents(data.active_students);
        }
        setOwnerSubmitting(false);
      })
      .catch(() => {
        setOwnerSubmitting(true);
      });
  };

  return (
    <CaseLoadManagementContext.Provider
      value={{
        user,
        fetchUser,
        userLoading,

        activeStudents,
        fetchActiveStudents,

        addOwnerToServices,
        ownerSubmitting,
      }}
    >
      {children}
    </CaseLoadManagementContext.Provider>
  );
};

export { CaseLoadManagementContextProvider, CaseLoadManagementContextConsumer, CaseLoadManagementContext };
