import React, { useState, createContext, useEffect } from 'react';
import axios from '../../shared/utils/axios.utils';

const UserContext = createContext({
  user: undefined,
  setUser: undefined,
  login: undefined,
  loginFormError: undefined,
  setLoginError: undefined,
  logout: undefined,
  currentUserHasRole: undefined,
  currentUserHasAnyRole: undefined,
});

const UserContextConsumer = UserContext.Consumer;
const UserContextProvider = ({ children }) => {
  const [user, setUser] = useState(undefined);
  const [loginFormError, setLoginError] = useState(undefined);

  const login = async (username: string, password: string) => {
    try {
      await axios.post(`sessions`, { username, password }).then((response) => {
        if (response.data.error) {
          setLoginError(response.data.error);
        } else {
          localStorage.setItem('token', response.data.jwt);
          setUser(response.data.user);
        }
      });
    } catch {
      setUser({});
      setLoginError('Failed to connect to server. Please check your internet connection or contact your supervisor.');
    }
  };

  const logout = async () => {
    try {
      localStorage.setItem('token', '');
      setUser({});
    } catch {
      setUser({});
    }
  };

  const currentUserHasRole = (role: string) => {
    if (user?.roles?.find((user_role: string) => user_role === role)) {
      return true;
    } else {
      return false;
    }
  };

  const currentUserHasAnyRole = (roles: string[]) => {
    return roles.some((role) => currentUserHasRole(role));
  };

  useEffect(() => {
    async function fetchUser() {
      try {
        const result = await axios.get(`sessions`);
        setUser(result.data.user || {});
      } catch (error) {
        setUser({});
      }
    }

    if (user === undefined) {
      fetchUser();
    }
  }, [user]);

  return (
    <UserContext.Provider
      value={{ user, setUser, login, logout, currentUserHasRole, currentUserHasAnyRole, loginFormError, setLoginError }}
    >
      {children}
    </UserContext.Provider>
  );
};
export { UserContextProvider, UserContextConsumer, UserContext };
