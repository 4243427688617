import React, { createContext, useState } from 'react';
import { axios, alertStore } from '../../shared/singletons';
import { SchoolFormValues, SchoolResponse } from '../model';
import { schoolRecordStore } from '../../shared/singletons';

const SchoolsContext = createContext({
  updateSchool: undefined,
  fetchSchool: undefined,
  school: undefined,
  setSchool: undefined,
  esySchedulingDetails: undefined,
  fetchEsySchedulingDetails: undefined,
  esyDetailsLoading: undefined,
});

const SchoolsContextConsumer = SchoolsContext.Consumer;
const SchoolsContextProvider = ({ children }) => {
  const [school, setSchool] = useState();
  const [esySchedulingDetails, setEsySchedulingDetails] = useState();
  const [esyDetailsLoading, setEsyDetailsLoading] = useState(true);

  const updateSchool = async (formValues: SchoolFormValues, schoolId: string) => {
    axios
      .put<string, SchoolResponse>(`payers/${schoolId}`, {
        payer: formValues,
        headers: { 'Content-Type': 'application/json' },
      })
      .then((response) => {
        alertStore.alertSuccess('Successfully updated ESY scheduling status.');
        schoolRecordStore.fetchRecord(schoolId);
      })
      .catch((error) => {});
  };

  const fetchSchool = async (schoolId: string) => {
    axios
      .get(`payers/${schoolId}`)
      .then((response) => {
        setSchool(response?.data?.result);
      })
      .catch((error) => {});
  };

  const fetchEsySchedulingDetails = async (schoolId: string) => {
    setEsyDetailsLoading(true);
    axios
      .get(`payers/${schoolId}/school_esy_schedulers`)
      .then((response) => {
        setEsySchedulingDetails(response?.data);
      })
      .catch((error) => {})
      .finally(() => setEsyDetailsLoading(false));
  };

  return (
    <SchoolsContext.Provider
      value={{
        updateSchool,
        school,
        setSchool,
        fetchSchool,
        esySchedulingDetails,
        fetchEsySchedulingDetails,
        esyDetailsLoading,
      }}
    >
      {children}
    </SchoolsContext.Provider>
  );
};

export { SchoolsContextProvider, SchoolsContextConsumer, SchoolsContext };
