/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from 'react';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { UserTicket } from '../model';
import { UserTicketsContext } from '../contexts/UserTicketsContext';

interface AdminReferralActions {
  referralTicket: UserTicket;
}

const AdminReferralActions: React.FC<AdminReferralActions> = (props) => {
  const { referralTicket } = props;

  const { setReferralTicketModalOpen, setReferralToEdit } = useContext(UserTicketsContext);

  const onApprove = async () => {
    setReferralToEdit(referralTicket);
    setReferralTicketModalOpen(true);
  };

  // Only one action for now, but it's likely that more will be added.
  // So let's keep this as a separate component.
  return (
    <CheckCircleIcon
      style={{ cursor: 'pointer', color: 'green', width: 20, marginLeft: 6 }}
      onClick={() => onApprove()}
    />
  );
};

export default AdminReferralActions;
