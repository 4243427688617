import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { Colors } from '../style/colors';
import { _compactJoin } from '../utils/collection.utils';

export enum ButtonFlavor {
  Primary = 'primary',
  Secondary = 'secondary',
}

interface ButtonProps {
  text: string;
  disabled: boolean;
  flavor: ButtonFlavor;
  handleClick: (e: any) => void;
}

const StyledButton = styled.button`
  border-radius: 2px;
  //min-width: 110px;
  padding: 0 10px;
  height: 40px;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 17px;
  text-align: center;
  border: none;
  cursor: pointer;

  &.disabled {
    cursor: not-allowed;
  }

  &.primary {
    background-color: ${Colors.PurplePrimary};
    color: ${Colors.BackgroundWhite};
  }

  &.secondary {
    background-color: ${Colors.TertiaryOne};
    color: ${Colors.BlackText};
  }
`;

const Button: FunctionComponent<ButtonProps> = (props: ButtonProps) => {
  const { text, disabled, handleClick, flavor = ButtonFlavor.Primary } = props;

  const classes = _compactJoin(
    [
      flavor, //
      disabled ? 'disabled' : null,
    ],
    ' ',
  );

  return (
    <StyledButton className={classes} disabled={disabled} onClick={handleClick}>
      {text}
    </StyledButton>
  );
};

export default Button;
