import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { ReferralsContext } from '../contexts/ReferralsContext';

type DocumentationUploadProps = {
  category: string;
  allAttachmentsOptional?: boolean;
};

const DocumentationUpload: React.FC<DocumentationUploadProps> = (props) => {
  const { category, allAttachmentsOptional = false } = props;
  const { handleRequiredUpload, handleSelectedFile } = React.useContext(ReferralsContext);

  const requiredUploads = {
    evaluation: allAttachmentsOptional ? '' : 'Permission Form',
    screening: allAttachmentsOptional ? '' : 'Permission Form',
    iep: allAttachmentsOptional ? '' : 'Student Documentation',
    '504': allAttachmentsOptional ? '' : 'Student Documentation',
  };

  const optionalUploads = {
    evaluation: [
      allAttachmentsOptional ? 'Permission Form' : undefined,
      'Upload Parent/Caregiver Input',
      'Upload Teacher Input',
      'Upload Grades',
      'Other Documentation',
    ],
    screening: [allAttachmentsOptional ? 'Permission Form' : undefined, 'Other Documentation'],
    iep: [allAttachmentsOptional ? 'Student Documentation' : undefined, 'Other Documentation'],
    '504': [allAttachmentsOptional ? 'Student Documentation' : undefined, 'Other Documentation'],
  };

  return (
    <Grid container spacing={1} style={{ marginLeft: '10px', marginTop: '20px' }}>
      <Grid item xs={12}>
        <Typography variant="h1" component="h1">
          Documentation Upload
        </Typography>
      </Grid>
      <br />

      {requiredUploads[category]?.length > 0 && (
        <Grid item xs={12}>
          <Grid container>
            <Grid item>
              <Typography>{requiredUploads[category]} (Required)</Typography>
            </Grid>
            <Grid item style={{ marginLeft: 7 }}>
              <input type="file" multiple onChange={(value) => handleRequiredUpload(value)} />
            </Grid>
          </Grid>
          <br />
        </Grid>
      )}

      <br />

      {optionalUploads[category]
        .filter((file) => file !== undefined)
        .map((optionalUploadLabel, idx) => (
          <Grid item xs={12} key={idx}>
            <Grid container>
              <Grid item>
                <Typography>{optionalUploadLabel}</Typography>
              </Grid>
              <Grid item style={{ marginLeft: 7 }}>
                <input type="file" multiple onChange={(value) => handleSelectedFile(value)} />
              </Grid>
            </Grid>
            <br />
          </Grid>
        ))}
    </Grid>
  );
};

export default DocumentationUpload;
