import React, { FunctionComponent } from 'react';
import { Typography, Box, Grid, Button, Card, CardContent } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import DataTable from '../../shared/components/DataTable';
import { IDataTableColumn } from '../../shared/components/DataTable/DataTable/model';
import { SchoolAction } from '../model';
// import { SchoolActionsContext } from '../contexts/ComplianceTrackingContext';

const SchoolActionsList: FunctionComponent = () => {
  // const { schoolActions, fetchSchoolActions } = React.useContext(SchoolActionsContext);
  // const [fetchedSchoolActions, setFetchedSchoolActions] = React.useState(false);
  const history = useHistory();

  // React.useEffect(() => {
  //   if (!fetchedSchoolActions) {
  //     setFetchedSchoolActions(true);
  //     fetchSchoolActions();
  //   }
  // }, [fetchSchoolActions, fetchedSchoolActions]);

  const columns: IDataTableColumn<SchoolAction>[] = [
    {
      name: 'Service Type',
      selector: 'service_type',
      sortable: true,
    },
    {
      name: 'Expected',
      selector: 'expected',
      sortable: true,
    },
    {
      name: 'Actual',
      selector: 'actual',
      sortable: false,
    },
    {
      name: 'Actions',
      selector: 'actions',
      sortable: false,
      cell: (school_action: SchoolAction) => (
        <Button target="_blank" href={school_action.link} variant="contained" color="primary">
          Edit Service
        </Button>
      ),
    },
  ];

  const complianceServices = [
    {
      service_type: 'Occupational Therapy',
      expected:
        '255 minutes per nine weeks via direct therapy, consultation, collaboration, progress monitoring, compliance activities, and/or observations',
      actual:
        '225 minutes per nine weeks via direct therapy, consultation, collaboration, progress monitoring, compliance activities, and/or observations',
      link: 'http://localhost:3001/students/10/services/34',
    },
    {
      service_type: 'Speech and Language Therapy',
      expected:
        'up to 4 - thirty minute individual direct therapy sessions quarterly (135 minutes per nine weeks via direct therapy, consultation, collaboration, progress monitoring, compliance activities, and/or observations)',
      actual:
        'up to 4 - thirty minute individual direct therapy sessions quarterly (135 minutes per nine weeks via direct therapy, consultation, collaboration, progress monitoring, compliance activities, and/or observations)',
      link: 'http://localhost:3001/students/10/services/34',
    },
  ];

  return (
    <Box>
      <Grid justify="space-between" container spacing={3}>
        <Grid item>
          <Typography variant="h1" component="h1">
            Compliance Tracking
          </Typography>
        </Grid>
        {/* <Grid item xs={12}>
          <FilterBar
            filters={filters}
            baseStore={schoolRecordStore}
            onFilter={() => schoolRecordStore.fetchRecords()}
            filtersOpen
          />
        </Grid> */}
      </Grid>
      <br />
      <Card>
        <CardContent>
          <DataTable
            title=""
            columns={columns}
            noHeader
            data={complianceServices}
            striped
            onRowClicked={(school_action: SchoolAction) => history.push(school_action.link)}
            highlightOnHover
            pointerOnHover
            pagination
            paginationServer
            paginationTotalRows={complianceServices?.length}
          />
        </CardContent>
      </Card>
    </Box>
  );
};

export default SchoolActionsList;
