import React, { FunctionComponent, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Typography, Checkbox, FormControlLabel } from '@material-ui/core';
import ConfirmCancelModal from '../../../shared/components/modal/ConfirmCancelModal';
import TextInput from '../../../shared/components/form/TextInput';
import SelectInput from '../../../shared/components/form/SelectInput';
import { ListingServicesContext } from '../contexts/ListingServicesContext';
import { axios } from '../../../shared/singletons';
import { ListingServiceFormValues } from '../model';
import { allAppointmentStatusChoices } from '../../../appointments/constants';
import { useStyles } from '../../../shared/style/siteWideStyles';

const NewListingServiceModal: FunctionComponent = () => {
  const history = useHistory();
  const classes = useStyles();

  const { fetchChoices, serviceCategoryChoices, createModalOpen, setCreateModalOpen } = useContext(
    ListingServicesContext,
  );

  const [choicesFetched, setChoicesFetched] = useState(false);
  const [formValues, setFormValues] = useState<ListingServiceFormValues>({
    possible_statuses: ['incomplete', 'needs_rescheduled'],
  });
  const [selectedStatuses, setSelectedStatuses] = useState([]);

  useEffect(() => {
    if (!choicesFetched) {
      setChoicesFetched(true);
      fetchChoices();
    }
  }, [choicesFetched, setChoicesFetched, fetchChoices]);

  const handleCheckboxChange = (e) => {
    const value = e.target.value;
    let newValues = [];

    if (e.target.checked) {
      newValues = [...selectedStatuses, value];
    } else {
      newValues = selectedStatuses.filter((item) => item !== value);
    }

    setSelectedStatuses(newValues);
    setFormValues({ ...formValues, possible_statuses: newValues });
  };

  const handleSubmit = () => {
    axios.post('/listing_services.json', { listing_service: formValues }).then(() => history.push('/listing_services'));
  };

  return (
    <ConfirmCancelModal
      isOpen={createModalOpen}
      title="Create Listing Service"
      confirmLabel="Submit"
      openStatusChanged={(isOpen) => setCreateModalOpen(isOpen)}
      onConfirm={handleSubmit}
      confirmDisabled={!formValues?.name}
      onCancel={() => setCreateModalOpen(false)}
    >
      <form noValidate autoComplete="off">
        <TextInput
          styleOverrides={classes.textInput}
          value={formValues?.name}
          label="Listing Service Name"
          valueChanged={(value: string) => setFormValues({ ...formValues, name: value })}
        />
        <SelectInput
          styleOverrides={classes.textInput}
          type="text"
          label="Listing Service Category"
          value={formValues?.service_category_id}
          valueChanged={(value: string) => setFormValues({ ...formValues, service_category_id: value })}
          choices={serviceCategoryChoices}
          defaultChoice={{ value: '', label: 'None' }}
        />
        <SelectInput
          styleOverrides={classes.textInput}
          type="text"
          label="Editable Units"
          choices={[
            { label: 'Yes', value: 'true' },
            { label: 'No', value: 'false' },
          ]}
          value={formValues?.editable}
          valueChanged={(value) => setFormValues({ ...formValues, editable: value })}
        />
        <TextInput
          styleOverrides={classes.textInput}
          type="text"
          label="Maximum Units"
          value={formValues?.maximum_units}
          valueChanged={(value) => setFormValues({ ...formValues, maximum_units: value })}
        />
        <SelectInput
          styleOverrides={classes.textInput}
          type="text"
          label="Goal Required"
          choices={[
            { label: 'Yes', value: 'true' },
            { label: 'No', value: 'false' },
          ]}
          value={formValues?.goal_required}
          valueChanged={(value) => setFormValues({ ...formValues, goal_required: value })}
        />
        <SelectInput
          styleOverrides={classes.textInput}
          type="text"
          label="Renewal Required"
          choices={[
            { label: 'Yes', value: 'true' },
            { label: 'No', value: 'false' },
          ]}
          value={formValues?.renewal_required}
          valueChanged={(value) => setFormValues({ ...formValues, renewal_required: value })}
        />
        <br />
        <br />
        <Typography variant="h3">Allow Statuses</Typography>
        {allAppointmentStatusChoices.map((stat) => (
          <div key={stat.label}>
            <FormControlLabel
              control={
                <Checkbox
                  value={stat.value}
                  checked={formValues?.possible_statuses?.includes(stat.value)}
                  onChange={handleCheckboxChange}
                  name="possible_statuses"
                  color="primary"
                  disabled={stat.disabled}
                />
              }
              label={stat.label}
            />{' '}
            &emsp;
          </div>
        ))}
      </form>
    </ConfirmCancelModal>
  );
};

export default NewListingServiceModal;
