/* eslint-disable react-hooks/exhaustive-deps */
import React, { FunctionComponent, useState, useEffect, useContext } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { Box, Grid, Typography, makeStyles, Button } from '@material-ui/core';
import { format } from 'date-fns';
import InformationField from '../../shared/components/InformationField';
import TextInput from '../../shared/components/form/TextInput';
import SelectInput from '../../shared/components/form/SelectInput';
import { appointmentFormStore, appointmentBaseStore } from '../../shared/singletons';
import { useStoreObservable } from '../../shared/state/useStoreObservable.hook';
import { UserContext } from '../../auth/contexts/userContext';
import { AppointmentsContext } from '../contexts/AppointmentsContext';
import { stringToDayOfWeek } from '../../shared/utils/date.utils';
import { formatDate } from '../../shared/utils/date.utils';
import AppointmentErrorsList from '../components/AppointmentErrorsList';

const useStyles = makeStyles({
  textInput: {
    height: 'auto',
    width: '98%',
    margin: '14px 0',
  },
  baseError: {
    marginBottom: '10px',
  },
});

const RescheduleAppointmentPage: FunctionComponent = (props) => {
  const { appointmentId, studentId } = useParams<Record<string, string>>();
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  function useQuery() {
    return new URLSearchParams(location.search);
  }

  const query = useQuery();
  const returnTo = query.get('return_to');

  const { locationChoices, serviceOwnerChoices, isLoadingChoices } = useStoreObservable(appointmentBaseStore);

  const { user, currentUserHasRole } = useContext(UserContext);
  const {
    fetchAppointment,
    appointment,
    appointmentErrorWarnings,
    updateButtonDisabled,
    selectedUnits,
    appointmentFormValues,
    setAppointmentFormValues,
    appointmentDuration,
    setAppointmentDuration,
    setRequireComment,
    appointmentComment,
    setAppointmentComment,
  } = useContext(AppointmentsContext);

  let [startDateLabel, setStartDateLabel] = useState('');

  useEffect(() => {
    fetchAppointment(studentId, appointmentId);
  }, []);

  useEffect(() => {
    appointmentBaseStore.fetchRescheduleChoices(appointment?.student_id);

    setAppointmentDuration(
      (Number(new Date(`${appointment?.schedule_date}T${appointment?.end_time}`)) -
        Number(new Date(`${appointment?.schedule_date}T${appointment?.start_time}`))) /
        (15 * 60000),
    );
    setAppointmentComment('');

    setAppointmentFormValues(appointment);
    setRequireComment(true);
  }, [appointment]);

  useEffect(() => {
    if (appointment?.id) {
      appointmentErrorWarnings(appointment, currentUserHasRole('admin') ? 'admin' : 'therapist');
    }
  }, [appointmentFormValues]);

  const handleFormValueChange = (key: string, value: string) => {
    // If any value changes besides owner, then status should be set to incomplete unless appointment is complete
    if (key === 'user_id' || key === 'end_time' || appointment?.status === 'complete' || key === 'update_all_occ') {
      setAppointmentFormValues({ ...appointmentFormValues, [key]: value });
    } else if (key === 'start_time') {
      const startTimeDifference =
        Number(new Date(`${appointment?.schedule_date} ${value}`)) -
        Number(new Date(`${appointment?.schedule_date} ${appointmentFormValues?.start_time}`));
      const newEndTime =
        Number(new Date(`${appointment?.schedule_date} ${appointmentFormValues?.end_time}`)) + startTimeDifference;

      setAppointmentFormValues({
        ...appointmentFormValues,
        start_time: value,
        end_time: format(new Date(newEndTime), 'HH:mm'),
      });
    } else {
      setAppointmentFormValues({ ...appointmentFormValues, [key]: value, status: 'incomplete' });
    }
  };

  const handleFormSubmit = () => {
    const onSuccess = () => {
      const redirectTo = returnTo || `/students/${studentId}/appointments/${appointmentId}`;
      history.push(redirectTo);
    };

    appointmentFormStore.createOrUpdateAppointment(
      appointmentFormValues,
      appointment.student_id,
      appointment.id,
      onSuccess,
    );
  };

  const handleCommentChange = (value: string) => {
    setAppointmentComment(value);

    setAppointmentFormValues({
      ...appointmentFormValues,
      comments_attributes: { '0': { content: value, user_id: user.id, category: 'reschedule' } },
    });
  };

  return isLoadingChoices || !appointment?.id ? (
    <Typography>Loading...</Typography>
  ) : (
    <Box p={2}>
      <Grid container spacing={6}>
        <Grid item>
          <InformationField label="Student" value={appointment?.student_name} />
        </Grid>
        <Grid item>
          <InformationField label="Timeline ID" value={appointment?.student_slug} />
        </Grid>
        <Grid item>
          <InformationField label="Service Type" value={appointment?.service_type} />
        </Grid>
      </Grid>
      <br />
      <Typography variant="h2">Reschedule Appointment</Typography>
      <Grid container>
        <Grid item xs={10}>
          <TextInput
            value={appointmentFormValues?.schedule_date}
            styleOverrides={classes.textInput}
            type="date"
            shrinkLabel
            label={`Start Date ${startDateLabel}`}
            valueChanged={(value: string) => {
              handleFormValueChange('schedule_date', value);
              setStartDateLabel(`(${stringToDayOfWeek(`${value} 12:00`)})`);
            }}
          />
        </Grid>
        <Grid container>
          <Grid item xs={2}>
            <p>
              Quarter: &emsp;
              {appointment?.quarter_name}
            </p>
          </Grid>
          <Grid item xs={3}>
            <p>
              First day of quarter: &emsp;
              {formatDate(appointment?.first_day_of_quarter)}
            </p>
          </Grid>
          <Grid item xs={3}>
            <p>
              Last day of quarter: &emsp;
              {formatDate(appointment?.last_day_of_quarter)}
            </p>
          </Grid>
          <Grid item xs={3}>
            <p>Original Date: {formatDate(appointment?.original_date)}</p>
          </Grid>
        </Grid>
        <Grid item xs={5}>
          <TextInput
            value={appointmentFormValues?.start_time}
            styleOverrides={classes.textInput}
            type="time"
            shrinkLabel
            label="Start Time"
            valueChanged={(value: string) => handleFormValueChange('start_time', value)}
          />
        </Grid>
        <Grid item xs={5}>
          <TextInput
            value={appointmentFormValues?.end_time}
            styleOverrides={classes.textInput}
            type="time"
            shrinkLabel
            label="End Time"
            valueChanged={(value: string) => handleFormValueChange('end_time', value)}
          />
        </Grid>
        <Grid container>
          <Grid item xs={4}>
            <p>
              Selected units: &nbsp;
              {selectedUnits}
            </p>
          </Grid>
          <Grid item xs={4}>
            <p>
              {appointment?.editable_units ? 'Original' : 'Required'} units: &nbsp;
              {appointmentDuration}
            </p>
          </Grid>
          <Grid item xs={4}>
            <p>
              Maximum units: &nbsp;
              {appointment?.maximum_units}
            </p>
          </Grid>
        </Grid>
        {currentUserHasRole('admin') && (
          <Grid item xs={10}>
            <SelectInput
              value={appointmentFormValues?.admin_units_override}
              styleOverrides={classes.textInput}
              type="text"
              label="Allow Service Provider to Exceed Maximum Units"
              valueChanged={(value: string) => handleFormValueChange('admin_units_override', value)}
              choices={[
                { value: 'true', label: 'Yes' },
                { value: 'false', label: 'No' },
              ]}
            />
          </Grid>
        )}
        <Grid item xs={10}>
          <SelectInput
            value={appointmentFormValues?.location_id}
            styleOverrides={classes.textInput}
            type="text"
            label="Location"
            valueChanged={(value: string) => handleFormValueChange('location_id', value)}
            choices={locationChoices}
            defaultChoice={{ value: '', label: 'None' }}
          />
        </Grid>
        {currentUserHasRole('admin') && (
          <Grid item xs={10}>
            <SelectInput
              value={appointmentFormValues?.user_id}
              styleOverrides={classes.textInput}
              type="text"
              label="Owner"
              valueChanged={(value: string) => handleFormValueChange('user_id', value)}
              choices={
                serviceOwnerChoices?.filter(
                  (choice) => parseInt(Object.keys(choice)[0]) === parseInt(appointment?.referral_service_id),
                )[0]?.[appointment?.referral_service_id]
              }
              defaultChoice={{ value: '', label: 'None' }}
            />
          </Grid>
        )}
        {currentUserHasRole('admin') && (
          <Grid item xs={10}>
            <TextInput
              value={appointmentFormValues?.original_date}
              styleOverrides={classes.textInput}
              type="date"
              shrinkLabel
              label="Original Date"
              valueChanged={(value: string) => handleFormValueChange('original_date', value)}
            />
          </Grid>
        )}
        <Grid item xs={10}>
          <TextInput
            value={appointmentComment}
            styleOverrides={classes.textInput}
            multiline
            rows={8}
            label="Comment"
            valueChanged={(value: string) => handleCommentChange(value)}
          />
        </Grid>
      </Grid>
      <br />
      <AppointmentErrorsList />
      <br />
      <Button variant="contained" color="primary" onClick={handleFormSubmit} disabled={updateButtonDisabled}>
        Reschedule
      </Button>
    </Box>
  );
};

export default RescheduleAppointmentPage;
