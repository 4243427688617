import React, { FunctionComponent, useEffect } from 'react';
import ServiceProviderDashboard from '../components/ServiceProviderDashboard';
import SchoolUserDashboard from '../components/SchoolUserDashboard';
import { dashboardStore } from '../../shared/singletons';
import { useStoreObservable } from '../../shared/state/useStoreObservable.hook';
import { UserContext } from '../../auth/contexts/userContext';
import SupervisorDashboard from '../components/SupervisorDashboard';

export type DashboardPageProps = Record<string, unknown>;

const DashboardPage: FunctionComponent<DashboardPageProps> = () => {
  const { isLoading } = useStoreObservable(dashboardStore);
  const { currentUserHasRole, currentUserHasAnyRole } = React.useContext(UserContext);
  const [serviceProvider, setServiceProvider] = React.useState<string>('');

  /* eslint-disable */
  useEffect(() => {
    dashboardStore.fetchRecord(serviceProvider ? serviceProvider : '0');
  }, [serviceProvider]);
  /* eslint-enable */

  if (currentUserHasAnyRole(['admin', 'supervisor']) && serviceProvider === '') {
    return (
      <SupervisorDashboard
        isLoading={isLoading}
        serviceProvider={serviceProvider}
        setServiceProvider={setServiceProvider}
      />
    );
  }
  if (currentUserHasRole('school_admin')) {
    return <SchoolUserDashboard isLoading={isLoading} />;
  }
  return (
    <ServiceProviderDashboard
      isLoading={isLoading}
      serviceProvider={serviceProvider}
      setServiceProvider={setServiceProvider}
    />
  );
};

export default DashboardPage;
