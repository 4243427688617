import React, { FunctionComponent } from 'react';
import { Card, CardActions, CardContent, Grid, Button, Typography } from '@material-ui/core';
import DataTable from '../../shared/components/DataTable';
import LoadingSpinner from '../../shared/components/LoadingSpinner';
import { IDataTableColumn } from '../../shared/components/DataTable/DataTable/model';
import { Goal } from '../model';
import { goalStore, sessionNoteStore } from '../../shared/singletons';
import GoalRowMenu from './GoalRowMenu';
import NewGoalModal from './NewGoalModal';
import ExpandedSessionNoteList from '../../sessionNotes/components/ExpandedSessionNoteList';
import { UserContext } from '../../auth/contexts/userContext';
import { Colors } from '../../shared/style/colors';
import EditGoalModal from './EditGoalModal';

interface GoalsListProps {
  goals: Goal[];
  serviceId?: string;
  studentId?: string;
  isLoading: boolean;
  showCreateButton?: boolean;
  afterAction: () => void;
}

const GoalsList: FunctionComponent<GoalsListProps> = (props) => {
  const { goals, serviceId, studentId, isLoading, showCreateButton = true, afterAction } = props;
  const [showInactive, setShowInactive] = React.useState(false);
  const { currentUserHasRole } = React.useContext(UserContext);
  const [expandedGoalId, setExpandedGoalId] = React.useState('');

  const filteredGoals = (showInactive && goals) || goals?.filter((goal) => goal.status !== 'inactive');

  const columns: IDataTableColumn<Goal>[] = [
    {
      name: 'Service Type',
      selector: 'service_type',
      sortable: true,
    },
    {
      name: 'START DATE',
      selector: 'start_date',
      sortable: true,
    },
    {
      name: 'END DATE',
      selector: 'end_date',
      sortable: true,
    },
    {
      name: 'GOAL OBJECTIVES',
      selector: 'objectives',
      sortable: true,
      wrap: true,
      grow: 5,
      cell: (goal: Goal) => <div style={{ whiteSpace: 'break-spaces' }}>{goal.objectives}</div>,
    },
    {
      name: 'STATUS',
      selector: 'status',
      sortable: true,
      cell: (row) => (
        <div
          style={{
            fontWeight: 700,
            textTransform: 'uppercase',
            color: row.status === 'active' ? Colors.Success : Colors.Red,
          }}
        >
          {row.status}
        </div>
      ),
    },
    {
      cell: (goal) => <GoalRowMenu goal={goal} afterAction={afterAction} />,
      name: 'ACTIONS',
      allowOverflow: true,
      button: true,
      selector: 'actions',
    },
  ];

  const handleRowExpand = (toggleState: boolean, goal: Goal) => {
    sessionNoteStore.clearSessionNotes();

    if (toggleState) {
      setExpandedGoalId(goal.id);
    }
  };

  const ExpandedHistoryList = ({ data }) => {
    if (expandedGoalId === data.id) {
      return <ExpandedSessionNoteList goalId={data.id} />;
    }
    return null;
  };

  return isLoading ? (
    <LoadingSpinner />
  ) : (
    <Card>
      <EditGoalModal onUpdate={afterAction} />
      <NewGoalModal serviceId={serviceId} studentId={studentId} afterAction={afterAction} />
      <CardContent>
        <Grid container justify="space-between" spacing={3}>
          <Grid item>
            <Typography variant="h2" component="h2">
              Goals
            </Typography>
          </Grid>
          <Grid item>
            {currentUserHasRole('admin') && showCreateButton && (
              <Button variant="contained" color="primary" onClick={() => goalStore.setModalOpen(true, serviceId)}>
                Create New Goal +
              </Button>
            )}
          </Grid>
        </Grid>
        <DataTable
          title=""
          columns={columns}
          noHeader
          data={filteredGoals}
          striped
          highlightOnHover
          pointerOnHover
          expandableRows
          onRowExpandToggled={handleRowExpand}
          expandableRowExpanded={(goal: Goal) => goal.id === expandedGoalId}
          expandableRowsComponent={<ExpandedHistoryList data />}
        />
      </CardContent>
      <CardActions>
        <Button size="small" color="primary" onClick={() => setShowInactive(!showInactive)}>
          {showInactive ? 'Hide Inactive Goals' : 'Show Inactive Goals'}
        </Button>
      </CardActions>
    </Card>
  );
};

export default GoalsList;
