import { axios } from '../../shared/singletons';
import { BaseStore } from '../../shared/state/base.store';
import { API_ENDPOINT } from '../../../config/env';

export class ApplicationSettingsState {
  settings: Record<string, string>;
  isLoading: boolean;
  formValues: Record<string, string>;
  formErrors: Record<string, string>;

  static create(props: Partial<ApplicationSettingsState>): ApplicationSettingsState {
    const defaults: ApplicationSettingsState = {
      settings: {},
      isLoading: false,
      formValues: {},
      formErrors: {},
    };
    return Object.assign(new ApplicationSettingsState(), defaults, props || {});
  }
}

export class ApplicationSettingsStore extends BaseStore<ApplicationSettingsState> {
  constructor() {
    super(ApplicationSettingsState.create({}));
  }

  public fetchRecords(): void {
    this.setState({ isLoading: true });

    axios(`${API_ENDPOINT}/settings/1.json`)
      .then((result) => result?.data?.result ?? [])
      .then((settings) => {
        this.setState({ settings, formValues: settings, isLoading: false });
      })
      .catch(() => {
        this.setState({ isLoading: false });
      });
  }
}
