import React, { useState, createContext } from 'react';
import { axios } from '../../shared/singletons';
import { ReportChoicesResponse } from '../model';
import { SelectOption } from '../../shared/common.model';

interface ReportsContextInterface {
  fetchChoices?: () => Promise<void>;
  supervisorChoices?: SelectOption[];
}

const ReportsContext = createContext<ReportsContextInterface>({});

const ReportsContextConsumer = ReportsContext.Consumer;
const ReportsContextProvider = ({ children }) => {
  const [supervisorChoices, setSupervisorChoices] = useState<SelectOption[]>([]);

  const fetchChoices = async () => {
    axios.get<string, ReportChoicesResponse>(`reports/new.json`).then((r: ReportChoicesResponse) => {
      const { supervisors } = r.data?.result;

      setSupervisorChoices(supervisors);
    });
  };

  return (
    <ReportsContext.Provider
      value={{
        fetchChoices,
        supervisorChoices,
      }}
    >
      {children}
    </ReportsContext.Provider>
  );
};

export { ReportsContextProvider, ReportsContextConsumer, ReportsContext };
