import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Grid, Typography, Button } from '@material-ui/core';
import EmailAlertForm from '../components/EmailAlertForm';
import { EmailAlert } from '../model';
import useEmailAlertRequestFunctions from '../constants/emailAlertRequestFunctions';
import { axios } from '../../shared/singletons';

const EditEmailAlertPage: React.FunctionComponent = () => {
  const { emailAlertId } = useParams<{ emailAlertId: string }>();

  const { handleUpdateEmailAlert } = useEmailAlertRequestFunctions();

  const [emailAlertFormValues, setEmailAlertFormValues] = useState<EmailAlert | null>(null);

  const fetchEmailAlert = async () => {
    axios
      .get(`/email_alerts/${emailAlertId}`)
      .then((response) => {
        setEmailAlertFormValues(response.data);
      })
      .catch((error) => {
        alert(error?.response?.data);
      });
  };

  useEffect(() => {
    fetchEmailAlert();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h1" component="h1">
          Edit Email Alert
        </Typography>
      </Grid>
      <Grid item xs={12} style={{ marginBottom: 12 }}>
        <EmailAlertForm formValues={emailAlertFormValues} setFormValues={setEmailAlertFormValues} />
      </Grid>
      <Grid item xs={2}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleUpdateEmailAlert(emailAlertId, emailAlertFormValues)}
        >
          Save Draft
        </Button>
      </Grid>
    </Grid>
  );
};

export default EditEmailAlertPage;
