import React, { FunctionComponent } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Card, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DataTable from '../../shared/components/DataTable';
import LoadingSpinner from '../../shared/components/LoadingSpinner';
import { IDataTableColumn } from '../../shared/components/DataTable/DataTable/model';
import { HistoryItem } from '../../shared/common.model';

const columns: IDataTableColumn<Record<string, unknown>>[] = [
  {
    name: 'DATE MODIFIED',
    selector: 'date_modified',
    sortable: true,
  },
  {
    name: 'FROM',
    selector: 'formatted_before_name',
    sortable: true,
    cell: (history: HistoryItem) => (
      <div style={{ whiteSpace: 'break-spaces' }}>
        {history.formatted_before_name.split(', ').map((item, index, array) => (
          <span key={index}>
            {item}
            {index !== array.length - 1 && <br />}
          </span>
        ))}
      </div>
    ),
  },
  {
    name: 'TO',
    selector: 'formatted_after_name',
    sortable: true,
    cell: (history: HistoryItem) => (
      <div style={{ whiteSpace: 'break-spaces' }}>
        {history.formatted_after_name.split(', ').map((item, index, array) => (
          <span key={index}>
            {item}
            {index !== array.length - 1 && <br />}
          </span>
        ))}
      </div>
    ),
  },
  {
    name: 'USER',
    selector: 'user',
    sortable: true,
  },
];

interface ProductivityDetailHistoryProps {
  historyItems: HistoryItem[];
  productivityDetailLoading: boolean;
}

const ProductivityDetailHistory: FunctionComponent<ProductivityDetailHistoryProps> = (
  props: ProductivityDetailHistoryProps,
) => {
  const { historyItems, productivityDetailLoading } = props;

  return productivityDetailLoading ? (
    <LoadingSpinner />
  ) : (
    <Card>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
          <Typography variant="h2" component="h2">
            Productivity Detail History
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <DataTable columns={columns} noHeader data={historyItems} striped highlightOnHover pointerOnHover />
        </AccordionDetails>
      </Accordion>
    </Card>
  );
};

export default ProductivityDetailHistory;
