import React, { Dispatch, SetStateAction, useState, useEffect } from 'react';

import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';

import LoadingSpinner from '../../shared/components/LoadingSpinner';
import ResetCheckmarkButton from './ResetCheckmarkButton';

import { Invoice } from '../model';
import { InvoiceColumnOption } from '../model';

import axios from '../../shared/utils/axios.utils';

interface InvoiceColumnPickerProps {
  invoice: Invoice;
  setInvoice: Dispatch<SetStateAction<Invoice>>;
  setActiveStep: Dispatch<SetStateAction<number>>;
  stepIndex: number;
}

const InvoiceColumnPicker: React.FC<InvoiceColumnPickerProps> = (props) => {
  let { invoice, setInvoice, setActiveStep, stepIndex } = props;

  let [selectedColumns, setSelectedColumns] = useState<string[]>([]);
  let [invoiceColumnOptions, setInvoiceColumnOptions] = useState<InvoiceColumnOption[]>([]);
  let [loading, setLoading] = useState(true);

  let fetchPotentialColumns = async () => {
    let response = await axios.get('/invoices/invoices/potential_columns');

    let fetchedColumnOptions = response.data.invoice_column_options;
    setInvoiceColumnOptions(fetchedColumnOptions);

    let initialColumns = fetchedColumnOptions
      ?.filter((option) => invoice?.invoice_column_ids?.includes(option.value) || option.required)
      ?.map((option) => option.value.toString());

    setSelectedColumns(initialColumns);
    setLoading(false);
  };

  useEffect(() => {
    if (invoice?.id) {
      fetchPotentialColumns();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoice]);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setSelectedColumns([...selectedColumns, event.target.value]);
    } else {
      setSelectedColumns(selectedColumns.filter((column) => column !== event.target.value));
    }
  };

  const handleContinue = async () => {
    let response = await axios.put(`/invoices/invoices/${invoice?.id}/columns`, {
      invoice_column_ids: selectedColumns,
      step_index: stepIndex,
    });
    setInvoice(response.data.result);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  return !loading ? (
    <Grid container spacing={1}>
      {invoiceColumnOptions?.map((field, index) => (
        <Grid item xs={3} key={`column_checkbox_${index}`}>
          <FormControlLabel
            key={field.label}
            checked={selectedColumns?.includes(field.value.toString())}
            control={<Checkbox onChange={handleCheckboxChange} value={field.value} />}
            label={field.label}
            disabled={field.required}
          />
        </Grid>
      ))}
      <Grid item xs={12} style={{ marginTop: 15, display: 'flex', alignItems: 'center' }}>
        <Button variant="contained" color="primary" onClick={handleContinue} style={{ marginRight: 15 }}>
          Continue
        </Button>
        <ResetCheckmarkButton invoice={invoice} setInvoice={setInvoice} stepIndex={stepIndex} />
      </Grid>
    </Grid>
  ) : (
    <LoadingSpinner />
  );
};

export default InvoiceColumnPicker;
