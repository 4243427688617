import React, { FunctionComponent } from 'react';
import { Box, Button, Grid, Typography, makeStyles } from '@material-ui/core';
import { applicationSettingsStore, axios } from '../../../shared/singletons';
import TextInput from '../../../shared/components/form/TextInput';
import { useStoreObservable } from '../../../shared/state/useStoreObservable.hook';

const useStyles = makeStyles({
  textInput: {
    height: '60px',
    width: '98%',
    margin: '24px 0 4px',
  },
  baseError: {
    marginBottom: '10px',
  },
});

const ApplicationSettingsForm: FunctionComponent = () => {
  const { formErrors, formValues } = useStoreObservable(applicationSettingsStore);
  const classes = useStyles();
  const [isUpdating, setUpdating] = React.useState(false);

  const handleFormValueChange = async (key: string, value: string) => {
    applicationSettingsStore.setState({ formValues: { ...formValues, [key]: value } });
  };

  const handleUpdate = () => {
    setUpdating(true);

    const data = new FormData();

    // Data was temporarily separated like this to append the logo form data more easily
    data.append('name', formValues.name);
    data.append('primary', formValues.primary);
    data.append('secondary', formValues.secondary);
    data.append('address_name', formValues.address_name);
    data.append('address_line1', formValues.address_line1);
    data.append('address_line2', formValues.address_line2);
    data.append('city', formValues.city);
    data.append('state', formValues.state);
    data.append('zip', formValues.zip);
    data.append('phone', formValues.phone);
    data.append('fax', formValues.fax);
    data.append('billing_contact', formValues.billing_contact);
    data.append('billing_contact_email', formValues.billing_contact_email);
    data.append('appt_email_start', formValues.appt_email_start);

    axios
      .put(`/settings/1.json`, data)
      .then(() => {
        applicationSettingsStore.fetchRecords();
      })
      .catch(() => {
        // applicationSettingsStore.fetchRecords();
      });
  };

  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item md={4} xs={12}>
          <TextInput
            value={formValues?.name}
            styleOverrides={classes.textInput}
            type="text"
            shrinkLabel
            label="Site Name"
            errorMessage={formErrors?.name}
            valueChanged={(value: string) => handleFormValueChange('name', value)}
          />
          <TextInput
            value={formValues?.primary}
            styleOverrides={classes.textInput}
            type="text"
            shrinkLabel
            label="Primary Color Hex Code"
            errorMessage={formErrors?.primary}
            valueChanged={(value: string) => handleFormValueChange('primary', value)}
          />
          <Typography>This color will be applied to all primary buttons and icons</Typography>
          <TextInput
            value={formValues?.secondary}
            styleOverrides={classes.textInput}
            type="text"
            shrinkLabel
            label="Secondary Color Hex Code"
            errorMessage={formErrors?.secondary}
            valueChanged={(value: string) => handleFormValueChange('secondary', value)}
          />
          <Typography>This color will be applied to all hover actions for buttons</Typography>
        </Grid>
      </Grid>
      <br />
      <Box my={1}>
        <Typography variant="h2" component="h2">
          Contact Information
        </Typography>
      </Box>
      <Grid container spacing={3}>
        <Grid item md={4} xs={12}>
          <TextInput
            value={formValues?.address_name}
            styleOverrides={classes.textInput}
            type="text"
            shrinkLabel
            label="Organization Name"
            errorMessage={formErrors?.address_name}
            valueChanged={(value: string) => handleFormValueChange('address_name', value)}
          />

          <TextInput
            value={formValues?.address_line1}
            styleOverrides={classes.textInput}
            type="text"
            shrinkLabel
            label="Address 1"
            errorMessage={formErrors?.address_line1}
            valueChanged={(value: string) => handleFormValueChange('address_line1', value)}
          />

          <TextInput
            value={formValues?.address_line2}
            styleOverrides={classes.textInput}
            type="text"
            shrinkLabel
            label="Address 2 (Optional)"
            errorMessage={formErrors?.address_line2}
            valueChanged={(value: string) => handleFormValueChange('address_line2', value)}
          />

          <TextInput
            value={formValues?.city}
            styleOverrides={classes.textInput}
            type="text"
            shrinkLabel
            label="City"
            errorMessage={formErrors?.city}
            valueChanged={(value: string) => handleFormValueChange('city', value)}
          />

          <Grid container>
            <Grid item xs={12} md={6}>
              <TextInput
                value={formValues?.state}
                styleOverrides={classes.textInput}
                type="text"
                shrinkLabel
                label="State"
                errorMessage={formErrors?.state}
                valueChanged={(value: string) => handleFormValueChange('state', value)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextInput
                value={formValues?.zip}
                styleOverrides={classes.textInput}
                type="text"
                shrinkLabel
                label="Zip"
                errorMessage={formErrors?.zip}
                valueChanged={(value: string) => handleFormValueChange('zip', value)}
              />
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12} md={6}>
              <TextInput
                value={formValues?.phone}
                styleOverrides={classes.textInput}
                type="text"
                shrinkLabel
                label="Phone"
                errorMessage={formErrors?.phone}
                valueChanged={(value: string) => handleFormValueChange('phone', value)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextInput
                value={formValues?.fax}
                styleOverrides={classes.textInput}
                type="text"
                shrinkLabel
                label="Fax (Optional)"
                errorMessage={formErrors?.fax}
                valueChanged={(value: string) => handleFormValueChange('fax', value)}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <br />
      <Box my={1}>
        <Typography variant="h2" component="h2">
          Billing Information
        </Typography>
      </Box>
      <Grid container spacing={3}>
        <Grid item md={4} xs={12}>
          <TextInput
            value={formValues?.billing_contact}
            styleOverrides={classes.textInput}
            type="text"
            shrinkLabel
            label="Full Name"
            errorMessage={formErrors?.billing_contact}
            valueChanged={(value: string) => handleFormValueChange('billing_contact', value)}
          />

          <TextInput
            value={formValues?.billing_contact_email}
            styleOverrides={classes.textInput}
            type="text"
            shrinkLabel
            label="Email"
            errorMessage={formErrors?.billing_contact_email}
            valueChanged={(value: string) => handleFormValueChange('billing_contact_email', value)}
          />

          <TextInput
            value={formValues?.appt_email_start}
            styleOverrides={classes.textInput}
            type="date"
            shrinkLabel
            label="Start Date"
            errorMessage={formErrors?.appt_email_start}
            valueChanged={(value: string) => handleFormValueChange('appt_email_start', value)}
          />
        </Grid>
      </Grid>
      <Button color="primary" variant="contained" onClick={handleUpdate}>
        {isUpdating ? 'Saving...' : 'Save Settings'}
      </Button>
    </Box>
  );
};

export default ApplicationSettingsForm;
