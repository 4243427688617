import React, { useContext } from 'react';
import { Grid } from '@material-ui/core';
import ConfirmCancelModal from '../../shared/components/modal/ConfirmCancelModal';
import YAGForm from '../../appointments/components/YAGForm';
import YearlongAppointmentPreviewCalendar from '../../appointments/components/YearlongAppointmentPreviewCalendar';
import { DuplicateAppointmentsContext } from '../contexts/DuplicateAppointmentsContext';
import InformationField from '../../shared/components/InformationField';
import { AppointmentsContext } from '../../appointments/contexts/AppointmentsContext';

type YAGFormModalProps = {
  locationId: string;
  ownerId: string;
  afterCreate?: () => void;
};

const YAGFormModal: React.FC<YAGFormModalProps> = (props) => {
  const { locationId, ownerId, afterCreate } = props;

  const {
    serviceForAppointmentGenerator,
    yearlongGeneratorModalOpen,
    setYearlongGeneratorModalOpen,
    appointmentScheduler,
    academicYear,
  } = useContext(DuplicateAppointmentsContext);

  const { formShowing } = useContext(AppointmentsContext);

  return (
    <ConfirmCancelModal
      isOpen={yearlongGeneratorModalOpen}
      title="Yearlong Appointment Generator"
      confirmLabel=""
      openStatusChanged={(isOpen) => setYearlongGeneratorModalOpen(isOpen)}
      onConfirm={() => console.log('onConfirm')}
      confirmDisabled={true}
      width={'1200px'}
    >
      <Grid container>
        <Grid item xs={3}>
          <InformationField label="Student Name" value={serviceForAppointmentGenerator?.student_name} />
        </Grid>
        <Grid item xs={3}>
          <InformationField label="Timeline ID" value={serviceForAppointmentGenerator?.slug} />
        </Grid>
        <Grid item xs={3}>
          <InformationField label="Service" value={serviceForAppointmentGenerator?.service_type} />
        </Grid>
        <Grid item>
          <InformationField label="Academic Year" value={appointmentScheduler.school_data?.year_title} />
        </Grid>
        {formShowing ? (
          <YAGForm
            service={serviceForAppointmentGenerator}
            school={appointmentScheduler.school_data}
            locationId={locationId}
            ownerId={ownerId}
            academicYear={academicYear}
          />
        ) : (
          <Grid item xs={10}>
            <YearlongAppointmentPreviewCalendar afterCreate={() => afterCreate()} includeProrateButton={false} />
          </Grid>
        )}
      </Grid>
    </ConfirmCancelModal>
  );
};

export default YAGFormModal;
