import React, { FC, useContext } from 'react';

import { CSVLink } from 'react-csv';

import Button from '@material-ui/core/Button';
import { CircularProgress } from '@material-ui/core';

import { AppointmentsContext } from '../contexts/AppointmentsContext';

const PrepareDownloadAppointmentsButton: FC = () => {
  let { appointmentsDownload, preparingAppointmentsDownload, prepareAppointmentsDownload } = useContext(
    AppointmentsContext,
  );

  return (
    <>
      {appointmentsDownload?.length > 0 ? (
        <CSVLink
          data={appointmentsDownload}
          filename="appointments.csv"
          className={`MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary

          ${preparingAppointmentsDownload ? 'Mui-disabled' : ''}
        `}
          style={{ marginLeft: '8px', backgroundColor: '#424E7A', color: '#fff' }}
        >
          Download Appointments
        </CSVLink>
      ) : (
        <Button
          onClick={() => prepareAppointmentsDownload()}
          style={{ marginLeft: '8px' }}
          variant="contained"
          color="primary"
        >
          {preparingAppointmentsDownload ? <CircularProgress size={24} /> : 'Prepare Download'}
        </Button>
      )}
    </>
  );
};

export default PrepareDownloadAppointmentsButton;
