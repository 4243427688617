import { axios } from '../shared/singletons';
import { BaseStore } from '../shared/state/base.store';
import { StudentChoicesResponse } from './model';
import { API_ENDPOINT } from '../../config/env';
import { SelectOption } from '../shared/common.model';

import { Colors } from '../shared/style/colors';

export class StudentState {
  schoolChoices: SelectOption[];
  locationChoices: SelectOption[];
  gradeLevelChoices: SelectOption[];
  statusChoices: SelectOption[];
  userChoices: SelectOption[];
  statusColors: Record<string, string>;

  static create(props: Partial<StudentState>): StudentState {
    const defaults: StudentState = {
      schoolChoices: [],
      locationChoices: [],
      gradeLevelChoices: [],
      userChoices: [],
      statusChoices: [
        { label: 'Active', value: 'false' },
        { label: 'Inactive', value: 'true' },
      ],

      statusColors: {
        false: Colors.Success,
        true: Colors.Red,
      },
    };
    return Object.assign(new StudentState(), defaults, props || {});
  }
}

export class StudentBaseStore extends BaseStore<StudentState> {
  constructor() {
    super(StudentState.create({}));
  }

  public fetchChoices(): void {
    axios.get<string, StudentChoicesResponse>(`${API_ENDPOINT}/patients/new.json`).then((r: StudentChoicesResponse) => {
      const { schools, grade_levels, locations, users } = r.data?.result;

      this.setState({
        schoolChoices: schools,
        gradeLevelChoices: grade_levels,
        locationChoices: locations,
        userChoices: users,
      });
    });
  }
}
