import React, { FunctionComponent } from 'react';
import { Translation } from '../utils/generic.model';
import { __t } from '../utils/translation.utils';

type TranslationProps = {
  textColor?: string;
  tKey?: string;
  tParams?: Object;
  t?: Translation; // this is a convenience to pass in key and params wrapped in an object
};

const TranslatedText: FunctionComponent<TranslationProps> = (props: TranslationProps) => {
  const { t, tKey, tParams, textColor = 'inherit' } = props;
  const translatedText = t ? __t(t.key, t.params) : __t(tKey, tParams);
  return <span style={{ color: textColor }}>{translatedText}</span>;
};

export default TranslatedText;
