import React, { FunctionComponent, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles, Button } from '@material-ui/core';
import { Typography, Grid } from '@material-ui/core';
import TextInput from '../../shared/components/form/TextInput';
import ErrorAlert from '../../shared/components/ErrorAlert';
import { schoolFormStore } from '../../shared/singletons';
import { useStoreObservable } from '../../shared/state/useStoreObservable.hook';
import axios from '../../shared/utils/axios.utils';

// TODO: Temp styles before we line things up with the mockup
const useStyles = makeStyles({
  textInput: {
    height: '60px',
    width: '80%',
    margin: '10px 0',
  },
  baseError: {
    marginBottom: '10px',
  },
});

const SchoolNewPage: FunctionComponent = () => {
  const { formErrorAlertVisible, formErrors } = useStoreObservable(schoolFormStore);
  const history = useHistory();

  const classes = useStyles();

  const [formValues, setFormValues] = useState<Record<string, string>>({});
  const [disableCreate, setDisableCreate] = useState(true);

  const dateFields = [
    ['start_date', 'Overall Start Date'],
    ['end_date', 'Overall End Date'],
    ['q1_sd', 'Q1 Start Date'],
    ['q1_ed', 'Q1 End Date'],
    ['q2_sd', 'Q2 Start Date'],
    ['q2_ed', 'Q2 End Date'],
    ['q3_sd', 'Q3 Start Date'],
    ['q3_ed', 'Q3 End Date'],
    ['q4_sd', 'Q4 Start Date'],
    ['q4_ed', 'Q4 End Date'],
    ['esy_sd', 'ESY Start Date'],
    ['esy_ed', 'ESY End Date'],
  ];

  useEffect(() => {
    const isAnyDateFieldEmpty = dateFields.some((field) => !formValues[field[0]]);
    const isNameOrAbbrEmpty = !formValues.name || !formValues.abbr;
    setDisableCreate(isAnyDateFieldEmpty || isNameOrAbbrEmpty);
  }, [formValues, dateFields]);

  const createSchool = async () => {
    await axios.post('/payers', formValues).then(() => {
      history.push('/schools');
    });
  };

  return (
    <form noValidate autoComplete="off">
      {formErrors?.base && (
        <ErrorAlert
          display={formErrorAlertVisible}
          onClose={() => schoolFormStore.setState({ formErrorAlertVisible: false })}
        >
          {formErrors?.base}
        </ErrorAlert>
      )}

      <Typography variant="h1">Create New School</Typography>
      <TextInput
        styleOverrides={classes.textInput}
        label="School Name"
        errorMessage={formErrors?.name}
        value={formValues?.name}
        valueChanged={(value: string) => setFormValues({ ...formValues, name: value })}
      />
      <TextInput
        styleOverrides={classes.textInput}
        label="Abbreviation"
        errorMessage={formErrors?.abbr}
        value={formValues?.abbr}
        valueChanged={(value: string) => setFormValues({ ...formValues, abbr: value })}
      />
      <Typography variant="h2">Contact Information</Typography>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <TextInput
            styleOverrides={classes.textInput}
            label="Contact Name"
            errorMessage={formErrors?.contact_name}
            value={formValues?.contact_name}
            valueChanged={(value: string) => setFormValues({ ...formValues, contact_name: value })}
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            styleOverrides={classes.textInput}
            label="Contact Email"
            errorMessage={formErrors?.contact_email}
            value={formValues?.contact_email}
            valueChanged={(value: string) => setFormValues({ ...formValues, contact_email: value })}
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            styleOverrides={classes.textInput}
            label="Address Line 1"
            errorMessage={formErrors?.address1}
            value={formValues?.address1}
            valueChanged={(value: string) => setFormValues({ ...formValues, address1: value })}
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            styleOverrides={classes.textInput}
            label="Address Line 2"
            errorMessage={formErrors?.address2}
            value={formValues?.address2}
            valueChanged={(value: string) => setFormValues({ ...formValues, address2: value })}
          />
        </Grid>
        <Grid item xs={5}>
          <TextInput
            styleOverrides={classes.textInput}
            label="City"
            errorMessage={formErrors?.city}
            value={formValues?.city}
            valueChanged={(value: string) => setFormValues({ ...formValues, city: value })}
          />
        </Grid>
        <Grid item xs={2}>
          <TextInput
            styleOverrides={classes.textInput}
            label="State"
            errorMessage={formErrors?.state}
            value={formValues?.state}
            valueChanged={(value: string) => setFormValues({ ...formValues, state: value })}
          />
        </Grid>
        <Grid item xs={4}>
          <TextInput
            styleOverrides={classes.textInput}
            label="Zip"
            errorMessage={formErrors?.zip}
            value={formValues?.zip}
            valueChanged={(value: string) => setFormValues({ ...formValues, zip: value })}
          />
        </Grid>
        <Grid item xs={4}>
          <TextInput
            styleOverrides={classes.textInput}
            label="Phone"
            errorMessage={formErrors?.phone}
            value={formValues?.phone}
            valueChanged={(value: string) => setFormValues({ ...formValues, phone: value })}
          />
        </Grid>
        <Grid item xs={4}>
          <TextInput
            styleOverrides={classes.textInput}
            label="Fax"
            errorMessage={formErrors?.fax}
            value={formValues?.fax}
            valueChanged={(value: string) => setFormValues({ ...formValues, fax: value })}
          />
        </Grid>
      </Grid>
      <br />
      <Typography variant="h2">Quarter End Dates</Typography>
      <Grid container spacing={3}>
        {dateFields.map((field) => (
          <Grid item xs={6} key={field[0]}>
            <TextInput
              value={formValues[field[0]]}
              styleOverrides={classes.textInput}
              type="date"
              shrinkLabel
              label={field[1]}
              valueChanged={(value) => {
                setFormValues({ ...formValues, [field[0]]: value });
              }}
            />
          </Grid>
        ))}
      </Grid>
      <Button variant="contained" color="primary" onClick={createSchool} disabled={disableCreate}>
        Create School
      </Button>
    </form>
  );
};

export default SchoolNewPage;
