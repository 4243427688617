import React from 'react';
import { IDataTableColumn } from '../../shared/components/DataTable/DataTable/model';
import { Quarter } from '../model';

const appointmentQuarterColumns: IDataTableColumn<Record<string, unknown>>[] = [
  {
    name: 'Name',
    selector: 'name',
    sortable: true,
  },
  {
    name: 'START DATE',
    selector: 'start_date',
    sortable: true,
  },
  {
    name: 'END DATE',
    selector: 'end_date',
    sortable: true,
  },
  {
    name: 'APPOINTMENTS COUNT',
    selector: 'appointments_count',
    sortable: true,
  },
  {
    name: 'SCHEDULED',
    selector: 'scheduled',
    sortable: true,
    cell: (quarter: Quarter) => <div style={{ whiteSpace: 'break-spaces' }}>{quarter.scheduled}</div>,
  },
  {
    name: 'INCOMPLETE',
    selector: 'incomplete',
    sortable: true,
    cell: (quarter: Quarter) => <div style={{ whiteSpace: 'break-spaces' }}>{quarter.incomplete}</div>,
  },
  {
    name: 'COMPLETED',
    selector: 'completed',
    sortable: true,
    cell: (quarter: Quarter) => <div style={{ whiteSpace: 'break-spaces' }}>{quarter.completed}</div>,
  },
  {
    name: 'EARLY CANCEL',
    selector: 'early_cancel',
    sortable: true,
    cell: (quarter: Quarter) => <div style={{ whiteSpace: 'break-spaces' }}>{quarter.early_cancel}</div>,
  },
  {
    name: 'LATE CANCEL',
    selector: 'late_cancel',
    sortable: true,
    cell: (quarter: Quarter) => <div style={{ whiteSpace: 'break-spaces' }}>{quarter.late_cancel}</div>,
  },
  {
    name: 'NEEDS RESCHEDULED',
    selector: 'needs_rescheduled',
    sortable: true,
    cell: (quarter: Quarter) => <div style={{ whiteSpace: 'break-spaces' }}>{quarter.needs_rescheduled}</div>,
  },
  {
    name: 'NO SHOW',
    selector: 'no_show',
    sortable: true,
    cell: (quarter: Quarter) => <div style={{ whiteSpace: 'break-spaces' }}>{quarter.no_show}</div>,
  },
];

export default appointmentQuarterColumns;
