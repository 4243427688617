import React, { FC, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { Appointment } from '../../appointments/model';
import LoadingSpinner from '../../shared/components/LoadingSpinner';
import axios from '../../shared/utils/axios.utils';
import AccountantAppointmentsList from '../../appointments/components/AppointmentListsByRole/AccountantAppointmentsList';

interface IncludedAppointmentsProps {
  invoiceId: string;
  includedAppointmentCount: number;
}

const IncludedAppointments: FC<IncludedAppointmentsProps> = ({ invoiceId, includedAppointmentCount }) => {
  const [accordionExpanded, setAccordionExpanded] = useState(false);
  const [includedAppointments, setIncludedAppointments] = useState<Appointment[]>([]);
  const [totalAppointmentsCount, setTotalAppointmentsCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const fetchIncludedAppointments = async (page: number = 1) => {
    const response = await axios.get(`/invoices/invoices/${invoiceId}/included_appointments`, { params: { page } });
    setIncludedAppointments(response.data.result);
    setTotalAppointmentsCount(response.data.total_appointments_count);
  };

  const handleAccordionChange = () => {
    if (!accordionExpanded) fetchIncludedAppointments(currentPage);
    setAccordionExpanded(!accordionExpanded);
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    fetchIncludedAppointments(page);
  };

  const handleColumnSort = async (column: Record<string, string>, sortDirection: string) => {
    console.log('column', column);
  };

  return (
    <Accordion expanded={accordionExpanded} onChange={handleAccordionChange}>
      <AccordionSummary>
        <Typography variant="h2">Included Appointments: {includedAppointmentCount}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {/* Need to ensure the table is ready, or else the pagination numbers get stuck on 1 to 25 */}
        {includedAppointments.length > 0 ? (
          <AccountantAppointmentsList
            records={includedAppointments}
            totalRecords={totalAppointmentsCount}
            recordsLoading={false}
            handlePageChange={handlePageChange}
            handleColumnSort={handleColumnSort}
          />
        ) : (
          <LoadingSpinner />
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default IncludedAppointments;
