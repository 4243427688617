import React from 'react';
import { AccordionSummary, Box, Button, Grid, Typography, makeStyles } from '@material-ui/core';

interface DashboardPanelProps {
  title: string;
  panelColor: string;
  panelCount: string;
}

const useStyles = makeStyles({
  counter: {
    marginTop: `0px`,
    minWidth: `30px`,
    width: `30px`,
    height: `30px`,
  },
});

const DashboardPanel: React.FC<DashboardPanelProps> = (props) => {
  const { title, panelColor, panelCount = 0 } = props;
  const classes = useStyles();

  return (
    <Box>
      <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
        <Grid justify="space-between" container spacing={3} style={{ paddingTop: '10px' }}>
          <Grid item>
            <Grid container spacing={2}>
              <Grid item>
                <Box display={{ xs: 'none', sm: 'none', md: 'block' }}>
                  <Typography variant="h2" component="h2">
                    {title}
                  </Typography>
                </Box>
                <Box style={{ width: '160px' }} display={{ xs: 'block', sm: 'block', md: 'none' }}>
                  <Typography align="center">{title}</Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container spacing={1}>
              <Button className={classes.counter} style={{ backgroundColor: panelColor }}>
                <strong style={{ color: '#fff' }}>{panelCount}</strong>
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </AccordionSummary>
    </Box>
  );
};

export default DashboardPanel;
