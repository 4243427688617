import React, { FunctionComponent, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import UserDetail from '../components/UserDetail';
import { UserRouteParams } from '../model';
import { InternalUsersContext } from '../../internalUsers/contexts/InternalUsersContext';

export type UserDetailPageProps = Record<string, unknown>;

const UserDetailPage: FunctionComponent<UserDetailPageProps> = () => {
  const { user, userLoading, fetchUser } = useContext(InternalUsersContext);
  const { userId, tab } = useParams<UserRouteParams>();

  useEffect(() => {
    fetchUser(userId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  return (
    <>
      <UserDetail user={user} tab={tab} userLoading={userLoading} />
    </>
  );
};

export default UserDetailPage;
