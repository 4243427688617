import React, { FunctionComponent } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Grid, Button } from '@material-ui/core';
import { Service } from '../../services/model';
import TextInput from '../../shared/components/form/TextInput';
import { axios } from '../../shared/singletons';
import { API_ENDPOINT } from '../../../config/env';
import LoadingSpinner from '../../shared/components/LoadingSpinner';

const useStyles = makeStyles({
  textInput: {
    height: '60px',
    width: '98%',
    margin: '10px 0',
  },
  baseError: {
    marginBottom: '10px',
  },
});

interface GoalAccordionProps {
  serviceId?: string;
  idx?: number;
}

const GoalAccordion: FunctionComponent<GoalAccordionProps> = (props) => {
  const { serviceId, idx } = props;
  const classes = useStyles();
  const [formValues, setFormValues] = React.useState<any>({});
  const [expanded, setExpanded] = React.useState<boolean>(true);
  const [service, setService] = React.useState<Service>({});
  const [isLoading, setIsLoading] = React.useState<boolean>(true);

  const fetchService = () => {
    setIsLoading(true);

    axios.get<string, any>(`${API_ENDPOINT}/referral_services/${serviceId}.json`).then((response) => {
      setService(response?.data?.result);
      setIsLoading(false);
    });
  };

  React.useEffect(() => {
    fetchService();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    setFormValues({
      start_date: service?.start_date,
      end_date: service?.end_date,
    });
  }, [service]);

  const handleSubmit = (serviceId, collapse) => {
    axios({
      method: 'post',
      url: `${API_ENDPOINT}/referral_services/${serviceId}/goals`,
      data: { goal: formValues },
      headers: { 'Content-Type': 'application/json' },
    }).then(() => {
      fetchService();
      if (collapse) {
        setExpanded(false);
      } else {
        setFormValues({
          name: '',
          description: '',
        });
      }
    });
  };

  return isLoading ? (
    <LoadingSpinner />
  ) : (
    <Accordion expanded={expanded}>
      <AccordionSummary onClick={() => setExpanded(!expanded)} expandIcon="▼">
        <Typography variant="h2" component="h2">
          {`${idx + 1}) ${service?.service_type} Goals`}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid item xs={12}>
          <Grid>
            {service?.goals?.length > 0 && (
              <Typography variant="h3" component="h3" style={{ marginTop: -10 }}>
                Saved Goals
              </Typography>
            )}
            {service.goals?.map((goal, goalidx) => {
              return (
                <Grid item xs={6}>
                  <p>
                    <strong>{`${goalidx + 1})`}</strong> {`${goal.objectives}`}
                  </p>
                </Grid>
              );
            })}
          </Grid>
          <Grid>
            <Typography variant="h3" component="h3" style={{ marginTop: 10 }}>
              New Goal
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <TextInput
                  styleOverrides={classes.textInput}
                  value={formValues?.start_date}
                  label="Start Date"
                  type="date"
                  shrinkLabel
                  valueChanged={(value: string) => setFormValues({ ...formValues, start_date: value })}
                />
              </Grid>
              <Grid item xs={6} style={{ marginLeft: -15 }}>
                <TextInput
                  styleOverrides={classes.textInput}
                  value={formValues?.end_date}
                  label="End Date"
                  type="date"
                  shrinkLabel
                  valueChanged={(value: string) => setFormValues({ ...formValues, end_date: value })}
                />
              </Grid>
            </Grid>
            <div style={{ height: 200 }}>
              <Grid item xs={12}>
                <TextInput
                  styleOverrides={classes.textInput}
                  value={formValues?.description}
                  label="Goal Objectives"
                  multiline
                  rows={8}
                  valueChanged={(value: string) => setFormValues({ ...formValues, description: value })}
                />
              </Grid>
            </div>
            <Grid>
              <Button variant="contained" color="primary" onClick={() => handleSubmit(service.id, true)}>
                Save and continue
              </Button>
              &emsp;
              <Button variant="contained" color="primary" onClick={() => handleSubmit(service.id, false)}>
                Save and add another goal
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default GoalAccordion;
