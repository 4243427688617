/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from 'react';
import { Button } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { PanTool } from '@material-ui/icons';
import { UserTicketsContext } from '../contexts/UserTicketsContext';
import { UserTicket } from '../model';

interface AdminTicketActionsProps {
  changeRequest: UserTicket;
}

const AdminTicketActions: React.FC<AdminTicketActionsProps> = (props) => {
  const { changeRequest } = props;

  const { updateUserTicket, setApproveTicketModalOpen, setTicketToEdit, setRejectTicketModalOpen } = useContext(
    UserTicketsContext,
  );

  const onApprove = async (row) => {
    setTicketToEdit(row);
    setApproveTicketModalOpen(true);
  };

  const onReject = async (row) => {
    setTicketToEdit(row);
    setRejectTicketModalOpen(true);
  };

  const handleNoAction = async (row) => {
    updateUserTicket(row, { status: 'no_action' });
  };

  const revertToPending = async (row) => {
    updateUserTicket(row, { status: 'pending' });
  };

  return (
    <>
      {changeRequest.category === 'meeting_completed' ? (
        <>
          {changeRequest.status === 'no_action' ? (
            <PanTool
              style={{ cursor: 'pointer', color: 'orange', width: 20 }}
              onClick={() => revertToPending(changeRequest)}
            />
          ) : (
            <Button variant="contained" color="primary" onClick={() => handleNoAction(changeRequest)}>
              No Action Needed
            </Button>
          )}
        </>
      ) : (
        <>
          {['approved', 'rejected'].includes(changeRequest?.status) ? (
            <PanTool
              style={{ cursor: 'pointer', color: 'orange', width: 20, marginLeft: 6 }}
              onClick={() => revertToPending(changeRequest)}
            />
          ) : (
            <>
              <CheckCircleIcon
                style={{ cursor: 'pointer', color: 'green', width: 20, marginLeft: 6 }}
                onClick={() => onApprove(changeRequest)}
              />
              <CancelIcon
                style={{ cursor: 'pointer', marginLeft: 7, color: 'red' }}
                onClick={() => onReject(changeRequest)}
              />
            </>
          )}
        </>
      )}
    </>
  );
};

export default AdminTicketActions;
