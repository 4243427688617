import React, { FunctionComponent, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { MenuItem } from '@material-ui/core';
import CustomMaterialMenu from '../../shared/components/CustomMaterialMenu';
import { Training } from '../model';
import axios from '../../shared/utils/axios.utils';
import { TrainingsContext } from '../contexts/TrainingsContext';

interface TrainingMenuProps {
  training: Training;
}

const TrainingRowMenu: FunctionComponent<TrainingMenuProps> = (props: TrainingMenuProps) => {
  const { training } = props;

  const history = useHistory();
  const {
    // setTrainingDestroyModalOpen,
    setTrainingFormModalOpen,
    setTrainingToEdit,
    // setTrainingToDelete
  } = useContext(TrainingsContext);

  const handleEditClicked = () => {
    setTrainingFormModalOpen(true);
    setTrainingToEdit(training);
  };

  const handleArchiveClicked = async () => {
    const newArchived = !training.archived;
    await axios.put(`manage_trainings/${training.id}.json`, { training: { archived: newArchived } });
    history.push(`/manage_trainings`);
  };

  // const handleDeleteClicked = (trainingId) => {
  //   setTrainingToDelete(trainingId);
  //   setTrainingDestroyModalOpen(true);
  // };

  return (
    <CustomMaterialMenu size="small">
      <MenuItem onClick={handleEditClicked}>Edit Training</MenuItem>
      <MenuItem onClick={handleArchiveClicked}>{training.archived ? 'Unarchive' : 'Archive'} Training</MenuItem>
      {/* <MenuItem onClick={() => setTrainingDestroyModalOpen(true)}>Delete Training</MenuItem> */}
    </CustomMaterialMenu>
  );
};

export default TrainingRowMenu;
