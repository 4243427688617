import React, { FunctionComponent } from 'react';
import { Box, Step, StepLabel, Stepper } from '@material-ui/core';
import { NumberChoice } from '../utils/generic.model';
import { _numberRange } from '../utils/number.utils';

interface NumberedStepperProps {
  totalSteps: number;
  currentStepIndex: number;
}

const NumberedStepper: FunctionComponent<NumberedStepperProps> = (props: NumberedStepperProps) => {
  const { currentStepIndex, totalSteps } = props;

  const steps: NumberChoice[] = _numberRange(1, totalSteps).map((value) => ({
    id: value,
    label: `Step ${value}`,
  }));

  return (
    <Stepper activeStep={currentStepIndex}>
      {steps.map((step) => (
        <Step key={step.id}>
          <StepLabel>
            <Box style={{ width: '100%' }} display={{ xs: 'none', sm: 'none', md: 'block' }}>
              {step.label}
            </Box>
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};

export default NumberedStepper;
