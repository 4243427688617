import React, { FunctionComponent } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import ServiceInformation from '../../services/components/ServiceInformation';
import AttachmentsList from '../../attachments/components/AttachmentsList';
import CommunicationsList from '../../communications/components/CommunicationsList';
import AppointmentQuarters from './AppointmentQuarters';
import { Appointment } from '../model';
import { appointmentRecordStore } from '../../shared/singletons';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
});

interface AppointmentServiceDetailsProps {
  appointment: Appointment;
  appointmentLoading: boolean;
}

const AppointmentServiceDetails: FunctionComponent<AppointmentServiceDetailsProps> = (props) => {
  const { appointment, appointmentLoading } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <ServiceInformation service={appointment?.service} serviceLoading={appointmentLoading} />
        </Grid>
        <Grid item xs={12} md={6}>
          <AttachmentsList
            attachments={appointment.attachments}
            showAddAttachment={appointment?.student?.has_active_service}
            isLoading={appointmentLoading}
            attachmentableType="patients"
            attachmentableId={appointment.student_id}
            afterAction={() => appointmentRecordStore.fetchRecord(appointment.student_id, appointment.id)}
          />
        </Grid>
        <Grid item xs={12}>
          <AppointmentQuarters
            initialQuarters={appointment?.quarters}
            academic_years={appointment?.academic_years}
            year_choice={appointment?.year_choice}
            service={appointment?.service}
          />
        </Grid>
        <Grid item xs={12}>
          <CommunicationsList
            communications={appointment.communications}
            parentType="appointments"
            parentId={appointment.id}
            isLoading={appointmentLoading}
            onSuccess={() => appointmentRecordStore.fetchRecord(appointment.student_id, appointment.id)}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default AppointmentServiceDetails;
