import React, { FunctionComponent } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import DataTable from '../../shared/components/DataTable';
import { IDataTableColumn } from '../../shared/components/DataTable/DataTable/model';
import { SessionNote } from '../model';

const columns: IDataTableColumn<SessionNote>[] = [
  {
    name: 'DATE',
    selector: 'appointment_date',
    sortable: true,
    sortFunction: (rowA: SessionNote, rowB: SessionNote) =>
      new Date(rowA.appointment_date).getTime() - new Date(rowB.appointment_date).getTime(),
  },
  {
    name: 'CREATED BY',
    selector: 'created_by',
    sortable: true,
  },
  {
    name: 'CONTENT',
    selector: 'all_fields',
    sortable: true,
    grow: 8,
    cell: (row: SessionNote) => (
      <div>
        <p>
          <strong>Content: </strong>
          {row.subjective_comment || row.content || 'N/A'}
        </p>
        {row.assistance && (
          <p>
            <strong>Assistance: </strong>
            {row.assistance}
          </p>
        )}
        {row.percent_string && (
          <p>
            <strong>Percent: </strong>
            {row.percent_string}
          </p>
        )}
      </div>
    ),
  },
];

interface SubjectiveSessionNotesProps {
  subjectiveNotes?: SessionNote[];
  title?: string;
}

const SubjectiveSessionNotes: FunctionComponent<SubjectiveSessionNotesProps> = (props) => {
  const { subjectiveNotes, title = 'Goal History' } = props;

  return (
    <Card>
      <CardContent>
        <Typography variant="h2" component="h2">
          {title}
        </Typography>
        <DataTable
          columns={columns}
          noHeader
          data={subjectiveNotes}
          striped
          highlightOnHover
          pointerOnHover
          pagination
        />
      </CardContent>
    </Card>
  );
};

export default SubjectiveSessionNotes;
