/* eslint-disable react-hooks/exhaustive-deps */
import React, { FunctionComponent, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import RenewalsCaseLoad from '../components/RenewalsCaseloadList';
import { RenewalsContext } from '../contexts/RenewalsContext';
import { RenewalRouteParams } from '../model';

export type RenewalsCaseLoadPageProps = Record<string, unknown>;

const RenewalsCaseLoadPage: FunctionComponent<RenewalsCaseLoadPageProps> = () => {
  const { studentId } = useParams<RenewalRouteParams>();

  const { fetchIepRenewals, fetchRevaluationRenewals } = React.useContext(RenewalsContext);

  const query = new URLSearchParams(useLocation().search);
  const filterParams = query.get('filters')?.split('__')?.join('&');

  useEffect(() => {
    fetchIepRenewals(filterParams);
    fetchRevaluationRenewals(filterParams);
  }, []);

  return <RenewalsCaseLoad studentId={studentId} showDownloadButton showCreateButton={false} showFilterBar />;
};

export default RenewalsCaseLoadPage;
