import React, { FunctionComponent, useState } from 'react';
import { Box } from '@material-ui/core';
import ConfirmCancelModal from '../../shared/components/modal/ConfirmCancelModal';
import LocationForm from './LocationForm';
import { School } from '../model';
import axios from '../../shared/utils/axios.utils';

interface NewLocationModalProps {
  school: School;
  newLocationModalOpen: boolean;
  setNewLocationModalOpen: (open: boolean) => void;
}

const EditLocationModal: FunctionComponent<NewLocationModalProps> = (props) => {
  const { newLocationModalOpen, setNewLocationModalOpen, school } = props;

  const [locationFormValues, setLocationFormValues] = useState({ payer_id: school.id });
  const [formErrors, setFormErrors] = useState<any>(null);

  const handleFormSubmit = () => {
    axios({
      method: 'post',
      url: `locations.json`,
      data: { location: locationFormValues },
      headers: { 'Content-Type': 'application/json' },
    })
      .then(() => {
        window.location.reload();
      })
      .catch((e) => {
        setFormErrors(e.response.data.errors);
      });
  };

  return (
    <ConfirmCancelModal
      isOpen={newLocationModalOpen}
      title="Edit Location"
      onConfirm={handleFormSubmit}
      confirmLabel="Create"
      openStatusChanged={(isOpen) => setNewLocationModalOpen(isOpen)}
    >
      <Box p={2}>
        <LocationForm
          school={school}
          locationFormValues={locationFormValues}
          setLocationFormValues={setLocationFormValues}
          formErrors={formErrors}
        />
      </Box>
    </ConfirmCancelModal>
  );
};

export default EditLocationModal;
