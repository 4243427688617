import React from 'react';
import { Box, Card, CardContent, Grid, Typography } from '@material-ui/core';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import DataTable from '../../shared/components/DataTable';
import { dashboardStore } from '../../shared/singletons';
import { useStoreObservable } from '../../shared/state/useStoreObservable.hook';

const columns: unknown[] = [
  {
    name: 'SCHOOL',
    selector: 'school',
    sortable: true,
  },
  {
    name: 'DATE',
    selector: 'date',
    sortable: true,
  },
  {
    name: 'Category',
    selector: 'category',
    sortable: true,
  },
];

const UpcomingSchoolClosings: React.FunctionComponent = () => {
  const { isLoading, schoolClosings } = useStoreObservable(dashboardStore);

  const NoSchoolClosings = () => (
    <>
      <Grid container justify="center">
        <Grid item style={{ textAlign: 'center' }}>
          <br />
          <EventAvailableIcon style={{ fontSize: 48 }} color="primary" display="inline-block" />
          <p style={{ textAlign: 'center' }}>No School Closings Over Next 2 Weeks</p>
        </Grid>
      </Grid>
    </>
  );

  return (
    <Box>
      <Grid justify="space-between" container spacing={3}>
        <Grid item>
          <Typography variant="h1" component="h1">
            Upcoming School Closings
          </Typography>
        </Grid>
      </Grid>
      <Card>
        <CardContent>
          <DataTable
            title=""
            columns={columns}
            noHeader
            data={schoolClosings}
            progressPending={isLoading}
            striped
            highlightOnHover
            pointerOnHover
            noDataComponent={<NoSchoolClosings />}
            pagination
          />
        </CardContent>
      </Card>
    </Box>
  );
};

export default UpcomingSchoolClosings;
