/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import { Typography, Grid, Card, CardContent, Button, makeStyles } from '@material-ui/core';
import TextInput from '../../shared/components/form/TextInput';
import SelectInput from '../../shared/components/form/SelectInput';
import { axios } from '../../shared/singletons';
import { GoalsContext } from '../../goals/contexts/GoalsContext';
import { StudentsContext } from '../../students/contexts/StudentsContext';

type GoalNewCardProps = {
  studentId?: string;
};

const useStyles = makeStyles((theme) => ({
  textInput: {
    height: '60px',
    width: '80%',
    margin: '10px 0',
  },
}));

const GoalNewCard: React.FC<GoalNewCardProps> = (props) => {
  const { studentId } = props;
  const { activeGoals, setActiveGoals, setAddGoalShowing } = useContext(GoalsContext);
  const { student } = useContext(StudentsContext);
  const [readyToSave, setReadyToSave] = useState(false);

  const bulkDatesString = JSON.parse(localStorage.getItem('bulkDates'));

  const [formValues, setFormValues] = useState({
    start_date: bulkDatesString?.start_date || '',
    end_date: bulkDatesString?.end_date || '',
    status: 'active',
    description: '',
    referral_service_id: '',
  });

  const classes = useStyles();

  useEffect(() => {
    if (formValues?.referral_service_id && formValues?.start_date && formValues?.end_date) {
      setReadyToSave(true);
    } else {
      setReadyToSave(false);
    }
  }, [formValues]);

  const createGoal = () => {
    axios
      .post(`patients/${studentId}/goals`, { referral_service_id: formValues?.referral_service_id, goal: formValues })
      .then((response) => {
        const newGoal = response?.data;

        const newGoals = [...activeGoals];
        newGoals.push(newGoal);
        setActiveGoals(newGoals);
        setAddGoalShowing(false);
      });
  };

  return (
    <Card>
      <CardContent>
        <Grid item>
          <Typography variant="h2" component="h2">
            New Goal
          </Typography>
        </Grid>
        <br />
        <Grid item xs={8}>
          <SelectInput
            styleOverrides={classes.textInput}
            value={formValues?.referral_service_id}
            label="Service"
            choices={student?.service_options}
            valueChanged={(value: string) => setFormValues({ ...formValues, referral_service_id: value })}
            type="text"
          />
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={3} md={3}>
            <TextInput
              styleOverrides={classes.textInput}
              type="date"
              shrinkLabel
              label="Start Date"
              value={formValues?.start_date}
              valueChanged={(value) => setFormValues({ ...formValues, start_date: value })}
            />
          </Grid>
          <Grid item xs={3} md={3}>
            <TextInput
              styleOverrides={classes.textInput}
              type="date"
              shrinkLabel
              label="End Date"
              value={formValues?.end_date}
              valueChanged={(value) => setFormValues({ ...formValues, end_date: value })}
            />
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={8} style={{ paddingLeft: 25 }}>
              <TextInput
                styleOverrides={classes.textInput}
                value={formValues?.description}
                label="Goal Objectives"
                multiline
                rows={6}
                valueChanged={(value: string) => setFormValues({ ...formValues, description: value })}
              />
            </Grid>
          </Grid>
        </Grid>
        <br />

        <Grid item xs={12} spacing={1}>
          <Grid style={{ marginTop: 80, display: 'flex', justifyContent: 'space-between' }}>
            <Button variant="contained" color="primary" disabled={!readyToSave} onClick={createGoal}>
              Create
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default GoalNewCard;
