import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import DataTable from '../../shared/components/DataTable';
import SelectInput from '../../shared/components/form/SelectInput';
import { SelectOption } from '../../shared/common.model';

import InvoiceQuickStatusChange from '../components/InvoiceQuickStatusChange';
import FinalizeInvoiceModal from '../components/FinalizeInvoiceModal';
import SentInvoiceModal from '../components/SentInvoiceModal';

import { Invoice } from '../model';
import { useStyles } from '../../shared/style/siteWideStyles';
import axios from '../../shared/utils/axios.utils';

const InvoicesListPage = () => {
  const history = useHistory();
  const classes = useStyles();

  let [schoolOptions, setSchoolOptions] = useState<SelectOption[]>([]);
  let [selectedSchoolId, setSelectedSchoolId] = useState<string | null>(null);

  let [yearOptions, setYearOptions] = useState<SelectOption[]>([]);
  let [monthOptions, setMonthOptions] = useState<SelectOption[]>([]);
  let [selectedYearId, setSelectedYearId] = useState<string | null>();
  let [selectedMonthId, setSelectedMonthId] = useState<string | null>();
  let [invoiceForModal, setInvoiceForModal] = useState<Invoice>();
  let [finalizeModalOpen, setFinalizeModalOpen] = useState<boolean>(false);
  let [sentModalOpen, setSentModalOpen] = useState<boolean>(false);

  let [invoices, setInvoices] = useState<Invoice[]>([]);

  const invoiceColumns = [
    { name: 'Title', selector: 'title', sortable: true },
    { name: 'School', selector: 'school_name', sortable: true },
    { name: 'Appointments', selector: 'included_appointment_count', sortable: true, grow: 0.5 },
    {
      name: 'Finalized',
      selector: 'finalized_date',
      sortable: true,
      cell: (row) => (
        <Grid container>
          <Grid item xs={12}>
            {row.finalized_date}
          </Grid>
          <Grid item xs={12}>
            {row.finalized_by}
          </Grid>
        </Grid>
      ),
    },
    {
      name: 'Status',
      selector: 'status',
      sortable: true,
      grow: 0.5,
      cell: (row) => (
        <InvoiceQuickStatusChange
          invoiceToEdit={row}
          setFinalizeModalOpen={setFinalizeModalOpen}
          setSentModalOpen={setSentModalOpen}
          setInvoiceForModal={setInvoiceForModal}
        />
      ),
    },
  ];

  const fetchInvoiceOptions = () => {
    axios
      .get('/invoices/invoices/new', { params: { invoice_year_id: selectedYearId || null } })
      .then((response) => {
        setYearOptions(response.data.year_options);
        setSelectedYearId(response.data.default_invoice_year_id);
        setMonthOptions(response.data.month_options);
        setSelectedMonthId(response.data.default_invoice_month_id);
        setSchoolOptions(response.data.school_options);
      })
      .catch((error) => console.error('Error fetching schoolOptions:', error));
  };

  const fetchInvoices = async () => {
    let response = await axios.get(`/invoices/invoices`, {
      params: { 'ransack[invoice_month_id_eq]': selectedMonthId, 'ransack[payer_id_eq]': selectedSchoolId },
    });
    setInvoices(response.data.result);
  };

  useEffect(() => {
    fetchInvoiceOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedYearId]);

  useEffect(() => {
    fetchInvoices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMonthId, selectedSchoolId]);

  const handleClearPressed = () => {
    setSelectedMonthId(null);
    setSelectedSchoolId(null);
  };

  return (
    <Grid container style={{ padding: '1%' }}>
      <FinalizeInvoiceModal
        finalizeModalOpen={finalizeModalOpen}
        setFinalizeModalOpen={setFinalizeModalOpen}
        invoiceForModal={invoiceForModal}
        fetchInvoices={fetchInvoices}
      />
      <SentInvoiceModal
        sentModalOpen={sentModalOpen}
        setSentModalOpen={setSentModalOpen}
        invoiceForModal={invoiceForModal}
        fetchInvoices={fetchInvoices}
      />
      <Grid item xs={12}>
        <Typography variant="h1">Invoices</Typography>
      </Grid>
      <Grid item xs={2} style={{ marginBottom: 20 }}>
        <SelectInput
          label="Year"
          styleOverrides={classes.textInput}
          value={selectedYearId}
          choices={yearOptions}
          valueChanged={(value) => setSelectedYearId(value)}
        />
      </Grid>
      <Grid item xs={4} style={{ marginBottom: 20 }}>
        <SelectInput
          label="Month"
          styleOverrides={classes.textInput}
          value={selectedMonthId}
          choices={monthOptions}
          valueChanged={(value) => setSelectedMonthId(value)}
        />
      </Grid>
      <Grid item xs={4}>
        <SelectInput
          label="School"
          styleOverrides={classes.textInput}
          value={selectedSchoolId}
          choices={schoolOptions}
          valueChanged={(value) => setSelectedSchoolId(value)}
        />
      </Grid>
      <Grid item xs={2} style={{ marginTop: 13 }} onClick={handleClearPressed}>
        <Button variant="contained">Clear</Button>
      </Grid>
      <Grid item xs={12} style={{ marginBottom: 20 }}>
        <DataTable
          data={invoices}
          columns={invoiceColumns}
          noHeader
          striped
          highlightOnHover
          pointerOnHover
          pagination
          paginationPerPage={25}
          paginationRowsPerPageOptions={[25]}
          onRowClicked={(row) => history.push(`/invoices/${row.id}`)}
        />
      </Grid>
    </Grid>
  );
};

export default InvoicesListPage;
