import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import DeleteModal from '../../shared/components/modal/DeleteModal';
import { AppointmentsContext } from '../contexts/AppointmentsContext';
import { appointmentFormStore } from '../../shared/singletons';
import { useStyles } from '../../shared/style/siteWideStyles';

function DeleteFutureAppointmentsConfirmModal() {
  const history = useHistory();
  const classes = useStyles();

  const { destroyFutureModalOpen, setDestroyFutureModalOpen, appointmentToDelete } = useContext(AppointmentsContext);

  const handleDestroyFutureAppointments = () => {
    appointmentFormStore.deleteFutureAppointments(appointmentToDelete.id, () =>
      history.push(
        `/students/${appointmentToDelete.student_id}/services/${appointmentToDelete.service_id}/appointments`,
      ),
    );
  };

  return (
    <DeleteModal
      isOpen={destroyFutureModalOpen}
      openStatusChanged={setDestroyFutureModalOpen}
      onDelete={handleDestroyFutureAppointments}
    >
      <Typography align="center" className={classes.modalText} variant="h5">
        Are you sure you want to delete THIS AND ALL FUTURE appointments? This action cannot be undone.
      </Typography>
    </DeleteModal>
  );
}

export default DeleteFutureAppointmentsConfirmModal;
