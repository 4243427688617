import React, { FunctionComponent } from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import StudentInformation from '../../students/components/StudentInformation';
import AppointmentInformation from './AppointmentInformation';
import AppointmentHistory from './AppointmentHistory';
import { Appointment } from '../model';

interface AppointmentDetailsTabProps {
  appointment: Appointment;
  appointmentLoading: boolean;
}

const AppointmentDetailsTab: FunctionComponent<AppointmentDetailsTabProps> = (props: AppointmentDetailsTabProps) => {
  const { appointment, appointmentLoading } = props;

  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <AppointmentInformation appointment={appointment} appointmentLoading={appointmentLoading} />
        </Grid>
        <Grid item xs={12} md={6}>
          <StudentInformation student={appointment?.student} studentLoading={appointmentLoading} />
        </Grid>
        <Grid item xs={12}>
          <AppointmentHistory historyItems={appointment.history} appointmentLoading={appointmentLoading} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default AppointmentDetailsTab;
