/* eslint-disable react-hooks/exhaustive-deps */
import React, { FunctionComponent, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { CircularProgress, Box } from '@mui/material';
import NewSessionNote from '../components/NewSessionNote';
import { useStoreObservable } from '../../shared/state/useStoreObservable.hook';
import { SessionNoteState } from '../sessionNote.store';
import { sessionNoteStore } from '../../shared/singletons';
import { SessionNoteRouteParams } from '../model';
import { AppointmentsContext } from '../../appointments/contexts/AppointmentsContext';
import SupervisorSessionNotePage from '../components/SupervisorSessionNotePage';

export type NewSessionNotePageProps = Record<string, unknown>;

const NewSessionNotePage: FunctionComponent<NewSessionNotePageProps> = () => {
  const sessionNoteState = useStoreObservable<SessionNoteState>(sessionNoteStore);
  const { appointment, appointmentLoading } = sessionNoteState;
  const { studentId, appointmentId } = useParams<SessionNoteRouteParams>();
  const { fetchAppointmentChoices } = React.useContext(AppointmentsContext);

  useEffect(() => {
    sessionNoteStore.fetchAppointment(studentId, appointmentId);
    fetchAppointmentChoices(studentId);
  }, [studentId, appointmentId]);

  return appointmentLoading ? (
    <Box sx={{ display: 'inline-block', width: '100%', textAlign: 'center', marginTop: 25 }}>
      <p style={{ fontSize: '18px' }}>Loading appointment...</p>
      <br />
      <CircularProgress />
    </Box>
  ) : (
    <>
      {appointment?.owns_record_personally ? (
        <NewSessionNote appointment={appointment} studentId={studentId} appointmentLoading={appointmentLoading} />
      ) : (
        <SupervisorSessionNotePage appointment={appointment} />
      )}
    </>
  );
};

export default NewSessionNotePage;
