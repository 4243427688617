import { axios, alertStore } from '../shared/singletons';
import { BaseStore } from '../shared/state/base.store';
import { Goal, GoalFormValues, GoalFormErrors, GoalResponse } from './model';
import { API_ENDPOINT } from '../../config/env';
import { SelectOption } from '../shared/common.model';

export class GoalState {
  records: Goal[];
  goal: Goal;
  recordsLoading: boolean;
  goalLoading: boolean;
  isDeleting: boolean;

  formValues: GoalFormValues;
  formErrors: GoalFormErrors;
  formErrorAlertVisible: boolean;
  formStepperIndex: number;
  createModalOpen: boolean;
  editModalOpen: boolean;
  formServiceId: string;

  serviceChoices: SelectOption[];

  static create(props: Partial<GoalState>): GoalState {
    const defaults: GoalState = {
      records: [],
      goal: {},
      recordsLoading: false,
      goalLoading: false,
      isDeleting: false,

      formValues: null,
      formErrors: null,
      formErrorAlertVisible: true,
      formStepperIndex: 0,
      createModalOpen: false,
      editModalOpen: false,
      formServiceId: undefined,

      serviceChoices: [],
    };
    return Object.assign(new GoalState(), defaults, props || {});
  }
}

export class GoalStore extends BaseStore<GoalState> {
  constructor() {
    super(GoalState.create({}));
  }

  public fetchGoal(serviceId: string, goalId: string): void {
    this.setState({ goalLoading: true });

    axios(`${API_ENDPOINT}/referral_services/${serviceId}/goals/${goalId}.json`)
      .then((result) => result?.data?.result ?? [])
      .then((goal) => {
        this.setState({ goal, goalLoading: false });
      })
      .catch(() => {
        this.setState({ goalLoading: false });
      });
  }

  public createGoal(formValues: GoalFormValues, serviceId: string, onCreate: () => void): void {
    axios({
      method: 'post',
      url: `${API_ENDPOINT}/referral_services/${serviceId}/goals.json`,
      data: { goal: formValues },
      headers: { 'Content-Type': 'application/json' },
    })
      .then(() => {
        if (onCreate) {
          onCreate();
        }
        this.setState({ createModalOpen: false, formStepperIndex: 2 });
      })
      .catch((error) => {
        this.setState({
          formStepperIndex: 1, // Don't progress stepper if submit errored
          formErrors: error.response.data,
          formErrorAlertVisible: !!error.response?.data?.base, // The base errors are not tied to a specific field and displayed at the top of the form
        });
      });
  }

  public updateGoal(formValues: GoalFormValues, goalId: string, onUpdate?: () => void): void {
    axios({
      method: 'put',
      url: `${API_ENDPOINT}/goals/${goalId}.json`,
      data: { goal: formValues },
      headers: { 'Content-Type': 'application/json' },
    })
      .then(() => {
        if (onUpdate) {
          onUpdate();
        }
        this.setState({ editModalOpen: false });
      })
      .catch(() => {
        this.setState({ editModalOpen: false });
      });
  }

  public deleteGoal(goalId: string, onDelete: () => void): void {
    this.setState({ isDeleting: true });

    axios
      .delete<string, GoalResponse>(`goals/${goalId}.json`, {
        headers: { 'Content-Type': 'application/json' },
      })
      .then(() => {
        if (onDelete) {
          onDelete();
        }
        this.setState({ isDeleting: false });
        alertStore.alertSuccess('Goal was successfully deleted.');
      })
      .catch(() => {
        this.setState({ isDeleting: false });
        alertStore.alertError('Something went wrong deleting your goal.');
      });
  }

  public setFormValue(formValues: GoalFormValues): void {
    this.setState({ formValues });
  }

  public setServiceId(serviceId: string): void {
    this.setState({ formServiceId: serviceId });
  }

  public setStepperIndex(index: number): void {
    this.setState({ formStepperIndex: index });
  }

  public setModalOpen(isOpen: boolean, serviceId?: string): void {
    if (serviceId) {
      this.setState({ createModalOpen: isOpen, formServiceId: serviceId, formValues: {}, formStepperIndex: 1 });
    } else {
      this.setState({ createModalOpen: isOpen, formValues: {}, formStepperIndex: 0 });
    }
  }

  public setEditModalOpen(isOpen: boolean, goal: Goal): void {
    const formValues = {
      description: goal.objectives,
      start_date: goal.form_start_date,
      end_date: goal.form_end_date,
      status: goal.status,
    };

    this.setState({ editModalOpen: isOpen, formValues, goal });
  }

  public setFormErrorAlertVisibility(isVisible: boolean): void {
    this.setState({ formErrorAlertVisible: isVisible });
  }
}
