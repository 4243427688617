import React, { memo, useCallback, useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import TableCell from './TableCell';
import TableCellCheckbox from './TableCellCheckbox';
import TableCellExpander from './TableCellExpander';
import ExpanderRow from './ExpanderRow';
import { getConditionalStyle } from './util';

const STOP_PROP_TAG = '___react-data-table-allow-propagation___';

const highlightCSS = css<any>`
  &:hover {
    ${(props) => props.highlightOnHover && props.theme.rows.highlightOnHoverStyle};
  }
`;

const pointerCSS = css`
  &:hover {
    cursor: pointer;
  }
`;

const TableRowStyle = styled.div<any>`
  display: flex;
  align-items: stretch;
  align-content: stretch;
  width: 100%;
  box-sizing: border-box;
  ${(props) => props.theme.rows.style};
  ${(props) => props.dense && props.theme.rows.denseStyle};
  ${(props) => props.striped && props.theme.rows.stripedStyle};
  ${(props) => props.highlightOnHover && highlightCSS};
  ${(props) => props.pointerOnHover && pointerCSS};
  ${(props) => props.selected && props.theme.rows.selectedHighlightStyle};
  ${(props) => props.extendedRowStyle};

  @media (max-width: 960px) {
    padding: 15px 0;
    display: grid;
  }
`;

export type TableRowProps = {
  id: string;
  keyField: string;
  columns: any[];
  row: Object;
  onRowClicked: (row: Object, event: Event) => any;
  onRowDoubleClicked: (row: Object, event: Event) => any;
  onRowExpandToggled: (isExpanded: boolean, row: Object) => any;
  defaultExpanded?: boolean;
  defaultExpanderDisabled?: boolean;
  selectableRows: boolean;
  expandableRows: boolean;
  striped: boolean;
  highlightOnHover: boolean;
  pointerOnHover: boolean;
  dense: boolean;
  expandableRowsComponent: any;
  expandableRowsHideExpander: boolean;
  expandOnRowClicked: boolean;
  expandOnRowDoubleClicked: boolean;
  conditionalRowStyles: any[];
  inheritConditionalStyles: any;
  selected: boolean;
  selectableRowsHighlight: boolean;
};

const TableRow: React.FunctionComponent<TableRowProps> = memo((props) => {
  const {
    id,
    keyField,
    columns,
    row,
    onRowClicked,
    onRowDoubleClicked,
    selectableRows,
    expandableRows,
    striped,
    highlightOnHover,
    pointerOnHover,
    dense,
    expandableRowsComponent,
    defaultExpanderDisabled,
    defaultExpanded,
    expandableRowsHideExpander,
    expandOnRowClicked,
    expandOnRowDoubleClicked,
    conditionalRowStyles,
    inheritConditionalStyles,
    onRowExpandToggled,
    selected,
    selectableRowsHighlight,
  } = props;
  const [expanded, setExpanded] = useState(defaultExpanded);
  useEffect(() => {
    setExpanded(defaultExpanded);
  }, [defaultExpanded]);

  const handleExpanded = useCallback(() => {
    setExpanded(!expanded);
    onRowExpandToggled(!expanded, row);
  }, [expanded, onRowExpandToggled, row]);

  const showPointer = pointerOnHover || (expandableRows && (expandOnRowClicked || expandOnRowDoubleClicked));

  const handleRowClick = useCallback(
    (e) => {
      // use event delegation allow events to propagate only when the element with data-tag ___react-data-table-allow-propagation___ is present
      if (
        e?.target &&
        e?.target?.getAttribute &&
        e?.target?.getAttribute('data-tag') === STOP_PROP_TAG &&
        typeof onRowClicked === 'function'
      ) {
        onRowClicked(row, e);

        if (!defaultExpanderDisabled && expandableRows && expandOnRowClicked) {
          handleExpanded();
        }
      }
    },
    [defaultExpanderDisabled, expandOnRowClicked, expandableRows, handleExpanded, onRowClicked, row],
  );

  const handleRowDoubleClick = useCallback(
    (e) => {
      if (e.target && e.target.getAttribute('data-tag') === STOP_PROP_TAG && typeof onRowDoubleClicked === 'function') {
        onRowDoubleClicked(row, e);
        if (!defaultExpanderDisabled && expandableRows && expandOnRowDoubleClicked) {
          handleExpanded();
        }
      }
    },
    [defaultExpanderDisabled, expandOnRowDoubleClicked, expandableRows, handleExpanded, onRowDoubleClicked, row],
  );

  const extendedRowStyle = getConditionalStyle(row, conditionalRowStyles);
  const hightlightSelected = selectableRowsHighlight && selected;
  const inheritStyles = inheritConditionalStyles ? extendedRowStyle : null;

  return (
    <>
      <TableRowStyle
        id={`row-${id}`}
        role="row"
        striped={striped}
        highlightOnHover={highlightOnHover}
        pointerOnHover={!defaultExpanderDisabled && showPointer}
        dense={dense}
        onClick={handleRowClick}
        onDoubleClick={handleRowDoubleClick}
        className="rdt_TableRow"
        extendedRowStyle={extendedRowStyle}
        selected={hightlightSelected}
      >
        {selectableRows && <TableCellCheckbox name={`select-row-${row[keyField]}`} row={row} selected={selected} />}
        {expandableRows && !expandableRowsHideExpander && (
          <TableCellExpander
            expanded={expanded}
            row={row}
            onRowExpandToggled={handleExpanded}
            disabled={defaultExpanderDisabled}
          />
        )}
        {columns.map((column) => (
          <TableCell
            id={`cell-${column.id}-${row[keyField]}`}
            key={`cell-${column.id}-${row[keyField]}`}
            column={column}
            row={row}
          />
        ))}
      </TableRowStyle>
      {expandableRows && expanded && (
        <ExpanderRow key={`expander--${row[keyField]}`} data={row} extendedRowStyle={inheritStyles}>
          {expandableRowsComponent}
        </ExpanderRow>
      )}
    </>
  );
});

TableRow.defaultProps = {
  defaultExpanded: false,
  defaultExpanderDisabled: false,
};

export default TableRow;
