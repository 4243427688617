/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Card, Grid, Button, Accordion, AccordionSummary, AccordionDetails, Typography } from '@material-ui/core';
import { StudentsContext } from '../../students/contexts/StudentsContext';
import { RenewalOptionsContext } from '../../renewals/contexts/RenewalOptionsContext';
import { AnnualUpdatesContext } from '../contexts/AnnualUpdatesContext';
import LoadingSpinner from '../../shared/components/LoadingSpinner';
import attachmentColumns from '../../attachments/constants/columns';
import PageHeader from '../components/PageHeader';
import RenewalEditCard from '../components/RenewalEditCard';
import RenewalNewCard from '../components/RenewalNewCard';
import { axios } from '../../shared/singletons';
import DataTable from '../../shared/components/DataTable';
import { ReferralsContext } from '../../referrals/contexts/ReferralsContext';
import NavButtons from '../components/NavButtons';

const AnnualUpdaterRenewals: React.FC = () => {
  const { studentId } = useParams<Record<string, string>>();

  const { fetchStudent, student, studentsLoading } = useContext(StudentsContext);
  const { handleSelectedFile, optionalAttachedFiles } = React.useContext(ReferralsContext);
  const { fetchChoices } = React.useContext(RenewalOptionsContext);
  const {
    newRenewalButtonShowing,
    setNewRenewalButtonShowing,
    newRenewalCardShowing,
    setNewRenewalCardShowing,
    newestRenewal,
    continueDisabled,
    setContinueDisabled,
  } = React.useContext(AnnualUpdatesContext);

  const [attachments, setAttachments] = useState([]);

  useEffect(() => {
    if (studentId) {
      fetchStudent(studentId);
      axios.get('/attachments', { params: { id: studentId } }).then((response) => {
        setAttachments(response?.data?.result);
      });
    }
  }, [studentId]);

  useEffect(() => {
    fetchChoices(student?.id);
  }, [student]);

  useEffect(() => {
    if (optionalAttachedFiles?.length > 0) {
      setContinueDisabled(true);
    }
  }, [optionalAttachedFiles]);

  const submitAttachments = () => {
    const updatedAttachments = [...attachments];
    const fileGroup = optionalAttachedFiles.map((attachmentList) => {
      return Array.from(attachmentList).forEach((attachment) => {
        const data = new FormData();

        data.append(`file`, attachment, attachment.name);
        data.append('category', 'Student Documentation');

        const dataObject = {
          file_name: attachment.name,
          archived: false,
          category: 'Student Documentation',
          file_url: data,
        };

        updatedAttachments.push(dataObject);

        return axios.post(`/patients/${studentId}/attachments.json`, data);
      });
    });

    Promise.all(fileGroup).then(() => {
      setContinueDisabled(false);
      setAttachments(updatedAttachments);
    });
  };

  return studentsLoading ? (
    <LoadingSpinner />
  ) : (
    <>
      <PageHeader
        title="Renewals"
        message="Edit the renewal date to the Anticipated Duration of Services and Programs date in the IEP. Edit the
        stage to 'Assigned' "
        student={student}
        step={3}
      />
      <br />

      {student?.renewals?.map((renewal, idx) => (
        <React.Fragment key={renewal.id}>
          <RenewalEditCard initialRenewal={renewal} title={`Renewal ${idx + 1} - ${renewal?.service_name}`} />
          <br />
        </React.Fragment>
      ))}

      <br />
      {Object.keys(newestRenewal).length > 0 && (
        <RenewalEditCard
          initialRenewal={newestRenewal}
          title={`Renewal ${student?.renewals.length + 1} - ${newestRenewal?.service_name}`}
        />
      )}
      {newRenewalCardShowing && (
        <Card>
          <RenewalNewCard />
        </Card>
      )}
      {newRenewalButtonShowing && (
        <Grid>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setNewRenewalCardShowing(true);
              setNewRenewalButtonShowing(false);
            }}
          >
            Create New Renewal
          </Button>
        </Grid>
      )}
      <br />

      <Accordion>
        <AccordionSummary>
          <Typography variant="h3" component="h3">
            Attachments
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container>
            <Grid item xs={12}>
              <DataTable
                columns={attachmentColumns}
                noHeader
                data={attachments}
                striped
                highlightOnHover
                pointerOnHover
              />
            </Grid>
            <br />
            <br />
            <Grid item>
              <Typography>Add Attachments</Typography>
            </Grid>
            <Grid item style={{ marginLeft: 7 }}>
              <input type="file" multiple onChange={(value) => handleSelectedFile(value)} />
            </Grid>
            <Button
              variant="contained"
              color="primary"
              disabled={!continueDisabled}
              onClick={() => submitAttachments()}
            >
              Save Attachments
            </Button>
          </Grid>
        </AccordionDetails>
      </Accordion>

      <br />
      <NavButtons activePage="renewals" studentId={studentId} />
    </>
  );
};

export default AnnualUpdaterRenewals;
