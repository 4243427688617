import React, { FunctionComponent } from 'react';
import { Box, MenuItem, Typography, makeStyles } from '@material-ui/core';
import CustomMaterialMenu from '../../shared/components/CustomMaterialMenu';
import { Goal } from '../model';
import DeleteModal from '../../shared/components/modal/DeleteModal';
import { UserContext } from '../../auth/contexts/userContext';
import { goalStore } from '../../shared/singletons';

interface GoalRowMenuProps {
  goal: Goal;
  afterAction: () => void;
}

const useStyles = makeStyles(() => ({
  modalText: {
    fontSize: '20px',
    marginLeft: '20%',
    marginBottom: '20px',
    width: '60%',
  },
}));

const GoalRowMenu: FunctionComponent<GoalRowMenuProps> = (props: GoalRowMenuProps) => {
  const { goal, afterAction } = props;
  const classes = useStyles();

  const { currentUserHasRole } = React.useContext(UserContext);
  const [isDestroyModalOpen, setDestroyModalOpen] = React.useState<boolean>(false);

  const handleDestroyFormSubmit = (): void => {
    goalStore.deleteGoal(goal.id, afterAction);
  };

  return (
    <>
      <DeleteModal
        isOpen={isDestroyModalOpen}
        openStatusChanged={setDestroyModalOpen}
        onDelete={handleDestroyFormSubmit}
      >
        <Typography align="center" className={classes.modalText} variant="h5">
          Are you sure you want to delete this goal? This action cannot be undone.
        </Typography>
      </DeleteModal>
      <CustomMaterialMenu size="small">
        <Box>
          {currentUserHasRole('admin') ? (
            <Box>
              <MenuItem onClick={() => goalStore.setEditModalOpen(true, goal)}>Edit Goal</MenuItem>
              <MenuItem onClick={() => setDestroyModalOpen(true)}>Delete Goal</MenuItem>
            </Box>
          ) : (
            <Box>
              <MenuItem>No Actions Available</MenuItem>
            </Box>
          )}
        </Box>
        <Box />
      </CustomMaterialMenu>
    </>
  );
};

export default GoalRowMenu;
