import React, { FunctionComponent } from 'react';
import Card from '@material-ui/core/Card';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CardContent from '@material-ui/core/CardContent';
import LoadingSpinner from '../../../shared/components/LoadingSpinner';
import EditRenewalTypeModal from './EditRenewalTypeModal';
import NewRenewalTypeModal from './NewRenewalTypeModal';
import RenewalTypeRowMenu from './RenewalTypeRowMenu';
import { IDataTableColumn } from '../../../shared/components/DataTable/DataTable/model';
import DataTable from '../../../shared/components/DataTable';
import { renewalTypeStore } from '../../../shared/singletons';
import { RenewalType } from '../model';

const columns: IDataTableColumn<Record<string, unknown>>[] = [
  {
    name: 'NAME',
    selector: 'name',
    sortable: true,
  },
  {
    cell: (renewalType) => <RenewalTypeRowMenu renewalType={renewalType} />,
    name: 'ACTIONS',
    allowOverflow: true,
    button: true,
    selector: 'actions',
  },
];

type RenewalTypesListProps = { records: RenewalType[]; isLoading: boolean };

const RenewalTypesList: FunctionComponent<RenewalTypesListProps> = (props: RenewalTypesListProps) => {
  const { records = [], isLoading = false } = props;

  return isLoading ? (
    <LoadingSpinner />
  ) : (
    <Box>
      <EditRenewalTypeModal />
      <NewRenewalTypeModal />
      <Grid justify="space-between" container spacing={3}>
        <Grid item>
          <Typography variant="h1" component="h1">
            Renewal Types
          </Typography>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={() => renewalTypeStore.setState({ createModalOpen: true })}
          >
            Create New Renewal Type +
          </Button>
        </Grid>
      </Grid>
      <br />
      <Card>
        <CardContent>
          <DataTable noHeader columns={columns} data={records} striped highlightOnHover pointerOnHover />
        </CardContent>
      </Card>
    </Box>
  );
};

export default RenewalTypesList;
