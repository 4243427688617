// This is abstracted because it is a complex logic flow that is likely to change in the near future.
// It is used in two places: ReferralForm and NewStudentServiceForm

export const buildServiceTypeChoices = (category, serviceTypeChoices) => {
  if (category === 'evaluation') {
    return serviceTypeChoices.filter((choice: any) => ['Evaluation', 'Meetings'].includes(choice.category));
  } else if (category === 'screening') {
    return serviceTypeChoices.filter((choice: any) => ['Screening', 'Meetings'].includes(choice.category));
  } else if (category === 'iep') {
    return serviceTypeChoices.filter((choice: any) =>
      [
        'Indirect Services',
        'Therapy Services',
        'Academic/Paraprofessional Services',
        'Behavior/Counseling Services',
        'IEP Service',
        'IEP and 504 Service',
      ].includes(choice.category),
    );
  }

  if (category === '504') {
    return serviceTypeChoices.filter((choice: any) =>
      [
        'Indirect Services',
        'Therapy Services',
        'Academic/Paraprofessional Services',
        'Behavior/Counseling Services',
        '504 Service',
        'IEP and 504 Service',
      ].includes(choice.category),
    );
  }
};
