import React from 'react';
import { useParams } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import ReferralStudentReviewForm from '../components/ReferralStudentReviewForm';
import { ReferralsContext } from '../contexts/ReferralsContext';
import { ReferralRouteParams } from '../model';

const ReviewReferralStudent: React.FC = () => {
  const { studentId } = useParams<ReferralRouteParams>();
  const { fetchReferralStudent } = React.useContext(ReferralsContext);
  const [studentFetched, setStudentFetched] = React.useState(false);

  React.useEffect(() => {
    if (!studentFetched) {
      setStudentFetched(true);
      fetchReferralStudent(studentId);
    }
  }, [fetchReferralStudent, studentFetched, studentId]);

  return false ? (
    <p>Loading...</p>
  ) : (
    <>
      <Typography variant="h1" component="h1">
        New Referral Details
      </Typography>
      <ReferralStudentReviewForm />
    </>
  );
};

export default ReviewReferralStudent;
