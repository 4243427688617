import { axios } from '../shared/singletons';
import { BaseStore } from '../shared/state/base.store';
import { UserChoicesResponse } from './model';
import { SelectOption } from '../shared/common.model';

export class UserState {
  supervisorChoices: SelectOption[];
  schoolChoices: SelectOption[];
  statusChoices: SelectOption[];
  roleChoices: Record<string, string>;

  static create(props: Partial<UserState>): UserState {
    const defaults: UserState = {
      supervisorChoices: [],
      schoolChoices: [],
      roleChoices: {},
      statusChoices: [
        { label: 'Active', value: 'active' },
        { label: 'Complete', value: 'complete' },
      ],
    };
    return Object.assign(new UserState(), defaults, props || {});
  }
}

export class UserSelectOptionStore extends BaseStore<UserState> {
  constructor() {
    super(UserState.create({}));
  }

  public fetchChoices(): void {
    axios.get<string, UserChoicesResponse>(`users/new.json`).then((r) => {
      const { supervisors: supervisorChoices, schools: schoolChoices, roles: roleChoices } = r.data?.result;

      this.setState({
        supervisorChoices,
        schoolChoices,
        roleChoices,
      });
    });
  }
}
