import React, { FunctionComponent } from 'react';
import ConfirmCancelModal from '../../shared/components/modal/ConfirmCancelModal';
import DuplicateAppointmentsByRenewalDateForm from './DuplicateAppointmentsByRenewalDateForm';
import { axios } from '../../shared/singletons';
import { DuplicateAppointmentsContext } from '../contexts/DuplicateAppointmentsContext';

interface DuplicateAppointmentsByRenewalDateModalProps {
  afterAction: () => void;
}

const DuplicateAppointmentsByRenewalDateModal: FunctionComponent<DuplicateAppointmentsByRenewalDateModalProps> = () => {
  const {
    duplicateAppointmentsByRenewalDateModalOpen,
    setDuplicateAppointmentsByRenewalDateModalOpen,
    formValues,
    fetchRecord,
    appointmentScheduler,
    submittingDuplication,
    setSubmittingDuplication,
  } = React.useContext(DuplicateAppointmentsContext);

  const [submissionError, setSubmissionError] = React.useState('');

  const handleSubmit = async () => {
    setSubmittingDuplication(true);

    try {
      await axios.post<string, any>(
        `users/${appointmentScheduler.owner_id}/create_by_renewal_date.json?user_id=${appointmentScheduler.owner_id}`,
        {
          appointment: { ...formValues, owner_array: ['', appointmentScheduler.owner_id.toString()] },
          headers: { 'Content-Type': 'application/json' },
        },
      );

      await fetchRecord(appointmentScheduler.owner_id, appointmentScheduler.id);
      setDuplicateAppointmentsByRenewalDateModalOpen(false);
    } catch (error) {
      setSubmissionError(error.response.data.base);
    } finally {
      setSubmittingDuplication(false);
    }
  };

  return (
    <ConfirmCancelModal
      isOpen={duplicateAppointmentsByRenewalDateModalOpen}
      title="Duplicate Appointments By Renewal Date"
      confirmLabel={submittingDuplication ? 'Submitting...' : 'Submit'}
      openStatusChanged={(isOpen) => setDuplicateAppointmentsByRenewalDateModalOpen(isOpen)}
      onConfirm={handleSubmit}
      errorMessage={submissionError}
      confirmDisabled={submittingDuplication}
    >
      <DuplicateAppointmentsByRenewalDateForm />
    </ConfirmCancelModal>
  );
};

export default DuplicateAppointmentsByRenewalDateModal;
