import React, { FunctionComponent, useContext } from 'react';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import DomainIcon from '@material-ui/icons/Domain';
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import AccountEditModal from '../components/AccountEditModal';
import { UserContext } from '../../auth/contexts/userContext';
import Breadcrumbs, { BreadcrumbLink } from '../../shared/components/Breadcrumbs';
import { userFormStore } from '../../shared/singletons';

const links: BreadcrumbLink[] = [
  {
    href: '/',
    text: 'HOME',
  },
  {
    href: '/account',
    text: 'ACCOUNT',
  },
  {
    href: '/account',
    text: 'MY ACCOUNT',
  },
];

const AccountEditPage: FunctionComponent = () => {
  const { user, currentUserHasRole } = useContext(UserContext);

  return (
    <Box>
      <AccountEditModal user={user} />
      <Grid justify="space-between" container spacing={3}>
        <Grid item>
          <Typography variant="h1" component="h1">
            My Account
          </Typography>
          <Breadcrumbs links={links} />
          <br />
        </Grid>
        <Grid item>
          <Button variant="contained" color="primary" onClick={() => userFormStore.setAccountEditModalOpen(true, user)}>
            Change Password
          </Button>
        </Grid>
      </Grid>
      <br />
      <Grid justify="space-between" container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="h2" component="h2">
            {`${user.first_name} ${user.last_name}`}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid alignItems="center" container spacing={1}>
            <Grid item>
              <BookmarkBorderIcon style={{ display: 'inherit' }} />
            </Grid>
            <Grid item>
              <Typography variant="body1">{user?.formatted_permission_role}</Typography>
            </Grid>
          </Grid>
        </Grid>
        {user.email && !currentUserHasRole('school_admin') && (
          <Grid item xs={12}>
            <Grid alignItems="center" container spacing={1}>
              <Grid item>
                <MailOutlineIcon style={{ display: 'inherit' }} />
              </Grid>
              <Grid item>
                <Typography variant="body1">{user.email}</Typography>
              </Grid>
            </Grid>
          </Grid>
        )}
        {user.username && (
          <Grid item xs={12}>
            <Grid alignItems="center" container spacing={1}>
              <Grid item>
                <PersonOutlineIcon style={{ display: 'inherit' }} />
              </Grid>
              <Grid item>
                <Typography variant="body1">{user.username}</Typography>
              </Grid>
            </Grid>
          </Grid>
        )}
        {user.school && (
          <Grid item xs={12}>
            <Grid alignItems="center" container spacing={1}>
              <Grid item>
                <DomainIcon style={{ display: 'inherit' }} />
              </Grid>
              <Grid item>
                <Typography variant="body1">{user.school}</Typography>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default AccountEditPage;
