import React, { FunctionComponent, SetStateAction, Dispatch, useState, useEffect } from 'react';
import { Box, Grid, makeStyles } from '@material-ui/core';
import ConfirmCancelModal from '../../shared/components/modal/ConfirmCancelModal';
import SelectInput from '../../shared/components/form/SelectInput';
import TextInput from '../../shared/components/form/TextInput';
import { Appointment } from '../../appointments/model';
import { axios } from '../../shared/singletons';

const useStyles = makeStyles({
  textInput: {
    height: 'auto',
    width: '98%',
    margin: '14px 0',
  },
  baseError: {
    marginBottom: '10px',
  },
});

interface CompletedAppointmentTicketModalProps {
  onSuccess?: () => void;
  appointment?: Appointment;
  modalOpen: boolean;
  setModalOpen: Dispatch<SetStateAction<boolean>>;
}

const CompletedAppointmentTicketModal: FunctionComponent<CompletedAppointmentTicketModalProps> = (props) => {
  const { onSuccess, appointment, modalOpen, setModalOpen } = props;

  const classes = useStyles();

  const [category, setCategory] = useState('');
  const [comment, setComment] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [formValues, setFormValues] = useState<Record<string, string>>({
    status: appointment.status,
    schedule_date: appointment.schedule_date,
    start_time: appointment.start_time,
    end_time: appointment.end_time,
  });

  useEffect(() => {
    setComment('');
  }, []);

  const handleFormSubmit = () => {
    if (comment.trim() === '') {
      setErrorMessage('Please submit a comment explaining the status change.');
    } else {
      setModalOpen(false);

      axios
        .post(`change_requests`, {
          appointment_id: appointment.id,
          comment: comment,
          category: category,
          new_data_values: formValues,
        })
        .then(() => {
          onSuccess();
        });
    }
  };

  return (
    <ConfirmCancelModal
      isOpen={modalOpen}
      title={'Appointment Change Request'}
      onConfirm={handleFormSubmit}
      confirmLabel={'Submit Ticket'}
      openStatusChanged={(isOpen) => setModalOpen(isOpen)}
    >
      <Box p={2}>
        <Grid container>
          <Grid item xs={10}>
            <SelectInput
              value={category}
              styleOverrides={classes.textInput}
              type="text"
              label="Request Category"
              valueChanged={(value: string) => setCategory(value)}
              choices={[
                { label: 'Select an Option', value: 'default' },
                { label: 'Request Change to Appointment Date and/or Time', value: 'provider_time_change' },
                { label: 'Request Change to Appointment Status', value: 'provider_status_change' },
              ]}
            />
          </Grid>
          {category === 'provider_time_change' && (
            <>
              <Grid item xs={10}>
                <TextInput
                  value={formValues?.schedule_date}
                  styleOverrides={classes.textInput}
                  type="date"
                  label="Appointment Date"
                  shrinkLabel
                  valueChanged={(value: string) => setFormValues({ ...formValues, schedule_date: value })}
                />
              </Grid>
              <Grid item xs={10}>
                <TextInput
                  value={formValues?.start_time}
                  styleOverrides={classes.textInput}
                  type="time"
                  label="Start Time"
                  shrinkLabel
                  valueChanged={(value: string) => setFormValues({ ...formValues, start_time: value })}
                />
              </Grid>
              <Grid item xs={10}>
                <TextInput
                  value={formValues?.end_time}
                  styleOverrides={classes.textInput}
                  type="time"
                  label="End Time"
                  shrinkLabel
                  valueChanged={(value: string) => setFormValues({ ...formValues, end_time: value })}
                />
              </Grid>
            </>
          )}
          {(category === 'provider_time_change' || category === 'provider_status_change') && (
            <Grid item xs={10}>
              <TextInput
                value={comment}
                styleOverrides={classes.textInput}
                label={category === 'provider_time_change' ? 'Time Change Explanation' : 'Status Change Explanation'}
                multiline
                rows={8}
                valueChanged={(value: string) => setComment(value)}
              />
            </Grid>
          )}
          {errorMessage !== '' && <p style={{ color: 'red' }}>{errorMessage}</p>}
          <br />
        </Grid>
        <br />
      </Box>
    </ConfirmCancelModal>
  );
};

export default CompletedAppointmentTicketModal;
