import React, { FunctionComponent, useState, useEffect } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Grid,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import SelectInput from '../../shared/components/form/SelectInput';
import TextInput from '../../shared/components/form/TextInput';
import { reportStore } from '../../shared/singletons';
import { SelectOption } from '../../shared/common.model';
import { useStoreObservable } from '../../shared/state/useStoreObservable.hook';
import { axios } from '../../shared/singletons';
import DeleteModal from '../../shared/components/modal/DeleteModal';
import { allPossibleFields } from '../constants/invoiceOptions';
import { School } from '../../schools/model';

// TODO: Temp styles before we line things up with the mockup
const useStyles = makeStyles({
  textInput: {
    height: '60px',
    width: '98%',
    margin: '10px 0',
  },
  baseError: {
    marginBottom: '10px',
  },
  modalText: {
    fontSize: '20px',
    marginLeft: '15%',
    marginBottom: '20px',
    width: '70%',
  },
});

interface InvoiceReportProps {
  school: School;
}

const InvoiceReport: FunctionComponent<InvoiceReportProps> = (props) => {
  const { school } = props;
  const history = useHistory();
  const classes = useStyles();

  const { formValues, isLoading } = useStoreObservable(reportStore);

  const [templateChoices, setTemplateChoices] = useState([]);
  const [invoiceColumns, setInvoiceColumns] = useState([]);
  const [newTemplateName, setNewTemplateName] = useState('');
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [updateFieldOpen, setUpdateFieldOpen] = useState(false);
  const [downloadDisabled, setDownloadDisabled] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [invoiceTemplateId, setInvoiceTemplateId] = useState('1');
  const [invoiceTemplate, setInvoiceTemplate] = useState({ id: '', name: '', invoice_fields: [] });
  const [destroyModalOpen, setDestroyModalOpen] = useState(false);

  useEffect(() => {
    axios.get(`billing_invoice_templates`).then((response) => {
      const choicesResult = response?.data?.result;
      setTemplateChoices(choicesResult);
      setInvoiceTemplate(choicesResult[0]);
      setInvoiceTemplateId(choicesResult[0]?.id);
      setInvoiceColumns(choicesResult[0]?.invoice_fields);
    });
  }, []);

  let validateDate = (dateStr: string) => {
    const date = new Date(dateStr);
    const isValid = !isNaN(date.getTime()) && dateStr === date?.toISOString()?.split('T')?.[0];
    setDownloadDisabled(!isValid);
  };

  const handleFormValueChange = (key: string, value: string) => {
    reportStore.setState({ formValues: { ...formValues, [key]: value } });

    if (key === 'end_date') {
      validateDate(value);
    }
  };

  const handleInvoiceTemplateChange = (value: string) => {
    setInvoiceTemplateId(value);
    axios.get(`billing_invoice_templates/${value}`).then((response) => {
      setInvoiceTemplate(response.data);
      setInvoiceColumns(response.data?.invoice_fields);
    });
  };

  const handleCheckboxChange = (event: any) => {
    if (event.target.checked) {
      setInvoiceColumns([...invoiceColumns, event.target.value.toString()]);
    } else {
      const newColumns = [...invoiceColumns];
      setInvoiceColumns(newColumns.filter((elem) => elem !== event.target.value.toString()));
    }
  };

  const handleReportGeneration = async () => {
    setDownloading(true);
    await reportStore.generateInvoiceReport(school?.id, invoiceColumns);
    setDownloading(false);
  };

  const handleCreateTemplate = async () => {
    axios
      .post(`/billing_invoice_templates`, {
        billing_invoice_template: { name: newTemplateName, invoice_fields: invoiceColumns },
      })
      .then(() => {
        history.push(`/schools/${school?.id}/billings`);
      });
  };

  const handleUpdateTemplate = async () => {
    axios.put(`/billing_invoice_templates/${invoiceTemplateId}`, {
      billing_invoice_template: { name: newTemplateName, invoice_fields: invoiceColumns },
    });
    history.push(`/schools/${school?.id}/billings`);
  };

  const handleDeleteTemplate = async () => {
    axios.delete(`/billing_invoice_templates/${invoiceTemplateId}`).then(() => setDestroyModalOpen(false));
    history.push(`/schools/${school?.id}/billings`);
  };

  return (
    <Box>
      <DeleteModal
        isOpen={destroyModalOpen}
        openStatusChanged={setDestroyModalOpen}
        onDelete={() => handleDeleteTemplate()}
        confirmLabel="Confirm"
      >
        <Typography align="center" className={classes.modalText} variant="h5">
          Are you sure you want to delete this invoice template?
        </Typography>
      </DeleteModal>
      <DeleteModal
        isOpen={createModalOpen}
        openStatusChanged={setCreateModalOpen}
        onDelete={() => handleCreateTemplate()}
        confirmLabel="Create"
        showWarningIcon={false}
      >
        <>
          <Typography align="center" className={classes.modalText} variant="h5">
            You will be able to select the invoice fields by updating the template after the name has been saved.
          </Typography>
          <TextInput
            value={newTemplateName}
            label="Template Name"
            styleOverrides={classes.textInput}
            shrinkLabel
            valueChanged={(value: string) => setNewTemplateName(value)}
          />
        </>
      </DeleteModal>

      <Typography variant="h2" component="h2">
        Select Date Range
      </Typography>
      <Grid container spacing={3} justify="space-between">
        <Grid item>
          <Grid container spacing={3}>
            <Grid item>
              <TextInput
                styleOverrides={classes.textInput}
                value={formValues?.start_date}
                label="Start Date"
                type="date"
                shrinkLabel
                valueChanged={(value: string) => handleFormValueChange('start_date', value)}
              />
            </Grid>
            <Grid item>
              <TextInput
                styleOverrides={classes.textInput}
                value={formValues?.end_date}
                label="End Date"
                type="date"
                shrinkLabel
                valueChanged={(value: string) => handleFormValueChange('end_date', value)}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Button variant="contained" color="primary" onClick={() => setCreateModalOpen(true)}>
            Create New Template
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4} xl={3}>
          <SelectInput
            styleOverrides={classes.textInput}
            type="text"
            label="Invoice Template"
            value={invoiceTemplateId}
            valueChanged={(value: string) => handleInvoiceTemplateChange(value)}
            choices={templateChoices}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <FormControl component="fieldset">
            <FormLabel component="legend">All Invoice Fields</FormLabel>
            <br />
            <FormGroup>
              <Grid container spacing={1}>
                {allPossibleFields.map((school: SelectOption, _index: number) => (
                  <Grid item xs={4}>
                    <FormControlLabel
                      key={school.value}
                      checked={invoiceColumns?.includes(school.value)}
                      control={<Checkbox onChange={handleCheckboxChange} name={school.value} value={school.value} />}
                      label={school.label}
                    />
                  </Grid>
                ))}
              </Grid>
            </FormGroup>
          </FormControl>
        </Grid>
      </Grid>
      <br />
      <Grid item xs={12}>
        <Grid item xs={7}>
          {updateFieldOpen ? (
            <>
              <p>Invoice fields will also be updated with the name.</p>
              <TextInput
                value={newTemplateName}
                label="New Template Name"
                styleOverrides={classes.textInput}
                shrinkLabel
                valueChanged={(value: string) => setNewTemplateName(value)}
              />
            </>
          ) : (
            <>
              <Button
                variant="contained"
                color="primary"
                disabled={downloadDisabled || downloading}
                onClick={handleReportGeneration}
              >
                {isLoading ? 'Generating...' : 'Generate Invoice'}
              </Button>
              <Button
                variant="contained"
                color="primary"
                style={{ marginLeft: 7 }}
                onClick={() => {
                  setNewTemplateName(invoiceTemplate?.name);
                  setUpdateFieldOpen(true);
                }}
              >
                Update this Template
              </Button>
              <Button
                variant="contained"
                color="default"
                style={{ backgroundColor: '#F44F64', color: 'white', marginLeft: 7 }}
                onClick={() => setDestroyModalOpen(true)}
              >
                Delete this Template
              </Button>
            </>
          )}
          {updateFieldOpen && (
            <>
              <Button variant="contained" color="secondary" onClick={() => setUpdateFieldOpen(false)}>
                Cancel
              </Button>
              &emsp;
              <Button variant="contained" color="primary" onClick={handleUpdateTemplate}>
                Update
              </Button>
            </>
          )}
        </Grid>
        <br />
      </Grid>
      {downloadDisabled && (
        <>
          <Box my={2}>
            <Typography>Please enter a valid date before submitting your invoice.</Typography>
          </Box>
        </>
      )}
      {downloading && (
        <>
          <Box my={2}>
            <Typography>Your invoice report is being generated and will be sent to your email momentarily.</Typography>
          </Box>
        </>
      )}
    </Box>
  );
};

export default InvoiceReport;
