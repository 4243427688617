import React from 'react';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import Breadcrumbs, { BreadcrumbLink } from '../../shared/components/Breadcrumbs';

const links: BreadcrumbLink[] = [
  {
    href: '/',
    text: 'HOME',
  },
  {
    href: '/help',
    text: 'HELP',
  },
];

const SchoolUserHelp: React.FC = () => {
  return (
    <Box>
      <Grid justify="space-between" container spacing={3}>
        <Grid item>
          <Typography variant="h1" component="h1">
            Help Page
          </Typography>
          <Breadcrumbs links={links} />
          <br />
        </Grid>
      </Grid>
      <br />
      <Grid item xs={6}>
        <Typography>
          <strong>Have a question about Timeline?</strong>
        </Typography>
        <br />
        <Typography>
          If you need assistance with anything on the Timeline site, please reach out to our support team by sending an
          email to <a href="mailto:services@thehopelearningcenter.com">services@thehopelearningcenter.com</a>. We also
          have a customized user guide that you can download by clicking the button below.
        </Typography>
        <br />

        <br />
        <Button
          variant="contained"
          color="primary"
          target="_blank"
          href="https://timelinecds-production-cdn.s3.amazonaws.com/"
        >
          View User Guide
        </Button>
      </Grid>
    </Box>
  );
};

export default SchoolUserHelp;
