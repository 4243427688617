import React, { FunctionComponent } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { Service } from '../../services/model';
import LoadingSpinner from '../../shared/components/LoadingSpinner';

interface UserServicesProps {
  services: Service[];
  isLoading: boolean;
}

const UserServices: FunctionComponent<UserServicesProps> = (props) => {
  const { services, isLoading } = props;

  return isLoading ? (
    <LoadingSpinner />
  ) : (
    <Card>
      <CardContent>
        <Typography variant="h2" component="h2">
          User Services
        </Typography>
        <br />
        {services?.map((text, index) => (
          <Typography key={index}>{text}</Typography>
        ))}
      </CardContent>
    </Card>
  );
};

export default UserServices;
