import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import { Button, Card, CardContent, Grid, Typography, makeStyles } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import InformationField from '../../shared/components/InformationField';
import LoadingSpinner from '../../shared/components/LoadingSpinner';
import DuplicateAppointmentsModal from './DuplicateAppointmentsModal';
import DuplicateAppointmentsByQuarterModal from './DuplicateAppointmentsByQuarterModal';
import DuplicateAppointmentsByWeekModal from './DuplicateAppointmentsByWeekModal';
import DuplicateAppointmentsByRenewalDateModal from './DuplicateAppointmentsByRenewalDateModal';
import { SchoolsContext } from '../../schools/contexts/SchoolsContext';
import RenewalFormModal from '../../renewals/components/RenewalFormModal';
import DataTable from '../../shared/components/DataTable';
import { IDataTableColumn } from '../../shared/components/DataTable/DataTable/model';
import { ServicesContext } from '../../services/contexts/ServicesContexts';

interface SchoolSummerSchedulingProps {
  initialSchoolId: string;
}

const useStyles = makeStyles({
  textInput: {
    height: 'auto',
    width: 300,
    margin: '0',
  },
  baseError: {
    marginBottom: '10px',
  },
  link: {
    textDecoration: 'none',
  },
});

const SchoolSummerScheduling: FunctionComponent<SchoolSummerSchedulingProps> = (props) => {
  const classes = useStyles();
  const { school, esySchedulingDetails } = React.useContext(SchoolsContext);
  const { updateService } = React.useContext(ServicesContext);

  const hideService = (row) => {
    updateService(
      { esy_scheduling_active: false },
      row.student_id,
      row.id,
      () => (window.location.href = `/schools/${school.id}/esy_scheduling`),
    );
  };

  const columns: IDataTableColumn<any>[] = [
    {
      name: 'Student Name',
      selector: 'student_name',
      sortable: true,
    },
    {
      name: 'Timeline ID',
      selector: 'student_slug',
      sortable: true,
    },
    {
      name: 'Location',
      selector: 'location',
      sortable: true,
    },
    {
      name: 'Provider',
      selector: 'provider',
      sortable: true,
    },
    {
      name: 'ESY Services',
      selector: 'service_name',
      sortable: true,
    },
    {
      name: 'ESY Appointments',
      selector: 'esy_appointment_count',
      sortable: true,
    },
    {
      name: 'Actions',
      selector: 'file_name',
      sortable: true,
      cell: (row) => (
        <>
          {row.esy_appointment_count === 0 && (
            <Button
              variant="contained"
              disableElevation
              color="primary"
              style={{ marginRight: 7 }}
              onClick={() => window.open(`/students/${row.student_id}/services/${row.id}`, '_blank')}
            >
              Schedule
            </Button>
          )}
          <br />
          <Button variant="contained" disableElevation color="primary" onClick={() => hideService(row)}>
            Hide
          </Button>
        </>
      ),
    },
  ];

  return !school?.id ? (
    <LoadingSpinner />
  ) : (
    <Grid container spacing={3}>
      <RenewalFormModal
        onSuccess={() => {
          /* eslint-disable */
          window.location = window.location;
          /* eslint-enable */
        }}
      />
      <DuplicateAppointmentsModal afterAction={() => console.log('')} />
      <DuplicateAppointmentsByQuarterModal afterAction={() => console.log('')} />
      <DuplicateAppointmentsByWeekModal afterAction={() => console.log('')} />
      <DuplicateAppointmentsByRenewalDateModal afterAction={() => console.log('')} />
      {school?.error_message ? (
        <Grid item xs={12}>
          <Link to="/users" className={classes.link}>
            <Grid alignItems="center" container spacing={1}>
              <Grid item>
                <ArrowBackIcon color="primary" style={{ display: 'inherit' }} />
              </Grid>
              <Grid item>
                <Typography color="primary" variant="body1">
                  Back To Users
                </Typography>
              </Grid>
            </Grid>
          </Link>
          <Typography variant="h1" component="h1" align="center" style={{ marginTop: 50 }}>
            {school?.error_message}
          </Typography>
        </Grid>
      ) : (
        <>
          <Grid container justify="space-between" style={{ marginTop: 20 }}>
            <Grid item>
              <Typography variant="h1" component="h1">
                {`Schedule ESY Appointments for ${school?.name}`}
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={6}>
            <Grid item>
              <InformationField label="Student Name" value={school?.name} />
            </Grid>
            <Grid item>
              <InformationField label="Student ID" value={school?.abbreviation} />
            </Grid>
            <Grid item>
              <InformationField label="ESY Start" value={esySchedulingDetails?.esy_start_date} />
            </Grid>
            <Grid item>
              <InformationField label="ESY End" value={esySchedulingDetails?.esy_end_date} />
            </Grid>
          </Grid>
          <Card style={{ marginTop: 30, width: '100%' }}>
            <CardContent>
              <Typography variant="h2" component="h2">
                Unscheduled ESY Services
              </Typography>
              <DataTable
                columns={columns}
                noHeader
                data={esySchedulingDetails?.result?.filter((service) => service?.esy_appointment_count === 0)}
                striped
                highlightOnHover
                pointerOnHover
              />
            </CardContent>
          </Card>
          <Card style={{ marginTop: 30, width: '100%' }}>
            <CardContent>
              <Typography variant="h2" component="h2">
                Scheduled ESY Services
              </Typography>
              <DataTable
                columns={columns}
                noHeader
                data={esySchedulingDetails?.result?.filter((service) => service?.esy_appointment_count > 0)}
                striped
                highlightOnHover
                pointerOnHover
              />
            </CardContent>
          </Card>
        </>
      )}
    </Grid>
  );
};

export default SchoolSummerScheduling;
