import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { XYPlot, Hint, VerticalGridLines, MarkSeries, makeWidthFlexible } from 'react-vis';
import TimelineServiceTooltip from './TimelineServiceTooltip';
import SchoolUserTimelineLabels from './SchoolUserTimelineLabels';
import { dashboardStore } from '../../shared/singletons';
import { useStoreObservable } from '../../shared/state/useStoreObservable.hook';

const SchoolUserTimeline: React.FC = () => {
  const { timelineData } = useStoreObservable(dashboardStore);
  const [selectedServices, setSelectedServices] = React.useState<any>({});
  const FlexibleXYPlot = makeWidthFlexible(XYPlot);

  const findServicesByTimeline = (timelineValue: number | string | Date) => {
    const allServices = timelineData.redServices
      .concat(timelineData.yellowServices)
      .concat(timelineData.greenServices)
      .concat(timelineData.duplicateRedServices)
      .concat(timelineData.duplicateYellowServices)
      .concat(timelineData.duplicateGreenServices);

    return allServices.filter((service) => service.x === timelineValue);
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12} style={{ zIndex: 999 }}>
          <Typography variant="h1" component="h1" align="center">
            Referral Timeline
          </Typography>
          <br />
          <div style={{ position: 'relative', top: 15 }}>
            <FlexibleXYPlot height={50} xDomain={[2, 60]}>
              <VerticalGridLines />
              <MarkSeries
                color="green"
                data={timelineData.greenServices}
                onValueClick={(val) => {
                  setSelectedServices(findServicesByTimeline(val.x));
                }}
              />
              <MarkSeries
                color="yellow"
                data={timelineData.yellowServices}
                onValueClick={(val) => {
                  setSelectedServices(findServicesByTimeline(val.x));
                }}
              />
              <MarkSeries
                color="red"
                data={timelineData.redServices}
                onValueClick={(val) => {
                  setSelectedServices(findServicesByTimeline(val.x));
                }}
              />
              <MarkSeries
                color="green"
                stroke="#424E7A"
                strokeWidth={2}
                data={timelineData.duplicateGreenServices}
                onValueClick={(val) => {
                  setSelectedServices(findServicesByTimeline(val.x));
                }}
              />
              <MarkSeries
                color="yellow"
                stroke="#424E7A"
                strokeWidth={2}
                data={timelineData.duplicateYellowServices}
                onValueClick={(val) => {
                  setSelectedServices(findServicesByTimeline(val.x));
                }}
              />
              <MarkSeries
                color="red"
                stroke="#424E7A"
                strokeWidth={2}
                data={timelineData.duplicateRedServices}
                onValueClick={(val) => {
                  setSelectedServices(findServicesByTimeline(val.x));
                }}
              />
              {(selectedServices?.[0]?.x || selectedServices?.[0]?.x === 0) && (
                <Hint value={selectedServices}>
                  <TimelineServiceTooltip allServices={selectedServices} setAllServices={setSelectedServices} />
                </Hint>
              )}
            </FlexibleXYPlot>
          </div>
        </Grid>
      </Grid>
      <SchoolUserTimelineLabels />
    </>
  );
};

export default SchoolUserTimeline;
