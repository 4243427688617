import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import axios from '../../shared/utils/axios.utils';
import { Location } from '../model';
import { Grid, Typography } from '@material-ui/core';
import { BreadcrumbLink } from '../../shared/components/Breadcrumbs';
import Breadcrumbs from '../../shared/components/Breadcrumbs';
import DataTable from '../../shared/components/DataTable';

interface LocationDetailPageProps {}

const LocationDetailPage: React.FC<LocationDetailPageProps> = () => {
  const history = useHistory();
  const { locationId } = useParams<{ locationId: string }>();

  const [location, setLocation] = useState<Location>({});

  const links: BreadcrumbLink[] = [
    {
      href: '/schools',
      text: 'SCHOOLS',
    },
    {
      href: `/schools/${location.payer_id}`,
      text: location.payer_name,
    },
  ];

  const locationColumns = [
    {
      name: 'TIMELINE ID',
      selector: 'slug',
    },
    {
      name: 'NAME',
      selector: 'name',
    },
  ];

  const fetchLocation = async () => {
    axios
      .get(`/locations/${locationId}`)
      .then((response) => {
        setLocation(response.data.result);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    if (locationId) fetchLocation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationId]);

  return (
    <Grid justify="space-between" container spacing={3}>
      <Grid item>
        <Typography variant="h1" component="h1">
          {location.name}
        </Typography>
        <Breadcrumbs links={links} />
        <br />
        <Typography variant="h2" component="h2">
          Students
        </Typography>
        <DataTable
          data={location.students}
          columns={locationColumns}
          noHeader
          striped
          highlightOnHover
          pointerOnHover
          onRowClicked={(row) => history.push(`/students/${row.id}`)}
        />
      </Grid>
    </Grid>
  );
};

export default LocationDetailPage;
