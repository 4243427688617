import React, { FunctionComponent } from 'react';
import { MenuItem, Typography, makeStyles } from '@material-ui/core';
import CustomMaterialMenu from '../../shared/components/CustomMaterialMenu';
import { ListingFee } from '../model';
import DeleteModal from '../../shared/components/modal/DeleteModal';
import { listingFeeStore } from '../../shared/singletons';

interface ListingFeeRowMenuProps {
  listingFee: ListingFee;
}

const useStyles = makeStyles((theme) => ({
  modalText: {
    fontSize: '20px',
    marginLeft: '20%',
    marginBottom: '20px',
    width: '60%',

    [theme.breakpoints.down('sm')]: {
      marginLeft: '2%',
      width: '96%',
    },
  },
}));

const ListingFeeRowMenu: FunctionComponent<ListingFeeRowMenuProps> = (props: ListingFeeRowMenuProps) => {
  const { listingFee } = props;
  const classes = useStyles();
  const [isDestroyModalOpen, setDestroyModalOpen] = React.useState<boolean>(false);

  const handleDestroyFormSubmit = (): void => {
    listingFeeStore.deleteListingFee(listingFee);
  };

  return (
    <CustomMaterialMenu size="small">
      <DeleteModal
        isOpen={isDestroyModalOpen}
        openStatusChanged={setDestroyModalOpen}
        onDelete={handleDestroyFormSubmit}
      >
        <Typography align="center" className={classes.modalText} variant="h5">
          Are you sure you want to delete this listing fee? This action cannot be undone.
        </Typography>
      </DeleteModal>
      <MenuItem onClick={() => listingFeeStore.setEditModalOpen(true, listingFee.id)}>Edit Listing Fee</MenuItem>
      <MenuItem onClick={() => setDestroyModalOpen(true)}>Destroy Listing Fee</MenuItem>
    </CustomMaterialMenu>
  );
};

export default ListingFeeRowMenu;
