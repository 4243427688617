import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import StepContent from '@material-ui/core/StepContent';
// import DataTable from '../../shared/components/DataTable';
import InvoiceColumnPicker from '../components/InvoiceColumnPicker';
import ServicesToInclude from '../components/ServicesToInclude';
import AppointmentsToReview from '../components/AppointmentsToReview';
import AdditionalLineItems from '../components/AdditionalLineItems';
import IncludedAppointments from '../components/IncludedAppointments';
import InvoiceActivity from '../components/InvoiceActivity';
import DownloadInvoiceExcelFile from '../components/DownloadInvoiceExcelFile';

import { Invoice } from '../model';
import axios from '../../shared/utils/axios.utils';

const InvoiceDetailPage = () => {
  let { invoiceId } = useParams<{ invoiceId: string }>();

  let [activeStep, setActiveStep] = useState(0);
  let [invoice, setInvoice] = useState<Invoice>(null);

  const fetchInvoice = async () => {
    let response = await axios.get(`/invoices/invoices/${invoiceId}`);
    setInvoice(response.data.result);
  };

  useEffect(() => {
    fetchInvoice();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoiceId]);

  // const [invoiceActivity, setInvoiceActivity] = useState([
  //   {
  //     date: '2023-10-15 12:07pm',
  //     user: 'Colin Soleim',
  //     fields_changed: 'Set As Under Review',
  //   },
  //   {
  //     date: '2023-10-15 12:07pm',
  //     user: 'Colin Soleim',
  //     fields_changed: 'Set As Finalized',
  //   },
  //   {
  //     date: '2023-10-15 12:07pm',
  //     user: 'Colin Soleim',
  //     fields_changed: 'Downloaded Invoice',
  //   },
  //   {
  //     date: '2023-10-15 12:07pm',
  //     user: 'Colin Soleim',
  //     fields_changed: 'Deleted Occupational Therapy - Daily Line Item',
  //   },
  //   {
  //     date: '2023-10-15 12:07pm',
  //     user: 'Colin Soleim',
  //     fields_changed: 'Added Occupational Therapy - Daily Line Item',
  //   },
  //   {
  //     date: '2023-10-14 12:04pm',
  //     user: 'Colin Soleim',
  //     fields_changed: 'Assigned Services for Invoice',
  //   },
  //   {
  //     date: '2023-10-15 12:04pm',
  //     user: 'Colin Soleim',
  //     fields_changed: 'Changed Columns to Include',
  //   },
  // ]);

  // const activityColumns = [
  //   { name: 'Date', selector: 'date', sortable: true },
  //   { name: 'User', selector: 'user', sortable: true },
  //   { name: 'Actions', selector: 'fields_changed', sortable: false },
  // ];

  const steps = [
    {
      title: 'Columns to Include',
      component: (props) => (
        <InvoiceColumnPicker
          invoice={invoice}
          setInvoice={setInvoice}
          setActiveStep={setActiveStep}
          stepIndex={props.stepIndex}
        />
      ),
    },
    {
      title: 'Services To Include',
      component: (props) => (
        <ServicesToInclude
          invoice={invoice}
          setInvoice={setInvoice}
          setActiveStep={setActiveStep}
          stepIndex={props.stepIndex}
        />
      ),
    },
    {
      title: 'Appointments To Review',
      component: (props) => (
        <AppointmentsToReview
          invoice={invoice}
          setInvoice={setInvoice}
          setActiveStep={setActiveStep}
          stepIndex={props.stepIndex}
          fetchInvoice={fetchInvoice}
        />
      ),
    },
    {
      title: 'Additional Line Items',
      component: (props) => (
        <AdditionalLineItems
          invoice={invoice}
          setInvoice={setInvoice}
          setActiveStep={setActiveStep}
          stepIndex={props.stepIndex}
        />
      ),
    },
    {
      title: 'Download Excel File',
      component: (props) => (
        <DownloadInvoiceExcelFile
          invoice={invoice}
          setInvoice={setInvoice}
          stepIndex={props.stepIndex}
          fetchInvoice={fetchInvoice}
        />
      ),
    },
  ];

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h1">Invoice Details</Typography>
      </Grid>
      <Grid item xs={12} style={{ marginBottom: 15, display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="h1">{invoice?.title}</Typography>
        <Typography variant="h3">Included Appointments: {invoice?.included_appointment_count}</Typography>
      </Grid>
      <Grid item xs={12} style={{ marginBottom: 15 }}>
        <Stepper nonLinear activeStep={activeStep} orientation="vertical">
          {steps.map((step, index) => (
            <Step key={step.title} completed={invoice?.completed_steps?.includes(index.toString())}>
              <StepButton onClick={() => setActiveStep(index)}>{step.title}</StepButton>
              <StepContent>{step.component({ stepIndex: index })}</StepContent>
            </Step>
          ))}
        </Stepper>
      </Grid>
      <Grid item xs={12} style={{ marginBottom: 10 }}>
        <IncludedAppointments invoiceId={invoiceId} includedAppointmentCount={invoice?.included_appointment_count} />
      </Grid>
      <Grid item xs={12}>
        <InvoiceActivity />
      </Grid>
    </Grid>
  );
};

export default InvoiceDetailPage;
