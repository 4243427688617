import { axios } from '../shared/singletons';
import { BaseRecordStore } from '../shared/state/baseRecord.store';
import { Renewal } from './model';
import { API_ENDPOINT } from '../../config/env';

export class RenewalRecordState {
  records: Renewal[];
  renewal: Renewal;
  recordsLoading: boolean;
  renewalLoading: boolean;
  totalRecords: number;
  filterValues: Record<string, string>;

  static create(props: Partial<RenewalRecordState>): RenewalRecordState {
    const defaults: RenewalRecordState = {
      records: [],
      renewal: {},
      recordsLoading: false,
      renewalLoading: false,
      totalRecords: 0,
      filterValues: {
        'ransack[active_eq]': 'true',
      },
    };
    return Object.assign(new RenewalRecordState(), defaults, props || {});
  }
}

export class RenewalRecordStore extends BaseRecordStore<RenewalRecordState> {
  constructor() {
    super(RenewalRecordState.create(JSON.parse(sessionStorage.getItem('renewalState')) || {}));
  }

  public fetchRecords() {
    // No longer used, but cannot be deleted because it's declared in the base class
  }

  public fetchRecord(studentId: string, renewalId: string): void {
    this.setState({ renewalLoading: true });

    axios(`${API_ENDPOINT}/patients/${studentId}/renewals/${renewalId}.json`)
      .then((result) => result?.data?.result ?? [])
      .then((renewal) => {
        this.setState({ renewal, renewalLoading: false });
      })
      .catch(() => {
        this.setState({ renewalLoading: false });
      });
  }

  public setFilterValues(filterValues: Record<string, string>): void {
    this.setState({ filterValues });
  }

  public saveRenewalFilters(): void {
    const { filterValues } = this.getState();
    const savedRenewalState = {
      filterValues: {
        'ransack[renewal_stage_id_eq]': filterValues['ransack[renewal_stage_id_eq]'],
        'ransack[renewal_type_id_eq]': filterValues['ransack[renewal_type_id_eq]'],
        'ransack[patient_slug_i_cont]': filterValues['ransack[patient_slug_i_cont]'],
        'ransack[patient_payer_id_eq]': filterValues['ransack[patient_payer_id_eq]'],
        'ransack[users_id_eq]': filterValues['ransack[users_id_eq]'],
        'ransack[active_eq]': filterValues['ransack[active_eq]'],
      },
      loadListPageRecords: true,
    };

    sessionStorage.setItem('renewalState', JSON.stringify(savedRenewalState));
  }

  public formattedFilterParams(): string {
    const { filterValues } = this.getState();

    return Object?.keys(filterValues)?.reduce(
      (params, key, _index: number) => `${params}${key}=${filterValues[key]}&` || '',
      '',
    );
  }
}
