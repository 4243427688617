import React, { FunctionComponent } from 'react';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import RenewalInformation from './RenewalInformation';
import TabLabels from '../../shared/components/TabLabels';
import TabLabel from '../../shared/components/TabLabel';
import TabPanel from '../../shared/components/TabPanel';
import LoadingSpinner from '../../shared/components/LoadingSpinner';
import StudentInformation from '../../students/components/StudentInformation';
import RenewalHistory from './RenewalHistory';
import { Renewal } from '../model';
import Breadcrumbs, { BreadcrumbLink } from '../../shared/components/Breadcrumbs';
import { UserContext } from '../../auth/contexts/userContext';
import RenewalCustomActions from './RenewalCustomActions';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
});

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

interface RenewalDetailProps {
  renewal: Renewal;
  renewalLoading: boolean;
}

const tabLabels = ['details', 'actions'];

const RenewalDetail: FunctionComponent<RenewalDetailProps> = (props) => {
  const classes = useStyles();
  const { renewal, renewalLoading } = props;

  const [activeTab, setActiveTab] = React.useState<number>(0);

  const { currentUserHasRole } = React.useContext(UserContext);

  const handleChange = (_event: unknown, newTab: number) => {
    setActiveTab(newTab);
    window.history.pushState('', '', `/students/${renewal.student_id}/renewals/${renewal.id}/${tabLabels[newTab]}`);
  };

  const links = (): BreadcrumbLink[] => {
    if (renewal.referral_service_id) {
      return [
        {
          href: `/students/${renewal?.student_id}`,
          text: `${renewal?.student?.first_name} ${renewal?.student?.last_name}`,
        },
        {
          href: `/students/${renewal?.student_id}/renewals`,
          text: 'RENEWALS',
        },
        {
          href: `/students/${renewal?.student_id}/services/${renewal?.referral_service_id}`,
          text: `${renewal?.service_name}`,
        },
        {
          href: null,
          text: renewal.type,
        },
      ];
    }

    return [
      {
        href: `/students/${renewal?.student_id}`,
        text: `${renewal?.student?.first_name} ${renewal?.student?.last_name}`,
      },
      {
        href: `/students/${renewal?.student_id}/renewals`,
        text: 'RENEWALS',
      },
      {
        href: null,
        text: renewal.type,
      },
    ];
  };

  return renewalLoading ? (
    <LoadingSpinner />
  ) : (
    <div>
      <Grid justify="space-between" container spacing={3}>
        <Grid item>
          <Typography variant="h1" component="h1">
            {renewal.type}
          </Typography>
          <Breadcrumbs links={links()} />
          <br />
        </Grid>
      </Grid>
      <div className={classes.root}>
        <TabLabels variant="scrollable" value={activeTab} onChange={handleChange} aria-label="student tabs">
          <TabLabel label="Details" other={a11yProps(0)} />
          {currentUserHasRole('admin') && <TabLabel label="Actions" other={a11yProps(3)} />}
        </TabLabels>
        <TabPanel value={activeTab} index={0}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <RenewalInformation renewal={renewal} renewalLoading={renewalLoading} />
            </Grid>
            <Grid item xs={12} md={6}>
              <StudentInformation student={renewal?.student} studentLoading={renewalLoading} />
            </Grid>
            {currentUserHasRole('admin') && (
              <Grid item xs={12} md={12}>
                <RenewalHistory historyItems={renewal.history} isLoading={renewalLoading} />
              </Grid>
            )}
          </Grid>
        </TabPanel>
        <TabPanel value={activeTab} index={1}>
          <RenewalCustomActions renewal={renewal} />
        </TabPanel>
      </div>
    </div>
  );
};

export default RenewalDetail;
