import React, { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { Box, Grid, FormGroup, Checkbox, FormControlLabel, Typography, makeStyles } from '@material-ui/core';
import TextInput from '../../shared/components/form/TextInput';
import SelectInput from '../../shared/components/form/SelectInput';
import { studentBaseStore } from '../../shared/singletons';
import { useStoreObservable } from '../../shared/state/useStoreObservable.hook';
import { School, Location } from '../model';

// Import styles

const useStyles = makeStyles({
  textInput: {
    height: '60px',
    width: '80%',
    margin: '10px',
  },
  formTitle: {
    marginLeft: '12px',
  },
});

interface LocationFormProps {
  school: School;
  formErrors: any;
  locationFormValues?: Location;
  setLocationFormValues: Dispatch<SetStateAction<Location>>;
}

const LocationForm: React.FC<LocationFormProps> = (props) => {
  const { school, locationFormValues, setLocationFormValues, formErrors } = props;
  const classes = useStyles();
  // const { formErrors } = useContext(SchoolLocationsContext);
  const { gradeLevelChoices } = useStoreObservable(studentBaseStore);

  const [gradeLevelsSelected, setGradeLevelsSelected] = useState<string[]>(locationFormValues?.grade_level_ids || []);

  useEffect(() => {
    studentBaseStore.fetchChoices();
  }, []);

  useEffect(() => {
    let newFormValues = { ...locationFormValues };
    setLocationFormValues({ ...newFormValues, grade_level_ids: gradeLevelsSelected });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gradeLevelsSelected]);

  const handleGradeLevelCheck = (gradeLevelId: string) => {
    if (gradeLevelsSelected.includes(gradeLevelId)) {
      setGradeLevelsSelected(gradeLevelsSelected.filter((id) => id !== gradeLevelId));
    } else {
      setGradeLevelsSelected([...gradeLevelsSelected, gradeLevelId]);
    }
  };

  return (
    <form noValidate autoComplete="off">
      <Typography className={classes.formTitle} variant="h2" component="h3">
        {school?.name}
      </Typography>
      <Typography className={classes.formTitle} variant="h3" component="h3">
        Location Details
      </Typography>
      <TextInput
        styleOverrides={classes.textInput}
        type="text"
        label="Location Name"
        errorMessage={formErrors?.name}
        value={locationFormValues?.name}
        valueChanged={(value) => setLocationFormValues({ ...locationFormValues, name: value })}
      />
      <SelectInput
        styleOverrides={classes.textInput}
        type="text"
        label="Status"
        choices={[
          { label: 'Active', value: 'active' },
          { label: 'Inactive', value: 'inactive' },
        ]}
        errorMessage={formErrors?.status}
        value={locationFormValues?.status}
        valueChanged={(value) => setLocationFormValues({ ...locationFormValues, status: value })}
      />
      <Typography className={classes.formTitle} variant="h3" component="h3">
        Address
      </Typography>
      <TextInput
        styleOverrides={classes.textInput}
        type="text"
        label="Address 1"
        errorMessage={formErrors?.line1}
        value={locationFormValues?.line1}
        valueChanged={(value) => setLocationFormValues({ ...locationFormValues, line1: value })}
      />
      <TextInput
        styleOverrides={classes.textInput}
        type="text"
        label="Address 2 (optional)"
        errorMessage={formErrors?.line2}
        value={locationFormValues?.line2}
        valueChanged={(value) => setLocationFormValues({ ...locationFormValues, line2: value })}
      />
      <TextInput
        styleOverrides={classes.textInput}
        type="text"
        label="City"
        errorMessage={formErrors?.city}
        value={locationFormValues?.city}
        valueChanged={(value) => setLocationFormValues({ ...locationFormValues, city: value })}
      />
      <TextInput
        styleOverrides={classes.textInput}
        type="text"
        label="State"
        errorMessage={formErrors?.state}
        value={locationFormValues?.state}
        valueChanged={(value) => setLocationFormValues({ ...locationFormValues, state: value })}
      />
      <TextInput
        styleOverrides={classes.textInput}
        type="text"
        label="Zip"
        errorMessage={formErrors?.zip}
        value={locationFormValues?.zip}
        valueChanged={(value: string) => setLocationFormValues({ ...locationFormValues, zip: value })}
      />
      {gradeLevelChoices && (
        <Grid container className={classes.formTitle}>
          <Grid component={Box} item xs={12}>
            <Typography variant="h3" component="h3">
              Grade levels at this location
            </Typography>
            <br />
            <FormGroup row>
              <Grid container>
                {gradeLevelChoices.map((gradeLevel: any) => (
                  <Grid component={Box} key={gradeLevel.label} item xs={6} md={4}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name={gradeLevel.label}
                          value={gradeLevel.value}
                          color="primary"
                          onChange={() => handleGradeLevelCheck(gradeLevel.value)}
                        />
                      }
                      label={gradeLevel.label}
                      checked={locationFormValues?.grade_level_ids?.includes(gradeLevel?.value)}
                    />
                  </Grid>
                ))}
              </Grid>
            </FormGroup>
          </Grid>
        </Grid>
      )}
    </form>
  );
};

export default LocationForm;
