import { axios } from '../../shared/singletons';
import { useHistory } from 'react-router-dom';

const useEmailAlertRequestFunctions = () => {
  const history = useHistory();

  const handleCreateNewEmailAlert = async (formValues) => {
    axios
      .post(`/email_alerts`, formValues)
      .then(() => {
        history.push('/email_alerts');
      })
      .catch((error) => {
        alert(error?.response?.data);
      });
  };

  const handleUpdateEmailAlert = async (emailAlertId, formValues) => {
    axios
      .put(`/email_alerts/${emailAlertId}`, formValues)
      .then(() => {
        history.push('/email_alerts');
      })
      .catch((error) => {
        alert(error?.response?.data);
      });
  };

  const handleSendEmail = async (emailAlertId) => {
    axios
      .post(`/email_alerts/${emailAlertId}/send_now`)
      .then(() => {
        history.push('/email_alerts');
      })
      .catch((error) => {
        alert(error?.response?.data);
      });
  };

  return {
    handleCreateNewEmailAlert,
    handleUpdateEmailAlert,
    handleSendEmail,
  };
};

export default useEmailAlertRequestFunctions;
