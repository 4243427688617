import { axios, alertStore } from '../../shared/singletons';
import { BaseStore } from '../../shared/state/base.store';
import { ReferralStage, ReferralStageFormValues } from './model';
import { API_ENDPOINT } from '../../../config/env';

export class ReferralStageState {
  records: ReferralStage[];
  referralStage: ReferralStage;
  isLoading: boolean;

  formValues: ReferralStageFormValues;
  editModalOpen: boolean;
  createModalOpen: boolean;

  static create(props: Partial<ReferralStageState>): ReferralStageState {
    const defaults: ReferralStageState = {
      records: [],
      referralStage: {},
      isLoading: false,

      formValues: null,
      editModalOpen: false,
      createModalOpen: false,
    };
    return Object.assign(new ReferralStageState(), defaults, props || {});
  }
}

export class ReferralStageStore extends BaseStore<ReferralStageState> {
  constructor() {
    super(ReferralStageState.create({}));
  }

  public fetchRecords(): void {
    this.setState({ isLoading: true });

    axios(`${API_ENDPOINT}/referral_stages.json`)
      .then((result) => result?.data?.result ?? [])
      .then((referralStages) => {
        this.setState({ records: referralStages, isLoading: false });
      })
      .catch(() => {
        this.setState({ isLoading: false });
      });
  }

  public createReferralStage(formValues: ReferralStageFormValues): void {
    axios({
      method: 'post',
      url: `${API_ENDPOINT}/referral_stages.json`,
      data: { referral_stage: formValues },
      headers: { 'Content-Type': 'application/json' },
    })
      .then(() => {
        alertStore.alertSuccess('Successfully created referral stage.');
        this.fetchRecords();
        this.setState({ createModalOpen: false });
      })
      .catch(() => {
        this.setState({ createModalOpen: false });
      });
  }

  public updateReferralStage(editFormValues: ReferralStageFormValues, referralStage: ReferralStage): void {
    axios({
      method: 'put',
      url: `${API_ENDPOINT}/referral_stages/${referralStage.id}.json`,
      data: { referral_stage: editFormValues },
      headers: { 'Content-Type': 'application/json' },
    })
      .then(() => {
        alertStore.alertSuccess('Successfully updated referral stage.');
        this.fetchRecords();
        this.setState({ editModalOpen: false });
      })
      .catch(() => {
        this.setState({ editModalOpen: false });
      });
  }

  public deleteReferralStage(referralStage: ReferralStage, onDelete: () => void): void {
    axios({
      method: 'delete',
      url: `${API_ENDPOINT}/referral_stages/${referralStage.id}.json`,
      headers: { 'Content-Type': 'application/json' },
    })
      .then(() => {
        alertStore.alertSuccess('Successfully deleted referral stage.');
        this.fetchRecords();
        onDelete();
      })
      .catch(() => {
        onDelete();
      });
  }

  public setEditModalOpen(isOpen: boolean, referralStage: ReferralStage): void {
    const formValues = {
      name: referralStage.name,
    };

    this.setState({ editModalOpen: isOpen, formValues, referralStage });
  }

  public setFormValue(formValues: ReferralStageFormValues): void {
    this.setState({ formValues });
  }
}
