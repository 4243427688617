import React, { FunctionComponent } from 'react';
import { CSVLink } from 'react-csv';
import { Box, Button, Grid, Typography, makeStyles } from '@material-ui/core';
import SelectInput from '../../shared/components/form/SelectInput';
import DataTable from '../../shared/components/DataTable';
import { IDataTableColumn } from '../../shared/components/DataTable/DataTable/model';
import { reportStore } from '../../shared/singletons';
import { useStoreObservable } from '../../shared/state/useStoreObservable.hook';

const useStyles = makeStyles({
  textInput: {
    height: '60px',
    width: '98%',
    margin: '10px 0',
  },
  baseError: {
    marginBottom: '10px',
  },
});

const appointmentColumns: IDataTableColumn[] = [
  {
    name: 'STUDENT NAME',
    selector: 'Student Name',
    sortable: true,
  },
  {
    name: 'SCHOOL',
    selector: 'School',
    sortable: true,
  },
  {
    name: 'LOCATION',
    selector: 'Location',
    sortable: true,
  },
  {
    name: 'SERVICE TYPE',
    selector: 'Service Type',
    sortable: true,
  },
  {
    name: 'SCHEDULED DATE',
    selector: 'Scheduled Date',
    sortable: true,
    grow: 1.2,
  },
  {
    name: 'START TIME',
    selector: 'Start Time',
    sortable: true,
    grow: 0.65,
  },
  {
    name: 'END TIME',
    selector: 'End Time',
    sortable: true,
    grow: 0.65,
  },
  {
    name: 'OWNER',
    selector: 'Owner',
    sortable: true,
  },
  {
    name: 'ORIGINAL DATE',
    selector: 'Original Date',
    sortable: true,
    grow: 0.85,
  },
  {
    name: 'UNITS',
    selector: 'Units',
    sortable: true,
    grow: 0.5,
  },
  {
    name: 'STATUS',
    selector: 'Status',
    sortable: true,
  },
];

const InvalidAppointmentsReport: FunctionComponent = () => {
  const classes = useStyles();

  const { formValues, invalidAppointmentRecords, isLoading } = useStoreObservable(reportStore);

  const [downloadDisabled, setDownloadDisabled] = React.useState(true);

  const handleFormValueChange = (key: string, value: string) => {
    setDownloadDisabled(true);
    reportStore.setState({ formValues: { ...formValues, [key]: value } });
  };

  const handleReportGeneration = async () => {
    setDownloadDisabled(true);
    await reportStore.generateInvalidAppointmentsReport();
    setDownloadDisabled(false);
  };

  return (
    <Box>
      <Typography variant="h2" component="h2">
        Select Academic Year
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4} xl={3}>
          <SelectInput
            styleOverrides={classes.textInput}
            type="text"
            label="Academic Year"
            value={'2023-2024'}
            valueChanged={(value: string) => handleFormValueChange('status', value)}
            choices={[{ label: '2023-2024 Academic Year', value: '2023-2024' }]}
          />
        </Grid>
      </Grid>
      <br />
      <Button variant="contained" color="primary" onClick={handleReportGeneration}>
        {isLoading ? 'Generating...' : 'Generate Report'}
      </Button>
      <CSVLink
        data={invalidAppointmentRecords}
        filename={`invalid_appointments_2023-2024.csv`}
        className={`MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary ${
          downloadDisabled ? 'Mui-disabled' : ''
        }`}
        style={{ marginLeft: '8px' }}
      >
        Download Report
      </CSVLink>
      <Box my={4}>
        <DataTable title="" columns={appointmentColumns} noHeader data={invalidAppointmentRecords} />
      </Box>
    </Box>
  );
};

export default InvalidAppointmentsReport;
