import React from 'react';
import { Box, Checkbox, FormControlLabel, Grid, Typography, makeStyles } from '@material-ui/core';
import { listingFeeStore } from '../../shared/singletons';
import TextInput from '../../shared/components/form/TextInput';
import { useStoreObservable } from '../../shared/state/useStoreObservable.hook';
import ConfirmCancelModal from '../../shared/components/modal/ConfirmCancelModal';

const useStyles = makeStyles({
  dateInput: {
    height: '60px',
    width: '98%',
    margin: '10px',
  },
  textInput: {
    height: '60px',
    width: '98%',
  },
  formTitle: {
    marginLeft: '12px',
  },
});

const NewListingFeeModal: React.FC = () => {
  const { createModalOpen, formValues, formSchoolId, serviceFees } = useStoreObservable(listingFeeStore);
  const [serviceFeeAttributes, setServiceFeeAttributes] = React.useState({});
  const [prepAttributes, setPrepAttributes] = React.useState({});
  const classes = useStyles();

  const handleFormValueChange = (key: string, value: string) => {
    listingFeeStore.setState({ formValues: { ...formValues, [key]: value } });
  };

  const handleServiceFeeChange = (fee_type: string, amount: string, service_id: string, fee_index: string) => {
    setServiceFeeAttributes({
      ...serviceFeeAttributes,
      [fee_index]: {
        amount,
        prep: prepAttributes[fee_index]?.value || '0',
        service_id,
        service_fee_type: fee_type,
      },
    });
  };

  const handlePrepChange = (checked: boolean, fee_index: string) => {
    setPrepAttributes({
      ...prepAttributes,
      [fee_index]: {
        value: checked ? '1' : '0',
      },
    });

    setServiceFeeAttributes({
      ...serviceFeeAttributes,
      [fee_index]: {
        amount: serviceFeeAttributes[fee_index]?.amount,
        prep: checked ? '1' : '0',
        service_id: serviceFeeAttributes[fee_index]?.service_id,
        service_fee_type: serviceFeeAttributes[fee_index]?.service_fee_type,
      },
    });
  };

  const handleModalSubmit = () => {
    listingFeeStore
      .setFormValues({
        ...formValues,
        service_fees_attributes: serviceFeeAttributes,
      })
      .then(() => {
        listingFeeStore.createListingFee(listingFeeStore.getState().formValues, formSchoolId);
      });
  };

  React.useEffect(() => {
    listingFeeStore.fetchServiceFees(formSchoolId);
  }, [formSchoolId]);

  return (
    <ConfirmCancelModal
      isOpen={createModalOpen}
      title="Add Listing Fee"
      openStatusChanged={(open: boolean) => listingFeeStore.setState({ createModalOpen: open })}
      cancelLabel="Cancel"
      confirmLabel="Create Listing Fee"
      hasCancelButton
      hasConfirmButton
      width="768px"
      onConfirm={() => handleModalSubmit()}
    >
      <Box>
        <br />
        <Typography variant="h2" component="h2" className={classes.formTitle}>
          Listing Fee Dates
        </Typography>

        <Box>
          <Grid container spacing={0}>
            <Grid item xs={8}>
              <TextInput
                styleOverrides={classes.dateInput}
                type="date"
                shrinkLabel
                label="Start Date"
                value={formValues?.start_date}
                valueChanged={(value) => handleFormValueChange('start_date', value)}
              />
            </Grid>
          </Grid>
          <Grid container spacing={0}>
            <Grid item xs={8}>
              <TextInput
                styleOverrides={classes.dateInput}
                type="date"
                shrinkLabel
                label="End Date"
                value={formValues?.end_date}
                valueChanged={(value) => handleFormValueChange('end_date', value)}
              />
            </Grid>
          </Grid>
          {serviceFees && (
            <Grid container className={classes.formTitle}>
              <Grid component={Box} item xs={12}>
                <Typography variant="h2" component="h2">
                  Service Fees
                </Typography>
                <br />
                {serviceFees.map((fee: any, index: number) => (
                  <>
                    <Typography variant="h3" component="h3">
                      {fee.label}
                    </Typography>
                    <br />
                    <Grid container spacing={3}>
                      <Grid item md={6} xs={12}>
                        <TextInput
                          styleOverrides={classes.textInput}
                          type="text"
                          label="Standard Amount"
                          value={serviceFeeAttributes[index]?.amount}
                          valueChanged={(value) =>
                            handleServiceFeeChange(fee.label, value, fee.value, index.toString())
                          }
                        />
                        {/* <TextInput
                          styleOverrides={classes.textInput}
                          type="text"
                          label={`Late Cancel & No Show Amount`}
                          value={serviceFeeAttributes[index]?.cancel_amount}
                          valueChanged={(value) => null}
                        /> */}
                      </Grid>
                      <Grid item md={3} xs={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              onChange={(e) => handlePrepChange(e.target.checked, index.toString())}
                              name="prep"
                              value="1"
                            />
                          }
                          label="PREP"
                        />
                      </Grid>
                    </Grid>
                    <br />
                  </>
                ))}
              </Grid>
            </Grid>
          )}
        </Box>
        <br />
      </Box>
    </ConfirmCancelModal>
  );
};

export default NewListingFeeModal;
