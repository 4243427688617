/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { axios } from '../../shared/singletons';
import { API_ENDPOINT } from '../../../config/env';
import { Typography, Grid, Button, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import { StudentsContext } from '../../students/contexts/StudentsContext';
import LoadingSpinner from '../../shared/components/LoadingSpinner';
import PageHeader from '../components/PageHeader';
import GoalEditCard from '../components/GoalEditCard';
import GoalNewCard from '../components/GoalNewCard';
import ConfirmCancelModal from '../../shared/components/modal/ConfirmCancelModal';
import { IDataTableColumn } from '../../shared/components/DataTable/DataTable/model';
import DataTable from '../../shared/components/DataTable';
import { Goal } from '../../goals/model';
import { GoalsContext } from '../../goals/contexts/GoalsContext';
import NavButtons from '../components/NavButtons';

const columns: IDataTableColumn<Goal>[] = [
  {
    name: 'Service Type',
    selector: 'service_type',
    sortable: true,
  },
  {
    name: 'START DATE',
    selector: 'start_date',
    sortable: true,
  },
  {
    name: 'END DATE',
    selector: 'end_date',
    sortable: true,
  },
  {
    name: 'OBJECTIVES',
    selector: 'objectives',
    sortable: true,
    wrap: true,
    grow: 5,
    cell: (goal: Goal) => <div style={{ whiteSpace: 'break-spaces' }}>{goal.objectives}</div>,
  },
];

const AnnualUpdaterGoals: React.FC = () => {
  const { studentId } = useParams<Record<string, string>>();
  const { fetchStudent, student, studentsLoading } = useContext(StudentsContext);
  const {
    activeGoals,
    setActiveGoals,
    inactiveGoals,
    setInactiveGoals,
    addGoalShowing,
    setAddGoalShowing,
  } = useContext(GoalsContext);

  const [modalOpen, setModalOpen] = useState(false);
  // const [formValues, setFormValues] = useState({ start_date: '2023-08-25', end_date: '2024-05-30' });

  useEffect(() => {
    if (studentId) {
      fetchStudent(studentId);
    }
  }, [studentId]);

  useEffect(() => {
    if (student?.goals) {
      const updatedActiveGoals = student?.goals?.filter((goal) => {
        return goal.status !== 'inactive';
      });
      const updatedInactiveGoals = student?.goals?.filter((goal) => {
        return goal.status === 'inactive';
      });

      setActiveGoals(updatedActiveGoals);
      setInactiveGoals(updatedInactiveGoals);
    }
  }, [student]);

  const handleSetAllInactive = () => {
    const updatedGoalData = activeGoals?.map((goal, idx) => {
      return axios.put(`${API_ENDPOINT}/goals/${goal.id}.json`, { goal: { status: 'inactive' } });
    });

    Promise.all(updatedGoalData).then(() => {
      setModalOpen(false);
      setActiveGoals([]);
      setInactiveGoals([...inactiveGoals, ...activeGoals]);
    });
  };

  return studentsLoading ? (
    <LoadingSpinner />
  ) : (
    <>
      <ConfirmCancelModal
        title="Set All Goals Inactive"
        cancelLabel="Cancel"
        confirmLabel="Inactivate All"
        height="300px"
        width="768px"
        hasCancelButton
        hasConfirmButton
        isOpen={modalOpen}
        onConfirm={handleSetAllInactive}
        openStatusChanged={(isOpen) => setModalOpen(false)}
      >
        <Typography variant="h2">Update the status for all goals to "Inactive" ?</Typography>
      </ConfirmCancelModal>

      <PageHeader
        title="Goals"
        message="If applicable, old goals should be archived. Change the End Date to the day before the implementation
                of the new IEP. Then change the status to 'Inactive' "
        student={student}
        step={2}
      />
      <br />
      <Button variant="contained" style={{ marginBottom: 20 }} color="primary" onClick={() => setModalOpen(true)}>
        Set All Goals To Inactive
      </Button>

      <br />
      {activeGoals?.map((goal, idx) => (
        <>
          <GoalEditCard idx={idx} goalId={goal?.id} />
          <br />
        </>
      ))}
      <br />

      {addGoalShowing ? (
        <GoalNewCard studentId={student?.id} />
      ) : (
        <Button variant="contained" color="primary" onClick={() => setAddGoalShowing(true)}>
          Add Goal
        </Button>
      )}
      <br />

      <Grid style={{ marginTop: 30 }}>
        <Accordion>
          <AccordionSummary>
            <Typography variant="h3" component="h3">
              {inactiveGoals?.length} Inactive {inactiveGoals?.length === 1 ? 'Goal' : 'Goals'}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <DataTable
              title=""
              columns={columns}
              noHeader
              data={inactiveGoals}
              striped
              highlightOnHover
              pointerOnHover
            />
          </AccordionDetails>
        </Accordion>
      </Grid>

      <br />
      <NavButtons activePage="goals" studentId={studentId} />
    </>
  );
};

export default AnnualUpdaterGoals;
