import React from 'react';
import { Link } from '@material-ui/core';
import { Doughnut } from 'react-chartjs-2';

interface SchoolUserRadialChartProps {
  data: number[];
  label: string;
  labelNumber: string;
  link: string;
  chartSize: string;
}

const SchoolUserRadialChart: React.FC<SchoolUserRadialChartProps> = (props) => {
  const { data, label, labelNumber, link } = props;

  const referralDonutData = {
    datasets: [
      {
        backgroundColor: ['#424E7A', '#fff'],
        data,
      },
    ],
  };

  return (
    <>
      <Doughnut
        data={referralDonutData}
        width={600}
        height={300}
        options={{
          aspectRatio: 2,
          width: 600,
          maintainAspectRatio: false,
          responsive: false,
          cutoutPercentage: 90,
          circumference: Math.PI,
          rotation: Math.PI,
          tooltips: { enabled: false },
        }}
      />
      <h4 style={{ textAlign: 'center', position: 'relative', top: -105, fontSize: 36, lineHeight: '40px' }}>
        <Link href={link}>
          {labelNumber}
          <br />
          {label}
        </Link>
      </h4>
    </>
  );
};

export default SchoolUserRadialChart;
