/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import { Button, Grid, Typography, Card } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import InformationField from '../../shared/components/InformationField';
import { StudentsContext } from '../contexts/StudentsContext';
import { useHistory } from 'react-router-dom';

const StudentDeletePage: React.FunctionComponent = () => {
  const { studentId } = useParams<Record<string, string>>();
  const [studentFetched, setStudentFetched] = useState(false);
  const { student, fetchStudent, studentsLoading, deleteStudent } = useContext(StudentsContext);
  const history = useHistory();

  useEffect(() => {
    if (!studentFetched) {
      fetchStudent(studentId);
      setStudentFetched(true);
    }
  }, [studentFetched, studentId]);

  return studentsLoading ? (
    <p>Loading...</p>
  ) : (
    <>
      <Typography variant="h1" component="h1">
        {`Delete ${student.first_name} ${student.last_name}`}
      </Typography>
      <Grid container flex-direction="column">
        <Grid item xs={3}>
          <InformationField label="Timeline ID" value={student.slug} />
        </Grid>
        <Grid item xs={3}>
          <InformationField label="Student Name" value={`${student.first_name} ${student.last_name}`} />
        </Grid>
        <Grid item xs={3}>
          <InformationField label="School Name" value={student.school} />
        </Grid>
      </Grid>
      <br />
      <Card>
        <Grid container>
          <Grid style={{ padding: 15 }}>
            <Grid xs={12} style={{ display: 'flex', justifyContent: 'flex-start' }}>
              <p>This will permanently delete the student from the Timeline site</p>
            </Grid>
            <Grid xs={12} style={{ display: 'flex', justifyContent: 'flex-start' }}>
              <p>This cannot be undone</p>
            </Grid>
            <Grid xs={12} style={{ display: 'flex', justifyContent: 'flex-start' }}>
              <p>In most cases, students should be discharged instead of deleted</p>
            </Grid>
          </Grid>
          <br />
          <Grid xs={12} style={{ padding: 15 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => history.push(`/students/${student.id}/discharge`)}
            >
              Go to Discharge Page
            </Button>
          </Grid>

          <Grid xs={12} style={{ padding: 15 }}>
            <Button
              variant="contained"
              style={{ backgroundColor: '#F44F64' }}
              color="primary"
              onClick={() => deleteStudent(student.id)}
            >
              Delete Student
            </Button>
          </Grid>
        </Grid>
      </Card>
    </>
  );
};

export default StudentDeletePage;
