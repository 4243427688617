import React from 'react';
import { IDataTableColumn } from '../../shared/components/DataTable/DataTable/model';
import { Attachment } from '../model';

const columns: IDataTableColumn<Attachment>[] = [
  {
    name: 'FILE NAME',
    selector: 'file_name',
    sortable: true,
    cell: (row) => (
      <a target="_blank" rel="noopener noreferrer" href={row.file_url}>
        {row.file_name}
      </a>
    ),
  },
  {
    name: 'FILE TYPE',
    selector: 'file_name',
    sortable: true,
    cell: (row) => <p>Student Documentation</p>,
  },
  {
    name: 'DATE CREATED',
    selector: 'date_created',
    sortable: true,
  },
];

export default columns;
