import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutlineRounded';
import { BaseModalProps } from './modal.model';
import ConfirmCancelModal from './ConfirmCancelModal';
import { Colors } from '../../style/colors';

interface DeleteModalProps extends BaseModalProps {
  onDelete?: () => void;
  confirmLabel?: string;
  showWarningIcon?: boolean;
  confirmDisabled?: boolean;
}

const useStyles = makeStyles((theme) => ({
  errorIcon: {
    color: Colors.Red,
    fontSize: '66px',
    marginBottom: '20px',
  },
  modalText: {
    fontSize: '20px',
    marginLeft: '20%',
    marginBottom: '20px',
    width: '60%',

    [theme.breakpoints.down('sm')]: {
      marginLeft: '2%',
      width: '96%',
    },
  },
}));

const DeleteModalWrapper = styled.div``;

const ModalContent = styled.div``;
const ChildrenContainer = styled.div``;

const DeleteModal: FunctionComponent<DeleteModalProps> = (props: DeleteModalProps) => {
  const {
    isOpen,
    children,
    showWarningIcon = true,
    onDelete = () => null,
    openStatusChanged = (isOpen: boolean) => null,
    confirmLabel = 'Delete',
    height = '350px',
    confirmDisabled = false,
  } = props;

  const classes = useStyles();

  return (
    <DeleteModalWrapper>
      <ConfirmCancelModal
        isOpen={isOpen}
        openStatusChanged={openStatusChanged}
        cancelLabel="Cancel"
        confirmLabel={confirmLabel}
        width="768px"
        height={height}
        onConfirm={onDelete}
        onCancel={() => openStatusChanged(false)}
        hasTitleBar={false}
        hasFooterBar={false}
        hasCancelButton
        hasConfirmButton
        confirmDisabled={confirmDisabled}
      >
        <ModalContent>
          {showWarningIcon && <ErrorOutlineIcon className={classes.errorIcon} />}
          <ChildrenContainer>{children}</ChildrenContainer>
        </ModalContent>
      </ConfirmCancelModal>
    </DeleteModalWrapper>
  );
};

export default DeleteModal;
