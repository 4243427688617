import React, { FunctionComponent } from 'react';
import Card from '@material-ui/core/Card';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CardContent from '@material-ui/core/CardContent';
import LoadingSpinner from '../../../shared/components/LoadingSpinner';
import EditListingServiceModal from './EditListingServiceModal';
import ServiceSubcategoryRowMenu from './ServiceSubcategoryRowMenu';
import { IDataTableColumn } from '../../../shared/components/DataTable/DataTable/model';
import DataTable from '../../../shared/components/DataTable';
import { ListingService } from '../model';

const columns: IDataTableColumn<Record<string, unknown>>[] = [
  {
    name: 'CATEGORY',
    selector: 'parent_category_name',
    sortable: true,
  },
  {
    name: 'SUBCATEGORY NAME',
    selector: 'name',
    sortable: true,
  },
  {
    name: 'INCLUDED SERVICES',
    selector: 'included_services',
    sortable: true,
    grow: 2,
  },
  {
    name: 'STUDENT SERVICE COUNT',
    selector: 'active_service_count',
    sortable: true,
  },
  {
    name: 'REFERRAL SERVICE COUNT',
    selector: 'active_referral_count',
    sortable: true,
  },
  {
    name: 'STATUS',
    selector: 'status',
    sortable: true,
    cell: (row: Record<string, string>) => (
      <div
        style={{
          fontWeight: 700,
          textTransform: 'uppercase',
          color: row.status === 'active' ? 'green' : 'red',
        }}
      >
        {row.status}
      </div>
    ),
  },
  {
    cell: (listingService) => <ServiceSubcategoryRowMenu listingService={listingService} />,
    name: 'ACTIONS',
    allowOverflow: true,
    button: true,
    selector: 'actions',
  },
];

type ListingServicesListProps = { records: ListingService[]; isLoading: boolean };

const ListingServicesList: FunctionComponent<ListingServicesListProps> = (props: ListingServicesListProps) => {
  const { records = [], isLoading = false } = props;

  return isLoading ? (
    <LoadingSpinner />
  ) : (
    <Box>
      <EditListingServiceModal />
      <Grid justify="space-between" container spacing={3}>
        <Grid item>
          <Typography variant="h1" component="h1">
            Listing Service Subcategories
          </Typography>
        </Grid>
      </Grid>
      <br />
      <Card>
        <CardContent>
          <DataTable noHeader columns={columns} data={records} striped highlightOnHover />
        </CardContent>
      </Card>
    </Box>
  );
};

export default ListingServicesList;
