/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { CircularProgress, Box } from '@mui/material';
import { axios } from '../../shared/singletons';
import RescheduleAllFutureAppointmentsForm from '../components/RescheduleAllFutureAppointmentsForm';
import RescheduleAppointmentPreview from '../components/RescheduleAppointmentPreview';
import { AppointmentRouteParams } from '../model';
import { AppointmentsContext } from '../contexts/AppointmentsContext';

const AppointmentSchedulerPage: FC = () => {
  let { studentId, appointmentId } = useParams<AppointmentRouteParams>();
  let { fetchAppointment, appointmentsLoading } = useContext(AppointmentsContext);

  let [dayOfWeekOptions, setDayOfWeekOptions] = useState([]);
  let [siblingCounter, setSiblingCounter] = useState(1);
  let [showPreview, setShowPreview] = useState(false);

  useEffect(() => {
    axios
      .get(`/bulk_appointments/${appointmentId}/edit`)
      .then((response) => {
        setDayOfWeekOptions(response.data.day_of_week_options);
        setSiblingCounter(response.data.sibling_counter);
      })
      .catch((error) => {
        console.error('Error fetching day of week options:', error);
      });
  }, []);

  useEffect(() => {
    fetchAppointment(studentId, appointmentId);
  }, []);

  return appointmentsLoading ? (
    <Box sx={{ display: 'inline-block', width: '100%', textAlign: 'center', marginTop: 25 }}>
      <p style={{ fontSize: '18px' }}>Loading appointment...</p>
      <br />
      <CircularProgress />
    </Box>
  ) : (
    <>
      {showPreview ? (
        <RescheduleAppointmentPreview setShowPreview={setShowPreview} />
      ) : (
        <RescheduleAllFutureAppointmentsForm
          onSuccess={() => setShowPreview(true)}
          dayOfWeekOptions={dayOfWeekOptions}
          siblingCounter={siblingCounter}
        />
      )}
    </>
  );
};

export default AppointmentSchedulerPage;
