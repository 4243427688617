import React from 'react';
import { Service, ServiceFormValues, ServiceResponse, ServiceFormErrors, ServiceChoicesResponse } from '../model';
import { axios, alertStore } from '../../shared/singletons';
import { API_ENDPOINT } from '../../../config/env';
import { SelectOption } from '../../shared/common.model';
import { today } from '../../shared/utils/date.utils';
import { Colors } from '../../shared/style/colors';

// TODO: Fix the spelling error in the name of this file

interface ServicesContextInterface {
  fetchServices?: (params: Record<string, string>, page?: number, sort?: string) => Promise<void>;
  services?: Service[];
  servicesDownload?: Service[];

  fetchReferrals?: (params: Record<string, string>, page?: number, sort?: string) => Promise<void>;

  setServices?: React.Dispatch<React.SetStateAction<Service[]>>;
  totalServices?: number;

  servicesLoading?: boolean;
  serviceSubmitting?: boolean;

  createOrUpdateService?: (
    formValues: ServiceFormValues,
    studentId: string,
    onSuccess?: () => void,
    serviceId?: string,
    attachedFiles?: FileList[],
  ) => Promise<void>;

  updateService?: (
    formValues: ServiceFormValues,
    studentId: string,
    serviceId?: string,
    onSuccess?: () => void,
  ) => Promise<void>;

  formErrors?: ServiceFormErrors;
  formErrorAlertVisible?: boolean;
  setFormErrorAlertVisible?: React.Dispatch<React.SetStateAction<boolean>>;
  fetchChoices?: () => Promise<void>;
  schoolChoices?: SelectOption[];
  phaseChoices?: SelectOption[];
  serviceTypeChoices?: SelectOption[];
  stageChoices?: SelectOption[];
  ownerChoices?: SelectOption[];
  activeOwnerChoices?: SelectOption[];
  frequencyChoices?: SelectOption[];
  statusChoices?: SelectOption[];
  phaseColors?: Record<string, string>;
  addOwnerToServices?: (userId: string, services: string[]) => Promise<void>;
  servicesChecked?: String[];
  setServicesChecked?: React.Dispatch<React.SetStateAction<String[]>>;

  filterValues?: Record<string, string>;
  setFilterValues?: React.Dispatch<React.SetStateAction<Record<string, string>>>;

  sortValue?: string;
  setSortValue?: React.Dispatch<React.SetStateAction<string>>;
}

const ServicesContext = React.createContext<ServicesContextInterface>({});
const ServicesContextConsumer = ServicesContext.Consumer;

const ServicesContextProvider: React.FC = ({ children }) => {
  const [services, setServices] = React.useState<Service[]>([]);
  const [servicesDownload, setServicesDownload] = React.useState<Service[]>([]);
  const [totalServices, setTotalServices] = React.useState<number>(0);
  const [servicesLoading, setServicesLoading] = React.useState(false);
  const [serviceSubmitting, setServiceSubmitting] = React.useState(false);
  const [formErrors, setFormErrors] = React.useState<ServiceFormErrors>({});
  const [formErrorAlertVisible, setFormErrorAlertVisible] = React.useState(false);
  const [schoolChoices, setSchoolChoices] = React.useState<SelectOption[]>([]);
  const [phaseChoices, setPhaseChoices] = React.useState<SelectOption[]>([]);
  const [serviceTypeChoices, setServiceTypeChoices] = React.useState<SelectOption[]>([]);
  const [stageChoices, setStageChoices] = React.useState<SelectOption[]>([]);
  const [ownerChoices, setOwnerChoices] = React.useState<SelectOption[]>([]);
  const [activeOwnerChoices, setActiveOwnerChoices] = React.useState<SelectOption[]>([]);
  const [frequencyChoices, setFrequencyChoices] = React.useState<SelectOption[]>([]);
  const [servicesChecked, setServicesChecked] = React.useState<String[]>([]);
  const [sortValue, setSortValue] = React.useState('timeline desc');

  const phaseColors = {
    referral: Colors.PurpleThree,
    student: Colors.Success,
    patient: Colors.Success,
    cancelled: Colors.Red,
    on_hold: Colors.Orange,
  };

  const [statusChoices] = React.useState([
    { label: 'Active', value: 'active' },
    { label: 'Complete', value: 'complete' },
  ]);

  const [filterValues, setFilterValues] = React.useState({});

  const fetchServices = async (filterParams = filterValues, page = 1, sort = sortValue) => {
    setServicesLoading(true);

    saveServiceFilters();
    const combinedParams = { ...filterParams, page: page, per: 10, sort: sort };
    axios(`referral_services.json?`, { params: combinedParams })
      .then((response) => {
        setTotalServices(response?.data?.total_records);
        setServicesDownload(response?.data?.referral_services_download);
        setServices(response?.data?.result ?? []);
        setFilterValues(filterParams);
        setServicesLoading(false);
        return response?.data?.result ?? [];
      })
      .catch(() => {
        setServicesLoading(false);
      });
  };

  const fetchReferrals = async (filterParams = filterValues, page = 1, sort = sortValue) => {
    setServicesLoading(true);

    saveServiceFilters();
    const combinedParams = { ...filterParams, page: page, per: 10, sort: sort };
    axios(`referrals.json?`, { params: combinedParams })
      .then((response) => {
        setTotalServices(response?.data?.total_records);
        setServicesDownload(response?.data?.referral_services_download);
        setServices(response?.data?.result ?? []);
        setFilterValues(filterParams);
        setServicesLoading(false);
        return response?.data?.result ?? [];
      })
      .catch(() => {
        setServicesLoading(false);
      });
  };

  const createOrUpdateService = async (
    formValues: ServiceFormValues,
    studentId: string,
    onSuccess?: () => void,
    serviceId?: string,
    attachedFiles?: FileList[],
  ) => {
    try {
      setServiceSubmitting(true);

      await axios[serviceId ? 'put' : 'post']<string, ServiceResponse>(
        `${API_ENDPOINT}/patients/${studentId}/referral_services${serviceId ? `/${serviceId}` : ''}.json`,
        {
          referral_service: formValues,
          headers: { 'Content-Type': 'application/json' },
        },
      ).then((response) => {
        attachedFiles = attachedFiles.filter((file) => file !== undefined);

        if (attachedFiles.length > 0) {
          const responses = attachedFiles.map((attachmentList) => {
            return Array.from(attachmentList).map((attachment) => {
              const data = new FormData();

              data.append(`file`, attachment, attachment.name);
              data.append('category', 'other');

              return axios.post(`/patients/${studentId}/attachments.json`, data);
            });
          });

          Promise.all(responses).then(() => {
            setTimeout(() => {
              setServiceSubmitting(false);
              onSuccess();
            }, 3000);
          });
        } else {
          setServiceSubmitting(false);
          onSuccess();
        }
      });
    } catch (error) {
      setServiceSubmitting(false);
      setFormErrors(error.response?.data);
      setFormErrorAlertVisible(true);
    }
  };

  const updateService = async (
    formValues: ServiceFormValues,
    studentId: string,
    serviceId?: string,
    onSuccess?: () => void,
  ) => {
    try {
      setServiceSubmitting(true);

      await axios
        .put<string, ServiceResponse>(`${API_ENDPOINT}/patients/${studentId}/referral_services/${serviceId}.json`, {
          referral_service: formValues,
          headers: { 'Content-Type': 'application/json' },
        })
        .then((response) => {
          setServiceSubmitting(false);
          onSuccess();
        });
    } catch (error) {
      setServiceSubmitting(false);
      setFormErrors(error.response?.data);
      setFormErrorAlertVisible(true);
    }
  };

  const fetchChoices = async () => {
    axios
      .get<string, ServiceChoicesResponse>(`${API_ENDPOINT}/referral_services/new.json`)
      .then((r: ServiceChoicesResponse) => {
        const {
          phase_options,
          service_types,
          stage_options,
          owners,
          active_owners,
          all_schools,
          frequency_options,
        } = r.data?.result;

        setSchoolChoices(all_schools);
        setPhaseChoices(phase_options);
        setServiceTypeChoices(service_types);
        setStageChoices(stage_options);
        setOwnerChoices(owners);
        setActiveOwnerChoices(active_owners);
        setFrequencyChoices(frequency_options);
      });
  };

  const addOwnerToServices = async (userId: string, serviceIds: string[]) => {
    await axios
      .put(`/case_load_management/0.json`, {
        user_id: userId,
        services: serviceIds,
        headers: { 'Content-Type': 'application/json' },
      })
      .then((result) => {
        return result?.data?.referral_services ?? [];
      })
      .then((records) => {
        setServices(records);
        setServicesLoading(false);
      })
      .catch(() => {
        setServicesLoading(false);
        alertStore.alertError('Unable to add owner, please try again');
      });
  };

  const saveServiceFilters = () => {
    const savedServiceState = {
      filterValues: {
        'ransack[phase_eq]': filterValues['ransack[phase_eq]'],
        'ransack[patient_slug_i_cont]': filterValues['ransack[patient_slug_i_cont]'],
        'ransack[patient_last_name_i_cont]': filterValues['ransack[patient_last_name_i_cont]'],
        'ransack[payer_id_eq]': filterValues['ransack[payer_id_eq]'],
        'ransack[service_id_eq]': filterValues['ransack[service_id_eq]'],
        'ransack[users_id_eq]': filterValues['ransack[users_id_eq]'],
        'ransack[referral_stage_id_eq]': filterValues['ransack[referral_stage_id_eq]'],
        'ransack[status_eq]': filterValues['ransack[status_eq]'],

        'appointment_ransack[start_time_gteq]': today(),
        'appointment_ransack[start_time_lteq]': today(),
      },
      loadListPageRecords: true,
    };

    sessionStorage.setItem('serviceState', JSON.stringify(savedServiceState));
  };

  return (
    <ServicesContext.Provider
      value={{
        fetchServices,
        fetchReferrals,
        services,
        servicesDownload,
        setServices,
        totalServices,
        servicesLoading,
        serviceSubmitting,

        createOrUpdateService,
        updateService,

        formErrors,
        formErrorAlertVisible,
        setFormErrorAlertVisible,
        fetchChoices,
        schoolChoices,
        phaseChoices,
        serviceTypeChoices,
        stageChoices,
        ownerChoices,
        activeOwnerChoices,
        frequencyChoices,
        statusChoices,
        phaseColors,
        addOwnerToServices,
        servicesChecked,
        setServicesChecked,
        filterValues,
        setFilterValues,

        sortValue,
        setSortValue,
      }}
    >
      {children}
    </ServicesContext.Provider>
  );
};

export { ServicesContextProvider, ServicesContextConsumer, ServicesContext };
