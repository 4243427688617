import React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';

const steps = ['Basic Info', 'Contact Info', 'Services', 'Goals & Renewals'];

interface StudentBuilderProgressStepperProps {
  activeStep: number;
}

const StudentBuilderProgressStepper: React.FC<StudentBuilderProgressStepperProps> = (props) => {
  const { activeStep } = props;

  const isStepOptional = (step: number) => {
    return step === 1;
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps: { completed?: boolean } = {};
          const labelProps: {
            optional?: React.ReactNode;
          } = {};
          if (isStepOptional(index)) {
            labelProps.optional = <Typography variant="caption">Optional</Typography>;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <br />
    </Box>
  );
};

export default StudentBuilderProgressStepper;
