/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Button } from '@material-ui/core';
import { UserTicketsContext } from '../contexts/UserTicketsContext';
import { UserTicket } from '../model';

interface ProviderTicketActionsProps {
  changeRequest: UserTicket;
}

const ProviderTicketActions: React.FC<ProviderTicketActionsProps> = (props) => {
  const { changeRequest } = props;

  const { updateUserTicket } = React.useContext(UserTicketsContext);

  let buttonLabel = 'Mark Viewed';
  let disableButton = false;

  if (changeRequest.status === 'pending') {
    buttonLabel = 'Pending';
    disableButton = true;
  } else if (changeRequest.viewed) {
    buttonLabel = 'Viewed';
    disableButton = true;
  }

  const handleMarkViewed = async (row) => {
    updateUserTicket(row, { viewed: 'true' });
  };

  return (
    <Button
      variant="contained"
      color="secondary"
      style={{ float: 'right', marginLeft: 15 }}
      onClick={() => handleMarkViewed(changeRequest)}
      disabled={disableButton}
    >
      {buttonLabel}
    </Button>
  );
};

export default ProviderTicketActions;
