import React, { Dispatch, SetStateAction } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ConfirmCancelModal from '../../shared/components/modal/ConfirmCancelModal';
import TextInput from '../../shared/components/form/TextInput';
import SelectInput from '../../shared/components/form/SelectInput';
import { SchoolClosingContext } from '../contexts/SchoolClosingsContext';
import { SchoolClosingFormValues } from '../model';

const useStyles = makeStyles({
  textInput: {
    height: '60px',
    width: '80%',
    margin: '10px 0',
  },
  baseError: {
    marginBottom: '10px',
  },
});

interface SchoolClosingModalProps {
  formValues: SchoolClosingFormValues;
  setFormValues: Dispatch<SetStateAction<SchoolClosingFormValues>>;
  formModalOpen: boolean;
  setFormModalOpen: Dispatch<SetStateAction<boolean>>;
}

const SchoolClosingModal: React.FC<SchoolClosingModalProps> = (props) => {
  const { formValues, setFormValues, formModalOpen, setFormModalOpen } = props;

  const classes = useStyles();
  const { createSchoolClosing } = React.useContext(SchoolClosingContext);

  const handleSubmit = (): void => {
    createSchoolClosing(formValues, formValues.payer_id);
    setFormModalOpen(false);
  };

  return (
    <ConfirmCancelModal
      isOpen={formModalOpen}
      title="Create School Closing"
      confirmLabel="Submit"
      openStatusChanged={(isOpen) => setFormModalOpen(isOpen)}
      onConfirm={handleSubmit}
    >
      <form noValidate autoComplete="off">
        <TextInput
          styleOverrides={classes.textInput}
          value={formValues?.start_date}
          label="Start Date"
          type="date"
          shrinkLabel
          valueChanged={(value: string) => setFormValues({ ...formValues, start_date: value })}
        />
        <TextInput
          styleOverrides={classes.textInput}
          value={formValues?.end_date}
          label="End Date"
          type="date"
          shrinkLabel
          valueChanged={(value: string) => setFormValues({ ...formValues, end_date: value })}
        />
        <SelectInput
          styleOverrides={classes.textInput}
          type="text"
          label="Category"
          choices={[
            { label: 'Inservice', value: 'inservice' },
            { label: 'School Closure', value: 'school_closure' },
          ]}
          value={formValues?.category}
          valueChanged={(value: string) => setFormValues({ ...formValues, category: value })}
        />
      </form>
    </ConfirmCancelModal>
  );
};

export default SchoolClosingModal;
